import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';
import Loading from '../../../components/Loading';

import Expired from './ExpiredPasspor';
import ExpiredPassporChild from './ExpiredPassporChild';
import NewBornPasspor from './NewBornPasspor';
import LostPassport from './LostPassport';
import BrokenPasspor from './BrokenPasspor';
import SplpDetail from './SPLP';

import './style.scss';

import { getPassportServiceList } from '../../form/PassportForm/action';

const Passpor = ({ history, i18n, passportService, getPassportServiceList }) => {
  const passportServiceList = get(passportService, ['data']);
  const serviceStatus = get(passportService, ['status']);
  const loadingService = serviceStatus !== 'resolve';
  const sideBarList = [
    {
      label: i18n('paspor_page.tab1.title'),
      link: '/passpor',
      Component: Expired
    },
    {
      label: i18n('paspor_page.tab2.title'),
      link: '/passpor/expired-child',
      Component: ExpiredPassporChild
    },
    {
      label: i18n('paspor_page.tab3.title'),
      link: '/passpor/new-born',
      Component: NewBornPasspor
    },
    {
      label: i18n('paspor_page.tab4.title'),
      link: '/passpor/lost',
      Component: LostPassport
    },
    {
      label: i18n('paspor_page.tab5.title'),
      link: '/passpor/change',
      Component: BrokenPasspor
    },
    {
      label: i18n('paspor_page.tab6.sidebar_title'),
      link: '/passpor/splp',
      Component: SplpDetail
    }
  ];

  // active tab
  const activePath = get(history, ['location', 'pathname']);

  useEffect(() => {
    getPassportServiceList();
  }, [getPassportServiceList]);

  return (
    <>
      <Banner
        title={i18n('paspor_page.banner.title')}
        subtitle={
          <>
            <div className='text'>{i18n('paspor_page.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('paspor_page.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      {loadingService && <Loading />}
      <CardSideBar
        activePath={activePath}
        sideBarList={sideBarList}
        passportServiceList={passportServiceList}
      />
    </>
  );
};

Passpor.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  passportService: PropTypes.object,
  getPassportServiceList: PropTypes.func
};

const mapStateToProps = (state) => ({
  passportService: {
    status: state.passportServiceReducer.status,
    error: state.passportServiceReducer.error,
    data: state.passportServiceReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getPassportServiceList }),
  withRouter
)(Passpor);
