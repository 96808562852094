const diasporaRegister = {
  data: [],
  status: 'idle',
  error: ''
};

const diasporaRegisterReducer = (state = diasporaRegister, action) => {
  switch (action.type) {
    case 'GET_DIASPORA_REGISTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DIASPORA_REGISTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DIASPORA_REGISTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export { diasporaRegisterReducer };
