export default [
  'unemployed',
  'state_off',
  'civil_servant',
  'tni_polri',
  'private_employees',
  'other',
  'it_admin',
  'admin',
  'export_admin',
  'import_admin',
  'real_estate_agent',
  'economist',
  'geologist',
  'nutritionists',
  'acturial',
  'account',
  'finnacial_accountant',
  'tax_accountant',
  'public_accountant',
  'pharmacist',
  'architect',
  'film_artist',
  'internal_audit',
  'marine',
  'flight',
  'treasurer',
  'midwife',
  'insurance_broker',
  'stock_broker',
  'labor',
  'head_of_district',
  'designer',
  'computer_graphic_design',
  'doctor',
  'lecturer',
  'editor',
  'fasset',
  'phisicist',
  'photographer',
  'geophysicist',
  'teacher',
  'judge',
  'scientist',
  'illustartor',
  'prosecutor',
  'journalism',
  'technical_journalism',
  'draftsman',
  'cook',
  'sea_captain',
  'pilot_captain',
  'cashier',
  'headmaster',
  'chemist',
  'chef',
  'collector',
  'conductor',
  'consultant',
  'manager',
  'machinist',
  'fisherman',
  'it_network_engineer',
  'notary_public',
  'pastor',
  'trainer',
  'painter',
  'tour_guide',
  'dancer',
  'lawyer',
  'translator',
  'singer',
  'tv_radio_announcer',
  'nurse',
  'it_programmer',
  'stewardess',
  'librarian',
  'reporter',
  'receptionist',
  'literature',
  'security_unit',
  'secretary',
  'driver',
  'it_systems_analyst',
  'it_systems_engineer',
  'technician',
  'therapist',
  'journalist',
  'it_web_developer',
  'it_webmaster',
  'entrepreneur',
  'student'
];
