import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import './style.scss';

import { getQuery } from '../../../pages/Visa/function';
import { getVisaApplicant, getDetail } from '../action';
import { getVisaService } from '../../VisaForm/action';
import { getOfficeList } from '../../NumberRegisterForm/action';
import { printPreview } from '../../functions';

const RegisterReview = ({
  history,
  i18n,
  visaService,
  visaApplicant,
  offices,
  getOfficeList,
  getVisaApplicant,
  getVisaService
}) => {
  const { uniqueId } = getQuery(history);
  const { data: visaServiceList } = visaService;
  const { data: visaApplicantDetail } = visaApplicant;
  const { data: officeList } = offices;
  const visaTitle = get(
    getDetail(visaServiceList, get(visaApplicantDetail, [0, 'visa_service_id']), '_id'),
    ['title']
  );
  const serviceId = get(visaApplicantDetail, [0, 'visa_service_id']);

  useEffect(() => {
    getVisaService();
    getOfficeList();
    getVisaApplicant(`?unique_number=${uniqueId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!uniqueId) {
      history.push('/visa');
    }
  }, [history, uniqueId]);

  return (
    <>
      <Banner
        title={visaTitle}
        subtitle={
          <div
            className='desc'
            dangerouslySetInnerHTML={{ __html: i18n('visa_page.banner.desc') }}
          />
        }
        backButtonClick={() => history.goBack()}
      />
      <CardDetail
        title={i18n('review_visa_number_regis.heading1', {
          type: visaTitle
        })}
        className='registration-review'
      >
        <div className='note'>{i18n('review_visa_number_regis.note')}</div>
        <div className='review-wrap'>
          {visaApplicantDetail.map(
            ({ unique_number, office_id, visa_service_id, created_at }, idx1) => (
              <div className='number-item' key={idx1}>
                <div className='item'>
                  <div className='label'>
                    {i18n('review_number_register.your_number')}
                  </div>
                  <div className='text'>{unique_number}</div>
                </div>
                <div className='item'>
                  <div className='label'>{i18n('review_number_register.category')}</div>
                  <div className='text'>
                    {get(getDetail(visaServiceList, visa_service_id, '_id'), ['title'])}
                  </div>
                </div>
                <div className='item'>
                  <div className='label'>{i18n('review_number_register.date_input')}</div>
                  <div className='text'>{moment(created_at).format('DD/MM/YY')}</div>
                </div>
                <div className='item'>
                  <div className='label'>{i18n('review_number_register.location')}</div>
                  <div className='text'>
                    {get(getDetail(officeList, office_id, 'id'), ['name'])}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <div className='btn-wrap'>
          <Button
            color='primary'
            outline
            onClick={() => printPreview('.registration-review')}
          >
            <Icon icon='print' />
            {i18n('review_number_register.print_number')}
          </Button>
          <Button
            color='primary'
            onClick={() =>
              history.push(
                `/visa/form/general-info?serviceId=${serviceId}&uniqueId=${uniqueId}&type=visa`
              )
            }
          >
            {i18n('cta.next')}
          </Button>
        </div>
      </CardDetail>
    </>
  );
};

RegisterReview.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  visaService: PropTypes.object,
  visaApplicant: PropTypes.object,
  offices: PropTypes.object,
  getOfficeList: PropTypes.func,
  getVisaApplicant: PropTypes.func,
  getVisaService: PropTypes.func
};

const mapStateToProps = (state) => ({
  visaService: {
    status: state.visaServiceReducer.status,
    error: state.visaServiceReducer.error,
    data: state.visaServiceReducer.data
  },
  visaApplicant: {
    status: state.visaApplicantReducer.status,
    error: state.visaApplicantReducer.error,
    data: state.visaApplicantReducer.data
  },
  offices: {
    status: state.officeListReducer.status,
    error: state.officeListReducer.error,
    data: state.officeListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getVisaApplicant, getVisaService, getOfficeList }),
  withRouter
)(RegisterReview);
