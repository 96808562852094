import React from 'react';
import PropType from 'prop-types';

const Pagination = ({ divider, total, currentPage, setCurrentPage, total_page }) => {
  // const lastPage = Math.ceil(total / divider);
  const lastPage = total_page;
  function pageNumber() {
    const items = [];
    for (let i = 1; i <= lastPage; i++) {
      items.push(
        <span
          key={i}
          className={`page-number ${currentPage === i ? 'active' : ''}`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </span>
      );
    }
    return items;
  }

  return (
    <div className='pagination'>
      {currentPage !== 1 && (
        <span className='prev' onClick={() => setCurrentPage(currentPage - 1)}>
          Sebelumnya
        </span>
      )}
      {pageNumber()}
      {currentPage !== lastPage && (
        <span className='next' onClick={() => setCurrentPage(currentPage + 1)}>
          Selanjutnya
        </span>
      )}
    </div>
  );
};

Pagination.propTypes = {
  divider: PropType.number,
  total: PropType.number,
  currentPage: PropType.number,
  setCurrentPage: PropType.func
};

export default Pagination;
