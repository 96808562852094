import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MiniCard from '../../../../components/CardDetail/MiniCard';

import { apiCaller } from '../../../../helpers/apiCaller';
import { getVisaStatistic } from '../../Visa/action';
import { getVisaService } from '../../../form/VisaForm/action';
import { getVisaServiceTitle } from '../../../form/VisaForm/functions';
import { setSummary } from '../functions';

const VisaDashboard = ({ getVisaService, visaService, ...props }) => {
  const visaServiceList = get(visaService, ['data']) || [];
  const showList = [
    'Journalist Visa',
    'Business Visa - Single Entry',
    'Tourist Visa',
    'Socio-Cultural Visa',
    'Business Visa - Multiple Entry',
    'Limited Stay Visa',
    'Diplomatic / Service Visa'
  ];

  useEffect(() => {
    getVisaService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = () =>
    visaServiceList.map((detail, idx) => {
      const isShow = showList.find((item) => item === detail.title);
      if (isShow) {
        return (
          <StatisticDetail
            detail={detail}
            {...props}
            visaServiceList={visaServiceList}
            key={idx}
          />
        );
      }
    });

  return (
    <div className='statistic detail-container'>
      <div className='title'>Visa</div>
      <div className='detail-wrap'>{renderDetails()}</div>
    </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
const StatisticDetail = ({
  detail,
  history,
  startDate,
  endDate,
  reloadData,
  setReloadData,
  visaServiceList
}) => {
  const { title, _id: id } = detail;
  const [statistic, setStatistic] = useState({
    numOfIn: String(0),
    numOfProcess: String(0),
    numOfFinish: String(0),
    numOfPending: String(0),
    numOfWaiting: String(0)
  });

  const statusTab = {
    '1': 'SUBMITTED'
  };

  const loadData = () => {
    const params = {
      service_id: id,
      from: startDate,
      to: endDate
    };

    apiCaller('admin/visa/statistic', 'get', undefined, true, params)
      .then((response) => {
        const statisticData = get(response, ['data', 'data']);
        const keyTitle = getVisaServiceTitle(visaServiceList, id);
        const summaryData = {
          title,
          subtitle: 'Foreign Citizen',
          onClick: () => history.push(`/admin/visa/${keyTitle}`),
          ...setSummary(statisticData, statusTab)
        };
        setStatistic(summaryData);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const detailMiniCard = {
    title,
    subtitle: 'Document WNI',
    ...statistic
  };

  return <MiniCard detail={detailMiniCard} />;
};

StatisticDetail.propTypes = {
  history: PropTypes.object,
  detail: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reloadData: PropTypes.bool,
  setReloadData: PropTypes.func,
  visaServiceList: PropTypes.array
};

VisaDashboard.propTypes = {
  history: PropTypes.object,
  getVisaService: PropTypes.func,
  visaService: PropTypes.object,
  getVisaStatistic: PropTypes.func,
  passportStatistic: PropTypes.object
};

const mapStateToProps = (state) => ({
  visaService: {
    status: state.visaServiceReducer.status,
    error: state.visaServiceReducer.error,
    data: state.visaServiceReducer.data
  },
  passportStatistic: {
    status: state.getVisaStatisticReducer.status,
    error: state.getVisaStatisticReducer.error,
    data: state.getVisaStatisticReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getVisaService,
    getVisaStatistic
  })
)(VisaDashboard);
