import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../components/Button';
import DatepickerRange from '../components/DatepickerRange';
import Content from './components/MainContent';

import {
  getPassportList,
  resetPassportList,
  getPassportStatistic,
  getPassportStatisticWeek,
  getPassportStatisticMonth,
  getPassportStatisticAll
} from './action';
import { getPassportServiceList } from '../../form/PassportForm/action';
import { getPassportServiceId } from '../../pages/Paspor/function';
import passportServiceTitle from '../../../constant/passportService';
import { getStatisticDate, downloadFile } from '../functions';

const AdminPassport = ({
  getPassportList,
  passportList,
  getPassportServiceList,
  passportService,
  resetPassportList,
  getPassportStatistic,
  getPassportStatisticWeek,
  getPassportStatisticMonth,
  getPassportStatisticAll,
  passportStatisticAll,
  passportStatistic,
  passportStatisticWeek,
  passportStatisticMonth
}) => {
  const { type } = useParams();

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [activeTab, setActiveTab] = useState('1');
  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();
  const statusTabList = {
    '1': 'REGISTERED',
    '2': 'PROCESS',
    '3': 'APPROVED',
    '4': 'PENDING',
    '5': 'WAITING'
  };

  const title = passportServiceTitle[type];
  const passportListData = get(passportList, ['data', 'data']) || [];
  const passportServiceList = get(passportService, ['data']) || [];
  const pagination = get(passportList, ['data', 'pagination']) || {};

  const passportServiceId = getPassportServiceId(passportServiceList, type);
  const loading =
    get(passportService, ['status']) === 'pending' ||
    get(passportList, ['status']) === 'pending';

  const getAllStatistic = () => {
    const allStatistic = get(passportStatisticAll, ['data']) || [];
    const summaryData = {
      numOfIn: String(0),
      numOfProcess: String(0),
      numOfFinish: String(0),
      numOfPending: String(0),
      numOfWaiting: String(0)
    };
    allStatistic.map(({ status, total }) => {
      switch (status) {
        case statusTabList['1']:
          summaryData.numOfIn = String(total);
          break;
        case statusTabList['2']:
          summaryData.numOfProcess = String(total);
          break;
        case statusTabList['3']:
          summaryData.numOfFinish = String(total);
          break;
        case statusTabList['4']:
          summaryData.numOfPending = String(total);
          break;
        case statusTabList['5']:
          summaryData.numOfWaiting = String(total);
          break;
      }
    });
    return summaryData;
  };

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      }
    ];
    const dayStatistic = get(passportStatistic, ['data']) || [];
    const weekStatistic = get(passportStatisticWeek, ['data']) || [];
    const monthStatistic = get(passportStatisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ status, total }) => {
        switch (status) {
          case statusTabList['1']:
            summaryData[index].numOfIn = String(total);
            break;
          case statusTabList['2']:
            summaryData[index].numOfProcess = String(total);
            break;
          case statusTabList['3']:
            summaryData[index].numOfFinish = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const loadData = () => {
    const params = {
      passport_service: passportServiceId,
      status: statusTabList[activeTab],
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getPassportList(params);
  };

  const loadStatisticData = (isAllOnly) => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();
    if (isAllOnly) {
      getPassportStatisticAll({
        passport_service: passportServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    } else {
      getPassportStatistic({
        passport_service: passportServiceId,
        from: today,
        to: today
      });
      getPassportStatisticWeek({
        passport_service: passportServiceId,
        from: startWeek,
        to: endWeek
      });
      getPassportStatisticMonth({
        passport_service: passportServiceId,
        from: startMonth,
        to: endMonth
      });
      getPassportStatisticAll({
        passport_service: passportServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    }
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      passport_service: passportServiceId
    };
    const fileName = `passport-${title}-${params.from}-${params.to}`;
    downloadFile('passport', params, fileName);
  };

  useEffect(() => {
    getPassportServiceList();
    return () => resetPassportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passportServiceId]);

  useEffect(() => {
    resetPassportList();

    if (passportServiceId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pageDivider, page, query, passportServiceId]);

  useEffect(() => {
    setActiveTab('1');
    setPage(1);
    setPageDivider(5);
    setQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Content
      title={title}
      dataTab={passportListData}
      summaryData={getSummaryData()}
      type={type}
      loading={loading}
      onTabChanges={setActiveTab}
      onPageChanges={setPage}
      onPageDividerChanges={setPageDivider}
      onQueryChanges={setQuery}
      defaultActiveTab={activeTab}
      pagination={pagination}
      loadData={() => {
        loadData();
        loadStatisticData();
      }}
      allStatistic={getAllStatistic()}
      topActionComponent={
        <div className='detail-action' style={{ display: 'flex' }}>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={() => {
              loadData();
              loadStatisticData(true);
            }}
          />
          <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
            Download
          </Button>
        </div>
      }
    />
  );
};

AdminPassport.propTypes = {
  getPassportList: PropTypes.func,
  passportList: PropTypes.object,
  getPassportServiceList: PropTypes.func,
  passportService: PropTypes.object,
  resetPassportList: PropTypes.func,
  getPassportStatistic: PropTypes.func,
  passportStatistic: PropTypes.object,
  getPassportStatisticWeek: PropTypes.func,
  getPassportStatisticAll: PropTypes.func,
  passportStatisticAll: PropTypes.object
};

const mapStateToProps = (state) => ({
  passportList: {
    status: state.getPassportListReducer.status,
    error: state.getPassportListReducer.error,
    data: state.getPassportListReducer.data
  },
  passportService: {
    status: state.passportServiceReducer.status,
    error: state.passportServiceReducer.error,
    data: state.passportServiceReducer.data
  },
  passportStatistic: {
    status: state.getPassportStatisticReducer.status,
    error: state.getPassportStatisticReducer.error,
    data: state.getPassportStatisticReducer.data
  },
  passportStatisticWeek: {
    status: state.getPassportStatisticWeekReducer.status,
    error: state.getPassportStatisticWeekReducer.error,
    data: state.getPassportStatisticWeekReducer.data
  },
  passportStatisticMonth: {
    status: state.getPassportStatisticMonthReducer.status,
    error: state.getPassportStatisticMonthReducer.error,
    data: state.getPassportStatisticMonthReducer.data
  },
  passportStatisticAll: {
    status: state.getPassportStatisticAllReducer.status,
    error: state.getPassportStatisticAllReducer.error,
    data: state.getPassportStatisticAllReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getPassportList,
    getPassportServiceList,
    resetPassportList,
    getPassportStatistic,
    getPassportStatisticWeek,
    getPassportStatisticMonth,
    getPassportStatisticAll
  }),
  withRouter
)(AdminPassport);
