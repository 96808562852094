import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import downloadImg from '../../assets/img/download.svg';
import { postPhotoGeneral } from '../PhotoUpload/action';

const PhotoUploaderSecond = ({ i18n, uploadPhoto, postPhotoGeneral }) => {
  const handlerChanges = (e) => {
    const photo = {};
    const file = e.target.files[0];
    photo.file = file;
    const formData = new FormData();
    formData.append('file', photo.file);
    postPhotoGeneral(formData);
  };

  return (
    <div className='photo-uploader-second'>
      <div className='row-name' />
      <div className='upload-wrap'>
        <div className='upload-button-wrap'>
          {/* <div className='upload-button'>
            <img src={downloadImg} alt='download-icon' />
            {i18n('upload_component.upload')}
          </div>
          <div className='img-name'>
            {i18n('upload_component.file_name')}
            <span className='delete'>x</span>
          </div> */}
          <input
            type='file'
            placeholder='Upload File'
            className='input-file'
            onChange={handlerChanges}
          />
        </div>
      </div>
    </div>
  );
};

PhotoUploaderSecond.propTypes = {
  i18n: PropTypes.func,
  postPhotoGeneral: PropTypes.func,
  uploadPhoto: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  uploadPhoto: {
    status: state.uploadPhotoGeneral.status,
    error: state.uploadPhotoGeneral.error,
    data: state.uploadPhotoGeneral.data
  }
});

export default connect(mapStateToProps, { postPhotoGeneral })(PhotoUploaderSecond);
