import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { printPreview } from '../../functions';

import './reviewStyle.scss';

const RegisterReview = ({
  history,
  i18n,
  reviewDetail,
  title,
  nextDirection,
  keyService = ['passport_service', 'title'],
  serviceName,
  withModalSuccess,
  stepNextRegister
}) => {
  const [showModal, setShowModal] = useState(false);

  const modalProps = {
    className: 'success registration',
    title: (
      <div className='top-wrap'>
        <Icon icon='green-check' />
        <div>{i18n('passport_schedule_confirm.modal_success.title')}</div>
        <div style={{ fontSize: 16 }}>{title}</div>
      </div>
    ),
    desc: (
      <div className='desc-wrap'>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n('review_number_register.success_regis')
          }}
        />

        <div
          style={{
            textAlign: 'left',
            margin: '16px 0 32px',
            lineHeight: '1.5'
          }}
          {...(!stepNextRegister
            ? {
              dangerouslySetInnerHTML: {
                __html: i18n('review_number_register.next_step')
              }
            }
            : {})}
        >
          {stepNextRegister}
        </div>
      </div>
    ),
    buttonProps: [
      {
        btnStyle: { color: 'primary' },
        label: i18n('review_number_register.back_to_home'),
        onClick: () => {
          setShowModal(false);
          history.push(nextDirection);
        }
      }
    ]
  };

  return (
    <>
      <Banner
        title={title}
        subtitle={
          <>
            <div className='text'>{i18n('paspor_page.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('paspor_page.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      <CardDetail
        title={i18n('review_number_register.title')}
        description={i18n('review_number_register.subtitle')}
        className='registration-review'
      >
        <div className='review-wrap'>
          <div className='number-item'>
            <div className='item'>
              <div className='label'>{i18n('review_number_register.your_number')}</div>
              <div className='text'>{get(reviewDetail, ['unique_number'])}</div>
            </div>
            <div className='item'>
              <div className='label'>{i18n('review_number_register.category')}</div>
              <div className='text'>
                {serviceName || get(reviewDetail, [...keyService])}
              </div>
            </div>
            <div className='item'>
              <div className='label'>{i18n('review_number_register.date_input')}</div>
              <div className='text'>
                {moment(get(reviewDetail, ['created_at'])).format('DD/MM/YY')}
              </div>
            </div>
            <div className='item'>
              <div className='label'>{i18n('review_number_register.location')}</div>
              <div className='text'>{get(reviewDetail, ['office', 'name'])}</div>
            </div>
          </div>
        </div>
        <div className='btn-wrap'>
          <Button
            color='primary'
            outline
            onClick={() => printPreview('.registration-review')}
          >
            <Icon icon='print' />
            {i18n('review_number_register.print_number')}
          </Button>
          <Button
            color='primary'
            onClick={() =>
              withModalSuccess ? setShowModal(true) : history.push(nextDirection)
            }
          >
            {i18n('cta.next')}
          </Button>
        </div>
      </CardDetail>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

RegisterReview.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  reviewDetail: PropTypes.object,
  title: PropTypes.string,
  nextDirection: PropTypes.string
};

export default compose(withRouter)(RegisterReview);
