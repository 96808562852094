import React from 'react';
import InstructionWrap from '../../../../components/InstructionWrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const OnArrival = ({ i18n }) => {
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('visa_page.tab2.title')}</div>
      <main>
        <InstructionWrap
          instruction={i18n('visa_page.tab2.step.instruction1')}
          instructionList={i18n('visa_page.instruction_step.on_arrival_req')}
          type='bullet'
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab2.step.instruction2')}
          instructionList={i18n('visa_page.instruction_step.on_arrival_req2')}
          type='bullet'
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab2.step.instruction3')}
          instructionList=''
          type='country'
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab2.step.instruction4')}
          instructionList=''
          type='checkpoint'
        />
      </main>
    </div>
  );
};

OnArrival.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(OnArrival);
