const visaApplicant = {
  data: [],
  status: 'idle',
  error: ''
};

const visaApplicantReducer = (state = visaApplicant, action) => {
  switch (action.type) {
    case 'GET_VISA_APPLICANT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_APPLICANT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.visaApplicant
      };
    case 'GET_VISA_APPLICANT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.visaApplicant.data.data
      };
    default:
      return state;
  }
};

export { visaApplicantReducer };
