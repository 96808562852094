import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';

import GeneralInfo from './BussinessIndonesia/GeneralInfo';
import PTPMA from './BussinessIndonesia/PTPMA';
import RepresentativeOffice from './BussinessIndonesia/RepresentativeOffice';
import TradeImpor from './BussinessIndonesia/TradeImpor';
import TradeEkspor from './BussinessIndonesia/TradeEkspor';

import BussinessKJRI from './BussinessKJRI/GeneralInfo';
import Investasi from './BussinessKJRI/Investment';
import Trade from './BussinessKJRI/Trade';

import NewYorkNow from './TradeExhibition/NewYorkNow';
import SummerFood from './TradeExhibition/SummerFancyFood';
import SeafoodExpo from './TradeExhibition/SENA';
import AllegraFestival from './TradeExhibition/AllegraNY';
import OtherExpoTrade from './BussinessIndonesia/GeneralInfo';

import NewYorkTravelShow from './CultureExhibition/NewYorkTimesTravelShow';
import PhilladelphiaTravel from './CultureExhibition/Philladelphia';
import BostonTravel from './CultureExhibition/Boston';
import IndonesianStreetFestival from './CultureExhibition/IndonesianStreetFestival';
import OtherExporCulture from './BussinessIndonesia/GeneralInfo';

const Economy = ({ history, i18n }) => {
  const sideBarList = [
    {
      label: i18n('economy.tab1.parent_title'),
      parentKey: 'indonesia',
      sublist: [
        {
          label: i18n('economy.tab1.sidebar'),
          link: '/economy',
          Component: GeneralInfo
        },
        {
          label: i18n('economy.tab2.sidebar'),
          link: '/economy/ptpma',
          Component: PTPMA
        },
        {
          label: i18n('economy.tab3.sidebar'),
          link: '/economy/representative-office',
          Component: RepresentativeOffice
        },
        {
          label: i18n('economy.tab4.sidebar'),
          link: '/economy/trade-impor',
          Component: TradeImpor
        },
        {
          label: i18n('economy.tab5.sidebar'),
          link: '/economy/trade-expor',
          Component: TradeEkspor
        }
      ]
    },
    {
      label: i18n('economy.tab6.parent_title'),
      parentKey: 'kjri',
      sublist: [
        {
          label: i18n('economy.tab6.sidebar'),
          link: '/economy/kjri-general-info',
          Component: BussinessKJRI
        },
        {
          label: i18n('economy.tab7.sidebar'),
          link: '/economy/kjri-investment',
          Component: Investasi
        },
        {
          label: i18n('economy.tab8.sidebar'),
          link: '/economy/kjri-trade',
          Component: Trade
        }
      ]
    },
    {
      label: i18n('economy.tab10.parent_title'),
      parentKey: 'trade',
      sublist: [
        {
          label: i18n('economy.tab10.sidebar'),
          link: '/economy/new-york-now',
          Component: NewYorkNow
        },
        {
          label: i18n('economy.tab11.sidebar'),
          link: '/economy/summer',
          Component: SummerFood
        },
        {
          label: i18n('economy.tab12.sidebar'),
          link: '/economy/sena',
          Component: SeafoodExpo
        },
        {
          label: i18n('economy.tab13.sidebar'),
          link: '/economy/allegra-festival',
          Component: AllegraFestival
        },
        {
          label: i18n('economy.tab14.sidebar'),
          link: '/economy/trade-expo/form?type=other',
          Component: OtherExpoTrade
        }
      ]
    },
    {
      label: i18n('economy.tab15.parent_title'),
      parentKey: 'expo',
      sublist: [
        {
          label: i18n('economy.tab15.sidebar'),
          link: '/economy/new-york-travel',
          Component: NewYorkTravelShow
        },
        {
          label: i18n('economy.tab16.sidebar'),
          link: '/economy/philladelphia-travel',
          Component: PhilladelphiaTravel
        },
        {
          label: i18n('economy.tab17.sidebar'),
          link: '/economy/boston-travel',
          Component: BostonTravel
        },
        {
          label: i18n('economy.tab18.sidebar'),
          link: '/economy/indonesian-festival',
          Component: IndonesianStreetFestival
        },
        {
          label: i18n('economy.tab19.sidebar'),
          link: '/economy/culture-expo/form',
          Component: OtherExporCulture
        }
      ]
    }
  ];

  // active tab
  const activePath = get(history, ['location', 'pathname']);

  return (
    <>
      <Banner
        title={i18n('economy.banner.title')}
        subtitle={
          <>
            <div className='text'>{i18n('economy.banner.subtitle')}</div>
          </>
        }
        preButtonDesc={<div className='pre-btn-desc'>{i18n('economy.banner.desc')}</div>}
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a
                href='https://oss.go.id/portal/'
                target='_blank'
                without
                rel='noopener noreferrer'
              >
                {i18n('economy.banner.cta')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      <CardSideBar activePath={activePath} sideBarList={sideBarList} />
    </>
  );
};

Economy.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func
};

export default withRouter(Economy);
