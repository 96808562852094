import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';

import Document from './Document';
import DocumentChanges from './DocumentChanges';
import Visa from './Visa';
import DocumentLegalization from './DocumentLegalization';
import Letter from './Letter';

const Faq = ({ history, i18n }) => {
  const sideBarList = [
    {
      label: i18n('rates.tab1.title'),
      link: '/rates',
      Component: Document
    },
    {
      label: i18n('rates.tab2.title'),
      link: '/rates/document-changes',
      Component: DocumentChanges
    },
    {
      label: i18n('rates.tab3.title'),
      link: '/rates/visa',
      Component: Visa
    },
    {
      label: i18n('rates.tab4.title'),
      link: '/rates/document-legalization',
      Component: DocumentLegalization
    },
    {
      label: i18n('rates.tab5.title'),
      link: '/rates/letter',
      Component: Letter
    }
  ];

  // active tab
  const activePath = get(history, ['location', 'pathname']);

  return (
    <>
      <Banner
        title={i18n('rates.banner.title')}
        subtitle={
          <>
            <div className='text'>{i18n('rates.banner.subtitle')}</div>
          </>
        }
        backButtonClick={() => history.goBack()}
      />
      <CardSideBar
        activePath={activePath}
        sideBarList={sideBarList}
        currMenu={i18n('rates.custom')}
      />
    </>
  );
};

Faq.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func
};

export default withRouter(Faq);
