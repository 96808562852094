import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Tabbing from '../../../../../components/Tabbing';
import DashboardTable from '../../../../../components/DashboardTable';
import MiniCard from '../../../../../components/CardDetail/MiniCard';
import Modal from '../../../../../components/Modal';
import Loading from '../../../../../components/Loading';

import { typeModalList } from '../../../functions';
import { patchApprove, patchReject, patchProcess, patchPending } from '../../action';
import './style.scss';

const MainContent = ({
  history,
  title,
  dataTab,
  summaryData = [],
  type,
  loading,
  onTabChanges,
  onPageChanges,
  onPageDividerChanges,
  onQueryChanges,
  defaultActiveTab,
  patchApprove,
  patchReject,
  patchProcess,
  approve,
  reject,
  process,
  pagination,
  loadData,
  allStatistic,
  pending,
  patchPending,
  topActionComponent
}) => {
  const [showModal, setShowModal] = useState(false);
  const [actionData, setActionData] = useState();
  const [typeModal, setTypeModal] = useState();
  const [reason, setReason] = useState();
  const [selectedData, setSelectedData] = useState({});
  const [loadingAction, setLoadingAction] = useState(false);

  const { numOfIn, numOfProcess, numOfFinish, numOfPending } = allStatistic || {};

  // action
  const { status: statusApprove, data: dataApprove, error: errorApprove } = approve;
  const { status: statusReject, data: dataReject, error: errorReject } = reject;
  const { status: statusProcess, data: dataProcess, error: errorProcess } = process;
  const { status: statusPending, data: dataPending, error: errorPending } = pending;

  const navs = [
    `Masuk (${numOfIn})`,
    `Proses (${numOfProcess})`,
    `Selesai (${numOfFinish})`,
    `Tunda (${numOfPending})`
  ];

  const columns = [
    // { dataField: 'id', text: 'No' },
    // { dataField: 'biography.name', text: 'Title' },
    // { dataField: 'created_at', text: 'Date' },
    // { dataField: 'status', text: 'Status' },
    // { dataField: 'unique_number', text: 'Action' }
    { dataField: 'id', text: 'No' },
    { dataField: 'created_at', text: 'Datetime' },
    { dataField: 'name', text: 'Name' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'phone', text: 'Phone' },
    { dataField: 'status', text: 'Status' },
    { dataField: 'unique_number', text: 'Action' }
  ];

  const handlerAction = (type, data) => {
    if (showModal) {
      setShowModal(false);
      setTypeModal();
      setSelectedData({});
    } else {
      setShowModal(true);
      setTypeModal(type);
      setSelectedData(data);
    }
  };

  const handlerSubmitAction = () => {
    const { id } = selectedData;
    if (typeModal === 'process') {
      patchProcess(id);
    } else if (typeModal === 'reject') {
      patchReject(id, {
        note: reason
      });
    } else if (typeModal === 'finish') {
      patchApprove(id);
    } else if (typeModal === 'tunda') {
      patchPending(id, {
        note: reason
      });
    }

    setShowModal(false);
    setLoadingAction(true);
  };

  const actionList = {
    check: { onClick: (id, data) => handlerAction('process', data), type: 'check' },
    reject: { onClick: (id, data) => handlerAction('tunda', data), type: 'delete' }
  };
  const action1 = [actionList.check, actionList.reject];
  const action2 = [
    { onClick: (id, data) => handlerAction('finish', data), type: 'check' }
  ];
  const action3 = [];

  // For tabbing
  const propsTableTab = {
    columns,
    search: 'biography.name',
    idKeyField: 'unique_number',
    onPageChanges,
    onPageDividerChanges,
    onQueryChanges,
    data: dataTab,
    pagination
  };

  const table = [
    <DashboardTable key='1' action={action1} {...propsTableTab} />,
    <DashboardTable key='2' action={action2} {...propsTableTab} />,
    <DashboardTable key='3' action={action3} {...propsTableTab} />,
    <DashboardTable key='4' action={action3} {...propsTableTab} />
  ];

  const details = [
    {
      title,
      list: summaryData
    }
  ];

  const detailModal = () => {
    const onChange = (e) => setReason(e.target.value);
    const name = get(selectedData, ['biography', 'name']);

    return typeModalList[typeModal]({ name, reason, onChange, actionData });
  };

  const modalProps = typeModal ? detailModal() : {};

  const renderDetails = () =>
    details.map(({ title, list }, idx) => (
      <div className='detail-container' key={idx}>
        <div
          className='detail-top'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h1 className='title'>{title}</h1>
          {topActionComponent}
        </div>
        <div className='detail-wrap'>
          {list.map((detail, idx2) => (
            <MiniCard detail={detail} key={idx2} />
          ))}
        </div>
      </div>
    ));

  useEffect(() => {
    if (loadingAction) {
      let data = {};
      let error = {};
      let status = 'idle';

      if (typeModal === 'process') {
        data = dataProcess;
        error = errorProcess;
        status = statusProcess;
      } else if (typeModal === 'reject') {
        data = dataReject;
        error = errorReject;
        status = statusReject;
      } else if (typeModal === 'finish') {
        data = dataApprove;
        error = errorApprove;
        status = statusApprove;
      } else if (typeModal === 'tunda') {
        data = dataPending;
        error = errorPending;
        status = statusPending;
      }

      if (status === 'resolve') {
        const messageError = get(error, ['response', 'data', 'error', 'message']);
        const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
        const messageDetail = get(error, [
          'response',
          'data',
          'error',
          'detail',
          0,
          'message'
        ]);

        const { status, message } = data;
        setLoadingAction(false);

        if (status) {
          // show success message
          handlerAction('successSave', {});
          loadData();
        } else {
          const messageErrorFinal =
            message !== '' ? message : `${messageError}. ${keyDetail}: ${messageDetail}.`;
          handlerAction('failedSave', {});
          setActionData(messageErrorFinal);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAction, statusApprove, statusReject, statusProcess, statusPending]);

  return (
    <div className='paspor-splp'>
      {(loading || loadingAction) && <Loading />}
      {renderDetails()}
      <Tabbing
        navs={navs}
        table={table}
        onChange={onTabChanges}
        defaultActiveTab={defaultActiveTab}
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className={get(modalProps, ['className']) || 'confirm'}
        buttonProps={[
          {
            btnStyle: {
              color: get(modalProps, ['cta', 'no', 'color']) || 'info',
              outline: get(modalProps, ['cta', 'no', 'outline']) || true
            },
            label: get(modalProps, ['cta', 'no', 'text']),
            onClick: () => {
              handlerAction();
              setShowModal(false);
            }
          },
          ...[
            get(modalProps, ['cta', 'yes'])
              ? {
                  btnStyle: { color: get(modalProps, ['cta', 'yes', 'color']) },
                  label: get(modalProps, ['cta', 'yes', 'text']),
                  onClick: handlerSubmitAction
                }
              : {}
          ]
        ]}
        title={get(modalProps, ['title'])}
        desc={get(modalProps, ['desc'])}
      />
    </div>
  );
};

MainContent.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  approve: {
    status: state.patchDocLegalApproveReducer.status,
    error: state.patchDocLegalApproveReducer.error,
    data: state.patchDocLegalApproveReducer.data
  },
  reject: {
    status: state.patchDocLegalRejectReducer.status,
    error: state.patchDocLegalRejectReducer.error,
    data: state.patchDocLegalRejectReducer.data
  },
  process: {
    status: state.patchDocLegalProcessReducer.status,
    error: state.patchDocLegalProcessReducer.error,
    data: state.patchDocLegalProcessReducer.data
  },
  pending: {
    status: state.patchDocLegalPendingReducer.status,
    error: state.patchDocLegalPendingReducer.error,
    data: state.patchDocLegalPendingReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    patchApprove,
    patchReject,
    patchProcess,
    patchPending
  }),
  withRouter
)(MainContent);
