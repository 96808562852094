import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const RowDetail = ({ label, info }) => {
  return (
    <div className='row-detail'>
      <div className='label'>{label}</div>
      <div className='info'>{info}</div>
    </div>
  );
};

RowDetail.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string
};

export default RowDetail;
