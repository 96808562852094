import { apiCaller } from '../../../helpers/apiCaller';

const getVisaListRequest = () => ({
  type: 'GET_VISA_LIST_REQUEST'
});

const getVisaListSuccess = (data) => ({
  type: 'GET_VISA_LIST_SUCCESS',
  payload: data
});

const getVisaListFailure = (error) => ({
  type: 'GET_VISA_LIST_FAILURE',
  payload: error
});

const resetVisaList = () => ({
  type: 'RESET_VISA_LIST'
});

const getVisaList = (params) => {
  return (dispatch) => {
    dispatch(getVisaListRequest());
    return apiCaller('admin/visa', 'get', undefined, true, params)
      .then((data) => dispatch(getVisaListSuccess(data)))
      .catch((error) => dispatch(getVisaListFailure(error)));
  };
};

// DAY
const getVisaStatisticRequest = () => ({
  type: 'GET_VISA_STATISTIC_REQUEST'
});

const getVisaStatisticSuccess = (data) => ({
  type: 'GET_VISA_STATISTIC_SUCCESS',
  payload: data
});

const getVisaStatisticFailure = (error) => ({
  type: 'GET_VISA_STATISTIC_FAILURE',
  payload: error
});

const getVisaStatistic = (params) => {
  return (dispatch) => {
    dispatch(getVisaStatisticRequest());
    return apiCaller('admin/visa/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getVisaStatisticSuccess(data)))
      .catch((error) => dispatch(getVisaStatisticFailure(error)));
  };
};

// WEEK
const getVisaStatisticWeekRequest = () => ({
  type: 'GET_VISA_STATISTIC_WEEK_REQUEST'
});

const getVisaStatisticWeekSuccess = (data) => ({
  type: 'GET_VISA_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getVisaStatisticWeekFailure = (error) => ({
  type: 'GET_VISA_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getVisaStatisticWeek = (params) => {
  return (dispatch) => {
    dispatch(getVisaStatisticWeekRequest());
    return apiCaller('admin/visa/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getVisaStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getVisaStatisticWeekFailure(error)));
  };
};

// MONTH
const getVisaStatisticMonthRequest = () => ({
  type: 'GET_VISA_STATISTIC_MONTH_REQUEST'
});

const getVisaStatisticMonthSuccess = (data) => ({
  type: 'GET_VISA_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getVisaStatisticMonthFailure = (error) => ({
  type: 'GET_VISA_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getVisaStatisticMonth = (params) => {
  return (dispatch) => {
    dispatch(getVisaStatisticMonthRequest());
    return apiCaller('admin/visa/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getVisaStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getVisaStatisticMonthFailure(error)));
  };
};

// MONTH
const getVisaStatisticAllRequest = () => ({
  type: 'GET_VISA_STATISTIC_ALL_REQUEST'
});

const getVisaStatisticAllSuccess = (data) => ({
  type: 'GET_VISA_STATISTIC_ALL_SUCCESS',
  payload: data
});

const getVisaStatisticAllFailure = (error) => ({
  type: 'GET_VISA_STATISTIC_ALL_FAILURE',
  payload: error
});

const getVisaStatisticAll = (params) => {
  return (dispatch) => {
    dispatch(getVisaStatisticAllRequest());
    return apiCaller('admin/visa/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getVisaStatisticAllSuccess(data)))
      .catch((error) => dispatch(getVisaStatisticAllFailure(error)));
  };
};

// PROCESS
const patchProcessRequest = () => ({
  type: 'PATCH_VISA_PROCESS_REQUEST'
});

const patchProcessSuccess = (data) => ({
  type: 'PATCH_VISA_PROCESS_SUCCESS',
  payload: data
});

const patchProcessFailure = (error) => ({
  type: 'PATCH_VISA_PROCESS_FAILURE',
  payload: error
});

const patchProcess = (id) => {
  return (dispatch) => {
    dispatch(patchProcessRequest());
    return apiCaller(`admin/visa/process/${id}`, 'patch', undefined, true)
      .then((data) => dispatch(patchProcessSuccess(data)))
      .catch((error) => dispatch(patchProcessFailure(error)));
  };
};

// APPROVE
const patchApproveRequest = () => ({
  type: 'PATCH_VISA_APPROVE_REQUEST'
});

const patchApproveSuccess = (data) => ({
  type: 'PATCH_VISA_APPROVE_SUCCESS',
  payload: data
});

const patchApproveFailure = (error) => ({
  type: 'PATCH_VISA_APPROVE_FAILURE',
  payload: error
});

const patchApprove = (id) => {
  return (dispatch) => {
    dispatch(patchApproveRequest());
    return apiCaller(`admin/visa/approve/${id}`, 'patch', undefined, true)
      .then((data) => dispatch(patchApproveSuccess(data)))
      .catch((error) => dispatch(patchApproveFailure(error)));
  };
};

// PENDING
const patchPendingRequest = () => ({
  type: 'PATCH_VISA_PENDING_REQUEST'
});

const patchPendingSuccess = (data) => ({
  type: 'PATCH_VISA_PENDING_SUCCESS',
  payload: data
});

const patchPendingFailure = (error) => ({
  type: 'PATCH_VISA_PENDING_FAILURE',
  payload: error
});

const patchPending = (id, params) => {
  return (dispatch) => {
    dispatch(patchPendingRequest());
    return apiCaller(`admin/visa/pending/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchPendingSuccess(data)))
      .catch((error) => dispatch(patchPendingFailure(error)));
  };
};

// DETAIL VISA
const getVisaDetailRequest = () => ({
  type: 'GET_VISA_DETAIL_REQUEST'
});

const getVisaDetailSuccess = (data) => ({
  type: 'GET_VISA_DETAIL_SUCCESS',
  payload: data
});

const getVisaDetailFailure = (error) => ({
  type: 'GET_VISA_DETAIL_FAILURE',
  payload: error
});

const getVisaDetail = (id) => {
  return (dispatch) => {
    dispatch(getVisaDetailRequest());
    return apiCaller(`admin/visa/detail/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getVisaDetailSuccess(data)))
      .catch((error) => dispatch(getVisaDetailFailure(error)));
  };
};

export {
  getVisaList,
  resetVisaList,
  getVisaStatistic,
  getVisaStatisticWeek,
  getVisaStatisticMonth,
  getVisaStatisticAll,
  patchApprove,
  patchPending,
  patchProcess,
  getVisaDetail
};
