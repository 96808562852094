import get from 'lodash/get';
import moment from 'moment';
import { visaType } from '../../pages/Visa/function';

export const setParams = (form) => {
  const {
    personalInfo,
    generalInfo,
    other,
    passport,
    photo,
    signIn,
    applicationId
  } = form;
  const formatDate = 'YYYY-MM-DD';

  const params = {
    // general info
    visit_purpose: get(generalInfo, ['purposeVisit']),
    visa_type: get(generalInfo, ['visaType']),
    stay_duration: get(generalInfo, ['lengthStay']),
    entry_port_id: get(generalInfo, ['pointEntry', '_id']),
    entry_date: moment(get(generalInfo, ['dateEntry'])).format(formatDate),
    vehicle_number: get(generalInfo, ['flightVessel']),
    departure_date: moment(get(generalInfo, ['dateDeparture'])).format(formatDate),
    departure_port_id: get(generalInfo, ['portDeparture', '_id']),
    id_address: get(generalInfo, ['addressId']),
    id_city_code: get(generalInfo, ['city', 'code']),
    id_province_code: get(generalInfo, ['province', 'code']),
    id_zipcode: get(generalInfo, ['zip']),
    id_phone: `${get(generalInfo, ['id_phone'])}`,
    // personal info
    first_name: get(personalInfo, ['firstName']),
    middle_name: get(personalInfo, ['middleName']),
    last_name: get(personalInfo, ['familyName']),
    sex: get(personalInfo, ['gender']),
    marital_status: get(personalInfo, ['marriageStatus']),
    birth_country_code: get(personalInfo, ['countryBirth', 'code']),
    birth_date: moment(get(personalInfo, ['dob'])).format(formatDate),
    birth_place: get(personalInfo, ['dob_place']),
    nationality_code: get(personalInfo, ['nationality', 'code']),
    us_address: get(personalInfo, ['us_address']),
    us_city: get(personalInfo, ['city']),
    us_state_id: get(personalInfo, ['state', 'value']),
    us_zipcode: get(personalInfo, ['us_address']),
    us_phone: `${get(personalInfo, ['phone'])}`,
    job_profession: get(personalInfo, ['profession', 'value']),
    job_position: get(personalInfo, ['current_position']),
    ...(get(personalInfo, ['institution_phone']) && {
      job_phone: `${get(personalInfo, ['institution_phone'])}`
    }),
    job_institution: get(personalInfo, ['institution_name']),
    job_address: get(personalInfo, ['institution_address']),
    job_city: get(personalInfo, ['institution_city']),
    job_state_id: get(personalInfo, ['institution_state']),
    job_country_code: get(personalInfo, ['institution_country', 'code']),
    job_zipcode: get(personalInfo, ['institution_zip']),
    // passport
    passport_type: get(passport, ['passport_type']),
    passport_number: get(passport, ['passport_number']),
    passport_issuing: get(passport, ['place_issue']),
    passport_country_code: get(passport, ['country_place_issue', 'code']),
    passport_issue_date: moment(get(passport, ['date_issue'])).format(formatDate),
    passport_expiry: moment(get(passport, ['date_expired'])).format(formatDate),
    // other
    sponsor_type: get(other, ['sponsorship_type']),
    sponsor_name: get(other, ['sponsor_name']),
    sponsor_contact: get(other, ['contact_name']),
    sponsor_address: get(other, ['address']),
    sponsor_city_code: get(other, ['city', 'code']),
    sponsor_province_code: get(other, ['province', 'code']),
    sponsor_zipcode: get(other, ['zip_code']),
    sponsor_phone: `${get(other, ['phone'])}`,
    questionnaire: [
      {
        question: 'Have You Ever Been to Indonesia Before?',
        answer: get(other, ['question_1']) === 'true' ? 'YES' : 'NO'
      },
      {
        question: 'Are You in Possession of Other Countries Travel Documents?',
        answer: get(other, ['question_4']) === 'true' ? 'YES' : 'NO'
      },
      {
        question: 'Do You Have Previous Visa to Enter Indonesia?',
        answer: get(other, ['question_5']) === 'true' ? 'YES' : 'NO'
      },
      {
        question: 'Have Your Visa Application been Denied Before?',
        answer: get(other, ['question_6']) === 'true' ? 'YES' : 'NO'
      },
      {
        question: 'Have you ever been forced to leave Indonesia',
        answer: get(other, ['question_7']) === 'true' ? 'YES' : 'NO'
      },
      {
        question: 'Have You Ever been Commited a Crime or Any Offence?',
        answer: get(other, ['question_8']) === 'true' ? 'YES' : 'NO'
      }
    ],
    ...(get(other, ['question_1']) === 'true' && {
      visit_date: moment(get(other, ['question_2'])).format(formatDate),
      last_visit_purpose: get(other, ['question_3'])
    }),
    return_ticket: get(other, ['question_9']),
    ticket_issuing: get(other, ['question_10']),
    ticket_issuing_date: moment(get(other, ['question_11'])).format(formatDate),
    ticket_expiry_date: moment(get(other, ['question_12'])).format(formatDate),
    // photo
    image_url: get(photo, ['url']),
    // sign in
    signature_name: get(signIn, ['signature_name']),
    completed_at: get(signIn, ['completed_at']),
    unique_number: applicationId
    // visa_service_id: serviceId
  };

  return params;
};

export const setParamsStorage = (tmpData) => {
  const data = JSON.parse(tmpData);

  const tmpForm = {
    generalInfo: {
      purposeVisit: data.visit_purpose,
      visaType: data.visa_type,
      lengthStay: data.stay_duration,
      pointEntry: {
        _id: data.entry_port_id
      },
      dateEntry: new Date(moment(data.entry_date)),
      flightVessel: data.vehicle_number,
      dateDeparture: new Date(moment(data.departure_date)),
      portDeparture: {
        _id: data.departure_port_id
      },
      addressId: data.id_address,
      city: {
        code: data.id_city_code
      },
      province: {
        code: data.id_province_code
      },
      zip: data.id_zipcode,
      id_phone: data.id_phone
    },
    personalInfo: {
      firstName: data.first_name,
      middleName: data.middle_name,
      familyName: data.last_name,
      gender: data.sex,
      marriageStatus: data.marital_status,
      countryBirth: {
        code: data.birth_country_code
      },
      dob: new Date(moment(data.birth_date)),
      dob_place: data.birth_place,
      nationality: {
        code: data.nationality_code
      },
      us_address: data.us_address,
      city: data.us_city,
      state: {
        value: data.us_state_id
      },
      // us_address: data.us_zipcode,
      phone: data.us_phone,
      profession: data.job_profession,
      current_position: data.job_position,
      institution_phone: data.job_phone,
      institution_name: data.job_institution,
      institution_address: data.job_address,
      institution_city: data.job_city,
      institution_state: data.job_state_id,
      institution_country: {
        code: data.job_country_code
      },
      institution_zip: data.job_zipcode
    },
    passport: {
      passport_type: data.passport_type,
      passport_number: data.passport_number,
      place_issue: data.passport_issuing,
      country_place_issue: {
        code: data.passport_country_code
      },
      date_issue: new Date(moment(data.passport_issue_date)),
      date_expired: new Date(moment(data.passport_expiry))
    },
    other: {
      sponsorship_type: data.sponsor_type,
      sponsor_name: data.sponsor_name,
      contact_name: data.sponsor_contact,
      address: data.sponsor_address,
      city: {
        code: data.sponsor_city_code
      },
      province: {
        code: data.sponsor_province_code
      },
      zip_code: data.sponsor_zipcode,
      phone: data.sponsor_phone,
      question_1: 'true',
      question_2: new Date(),
      question_3: data.last_visit_purpose,
      question_4: 'true',
      question_5: 'true',
      question_6: 'true',
      question_7: 'true',
      question_9: data.return_ticket,
      question_10: data.ticket_issuing,
      question_11: data.ticket_issuing_date,
      question_12: data.ticket_expiry_date
    },
    photo: {
      url: data.image_url
    },
    signIn: {
      signature_name: data.signature_name,
      completed_at: data.completed_at
    }
  };

  return tmpForm;
};

export const getVisaServiceTitle = (list, visaId) => {
  const visaServiceSelected = list.find(({ _id }) => _id === visaId);

  const findKeyTitle = Object.keys(visaType).find(
    (keyVisa) => visaType[keyVisa].key === get(visaServiceSelected, ['title'])
  );

  return findKeyTitle;
};

export const getServiceTitleByName = (name) => {
  const findKeyTitle = Object.keys(visaType).find((key) => visaType[key].key === name);

  if (findKeyTitle) {
    return visaType[findKeyTitle].label;
  }
};
