const photo = {
  data: {},
  status: 'idle',
  error: ''
};

const fileReducer = (state = photo, action) => {
  switch (action.type) {
    case 'POST_FILE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_FILE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.photo
      };
    case 'POST_FILE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.photo.data
      };
    case 'RESET_PHOTO_DATA':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export { fileReducer };
