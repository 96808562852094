import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Alert } from 'reactstrap';
import * as yup from 'yup';
import moment from 'moment';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import Banner from '../../../../components/Banner';
import Icon from '../../../../components/Icon';
import CardDetail from '../../../../components/CardDetail';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RadioButton from '../../../../components/input/RadioButton';
import DobInput from '../../components/Dob';
import Datepicker from '../../../../components/input/Datepicker';
import Dropdown from '../../../../components/Dropdown';

import { getQuery } from '../../../pages/Paspor/function';
import { getCountryList } from '../../PassportForm/action';
import { getSosBudLetter, postSosBudSubmission, resetPostSubmission } from '../action';

import { optionsList, sosBudType } from '../functions';

import { errorAlertScroll } from '../../../../helpers/functions';

const MoveSchoolForm = ({
  history,
  i18n,
  country,
  getCountryList,
  getSosBudLetter,
  letterRegisDetail,
  postSosBudSubmission,
  submission,
  resetPostSubmission
}) => {
  const { id } = getQuery(history);
  const detailForm = get(letterRegisDetail, ['data', 0]);
  const { data, status, error } = submission;

  const [form, setForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [errorSave, setErrorSave] = useState('');
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);
  const applicationId = get(detailForm, ['unique_number']);

  const country_options = get(country, ['data']);
  const { genderOptions, passport_type_options } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      name: yup.string().required('required'),
      gender: yup.string().required('required'),
      birth_city: yup.string().required('required'),
      birth_date: yup.date().required('required'),
      passport_type: yup.string().required('required'),
      passport_number: yup.string().required('required'),
      passport_expired: yup.date().required('required'),
      // father
      father_name: yup.string().required('required'),
      father_birth_city: yup.string().required('required'),
      father_birth_date: yup.date().required('required'),
      father_nationality: yup.object().shape({
        name: yup.string().required('required')
      }),
      father_passport_type: yup.string().required('required'),
      father_passport_number: yup.string().required('required'),
      father_passport_expired: yup.date().required('required'),
      father_job: yup.string().required('required'),
      // mother
      mother_name: yup.string().required('required'),
      mother_birth_city: yup.string().required('required'),
      mother_birth_date: yup.date().required('required'),
      mother_nationality: yup.object().shape({
        name: yup.string().required('required')
      }),
      mother_passport_type: yup.string().required('required'),
      mother_passport_number: yup.string().required('required'),
      mother_passport_expired: yup.date().required('required'),
      mother_job: yup.string().required('required'),
      // address
      american_address: yup.string().required('required'),
      indo_address: yup.string().required('required'),
      email: yup.string().email('email').required('required'),
      id_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      // school
      school_name: yup.string().required('required'),
      school_address: yup.string().required('required'),
      kelas_terakhir: yup.string().required('required'),
      reason: yup.string().required('required'),
      diterima_dikelas: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        if (showModal) {
          setShowModal(false);
          setTypeModal();
        } else {
          setShowModal(true);
          setTypeModal('confirm');
        }
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const submit = () => {
    const params = {
      unique_number: applicationId,
      service_id: get(detailForm, ['service_id']),
      child: {
        name: form.name,
        gender: form.gender,
        birth_city: form.birth_city,
        birth_date: moment(form.birth_date).format('YYYY-MM-DD'),
        passport_type: form.passport_type,
        passport_number: form.passport_number,
        passport_expiry: moment(form.passport_expired).format('YYYY-MM-DD')
      },
      father: {
        name: form.father_name,
        birth_city: form.father_birth_city,
        birth_date: moment(form.father_birth_date).format('YYYY-MM-DD'),
        passport_type: form.father_passport_type,
        passport_number: form.father_passport_number,
        passport_expiry: moment(form.father_passport_expired).format('YYYY-MM-DD'),
        job_name: form.father_job,
        nationality: form.father_nationality.name
      },
      mother: {
        name: form.mother_name,
        birth_city: form.mother_birth_city,
        birth_date: moment(form.mother_birth_date).format('YYYY-MM-DD'),
        passport_type: form.mother_passport_type,
        passport_number: form.mother_passport_number,
        passport_expiry: moment(form.mother_passport_expired).format('YYYY-MM-DD'),
        job_name: form.mother_job,
        nationality: form.mother_nationality.name
      },
      school: {
        name: form.school_name,
        address: form.school_address,
        old_grade: parseInt(form.kelas_terakhir),
        new_grade: parseInt(form.diterima_dikelas)
      },
      us_address: form.american_address,
      id_address: form.indo_address,
      email: form.email,
      phone: `${form.id_phone}`,
      reason: form.reason
    };

    setShowModal(false);
    postSosBudSubmission(params);
  };

  const handlerChanges = (value, key, errorKey) => {
    if (key === 'marital_status') {
      const tmp_form = { ...form };
      if (value !== 'married') {
        tmp_form.spouse = {};
      }
      setForm({ ...tmp_form, [key]: value });
    } else {
      setForm({ ...form, [key]: value });
    }
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    getCountryList();
    getSosBudLetter(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const keyDetail = get(error, ['response', 'data', 'error', 'detail']);
      const messageDetail = get(error, ['response', 'data', 'error', 'message']);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setShowModal(true);
      setTypeModal('success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const renderNextStep = () => {
    if (sosBudType['transferSchool']) {
      const list = sosBudType['transferSchool'].list;
      return (
        <>
          <ol style={{ textAlign: 'left' }}>
            {list.map((listTitle, key) => (
              <li
                key={key}
                dangerouslySetInnerHTML={{
                  __html: i18n(`pensosbud.instruction_step.${listTitle}`)
                }}
              />
            ))}
          </ol>
          <div style={{ marginBottom: '16px' }}>{i18n('track_status_info')}</div>
        </>
      );
    }
  };

  let modalProps = {
    className: 'confirm',
    buttonProps: [
      {
        btnStyle: { color: 'info', outline: true },
        label: i18n('cta.no'),
        onClick: () => setShowModal(false)
      },
      {
        btnStyle: { color: 'info' },
        label: i18n('cta.ok'),
        onClick: () => submit()
      }
    ],
    title: i18n('confirm_modal.title'),
    desc: i18n('confirm_modal.desc')
  };

  if (typeModal === 'success') {
    modalProps = {
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('cta.ok'),
          onClick: () => {
            history.push('/pensosbud');
            resetPostSubmission();
          }
        }
      ],
      className: 'success',
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>
            {i18n('success_save_modal.title_with_name', {
              name: i18n('pensosbud.move_school_form.banner_title')
            })}
          </div>
        </div>
      ),
      desc: renderNextStep()
    };
  }

  return (
    <>
      {status === 'pending' && <Loading />}
      <Banner
        title={i18n('pensosbud.move_school_form.banner_title')}
        subtitle={i18n('pensosbud.banner.subtitle')}
        backButtonClick={() => history.goBack()}
      />
      <CardDetail
        title={i18n('pensosbud.move_school_form.form.title')}
        className='pensosbud-form form'
        rightInfo={
          <span>
            <span className='grey-text'>{i18n('form_personal_data.application_id')}</span>
            {applicationId}
          </span>
        }
      >
        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        <form onSubmit={handlerSubmit} id='graduate'>
          <div className='form-container'>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('form_personal_data.instruction_personal_info')}
              </div>
              <TextInput
                label={i18n('form.fullname.label')}
                placeholder={i18n('form.fullname.placeholder')}
                value={get(form, ['name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'name')
                }}
                errorMessage={
                  get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
                }
              />
              <RadioButton
                className='gender-type'
                label={i18n('form.gender.label')}
                options={genderOptions}
                value={get(form, ['gender'])}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'gender')
                }}
                errorMessage={
                  get(errors, ['gender']) && i18n(`validation.${get(errors, ['gender'])}`)
                }
              />
              <DobInput
                i18n={i18n}
                id='personal'
                place={{
                  label: i18n('form.dob_place.label'),
                  placeholder: i18n('form.dob_place.placeholder'),
                  onChange: (birth_city) => handlerChanges(birth_city, 'birth_city'),
                  value: get(form, ['birth_city']),
                  errorMessage:
                    get(errors, ['birth_city']) &&
                    i18n(`validation.${get(errors, ['birth_city'])}`)
                }}
                date={{
                  label: i18n('form.dob.label'),
                  placeholder: i18n('form.dob.placeholder'),
                  datepickerProps: { maxDate: new Date() },
                  onChange: (birth_date) => handlerChanges(birth_date, 'birth_date'),
                  value: get(form, ['birth_date']),
                  errorMessage:
                    get(errors, ['birth_date']) &&
                    i18n(`validation.${get(errors, ['birth_date'])}`)
                }}
              />
              <RadioButton
                className='passport-type'
                label={i18n('pensosbud.move_school_form.form.passport_type.label')}
                options={passport_type_options}
                value={get(form, ['passport_type'])}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'passport_type')
                }}
                errorMessage={
                  get(errors, ['passport_type']) &&
                  i18n(`validation.${get(errors, ['passport_type'])}`)
                }
              />
              <TextInput
                label={i18n('form.passport_number.label')}
                placeholder={i18n('form.passport_number.placeholder')}
                value={get(form, ['passport_number'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'passport_number')
                }}
                errorMessage={
                  get(errors, ['passport_number']) &&
                  i18n(`validation.${get(errors, ['passport_number'])}`)
                }
              />
              <Datepicker
                label={i18n('pensosbud.move_school_form.form.passport_expired.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.passport_expired.placeholder'
                )}
                className='date_issue-date'
                value={get(form, ['passport_expired'])}
                onChange={(passport_expired) =>
                  handlerChanges(passport_expired, 'passport_expired')
                }
                errorMessage={
                  get(errors, ['passport_expired']) &&
                  i18n(`validation.${get(errors, ['passport_expired'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('pensosbud.move_school_form.form.father_data.title')}
              </div>
              <TextInput
                label={i18n('pensosbud.move_school_form.form.father_data.name.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.father_data.name.placeholder'
                )}
                value={get(form, ['father_name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'father_name')
                }}
                errorMessage={
                  get(errors, ['father_name']) &&
                  i18n(`validation.${get(errors, ['father_name'])}`)
                }
              />
              <DobInput
                i18n={i18n}
                id='personal'
                place={{
                  label: i18n(
                    'pensosbud.move_school_form.form.father_data.dob_place.label'
                  ),
                  placeholder: i18n(
                    'pensosbud.move_school_form.form.father_data.dob_place.placeholder'
                  ),
                  onChange: (father_birth_city) =>
                    handlerChanges(father_birth_city, 'father_birth_city'),
                  value: get(form, ['father_birth_city']),
                  errorMessage:
                    get(errors, ['father_birth_city']) &&
                    i18n(`validation.${get(errors, ['father_birth_city'])}`)
                }}
                date={{
                  label: i18n(
                    'pensosbud.move_school_form.form.father_data.dob_date.label'
                  ),
                  placeholder: i18n(
                    'pensosbud.move_school_form.form.father_data.dob_date.placeholder'
                  ),
                  datepickerProps: { maxDate: new Date() },
                  onChange: (birth_date) =>
                    handlerChanges(birth_date, 'father_birth_date'),
                  value: get(form, ['father_birth_date']),
                  errorMessage:
                    get(errors, ['father_birth_date']) &&
                    i18n(`validation.${get(errors, ['father_birth_date'])}`)
                }}
              />
              <Dropdown
                className='row input-container'
                label={i18n(
                  'pensosbud.move_school_form.form.father_data.nationality.label'
                )}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.father_data.nationality.label'
                )}
                value={get(form, ['father_nationality'])}
                options={country_options}
                onClick={(item) =>
                  handlerChanges(item, 'father_nationality', 'father_nationality.name')
                }
                isValueObject
                dropdownToggleProps={{
                  tag: 'div',
                  className: 'input-dropdown'
                }}
                valueKey='name'
                labelKey='name'
                withAutocomplete
                errorMessage={
                  get(errors, ['father_nationality.name']) &&
                  i18n(`validation.${get(errors, ['father_nationality.name'])}`)
                }
              />
              <RadioButton
                className='passport-type'
                label={i18n(
                  'pensosbud.move_school_form.form.father_data.passport_type.label'
                )}
                options={passport_type_options}
                value={get(form, ['father_passport_type'])}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'father_passport_type')
                }}
                errorMessage={
                  get(errors, ['father_passport_type']) &&
                  i18n(`validation.${get(errors, ['father_passport_type'])}`)
                }
              />
              <TextInput
                label={i18n(
                  'pensosbud.move_school_form.form.father_data.passport_number.label'
                )}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.father_data.passport_number.placeholder'
                )}
                value={get(form, ['father_passport_number'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) =>
                    handlerChanges(e.target.value, 'father_passport_number')
                }}
                errorMessage={
                  get(errors, ['father_passport_number']) &&
                  i18n(`validation.${get(errors, ['father_passport_number'])}`)
                }
              />
              <Datepicker
                label={i18n(
                  'pensosbud.move_school_form.form.father_data.passport_expired.label'
                )}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.father_data.passport_expired.placeholder'
                )}
                className='date_issue-date'
                value={get(form, ['father_passport_expired'])}
                onChange={(father_passport_expired) =>
                  handlerChanges(father_passport_expired, 'father_passport_expired')
                }
                errorMessage={
                  get(errors, ['father_passport_expired']) &&
                  i18n(`validation.${get(errors, ['father_passport_expired'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.father_data.job.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.father_data.job.placeholder'
                )}
                value={get(form, ['father_job'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'father_job')
                }}
                errorMessage={
                  get(errors, ['father_job']) &&
                  i18n(`validation.${get(errors, ['father_job'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('pensosbud.move_school_form.form.mother_data.title')}
              </div>
              <TextInput
                label={i18n('pensosbud.move_school_form.form.mother_data.name.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.mother_data.name.placeholder'
                )}
                value={get(form, ['mother_name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'mother_name')
                }}
                errorMessage={
                  get(errors, ['mother_name']) &&
                  i18n(`validation.${get(errors, ['mother_name'])}`)
                }
              />
              <DobInput
                i18n={i18n}
                id='personal'
                place={{
                  label: i18n(
                    'pensosbud.move_school_form.form.mother_data.dob_place.label'
                  ),
                  placeholder: i18n(
                    'pensosbud.move_school_form.form.mother_data.dob_place.placeholder'
                  ),
                  onChange: (mother_birth_city) =>
                    handlerChanges(mother_birth_city, 'mother_birth_city'),
                  value: get(form, ['mother_birth_city']),
                  errorMessage:
                    get(errors, ['mother_birth_city']) &&
                    i18n(`validation.${get(errors, ['mother_birth_city'])}`)
                }}
                date={{
                  label: i18n(
                    'pensosbud.move_school_form.form.mother_data.dob_date.label'
                  ),
                  placeholder: i18n(
                    'pensosbud.move_school_form.form.mother_data.dob_date.placeholder'
                  ),
                  datepickerProps: { maxDate: new Date() },
                  onChange: (mother_birth_date) =>
                    handlerChanges(mother_birth_date, 'mother_birth_date'),
                  value: get(form, ['mother_birth_date']),
                  errorMessage:
                    get(errors, ['mother_birth_date']) &&
                    i18n(`validation.${get(errors, ['mother_birth_date'])}`)
                }}
              />
              <Dropdown
                className='row input-container'
                label={i18n(
                  'pensosbud.move_school_form.form.mother_data.nationality.label'
                )}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.mother_data.nationality.label'
                )}
                value={get(form, ['mother_nationality'])}
                options={country_options}
                onClick={(item) =>
                  handlerChanges(item, 'mother_nationality', 'mother_nationality.name')
                }
                isValueObject
                dropdownToggleProps={{
                  tag: 'div',
                  className: 'input-dropdown'
                }}
                valueKey='name'
                labelKey='name'
                withAutocomplete
                errorMessage={
                  get(errors, ['mother_nationality.name']) &&
                  i18n(`validation.${get(errors, ['mother_nationality.name'])}`)
                }
              />
              <RadioButton
                className='passport-type'
                label={i18n(
                  'pensosbud.move_school_form.form.mother_data.passport_type.label'
                )}
                options={passport_type_options}
                value={get(form, ['mother_passport_type'])}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'mother_passport_type')
                }}
                errorMessage={
                  get(errors, ['mother_passport_type']) &&
                  i18n(`validation.${get(errors, ['mother_passport_type'])}`)
                }
              />
              <TextInput
                label={i18n(
                  'pensosbud.move_school_form.form.mother_data.passport_number.label'
                )}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.mother_data.passport_number.placeholder'
                )}
                value={get(form, ['mother_passport_number'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) =>
                    handlerChanges(e.target.value, 'mother_passport_number')
                }}
                errorMessage={
                  get(errors, ['mother_passport_number']) &&
                  i18n(`validation.${get(errors, ['mother_passport_number'])}`)
                }
              />
              <Datepicker
                label={i18n(
                  'pensosbud.move_school_form.form.mother_data.passport_expired.label'
                )}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.mother_data.passport_expired.placeholder'
                )}
                className='date_issue-date'
                value={get(form, ['mother_passport_expired'])}
                onChange={(mother_passport_expired) =>
                  handlerChanges(mother_passport_expired, 'mother_passport_expired')
                }
                errorMessage={
                  get(errors, ['mother_passport_expired']) &&
                  i18n(`validation.${get(errors, ['mother_passport_expired'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.mother_data.job.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.mother_data.job.placeholder'
                )}
                value={get(form, ['mother_job'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'mother_job')
                }}
                errorMessage={
                  get(errors, ['mother_job']) &&
                  i18n(`validation.${get(errors, ['mother_job'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('pensosbud.move_school_form.form.school_info')}
              </div>
              <TextInput
                label={i18n('pensosbud.move_school_form.form.school_name.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.school_name.placeholder'
                )}
                value={get(form, ['school_name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'school_name')
                }}
                errorMessage={
                  get(errors, ['school_name']) &&
                  i18n(`validation.${get(errors, ['school_name'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.school_address.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.school_address.placeholder'
                )}
                value={get(form, ['school_address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'school_address')
                }}
                errorMessage={
                  get(errors, ['school_address']) &&
                  i18n(`validation.${get(errors, ['school_address'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.kelas_terakhir.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.kelas_terakhir.placeholder'
                )}
                value={get(form, ['kelas_terakhir'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'kelas_terakhir')
                }}
                errorMessage={
                  get(errors, ['kelas_terakhir']) &&
                  i18n(`validation.${get(errors, ['kelas_terakhir'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.reason.label')}
                placeholder={i18n('pensosbud.move_school_form.form.reason.placeholder')}
                value={get(form, ['reason'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'reason')
                }}
                errorMessage={
                  get(errors, ['reason']) && i18n(`validation.${get(errors, ['reason'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.diterima_dikelas.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.diterima_dikelas.placeholder'
                )}
                value={get(form, ['diterima_dikelas'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'diterima_dikelas')
                }}
                errorMessage={
                  get(errors, ['diterima_dikelas']) &&
                  i18n(`validation.${get(errors, ['diterima_dikelas'])}`)
                }
                detailInput={i18n(
                  'pensosbud.move_school_form.form.diterima_dikelas.detail'
                )}
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('pensosbud.move_school_form.form.address')}
              </div>
              <TextInput
                label={i18n('pensosbud.move_school_form.form.american_address.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.american_address.placeholder'
                )}
                value={get(form, ['american_address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'american_address')
                }}
                errorMessage={
                  get(errors, ['american_address']) &&
                  i18n(`validation.${get(errors, ['american_address'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.indo_address.label')}
                placeholder={i18n(
                  'pensosbud.move_school_form.form.indo_address.placeholder'
                )}
                value={get(form, ['indo_address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'indo_address')
                }}
                errorMessage={
                  get(errors, ['indo_address']) &&
                  i18n(`validation.${get(errors, ['indo_address'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.email.label')}
                placeholder={i18n('pensosbud.move_school_form.form.email.placeholder')}
                value={get(form, ['email'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'email')
                }}
                errorMessage={
                  get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.move_school_form.form.phone.label')}
                placeholder={i18n('pensosbud.move_school_form.form.phone.placeholder')}
                prependText='+62'
                value={get(form, ['id_phone'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'id_phone')
                }}
                errorMessage={
                  get(errors, ['id_phone']) &&
                  i18n(`validation.${get(errors, ['id_phone'])}`)
                }
              />
            </div>
          </div>

          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.push('/pensosbud')}>
              {i18n('cta.cancel')}
            </Button>
            <Button color='primary' type='submit' className='pass-btn'>
              {i18n('cta.next')}
            </Button>
          </div>
        </form>
      </CardDetail>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

MoveSchoolForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  country: PropTypes.object,
  getCountryList: PropTypes.func,
  getSosBudLetter: PropTypes.func,
  letterRegisDetail: PropTypes.object,
  postSosBudSubmission: PropTypes.func,
  submission: PropTypes.object,
  resetPostSubmission: PropTypes.func
};

const mapStateToProps = (state) => ({
  country: {
    status: state.countryListReducer.status,
    error: state.countryListReducer.error,
    data: state.countryListReducer.data
  },
  letterRegisDetail: {
    status: state.sosBudLetterDetailReducer.status,
    error: state.sosBudLetterDetailReducer.error,
    data: state.sosBudLetterDetailReducer.data
  },
  submission: {
    status: state.sosBudSubmissionReducer.status,
    error: state.sosBudSubmissionReducer.error,
    data: state.sosBudSubmissionReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getCountryList,
    getSosBudLetter,
    postSosBudSubmission,
    resetPostSubmission
  }),
  withRouter
)(MoveSchoolForm);
