const defaultData = {
  data: [],
  status: 'idle',
  error: ''
};

const sosBudServiceListReducer = (state = defaultData, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_SERVICE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_SERVICE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.sosBudService
      };
    case 'GET_SOSBUD_SERVICE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.sosBudService.data.data
      };
    default:
      return state;
  }
};

const sosBudLetterReducer = (state = { ...defaultData, data: {} }, action) => {
  switch (action.type) {
    case 'POST_SOSBUD_LETTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_SOSBUD_LETTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.sosBudLetter
      };
    case 'POST_SOSBUD_LETTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.sosBudLetter.data.data
      };
    case 'RESET_NUMBER_REGISTER':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};
const sosBudLetterDetailReducer = (state = { ...defaultData, data: {} }, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_LETTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_LETTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.sosBudLetter
      };
    case 'GET_SOSBUD_LETTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.sosBudLetter.data.data
      };
    case 'RESET_DETAIL_REGISTER':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const sosBudEducationReducer = (state = { ...defaultData, data: {} }, action) => {
  switch (action.type) {
    case 'POST_SOSBUD_EDUCATION_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_SOSBUD_EDUCATION_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.sosBudEducation
      };
    case 'POST_SOSBUD_EDUCATION_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.sosBudEducation.data.data
      };
    default:
      return state;
  }
};

const sosBudAlumniReducer = (state = { ...defaultData, data: {} }, action) => {
  switch (action.type) {
    case 'POST_SOSBUD_ALUMNI_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_SOSBUD_ALUMNI_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.sosBudAlumni
      };
    case 'POST_SOSBUD_ALUMNI_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.sosBudAlumni.data.data
      };
    default:
      return state;
  }
};

const sosBudListServeReducer = (state = { ...defaultData, data: {} }, action) => {
  switch (action.type) {
    case 'POST_SOSBUD_LISTSERVE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_SOSBUD_LISTSERVE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.sosBudListServe
      };
    case 'POST_SOSBUD_LISTSERVE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.sosBudListServe.data.data
      };
    default:
      return state;
  }
};

const sosBudSubmissionReducer = (state = { ...defaultData, data: {} }, action) => {
  switch (action.type) {
    case 'POST_SOSBUD_SUBMISSION_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_SOSBUD_SUBMISSION_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.sosBudListServe
      };
    case 'POST_SOSBUD_SUBMISSION_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.sosBudListServe.data.data
      };
    case 'RESET_SOSBUD_SUBMISSION':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export {
  sosBudServiceListReducer,
  sosBudLetterReducer,
  sosBudEducationReducer,
  sosBudAlumniReducer,
  sosBudListServeReducer,
  sosBudLetterDetailReducer,
  sosBudSubmissionReducer
};
