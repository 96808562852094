import { apiCaller } from '../../../helpers/apiCaller';

// SOSBUD REGISTER
const getSosBudRegisterListRequest = () => ({
  type: 'GET_SOSBUD_REGISTER_LIST_REQUEST'
});

const getSosBudRegisterListSuccess = (data) => ({
  type: 'GET_SOSBUD_REGISTER_LIST_SUCCESS',
  payload: data
});

const getSosBudRegisterListFailure = (error) => ({
  type: 'GET_SOSBUD_REGISTER_LIST_FAILURE',
  payload: error
});

const resetSosBudRegisterList = () => ({
  type: 'RESET_SOSBUD_REGISTER_LIST'
});

const getSosBudRegisterList = (params) => {
  return (dispatch) => {
    dispatch(getSosBudRegisterListRequest());
    return apiCaller('admin/pensosbud/registration', 'get', undefined, true, params)
      .then((data) => dispatch(getSosBudRegisterListSuccess(data)))
      .catch((error) => dispatch(getSosBudRegisterListFailure(error)));
  };
};

// SOSBUD SUBMISSION
const getSosBudSubmissionListRequest = () => ({
  type: 'GET_SOSBUD_SUBMISSION_LIST_REQUEST'
});

const getSosBudSubmissionListSuccess = (data) => ({
  type: 'GET_SOSBUD_SUBMISSION_LIST_SUCCESS',
  payload: data
});

const getSosBudSubmissionListFailure = (error) => ({
  type: 'GET_SOSBUD_SUBMISSION_LIST_FAILURE',
  payload: error
});

const resetSosBudSubmissionList = () => ({
  type: 'RESET_SOSBUD_SUBMISSION_LIST'
});

const getSosBudSubmissionList = (params) => {
  return (dispatch) => {
    dispatch(getSosBudSubmissionListRequest());
    return apiCaller('v2/admin/pensosbud/submission', 'get', undefined, true, params)
      .then((data) => dispatch(getSosBudSubmissionListSuccess(data)))
      .catch((error) => dispatch(getSosBudSubmissionListFailure(error)));
  };
};

// SOSBUD REGISTER DETAIL
const getSosBudRegisterDetailRequest = () => ({
  type: 'GET_SOSBUD_REGISTER_DETAIL_REQUEST'
});

const getSosBudRegisterDetailSuccess = (data) => ({
  type: 'GET_SOSBUD_REGISTER_DETAIL_SUCCESS',
  payload: data
});

const getSosBudRegisterDetailFailure = (error) => ({
  type: 'GET_SOSBUD_REGISTER_DETAIL_FAILURE',
  payload: error
});

const getSosBudRegisterDetail = (id) => {
  return (dispatch) => {
    dispatch(getSosBudRegisterDetailRequest());
    return apiCaller(`pensosbud/registration/${id}`, 'get')
      .then((data) => dispatch(getSosBudRegisterDetailSuccess(data)))
      .catch((error) => dispatch(getSosBudRegisterDetailFailure(error)));
  };
};

// SOSBUD SUBMISSION DETAIL
const getSosBudSubmissionDetailRequest = () => ({
  type: 'GET_SOSBUD_SUBMISSION_DETAIL_REQUEST'
});

const getSosBudSubmissionDetailSuccess = (data) => ({
  type: 'GET_SOSBUD_SUBMISSION_DETAIL_SUCCESS',
  payload: data
});

const getSosBudSubmissionDetailFailure = (error) => ({
  type: 'GET_SOSBUD_SUBMISSION_DETAIL_FAILURE',
  payload: error
});

const getSosBudSubmissionDetail = (id) => {
  return (dispatch) => {
    dispatch(getSosBudSubmissionDetailRequest());
    return apiCaller(`pensosbud/submission/${id}`, 'get')
      .then((data) => dispatch(getSosBudSubmissionDetailSuccess(data)))
      .catch((error) => dispatch(getSosBudSubmissionDetailFailure(error)));
  };
};

// APPROVE
const patchApproveRequest = () => ({
  type: 'PATCH_SOSBUD_APPROVE_REQUEST'
});

const patchApproveSuccess = (data) => ({
  type: 'PATCH_SOSBUD_APPROVE_SUCCESS',
  payload: data
});

const patchApproveFailure = (error) => ({
  type: 'PATCH_SOSBUD_APPROVE_FAILURE',
  payload: error
});

const patchApprove = (id) => {
  return (dispatch) => {
    dispatch(patchApproveRequest());
    return apiCaller(`admin/pensosbud/approve/${id}`, 'patch', undefined, true)
      .then((data) => dispatch(patchApproveSuccess(data)))
      .catch((error) => dispatch(patchApproveFailure(error)));
  };
};

// REJECT
const patchRejectRequest = () => ({
  type: 'PATCH_SOSBUD_REJECT_REQUEST'
});

const patchRejectSuccess = (data) => ({
  type: 'PATCH_SOSBUD_REJECT_SUCCESS',
  payload: data
});

const patchRejectFailure = (error) => ({
  type: 'PATCH_SOSBUD_REJECT_FAILURE',
  payload: error
});

const patchReject = (id, params) => {
  return (dispatch) => {
    dispatch(patchRejectRequest());
    return apiCaller(`admin/pensosbud/reject/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchRejectSuccess(data)))
      .catch((error) => dispatch(patchRejectFailure(error)));
  };
};

// PROCESS
const patchProcessRequest = () => ({
  type: 'PATCH_SOSBUD_PROCESS_REQUEST'
});

const patchProcessSuccess = (data) => ({
  type: 'PATCH_SOSBUD_PROCESS_SUCCESS',
  payload: data
});

const patchProcessFailure = (error) => ({
  type: 'PATCH_SOSBUD_PROCESS_FAILURE',
  payload: error
});

const patchProcess = (id, params) => {
  return (dispatch) => {
    dispatch(patchProcessRequest());
    return apiCaller(`admin/pensosbud/process/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchProcessSuccess(data)))
      .catch((error) => dispatch(patchProcessFailure(error)));
  };
};

// PENDING
const patchPendingRequest = () => ({
  type: 'PATCH_SOSBUD_PENDING_REQUEST'
});

const patchPendingSuccess = (data) => ({
  type: 'PATCH_SOSBUD_PENDING_SUCCESS',
  payload: data
});

const patchPendingFailure = (error) => ({
  type: 'PATCH_SOSBUD_PENDING_FAILURE',
  payload: error
});

const patchPending = (id, params) => {
  return (dispatch) => {
    dispatch(patchPendingRequest());
    return apiCaller(`admin/pensosbud/pending/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchPendingSuccess(data)))
      .catch((error) => dispatch(patchPendingFailure(error)));
  };
};

// DAY
const getStatisticRequest = () => ({
  type: 'GET_SOSBUD_STATISTIC_REQUEST'
});

const getStatisticSuccess = (data) => ({
  type: 'GET_SOSBUD_STATISTIC_SUCCESS',
  payload: data
});

const getStatisticFailure = (error) => ({
  type: 'GET_SOSBUD_STATISTIC_FAILURE',
  payload: error
});

const getStatistic = (params) => {
  return (dispatch) => {
    dispatch(getStatisticRequest());
    return apiCaller('admin/pensosbud/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticSuccess(data)))
      .catch((error) => dispatch(getStatisticFailure(error)));
  };
};

// WEEK
const getStatisticWeekRequest = () => ({
  type: 'GET_SOSBUD_STATISTIC_WEEK_REQUEST'
});

const getStatisticWeekSuccess = (data) => ({
  type: 'GET_SOSBUD_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getStatisticWeekFailure = (error) => ({
  type: 'GET_SOSBUD_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getStatisticWeek = (params) => {
  return (dispatch) => {
    dispatch(getStatisticWeekRequest());
    return apiCaller('admin/pensosbud/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getStatisticWeekFailure(error)));
  };
};

// MONTH
const getStatisticMonthRequest = () => ({
  type: 'GET_SOSBUD_STATISTIC_MONTH_REQUEST'
});

const getStatisticMonthSuccess = (data) => ({
  type: 'GET_SOSBUD_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getStatisticMonthFailure = (error) => ({
  type: 'GET_SOSBUD_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getStatisticMonth = (params) => {
  return (dispatch) => {
    dispatch(getStatisticMonthRequest());
    return apiCaller('admin/pensosbud/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getStatisticMonthFailure(error)));
  };
};

// ALL
const getStatisticAllRequest = () => ({
  type: 'GET_SOSBUD_STATISTIC_ALL_REQUEST'
});

const getStatisticAllSuccess = (data) => ({
  type: 'GET_SOSBUD_STATISTIC_ALL_SUCCESS',
  payload: data
});

const getStatisticAllFailure = (error) => ({
  type: 'GET_SOSBUD_STATISTIC_ALL_FAILURE',
  payload: error
});

const getStatisticAll = (params) => {
  return (dispatch) => {
    dispatch(getStatisticAllRequest());
    return apiCaller('admin/pensosbud/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticAllSuccess(data)))
      .catch((error) => dispatch(getStatisticAllFailure(error)));
  };
};

export {
  getSosBudRegisterList,
  resetSosBudRegisterList,
  resetSosBudSubmissionList,
  getSosBudSubmissionList,
  getSosBudRegisterDetail,
  getSosBudSubmissionDetail,
  patchApprove,
  patchReject,
  patchProcess,
  patchPending,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth,
  getStatisticAll
};
