import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';
import Loading from '../../../components/Loading';

import ShortVisit from './ShortVisit';
import OnArrival from './OnArrival';
import Tourist from './Tourist';
import SocioCulture from './SocioCulture';
import BusinessSingle from './BusinessSingle';
import BusinessMultiple from './BusinessMultiple';
import LimitedStay from './LimitedStay';
import Diplomatic from './Diplomatic';
import PreClearance from './PreClearance';
import Journalist from './Journalist';

import { getVisaService } from '../../form/VisaForm/action';

import './style.scss';

const Visa = ({ history, i18n, visaService, getVisaService }) => {
  const visaServiceList = get(visaService, ['data']);
  const serviceStatus = get(visaService, ['status']);
  const loadingService = serviceStatus !== 'resolve';
  const sideBarList = [
    {
      label: i18n('visa_page.tab1.title'),
      link: '/visa',
      Component: ShortVisit
    },
    {
      label: i18n('visa_page.tab2.title'),
      link: '/visa/onarrival',
      Component: OnArrival
    },
    {
      label: i18n('visa_page.tab3.title'),
      link: '/visa/tourist',
      Component: Tourist
    },
    {
      label: i18n('visa_page.tab4.title'),
      link: '/visa/socioculture',
      Component: SocioCulture
    },
    {
      label: i18n('visa_page.tab5.title'),
      link: '/visa/business-single',
      Component: BusinessSingle
    },
    {
      label: i18n('visa_page.tab6.title'),
      link: '/visa/business-multiple',
      Component: BusinessMultiple
    },
    {
      label: i18n('visa_page.tab7.title'),
      link: '/visa/limited-stay',
      Component: LimitedStay
    },
    {
      label: i18n('visa_page.tab8.title'),
      link: '/visa/diplomatic',
      Component: Diplomatic
    },
    {
      label: i18n('visa_page.tab9.title'),
      link: '/visa/pre-clearance',
      Component: PreClearance
    },
    {
      label: i18n('visa_page.tab10.title'),
      link: '/visa/journalist',
      Component: Journalist
    }
  ];

  const activePath = get(history, ['location', 'pathname']);

  useEffect(() => {
    getVisaService();
  }, [getVisaService]);

  return (
    <>
      <Banner
        title={i18n('visa_page.banner.title')}
        subtitle={
          <div
            className='desc'
            dangerouslySetInnerHTML={{ __html: i18n('visa_page.banner.desc') }}
          />
        }
        backButtonClick={() => history.goBack()}
      />
      {loadingService && <Loading />}
      <CardSideBar
        activePath={activePath}
        sideBarList={sideBarList}
        visaServiceList={visaServiceList}
      />
    </>
  );
};

Visa.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  visaService: PropTypes.object,
  getVisaService: PropTypes.func
};

const mapStateToProps = (state) => ({
  visaService: {
    status: state.visaServiceReducer.status,
    error: state.visaServiceReducer.error,
    data: state.visaServiceReducer.data
  }
});

export default compose(connect(mapStateToProps, { getVisaService }), withRouter)(Visa);
