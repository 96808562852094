import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import * as yup from 'yup';
import { Alert } from 'reactstrap';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../../../helpers/functions';

import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/input/TextInput';
import RadioButton from '../../../../../components/input/RadioButton';
import Datepicker from '../../../../../components/input/Datepicker';
import Loading from '../../../../../components/Loading';
import Modal from '../../../../../components/Modal';
import Icon from '../../../../../components/Icon';

import { postSosBudAlumni } from '../../action';

import './style.scss';

const IACSAlumniForm = ({ history, i18n, serviceId, postSosBudAlumni, sosBudAlumni }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const scholarshipOptions = [
    { value: 'DARMASISWA', name: i18n('pensosbud.tab10.form.scholarship.opt1') },
    { value: 'BSBI', name: i18n('pensosbud.tab10.form.scholarship.opt2') }
  ];
  const { data, error, status } = sosBudAlumni;

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      name: yup.string().required('required'),
      scholarship: yup.string().required('required'),
      scholarship_period: yup.string().required('required'),
      city: yup.string().required('required'),
      dob: yup.date().required('required'),
      occupanci: yup.string().required('required'),
      address: yup.string().required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      email: yup.string().email('email').required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        postSosBudAlumni({
          name: form.name,
          email: form.email,
          phone: form.phone,
          service_id: serviceId,
          birth_date: moment(form.dob).format('YYYY-MM-DD'),
          scholarship_type: form.scholarship,
          scholarship_month: parseInt(moment(form.scholarship_period).format('MM')),
          scholarship_year: parseInt(moment(form.scholarship_period).format('YYYY')),
          study_city: form.city,
          job: form.occupanci,
          address: form.address
        });
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
      const messageDetail = get(error, [
        'response',
        'data',
        'error',
        'detail',
        0,
        'message'
      ]);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      errorAlertScroll();
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // Modal
  let modalProps = {
    buttonProps: [
      {
        btnStyle: { color: 'primary' },
        label: i18n('cta.ok'),
        onClick: () => {
          setForm({});
          setShowModal(false);
        }
      }
    ],
    className: 'success',
    title: (
      <div className='top-wrap'>
        <Icon icon='green-check' />
        <div>{i18n('success_save_modal.title')}</div>
      </div>
    ),
    desc: (
      <div
        style={{
          marginBottom: '16px'
        }}
      >
        {i18n('thankyou_modal.title')}
      </div>
    )
  };

  return (
    <>
      <form onSubmit={handlerSubmit} id='iacs-alumni'>
        {status === 'pending' && <Loading />}

        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        <div className='form-wrap'>
          <TextInput
            label={i18n('pensosbud.tab10.form.name.label')}
            placeholder={i18n('pensosbud.tab10.form.name.placeholder')}
            value={get(form, ['name'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'name')
            }}
            errorMessage={
              get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
            }
          />

          <RadioButton
            label={i18n('pensosbud.tab10.form.scholarship.label')}
            options={scholarshipOptions}
            value={get(form, ['scholarship'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'scholarship')
            }}
            errorMessage={
              get(errors, ['scholarship']) &&
              i18n(`validation.${get(errors, ['scholarship'])}`)
            }
          />
          <Datepicker
            label={i18n('pensosbud.tab10.form.scholarship_period.label')}
            placeholder={i18n('pensosbud.tab10.form.scholarship_period.placeholder')}
            className='scholarship_period'
            value={get(form, ['scholarship_period'])}
            onChange={(scholarship_period) =>
              handlerChanges(scholarship_period, 'scholarship_period')
            }
            errorMessage={
              get(errors, ['scholarship_period']) &&
              i18n(`validation.${get(errors, ['scholarship_period'])}`)
            }
            datepickerProps={{
              showMonthYearPicker: true
            }}
            dateFormat='MM/yyyy'
          />

          <div className='col2'>
            <TextInput
              label={i18n('pensosbud.tab10.form.city.label')}
              placeholder={i18n('pensosbud.tab10.form.city.placeholder')}
              value={get(form, ['city'])}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'city')
              }}
              errorMessage={
                get(errors, ['city']) && i18n(`validation.${get(errors, ['city'])}`)
              }
            />
            <Datepicker
              label={i18n('pensosbud.tab10.form.dob.label')}
              placeholder={i18n('pensosbud.tab10.form.dob.placeholder')}
              className='dob'
              value={get(form, ['dob'])}
              onChange={(dob) => handlerChanges(dob, 'dob')}
              errorMessage={
                get(errors, ['dob']) && i18n(`validation.${get(errors, ['dob'])}`)
              }
            />
          </div>

          <TextInput
            label={i18n('pensosbud.tab10.form.occupanci.label')}
            placeholder={i18n('pensosbud.tab10.form.occupanci.placeholder')}
            value={get(form, ['occupanci'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'occupanci')
            }}
            errorMessage={
              get(errors, ['occupanci']) &&
              i18n(`validation.${get(errors, ['occupanci'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab10.form.address.label')}
            placeholder={i18n('pensosbud.tab10.form.address.placeholder')}
            value={get(form, ['address'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'address')
            }}
            errorMessage={
              get(errors, ['address']) && i18n(`validation.${get(errors, ['address'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab10.form.phone.label')}
            placeholder={i18n('pensosbud.tab10.form.phone.placeholder')}
            value={get(form, ['phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'phone')
            }}
            errorMessage={
              get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab10.form.email.label')}
            placeholder={i18n('pensosbud.tab10.form.email.placeholder')}
            value={get(form, ['email'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'email')
            }}
            errorMessage={
              get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
            }
          />
        </div>

        <div className='btn-wrap'>
          <Button color='primary' type='submit' className='pass-btn'>
            {i18n('pensosbud.tab10.form.cta')}
          </Button>
        </div>
      </form>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

IACSAlumniForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  serviceId: PropTypes.string,
  postSosBudAlumni: PropTypes.func,
  sosBudAlumni: PropTypes.object
};

const mapStateToProps = (state) => ({
  sosBudAlumni: {
    status: state.sosBudAlumniReducer.status,
    error: state.sosBudAlumniReducer.error,
    data: state.sosBudAlumniReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postSosBudAlumni
  }),
  withRouter
)(IACSAlumniForm);
