const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getInvestmentQuestionListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_INVESTMENT_QUESTION_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INVESTMENT_QUESTION_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INVESTMENT_QUESTION_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_INVESTMENT_QUESTION_LIST_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getInvestmentQuestionDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_INVESTMENT_QUESTION_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INVESTMENT_QUESTION_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INVESTMENT_QUESTION_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_INVESTMENT_QUESTION_DETAIL_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getTradeQuestionListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_TRADE_QUESTION_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TRADE_QUESTION_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_TRADE_QUESTION_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_TRADE_QUESTION_LIST_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getTradeQuestionDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_TRADE_QUESTION_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TRADE_QUESTION_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_TRADE_QUESTION_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_TRADE_QUESTION_DETAIL_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getHubIndoStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_HUB_INDO_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_HUB_INDO_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_HUB_INDO_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getHubIndoStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_HUB_INDO_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_HUB_INDO_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_HUB_INDO_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getHubIndoStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_HUB_INDO_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_HUB_INDO_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_HUB_INDO_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

export {
  getInvestmentQuestionListReducer,
  getInvestmentQuestionDetailReducer,
  getTradeQuestionListReducer,
  getTradeQuestionDetailReducer,
  getHubIndoStatisticReducer,
  getHubIndoStatisticWeekReducer,
  getHubIndoStatisticMonthReducer
};
