const news = {
  data: {},
  status: 'idle',
  error: ''
};

const newNews = {
  data: {},
  status: 'idle',
  error: ''
};

const newsDetail = {
  data: {},
  status: 'idle',
  error: ''
};

const deleteNews = {
  data: {},
  status: 'idle',
  error: ''
};

const updateNews = {
  data: {},
  status: 'idle',
  error: ''
};

const newsReducerAdmin = (state = news, action) => {
  switch (action.type) {
    case 'GET_NEWS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NEWS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_NEWS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const newNewsReducerAdmin = (state = newNews, action) => {
  switch (action.type) {
    case 'POST_NEWS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_NEWS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_NEWS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const newsDetailReducerAdmin = (state = newsDetail, action) => {
  switch (action.type) {
    case 'GET_NEWS_DETAIL_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NEWS_DETAIL_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_NEWS_DETAIL_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data[0]
      };
    default:
      return state;
  }
};

const deleteNewsReducerAdmin = (state = deleteNews, action) => {
  switch (action.type) {
    case 'DELETE_NEWS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_NEWS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_NEWS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const updateNewsReducerAdmin = (state = updateNews, action) => {
  switch (action.type) {
    case 'UPDATE_NEWS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'UPDATE_NEWS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'UPDATE_NEWS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

export {
  newsReducerAdmin,
  newNewsReducerAdmin,
  newsDetailReducerAdmin,
  deleteNewsReducerAdmin,
  updateNewsReducerAdmin
};
