import { apiCaller } from '../../../../helpers/apiCaller';

// INVESTMENT INQUIRY
const getInvestmentInquiryListRequest = () => ({
  type: 'GET_INVESTMENT_INQUIRY_LIST_REQUEST'
});

const getInvestmentInquiryListSuccess = (data) => ({
  type: 'GET_INVESTMENT_INQUIRY_LIST_SUCCESS',
  payload: data
});

const getInvestmentInquiryListFailure = (error) => ({
  type: 'GET_INVESTMENT_INQUIRY_LIST_FAILURE',
  payload: error
});

const resetInvestmentInquiryList = () => ({
  type: 'RESET_INVESTMENT_INQUIRY_LIST_LIST'
});

const getInvestmentInquiryList = (params) => {
  return (dispatch) => {
    dispatch(getInvestmentInquiryListRequest());
    return apiCaller('admin/investment/inquiry', 'get', undefined, true, params)
      .then((data) => dispatch(getInvestmentInquiryListSuccess(data)))
      .catch((error) => dispatch(getInvestmentInquiryListFailure(error)));
  };
};

// INVESTMENT INQUIRY DETAIL
const getInvestmentInquiryDetailRequest = () => ({
  type: 'GET_INVESTMENT_INQUIRY_DETAIL_REQUEST'
});

const getInvestmentInquiryDetailSuccess = (data) => ({
  type: 'GET_INVESTMENT_INQUIRY_DETAIL_SUCCESS',
  payload: data
});

const getInvestmentInquiryDetailFailure = (error) => ({
  type: 'GET_INVESTMENT_INQUIRY_DETAIL_FAILURE',
  payload: error
});

const resetInvestmentInquiryDetail = () => ({
  type: 'RESET_INVESTMENT_INQUIRY_DETAIL_LIST'
});

const getInvestmentInquiryDetail = (id) => {
  return (dispatch) => {
    dispatch(getInvestmentInquiryDetailRequest());
    return apiCaller(`investment/inquiry/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getInvestmentInquiryDetailSuccess(data)))
      .catch((error) => dispatch(getInvestmentInquiryDetailFailure(error)));
  };
};

// TRADE INQUIRY
const getTradeInquiryListRequest = () => ({
  type: 'GET_TRADE_INQUIRY_LIST_REQUEST'
});

const getTradeInquiryListSuccess = (data) => ({
  type: 'GET_TRADE_INQUIRY_LIST_SUCCESS',
  payload: data
});

const getTradeInquiryListFailure = (error) => ({
  type: 'GET_TRADE_INQUIRY_LIST_FAILURE',
  payload: error
});

const resetTradeInquiryList = () => ({
  type: 'RESET_TRADE_INQUIRY_LIST_LIST'
});

const getTradeInquiryList = (params) => {
  return (dispatch) => {
    dispatch(getTradeInquiryListRequest());
    return apiCaller('admin/trade/inquiry', 'get', undefined, true, params)
      .then((data) => dispatch(getTradeInquiryListSuccess(data)))
      .catch((error) => dispatch(getTradeInquiryListFailure(error)));
  };
};

// TRADE INQUIRY DETAIL
const getTradeInquiryDetailRequest = () => ({
  type: 'GET_TRADE_INQUIRY_DETAIL_REQUEST'
});

const getTradeInquiryDetailSuccess = (data) => ({
  type: 'GET_TRADE_INQUIRY_DETAIL_SUCCESS',
  payload: data
});

const getTradeInquiryDetailFailure = (error) => ({
  type: 'GET_TRADE_INQUIRY_DETAIL_FAILURE',
  payload: error
});

const resetTradeInquiryDetail = () => ({
  type: 'RESET_TRADE_INQUIRY_DETAIL_LIST'
});

const getTradeInquiryDetail = (id) => {
  return (dispatch) => {
    dispatch(getTradeInquiryDetailRequest());
    return apiCaller(`trade/inquiry/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getTradeInquiryDetailSuccess(data)))
      .catch((error) => dispatch(getTradeInquiryDetailFailure(error)));
  };
};

// DAY
const getStatisticRequest = () => ({
  type: 'GET_BISNIS_STATISTIC_REQUEST'
});

const getStatisticSuccess = (data) => ({
  type: 'GET_BISNIS_STATISTIC_SUCCESS',
  payload: data
});

const getStatisticFailure = (error) => ({
  type: 'GET_BISNIS_STATISTIC_FAILURE',
  payload: error
});

const getStatistic = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticRequest());
    return apiCaller(`admin/${type}/inquiry/statistic`, 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticSuccess(data)))
      .catch((error) => dispatch(getStatisticFailure(error)));
  };
};

// WEEK
const getStatisticWeekRequest = () => ({
  type: 'GET_BISNIS_STATISTIC_WEEK_REQUEST'
});

const getStatisticWeekSuccess = (data) => ({
  type: 'GET_BISNIS_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getStatisticWeekFailure = (error) => ({
  type: 'GET_BISNIS_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getStatisticWeek = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticWeekRequest());
    return apiCaller(`admin/${type}/inquiry/statistic`, 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getStatisticWeekFailure(error)));
  };
};

// MONTH
const getStatisticMonthRequest = () => ({
  type: 'GET_BISNIS_STATISTIC_MONTH_REQUEST'
});

const getStatisticMonthSuccess = (data) => ({
  type: 'GET_BISNIS_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getStatisticMonthFailure = (error) => ({
  type: 'GET_BISNIS_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getStatisticMonth = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticMonthRequest());
    return apiCaller(`admin/${type}/inquiry/statistic`, 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getStatisticMonthFailure(error)));
  };
};

export {
  resetInvestmentInquiryList,
  getInvestmentInquiryList,
  resetInvestmentInquiryDetail,
  getInvestmentInquiryDetail,
  resetTradeInquiryList,
  getTradeInquiryList,
  resetTradeInquiryDetail,
  getTradeInquiryDetail,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
};
