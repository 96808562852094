import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../components/Icon';

import './style.scss';

const MiniCard = ({ detail }) => {
  const { title, onClick, jam_buka, time_target, kuota } = detail || {};

  return (
    <div className='antrian-card-container'>
      <div className='head'>
        <div className='col1'>
          <div className='title'>{title}</div>
        </div>
        <span className='edit-settings' onClick={() => onClick()}>
          Edit Pengaturan <Icon icon='chevron' />
        </span>
      </div>
      <div className='detail-container'>
        {jam_buka && (
          <div className='col1'>
            <div className='type'>Jam Buka</div>
            <div className='count'>{jam_buka}</div>
            <div className='unit'>AM</div>
          </div>
        )}
        {time_target && (
          <div className='col1'>
            <div className='type'>Time Target/hari</div>
            <div className='count'>{time_target}</div>
            <div className='unit'>Menit</div>
          </div>
        )}
        {kuota && (
          <div className='col1'>
            <div className='type'>Max. Kuota/hari</div>
            <div className='count'>{kuota}</div>
            <div className='unit'>Pemohon</div>
          </div>
        )}
      </div>
    </div>
  );
};

MiniCard.propTypes = {
  detail: PropTypes.object,
  i18n: PropTypes.func
};

export default MiniCard;
