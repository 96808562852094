import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import './style.scss';

const CardDetail = ({ title, description, children, className = '', rightInfo }) => {
  return (
    <Container className={`card-detail-container ${className}`}>
      <div className='header'>
        <div className='title'>
          {title}
          {rightInfo && <div className='right-info'>{rightInfo}</div>}
        </div>
        {description && <div className='desc'>{description}</div>}
      </div>
      <main>{children}</main>
    </Container>
  );
};

CardDetail.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string
};

export default withRouter(CardDetail);
