import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button';
import DocumentReview from './components/DocumentReview';

import { getQuery, getPassportServiceDetail } from '../function';
import {
  getRegistrationDetail,
  getPassportServiceList
} from '../../../form/PassportForm/action';

import './style.scss';

const ReviewPassportDoc = ({
  i18n,
  history,
  getRegistrationDetail,
  registerDetail,
  getPassportServiceList,
  passportService
}) => {
  const { uniqueId, serviceId } = getQuery(history);
  const data = get(registerDetail, ['data']);
  const linkPdfDownload = get(registerDetail, ['data', 'pdf']);
  const passportServiceDetail = getPassportServiceDetail(
    get(passportService, ['data']),
    serviceId
  );
  const passportServicePath = get(passportServiceDetail, ['route']);
  const instuctionList = get(passportServiceDetail, ['instructionList']) || [];
  const instuctionPrice = get(passportServiceDetail, ['price']) || 24;

  useEffect(() => {
    getRegistrationDetail(`/${uniqueId}`);
    getPassportServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='review-passport-container'>
      <div className='review-wrap'>
        <div className='title'>{i18n('review_registration_doc')}</div>
        <DocumentReview i18n={i18n} data={data} />
        <div className='btn-wrap'>
          <a
            href={linkPdfDownload}
            target='_blank'
            without
            rel='noopener noreferrer'
            className='the-btn  btn btn-primary'
          >
            <Icon icon='print-white' />
            {i18n('cta.print_report')}
          </a>
        </div>
      </div>
      <div className='next-step-wrap'>
        <div className='card-white'>
          <div className='top'>
            <div className='title'>{i18n('next_step')}</div>
            <div className='instruction'>{i18n('paspor_page.tab1.step.instruction')}</div>
          </div>
          <ol>
            {instuctionList.map((list, idx) => (
              <li
                key={idx}
                dangerouslySetInnerHTML={{
                  __html: i18n(`paspor_page.instruction_step.${list}`, {
                    price: instuctionPrice
                  })
                }}
              />
            ))}
          </ol>
        </div>
        <div className='btn-wrap'>
          <Button
            color='primary'
            className='pass-btn'
            onClick={() => history.push(`/passpor${passportServicePath}`)}
          >
            {i18n('cta.back_prev_page')}
          </Button>
        </div>
      </div>

      <div className='helper-btn'>
        <a
          href={linkPdfDownload}
          target='_blank'
          without
          rel='noopener noreferrer'
          className='the-btn  btn btn-primary'
        >
          <Icon icon='print-white' />
          {i18n('cta.print_report')}
        </a>
        <Button
          color='primary'
          outline
          onClick={() => history.push(`/passpor${passportServicePath}`)}
        >
          <Icon icon='next-checklist' />
          {i18n('cta.next_checklist')}
        </Button>
      </div>
    </div>
  );
};

ReviewPassportDoc.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  getRegistrationDetail: PropTypes.func,
  registerDetail: PropTypes.object,
  getPassportServiceList: PropTypes.func,
  passportService: PropTypes.object
};

const mapStateToProps = (state) => ({
  registerDetail: {
    status: state.registrationDetailReducer.status,
    error: state.registrationDetailReducer.error,
    data: state.registrationDetailReducer.data
  },
  passportService: {
    status: state.passportServiceReducer.status,
    error: state.passportServiceReducer.error,
    data: state.passportServiceReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getRegistrationDetail,
    getPassportServiceList
  }),
  withRouter
)(ReviewPassportDoc);
