import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Review from '../../components/NumberRegister/Review';

import { getQuery } from '../../../pages/Paspor/function';
import { getSubmissionDetail } from '../../../form/PassportForm/action';

const RegisterReview = ({ history, i18n, getSubmissionDetail, submission }) => {
  const { uniqueId, type } = getQuery(history);
  const submissionDetail = get(submission, ['data']);
  const keytitle = ['passport_service', 'title'];
  const redirect = `/passpor/registration/personal-info?serviceId=${get(
    submissionDetail,
    ['passport_service', 'id']
  )}&uniqueId=${get(submissionDetail, ['unique_number'])}&type=passport`;

  const title = get(submissionDetail, [...keytitle]);

  useEffect(() => {
    if (!uniqueId) {
      if (type) {
        history.push(`/${type}`);
      } else {
        history.push('/');
      }
    } else if (type && uniqueId) {
      getSubmissionDetail(`/${uniqueId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Review
      i18n={i18n}
      reviewDetail={submissionDetail}
      title={title}
      nextDirection={redirect}
    />
  );
};

RegisterReview.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  getSubmissionDetail: PropTypes.func,
  submission: PropTypes.object
};

const mapStateToProps = (state) => ({
  submission: {
    status: state.submissionDetailReducer.status,
    error: state.submissionDetailReducer.error,
    data: state.submissionDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSubmissionDetail
  }),
  withRouter
)(RegisterReview);
