import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Alert } from 'reactstrap';
import * as yup from 'yup';
import moment from 'moment';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RadioButton from '../../../../components/input/RadioButton';
import DobInput from '../../components/Dob';
import Datepicker from '../../../../components/input/Datepicker';

import { optionsList, sosBudType } from '../functions';
import { getQuery } from '../../../pages/Paspor/function';
import {
  getSosBudLetter,
  postSosBudSubmission,
  postSosBudSubmissionSuccess,
  resetPostSubmission
} from '../action';

import { errorAlertScroll } from '../../../../helpers/functions';

const LastStudyForm = ({
  history,
  i18n,
  getSosBudLetter,
  letterRegisDetail,
  postSosBudSubmission,
  submission,
  resetPostSubmission
}) => {
  const { id } = getQuery(history);
  const detailForm = get(letterRegisDetail, ['data', 0]);
  const { data, status, error } = submission;

  const [form, setForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [errorSave, setErrorSave] = useState('');
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);
  const applicationId = get(detailForm, ['unique_number']);

  const { genderOptions, passport_type_options } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      name: yup.string().required('required'),
      gender: yup.string().required('required'),
      birth_city: yup.string().required('required'),
      birth_date: yup.date().required('required'),
      passport_type: yup.string().required('required'),
      passport_number: yup.string().required('required'),
      passport_expired: yup.date().required('required'),
      american_address: yup.string().required('required'),
      indo_address: yup.string().required('required'),
      email: yup.string().email('email').required('required'),
      id_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      school_name: yup.string().required('required'),
      school_address: yup.string().required('required'),
      jangka_waktu: yup.string().required('required'),
      graduate_date: yup.date().required('required'),
      major: yup.string().required('required'),
      gelar: yup.string().required('required'),
      departure_date: yup.date().required('required'),
      departure_number: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        if (showModal) {
          setShowModal(false);
          setTypeModal();
        } else {
          setShowModal(true);
          setTypeModal('confirm');
        }
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const submit = () => {
    const params = {
      unique_number: applicationId,
      service_id: get(detailForm, ['service_id']),
      name: form.name,
      gender: form.gender,
      birth_city: form.birth_city,
      birth_date: moment(form.birth_date).format('YYYY-MM-DD'),
      passport_type: form.passport_type,
      passport_number: form.passport_number,
      passport_expiry: moment(form.passport_expired).format('YYYY-MM-DD'),
      us_address: form.american_address,
      id_address: form.indo_address,
      email: form.email,
      phone: `${form.id_phone}`,
      school: {
        name: form.school_name,
        address: form.school_address,
        study_length: parseInt(form.jangka_waktu),
        graduate_date: moment(form.graduate_date).format('YYYY-MM-DD'),
        major: form.major,
        salutation: form.gelar,
        thesis_title: form.thesis_title,
        setneg_number: form.setneg_numb,
        study_number: form.sk_numb
      },
      departure_date: moment(form.departure_date).format('YYYY-MM-DD'),
      flight_number: form.departure_number
    };

    setShowModal(false);
    postSosBudSubmission(params);
  };

  const handlerChanges = (value, key, errorKey) => {
    if (key === 'marital_status') {
      const tmp_form = { ...form };
      if (value !== 'married') {
        tmp_form.spouse = {};
      }
      setForm({ ...tmp_form, [key]: value });
    } else {
      setForm({ ...form, [key]: value });
    }
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    getSosBudLetter(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const keyDetail = get(error, ['response', 'data', 'error', 'detail']);
      const messageDetail = get(error, ['response', 'data', 'error', 'message']);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setShowModal(true);
      setTypeModal('success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const renderNextStep = () => {
    if (sosBudType['pns']) {
      const list = sosBudType['pns'].list;
      return (
        <>
          <ol style={{ textAlign: 'left' }}>
            {list.map((listTitle, key) => (
              <li
                key={key}
                dangerouslySetInnerHTML={{
                  __html: i18n(`pensosbud.instruction_step.${listTitle}`)
                }}
              />
            ))}
          </ol>
          <div style={{ marginBottom: '16px' }}>{i18n('track_status_info')}</div>
        </>
      );
    }
  };

  let modalProps = {
    className: 'confirm',
    buttonProps: [
      {
        btnStyle: { color: 'info', outline: true },
        label: i18n('cta.no'),
        onClick: () => setShowModal(false)
      },
      {
        btnStyle: { color: 'info' },
        label: i18n('cta.ok'),
        onClick: () => submit()
      }
    ],
    title: i18n('confirm_modal.title'),
    desc: i18n('confirm_modal.desc')
  };

  if (typeModal === 'success') {
    modalProps = {
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('cta.ok'),
          onClick: () => {
            history.push('/pensosbud');
            resetPostSubmission();
          }
        }
      ],
      className: 'success',
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>
            {i18n('success_save_modal.title_with_name', {
              name: i18n('pensosbud.last_study_form.banner_title')
            })}
          </div>
        </div>
      ),
      desc: renderNextStep()
    };
  }

  return (
    <>
      {status === 'pending' && <Loading />}
      <Banner
        title={i18n('pensosbud.last_study_form.banner_title')}
        subtitle={i18n('pensosbud.banner.subtitle')}
        backButtonClick={() => history.goBack()}
      />
      <CardDetail
        title={i18n('pensosbud.last_study_form.form.title')}
        className='pensosbud-form form'
        rightInfo={
          <span>
            <span className='grey-text'>{i18n('form_personal_data.application_id')}</span>
            {applicationId}
          </span>
        }
      >
        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        <form onSubmit={handlerSubmit} id='graduate'>
          <div className='form-container'>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('form_personal_data.instruction_personal_info')}
              </div>
              <TextInput
                label={i18n('form.fullname.label')}
                placeholder={i18n('form.fullname.placeholder')}
                value={get(form, ['name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'name')
                }}
                errorMessage={
                  get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
                }
              />
              <RadioButton
                className='gender-type'
                label={i18n('form.gender.label')}
                options={genderOptions}
                value={get(form, ['gender'])}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'gender')
                }}
                errorMessage={
                  get(errors, ['gender']) && i18n(`validation.${get(errors, ['gender'])}`)
                }
              />
              <DobInput
                i18n={i18n}
                id='personal'
                place={{
                  label: i18n('form.dob_place.label'),
                  placeholder: i18n('form.dob_place.placeholder'),
                  onChange: (birth_city) => handlerChanges(birth_city, 'birth_city'),
                  value: get(form, ['birth_city']),
                  errorMessage:
                    get(errors, ['birth_city']) &&
                    i18n(`validation.${get(errors, ['birth_city'])}`)
                }}
                date={{
                  label: i18n('form.dob.label'),
                  placeholder: i18n('form.dob.placeholder'),
                  datepickerProps: { maxDate: new Date() },
                  onChange: (birth_date) => handlerChanges(birth_date, 'birth_date'),
                  value: get(form, ['birth_date']),
                  errorMessage:
                    get(errors, ['birth_date']) &&
                    i18n(`validation.${get(errors, ['birth_date'])}`)
                }}
              />
              <RadioButton
                className='passport-type'
                label={i18n('pensosbud.last_study_form.form.passport_type.label')}
                options={passport_type_options}
                value={get(form, ['passport_type'])}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'passport_type')
                }}
                errorMessage={
                  get(errors, ['passport_type']) &&
                  i18n(`validation.${get(errors, ['passport_type'])}`)
                }
              />
              <TextInput
                label={i18n('form.passport_number.label')}
                placeholder={i18n('form.passport_number.placeholder')}
                value={get(form, ['passport_number'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'passport_number')
                }}
                errorMessage={
                  get(errors, ['passport_number']) &&
                  i18n(`validation.${get(errors, ['passport_number'])}`)
                }
              />
              <Datepicker
                label={i18n('pensosbud.last_study_form.form.passport_expired.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.passport_expired.placeholder'
                )}
                className='date_issue-date'
                value={get(form, ['passport_expired'])}
                onChange={(passport_expired) =>
                  handlerChanges(passport_expired, 'passport_expired')
                }
                errorMessage={
                  get(errors, ['passport_expired']) &&
                  i18n(`validation.${get(errors, ['passport_expired'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('pensosbud.last_study_form.form.address')}
              </div>
              <TextInput
                label={i18n('pensosbud.last_study_form.form.american_address.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.american_address.placeholder'
                )}
                value={get(form, ['american_address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'american_address')
                }}
                errorMessage={
                  get(errors, ['american_address']) &&
                  i18n(`validation.${get(errors, ['american_address'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.indo_address.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.indo_address.placeholder'
                )}
                value={get(form, ['indo_address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'indo_address')
                }}
                errorMessage={
                  get(errors, ['indo_address']) &&
                  i18n(`validation.${get(errors, ['indo_address'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.email.label')}
                placeholder={i18n('pensosbud.last_study_form.form.email.placeholder')}
                value={get(form, ['email'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'email')
                }}
                errorMessage={
                  get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.phone.label')}
                placeholder={i18n('pensosbud.last_study_form.form.phone.placeholder')}
                prependText='+62'
                value={get(form, ['id_phone'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'id_phone')
                }}
                errorMessage={
                  get(errors, ['id_phone']) &&
                  i18n(`validation.${get(errors, ['id_phone'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('pensosbud.last_study_form.form.school_info')}
              </div>
              <TextInput
                label={i18n('pensosbud.last_study_form.form.school_name.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.school_name.placeholder'
                )}
                value={get(form, ['school_name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'school_name')
                }}
                errorMessage={
                  get(errors, ['school_name']) &&
                  i18n(`validation.${get(errors, ['school_name'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.school_address.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.school_address.placeholder'
                )}
                value={get(form, ['school_address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'school_address')
                }}
                errorMessage={
                  get(errors, ['school_address']) &&
                  i18n(`validation.${get(errors, ['school_address'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.jangka_waktu.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.jangka_waktu.placeholder'
                )}
                value={get(form, ['jangka_waktu'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'jangka_waktu')
                }}
                errorMessage={
                  get(errors, ['jangka_waktu']) &&
                  i18n(`validation.${get(errors, ['jangka_waktu'])}`)
                }
              />
              <Datepicker
                label={i18n('pensosbud.last_study_form.form.graduate_date.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.graduate_date.placeholder'
                )}
                className='date_issue-date'
                value={get(form, ['graduate_date'])}
                onChange={(graduate_date) =>
                  handlerChanges(graduate_date, 'graduate_date')
                }
                errorMessage={
                  get(errors, ['graduate_date']) &&
                  i18n(`validation.${get(errors, ['graduate_date'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.major.label')}
                placeholder={i18n('pensosbud.last_study_form.form.major.placeholder')}
                value={get(form, ['major'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'major')
                }}
                errorMessage={
                  get(errors, ['major']) && i18n(`validation.${get(errors, ['major'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.gelar.label')}
                placeholder={i18n('pensosbud.last_study_form.form.gelar.placeholder')}
                value={get(form, ['gelar'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'gelar')
                }}
                errorMessage={
                  get(errors, ['gelar']) && i18n(`validation.${get(errors, ['gelar'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.thesis_title.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.thesis_title.placeholder'
                )}
                value={get(form, ['thesis_title'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'thesis_title')
                }}
                errorMessage={
                  get(errors, ['thesis_title']) &&
                  i18n(`validation.${get(errors, ['thesis_title'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.setneg_numb.label')}
                placeholder={i18n(
                  'pensosbud.last_study_form.form.setneg_numb.placeholder'
                )}
                value={get(form, ['setneg_numb'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'setneg_numb')
                }}
                errorMessage={
                  get(errors, ['setneg_numb']) &&
                  i18n(`validation.${get(errors, ['setneg_numb'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.last_study_form.form.sk_numb.label')}
                placeholder={i18n('pensosbud.last_study_form.form.sk_numb.placeholder')}
                value={get(form, ['sk_numb'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'sk_numb')
                }}
                errorMessage={
                  get(errors, ['sk_numb']) &&
                  i18n(`validation.${get(errors, ['sk_numb'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('pensosbud.graduate_form.form.plan_back_indo')}
              </div>
              <Datepicker
                label={i18n('pensosbud.graduate_form.form.departure_date.label')}
                placeholder={i18n(
                  'pensosbud.graduate_form.form.departure_date.placeholder'
                )}
                className='date_issue-date'
                value={get(form, ['departure_date'])}
                onChange={(departure_date) =>
                  handlerChanges(departure_date, 'departure_date')
                }
                errorMessage={
                  get(errors, ['departure_date']) &&
                  i18n(`validation.${get(errors, ['departure_date'])}`)
                }
              />
              <TextInput
                label={i18n('pensosbud.graduate_form.form.departure_number.label')}
                placeholder={i18n(
                  'pensosbud.graduate_form.form.departure_number.placeholder'
                )}
                value={get(form, ['departure_number'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'departure_number')
                }}
                errorMessage={
                  get(errors, ['departure_number']) &&
                  i18n(`validation.${get(errors, ['departure_number'])}`)
                }
              />
            </div>
          </div>

          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.push('/pensosbud')}>
              {i18n('cta.cancel')}
            </Button>
            <Button color='primary' type='submit' className='pass-btn'>
              {i18n('cta.next')}
            </Button>
          </div>
        </form>
      </CardDetail>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

LastStudyForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  letterRegisDetail: PropTypes.object,
  postSosBudSubmission: PropTypes.func,
  submission: PropTypes.object,
  resetPostSubmission: PropTypes.func
};

const mapStateToProps = (state) => ({
  letterRegisDetail: {
    status: state.sosBudLetterDetailReducer.status,
    error: state.sosBudLetterDetailReducer.error,
    data: state.sosBudLetterDetailReducer.data
  },
  submission: {
    status: state.sosBudSubmissionReducer.status,
    error: state.sosBudSubmissionReducer.error,
    data: state.sosBudSubmissionReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudLetter,
    postSosBudSubmission,
    postSosBudSubmissionSuccess,
    resetPostSubmission
  }),
  withRouter
)(LastStudyForm);
