import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MiniCard from '../../../../components/CardDetail/MiniCard';

import { apiCaller } from '../../../../helpers/apiCaller';
import { setSummary } from '../functions';

const OtherDashboard = (props) => {
  const serviceList = [
    { title: 'Pengaduan', code: 'complaint', subtitle: 'Master Data' },
    { title: 'Pengumuman', code: 'news', subtitle: 'Master Data' },
    { title: 'Kegiatan', code: 'activity', subtitle: 'Master Data' },
    { title: 'Tempat Wisata', code: 'wisata', subtitle: 'Master Data' },
    { title: 'Antrian', code: 'appointment', subtitle: 'Master Data' },
    { title: 'User', code: 'user', subtitle: 'Master Data' }
  ];

  const renderDetails = () =>
    serviceList.map((detail, idx) => (
      <StatisticDetail detail={detail} {...props} serviceList={serviceList} key={idx} />
    ));

  return (
    <div className='statistic detail-container'>
      <div className='title'>Lainnya</div>
      <div className='detail-wrap'>{renderDetails()}</div>
    </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
const StatisticDetail = ({
  detail,
  history,
  startDate,
  endDate,
  reloadData,
  setReloadData
}) => {
  const { title, code, subtitle } = detail;
  const [statistic, setStatistic] = useState({
    numOfIn: String(0)
  });

  const detailService = {
    complaint: {
      adminLink: 'pengaduan',
      statisticLink: 'admin/complaint/statistic',
      statisticKey: ['numOfIn', 'numOfProcess', 'numOfFinish']
    },
    news: {
      adminLink: 'news',
      statisticLink: 'admin/news/statistic',
      statisticKey: ['numOfIn'],
      statisticContent: {
        numOfIn: 'Data Berita'
      }
    },
    activity: {
      adminLink: 'events',
      statisticLink: 'admin/events/statistic',
      statisticKey: ['numOfIn'],
      statisticContent: {
        numOfIn: 'Data Kegiatan'
      }
    },
    wisata: {
      adminLink: 'pariwisata',
      statisticLink: 'admin/tours/statistic',
      statisticKey: ['numOfIn'],
      statisticContent: {
        numOfIn: 'Data Tempat Wisata'
      }
    },
    appointment: {
      adminLink: 'antrian-paspor',
      statisticLink: 'admin/appointments/statistic',
      statisticKey: ['numOfIn', 'numOfProcess', 'numOfFinish']
    },
    user: {
      adminLink: 'users',
      statisticLink: 'admin/user/statistic',
      statisticKey: ['numOfIn'],
      statisticContent: {
        numOfIn: 'Data User'
      }
    }
  };

  const loadData = () => {
    const params = {
      from: startDate,
      to: endDate
    };

    if (detailService[code].statisticLink !== '') {
      apiCaller(detailService[code].statisticLink, 'get', undefined, true, params)
        .then((response) => {
          const statisticData = get(response, ['data', 'data']);
          let summaryData = {
            title,
            subtitle
          };

          if (code === 'news') {
            summaryData.numOfIn = get(statisticData, [0, 'total']);
          } else if (code === 'activity') {
            summaryData.numOfIn = get(statisticData, [0, 'total']);
          } else if (code === 'wisata') {
            summaryData.numOfIn = get(statisticData, [0, 'total']);
          } else if (code === 'user') {
            summaryData.numOfIn = get(statisticData, ['total']);
          } else {
            summaryData = {
              title,
              subtitle,
              ...setSummary(statisticData)
            };
          }
          setStatistic(summaryData);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const tmpSummary = {};
  detailService[code].statisticKey.map((sKey) => {
    tmpSummary[sKey] = statistic[sKey] || '0';
  });

  const detailMiniCard = {
    title,
    subtitle,
    ...tmpSummary,
    content: detailService[code].statisticContent,
    ...(detailService[code].adminLink !== ''
      ? {
          onClick: () => history.push(`/admin/${detailService[code].adminLink}`)
        }
      : {})
  };

  return <MiniCard detail={detailMiniCard} />;
};

StatisticDetail.propTypes = {
  history: PropTypes.object,
  detail: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

OtherDashboard.propTypes = {
  history: PropTypes.object
};

export default OtherDashboard;
