import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RadioButton from '../../../../components/input/RadioButton';
import Dropdown from '../../../../components/Dropdown';
import Datepicker from '../../../../components/input/Datepicker';

import { optionsList } from './functions';
import kjri_area from '../../../../constant/kjri_area';
import job_list from '../../../../constant/job_list';

import { getCountryList } from '../../PassportForm/action';

const PersonalInfoForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  tmpform,
  setTmpForm,
  country,
  getCountryList
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({
    ...(formGlobal.personalInfo || tmpform.personalInfo)
  });
  const [errors, setErrors] = useState({});
  const country_options = get(country, ['data']);

  const disableJob =
    get(form, ['profession']) === 'Tidak Bekerja' ||
    get(form, ['profession', 'value']) === 'unemployed';

  const job_options = [];
  job_list.map((job_key) => {
    job_options.push({
      value: job_key,
      label: i18n(`job_list.${job_key}`)
    });
  });

  const { genderOptions, marriageOptions } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      firstName: yup.string().required('required'),
      familyName: yup.string().required('required'),
      gender: yup.string().required('required'),
      marriageStatus: yup.string().required('required'),
      countryBirth: yup.object().shape({
        id: yup.string().required('required')
      }),
      dob: yup.date().required('required'),
      dob_place: yup.string().required('required'),
      nationality: yup.object().shape({
        id: yup.string().required('required')
      }),
      us_address: yup.string().required('required'),
      city: yup.string().required('required'),
      state: yup.object().shape({
        value: yup.string().required('required')
      }),
      zip: yup.string().required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      email: yup.string().email('email').required('required'),
      profession: yup.object().shape({
        value: yup.string().required('required')
      }),
      ...(!disableJob && {
        current_position: yup.string().required('required'),
        institution_name: yup.string().required('required'),
        institution_phone: yup
          .number()
          .test(
            'len',
            i18n('validation.min_length', { min: 10 }),
            (val) => val && val.toString().length > 9
          )
          .typeError('number')
          .required('required')
          .integer('number')
          .positive('number'),
        institution_address: yup.string().required('required'),
        institution_city: yup.string().required('required'),
        institution_state: yup.string().required('required'),
        institution_country: yup.object().shape({
          id: yup.string().required('required')
        }),
        institution_zip: yup.string().required('required')
      })
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, personalInfo: form });
        history.push(`/visa/form/passport${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    const tmpErrors = { ...errors };
    if (
      (key === 'profession' && value.value === 'Tidak Bekerja') ||
      value.value === 'unemployed'
    ) {
      tmpErrors['current_position'] = undefined;
      tmpErrors['institution_name'] = undefined;
      tmpErrors['institution_phone'] = undefined;
      tmpErrors['institution_address'] = undefined;
      tmpErrors['institution_country.id'] = undefined;
      tmpErrors['institution_state'] = undefined;
      tmpErrors['institution_city'] = undefined;
      tmpErrors['institution_zip'] = undefined;
    }
    setErrors({ ...tmpErrors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, personalInfo: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    getCountryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handlerSubmit} id='personal-info'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_personal_info')}
          </div>
          <TextInput
            label={i18n('form.first_name.label')}
            placeholder={i18n('form.first_name.placeholder')}
            value={get(form, ['firstName'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'firstName')
            }}
            errorMessage={
              get(errors, ['firstName']) &&
              i18n(`validation.${get(errors, ['firstName'])}`)
            }
          />
          <TextInput
            label={i18n('form.middle_name.label')}
            placeholder={i18n('form.middle_name.placeholder')}
            detailInput={i18n('form.middle_name.info')}
            value={get(form, ['middleName'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'middleName')
            }}
            errorMessage={
              get(errors, ['middleName']) &&
              i18n(`validation.${get(errors, ['middleName'])}`)
            }
          />
          <TextInput
            label={i18n('form.family_name.label')}
            placeholder={i18n('form.family_name.placeholder')}
            value={get(form, ['familyName'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'familyName')
            }}
            errorMessage={
              get(errors, ['familyName']) &&
              i18n(`validation.${get(errors, ['familyName'])}`)
            }
          />
          <RadioButton
            className='gender-type'
            label={i18n('form.gender.label')}
            options={genderOptions}
            value={get(form, ['gender'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'gender')
            }}
            errorMessage={
              get(errors, ['gender']) && i18n(`validation.${get(errors, ['gender'])}`)
            }
          />
          <RadioButton
            label={i18n('form.marriage_status.label')}
            options={marriageOptions}
            value={get(form, ['marriageStatus'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'marriageStatus')
            }}
            errorMessage={
              get(errors, ['marriageStatus']) &&
              i18n(`validation.${get(errors, ['marriageStatus'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.country_of_birth.label')}
            placeholder={i18n('form.country_of_birth.placeholder')}
            value={get(form, ['countryBirth'])}
            options={country_options}
            onClick={(item) => handlerChanges(item, 'countryBirth', 'countryBirth.id')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='id'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['countryBirth.id']) &&
              i18n(`validation.${get(errors, ['countryBirth.id'])}`)
            }
          />
          <Datepicker
            label={i18n('form.dob.label')}
            placeholder={i18n('form.dob.placeholder')}
            value={get(form, ['dob'])}
            onChange={(date) => handlerChanges(date, 'dob')}
            errorMessage={
              get(errors, ['dob']) && i18n(`validation.${get(errors, ['dob'])}`)
            }
          />
          <TextInput
            label={i18n('form.dob_place.label')}
            placeholder={i18n('form.dob_place.placeholder')}
            value={get(form, ['dob_place'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'dob_place')
            }}
            errorMessage={
              get(errors, ['dob_place']) &&
              i18n(`validation.${get(errors, ['dob_place'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.nationality.label')}
            placeholder={i18n('form.nationality.placeholder')}
            value={get(form, ['nationality'])}
            options={country_options}
            onClick={(item) => handlerChanges(item, 'nationality', 'nationality.id')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='id'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['nationality.id']) &&
              i18n(`validation.${get(errors, ['nationality.id'])}`)
            }
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>{i18n('form_visa.instruction.address_usa')}</div>
          <TextInput
            label={i18n('form.address_in_usa.label')}
            placeholder={i18n('form.address_in_usa.placeholder')}
            value={get(form, ['us_address'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'us_address')
            }}
            errorMessage={
              get(errors, ['us_address']) &&
              i18n(`validation.${get(errors, ['us_address'])}`)
            }
          />
          <TextInput
            label={i18n('form.city.label')}
            placeholder={i18n('form.city.placeholder')}
            value={get(form, ['city'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'city')
            }}
            errorMessage={
              get(errors, ['city']) && i18n(`validation.${get(errors, ['city'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.state.label')}
            placeholder={i18n('form.state.placeholder')}
            value={get(form, ['state'])}
            options={kjri_area}
            onClick={(item) => handlerChanges(item, 'state', 'state.value')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            withAutocomplete
            errorMessage={
              get(errors, ['state.value']) &&
              i18n(`validation.${get(errors, ['state.value'])}`)
            }
          />
          <TextInput
            label={i18n('form.zip_code.label')}
            placeholder={i18n('form.zip_code.placeholder')}
            value={get(form, ['zip'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'zip')
            }}
            errorMessage={
              get(errors, ['zip']) && i18n(`validation.${get(errors, ['zip'])}`)
            }
          />
          <TextInput
            label={i18n('form.phone.label')}
            placeholder={i18n('form.phone.placeholder')}
            prependText='+1'
            value={get(form, ['phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'phone')
            }}
            errorMessage={
              get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
            }
          />
          <TextInput
            label={i18n('form.email.label')}
            placeholder={i18n('form.email.placeholder')}
            value={get(form, ['email'])}
            inputProps={{
              maxLength: '255',
              onChange: (e) => handlerChanges(e.target.value, 'email')
            }}
            errorMessage={
              get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
            }
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>{i18n('form_visa.instruction.current_employee')}</div>
          <Dropdown
            className='row input-container'
            label={i18n('form.profession.label')}
            placeholder={i18n('form.profession.placeholder')}
            value={
              form.profession && {
                label: form.profession
                  ? i18n(`job_list.${get(form, ['profession', 'value'])}`)
                  : '',
                value: get(form, ['profession', 'value'])
              }
            }
            isValueObject
            options={job_options}
            onClick={(item) => handlerChanges(item, 'profession', 'profession.value')}
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            withAutocomplete
            errorMessage={
              get(errors, ['profession.value']) &&
              i18n(`validation.${get(errors, ['profession.value'])}`)
            }
          />
          <TextInput
            label={i18n('form.current_position.label')}
            placeholder={i18n('form.current_position.placeholder')}
            value={get(form, ['current_position'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'current_position')
            }}
            errorMessage={
              get(errors, ['current_position']) &&
              i18n(`validation.${get(errors, ['current_position'])}`)
            }
            disable={disableJob}
          />
          <TextInput
            label={i18n('form.institution_name.label')}
            placeholder={i18n('form.institution_name.placeholder')}
            value={get(form, ['institution_name'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'institution_name')
            }}
            errorMessage={
              get(errors, ['institution_name']) &&
              i18n(`validation.${get(errors, ['institution_name'])}`)
            }
            disable={disableJob}
          />
          <TextInput
            label={i18n('form.phone.label')}
            placeholder={i18n('form.phone.placeholder')}
            prependText='+1'
            value={get(form, ['institution_phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'institution_phone')
            }}
            errorMessage={
              get(errors, ['institution_phone']) &&
              i18n(`validation.${get(errors, ['institution_phone'])}`)
            }
            disable={disableJob}
          />
          <TextInput
            label={i18n('form.address_institution.label')}
            placeholder={i18n('form.address_institution.placeholder')}
            value={get(form, ['institution_address'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'institution_address')
            }}
            errorMessage={
              get(errors, ['institution_address']) &&
              i18n(`validation.${get(errors, ['institution_address'])}`)
            }
            disable={disableJob}
          />
          <TextInput
            label={i18n('form.city.label')}
            placeholder={i18n('form.city.placeholder')}
            value={get(form, ['institution_city'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'institution_city')
            }}
            errorMessage={
              get(errors, ['institution_city']) &&
              i18n(`validation.${get(errors, ['institution_city'])}`)
            }
            disable={disableJob}
          />
          <TextInput
            label={i18n('form.state.label')}
            placeholder={i18n('form.state.placeholder')}
            value={get(form, ['institution_state'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'institution_state')
            }}
            errorMessage={
              get(errors, ['institution_state']) &&
              i18n(`validation.${get(errors, ['institution_state'])}`)
            }
            disable={disableJob}
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.country.label')}
            placeholder={i18n('form.country.placeholder')}
            value={get(form, ['institution_country'])}
            options={country_options}
            onClick={(item) =>
              handlerChanges(item, 'institution_country', 'institution_country.id')
            }
            valueKey='id'
            labelKey='name'
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: `input-dropdown ${disableJob ? 'disable' : ''}`,
              disabled: disableJob
            }}
            withAutocomplete
            errorMessage={
              get(errors, ['institution_country.id']) &&
              i18n(`validation.${get(errors, ['institution_country.id'])}`)
            }
          />
          <TextInput
            label={i18n('form.zip_code.label')}
            placeholder={i18n('form.zip_code.placeholder')}
            value={get(form, ['institution_zip'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'institution_zip')
            }}
            errorMessage={
              get(errors, ['institution_zip']) &&
              i18n(`validation.${get(errors, ['institution_zip'])}`)
            }
            disable={disableJob}
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/visa/form/general-info${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

PersonalInfoForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object,
  country: PropTypes.object,
  getCountryList: PropTypes.func
};

const mapStateToProps = (state) => ({
  country: {
    status: state.countryListReducer.status,
    error: state.countryListReducer.error,
    data: state.countryListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getCountryList }),
  withRouter
)(PersonalInfoForm);
