import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '../Button';

const StepItemVisa = ({ instructions, i18n, history, type, serviceId }) => {
  return (
    <div className='step-item visa'>
      <div
        className='text'
        dangerouslySetInnerHTML={{
          __html: instructions
        }}
      />
      <Button
        color='primary'
        onClick={() =>
          history.push(`/visa/registration/create?type=${type}&serviceId=${serviceId}`)
        }
      >
        {i18n('cta.start_applicant')}
      </Button>
    </div>
  );
};

StepItemVisa.propTypes = {
  instructions: PropTypes.string
};

export default withRouter(StepItemVisa);
