import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { withRouter, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '../../../../components/Button';
import { arrayHasValue } from '../../../../helpers/helpers';
import './style.scss';
import moment from 'moment';
import { getNewsDetail } from '../action';

const DetailNewsAdmin = ({ history, i18n, newsDetail, getNewsDetail }) => {
  const match = useRouteMatch();
  const { id } = match.params;

  useEffect(() => {
    if (!arrayHasValue(newsDetail.data)) {
      getNewsDetail(id);
    }
  }, []);

  return (
    <>
      <Container style={{ paddingTop: 24, marginLeft: 0, marginRight: 0 }}>
        <div className='admin-news-detail'>
          <span className='back-homepage' onClick={() => history.push('/admin/news')}>
            <span className='btn-back' />
            {newsDetail.status === 'resolve' ? newsDetail.data.title : ''}
          </span>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => history.push('/admin/news/edit/' + id)}
          >
            Edit Berita
          </Button>
          <div
            className='news-detail'
            style={{ height: 720, overflowY: 'scroll', borderRadius: 8 }}
          >
            <h2>{newsDetail.status === 'resolve' ? newsDetail.data.title : ''}</h2>

            <div className='news-info'>
              {newsDetail.status === 'resolve'
                ? i18n('news.detail.by') +
                  'Admin KJRI -' +
                  moment(newsDetail.data.updated_at)
                    .utcOffset(newsDetail.data.updated_at)
                    .format('YYYY-MM-DD HH:mm')
                : ''}
            </div>
            {newsDetail.status === 'resolve' ? (
              <img src={newsDetail.data.image_url} alt='news-title' />
            ) : ''}
            <div
              className='news-content'
              dangerouslySetInnerHTML={{
                __html: newsDetail.status === 'resolve' ? newsDetail.data.content : ''
              }}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

DetailNewsAdmin.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  newsDetail: PropTypes.object,
  getNewsDetail: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  newsDetail: {
    status: state.newsDetailReducerAdmin.status,
    error: state.newsDetailReducerAdmin.error,
    data: state.newsDetailReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, { getNewsDetail }),
  withRouter
)(DetailNewsAdmin);
