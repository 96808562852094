import React from 'react';
import HomepageIcon from '../../../../components/HomepageIcon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import paspor from '../../../../assets/img/icon/passport.svg';
import account from '../../../../assets/img/icon/lapor_diri.svg';
import letter from '../../../../assets/img/icon/surat_keterangan.svg';
import stamp from '../../../../assets/img/icon/stamp_icon.svg';
import visa from '../../../../assets/img/icon/visa_icon.svg';
import chart from '../../../../assets/img/icon/chart.svg';
import gunungan from '../../../../assets/img/icon/pensosbud_icon.svg';
import search from '../../../../assets/img/icon/track_status.svg';
import bull from '../../../../assets/img/icon/berita_informasi.svg';
import call from '../../../../assets/img/icon/call_24px.svg';
import message from '../../../../assets/img/icon/pengaduan_icon.svg';
import supervisor from '../../../../assets/img/icon/diaspora_icon.svg';

const HomepageMenu = ({ i18n }) => {
  const homeMenu = [
    [
      {
        img: account,
        link: '/self-report',
        color: '#44D492',
        text: i18n('homepage.menu.self_reporting')
      },
      {
        img: paspor,
        link: '/passpor',
        color: '#0BB3D9',
        text: i18n('homepage.menu.passport')
      },
      {
        img: letter,
        link: '/statement-letter',
        color: '#0578A6',
        text: i18n('homepage.menu.letter')
      },
      {
        img: stamp,
        link: '/doc-legalization',
        color: '#005A9C',
        text: i18n('homepage.menu.document')
      },
      {
        img: visa,
        link: '/visa',
        color: '#165FBB',
        text: i18n('homepage.menu.visa')
      },
      {
        img: chart,
        link: '/economy',
        color: '#5CB85C',
        text: i18n('homepage.menu.ekonomi')
      }
    ],
    [
      {
        img: gunungan,
        link: '/pensosbud',
        color: '#FEBE23',
        text: i18n('homepage.menu.info')
      },
      {
        img: search,
        link: '/track',
        color: '#5CB85C',
        text: i18n('homepage.menu.track')
      },
      {
        img: bull,
        link: '/news',
        color: '#0A4560',
        text: i18n('homepage.menu.annoucement')
      },
      {
        img: call,
        link: '/contact-us',
        color: '#30D569',
        text: i18n('homepage.menu.contact')
      },
      {
        img: message,
        link: '/complaint',
        color: '#FD5457',
        text: i18n('homepage.menu.feedback')
      },
      {
        img: supervisor,
        link: '/diaspora',
        color: '#FFA15C',
        text: i18n('homepage.menu.diaspora')
      }
    ]
  ];

  return (
    <>
      {homeMenu.map((menu, idx) => (
        <div
          className='homepage-menu-list'
          style={{ marginBottom: idx === 0 ? 44 : 0 }}
          key={idx}
        >
          {menu.map((propsHome, idx2) => (
            <HomepageIcon {...propsHome} key={idx2} />
          ))}
        </div>
      ))}
    </>
  );
};

HomepageMenu.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(HomepageMenu);
