import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment-timezone';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button';

import { parseQuery } from '../../../../helpers/functions';
import { passport_service } from './functions';
import { setSlotTimeAction, getAppointmentsList } from './action';

import './styleForm.scss';

const FormSchedule = ({
  history,
  i18n,
  slotTimeState,
  setSlotTimeAction,
  appointments,
  getAppointmentsList
}) => {
  const [startDate, setStartDate] = useState(moment().isoWeekday(1));
  const [endDate, setEndDate] = useState(moment(startDate).add(6, 'd'));
  const [slotTime, setSlotTime] = useState([]);
  const [error, setError] = useState(false);

  const { location } = history;
  const { search } = location;
  const searchQuery = parseQuery(search) || {};
  const { type, num } = searchQuery;
  const nyTime = moment().format('hh:mm A');

  const appointmentList = get(appointments, ['data']);

  const renderTime = () =>
    appointmentList.map(({ date, appointments }, idx) => {
      const disableDate = moment(date).isBefore(moment().subtract(1, 'day'));

      const checkHoliday = appointments.find((item) => item.status === 'HOLIDAY');
      const hasHoliday = !!checkHoliday;

      return (
        <div className={`calendar-container ${disableDate ? 'disable' : ''}`} key={idx}>
          <div className='day-date'>
            <div className='day'>
              {moment(new Date(date), 'YYYY-MM-DD').utcOffset(0).format('ddd')}
            </div>
            <div className='date'>
              {moment(new Date(date), 'YYYY-MM-DD').utcOffset(0).format('MMM DD')}
            </div>
          </div>
          <div className={`time-wrap ${error ? 'error' : ''}`}>
            {!hasHoliday && appointments.length > 0 ? (
              sortBy(appointments, ({ scheduled_at }) => {
                return moment(scheduled_at).utcOffset(0).format('HH:mm');
              }).map(({ scheduled_at, end_at, status, _id }, idxtime) => {
                const check = slotTime.find((slot) => slot._id === _id);
                const disableTime = moment(scheduled_at).utcOffset(0).isBefore(moment());
                const disable = disableDate || disableTime || status !== 'AVAILABLE';

                const isSelectedTime = check && Object.keys(check).length > 0;
                const isDisableMaxSelect =
                  !isSelectedTime && slotTime.length === parseInt(num || 1)
                    ? true
                    : false;

                return (
                  <div
                    className={`time ${isSelectedTime ? 'selected' : ''} ${
                      disable || isDisableMaxSelect ? 'disable' : ''
                    }`}
                    key={idxtime}
                    onClick={() => {
                      let tmpSlotTime = [...slotTime];

                      if (isSelectedTime) {
                        tmpSlotTime = slotTime.filter((slot) => slot._id !== _id);
                      } else {
                        tmpSlotTime.push({
                          scheduled_at,
                          end_at,
                          _id
                        });
                      }
                      setSlotTime(tmpSlotTime);
                      setError(false);
                    }}
                  >
                    {moment(scheduled_at).utcOffset(0).format('HH:mm')}
                  </div>
                );
              })
            ) : (
              <div className='time disable'>{i18n('passpor_schedule.close')}</div>
            )}
          </div>
        </div>
      );
    });

  const handleTime = (type) => {
    let newStartDate;
    let newEndDate;
    if (type === 'next') {
      newStartDate = moment(startDate).add(6, 'd');
      newEndDate = moment(newStartDate).add(6, 'd');
    } else {
      newStartDate = moment(startDate).subtract(6, 'd');
      newEndDate = moment(newStartDate).add(6, 'd');
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleSubmit = () => {
    if (slotTime.length !== parseInt(num || 1)) {
      setError(true);
    } else {
      setSlotTimeAction(slotTime);
      history.push(`/passpor/schedule/form/confirm${search}`);
    }
  };

  useEffect(() => {
    if (!type) {
      history.push('/passpor');
    }
  }, [history, type]);

  useEffect(() => {
    getAppointmentsList(
      `?from=${moment(startDate).format('YYYY-MM-DD')}&to=${moment(endDate).format(
        'YYYY-MM-DD'
      )}`
    );
  }, [endDate, getAppointmentsList, startDate]);

  return (
    <>
      <Banner
        title={i18n(`paspor_page.${passport_service[type].title}`)}
        subtitle={
          <>
            <div className='text'>{i18n('paspor_page.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('paspor_page.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      <div className='container'>
        <CardDetail
          title={`${i18n(
            'passpor_schedule.title'
          )} ${i18n('passport_schedule_form.for_people', { num: num ? num : 1 })}`}
          description={i18n('passpor_schedule.subtile')}
          className='schedule-form'
        >
          <div className='top'>
            <div className='slot-wrap'>
              <div
                className='title'
                dangerouslySetInnerHTML={{
                  __html: num
                    ? i18n('passport_schedule_form.schedule_for_num_people', { num })
                    : i18n('passport_schedule_form.schedule_individu')
                }}
              />
              <div className='slot-info'>{i18n('passpor_schedule.choose_time')}</div>
            </div>
            <div className='ny-time'>
              {i18n('passport_schedule_form.ny_time')}: <b>{nyTime}</b>
            </div>
          </div>
          <div className='time-container'>
            <div className='instruction'>
              {i18n('passport_schedule_form.choose_week')}
            </div>
            <div className='time-wrap'>
              <div
                className={`prev ${
                  moment(startDate).isBefore(moment()) ? 'disable' : ''
                }`}
                onClick={() =>
                  moment(startDate).isBefore(moment()) ? null : handleTime('prev')
                }
              >
                <Icon icon='chevron left blue' />
                {i18n('passport_schedule_form.last_week')}
              </div>
              <div className='calender-wrap'>
                <Icon icon='calendar' />
                {moment(startDate).format('DD-MM-YYYY')} -
                {moment(endDate).format('DD-MM-YYYY')}
              </div>
              <div className='next' onClick={() => handleTime('next')}>
                {i18n('passport_schedule_form.next_week')}
                <Icon icon='chevron blue' />
              </div>
            </div>
          </div>
          <div className='schedule-wrap'>{renderTime()}</div>
          {error && (
            <div className='error-message'>{i18n('passpor_schedule.required')}</div>
          )}
          <div className='btn-wrap'>
            <Button color='primary' onClick={() => handleSubmit()}>
              {i18n('nav.next')}
            </Button>
          </div>
        </CardDetail>
      </div>
    </>
  );
};

FormSchedule.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  slotTimeState: PropTypes.array,
  setSlotTimeAction: PropTypes.func,
  appointments: PropTypes.object,
  getAppointmentsList: PropTypes.func
};

const mapStateToProps = (state) => ({
  slotTimeState: state.scheduleReducer.slotTime,
  appointments: {
    status: state.appointmentListReducer.status,
    error: state.appointmentListReducer.error,
    data: state.appointmentListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { setSlotTimeAction, getAppointmentsList }),
  withRouter
)(FormSchedule);
