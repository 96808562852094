import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const MobileDownload = ({ i18n }) => {
  return (
    <div className='mobile-container'>
      <div className='left'>
        <div className='welcome'>{i18n('mobile_download.welcome')}</div>
        <div className='title'>{i18n('mobile_download.title')}</div>
        <div className='subtitle'>{i18n('mobile_download.subtitle')}</div>

        <div className='instruction'>{i18n('mobile_download.download')}</div>
        <div className='store-wrap'>
          <a
            href='https://play.google.com/store/apps/details?id=org.kjri.consulateconnect'
            target='_blank'
            rel='noopener noreferrer'
            className='gplay store'
          />
          <a
            href='https://apps.apple.com/us/app/sco-kjri-new-york/id1563901250'
            target='_blank'
            rel='noopener noreferrer'
            className='appstore store'
          />
        </div>
      </div>
      <div className='illustration' />
    </div>
  );
};

MobileDownload.propTypes = {
  i18n: PropTypes.func
};

export default MobileDownload;
