import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './style.scss';

const NewsThumbnail = ({ data }) => {
  const { _id, image_url, title, updated_at } = data || {};

  return (
    <Col md='3'>
      <div className='news-thumbnail'>
        <Link to={`/news/detail/${_id}`}>
          <div
            className='news-thumbnail-img'
            style={{ background: `url(${image_url})` }}
          />
          <div className='news-thumbnail-title'>{title}</div>
        </Link>
        <div className='news-thumbnail-date'>
          {moment(updated_at).utcOffset(updated_at).format('LLL')}
        </div>
      </div>
    </Col>
  );
};

export default NewsThumbnail;
