import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import parse from 'html-react-parser';
import { Alert } from 'reactstrap';

import { errorAlertScroll } from '../../../../../helpers/functions';

import Button from '../../../../../components/Button';
import FileInput from '../../File';
import Signature from '../../Signature';
import { getQuery } from '../../../../pages/Paspor/function';

import { getPassportDocList } from '../../action';

const UploadDocForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  setTmpForm,
  tmpform,
  handleSubmit,
  getPassportDocList,
  passportDoc,
  isEditDoc,
  editDocData
}) => {
  const { location } = history;
  const { search } = location;
  const { serviceId, uniqueId } = isEditDoc ? editDocData : getQuery(history);
  const [files, setFiles] = useState(tmpform.documents || {});
  const signaturePadRef = React.useRef();

  const [alertDetail, setAlertDetail] = useState({});
  const [visible, setVisible] = useState(false);

  const passportDocList = get(passportDoc, ['data']) || [];

  const handlerAlert = (data) => {
    setVisible(true);
    setAlertDetail(data);
    setTimeout(() => {
      setVisible(false);
    }, 5000);
    errorAlertScroll();
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    const totalDoc = passportDocList.length;
    if(files.hasOwnProperty('doc_24')){
    // if (Object.keys(files).length === totalDoc) {
      if (isEditDoc) {
        handleSubmit(files);
      } else {
        setFormGlobal({ ...formGlobal, documents: files });
        handleSubmit();
      }
    } else {
      handlerAlert({
        color: 'danger',
        message: 'Harap upload tanda tangan'
      });
    }
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, documents: files });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    getPassportDocList(serviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Alert color={get(alertDetail, ['color'])} isOpen={visible}>
        {get(alertDetail, ['message'])}
      </Alert>
      <form onSubmit={handlerSubmit} id='american-address'>
        <div className='form-container'>
          <div className='form-wrap'>
            {!isEditDoc && (
              <div className='instruction' style={{ marginBottom: '16px' }}>
                {i18n('form_document.instruction')}
              </div>
            )}
            {passportDocList.map(({ number }, key) => {
              if (number === 24) {
                return (
                  <Signature
                    key={key}
                    signaturePadRef={signaturePadRef}
                    unique_number={uniqueId}
                    filePassport={get(files, ['doc_24', 'url'])}
                    setFiles={(signatureUrl) =>
                      setFiles({ ...files, doc_24: signatureUrl })
                    }
                    i18n={i18n}
                  />
                );
              } else {
                return (
                  <div key={key}>
                    <FileInput
                      key={key}
                      label={i18n(`form_document.${number}`)}
                      setFiles={(data) => setFiles({ ...files, [`doc_${number}`]: data })}
                      type='img'
                      fileInputType='passpor'
                      doc_type={i18n(`form_document.${number}`)}
                      doc_number={number}
                      unique_number={uniqueId}
                      filePassport={get(files, [`doc_${number}`, 'url'])}
                      fileTypePassport={get(files, [`doc_${number}`, 'type'])}
                    />
                    <div className='detail-file'>
                      {i18n(`form_document.detail_info.${number}`) !== number.toString()
                        ? parse(i18n(`form_document.detail_info.${number}`))
                        : ''}
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className='notes'>
            <b>$23 USPS Money Order</b>, payable to: Indonesian Consulate
          </div>
        </div>
        <div className='btn-wrap'>
          <Button
            color='link'
            type='button'
            onClick={() =>
              history.push(`/passpor/registration/emergency-contact${search}`)
            }
          >
            {i18n('cta.cancel')}
          </Button>
          <Button color='primary' type='submit' className='pass-btn'>
            {i18n('cta.submit')}
          </Button>
        </div>
      </form>
    </>
  );
};

UploadDocForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object,
  getPassportDocList: PropTypes.func,
  passportDoc: PropTypes.object
};

const mapStateToProps = (state) => ({
  passportDoc: {
    status: state.passportDocReducer.status,
    error: state.passportDocReducer.error,
    data: state.passportDocReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getPassportDocList
  }),
  withRouter
)(UploadDocForm);
