import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';

import { getSosBudServiceId } from '../../../form/PensosbudForm/functions';

const GraduateSchool = ({ i18n, sosBudServiceList }) => {
  const lang = (type, params) => i18n(`pensosbud.tab2.${type}`, { ...params });
  const serviceId = getSosBudServiceId(sosBudServiceList, 'graduate');

  const stepOuterList = [
    {
      label: i18n('pensosbud.step.take_number.title'),
      cta: i18n('pensosbud.step.take_number.cta'),
      link: `/pensosbud/registration/create?type=graduate&serviceId=${serviceId}`
    },
    {
      label: i18n('pensosbud.step.download.title'),
      cta: i18n('pensosbud.step.download.cta', { type: i18n('pensosbud.tab2.title') }),
      linkType: true,
      iconCta: 'download',
      ctaColor: 'outline',
      link: 'https://drive.google.com/file/d/1R60tiXpqrAuTFtdietQ1cZz6V8raTjIi/view'
    }
  ];

  const instructionList = [
    i18n('pensosbud.instruction_step.kjri_form'),
    i18n('pensosbud.instruction_step.ijasah0'),
    i18n('pensosbud.instruction_step.surat_keterangan'),
    i18n('pensosbud.instruction_step.passport'),
    i18n('pensosbud.instruction_step.transkrip'),
    i18n('pensosbud.instruction_step.thesis'),
    i18n('pensosbud.instruction_step.send_all_doc'),
    i18n('pensosbud.instruction_step.min_3_days')
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

GraduateSchool.propTypes = {
  i18n: PropTypes.func,
  sosBudServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(GraduateSchool);
