import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import TabDetail from './TabDetail';

import {
  getCountryList,
  getPortList,
  getProvinceIDList
} from '../../../form/PassportForm/action';
import { getCityId } from '../../../form/VisaForm/action';
import { getVisaDetail, patchApprove, patchPending, patchProcess } from '../action';
import { typeModalList } from '../../functions';
import { detailingData } from './functions';

import './style.scss';
import Right from '../../../../assets/img/chevron_right_24px.svg';

import visaService from '../../../../constant/visaService';

const DetailVisa = ({
  history,
  getVisaDetail,
  patchApprove,
  patchPending,
  patchProcess,
  visaDetail,
  approve,
  pending,
  process,
  country,
  getCountryList,
  port,
  getPortList,
  province,
  getProvinceIDList,
  citiesId,
  getCityId
}) => {
  const { type, id } = useParams();
  const country_options = get(country, ['data']);
  const provinceOptions = get(province, ['data']);
  const portOptions = get(port, ['data']);
  const cityIdList = get(citiesId, ['data']);

  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [reason, setReason] = useState();
  const [menuActive, setMenuActive] = useState('general');
  const [loadingAction, setLoadingAction] = useState(false);
  const [actionData, setActionData] = useState();

  const detailPassport = get(visaDetail, ['data']);
  const loadingDetail = get(visaDetail, ['status']) === 'pending';
  const detailData = detailingData({
    data: detailPassport,
    country_options,
    provinceOptions,
    portOptions,
    cityIdList
  });
  const status = get(detailData, ['status']);
  const unique_id = get(detailData, ['unique_number']);

  const personalData = {
    name: get(detailData, ['name']),
    email: get(detailData, ['email']),
    phone: get(detailData, ['phone']),
    unique_id,
    citizenship: get(detailData, ['citizenshipData', 'name']),
    bookingTime: moment(get(detailData, ['created_at'])).format('LLL')
  };

  // action
  const { status: statusApprove, data: dataApprove, error: errorApprove } = approve;
  const { status: statusProcess, data: dataProcess, error: errorProcess } = process;
  const { status: statusPending, data: dataPending, error: errorPending } = pending;

  const handlerModal = (type) => {
    if (showModal) {
      setShowModal(false);
      setTypeModal();
    } else {
      setShowModal(true);
      setTypeModal(type);
    }
  };

  const handlerSubmitAction = () => {
    // handlerModal();
    if (typeModal === 'process') {
      patchProcess(id);
    } else if (typeModal === 'finish') {
      patchApprove(id);
    } else if (typeModal === 'tunda') {
      patchPending(id, {
        note: reason
      });
    }

    setShowModal(false);
    setLoadingAction(true);
  };

  const detailModal = () => {
    const onChange = (e) => setReason(e.target.value);
    const name = personalData.name;

    return typeModalList[typeModal]({ name, reason, onChange, actionData });
  };

  const modalProps = typeModal ? detailModal() : {};

  useEffect(() => {
    getVisaDetail(id);
    getCountryList();
    getPortList();
    getProvinceIDList();
    getCityId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingAction) {
      let data = {};
      let error = {};
      let status = 'idle';

      if (typeModal === 'process') {
        data = dataProcess;
        error = errorProcess;
        status = statusProcess;
      } else if (typeModal === 'tunda') {
        data = dataPending;
        error = errorPending;
        status = statusPending;
      } else if (typeModal === 'finish') {
        data = dataApprove;
        error = errorApprove;
        status = statusApprove;
      }

      if (status === 'resolve') {
        const messageError = get(error, ['response', 'data', 'error', 'message']);
        const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
        const messageDetail = get(error, [
          'response',
          'data',
          'error',
          'detail',
          0,
          'message'
        ]);

        const { status, message } = data;
        if (status) {
          // show success message
          handlerModal('successSave', {});
          getVisaDetail(id);
        } else {
          const messageErrorFinal =
            message !== '' ? message : `${messageError}. ${keyDetail}: ${messageDetail}.`;
          handlerModal('failedSave', {});
          setActionData(messageErrorFinal);
        }
        setLoadingAction(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAction, statusApprove, statusProcess, statusPending]);

  return (
    <div className='admin-visa-detail'>
      {(loadingDetail || loadingAction) && <Loading />}
      <span className='back-homepage' onClick={() => history.goBack()}>
        <span className='btn-back' />
        VISA - {visaService[type]}
      </span>
      <div className='content'>
        <div className='detail-top'>
          <div className='img' />
          <div className='detail-top-user'>
            <table>
              <tr>
                <td>
                  <label>Nama Lengkap</label>
                </td>
                <td>{personalData.name}</td>
              </tr>
              <tr>
                <td>
                  <label>Email</label>
                </td>
                <td>{personalData.email}</td>
              </tr>
              <tr>
                <td>
                  <label>Nomor Telepon</label>
                </td>
                <td>{personalData.phone}</td>
              </tr>
            </table>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Nomor Registrasi</label>
            </div>
            <div className='det-value'>
              {unique_id}
              <span className='label'>{status}</span>
            </div>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Kewarganegaraan</label>
            </div>
            <div className='det-value'>{personalData.citizenship}</div>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Tangal Booking</label>
            </div>
            <div className='det-value'>{personalData.bookingTime}</div>
          </div>
        </div>
        <div className='detail-mid'>
          <div className='detail-mid-top'>Form {history.location.state}</div>
          <div className='detail-mid-menu'>
            <span
              onClick={() => setMenuActive('general')}
              className={`${menuActive === 'general' ? 'active' : ''}`}
            >
              <span className='name'>General Information</span>
              <img src={Right} alt='icon-right' />
            </span>
            <span
              onClick={() => setMenuActive('personal')}
              className={`${menuActive === 'personal' ? 'active' : ''}`}
            >
              <span className='name'>Personal Information</span>
              <img src={Right} alt='icon-right' />
            </span>
            <span
              className={`${menuActive === 'passport' ? 'active' : ''}`}
              onClick={() => setMenuActive('passport')}
            >
              <span className='name'>Passport</span>
              <img src={Right} alt='icon-right' />
            </span>
            <span
              onClick={() => setMenuActive('other')}
              className={`${menuActive === 'other' ? 'active' : ''}`}
            >
              <span className='name'>Other</span>
              <img src={Right} alt='icon-right' />
            </span>
            <span
              onClick={() => setMenuActive('photo')}
              className={`${menuActive === 'photo' ? 'active' : ''}`}
            >
              <span className='name'>Photo</span>
              <img src={Right} alt='icon-right' />
            </span>
            <span
              onClick={() => setMenuActive('sign')}
              className={`${menuActive === 'sign' ? 'active' : ''}`}
            >
              <span className='name'>Sign In</span>
            </span>
          </div>
          <TabDetail
            menuActive={menuActive}
            data={detailData}
            country_options={country_options}
            provinceOptions={provinceOptions}
            portOptions={portOptions}
            cityIdList={cityIdList}
          />
          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.goBack()}>
              Cancel
            </Button>
            <div className='btn-wrap-right'>
              {status === 'SUBMITTED' && (
                <>
                  <Button color='warn' onClick={() => handlerModal('tunda')}>
                    <Icon icon='close' />
                    Tunda Proses
                  </Button>
                  <Button color='primary' onClick={() => handlerModal('process')}>
                    <Icon icon='check' />
                    Proses
                  </Button>
                </>
              )}
              {status === 'PROCESS' && (
                <>
                  <Button color='primary' onClick={() => handlerModal('finish')}>
                    <Icon icon='check' />
                    Proses
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className={get(modalProps, ['className']) || 'confirm'}
        buttonProps={[
          {
            btnStyle: { color: 'info', outline: true },
            label: get(modalProps, ['cta', 'no', 'text']),
            onClick: () => {
              setShowModal(false);
            }
          },
          {
            btnStyle: { color: get(modalProps, ['cta', 'yes', 'color']) },
            label: get(modalProps, ['cta', 'yes', 'text']),
            onClick: handlerSubmitAction
          }
        ]}
        title={get(modalProps, ['title'])}
        desc={get(modalProps, ['desc'])}
      />
    </div>
  );
};

DetailVisa.propTypes = {
  history: PropTypes.object,
  country: PropTypes.object,
  getCountryList: PropTypes.func,
  getCityId: PropTypes.func
};

const mapStateToProps = (state) => ({
  visaDetail: {
    status: state.getVisaDetailReducer.status,
    error: state.getVisaDetailReducer.error,
    data: state.getVisaDetailReducer.data
  },
  approve: {
    status: state.patchVisaApproveReducer.status,
    error: state.patchVisaApproveReducer.error,
    data: state.patchVisaApproveReducer.data
  },
  pending: {
    status: state.patchVisaPendingReducer.status,
    error: state.patchVisaPendingReducer.error,
    data: state.patchVisaPendingReducer.data
  },
  process: {
    status: state.patchVisaProcessReducer.status,
    error: state.patchVisaProcessReducer.error,
    data: state.patchVisaProcessReducer.data
  },
  country: {
    status: state.countryListReducer.status,
    error: state.countryListReducer.error,
    data: state.countryListReducer.data
  },
  port: {
    status: state.portListReducer.status,
    error: state.portListReducer.error,
    data: state.portListReducer.data
  },
  province: {
    status: state.provinceIDReducer.status,
    error: state.provinceIDReducer.error,
    data: state.provinceIDReducer.data
  },
  citiesId: {
    status: state.cityIdReducer.status,
    error: state.cityIdReducer.error,
    data: state.cityIdReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getVisaDetail,
    patchApprove,
    patchProcess,
    patchPending,
    getCountryList,
    getPortList,
    getProvinceIDList,
    getCityId
  }),
  withRouter
)(DetailVisa);
