import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Alert } from 'reactstrap';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import StateZip from '../../components/StateZip';
import Dropdown from '../../../../components/Dropdown';
import Icon from '../../../../components/Icon';

import { errorAlertScroll } from '../../../../helpers/functions';
import { getCountryList } from '../../PassportForm/action';
import { postInvestmentInquiry, resetPostData } from './action';

// import './style.scss';

const InvestmentInquiry = ({
  history,
  i18n,
  country,
  getCountryList,
  investmentInquiry,
  postInvestmentInquiry,
  resetPostData
}) => {
  const { data, error, status } = investmentInquiry;

  const [form, setForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [errorSave, setErrorSave] = useState('');
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);
  const country_options = get(country, ['data']);
  const business_interest_options = [
    {
      value: 'Opening Business',
      label: i18n('economy.options.opening_business')
    },
    {
      value: 'Opening Representative Office',
      label: i18n('economy.options.representative_office')
    }
  ];

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      first_name: yup.string().required('required'),
      last_name: yup.string().required('required'),
      current_position: yup.string().required('required'),
      company_name: yup.string().required('required'),
      business_field: yup.string().required('required'),
      primary_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      secondary_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      primary_email: yup.string().email('email').required('required'),
      secondary_email: yup.string().email('email').required('required'),
      address: yup.string().required('required'),
      city: yup.string().required('required'),
      state: yup.string().required('required'),
      zip_code: yup.string().required('required'),
      country: yup.object().shape({
        name: yup.string().required('required')
      }),
      business_interest: yup.object().shape({
        value: yup.string().required('required')
      }),
      inquiry: yup.string().required('required')
      // other, address 2
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setShowModal(true);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const submit = () => {
    setShowModal(false);
    postInvestmentInquiry({
      ...form,
      country: form.country.name,
      business_interest: form.business_interest.value
    });
  };

  const handlerChanges = (value, key, errorKey) => {
    if (key === 'marital_status') {
      const tmp_form = { ...form };
      if (value !== 'married') {
        tmp_form.spouse = {};
      }
      setForm({ ...tmp_form, [key]: value });
    } else {
      setForm({ ...form, [key]: value });
    }
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
      const messageDetail = get(error, [
        'response',
        'data',
        'error',
        'detail',
        0,
        'message'
      ]);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setIsSuccessModal(true);
      setShowModal(true);
      setForm({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    getCountryList();
    return () => {
      resetPostData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let modalProps = {
    className: 'confirm',
    buttonProps: [
      {
        btnStyle: { color: 'info', outline: true },
        label: i18n('cta.no'),
        onClick: () => setShowModal(false)
      },
      {
        btnStyle: { color: 'info' },
        label: i18n('cta.ok'),
        onClick: () => submit()
      }
    ],
    title: i18n('confirm_modal.title'),
    desc: i18n('confirm_modal.desc')
  };

  if (isSuccessModal) {
    modalProps = {
      className: 'success complaint',
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('cta.ok'),
          onClick: () => setShowModal(false)
        }
      ],
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>{i18n('success_save_modal.title')}</div>
        </div>
      )
    };
  }

  return (
    <>
      {status === 'pending' && <Loading />}
      <Banner
        title={i18n('economy.form.investment_inquiry')}
        subtitle={i18n('economy.form.investment_inquiry')}
        backButtonClick={() => history.goBack()}
      />
      <CardDetail
        title={i18n('economy.form.investment_inquiry')}
        className='pensosbud-form form'
      >
        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        <form onSubmit={handlerSubmit} id='graduate'>
          <div className='form-container'>
            <div className='form-wrap'>
              <div className='title'>{i18n('form_personal_data.instruction_name')}</div>
              <TextInput
                label={i18n('form.first_name.label')}
                placeholder={i18n('form.first_name.placeholder')}
                value={get(form, ['first_name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'first_name')
                }}
                errorMessage={
                  get(errors, ['first_name']) &&
                  i18n(`validation.${get(errors, ['first_name'])}`)
                }
              />
              <TextInput
                label={i18n('form.last_name.label')}
                placeholder={i18n('form.last_name.placeholder')}
                value={get(form, ['last_name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'last_name')
                }}
                errorMessage={
                  get(errors, ['last_name']) &&
                  i18n(`validation.${get(errors, ['last_name'])}`)
                }
              />
              <TextInput
                label={i18n('form.current_position.label')}
                placeholder={i18n('form.current_position.placeholder')}
                value={get(form, ['current_position'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'current_position')
                }}
                errorMessage={
                  get(errors, ['current_position']) &&
                  i18n(`validation.${get(errors, ['current_position'])}`)
                }
              />
              <TextInput
                label={i18n('form.company_name.label')}
                placeholder={i18n('form.company_name.placeholder')}
                value={get(form, ['company_name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'company_name')
                }}
                errorMessage={
                  get(errors, ['company_name']) &&
                  i18n(`validation.${get(errors, ['company_name'])}`)
                }
              />
              <TextInput
                label={i18n('form.business_fields.label')}
                placeholder={i18n('form.business_fields.placeholder')}
                value={get(form, ['business_field'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'business_field')
                }}
                errorMessage={
                  get(errors, ['business_field']) &&
                  i18n(`validation.${get(errors, ['business_field'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>{i18n('form_personal_data.instruction_phone')}</div>
              <TextInput
                label={i18n('form.primary.label')}
                placeholder={i18n('form.primary.placeholder')}
                value={get(form, ['primary_phone'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'primary_phone')
                }}
                errorMessage={
                  get(errors, ['primary_phone']) &&
                  i18n(`validation.${get(errors, ['primary_phone'])}`)
                }
              />
              <TextInput
                label={i18n('form.alternative.label')}
                placeholder={i18n('form.alternative.placeholder')}
                value={get(form, ['secondary_phone'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'secondary_phone')
                }}
                errorMessage={
                  get(errors, ['secondary_phone']) &&
                  i18n(`validation.${get(errors, ['secondary_phone'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>{i18n('form_personal_data.instruction_email')}</div>

              <TextInput
                label={i18n('form.primary.label')}
                placeholder={i18n('form.primary.placeholder')}
                value={get(form, ['primary_email'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'primary_email')
                }}
                errorMessage={
                  get(errors, ['primary_email']) &&
                  i18n(`validation.${get(errors, ['primary_email'])}`)
                }
              />
              <TextInput
                label={i18n('form.alternative.label')}
                placeholder={i18n('form.alternative.placeholder')}
                value={get(form, ['secondary_email'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'secondary_email')
                }}
                errorMessage={
                  get(errors, ['secondary_email']) &&
                  i18n(`validation.${get(errors, ['secondary_email'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('form_personal_data.instruction_address')}
              </div>

              <TextInput
                label={i18n('form.street_address.label')}
                placeholder={i18n('form.street_address.placeholder')}
                value={get(form, ['address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'address')
                }}
                errorMessage={
                  get(errors, ['address']) &&
                  i18n(`validation.${get(errors, ['address'])}`)
                }
              />

              <TextInput
                label={i18n('form.street_address_2.label')}
                placeholder={i18n('form.street_address_2.placeholder')}
                value={get(form, ['street_address_2'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'street_address_2')
                }}
                errorMessage={
                  get(errors, ['street_address_2']) &&
                  i18n(`validation.${get(errors, ['street_address_2'])}`)
                }
              />
              <StateZip
                isStateText
                i18n={i18n}
                form={form}
                handlerChanges={handlerChanges}
                errors={errors}
              />
              <Dropdown
                className='row input-container'
                label={i18n('form.country.label')}
                placeholder={i18n('form.country.placeholder')}
                value={get(form, ['country'])}
                options={country_options}
                onClick={(item) => handlerChanges(item, 'country', 'country.name')}
                isValueObject
                dropdownToggleProps={{
                  tag: 'div',
                  className: 'input-dropdown'
                }}
                valueKey='name'
                labelKey='name'
                withAutocomplete
                errorMessage={
                  get(errors, ['country.name']) &&
                  i18n(`validation.${get(errors, ['country.name'])}`)
                }
              />
              <Dropdown
                className='row input-container'
                label={i18n('form.business_interest.label')}
                placeholder={i18n('form.business_interest.placeholder')}
                value={get(form, ['business_interest'])}
                options={business_interest_options}
                onClick={(item) =>
                  handlerChanges(item, 'business_interest', 'business_interest.value')
                }
                isValueObject
                dropdownToggleProps={{
                  tag: 'div',
                  className: 'input-dropdown'
                }}
                valueKey='value'
                labelKey='label'
                withAutocomplete
                errorMessage={
                  get(errors, ['business_interest.value']) &&
                  i18n(`validation.${get(errors, ['business_interest.value'])}`)
                }
              />

              <TextInput
                label={i18n('form.other.label')}
                placeholder={i18n('form.other.placeholder')}
                value={get(form, ['other'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'other')
                }}
                errorMessage={
                  get(errors, ['other']) && i18n(`validation.${get(errors, ['other'])}`)
                }
              />

              <TextInput
                label={i18n('form.inquiry.label')}
                placeholder={i18n('form.inquiry.placeholder')}
                value={get(form, ['inquiry'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'inquiry')
                }}
                errorMessage={
                  get(errors, ['inquiry']) &&
                  i18n(`validation.${get(errors, ['inquiry'])}`)
                }
              />
            </div>
          </div>

          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.goBack()}>
              {i18n('cta.cancel')}
            </Button>
            <Button color='primary' type='submit' className='pass-btn'>
              {i18n('cta.submit')}
            </Button>
          </div>
        </form>
      </CardDetail>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

InvestmentInquiry.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  country: PropTypes.object,
  getCountryList: PropTypes.func,
  investmentInquiry: PropTypes.object,
  postInvestmentInquiry: PropTypes.func,
  resetPostData: PropTypes.func
};

const mapStateToProps = (state) => ({
  country: {
    status: state.countryListReducer.status,
    error: state.countryListReducer.error,
    data: state.countryListReducer.data
  },
  investmentInquiry: {
    status: state.investmentInquiryReducer.status,
    error: state.investmentInquiryReducer.error,
    data: state.investmentInquiryReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getCountryList, postInvestmentInquiry, resetPostData }),
  withRouter
)(InvestmentInquiry);
