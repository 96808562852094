import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { Alert } from 'reactstrap';
import get from 'lodash/get';

import { errorAlertScroll } from '../../../../helpers/functions';

import { apiCaller } from '../../../../helpers/apiCaller';

import './styles.scss';

const Signature = ({ signaturePadRef, unique_number, setFiles, i18n, filePassport }) => {
  const [alertDetail, setAlertDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const clear = () => {
    signaturePadRef.current.clear();
  };

  const handleUpload = () => {
    // Create an object of formData
    const formData = new FormData();
    const canvas = signaturePadRef.current.getCanvas();
    canvas.toBlob((blob) => {
      formData.append('file', blob, `signature${unique_number}.png`);
      // Update the formData object
      formData.append('doc_type', 'signature');
      formData.append('unique_number', unique_number);
      setLoading(true);
      // Send formData object
      apiCaller('storages/passport', 'post', formData)
        .then((res) => {
          if (get(res, ['response', 'data', 'status']) === 422) {
            const message = get(res, [
              'response',
              'data',
              'error',
              'detail',
              0,
              'message'
            ]);
            handlerAlert({
              color: 'danger',
              message: `Gagal upload. Coba sekali lagi. ${message}`
            });
          } else {
            const { data } = res;
            setFiles({
              url: get(data, ['data', 'url']),
              item: 'img'
            });
            handlerAlert({
              color: 'success',
              message: 'Signature success uploaded.'
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          const errRes = err.response;
          const message = get(errRes, ['data', 'error', 'detail', 0, 'message']);
          handlerAlert({
            color: 'danger',
            message: `Gagal upload. Coba sekali lagi. ${message}`
          });
        });
    });
  };

  const handlerAlert = (data) => {
    setVisible(true);
    setAlertDetail(data);
    setTimeout(() => {
      setVisible(false);
    }, 5000);
    errorAlertScroll();
  };

  return (
    <>
      <Alert color={get(alertDetail, ['color'])} isOpen={visible}>
        {get(alertDetail, ['message'])}
      </Alert>
      <div className='signature-container'>
        <div className='label'>{i18n('form_document.24')}</div>
        {filePassport ? (
          <div className='sign-img-wrap'>
            <img src={filePassport} alt='img-preview' />
            <span
              onClick={() => {
                setFiles();
              }}
            >
              Delete
            </span>
          </div>
        ) : (
          <div className='sign-wrap'>
            <SignatureCanvas
              canvasProps={{ width: 345, height: 161, className: 'sigCanvas' }}
              ref={signaturePadRef}
            />
            <div className='btn-sign-wrap'>
              <div className='clear' onClick={clear}>
                Clear
              </div>
              <div className='upload-button' onClick={handleUpload}>
                {loading ? 'Loading ....' : 'Upload'}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

Signature.propTypes = {
  signaturePadRef: PropTypes.object,
  unique_number: PropTypes.string,
  setFiles: PropTypes.func,
  i18n: PropTypes.func,
  filePassport: PropTypes.string
};

export default Signature;
