import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';

import { getStatementServiceId } from '../../../form/StatementLetterForm/functions';

const Birth = ({ i18n, statementServiceList }) => {
  const lang = (type, params) => i18n(`statement_letter.tab4.${type}`, { ...params });
  const serviceId = getStatementServiceId(statementServiceList, 'death2');

  const stepOuterList = [
    {
      label: i18n('statement_letter.step.report_self.title')
    },
    {
      label: i18n('statement_letter.step.take_number.title'),
      cta: i18n('statement_letter.step.take_number.cta'),
      link: `/statement-letter/registration/create?type=statement&serviceId=${serviceId}`
    },
    {
      label: i18n('statement_letter.step.field_form.title', {
        type: i18n('statement_letter.tab4.title')
      }),
      linkType: true,
      iconCta: 'download',
      ctaColor: 'outline',
      cta: i18n('statement_letter.step.field_form.cta'),
      link: 'https://drive.google.com/file/d/1R60tiXpqrAuTFtdietQ1cZz6V8raTjIi/view'
    }
  ];

  const instructionList = [
    i18n('statement_letter.instruction_step.copy_passport_death'),
    i18n('statement_letter.instruction_step.letter_death'),
    i18n('statement_letter.instruction_step.letter_funeral'),
    i18n('statement_letter.instruction_step.letter_funeral_balm'),
    i18n('statement_letter.instruction_step.letter_funeral_kremasi'),
    i18n('statement_letter.instruction_step.copy_id_recipient_body'),
    i18n('statement_letter.instruction_step.no_fee')
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
      introStep={i18n('statement_letter.tab1.step.instruction')}
    />
  );
};

Birth.propTypes = {
  i18n: PropTypes.func,
  statementServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Birth);
