import React from 'react';
import { withRouter, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';
import moment from 'moment';

const DetailPariwisata = ({ history, i18n }) => {
  const match = useRouteMatch();
  const { id } = match.params;
  const data = history.location.state;

  return (
    <>
      {data.map((data, index) => (
        <>
          {data._id === id ? (
            <div className='admin-news-detail'>
              <span
                className='back-homepage'
                onClick={() => history.push('/admin/pariwisata')}
              >
                <span className='btn-back' />
                {data.title}
              </span>
              <div
                className='news-detail'
                style={{ height: 720, overflowY: 'scroll', borderRadius: 8 }}
              >
                <img src={data.image_url} alt='news-title' />
                <h2>{data.title}</h2>
                <table>
                  <tr>
                    <td>Datetime</td>
                    <td>{moment(data.datetime).format('YYYY-MM-DD HH:mm')}</td>
                  </tr>
                  <tr>
                    <td>Place</td>
                    <td>{data.place}</td>
                  </tr>
                  <tr>
                    <td>Tags</td>
                    <td>
                      {data.tags.map((tag, i) => {
                        return tag + ', ';
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Wisata Terdekat</td>
                    <td>
                      {data.nearest_places.map((near, i) => {
                        return near + ', ';
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Content</td>
                    <td>
                      <div
                        className='news-content'
                        dangerouslySetInnerHTML={{
                          __html: data.content
                        }}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          ): ''}
        </>
      ))}
    </>
  );
};

DetailPariwisata.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func
};

export default withRouter(DetailPariwisata);
