import axios from 'axios';
import { apiCaller } from '../../../helpers/apiCaller';

const getNewsRequest = () => ({
  type: 'GET_NEWS_REQUEST'
});

const getNewsSuccess = (data) => ({
  type: 'GET_NEWS_SUCCESS',
  payload: data
});

const getNewsFailure = (error) => ({
  type: 'GET_NEWS_FAILURE',
  payload: error
});

const getNews = () => {
  return (dispatch) => {
    dispatch(getNewsRequest());
    return axios
      .get(process.env.REACT_APP_BACKEND_URI + '/news')
      .then((data) => dispatch(getNewsSuccess(data)))
      .catch((error) => dispatch(getNewsFailure(error)));
  };
};

const postNewsRequest = () => ({
  type: 'POST_NEWS_REQUEST'
});

const postNewsSuccess = (data) => ({
  type: 'POST_NEWS_SUCCESS',
  payload: data
});

const postNewsFailure = (error) => ({
  type: 'POST_NEWS_FAILURE',
  payload: error
});

const postNews = (params) => {
  return (dispatch) => {
    dispatch(postNewsRequest());
    return apiCaller('admin/news', 'post', params, true)
      .then((data) => dispatch(postNewsSuccess(data)))
      .catch((error) => dispatch(postNewsFailure(error)));
  };
};

const getNewsDetailRequest = () => ({
  type: 'GET_NEWS_DETAIL_ADMIN_REQUEST'
});

const getNewsDetailSuccess = (data) => ({
  type: 'GET_NEWS_DETAIL_ADMIN_SUCCESS',
  payload: data
});

const getNewsDetailFailure = (error) => ({
  type: 'GET_NEWS_DETAIL_ADMIN_FAILURE',
  payload: error
});

const getNewsDetail = (id) => {
  return (dispatch) => {
    dispatch(getNewsDetailRequest());
    return axios
      .get(process.env.REACT_APP_BACKEND_URI + '/news/' + id)
      .then((data) => dispatch(getNewsDetailSuccess(data)))
      .catch((error) => dispatch(getNewsDetailFailure(error)));
  };
};

const deleteNewsRequest = () => ({
  type: 'DELETE_NEWS_REQUEST'
});

const deleteNewsSuccess = (data) => ({
  type: 'DELETE_NEWS_SUCCESS',
  payload: data
});

const deleteNewsFailure = (error) => ({
  type: 'DELETE_NEWS_FAILURE',
  payload: error
});

const deleteNews = (id) => {
  return (dispatch) => {
    dispatch(deleteNewsRequest());
    return apiCaller('admin/news/' + id, 'delete', '', true)
      .then((data) => dispatch(deleteNewsSuccess(data)))
      .catch((error) => dispatch(deleteNewsFailure(error)));
  };
};

const updateNewsRequest = () => ({
  type: 'UPDATE_NEWS_REQUEST'
});

const updateNewsSuccess = (data) => ({
  type: 'UPDATE_NEWS_SUCCESS',
  payload: data
});

const updateNewsFailure = (error) => ({
  type: 'UPDATE_NEWS_FAILURE',
  payload: error
});

const updateNews = (id, data) => {
  return (dispatch) => {
    dispatch(updateNewsRequest());
    return apiCaller('admin/news/' + id, 'put', data, true)
      .then((data) => dispatch(updateNewsSuccess(data)))
      .catch((error) => dispatch(updateNewsFailure(error)));
  };
};

export { getNews, postNews, getNewsDetail, deleteNews, updateNews };
