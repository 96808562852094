import { apiCaller } from '../../../../helpers/apiCaller';

// INVESTMENT INQUIRY
const getInvestmentStatementListRequest = () => ({
  type: 'GET_INVESTMENT_STATEMENT_LIST_REQUEST'
});

const getInvestmentStatementListSuccess = (data) => ({
  type: 'GET_INVESTMENT_STATEMENT_LIST_SUCCESS',
  payload: data
});

const getInvestmentStatementListFailure = (error) => ({
  type: 'GET_INVESTMENT_STATEMENT_LIST_FAILURE',
  payload: error
});

const resetInvestmentStatementList = () => ({
  type: 'RESET_INVESTMENT_STATEMENT_LIST_LIST'
});

const getInvestmentStatementList = (params) => {
  return (dispatch) => {
    dispatch(getInvestmentStatementListRequest());
    return apiCaller('admin/investment/statement', 'get', undefined, true, params)
      .then((data) => dispatch(getInvestmentStatementListSuccess(data)))
      .catch((error) => dispatch(getInvestmentStatementListFailure(error)));
  };
};

// INVESTMENT INQUIRY DETAIL
const getInvestmentStatementDetailRequest = () => ({
  type: 'GET_INVESTMENT_STATEMENT_DETAIL_REQUEST'
});

const getInvestmentStatementDetailSuccess = (data) => ({
  type: 'GET_INVESTMENT_STATEMENT_DETAIL_SUCCESS',
  payload: data
});

const getInvestmentStatementDetailFailure = (error) => ({
  type: 'GET_INVESTMENT_STATEMENT_DETAIL_FAILURE',
  payload: error
});

const resetInvestmentStatementDetail = () => ({
  type: 'RESET_INVESTMENT_STATEMENT_DETAIL_LIST'
});

const getInvestmentStatementDetail = (id) => {
  return (dispatch) => {
    dispatch(getInvestmentStatementDetailRequest());
    return apiCaller(`investment/investment/statement/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getInvestmentStatementDetailSuccess(data)))
      .catch((error) => dispatch(getInvestmentStatementDetailFailure(error)));
  };
};

export {
  resetInvestmentStatementList,
  getInvestmentStatementList,
  resetInvestmentStatementDetail,
  getInvestmentStatementDetail
};
