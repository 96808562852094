import React from 'react';
import InstructionWrap from '../../../../components/InstructionWrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PreClearance = ({ i18n }) => {
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('visa_page.tab9.title')}</div>
      <main>
        <InstructionWrap
          instruction={i18n('visa_page.tab9.step.instruction1')}
          instructionList={i18n('visa_page.instruction_step.clearance_req')}
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab9.step.instruction2')}
          instructionList={i18n('visa_page.instruction_step.clearance_req2')}
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab9.step.instruction3')}
          instructionList=''
        />
      </main>
    </div>
  );
};

PreClearance.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(PreClearance);
