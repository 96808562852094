import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import get from 'lodash/get';
import { Alert } from 'reactstrap';
import * as yup from 'yup';

import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import Icon from '../../../../components/Icon';
import Checkbox from '../../../../components/input/Checkbox';

import { parseQuery, errorAlertScroll } from '../../../../helpers/functions';
import { passport_service } from './functions';
import {
  postBookAppointment,
  postMultiBookAppointment,
  getAppointmentDetail
} from './action';
import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import './styleConfirm.scss';

const ConfirmSchedule = ({
  history,
  i18n,
  slotTime,
  postBookAppointment,
  bookAppointment,
  multiBookAppointment,
  postMultiBookAppointment,
  getAppointmentDetail,
  appointmentDetail
}) => {
  const { location } = history;
  const { search } = location;
  const searchQuery = parseQuery(search) || {};
  const { type, num, serviceId } = searchQuery;

  const [form, setForm] = useState(slotTime);
  const [error, setError] = useState();
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [loading, setLoading] = useState(false);
  const [checklist, setChecklist] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [newQuery, setNewQuery] = useState();
  const {
    status: singleBookStatus,
    error: errorSingleBookAppointment,
    data: dataSingle
  } = bookAppointment;
  const {
    status: multiStatus,
    error: errorMultiBookAppointment,
    data: dataMulti
  } = multiBookAppointment;
  const { data: appointmentDetailData } = appointmentDetail;

  const status = parseInt(num) > 1 ? multiStatus : singleBookStatus;
  const errorBookAppointment =
    parseInt(num) > 1 ? errorMultiBookAppointment : errorSingleBookAppointment;
  const data = parseInt(num) > 1 ? dataMulti : dataSingle;

  const validation = () => {
    const params = {
      agreement: checklist.agreement,
      form
    };

    let schema = yup.object().shape({
      agreement: yup.boolean().required('required'),
      form: yup
        .array()
        .of(
          yup.object().shape({
            end_at: yup.string(),
            scheduled_at: yup.string(),
            _id: yup.string(),
            email: yup.string().email('email').required('required')
          })
        )
        .required('required')
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        setShowModal(true);
      })
      .catch((err) => {
        setError(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handleSubmit = () => {
    const params = [];

    slotTime.map(({ end_at, scheduled_at, email = '' }) => {
      params.push({
        passport_service_id: serviceId,
        end_at,
        scheduled_at,
        email
      });
    });

    setLoading(true);
    setShowModal(false);
    if (params.length === 1) {
      postBookAppointment(params[0]);
    } else {
      postMultiBookAppointment(params);
    }
  };

  const handlerEmail = (value, key) => {
    const tmpForm = [...form];
    tmpForm.map((item, idx) => {
      if (idx === key) {
        item.email = value;
      }
    });

    if (error && error[`form[${key}].email`]) {
      error[`form[${key}].email`] = undefined;
    }

    setForm(tmpForm);
  };

  const handlerCheckList = (keyCheck) => {
    const tmpCheckList = { ...checklist };
    const tmpError = { ...error };
    tmpCheckList[keyCheck] = !get(tmpCheckList, [keyCheck]);

    if (tmpError && tmpError[keyCheck]) {
      tmpError[keyCheck] = undefined;
    }
    setError(tmpError);
    setChecklist(tmpCheckList);
  };

  useEffect(() => {
    if (slotTime.length === 0) {
      history.goBack();
    }
  }, [history, slotTime]);

  useEffect(() => {
    if (status !== 'pending' && loading) {
      setLoading(false);
      if (status === 'resolve') {
        const { passport_service_id, id, token } =
          parseInt(num) > 1 ? get(data, [0]) : data;
        setNewQuery(
          `?type=passport&serviceId=${passport_service_id}&appointmentId=${id}&token=${token}`
        );
        getAppointmentDetail(`/${id}`);
        setTypeModal('success');
        setShowModal(true);
      } else {
        const message = get(errorBookAppointment, ['data', 'error', 'message']);
        setErrorSave(message);
        setVisible(true);
        setTimeout(() => {
          setVisible(false);
          setErrorSave('');
        }, 5000);
      }
    }
  }, [data, errorBookAppointment, getAppointmentDetail, loading, num, status, type]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  let modalProps = {
    className: 'confirm',
    title: i18n('confirm_modal.title'),
    desc: i18n('confirm_modal.desc'),
    buttonProps: [
      {
        btnStyle: { color: 'grey' },
        label: i18n('cta.no'),
        onClick: () => setShowModal(false)
      },
      {
        btnStyle: { color: 'primary' },
        label: i18n('cta.ok'),
        onClick: () => handleSubmit()
      }
    ]
  };

  if (typeModal === 'success') {
    const { passport_service_title } =
      get(appointmentDetailData, [0]) || appointmentDetailData || {};
    modalProps = {
      className: 'success',
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>{i18n('passport_schedule_confirm.modal_success.title')}</div>
        </div>
      ),
      desc: (
        <div className='desc-wrap'>
          <div>{i18n('passport_schedule_confirm.modal_success.desc')}</div>
          <div className='type'>{i18n(`paspor_page.${passport_service_title}`)}</div>
        </div>
      ),
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('passport_schedule_confirm.modal_success.cta.yes'),
          onClick: () => history.push(`/registration/create${newQuery}`)
        }
      ]
    };
  }

  return (
    <>
      <Banner
        title={i18n(`paspor_page.${passport_service[type].title}`)}
        subtitle={
          <>
            <div className='text'>{i18n('paspor_page.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('paspor_page.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      <div className='container'>
        {loading && <Loading />}
        <CardDetail
          title={`${i18n(
            'passpor_schedule.title'
          )} ${i18n('passport_schedule_form.for_people', { num: num ? num : 1 })}`}
          description={i18n('passpor_schedule.subtile')}
          className='schedule-form-confirm'
        >
          <div className='confirm-container'>
            <Alert color='danger' isOpen={visible} className='m0'>
              {errorSave}
            </Alert>
            <div className='title'>
              {i18n('passport_schedule_confirm.email_instruction')}
            </div>
            <div className='table-email-container'>
              <div className='head row-table'>
                <div className='col1'>{i18n('passport_schedule_confirm.applicant')}</div>
                <div className='col2'>{i18n('passport_schedule_confirm.date')}</div>
                <div className='col3'>{i18n('passport_schedule_confirm.by')}</div>
                <div className='col5'>
                  {i18n('passport_schedule_confirm.email_applicant')}
                </div>
              </div>
              <div className='main-table'>
                {form.map(({ scheduled_at, email }, idx) => (
                  <div className='row-table' key={idx}>
                    <div className='col1'>
                      {idx === 0
                        ? i18n('passport_schedule_confirm.you')
                        : `${i18n('passport_schedule_confirm.applicant')} 
                      ${parseInt(idx) + 1}`}
                    </div>
                    <div className='col2'>
                      {moment.utc(scheduled_at).format('DD-MM-YYYY')}{' '}
                      {moment.utc(scheduled_at).format('hh:mm')}
                    </div>
                    <div className='col3'>KJRI New York</div>
                    <div className='col5'>
                      <TextInput
                        placeholder={i18n('passport_schedule_confirm.email_applicant')}
                        value={email}
                        inputProps={{
                          onChange: (e) => handlerEmail(e.target.value, idx)
                        }}
                        errorMessage={
                          get(error, [`form[${idx}].email`]) &&
                          i18n(`validation.${get(error, [`form[${idx}].email`])}`)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='agreement-container'>
              <Checkbox
                className='check-list'
                label={
                  <>
                    <span>{i18n('passport_schedule_confirm.agreement')} </span>
                    <span
                      className='blue-text'
                      onClick={() => window.open('/passpor/term-condition')}
                    >
                      {i18n('passport_schedule_confirm.agreement_tnc')}
                    </span>
                  </>
                }
                checked={get(checklist, ['agreement'])}
                onChange={() => handlerCheckList('agreement')}
                errorMessage={get(error, ['agreement']) && i18n('validation.selected')}
              />
              <Checkbox
                className='check-list'
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: i18n('passport_schedule_confirm.send_promotion')
                    }}
                  />
                }
                checked={get(checklist, ['send_promotion'])}
                onChange={() => handlerCheckList('send_promotion')}
                errorMessage={
                  get(error, ['send_promotion']) && i18n('validation.selected')
                }
              />
              <div
                className='red-info'
                dangerouslySetInnerHTML={{
                  __html: i18n('passport_schedule_confirm.notes', {
                    name: i18n(
                      `paspor_page.${passport_service[type].title}`
                    ).toLowerCase()
                  })
                }}
              />
            </div>
          </div>
          <div className='btn-wrap'>
            <Button color='link' onClick={() => history.goBack()}>
              {i18n('nav.back')}
            </Button>
            <Button color='primary' onClick={() => validation()}>
              {i18n('nav.confirm')}
            </Button>
          </div>
        </CardDetail>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

ConfirmSchedule.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  slotTime: PropTypes.array,
  postBookAppointment: PropTypes.func,
  bookAppointment: PropTypes.object,
  multiBookAppointment: PropTypes.object,
  postMultiBookAppointment: PropTypes.func,
  getAppointmentDetail: PropTypes.func,
  appointmentDetail: PropTypes.object
};

const mapStateToProps = (state) => ({
  slotTime: state.scheduleReducer.slotTime,
  bookAppointment: {
    status: state.bookAppointmentReducer.status,
    error: state.bookAppointmentReducer.error,
    data: state.bookAppointmentReducer.data
  },
  multiBookAppointment: {
    status: state.multiBookAppointmentReducer.status,
    error: state.multiBookAppointmentReducer.error,
    data: state.multiBookAppointmentReducer.data
  },
  appointmentDetail: {
    status: state.appointmentDetailReducer.status,
    error: state.appointmentDetailReducer.error,
    data: state.appointmentDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postBookAppointment,
    postMultiBookAppointment,
    getAppointmentDetail
  }),
  withRouter
)(ConfirmSchedule);
