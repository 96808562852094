import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../components/Button';
import DatepickerRange from '../components/DatepickerRange';
import Content from './components/MainContent';

import {
  getDocLegalList,
  resetDocLegalList,
  getDocLegalStatistic,
  getDocLegalStatisticWeek,
  getDocLegalStatisticMonth,
  getDocLegalStatisticAll
} from './action';
import { getDocLegalServiceList } from '../../form/DocLegalizationForm/action';
import { getDocLegalServiceId } from '../../form/DocLegalizationForm/functions';
import docLegalServiceTitle from '../../../constant/docLegalizationService';
import { getStatisticDate, downloadFile } from '../functions';

const AdminDocLegal = ({
  getDocLegalList,
  docLegalList,
  getDocLegalServiceList,
  docLegalService,
  resetDocLegalList,
  getDocLegalStatistic,
  getDocLegalStatisticWeek,
  getDocLegalStatisticMonth,
  getDocLegalStatisticAll,
  docLegalStatisticAll,
  docLegalStatistic,
  docLegalStatisticWeek,
  docLegalStatisticMonth
}) => {
  const { type } = useParams();

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [activeTab, setActiveTab] = useState('1');
  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();
  const statusTabList = {
    '1': 'REGISTERED',
    '2': 'PROCESS',
    '3': 'APPROVED',
    '4': 'PENDING'
  };

  const title = docLegalServiceTitle[type];
  const docLegalListData = get(docLegalList, ['data', 'data']) || [];
  const docLegalServiceList = get(docLegalService, ['data']) || [];
  const pagination = get(docLegalList, ['data', 'pagination']) || {};

  const docLegalServiceId = getDocLegalServiceId(docLegalServiceList, type);
  const loading =
    get(docLegalService, ['status']) === 'pending' ||
    get(docLegalList, ['status']) === 'pending';

  const getAllStatistic = () => {
    const allStatistic = get(docLegalStatisticAll, ['data']) || [];
    const summaryData = {
      numOfIn: String(0),
      numOfProcess: String(0),
      numOfFinish: String(0),
      numOfPending: String(0)
    };
    allStatistic.map(({ status, total }) => {
      switch (status) {
        case statusTabList['1']:
          summaryData.numOfIn = String(total);
          break;
        case statusTabList['2']:
          summaryData.numOfProcess = String(total);
          break;
        case statusTabList['3']:
          summaryData.numOfFinish = String(total);
          break;
        case statusTabList['4']:
          summaryData.numOfPending = String(total);
          break;
      }
    });
    return summaryData;
  };

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      }
    ];
    const dayStatistic = get(docLegalStatistic, ['data']) || [];
    const weekStatistic = get(docLegalStatisticWeek, ['data']) || [];
    const monthStatistic = get(docLegalStatisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ status, total }) => {
        switch (status) {
          case statusTabList['1']:
            summaryData[index].numOfIn = String(total);
            break;
          case statusTabList['2']:
            summaryData[index].numOfProcess = String(total);
            break;
          case statusTabList['3']:
            summaryData[index].numOfFinish = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const loadData = () => {
    const params = {
      service_id: docLegalServiceId,
      status: statusTabList[activeTab],
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getDocLegalList(params);
  };

  const loadStatisticData = (isAllOnly) => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();

    if (isAllOnly) {
      getDocLegalStatisticAll({
        service_id: docLegalServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    } else {
      getDocLegalStatistic({
        service_id: docLegalServiceId,
        from: today,
        to: today
      });
      getDocLegalStatisticWeek({
        service_id: docLegalServiceId,
        from: startWeek,
        to: endWeek
      });
      getDocLegalStatisticMonth({
        service_id: docLegalServiceId,
        from: startMonth,
        to: endMonth
      });
      getDocLegalStatisticAll({
        service_id: docLegalServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    }
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      service_id: docLegalServiceId
    };
    const fileName = `document-legalization-${title}-${params.from}-${params.to}`;
    downloadFile('document-legalization', params, fileName);
  };

  useEffect(() => {
    getDocLegalServiceList();
    return () => resetDocLegalList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docLegalServiceId]);

  useEffect(() => {
    resetDocLegalList();

    if (docLegalServiceId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pageDivider, page, query, docLegalServiceId]);

  useEffect(() => {
    setActiveTab('1');
    setPage(1);
    setPageDivider(5);
    setQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Content
      title={title}
      dataTab={docLegalListData}
      summaryData={getSummaryData()}
      type={type}
      loading={loading}
      onTabChanges={setActiveTab}
      onPageChanges={setPage}
      onPageDividerChanges={setPageDivider}
      onQueryChanges={setQuery}
      defaultActiveTab={activeTab}
      pagination={pagination}
      loadData={() => {
        loadData();
        loadStatisticData();
      }}
      allStatistic={getAllStatistic()}
      topActionComponent={
        <div className='detail-action' style={{ display: 'flex' }}>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={() => {
              loadData();
              loadStatisticData(true);
            }}
          />
          <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
            Download
          </Button>
        </div>
      }
    />
  );
};

AdminDocLegal.propTypes = {
  getDocLegalList: PropTypes.func,
  docLegalList: PropTypes.object,
  getDocLegalServiceList: PropTypes.func,
  docLegalService: PropTypes.object,
  resetDocLegalList: PropTypes.func,
  getDocLegalStatistic: PropTypes.func,
  docLegalStatistic: PropTypes.object,
  getDocLegalStatisticWeek: PropTypes.func,
  getDocLegalStatisticAll: PropTypes.func,
  docLegalStatisticAll: PropTypes.object
};

const mapStateToProps = (state) => ({
  docLegalList: {
    status: state.getDocLegalListReducer.status,
    error: state.getDocLegalListReducer.error,
    data: state.getDocLegalListReducer.data
  },
  docLegalService: {
    status: state.docLegalServiceReducer.status,
    error: state.docLegalServiceReducer.error,
    data: state.docLegalServiceReducer.data
  },
  docLegalStatistic: {
    status: state.getDocLegalStatisticReducer.status,
    error: state.getDocLegalStatisticReducer.error,
    data: state.getDocLegalStatisticReducer.data
  },
  docLegalStatisticWeek: {
    status: state.getDocLegalStatisticWeekReducer.status,
    error: state.getDocLegalStatisticWeekReducer.error,
    data: state.getDocLegalStatisticWeekReducer.data
  },
  docLegalStatisticMonth: {
    status: state.getDocLegalStatisticMonthReducer.status,
    error: state.getDocLegalStatisticMonthReducer.error,
    data: state.getDocLegalStatisticMonthReducer.data
  },
  docLegalStatisticAll: {
    status: state.getDocLegalStatisticAllReducer.status,
    error: state.getDocLegalStatisticAllReducer.error,
    data: state.getDocLegalStatisticAllReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getDocLegalList,
    getDocLegalServiceList,
    resetDocLegalList,
    getDocLegalStatistic,
    getDocLegalStatisticWeek,
    getDocLegalStatisticMonth,
    getDocLegalStatisticAll
  }),
  withRouter
)(AdminDocLegal);
