import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabbing from '../../../components/Tabbing2';
import DashboardTable from '../../../components/DashboardTableOld';
import Button from '../../../components/Button';
import DatePickerRange from '../components/DatepickerRange';
import MiniCard from '../../../components/CardDetail/MiniCard';
import AntrianCard from '../components/AntrianCard';
import Loading from '../../../components/Loading';
import EditJadwal from './EditJadwal';
import './style.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayHasValue } from '../../../helpers/helpers';
import { downloadFile } from '../functions';
import {
  getAntrian,
  getStatisticDay,
  getStatisticWeek,
  getStatisticMonth,
  getStatisticAntrian,
  getJadwalAntrian,
  getExportAntrian,
  patchAntrian
} from './action';
import moment from 'moment';

const AntrianPaspor = ({
  history,
  getAntrian,
  antrianList,
  getStatisticDay,
  getStatisticMonth,
  getStatisticWeek,
  statisticMonthList,
  statisticDayList,
  statisticWeekList,
  statisticAntrian,
  getStatisticAntrian,
  jadwalAntrian,
  getJadwalAntrian,
  getExportAntrian,
  patchAntrian,
  patchAntrianProcess
}) => {
  const [showModal, setShowModal] = useState(false);
  const date = new Date();
  const last = new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000);
  const week1 = new Date(date.getTime() - 3 * 24 * 60 * 60 * 1000);
  const week2 = new Date(date.getTime() + 3 * 24 * 60 * 60 * 1000);
  const month = moment().month();
  const year = moment().year();
  const [startDate, setStartDate] = useState(last);
  const [endDate, setEndDate] = useState(date);
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(25);
  const [status, setStatus] = useState('BOOKED');

  const onChangeStartDate = (startDates) => {
    setStartDate(startDates);
    dataOnChange(per_page, page, startDates, endDate, status);
  };

  const onChangeEndDate = (endDates) => {
    setEndDate(endDates);
    setPage(1);
    dataOnChange(per_page, page, startDate, endDates, status);
  };

  const onChangeTab = (e) => {
    setStatus(e);
    dataOnChange(per_page, 1, startDate, endDate, e);
  };

  const onExportAntrian = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      status: status
    };

    const fileName = `Appointments-${params.from}-${params.to}`;
    downloadFile('appointments', params, fileName);
  };

  useEffect(() => {
    if (!arrayHasValue(antrianList.data)) {
      const params = {
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
        page: page,
        per_page: per_page,
        status: status
      };

      const paramsRange = {
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      };

      const paramsDay = {
        from: moment(date).format('YYYY-MM-DD'),
        to: moment(date).format('YYYY-MM-DD')
      };

      const paramsWeek = {
        from: moment(week1).format('YYYY-MM-DD'),
        to: moment(week2).format('YYYY-MM-DD')
      };

      const paramsMonth = {
        from: year + '-' + month + 1 + '-01',
        to: year + '-' + month + 1 + '-30'
      };

      getAntrian(params);
      getStatisticAntrian(paramsRange);
      getStatisticDay(paramsDay);
      getStatisticWeek(paramsWeek);
      getStatisticMonth(paramsMonth);
      getJadwalAntrian();
    }
  }, []);

  const dataOnChange = (perPage, currentPage, startDates, endDates, status) => {
    const params = {
      from: moment(startDates).format('YYYY-MM-DD'),
      to: moment(endDates).format('YYYY-MM-DD'),
      per_page: parseInt(perPage),
      page: parseInt(currentPage),
      status: status
    };

    const paramsRange = {
      from: moment(startDates).format('YYYY-MM-DD'),
      to: moment(endDates).format('YYYY-MM-DD')
    };

    getAntrian(params);
    getStatisticAntrian(paramsRange);
  };

  const columns = [
    { dataField: ['unique_number', 'passport_service_id'], text: 'Unique Number' },
    { dataField: 'scheduled_at', text: 'Scheduled At' },
    { dataField: 'end_at', text: 'End At' },
    { dataField: ['name', 'email'], text: 'Name & Email' },
    { dataField: 'service', text: 'Services' },
    { dataField: 'status', text: 'Status' },
    { dataField: 'id', text: 'Action' },
  ];

  const columns3 = [
    { dataField: ['unique_number', 'passport_service_id'], text: 'Unique Number' },
    { dataField: 'scheduled_at', text: 'Scheduled At' },
    { dataField: 'end_at', text: 'End At' },
    { dataField: ['name', 'email'], text: 'Name & Email' },
    { dataField: 'service', text: 'Services' },
    { dataField: 'status', text: 'Status' },
  ];

  const onClickHadir = (id) => {
    patchAntrian('checkin', id);
  };

  const onClickTidakHadir = (id) => {
    patchAntrian('abort', id);
  };

  const onClickSelesai = (id) => {
    patchAntrian('done', id);
  };

  const action = [
    {
      onClick: (id) => onClickHadir(id),
      type: 'hadir'
    },
    {
      onClick: (id) => onClickTidakHadir(id),
      type: 'tidak_hadir'
    }
  ];

  const action2 = [
    {
      onClick: (id) => onClickSelesai(id),
      type: 'selesai'
    }
  ];

  let booked = 0,
    proses = 0,
    selesai = 0,
    masukDay = '0',
    prosesDay = '0',
    selesaiDay = '0',
    masukWeek = '0',
    prosesWeek = '0',
    selesaiWeek = '0',
    masukMonth = '0',
    prosesMonth = '0',
    selesaiMonth = '0';

  const dataStatisticAntrian = statisticAntrian.status === 'resolve' ? statisticAntrian.data : [];
  const dataStatisticAntrianDay = statisticDayList.status === 'resolve' ? statisticDayList.data : [];
  const dataStatisticAntrianWeek = statisticWeekList.status === 'resolve' ? statisticWeekList.data : [];
  const dataStatisticAntrianMonth = statisticMonthList.status === 'resolve' ? statisticMonthList.data : [];

  for (let i = 0; i < dataStatisticAntrian.length; i++) {
    if (dataStatisticAntrian[i].status === 'BOOKED') {
      booked = dataStatisticAntrian[i].total;
    } else if (dataStatisticAntrian[i].status === 'CHECKED_IN') {
      proses = dataStatisticAntrian[i].total;
    } else if (dataStatisticAntrian[i].status === 'DONE') {
      selesai = dataStatisticAntrian[i].total;
    }
  }

  for (let i = 0; i < dataStatisticAntrianDay.length; i++) {
    if (dataStatisticAntrianDay[i].status === 'BOOKED') {
      masukDay = dataStatisticAntrianDay[i].total;
    } else if (dataStatisticAntrianDay[i].status === 'CHECKED_IN') {
      prosesDay = dataStatisticAntrianDay[i].total;
    } else if (dataStatisticAntrianDay[i].status === 'DONE') {
      selesaiDay = dataStatisticAntrianDay[i].total;
    }
  }

  for (let i = 0; i < dataStatisticAntrianWeek.length; i++) {
    if (dataStatisticAntrianWeek[i].status === 'BOOKED') {
      masukWeek = dataStatisticAntrianWeek[i].total;
    } else if (dataStatisticAntrianWeek[i].status === 'CHECKED_IN') {
      prosesWeek = dataStatisticAntrianWeek[i].total;
    } else if (dataStatisticAntrianWeek[i].status === 'DONE') {
      selesaiWeek = dataStatisticAntrianWeek[i].total;
    }
  }

  for (let i = 0; i < dataStatisticAntrianMonth.length; i++) {
    if (dataStatisticAntrianMonth[i].status === 'BOOKED') {
      masukMonth = dataStatisticAntrianMonth[i].total;
    } else if (dataStatisticAntrianMonth[i].status === 'CHECKED_IN') {
      prosesMonth = dataStatisticAntrianMonth[i].total;
    } else if (dataStatisticAntrianMonth[i].status === 'DONE') {
      selesaiMonth = dataStatisticAntrianMonth[i].total;
    }
  }

  const data = antrianList.status === 'resolve' ? antrianList.data.data : [];
  const pagination = antrianList.status === 'resolve' ? antrianList.data.pagination : {};
  const navs = [
    {
      onClick: () => onChangeTab('BOOKED'),
      text: 'Masuk (' + booked + ')',
      status: 'BOOKED'
    },
    {
      onClick: () => onChangeTab('CHECKED_IN'),
      text: 'Proses (' + proses + ')',
      status: 'CHECKED_IN'
    },
    {
      onClick: () => onChangeTab('DONE'),
      text: 'Selesai (' + selesai + ')',
      status: 'DONE'
    }
  ];

  const table = [
    <DashboardTable
      key='1'
      columns={status === 'DONE' ? columns3 : columns}
      data={data.data}
      action={status === 'CHECKED_IN' ? action2 : action}
      search='name'
      idKeyField='id'
      pagination={pagination}
      setPerPage={setPerPage}
      setPage={setPage}
      startDate={startDate}
      endDate={endDate}
      status={status}
      dataOnChange={dataOnChange}
    />
  ];

  const antrian = {
    title: 'Pengaturan Jadwal Antrian',
    jam_buka: jadwalAntrian.status === 'resolve' ? jadwalAntrian.data[0].value : '00:00',
    time_target: jadwalAntrian.status === 'resolve' ? jadwalAntrian.data[3].value : '0',
    kuota: jadwalAntrian.status === 'resolve' ? jadwalAntrian.data[4].value : '0',
    onClick: () => setShowModal(true)
  };

  const details = [
    {
      title: '',
      list: [
        {
          title: 'Antrian Hari Ini',
          subtitle: 'Permohonan Paspor',
          numOfIn: masukDay,
          numOfProcess: prosesDay,
          numOfFinish: selesaiDay
        },
        {
          title: 'Antrian Minggu Ini',
          subtitle: 'Permohonan Paspor',
          numOfIn: masukWeek,
          numOfProcess: prosesWeek,
          numOfFinish: selesaiWeek
        },
        {
          title: 'Antrian Bulan Ini',
          subtitle: 'Permohonan Paspor',
          numOfIn: masukMonth,
          numOfProcess: prosesMonth,
          numOfFinish: selesaiMonth
        }
      ]
    }
  ];

  const renderDetails = () =>
    details.map(({ title, list }, idx) => (
      <div className='detail-container' key={idx}>
        <div className='title'>{title}</div>
        <div className='detail-wrap'>
          <AntrianCard detail={antrian} />
          {list.map((detail, idx2) => (
            <MiniCard detail={detail} key={idx2} />
          ))}
        </div>
      </div>
    ));

  return (
    <div className='antrian-paspor'>
      <h1>Data Antrian Paspor</h1>
      <span className='date-status'>Hari Kerja</span>
      <span
        className='edit-holiday'
        onClick={() => history.push('/admin/antrian-paspor/holiday')}
      >
        Edit Hari Libur
      </span>
      <div className='antrian-paspor-action-right'>
        <DatePickerRange
          startDate={startDate}
          setStartDate={onChangeStartDate}
          endDate={endDate}
          setEndDate={onChangeEndDate}
        />
        <Button color='info' onClick={() => onExportAntrian()}>
          Download
        </Button>
      </div>
      {renderDetails()}
      {antrianList.status === 'resolve' ? (
        <Tabbing navs={navs} table={table} status={status} />
      ) : (
        <Loading />
      )}
      <EditJadwal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

AntrianPaspor.propTypes = {
  history: PropTypes.object,
  antrianList: PropTypes.object,
  statisticMonthList: PropTypes.object,
  statisticWeekList: PropTypes.object,
  statisticDayList: PropTypes.object,
  statisticAntrian: PropTypes.object,
  getAntrian: PropTypes.func,
  getStatisticDay: PropTypes.func,
  getStatisticMonth: PropTypes.func,
  getStatisticWeek: PropTypes.func,
  getStatisticAntrian: PropTypes.func,
  getJadwalAntrian: PropTypes.func,
  jadwalAntrian: PropTypes.object,
  getExportAntrian: PropTypes.func,
  patchAntrian: PropTypes.func,
  patchAntrianProcess: PropTypes.object
};

const mapStateToProps = (state) => ({
  antrianList: {
    status: state.antrianReducerAdmin.status,
    error: state.antrianReducerAdmin.error,
    data: state.antrianReducerAdmin.data
  },
  statisticDayList: {
    status: state.antrianStatisticDayReducerAdmin.status,
    error: state.antrianStatisticDayReducerAdmin.error,
    data: state.antrianStatisticDayReducerAdmin.data
  },
  statisticMonthList: {
    status: state.antrianStatisticMonthReducerAdmin.status,
    error: state.antrianStatisticMonthReducerAdmin.error,
    data: state.antrianStatisticMonthReducerAdmin.data
  },
  statisticWeekList: {
    status: state.antrianStatisticWeekReducerAdmin.status,
    error: state.antrianStatisticWeekReducerAdmin.error,
    data: state.antrianStatisticWeekReducerAdmin.data
  },
  statisticAntrian: {
    status: state.antrianStatisticAntrianReducerAdmin.status,
    error: state.antrianStatisticAntrianReducerAdmin.error,
    data: state.antrianStatisticAntrianReducerAdmin.data
  },
  jadwalAntrian: {
    status: state.jadwalAntrianReducerAdmin.status,
    error: state.jadwalAntrianReducerAdmin.error,
    data: state.jadwalAntrianReducerAdmin.data
  },
  patchAntrianProcess: {
    status: state.patchAntrianReducerAdmin.status,
    error: state.patchAntrianReducerAdmin.error,
    data: state.patchAntrianReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getAntrian,
    getStatisticDay,
    getStatisticWeek,
    getStatisticMonth,
    getStatisticAntrian,
    getJadwalAntrian,
    getExportAntrian,
    patchAntrian
  }),
  withRouter
)(AntrianPaspor);
