const numberRegister = {
  data: [],
  status: 'idle',
  error: ''
};

const numberRegisterReducer = (state = numberRegister, action) => {
  switch (action.type) {
    case 'GET_NUMBER_REGISTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NUMBER_REGISTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_NUMBER_REGISTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

// OFFICE LIST
const offices = {
  data: [],
  status: 'idle',
  error: ''
};

const officeListReducer = (state = offices, action) => {
  switch (action.type) {
    case 'GET_OFFICE_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_OFFICE_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.offices
      };
    case 'GET_OFFICE_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.offices.data.data
      };
    default:
      return state;
  }
};

export { numberRegisterReducer, officeListReducer };
