export const optionsList = (i18n) => {
  const passport_type_options = [
    { value: 'biasa', label: i18n('paspor_page.trx_options.regular') },
    { value: 'dinas', label: i18n('paspor_page.trx_options.dinas') },
    { value: 'diplomatik', label: i18n('paspor_page.trx_options.diplomatic') }
  ];

  const visa_american_options = [
    { value: 'green', name: i18n('form.visa_type.green_card') },
    { value: 'other', name: i18n('form.visa_type.other') }
  ];

  const american_stay_types = [
    { value: 'I-94', name: 'I-94' },
    { value: 'I-20', name: 'I-20' },
    { value: 'other', name: i18n('form.visa_type.other') },
    { value: 'tidak ada', name: i18n('form.visa_type.havent') }
  ];

  return {
    passport_type_options,
    visa_american_options,
    american_stay_types
  };
};
