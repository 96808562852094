import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';
import { Alert } from 'reactstrap';
import moment from 'moment';

import { convertArrayToObject } from '../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../helpers/functions';

import Banner from '../../../components/Banner';
import CardDetail from '../../../components/CardDetail';
import TextInput from '../../../components/input/TextInput';
import Datepicker from '../../../components/input/Datepicker';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import Icon from '../../../components/Icon';

import { postDiasporaRegister } from './action';

import './style.scss';

const FormDiaspora = ({ history, i18n, diasporaRegister, postDiasporaRegister }) => {
  const { data, error, status } = diasporaRegister;
  const defaultForm = {
    name: '',
    email: '',
    phone: '',
    birth_city: '',
    birth_date: '',
    occupation: '',
    organization: '',
    address: ''
  };
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let schema = yup.object().shape({
      name: yup.string().required('required'),
      email: yup.string().email('email').required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      birth_city: yup.string().required('required'),
      birth_date: yup.date().required('required'),
      occupation: yup.string().required('required'),
      organization: yup.string().required('required'),
      address: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        postDiasporaRegister({
          ...form,
          birth_date: moment(form.birth_date).format('YYYY-MM-DD')
        });
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      setErrorSave(message);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  return (
    <>
      {status === 'pending' && <Loading />}
      <Banner
        title={i18n('diaspora.banner.title')}
        subtitle={
          <>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('diaspora.banner.desc') }}
            />
          </>
        }
        backButtonClick={() => history.goBack()}
      />
      <CardDetail title={i18n('diaspora.diaspora_form')} className='diaspora'>
        <form onSubmit={handleSubmit}>
          <Alert color='danger' isOpen={visible}>
            {errorSave}
          </Alert>
          <div className='form-wrap'>
            <div
              className='instruction'
              dangerouslySetInnerHTML={{ __html: i18n('diaspora.instruction') }}
            />
            <TextInput
              label={i18n('diaspora.name.label')}
              placeholder={i18n('diaspora.name.placeholder')}
              value={form.name}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'name')
              }}
              errorMessage={
                get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
              }
            />
            <div className='borndate-wrapper'>
              <TextInput
                label={i18n('diaspora.place.label')}
                placeholder={i18n('diaspora.place.placeholder')}
                value={form.birth_city}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'birth_city')
                }}
                errorMessage={
                  get(errors, ['birth_city']) &&
                  i18n(`validation.${get(errors, ['birth_city'])}`)
                }
              />
              <Datepicker
                label={i18n('diaspora.borndate.label')}
                placeholder={i18n('diaspora.borndate.placeholder')}
                value={get(form, ['birth_date'])}
                onChange={(date) => handlerChanges(date, 'birth_date')}
                errorMessage={
                  get(errors, ['birth_date']) &&
                  i18n(`validation.${get(errors, ['birth_date'])}`)
                }
              />
            </div>
            <TextInput
              label={i18n('diaspora.job.label')}
              placeholder={i18n('diaspora.job.placeholder')}
              value={form.occupation}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'occupation')
              }}
              errorMessage={
                get(errors, ['occupation']) &&
                i18n(`validation.${get(errors, ['occupation'])}`)
              }
            />
            <TextInput
              label={i18n('diaspora.organization.label')}
              placeholder={i18n('diaspora.organization.placeholder')}
              value={form.organization}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'organization')
              }}
              errorMessage={
                get(errors, ['organization']) &&
                i18n(`validation.${get(errors, ['organization'])}`)
              }
            />
            <TextInput
              label={i18n('diaspora.address.label')}
              placeholder={i18n('diaspora.address.placeholder')}
              value={form.address}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'address')
              }}
              errorMessage={
                get(errors, ['address']) && i18n(`validation.${get(errors, ['address'])}`)
              }
            />
            <TextInput
              label={i18n('form.email.label')}
              placeholder={i18n('form.email.placeholder')}
              value={form.email}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'email')
              }}
              errorMessage={
                get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
              }
            />
            <TextInput
              label={i18n('form.phone.label')}
              placeholder={i18n('form.phone.placeholder')}
              value={form.phone}
              inputProps={{
                type: 'number',
                onChange: (e) => handlerChanges(e.target.value, 'phone')
              }}
              errorMessage={
                get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
              }
            />
          </div>
          <div className='btn-wrap'>
            <Button color='primary' type='submit'>
              Submit
            </Button>
          </div>
        </form>
      </CardDetail>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className='success complaint'
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: i18n('cta.ok'),
            onClick: () => {
              setShowModal(false);
              history.push('/');
            }
          }
        ]}
        title={
          <div className='top-wrap'>
            <Icon icon='green-check' />
            <div>{i18n('diaspora.modal_success.title')}</div>
          </div>
        }
        desc={i18n('diaspora.modal_success.desc')}
      />
    </>
  );
};

FormDiaspora.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  diasporaRegister: PropTypes.object,
  postDiasporaRegister: PropTypes.func
};

const mapStateToProps = (state) => ({
  diasporaRegister: {
    status: state.diasporaRegisterReducer.status,
    error: state.diasporaRegisterReducer.error,
    data: state.diasporaRegisterReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postDiasporaRegister
  }),
  withRouter
)(FormDiaspora);
