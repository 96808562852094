const defaultData = (isObject) => ({
  data: isObject ? {} : [],
  status: 'idle',
  error: ''
});

const eventRegister = {
  data: {},
  status: 'idle',
  error: ''
};

const eventListReducer = (state = defaultData(), action) => {
  switch (action.type) {
    case 'GET_EVENT_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EVENT_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.eventList
      };
    case 'GET_EVENT_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.eventList.data.data
      };
    default:
      return state;
  }
};

const eventDetailReducer = (state = defaultData(true), action) => {
  switch (action.type) {
    case 'GET_EVENT_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EVENT_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.eventDetail
      };
    case 'GET_EVENT_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.eventDetail.data.data
      };
    default:
      return state;
  }
};

const eventRegisterReducer = (state = eventRegister, action) => {
  switch (action.type) {
    case 'POST_EVENT_REGISTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_EVENT_REGISTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_EVENT_REGISTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

export { eventListReducer, eventDetailReducer, eventRegisterReducer };
