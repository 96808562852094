import { apiCaller } from '../../../helpers/apiCaller';

const getComplaintsRequest = () => ({
  type: 'GET_COMPLAINTS_REQUEST'
});

const getComplaintsSuccess = (data) => ({
  type: 'GET_COMPLAINTS_SUCCESS',
  payload: data
});

const getComplaintsFailure = (error) => ({
  type: 'GET_COMPLAINTS_FAILURE',
  payload: error
});

const getComplaints = (params) => {
  return (dispatch) => {
    dispatch(getComplaintsRequest());
    return apiCaller('admin/complaint', 'get', undefined, true, params)
      .then((data) => dispatch(getComplaintsSuccess(data)))
      .catch((error) => dispatch(getComplaintsFailure(error)));
  };
};

const getComplaintDetailRequest = () => ({
  type: 'GET_COMPLAINTS_DETAIL_ADMIN_REQUEST'
});

const getComplaintDetailSuccess = (data) => ({
  type: 'GET_COMPLAINTS_DETAIL_ADMIN_SUCCESS',
  payload: data
});

const getComplaintDetailFailure = (error) => ({
  type: 'GET_COMPLAINTS_DETAIL_ADMIN_FAILURE',
  payload: error
});

const getComplaintDetail = (id) => {
  return (dispatch) => {
    dispatch(getComplaintDetailRequest());
    return apiCaller('admin/complaint/detail/' + id, 'get', '', true)
      .then((data) => dispatch(getComplaintDetailSuccess(data)))
      .catch((error) => dispatch(getComplaintDetailFailure(error)));
  };
};

const postAnswerComplaintRequest = () => ({
  type: 'POST_ANSWER_COMPLAINT_REQUEST'
});

const postAnswerComplaintSuccess = (data) => ({
  type: 'POST_ANSWER_COMPLAINT_SUCCESS',
  payload: data
});

const postAnswerComplaintFailure = (error) => ({
  type: 'POST_ANSWER_COMPLAINT_FAILURE',
  payload: error
});

const sendEmailRequest = () => ({
  type: 'SEND_EMAIL_REQUEST'
});

const sendEmailSuccess = (data) => ({
  type: 'SEND_EMAIL_SUCCESS',
  payload: data
});

const sendEmailFailure = (error) => ({
  type: 'SEND_EMAIL_FAILURE',
  payload: error
});

const postAnswerComplaint = (id, data) => {
  return (dispatch) => {
    dispatch(postAnswerComplaintRequest());
    return apiCaller('admin/complaint/answer/' + id, 'patch', data, true)
      .then((data) => dispatch(postAnswerComplaintSuccess(data)))
      .catch((error) => dispatch(postAnswerComplaintFailure(error)));
  };
};

const sendEmail = (data) => {
  return (dispatch) => {
    dispatch(sendEmailRequest());
    return apiCaller('email', 'post', data, false)
      .then((data) => dispatch(sendEmailSuccess(data)))
      .catch((error) => dispatch(sendEmailFailure(error)));
  }
}

export { getComplaints, getComplaintDetail, postAnswerComplaint, sendEmail };
