import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import Banner from '../../../components/Banner';
import CardDetail from '../../../components/CardDetail';
import TrxTracking from './components/TrxTracking';
import RowDetail from '../../../components/RowDetail';
import PassportReuploadDoc from './components/PassportReuploadDoc';
import { errorAlertScroll } from '../../../helpers/functions';

import { postTrack, resetTrack } from './action';
import { getTitle } from './functions';
import './style.scss';

const keyType = {
  letter: {
    type: 'letter_service',
    name: 'name'
  },
  document: {
    type: 'legalization_service',
    name: 'name'
  },
  visa: {
    type: 'visa_service',
    name: 'title'
  },
  passport: {
    type: 'passport_service',
    name: 'title'
  },
  pensosbud: {
    type: 'service',
    name: 'title'
  }
};

const Track = ({ history, i18n, postTrack, trackDetail, resetTrack }) => {
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [trackParams, setTrackParams] = useState({});

  // PAssport doc
  const [form, setForm] = useState({});
  const [tmpform, setTmpForm] = useState({});

  const { data, error, status } = trackDetail;
  const { type, name } = keyType[trackParams.type] || {};

  const transactionDetail = {
    time: moment(get(data, ['created_at'])).format('DD-MM-YYYY'),
    status: get(data, ['status']),
    note: get(data, ['note']) || '-',
    location: get(data, ['office', 'name']),
    passporNumber: get(trackParams, ['unique_number']),
    name: get(data, ['name']),
    address: get(data, ['address', 'address']) || '-',
    type:
      getTitle(get(data, [type, name]), trackParams.type, i18n) || get(data, [type, name])
  };
  const reuploadPassport =
    trackParams.type === 'passport' &&
    (transactionDetail.status === 'REJECT' || transactionDetail.status === 'PENDING');

  const handlerSubmit = (form) => {
    const params = {
      type: get(form, ['service_type', 'value']),
      unique_number: get(form, ['code']),
      office_id: get(form, ['office_id', 'id'])
    };

    setTrackParams(params);
    postTrack(params);
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message =
        get(error, ['response', 'data', 'error', 'message']) || 'Maaf, ada kesalahan';
      setErrorSave(message);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    }
  }, [data, error, status]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    return () => resetTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner
        title={i18n('track.banner.title')}
        subtitle={i18n('track.banner.subtitle')}
        backButtonClick={() => history.goBack()}
      />
      <Container>
        <TrxTracking
          i18n={i18n}
          handlerSubmit={handlerSubmit}
          visible={visible}
          errorSave={errorSave}
          status={status}
        />
        {status === 'resolve' && (
          <>
            <CardDetail
              title={i18n('track.detail.title')}
              className='track-2'
              description={
                <>
                  <div className='item'>
                    <div className='label'>{i18n('track.detail.time')}</div>
                    <span>{transactionDetail.time}</span>
                  </div>
                  <div className='item'>
                    <div className='label'>{i18n('track.detail.status')}</div>
                    <span className='proccess-item'>{transactionDetail.status}</span>
                  </div>
                  <div className='item'>
                    <div className='label'>{i18n('track.detail.notes')}</div>
                    <span>{transactionDetail.note}</span>
                  </div>
                </>
              }
            >
              <RowDetail
                label={i18n('track.detail.location')}
                info={transactionDetail.location}
              />
              <RowDetail
                label={i18n('track.detail.passport_number')}
                info={transactionDetail.passporNumber}
              />
              <RowDetail
                label={i18n('track.detail.name')}
                info={transactionDetail.name}
              />
              <RowDetail
                label={i18n('track.detail.address')}
                info={transactionDetail.address}
              />
              <RowDetail
                label={i18n('track.detail.type_trx')}
                info={transactionDetail.type}
              />
            </CardDetail>
            {reuploadPassport && (
              <PassportReuploadDoc data={data} i18n={i18n} history={history} />
            )}
          </>
        )}
      </Container>
    </>
  );
};

Track.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  postTrack: PropTypes.func,
  trackDetail: PropTypes.object
};

const mapStateToProps = (state) => ({
  trackDetail: {
    status: state.trackReducer.status,
    error: state.trackReducer.error,
    data: state.trackReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { postTrack, resetTrack }),
  withRouter
)(Track);
