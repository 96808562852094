import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../components/Button';
import DatepickerRange from '../components/DatepickerRange';
import Content from './components/MainContent';

import {
  getVisaList,
  resetVisaList,
  getVisaStatistic,
  getVisaStatisticWeek,
  getVisaStatisticMonth,
  getVisaStatisticAll
} from './action';
import { getVisaService } from '../../form/VisaForm/action';
import { getVisaServiceId } from '../../pages/Visa/function';
import visaServiceTitle from '../../../constant/visaService';
import { getStatisticDate, downloadFile } from '../functions';

const AdminVisa = ({
  visaList,
  visaService,
  visaStatistic,
  visaStatisticWeek,
  visaStatisticMonth,
  visaStatisticAll,
  getVisaList,
  resetVisaList,
  getVisaStatistic,
  getVisaStatisticWeek,
  getVisaStatisticMonth,
  getVisaService,
  getVisaStatisticAll
}) => {
  const { type } = useParams();

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [activeTab, setActiveTab] = useState('1');
  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();
  const statusTabList = {
    '1': 'SUBMITTED',
    '2': 'PROCESS',
    '3': 'APPROVED',
    '4': 'PENDING'
  };

  const title = visaServiceTitle[type];
  const visaListData = get(visaList, ['data', 'data']) || [];
  const visaServiceList = get(visaService, ['data']) || [];
  const pagination = get(visaList, ['data', 'pagination']) || {};

  const visaServiceId = getVisaServiceId(visaServiceList, type);
  const loading =
    get(visaService, ['status']) === 'pending' || get(visaList, ['status']) === 'pending';

  const getAllStatistic = () => {
    const allStatistic = get(visaStatisticAll, ['data']) || [];
    const summaryData = {
      numOfIn: String(0),
      numOfProcess: String(0),
      numOfFinish: String(0),
      numOfPending: String(0)
    };
    allStatistic.map(({ status, total }) => {
      switch (status) {
        case statusTabList['1']:
          summaryData.numOfIn = String(total);
          break;
        case statusTabList['2']:
          summaryData.numOfProcess = String(total);
          break;
        case statusTabList['3']:
          summaryData.numOfFinish = String(total);
          break;
        case statusTabList['4']:
          summaryData.numOfPending = String(total);
          break;
      }
    });
    return summaryData;
  };

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      }
    ];
    const dayStatistic = get(visaStatistic, ['data']) || [];
    const weekStatistic = get(visaStatisticWeek, ['data']) || [];
    const monthStatistic = get(visaStatisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ status, total }) => {
        switch (status) {
          case statusTabList['1']:
            summaryData[index].numOfIn = String(total);
            break;
          case statusTabList['2']:
            summaryData[index].numOfProcess = String(total);
            break;
          case statusTabList['3']:
            summaryData[index].numOfFinish = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const loadData = () => {
    const params = {
      service_id: visaServiceId,
      status: statusTabList[activeTab],
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getVisaList(params);
  };

  const loadStatisticData = (isAllOnly) => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();

    if (isAllOnly) {
      getVisaStatisticAll({
        service_id: visaServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    } else {
      getVisaStatistic({
        service_id: visaServiceId,
        from: today,
        to: today
      });
      getVisaStatisticWeek({
        service_id: visaServiceId,
        from: startWeek,
        to: endWeek
      });
      getVisaStatisticMonth({
        service_id: visaServiceId,
        from: startMonth,
        to: endMonth
      });
      getVisaStatisticAll({
        service_id: visaServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    }
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      service_id: visaServiceId
    };
    const fileName = `visa-${title}-${params.from}-${params.to}`;
    downloadFile('visa', params, fileName);
  };

  useEffect(() => {
    getVisaService();
    return () => resetVisaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visaServiceId]);

  useEffect(() => {
    resetVisaList();

    if (visaServiceId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pageDivider, page, query, visaServiceId]);

  useEffect(() => {
    setActiveTab('1');
    setPage(1);
    setPageDivider(5);
    setQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Content
      title={title}
      dataTab={visaListData}
      summaryData={getSummaryData()}
      type={type}
      loading={loading}
      onTabChanges={setActiveTab}
      onPageChanges={setPage}
      onPageDividerChanges={setPageDivider}
      onQueryChanges={setQuery}
      defaultActiveTab={activeTab}
      pagination={pagination}
      loadData={() => {
        loadData();
        loadStatisticData();
      }}
      allStatistic={getAllStatistic()}
      topActionComponent={
        <div className='detail-action' style={{ display: 'flex' }}>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={() => {
              loadData();
              loadStatisticData(true);
            }}
          />
          <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
            Download
          </Button>
        </div>
      }
    />
  );
};

AdminVisa.propTypes = {
  visaList: PropTypes.object,
  visaService: PropTypes.object,
  visaStatistic: PropTypes.object,
  visaStatisticWeek: PropTypes.object,
  visaStatisticMonth: PropTypes.object,
  getVisaList: PropTypes.func,
  resetVisaList: PropTypes.func,
  getVisaStatistic: PropTypes.func,
  getVisaStatisticWeek: PropTypes.func,
  getVisaStatisticMonth: PropTypes.func,
  getVisaService: PropTypes.func
};

const mapStateToProps = (state) => ({
  visaList: {
    status: state.getVisaListReducer.status,
    error: state.getVisaListReducer.error,
    data: state.getVisaListReducer.data
  },
  visaService: {
    status: state.visaServiceReducer.status,
    error: state.visaServiceReducer.error,
    data: state.visaServiceReducer.data
  },
  visaStatistic: {
    status: state.getVisaStatisticReducer.status,
    error: state.getVisaStatisticReducer.error,
    data: state.getVisaStatisticReducer.data
  },
  visaStatisticWeek: {
    status: state.getVisaStatisticWeekReducer.status,
    error: state.getVisaStatisticWeekReducer.error,
    data: state.getVisaStatisticWeekReducer.data
  },
  visaStatisticMonth: {
    status: state.getVisaStatisticMonthReducer.status,
    error: state.getVisaStatisticMonthReducer.error,
    data: state.getVisaStatisticMonthReducer.data
  },
  visaStatisticAll: {
    status: state.getVisaStatisticAllReducer.status,
    error: state.getVisaStatisticAllReducer.error,
    data: state.getVisaStatisticAllReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getVisaList,
    resetVisaList,
    getVisaStatistic,
    getVisaStatisticWeek,
    getVisaStatisticMonth,
    getVisaService,
    getVisaStatisticAll
  }),
  withRouter
)(AdminVisa);
