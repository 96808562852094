import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown as DropdownStrap,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import Icon from '../Icon';

import './style.scss';

const Dropdown = ({
  label,
  sublabel,
  options,
  value = '',
  errorMessage,
  onClick,
  valueKey = 'value',
  labelKey = 'label',
  placeholder,
  isValueObject,
  className = '',
  dropdownProps,
  dropdownItemProps,
  dropdownMenuProps,
  dropdownToggleProps,
  withAutocomplete,
  iconRight,
  disable,
  detailInput,
  customToggle
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [autocomplete, setAutocomplete] = useState('');
  const [selectedDetail, setSelectedDetail] = useState({});
  const { label: selectedLabelValue, value: selectedValue } = selectedDetail;

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const renderOptions = () => {
    return options.map((item, idx) => {
      const dropdownLabel = item[labelKey] || item;
      const firstCheck = withAutocomplete
        ? dropdownLabel.toLowerCase().includes(autocomplete.toLowerCase())
        : true;

      if (firstCheck) {
        return (
          <DropdownItem
            key={idx}
            disabled={item.disabled}
            className={`${item[valueKey] === selectedValue ? 'selected' : ''}`}
            onClick={() => onClick(item)}
            {...dropdownItemProps}
          >
            {dropdownLabel}
          </DropdownItem>
        );
      }
      return null;
    });
  };

  useEffect(() => {
    if (!dropdownOpen) {
      setAutocomplete('');
    }
  }, [dropdownOpen]);

  useEffect(() => {
    let selectedLabelValue = value;
    let selectedValue = value;
    if (isValueObject) {
      selectedLabelValue = value && value[labelKey];
      selectedValue = value && value[valueKey];
    }

    // Set selected detail
    setSelectedDetail({
      label: selectedLabelValue,
      value: selectedValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={`dropdown-container ${className} ${errorMessage ? 'err' : ''}`}>
      {label && (
        <label>
          {label}
          {sublabel && <div className='sublabel'>{sublabel}</div>}
        </label>
      )}
      <div className='dropdown-wrap'>
        <DropdownStrap isOpen={dropdownOpen} toggle={toggle} {...dropdownProps}>
          <DropdownToggle caret {...dropdownToggleProps}>
            {withAutocomplete ? (
              <input
                type='text'
                value={selectedLabelValue || ''}
                placeholder={placeholder}
                readOnly
              />
            ) : (
              selectedLabelValue || <span className='placeholder'>{placeholder}</span>
            )}
            {customToggle}
            {iconRight && <Icon icon={iconRight} placement='right' />}
          </DropdownToggle>
          <DropdownMenu {...dropdownMenuProps}>
            {withAutocomplete && (
              <input
                type='text'
                value={autocomplete}
                placeholder={'Masukkan kata kunci'}
                onChange={(e) => setAutocomplete(e.target.value)}
              />
            )}
            <div className='option-wrap'>{renderOptions()}</div>
          </DropdownMenu>
        </DropdownStrap>
        {detailInput && <div className='detail-input'>{detailInput}</div>}
        {errorMessage && <div className='err-message'>{errorMessage}</div>}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  sublabel: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  onClick: PropTypes.func,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  placeholder: PropTypes.string,
  isValueObject: PropTypes.bool,
  className: PropTypes.string,
  dropdownProps: PropTypes.object,
  dropdownItemProps: PropTypes.object,
  dropdownMenuProps: PropTypes.object,
  dropdownToggleProps: PropTypes.object,
  withAutocomplete: PropTypes.bool,
  iconRight: PropTypes.string
};

export default Dropdown;
