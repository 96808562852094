import React, { useEffect } from 'react';
import DashboardTable from '../../../components/DashboardTableOld2';
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUsers, deleteUser } from './action';
import { arrayHasValue } from '../../../helpers/helpers';
import Loading from '../../../components/Loading';
import './style.scss';

const User = ({ history, getUsers, users, deletedUser, deleteUser }) => {
  useEffect(() => {
    if (!arrayHasValue(users.data)) {
      getUsers();
    }
  }, [getUsers, users.data]);

  const handleDelete = (id) => {
    deleteUser(id);
  };

  if (deletedUser.status === 'resolve') {
    window.location.reload();
  }

  const columns = [
    { dataField: 'email', text: 'Email' },
    { dataField: 'name', text: 'Fullname' },
    { dataField: 'username', text: 'Username' },
    { dataField: 'group.name', text: 'Role' },
    { dataField: 'created_at', text: 'Date' },
    { dataField: 'user_id', text: 'Action' }
  ];

  const data = users.data;

  const action = [
    {
      onClick: (id) => history.push(`/admin/users/edit/${id}`, data),
      type: 'edit'
    },
    { onClick: (id) => handleDelete(id), type: 'delete' }
  ];

  return (
    <div className='admin-user'>
      <div className='admin-user-top'>
        <h1>DATA USERS</h1>
        <div className='admin-user-action'>
          <Button color='primary' onClick={() => history.push('/admin/users/create')}>
            Tambah User
          </Button>
        </div>
      </div>
      {users.status === 'resolve' ? (
        <DashboardTable
          columns={columns}
          data={data}
          action={action}
          search='email'
          idKeyField='user_id'
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

User.propTypes = {
  history: PropTypes.object,
  getUsers: PropTypes.func,
  users: PropTypes.any,
  deleteUser: PropTypes.func,
  deletedUser: PropTypes.object
};

const mapStateToProps = (state) => ({
  users: {
    status: state.usersReducerAdmin.status,
    error: state.usersReducerAdmin.error,
    data: state.usersReducerAdmin.data
  },
  deletedUser: {
    status: state.deleteUserReducerAdmin.status,
    error: state.deleteUserReducerAdmin.error,
    data: state.deleteUserReducerAdmin.data
  }
});

export default compose(connect(mapStateToProps, { getUsers, deleteUser }), withRouter)(User);
