import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import Action from './Action';
import ImageView from './ImageView';
import searchIcon from '../../assets/img/icon/search.svg';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';

import Button from '../Button';

import './style.scss';

const DashboardTable = ({
  columns,
  data,
  action,
  search,
  idKeyField = 'id',
  onPageChanges,
  onPageDividerChanges,
  onQueryChanges,
  pagination = {}
}) => {
  const [pageDivider, setPageDivider] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyWord, setKeyword] = useState('');
  const listHead = columns.map((column, index) => (
    <td key={index} className={column.className || ''}>
      {column.text}
    </td>
  ));

  const {
    total_data = 0,
    total_page = 0,
    total = 0,
    page = 0,
    per_page = 0
  } = pagination;
  const total_page_final = total > 0 ? total_page : 0;
  const totalCurrentData =
    total > 0 && total_page_final > 0 ? (page - 1) * per_page + total : 0;
  const total_data_final = totalCurrentData > 0 ? total_data : 0;

  const columnDetail = (column, data, count) => {
    const { text, customColumn } = column;
    const columData = get(data, column.dataField);
    if (text === 'Action') {
      return <Action action={action} id={data[idKeyField]} data={data} />;
    } else if (text === 'CustomAction') {
      const selectedAction = action(data);
      return <Action action={selectedAction} id={data[idKeyField]} data={data} />;
    } else if (text === 'No') {
      return (page - 1) * per_page + Number(count) + 1;
    } else if (text.includes('Date')) {
      return moment(columData).utcOffset(columData).format('LLL');
    } else if (text.includes('Tanggal')) {
      return moment(columData).utcOffset(columData).format('LLL');
    } else if (text === 'Image') {
      return <ImageView url={columData} />;
    } else if (customColumn) {
      return customColumn(data);
    } else {
      return columData || '-';
    }
  };

  const dataView = data.map((data, index) => (
    <React.Fragment key={index}>
      <tr key={index}>
        {columns.map((column, idxClm) => (
          <td key={idxClm} className={column.className || ''}>
            {columnDetail(column, data, index)}
          </td>
        ))}
      </tr>
    </React.Fragment>
  ));

  const changePageDivider = (e) => {
    setPageDivider(e.target.value);
    setCurrentPage(1);
    onPageDividerChanges && onPageDividerChanges(e.target.value);
    onPageChanges && onPageChanges(1);
  };

  const changeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const changeCurrentPage = (e) => {
    setCurrentPage(e);
    onPageChanges && onPageChanges(e);
  };

  return (
    <div className='dashboard-table'>
      <div className='table-top'>
        <div className='page-view'>
          Show
          <select
            value={pageDivider}
            name='pageview'
            id='pageview'
            onChange={changePageDivider}
          >
            <option value='5'>5</option>
            <option value='15'>15</option>
            <option value='25'>25</option>
            <option value='45'>45</option>
          </select>
        </div>
        <div className='search'>
          <img src={searchIcon} alt='search-img' />
          <input value={keyWord} placeholder='Search Data..' onChange={changeKeyword} />
          <Button
            color='primary'
            onClick={() => onQueryChanges && onQueryChanges(keyWord)}
          >
            Search
          </Button>
        </div>
      </div>
      <table>
        <thead>
          <tr>{listHead}</tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            dataView
          ) : (
            <div style={{ padding: '16px' }}>No data found</div>
          )}
        </tbody>
      </table>
      <div className='table-bottom'>
        <div className='data-view'>
          {keyWord === '' && (
            <span>
              Data {totalCurrentData} of {total_data_final}
            </span>
          )}
        </div>
        <Pagination
          divider={pageDivider}
          total={total_data_final}
          currentPage={page}
          setCurrentPage={changeCurrentPage}
        />
      </div>
    </div>
  );
};

DashboardTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  action: PropTypes.array,
  search: PropTypes.string,
  idKeyField: PropTypes.any
};

export default withRouter(DashboardTable);
