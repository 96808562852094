import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';
import { Alert } from 'reactstrap';

import { convertArrayToObject } from '../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../helpers/functions';

import Banner from '../../../components/Banner';
import CardDetail from '../../../components/CardDetail';
import TextInput from '../../../components/input/TextInput';
import FileInput from '../../../components/input/File';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import Icon from '../../../components/Icon';

import { postComplainRegister } from './action';

import './style.scss';

const FormComplaint = ({ history, i18n, complaintRegister, postComplainRegister }) => {
  const { data, error, status } = complaintRegister;
  const defaultForm = {
    fullName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: ''
  };
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      name: get(form, ['name']),
      email: get(form, ['email']),
      phone: get(form, ['phone']),
      subject: get(form, ['subject']),
      message: get(form, ['message']),
      files: get(form, ['files']) || []
    };

    let schema = yup.object().shape({
      name: yup.string().required('required'),
      email: yup.string().email('email').required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      subject: yup.string().required('required'),
      message: yup.string().required('required')
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        postComplainRegister({
          ...params
        });
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      setErrorSave(message);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  return (
    <>
      {status === 'pending' && <Loading />}
      <Banner
        title={i18n('help.banner.title')}
        subtitle={
          <>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('help.banner.desc') }}
            />
          </>
        }
        backButtonClick={() => history.goBack()}
      />
      <CardDetail title={i18n('help.help_form')} className='complaint'>
        <form onSubmit={handleSubmit}>
          <Alert color='danger' isOpen={visible}>
            {errorSave}
          </Alert>
          <div className='form-wrap'>
            <TextInput
              label={i18n('form.fullname.label')}
              placeholder={i18n('form.fullname.placeholder')}
              value={form.name}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'name')
              }}
              errorMessage={
                get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
              }
            />
            <TextInput
              label={i18n('form.phone.label')}
              placeholder={i18n('form.phone.placeholder')}
              value={form.phone}
              inputProps={{
                type: 'number',
                onChange: (e) => handlerChanges(e.target.value, 'phone')
              }}
              errorMessage={
                get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
              }
            />
            <TextInput
              label={i18n('form.email.label')}
              placeholder={i18n('form.email.placeholder')}
              value={form.email}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'email')
              }}
              errorMessage={
                get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
              }
            />
            <TextInput
              label={i18n('help.subject.label')}
              placeholder={i18n('help.subject.placeholder')}
              value={form.subject}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'subject')
              }}
              errorMessage={
                get(errors, ['subject']) && i18n(`validation.${get(errors, ['subject'])}`)
              }
            />
            <TextInput
              label={i18n('help.message.label')}
              placeholder={i18n('help.message.placeholder')}
              value={form.message}
              inputMode='textarea'
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'message')
              }}
              errorMessage={
                get(errors, ['message']) && i18n(`validation.${get(errors, ['message'])}`)
              }
            />
            <FileInput
              label=' '
              setFileInput={(url) => setForm({ ...form, files: [url] })}
            />
          </div>
          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button color='primary' type='submit'>
              Submit
            </Button>
          </div>
        </form>
      </CardDetail>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className='success complaint'
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: i18n('cta.ok'),
            onClick: () => {
              setShowModal(false);
              history.push('/');
            }
          }
        ]}
        title={
          <div className='top-wrap'>
            <Icon icon='green-check' />
            <div>{i18n('help.modal_success.title')}</div>
          </div>
        }
        desc={i18n('help.modal_success.desc')}
      />
    </>
  );
};

FormComplaint.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  complaintRegister: PropTypes.object,
  postComplainRegister: PropTypes.func
};

const mapStateToProps = (state) => ({
  complaintRegister: {
    status: state.complaintRegisterReducer.status,
    error: state.complaintRegisterReducer.error,
    data: state.complaintRegisterReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postComplainRegister
  }),
  withRouter
)(FormComplaint);
