import React, { useEffect } from 'react';
import DashboardTable from '../../../components/DashboardTableOld2';
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayHasValue } from '../../../helpers/helpers';
import './style.scss';
import { getNews, deleteNews } from './action';
import Loading from '../../../components/Loading';

const AdminNews = ({ history, getNews, newsList, deleteNews, deletedNews }) => {
  useEffect(() => {
    if (!arrayHasValue(newsList.data)) {
      getNews();
    }
  }, [getNews, newsList.data]);

  const handleDelete = (id) => {
    deleteNews(id);
  };

  if (deletedNews.status === 'resolve') {
    window.location.reload();
  }

  const columns = [
    { dataField: '_id', text: 'No' },
    { dataField: 'title', text: 'Title' },
    { dataField: 'created_at', text: 'Date' },
    { dataField: 'status', text: 'Status' },
    { dataField: '_id', text: 'Action' }
  ];

  const action = [
    { onClick: (id) => history.push(`/admin/news/${id}`), type: 'view' },
    { onClick: (id) => history.push(`/admin/news/edit/${id}`), type: 'edit' },
    { onClick: (id) => handleDelete(id), type: 'delete' }
  ];
  const data = newsList.data;

  return (
    <div className='admin-news'>
      <div className='admin-news-top'>
        <h1>DATA BERITA & INFORMASI</h1>
        <div className='admin-news-action'>
          <Button color='primary' onClick={() => history.push('/admin/news/create')}>
            Tambah Berita & Informasi
          </Button>
        </div>
      </div>
      {newsList.status === 'resolve' ? (
        <DashboardTable
          columns={columns}
          data={data}
          action={action}
          search='title'
          idKeyField='_id'
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

AdminNews.propTypes = {
  history: PropTypes.object,
  newsList: PropTypes.object,
  getNews: PropTypes.func,
  deleteNews: PropTypes.func,
  deletedNews: PropTypes.object
};

const mapStateToProps = (state) => ({
  newsList: {
    status: state.newsReducerAdmin.status,
    error: state.newsReducerAdmin.error,
    data: state.newsReducerAdmin.data
  },
  deletedNews: {
    status: state.deleteNewsReducerAdmin.status,
    error: state.deleteNewsReducerAdmin.error,
    data: state.deleteNewsReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, { getNews, deleteNews }),
  withRouter
)(AdminNews);
