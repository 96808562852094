import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TextInput = ({
  label,
  sublabel,
  placeholder,
  errorMessage,
  inputProps,
  value,
  className = '',
  iconLeft,
  iconRight,
  textRight,
  prependText,
  disable = false,
  detailInput,
  inputMode,
  iconRightClick
}) => {
  return (
    <div
      className={`input-container ${className} ${errorMessage ? 'err' : ''} ${
        disable ? 'disable' : ''
      }`}
    >
      {label && (
        <div className='label'>
          {label}
          {sublabel && <div className='sublabel'>{sublabel}</div>}
        </div>
      )}
      <div className='input-wrap'>
        <div className='input-group'>
          {iconLeft && <span className={`icon left ${iconLeft}`} />}
          {prependText && <div className={'prependText left'}>{prependText}</div>}
          {inputMode === 'textarea' ? (
            <textarea
              type='text'
              placeholder={placeholder}
              value={value || ''}
              readOnly={disable}
              rows='4'
              style={{ resize: 'none', width: '100%' }}
              {...inputProps}
            />
          ) : (
            <input
              type='text'
              placeholder={placeholder}
              value={value || ''}
              readOnly={disable}
              {...inputProps}
            />
          )}
          {iconRight && (
            <span
              className={`icon right ${iconRight}`}
              onClick={iconRightClick && iconRightClick}
            />
          )}
          {textRight && <span className={'text right'}>{textRight}</span>}
        </div>
        {detailInput && <div className='detail-input'>{detailInput}</div>}
        {errorMessage && <div className='err-message'>{errorMessage}</div>}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  className: PropTypes.string,
  sublabel: PropTypes.any,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inputMode: PropTypes.string
};

export default TextInput;
