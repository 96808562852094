import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Button from '../Button';

import './style.scss';

const Banner = ({ title, subtitle, buttonProps, backButtonClick, preButtonDesc }) => {
  const renderBtn = () =>
    buttonProps.map(({ btnStyle, label, onClick, ...props }, idx) => (
      <Button key={idx} {...btnStyle} onClick={onClick}>
        {label}
      </Button>
    ));

  return (
    <div className='banner'>
      <Container>
        <span className='btn-back' onClick={backButtonClick} />
        <div className='title'>{title}</div>
        <div className='content'>
          <div className='subtitle'>{subtitle}</div>
          <div className='btn-container'>
            {preButtonDesc && preButtonDesc}
            {buttonProps && <div className='btn-wrap'>{renderBtn()}</div>}
          </div>
        </div>
      </Container>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonProps: PropTypes.array,
  backButtonClick: PropTypes.func,
  preButtonDesc: PropTypes.node
};

export default Banner;
