import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.scss';

const HomepageIcon = (props) => {
  const { img, link, color, text } = props;

  return (
    <div className='homepage-icon'>
      <Link to={link}>
        <div className='homepage-icon' style={{ background: color }}>
          <img src={img} alt='mantap' />
        </div>
        <span className='homepage-icon-text'>{text}</span>
      </Link>
    </div>
  );
};

HomepageIcon.propTypes = {
  img: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string
};

export default HomepageIcon;
