import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Research = ({ i18n }) => {
  const lang = (type, params) => i18n(`pensosbud.tab5.${type}`, { ...params });
  const stepList = [
    lang('step_applicant.formal_letter'),
    lang('step_applicant.research_proposal'),
    lang('step_applicant.researcher_passport'),
    lang('step_applicant.cv'),
    lang('step_applicant.photo'),
    lang('step_applicant.letter_recomment_professor'),
    lang('step_applicant.letter_recommend_indo'),
    lang('step_applicant.letter_support_indo'),
    lang('step_applicant.health'),
    lang('step_applicant.guaranteeing_fund'),
    lang('step_applicant.research_equipment'),
    `${lang('step_applicant.bring_fam')} ${lang('step_applicant.address')}`
  ];

  const procedureList = [
    lang('procedure.formal_letter'),
    lang('procedure.research_proposal'),
    lang('procedure.most_recent_student_cv'),
    lang('procedure.letter_recomment'),
    lang('procedure.letter_acceptance'),
    lang('procedure.student_passport')
  ];

  const requirementList = [
    lang('general_requirement.formal_letter'),
    lang('general_requirement.resercer_proposal'),
    lang('general_requirement.resercer_cv'),
    lang('general_requirement.letter_recoment_professor'),
    lang('general_requirement.acceptance_letter'),
    lang('general_requirement.guaranteeing'),
    lang('general_requirement.health'),
    lang('general_requirement.letter_recomend_indo'),
    lang('general_requirement.photo'),
    lang('general_requirement.researcher_passport'),
    lang('general_requirement.equipment_brought'),
    lang('general_requirement.bring_family'),
    `${lang('general_requirement.send_applicant')} ${lang('general_requirement.address')}`
  ];

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div className='intro'>
            <div dangerouslySetInnerHTML={{ __html: lang('step.visit') }} />
            <br />
            <div>
              <b>{lang('step.p1')}</b>
            </div>
            <div>{lang('step.p2')}</div>
            <br />
            <div>{lang('step.p3')}</div>
            <div>{lang('step.p4')}</div>
          </div>

          <div className='overview mtop16'>
            <div>
              <b>{lang('overview.title')}</b>
            </div>
            <div className='mtop8'>{lang('overview.p1')}</div>
            <div className='mtop8'>{lang('overview.p2')}</div>
            <div className='mtop16'>
              <a
                href={lang('overview.p3')}
                target='_blank'
                without
                rel='noopener noreferrer'
              >
                {lang('overview.p3')}
              </a>
            </div>
            <div className='mtop16'>{lang('overview.all_procedures')}</div>
          </div>

          <div className='step-applicant mtop16'>
            <div>
              <b>{lang('step_applicant.instruction')}</b>
            </div>
            <ol>
              {stepList.map((item, key) => (
                <li key={key}>
                  <div dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ))}
            </ol>
          </div>

          <div className='procedure mtop16'>
            <div>
              <b>{lang('procedure.title')}</b>
            </div>
            <ol>
              <li>
                <div>{lang('procedure.first_step')}</div>
                <div className='mtop8'>{lang('procedure.please_address')}</div>
                <ol>
                  {procedureList.map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ol>
              </li>
              <li>{lang('procedure.next_step')}</li>
            </ol>
          </div>

          <div className='requirement mtop16'>
            <div>
              <b>{lang('general_requirement.title')}</b>
            </div>
            <div>{lang('general_requirement.instruction')}</div>
            <ol>
              {requirementList.map((item, key) => (
                <li key={key}>
                  <div dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ))}
            </ol>
          </div>

          <div className='final mtop16'>
            <div>
              <b>{lang('final_approval.title')}</b>
            </div>
            <div>{lang('final_approval.pass_by_coor')}</div>
            <div
              className='mtop16'
              dangerouslySetInnerHTML={{ __html: lang('final_approval.for_further') }}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

Research.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Research);
