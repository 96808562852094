const photo = {
  data: {},
  status: 'idle',
  error: ''
};

const uploadPhotoGeneral = (state = photo, action) => {
  switch (action.type) {
    case 'POST_PHOTO_GENERAL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_PHOTO_GENERAL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_PHOTO_GENERAL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

export { uploadPhotoGeneral };
