const defaultResponse = {
  data: {},
  status: 'idle',
  error: ''
};

const numberRegisterStatementReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_NUMBER_REGISTER_STATEMENT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NUMBER_REGISTER_STATEMENT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.statementRegis
      };
    case 'GET_NUMBER_REGISTER_STATEMENT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.statementRegis.data.data
      };
    case 'RESET_NUMBER_REGISTER_STATEMENT':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const regisDetailStatementReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_REGIS_DETAIL_STATEMENT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_REGIS_DETAIL_STATEMENT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.statementRegisDetail
      };
    case 'GET_REGIS_DETAIL_STATEMENT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.statementRegisDetail.data.data
      };
    case 'RESET_DETAIL_REGISTER_STATEMENT':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const statementServiceReducer = (state = { ...defaultResponse, data: [] }, action) => {
  switch (action.type) {
    case 'GET_STATEMENT_SERVICE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_STATEMENT_SERVICE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.statementService
      };
    case 'GET_STATEMENT_SERVICE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.statementService.data.data
      };
    default:
      return state;
  }
};
export {
  numberRegisterStatementReducer,
  regisDetailStatementReducer,
  statementServiceReducer
};
