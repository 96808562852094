import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import parse from 'html-react-parser';
import moment from 'moment';

import './style.scss';

import { getNewsDetail } from '../action';

const DetailNews = ({ history, i18n, getNewsDetail, newsDetail, match }) => {
  const { id } = get(match, ['params']);
  const data = get(newsDetail, ['data', 0]);
  const { title, content = '', image_url, _id, user_id, updated_at } = data || {};

  useEffect(() => {
    getNewsDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container style={{ paddingTop: 24 }}>
        <div className='news-detail'>
          <span className='back-homepage' onClick={() => history.goBack()}>
            <span className='btn-back' />
            {i18n('cta.back_prev_page')}
          </span>

          <h2>{title}</h2>

          <div className='news-info'>
            {i18n('news.detail.by')} Admin KJRI -{' '}
            {moment(updated_at).format('DD MMM YYYY, HH:MM')}
          </div>
          <img src={image_url} alt='news-title' />
          <div className='news-content'>{parse(content)}</div>
        </div>
      </Container>
    </>
  );
};

DetailNews.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  getNewsDetail: PropTypes.func,
  newsDetail: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  newsDetail: {
    status: state.newsDetailReducer.status,
    error: state.newsDetailReducer.error,
    data: state.newsDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getNewsDetail }),
  withRouter
)(DetailNews);
