import get from 'lodash/get';
import kjri_area from '../../../../constant/kjri_area';

const tabDetail = {
  general: [
    {
      groupTitle: 'GENERAL INFORMATION',
      detail: [
        { label: 'Purpose of Visit', value: ['visit_purpose'] },
        { label: 'Visa Type', value: ['visa_type'] },
        { label: 'Length of Stay in Indonesia', value: ['stay_duration'] },
        { label: 'Point of Entry into Indonesia', value: ['entryPortData', 'label'] },
        { label: 'Date of Entry', value: ['entry_date'], type: 'date' },
        { label: 'Flight/Vessel Name and Number', value: ['vehicle_number'] },
        { label: 'Date of Departure', value: ['departure_date'], type: 'date' },
        { label: 'Port of Departure in Indonesia', value: ['departurePortData', 'label'] }
      ]
    },
    {
      groupTitle: 'ADDRESS IN INDONESIA',
      detail: [
        { label: 'Address in Indonesia', value: ['id_address'] },
        { label: 'City', value: ['idCityData', 'name'] },
        { label: 'Province', value: ['idProvinceData', 'name'] },
        { label: 'ZIP Code', value: ['id_zipcode'] },
        { label: 'Phone Number in Indonesia', value: ['id_phone'] }
      ]
    }
  ],
  personal: [
    {
      groupTitle: 'GENERAL INFORMATION',
      detail: [
        { label: 'First Name', value: ['first_name'] },
        { label: 'Middle Name', value: ['middle_name'] },
        { label: 'Family/Surname', value: ['last_name'] },
        { label: 'Sex', value: ['sex'] },
        { label: 'Marrital Status', value: ['marital_status'] },
        { label: 'Country of Birth', value: ['birthCountryData', 'name'] },
        { label: 'Date of Birth', value: ['birth_date'], type: 'date' },
        { label: 'Place of Birth', value: ['birth_place'] },
        { label: 'Nationality', value: ['citizenshipData', 'name'] }
      ]
    },
    {
      groupTitle: 'HOME ADDRESS IN USA',
      detail: [
        { label: 'Address in USA', value: ['us_address'] },
        { label: 'City', value: ['us_city'] },
        { label: 'State', value: ['usStateData', 'label'] },
        { label: 'ZIP Code', value: ['us_zipcode'] },
        { label: 'Phone Number', value: ['us_phone'] },
        { label: 'Email', value: ['email'] }
      ]
    },
    {
      groupTitle: 'CURRENT EMPLOYMENT',
      detail: [
        { label: 'Profession', value: ['job_profession'] },
        { label: 'Current Position', value: ['job_position'] },
        { label: 'Name of Institution', value: ['job_institution'] },
        { label: 'Phone', value: ['job_phone'] },
        { label: 'Address', value: ['job_address'] },
        { label: 'City', value: ['job_city'] },
        { label: 'State', value: ['job_state_id'] },
        { label: 'Country', value: ['jobCountryData', 'name'] },
        { label: 'ZIP Code', value: ['job_zipcode'] }
      ]
    }
  ],
  passport: [
    {
      groupTitle: null,
      detail: [
        { label: 'Type of Passport', value: ['passport_type'] },
        { label: 'Passport Number', value: ['passport_number'] },
        { label: 'Place of Issue', value: ['passport_issuing'] },
        { label: 'Country of Place of Issue', value: ['passportCountryData', 'name'] },
        { label: 'Date of Issue', value: ['passport_issue_date'], type: 'date' },
        { label: 'Date of Expiry', value: ['passport_expiry'], type: 'date' }
      ]
    }
  ],
  other: [
    {
      groupTitle: 'SPONSOR INFORMATION',
      detail: [
        { label: 'Sponsorship Type', value: ['sponsor_type'] },
        { label: 'Name of Sponsor', value: ['sponsor_name'] },
        { label: 'Contact Name', value: ['sponsor_contact'] },
        { label: 'Address', value: ['sponsor_address'] },
        { label: 'City', value: ['sponsorCityData', 'name'] },
        { label: 'Province', value: ['sponsorProvinceData', 'name'] },
        { label: 'ZIP Code', value: ['sponsor_zipcode'] },
        { label: 'Phone Number', value: ['sponsor_phone'] }
      ]
    },
    {
      groupTitle: 'OTHER INFORMATION',
      detail: [
        {
          label: 'Have you ever been to Indonesia before',
          value: ['questionnaire', 0, 'answer']
        },
        { label: 'If Yes Date of Visit', value: ['visit_date'], type: 'date' },
        { label: 'Purpose of Visit', value: ['last_visit_purpose'] },
        {
          label: 'Are you in Possesion of other countries travel documents',
          value: ['questionnaire', 1, 'answer']
        },
        {
          label: 'Do you have previous visa to enter Indonesia?',
          value: ['questionnaire', 2, 'answer']
        },
        {
          label: 'Have your visa application been denied before?',
          value: ['questionnaire', 3, 'answer']
        },
        {
          label: 'Have you ever been forced to leave Indonesia',
          value: ['questionnaire', 4, 'answer']
        },
        {
          label: 'Have you ever been commited a crime or any offence',
          value: ['questionnaire', 5, 'answer']
        },
        { label: 'Return/Through Ticket', value: ['return_ticket'] },
        { label: 'Place of Issue', value: ['ticket_issuing'] },
        { label: 'Date of Issue', value: ['ticket_issuing_date'], type: 'date' },
        { label: 'Date of Expiry', value: ['ticket_expiry_date'], type: 'date' }
      ]
    }
  ],
  photo: [
    {
      groupTitle: null,
      detail: [{ label: 'Photo', value: ['image_url'], type: 'img' }]
    }
  ],
  sign: [
    {
      groupTitle: null,
      detail: [
        { label: 'Completed at (City Name)', value: ['signature_name'] },
        { label: 'Signature Name', value: ['completed_at'] }
      ]
    }
  ]
};

const findDetail = (options, id, keyOptions) => {
  const data = options.find((option) => get(option, [keyOptions]) === id);

  return data;
};

const detailingData = ({
  data,
  country_options,
  provinceOptions,
  portOptions,
  cityIdList
}) => {
  const tmpData = { ...data };
  tmpData.citizenshipData = findDetail(
    country_options,
    get(data, ['nationality_code']),
    'code'
  );
  tmpData.departurePortData = findDetail(
    portOptions,
    get(data, ['departure_port_id']),
    '_id'
  );
  tmpData.entryPortData = findDetail(portOptions, get(data, ['entry_port_id']), '_id');
  tmpData.idProvinceData = findDetail(
    provinceOptions,
    get(data, ['id_province_code']),
    'code'
  );
  tmpData.idCityData = findDetail(cityIdList, get(data, ['id_city_code']), 'code');
  tmpData.passportCountryData = findDetail(
    country_options,
    get(data, ['passport_country_code']),
    'code'
  );
  tmpData.birthCountryData = findDetail(
    country_options,
    get(data, ['birth_country_code']),
    'code'
  );
  tmpData.jobCountryData = findDetail(
    country_options,
    get(data, ['job_country_code']),
    'code'
  );
  tmpData.sponsorCityData = findDetail(
    cityIdList,
    get(data, ['sponsor_city_code']),
    'code'
  );
  tmpData.sponsorProvinceData = findDetail(
    provinceOptions,
    get(data, ['sponsor_province_code']),
    'code'
  );
  tmpData.usStateData = findDetail(kjri_area, get(data, ['us_state_id']), 'value');

  return tmpData;
};

export { tabDetail, detailingData };
