import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';
import Loading from '../../../components/Loading';

import PowerLetter from './PowerLetter';
import ValidationDoc from './ValidationDoc';

import { getDocLegalServiceList } from '../../form/DocLegalizationForm/action';

const DocLegalization = ({ history, i18n, docLegalService, getDocLegalServiceList }) => {
  const docLegalServiceList = get(docLegalService, ['data']);
  const serviceStatus = get(docLegalService, ['status']);
  const loadingService = serviceStatus !== 'resolve';

  const sideBarList = [
    {
      label: i18n('doc_legalization.tab1.title'),
      link: '/doc-legalization',
      Component: PowerLetter
    },
    {
      label: i18n('doc_legalization.tab2.title'),
      link: '/doc-legalization/validation',
      Component: ValidationDoc
    }
  ];

  // active tab
  const activePath = get(history, ['location', 'pathname']);

  useEffect(() => {
    getDocLegalServiceList();
  }, [getDocLegalServiceList]);

  return (
    <>
      <Banner
        title={i18n('doc_legalization.banner.title')}
        subtitle={
          <>
            <div className='text'>{i18n('doc_legalization.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('doc_legalization.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      {loadingService && <Loading />}
      <CardSideBar
        activePath={activePath}
        sideBarList={sideBarList}
        docLegalServiceList={docLegalServiceList}
      />
    </>
  );
};

DocLegalization.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  docLegalService: PropTypes.object,
  getDocLegalServiceList: PropTypes.func
};

const mapStateToProps = (state) => ({
  docLegalService: {
    status: state.docLegalServiceReducer.status,
    error: state.docLegalServiceReducer.error,
    data: state.docLegalServiceReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getDocLegalServiceList }),
  withRouter
)(DocLegalization);
