import { apiCaller } from '../../../helpers/apiCaller';

const getNumberRegisterRequest = () => ({
  type: 'GET_NUMBER_REGISTER_REQUEST'
});

const getNumberRegisterSuccess = (data) => ({
  type: 'GET_NUMBER_REGISTER_SUCCESS',
  payload: data
});

const getNumberRegisterFailure = (error) => ({
  type: 'GET_NUMBER_REGISTER_FAILURE',
  payload: error
});

const postNumberRegister = (params) => {
  return (dispatch) => {
    dispatch(getNumberRegisterRequest());
    return apiCaller('visa/registration', 'post', params)
      .then((data) => dispatch(getNumberRegisterSuccess(data)))
      .catch((error) => dispatch(getNumberRegisterFailure(error)));
  };
};

const getVisaApplicantRequest = () => ({
  type: 'GET_VISA_APPLICANT_REQUEST'
});

const getVisaApplicantSuccess = (data) => ({
  type: 'GET_VISA_APPLICANT_SUCCESS',
  visaApplicant: data
});

const getVisaApplicantFailure = (error) => ({
  type: 'GET_VISA_APPLICANT_FAILURE',
  visaApplicant: error
});

const getVisaApplicant = (params) => {
  return (dispatch) => {
    dispatch(getVisaApplicantRequest());
    return apiCaller(`visa/find-submission${params}`)
      .then((data) => dispatch(getVisaApplicantSuccess(data)))
      .catch((error) => dispatch(getVisaApplicantFailure(error)));
  };
};

const getDetail = (list, id, keyId) => {
  const check = list.find((item) => item[keyId] === id);

  return check;
};

export { postNumberRegister, getVisaApplicant, getDetail };
