import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Banner from '../../../components/Banner';
import './style.scss';
import { Container } from 'reactstrap';
import ContactDetail from './ContactDetail';

import location from '../../../assets/img/homepage/place_24px.svg';
import fax from '../../../assets/img/homepage/cil_fax.svg';
import email from '../../../assets/img/homepage/email_24px.svg';
import call from '../../../assets/img/homepage/call_24px.svg';
import maps from '../../../assets/img/kjri_maps.png';

const ContactUs = ({ history, i18n }) => {
  return (
    <>
      <Banner
        title={i18n('contact_us.title')}
        subtitle={i18n('contact_us.subtitle')}
        backButtonClick={() => history.goBack()}
      />
      <Container style={{ marginTop: 26 }}>
        <div className='contact-us'>
          <h1>{i18n('contact_us.title1')}</h1>
          <h5>{i18n('contact_us.state')}</h5>
          <div className='inline-info'>
            <img src={location} alt='location' />
            ​5, East 68th Street, New York, NY 10065, The United States of America
          </div>
          {/* <div className='inline-info'>
            <img src={call} alt='location' />
            +1 415-875-0793
          </div> */}
          <div className='inline-info'>
            <img src={email} alt='location' />
            consular.newyork@kemlu.go.id / socio-cultural.newyork@kemlu.go.id /
            economic.newyork@kemlu.go.id
          </div>
          <div className='inline-info'>
            <img src={fax} alt='location' />
            (1-212) 212 879 0600
          </div>
          <p
            style={{ marginTop: 25 }}
            dangerouslySetInnerHTML={{ __html: i18n('contact_us.content1') }}
          />
          <p
            style={{ marginTop: 25 }}
            dangerouslySetInnerHTML={{ __html: i18n('contact_us.content2') }}
          />
          <div className='contact-list'>
            <ContactDetail
              title='Hotline 1'
              number='+1 (347) 806-9279'
              mail='consular.newyork@kemlu.go.id'
              wa='+13478069279'
              wil=''
            />
            <ContactDetail
              title='Hotline 2'
              number='+1 (646) 238-8721'
              mail='socio-cultural.newyork@kemlu.go.id'
              wa='+16462388721'
              wil=''
            />
            <ContactDetail
              title='Hotline 3'
              number='+1 (646) 491-3809'
              mail='economic.newyork@kemlu.go.id'
              wa='+16464913809'
              wil=''
            />
            <ContactDetail
              title='Hotline 4'
              number='+1 (929) 366-9842'
              mail='admin@gmail.com'
              wa='+19293669842'
              wil=''
            />
            <ContactDetail
              title='Hotline 5'
              number='+1 (347) 806-9279'
              mail='admin@gmail.com'
              wa='+13478069279'
              wil=''
            />
          </div>
          <p
            style={{ marginTop: 35 }}
            dangerouslySetInnerHTML={{ __html: i18n('contact_us.content3') }}
          />
          <div className='contact-list'>
            <ContactDetail
              title={i18n('contact_us.kjri_wdc')}
              number='+1 202-569-7996'
              wa='+12025697996'
              mail=''
              wil='WDC'
            />
            <ContactDetail
              title={i18n('contact_us.kjri_chicago')}
              number='+1 312-547-9114'
              wa='+13125479114'
              mail=''
              wil='CH'
            />
            <ContactDetail
              title={i18n('contact_us.kjri_houston')}
              number='+1 346-932-7284'
              wa='+13469327284'
              mail=''
              wil='HO'
            />
            <ContactDetail
              title={i18n('contact_us.kjri_la')}
              number='+1 213-590-8095'
              wa='+12135908095'
              mail=''
              wil='LA'
            />
            <ContactDetail
              title={i18n('contact_us.kjri_sf')}
              number='+1 415-474-9571'
              wa='+14154749571'
              mail=''
              wil='SF'
            />
          </div>
          <p
            style={{ marginTop: 35 }}
            dangerouslySetInnerHTML={{ __html: i18n('contact_us.content4') }}
          />
          <div className='inline-info' style={{ marginTop: -15 }}>
            <img src={location} alt='location' />
            ​5, East 68th Street, New York, NY 10065, The United States of America
          </div>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.google.com/maps/place/KJRI+NY/@40.7697689,-73.9699757,17z/data=!4m5!3m4!1s0x89c258ec518c04ed:0xef6c45acab06e83b!8m2!3d40.7696355!4d-73.9679807'
          >
            <img className='kjri-maps' src={maps} alt='maps' />
          </a>
        </div>
      </Container>
    </>
  );
};

ContactUs.propTypes = {
  i18n: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps, withRouter))(ContactUs);
