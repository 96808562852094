const news = {
  data: [],
  status: 'idle',
  error: ''
};

const newsReducer = (state = news, action) => {
  switch (action.type) {
    case 'GET_NEWS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NEWS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_NEWS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: [...state.data, action.payload.data]
      };
    default:
      return state;
  }
};

export { newsReducer };
