import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Button from '../../../../../components/Button';
import Icon from '../../../../../components/Icon';
import { getQuery } from '../../../../pages/Paspor/function';

import './style.scss';

const CourseReview = ({ history, i18n }) => {
  const { type, email } = getQuery(history);
  const courseName = {
    bahasa: 'bahasa_class',
    gamelan: 'gamelan_class'
  };
  const courseLink = {
    bahasa: 'lokakarya-bahasa',
    gamelan: 'lokakarya-gamelan-bali'
  };

  return (
    <div className='review-courses'>
      <div className='container'>
        <span
          className='back'
          onClick={() => history.push(`/pensosbud/${courseLink[type]}`)}
        >
          <Icon icon='arrow-blue' />
          {i18n('pensosbud.lokakarya_form.back')}
        </span>
        <div className='success-message'>
          <div className='success'>
            <Icon icon='green-check' />
            {i18n('pensosbud.lokakarya_form.success')}
          </div>
        </div>
        <div className='detail'>
          <div className='label'>{i18n('pensosbud.lokakarya_form.ur_course')}</div>
          <div className='val'>
            {i18n(`pensosbud.lokakarya_form.${courseName[type]}`)}
          </div>
        </div>
        <div className='detail'>
          <div className='label'>{i18n('pensosbud.lokakarya_form.send_to')}</div>
          <div className='val'>{email}</div>
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='primary'
          onClick={() => history.push(`/pensosbud/${courseLink[type]}`)}
          className='pass-btn'
        >
          {i18n('pensosbud.lokakarya_form.back_to_home')}
        </Button>
      </div>
    </div>
  );
};

CourseReview.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  sosBudEducation: {
    status: state.sosBudEducationReducer.status,
    error: state.sosBudEducationReducer.error,
    data: state.sosBudEducationReducer.data
  }
});

export default compose(connect(mapStateToProps), withRouter)(CourseReview);
