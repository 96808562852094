import { apiCaller } from '../../../helpers/apiCaller';

// Get event list
const getEventListRequest = () => ({
  type: 'GET_EVENT_LIST_REQUEST'
});

const getEventListSuccess = (data) => ({
  type: 'GET_EVENT_LIST_SUCCESS',
  eventList: data
});

const getEventListFailure = (error) => ({
  type: 'GET_EVENT_LIST_FAILURE',
  eventList: error
});

const getEventList = () => {
  return (dispatch) => {
    dispatch(getEventListRequest());
    return apiCaller('events')
      .then((data) => dispatch(getEventListSuccess(data)))
      .catch((error) => dispatch(getEventListFailure(error)));
  };
};

// Get event detail
const getEventDetailRequest = () => ({
  type: 'GET_EVENT_DETAIL_REQUEST'
});

const getEventDetailSuccess = (data) => ({
  type: 'GET_EVENT_DETAIL_SUCCESS',
  eventDetail: data
});

const getEventDetailFailure = (error) => ({
  type: 'GET_EVENT_DETAIL_FAILURE',
  eventDetail: error
});

const getEventDetail = (id) => {
  return (dispatch) => {
    dispatch(getEventDetailRequest());
    return apiCaller(`events/${id}`)
      .then((data) => dispatch(getEventDetailSuccess(data)))
      .catch((error) => dispatch(getEventDetailFailure(error)));
  };
};

const postEventRegisterRequest = () => ({
  type: 'POST_EVENT_REGISTER_REQUEST'
});

const postEventRegisterSuccess = (data) => ({
  type: 'POST_EVENT_REGISTER_SUCCESS',
  payload: data
});

const postEventRegisterFailure = (error) => ({
  type: 'POST_EVENT_REGISTER_FAILURE',
  payload: error
});

const postEventRegister = (params) => {
  return (dispatch) => {
    dispatch(postEventRegisterRequest());
    return apiCaller('events/registration', 'post', params, false)
      .then((data) => dispatch(postEventRegisterSuccess(data)))
      .catch((error) => dispatch(postEventRegisterFailure(error)));
  };
};

export { getEventList, getEventDetail, postEventRegister };
