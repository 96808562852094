import React, { useEffect } from 'react';
import { withRouter, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getEventDetail } from '../action';
import DashboardTable from '../../../../components/DashboardTableOld2';

import placeIcon from '../../../../assets/img/homepage/place_24px.svg';
import time from '../../../../assets/img/homepage/event_24px.svg';

const DetailNewsAdmin = ({ history, i18n, getEventDetail, eventDetail }) => {
  const match = useRouteMatch();
  const { id } = match.params;
  const data = history.location.state;

  const params = {
    page: 1,
    per_page: 200,
    event_id: id
  };

  useEffect(() => {
    getEventDetail(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const action = [];

  const columns = [
    { dataField: 'name', text: 'Title' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'created_at', text: 'Date' }
  ];
  const dataTable = eventDetail.data.data;

  return (
    <>
      {data.map((data, index) => (
        <>
          {data._id === id ? (
            <div className='admin-event-detail'>
              <span
                className='back-homepage'
                onClick={() => history.push('/admin/events')}
              >
                <span className='btn-back' />
                DETAIL ACARA/PAMERAN
              </span>
              <div className='event-admin-detail'>
                <div className='loka-banner'>
                  <div className='lb-left'>
                    <div className='lbl-left'>
                      <img src={data.image_url} alt='detail-event' />
                    </div>
                    <div className='lbl-right'>
                      <h3>{data.title}</h3>
                      <div>
                        <img
                          src={time}
                          alt='activity-thumbnail-place'
                          style={{ marginRight: 5 }}
                        />
                        {moment(data.datetime).format('MMMM DD, HH:MM')}
                        <span className='kotak-kuning'>Pendaftaran</span>
                      </div>
                      <div>
                        <img
                          src={placeIcon}
                          alt='activity-thumbnail-place'
                          style={{ marginRight: 5 }}
                        />
                        {data.place}
                      </div>
                    </div>
                  </div>
                  <div className='lb-right'>
                    <div className='total-pendaftar'>
                      {eventDetail.status === 'resolve' ? dataTable.data.length : ''}
                    </div>
                    <div className='label'>Pendaftar</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ))}
      {eventDetail.status === 'resolve' ? (
        <DashboardTable
          columns={columns}
          data={dataTable.data}
          action={action}
          search='name'
          idKeyField='id'
        />
      ) : (
        ''
      )}
    </>
  );
};

DetailNewsAdmin.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  getEventDetail: PropTypes.func,
  eventDetail: PropTypes.any
};

const mapStateToProps = (state) => ({
  eventDetail: {
    status: state.detailEventReducerAdmin.status,
    error: state.detailEventReducerAdmin.error,
    data: state.detailEventReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getEventDetail
  }),
  withRouter
)(DetailNewsAdmin);
