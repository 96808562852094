import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import Action from './Action';
import ImageView from './ImageView';
import searchIcon from '../../assets/img/icon/search.svg';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import './style.scss';
import get from 'lodash/get';

const DashboardTable = ({ columns, data, action, search, idKeyField = 'id' }) => {
  const [pageDivider, setPageDivider] = useState(45);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyWord, setKeyword] = useState('');
  const lastView = currentPage * pageDivider;
  const firstView = lastView - pageDivider;
  const listHead = columns.map((column, index) => (
    <td key={index} className={column.className || ''}>
      {column.text}
    </td>
  ));
  let count = 0;

  const columnDetail = (column, data, count) => {
    const { text, customColumn } = column;
    const columData = get(data, column.dataField);
    if (text === 'Action') {
      return <Action action={action} id={data[idKeyField]} data={data} />;
    } else if (text === 'CustomAction') {
      const selectedAction = action(data);
      return <Action action={selectedAction} id={data[idKeyField]} data={data} />;
    } else if (text === 'No') {
      return count;
    } else if (text.includes('Date')) {
      return moment(data[column.dataField])
        .utcOffset(data[column.dataField])
        .format('LLL');
    } else if (text.includes('At')) {
      return moment(data[column.dataField])
        .utcOffset(data[column.dataField])
        .format('LLL');
    } else if (text === 'Holiday') {
      return moment(data[column.dataField]).format('YYYY-MM-DD');
    } else if (text === 'Image') {
      return <ImageView url={data[column.dataField]} />;
    } else if (customColumn) {
      return customColumn(data);
    } else {
      return columData || '-';
    }
  };

  const dataView = data.map((data, index) => (
    <>
      {data[search].toString().toLowerCase().includes(keyWord) && (
        <>
          {count++ < lastView && count > firstView && (
            <tr key={index}>
              {columns.map((column, index) => (
                <td
                  key={index}
                  className={column.className || ''}
                  style={{ maxWidth: 180 }}
                >
                  {columnDetail(column, data, count)}
                </td>
              ))}
            </tr>
          )}
        </>
      )}
    </>
  ));

  const changePageDivider = (e) => {
    setPageDivider(e.target.value);
    setCurrentPage(1);
  };

  const changeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const changeCurrentPage = (e) => {
    setCurrentPage(e);
  };

  return (
    <div className='dashboard-table'>
      <div className='table-top'>
        <div className='page-view'>
          Show
          <select
            value={pageDivider}
            name='pageview'
            id='pageview'
            onChange={changePageDivider}
          >
            <option value='5'>5</option>
            <option value='15'>15</option>
            <option value='25'>25</option>
            <option value='45'>45</option>
          </select>
        </div>
        <div className='search'>
          <img src={searchIcon} alt='search-img' />
          <input value={keyWord} placeholder='Search Data..' onChange={changeKeyword} />
        </div>
      </div>
      <table>
        <thead>
          <tr>{listHead}</tr>
        </thead>
        <tbody>{dataView}</tbody>
      </table>
      <div className='table-bottom'>
        <div className='data-view'>
          {keyWord === '' && (
            <span>
              Data {pageDivider > data.length ? data.length : pageDivider} of{' '}
              {data.length}
            </span>
          )}
        </div>
        <Pagination
          divider={parseInt(pageDivider)}
          total={count}
          currentPage={currentPage}
          setCurrentPage={changeCurrentPage}
        />
      </div>
    </div>
  );
};

DashboardTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  action: PropTypes.array,
  search: PropTypes.string,
  idKeyField: PropTypes.any
};

export default withRouter(DashboardTable);
