import get from 'lodash/get';
import id from './id.json';
import en from './en.json';

const lang = { id, en };

if (!String.prototype.format) {
  // eslint-disable-next-line no-extend-native
  String.prototype.format = function (params) {
    return this.replace(/{(\w+)}/g, function (match, name) {
      return params && typeof params[name] !== 'undefined' ? params[name] : match;
    });
  };
}

export const sanitizeLang = (lang) => {
  return /^(id|en)$/.test(lang) ? lang : 'id';
};

export const getTranslation = (source, key) => {
  if (!key) {
    return '';
  }

  if (!source) {
    return key;
  }

  return source[key] || key;
};

const findTranslation = (source, key) => {
  if (!key) {
    return '';
  }

  if (typeof key !== 'string') {
    return '';
  }

  const matches = key.split('.');
  let i = 0;
  let str = source;

  while (i < matches.length) {
    str = getTranslation(str, matches[i]);
    i++;
  }

  return str;
};

export const i18n = (userlang) => {
  return (str, rest) => {
    const translation = findTranslation(lang[userlang], str);

    // handle pluralisation
    if (translation && Array.isArray(translation)) {
      if (translation.length === 0) {
        return str;
      }

      return translation.length > 1
        ? translation[get(rest, ['count']) === 1 ? 0 : 1]
        : translation[0];
    }

    return translation && typeof translation === 'string'
      ? translation.format(rest)
      : str;
  };
};

export const sanitizeCurrency = (curr) => {
  const supported = /^(IDR|USD|SGD|MYR|THB|JPY|EUR)$/i;

  return curr && supported.test(curr) ? curr.toUpperCase() : 'IDR';
};

export default i18n();
