import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Review from '../../components/NumberRegister/Review';

import { getQuery } from '../../../pages/Paspor/function';
import {
  getRegisDetailStatement,
  getStatementServiceList,
  resetDetailRegisterStatement
} from '../action';
import { getServiceTitle, statementType } from '../functions';

const RegisterReview = ({
  history,
  i18n,
  getRegisDetailStatement,
  statementRegisDetail,
  getStatementServiceList,
  statementService,
  resetDetailRegisterStatement
}) => {
  const { uniqueId, type } = getQuery(history);
  const statementDetail = get(statementRegisDetail, ['data']);
  const serviceId = get(statementDetail, ['letter_service', 'id']);
  const keyTitle = getServiceTitle(get(statementService, ['data']), serviceId);
  const title =
    keyTitle && statementType[keyTitle] && i18n(`${statementType[keyTitle].label}`);
  const redirect = '/statement-letter';

  useEffect(() => {
    if (!uniqueId) {
      if (type) {
        history.push(`/${type}`);
      } else {
        history.push('/');
      }
    } else if (type && uniqueId) {
      getStatementServiceList();
      getRegisDetailStatement(`${uniqueId}`);
    }

    return () => {
      resetDetailRegisterStatement();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNextStep = () => {
    if (keyTitle && statementType[keyTitle]) {
      const list = keyTitle && statementType[keyTitle].list;
      return (
        <>
          <ol>
            {list.map((listTitle, key) => (
              <li
                key={key}
                dangerouslySetInnerHTML={{
                  __html: i18n(`statement_letter.instruction_step.${listTitle}`)
                }}
              />
            ))}
          </ol>
          <div>{i18n('track_status_info')}</div>
        </>
      );
    }
  };

  return (
    <Review
      i18n={i18n}
      reviewDetail={statementDetail}
      title={title}
      nextDirection={redirect}
      serviceName={title}
      withModalSuccess
      stepNextRegister={renderNextStep()}
    />
  );
};

RegisterReview.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  getRegisDetailStatement: PropTypes.func,
  statementRegisDetail: PropTypes.object,
  getStatementServiceList: PropTypes.func,
  statementService: PropTypes.array,
  resetDetailRegisterStatement: PropTypes.func
};

const mapStateToProps = (state) => ({
  statementRegisDetail: {
    status: state.regisDetailStatementReducer.status,
    error: state.regisDetailStatementReducer.error,
    data: state.regisDetailStatementReducer.data
  },
  statementService: {
    status: state.statementServiceReducer.status,
    error: state.statementServiceReducer.error,
    data: state.statementServiceReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getRegisDetailStatement,
    getStatementServiceList,
    resetDetailRegisterStatement
  }),
  withRouter
)(RegisterReview);
