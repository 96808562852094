import { apiCaller } from '../../../helpers/apiCaller';
// LIST
const getDiasporaListRequest = () => ({
  type: 'GET_DIASPORA_REQUEST'
});

const getDiasporaListSuccess = (data) => ({
  type: 'GET_DIASPORA_SUCCESS',
  payload: data
});

const getDiasporaListFailure = (error) => ({
  type: 'GET_DIASPORA_FAILURE',
  payload: error
});

const resetDiasporaList = () => ({
  type: 'RESET_DIASPORA_LIST'
});

const getDiasporaList = (params) => {
  return (dispatch) => {
    dispatch(getDiasporaListRequest());
    return apiCaller('admin/diaspora/registration', 'get', undefined, true, params)
      .then((data) => dispatch(getDiasporaListSuccess(data)))
      .catch((error) => dispatch(getDiasporaListFailure(error)));
  };
};

// DETAIL
const getDiasporaDetailRequest = () => ({
  type: 'GET_DIASPORA_DETAIL_REQUEST'
});

const getDiasporaDetailSuccess = (data) => ({
  type: 'GET_DIASPORA_DETAIL_SUCCESS',
  payload: data
});

const getDiasporaDetailFailure = (error) => ({
  type: 'GET_DIASPORA_DETAIL_FAILURE',
  payload: error
});

const getDiasporaDetail = (id) => {
  return (dispatch) => {
    dispatch(getDiasporaDetailRequest());
    return apiCaller(
      `admin/diaspora/registration/${id}?type=unique_number`,
      'get',
      undefined,
      true
    )
      .then((data) => dispatch(getDiasporaDetailSuccess(data)))
      .catch((error) => dispatch(getDiasporaDetailFailure(error)));
  };
};
// DAY
const getStatisticRequest = () => ({
  type: 'GET_DIASPORA_STATISTIC_REQUEST'
});

const getStatisticSuccess = (data) => ({
  type: 'GET_DIASPORA_STATISTIC_SUCCESS',
  payload: data
});

const getStatisticFailure = (error) => ({
  type: 'GET_DIASPORA_STATISTIC_FAILURE',
  payload: error
});

const getStatistic = (params) => {
  return (dispatch) => {
    dispatch(getStatisticRequest());
    return apiCaller('admin/diaspora/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticSuccess(data)))
      .catch((error) => dispatch(getStatisticFailure(error)));
  };
};

// WEEK
const getStatisticWeekRequest = () => ({
  type: 'GET_DIASPORA_STATISTIC_WEEK_REQUEST'
});

const getStatisticWeekSuccess = (data) => ({
  type: 'GET_DIASPORA_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getStatisticWeekFailure = (error) => ({
  type: 'GET_DIASPORA_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getStatisticWeek = (params) => {
  return (dispatch) => {
    dispatch(getStatisticWeekRequest());
    return apiCaller('admin/diaspora/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getStatisticWeekFailure(error)));
  };
};

// MONTH
const getStatisticMonthRequest = () => ({
  type: 'GET_DIASPORA_STATISTIC_MONTH_REQUEST'
});

const getStatisticMonthSuccess = (data) => ({
  type: 'GET_DIASPORA_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getStatisticMonthFailure = (error) => ({
  type: 'GET_DIASPORA_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getStatisticMonth = (params) => {
  return (dispatch) => {
    dispatch(getStatisticMonthRequest());
    return apiCaller('admin/diaspora/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getStatisticMonthFailure(error)));
  };
};

export {
  resetDiasporaList,
  getDiasporaList,
  getDiasporaDetail,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
};
