import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import TextInput from '../../../../components/input/TextInput';
import Datepicker from '../../../../components/input/Datepicker';
import './style.scss';

const DobDetail = ({ place, date, disable = false }) => {
  return (
    <div className='row-input dob-container'>
      <TextInput
        label={place.label}
        placeholder={place.placeholder}
        className='dob-place'
        value={get(place, ['value'])}
        inputProps={{
          maxLength: 255,
          onChange: (e) => place.onChange(e.target.value)
        }}
        errorMessage={place.errorMessage}
        disable={disable}
      />
      <Datepicker
        label={date.label}
        placeholder={date.placeholder}
        className='dob-date'
        value={get(date, ['value'])}
        onChange={(birth_date) => date.onChange(birth_date)}
        datepickerProps={date.datepickerProps}
        errorMessage={date.errorMessage}
        disable={disable}
      />
    </div>
  );
};

DobDetail.propTypes = {
  i18n: PropTypes.func,
  place: PropTypes.object,
  date: PropTypes.object
};

export default DobDetail;
