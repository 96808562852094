import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';
import { getPassportServiceId } from '../function';

const SPLPDetail = ({ i18n, passportServiceList }) => {
  const passportServiceId = getPassportServiceId(passportServiceList, 'splp');
  const lang = (type, params) => i18n(`paspor_page.tab6.${type}`, { ...params });

  const stepOuterList = [
    {
      label: i18n('paspor_page.tab6.step.splp_publish')
    },
    {
      label: i18n('paspor_page.tab6.step.field_the_form'),
      cta: i18n('paspor_page.step.create_form_register.cta'),
      link: `/passpor/schedule?type=splp&serviceId=${passportServiceId}`
    }
  ];

  const instructionList = [
    i18n('paspor_page.instruction_step.lost_letter'),
    i18n('paspor_page.instruction_step.copy_identitas_idn'),
    i18n('paspor_page.instruction_step.copy_lost_passpor'),
    i18n('paspor_page.instruction_step.photo_color'),
    i18n('paspor_page.instruction_step.usps_money_order', { price: 23 })
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

SPLPDetail.propTypes = {
  i18n: PropTypes.func,
  passportServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(SPLPDetail);
