const adminLogin = {
  data: {},
  status: 'idle',
  error: ''
};

const postAdminLoginReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'POST_ADMIN_LOGIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_ADMIN_LOGIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_ADMIN_LOGIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export { postAdminLoginReducer };
