import React from 'react';

const NotFound = () => {
  return (
    <>
      <h1>Sorry, page not found!</h1>
    </>
  );
};

export default NotFound;
