import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Datepicker from '../../../../components/input/Datepicker';
import './style.scss';

const DatePickerRange = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onCalendarClose
}) => {
  const EndDatePickerRef = React.useRef();

  return (
    <Datepicker
      className='date-range-container'
      label='Data range:'
      icon='chevron down'
      datepickerProps={{
        selected: startDate,
        onChange: (date) => {
          setStartDate(date);
          if (endDate) {
            const start = moment(date);
            const end = moment(endDate);
            const diff = end.diff(start, 'days');
            if (diff < 0) {
              setEndDate(null);
            }
          }
          EndDatePickerRef.current.setOpen(true);
        },
        startDate: startDate,
        endDate: endDate,
        selectsStart: true
      }}
      rangeDetail={{
        selected: endDate,
        onChange: (date) => setEndDate(date),
        selectsEnd: true,
        startDate: startDate,
        endDate: endDate,
        minDate: startDate,
        ref: EndDatePickerRef,
        onCalendarClose: onCalendarClose
      }}
    />
  );
};

DatePickerRange.propTypes = {
  startDate: PropTypes.any,
  setStartDate: PropTypes.func,
  endDate: PropTypes.any,
  setEndDate: PropTypes.func,
  onCalendarClose: PropTypes.func
};

export default DatePickerRange;
