import { apiCaller } from '../../../../helpers/apiCaller';

// Number regis passport
const getExhibitorRequest = () => ({
  type: 'GET_EXHIBITOR_REQUEST'
});

const getExhibitorSuccess = (data) => ({
  type: 'GET_EXHIBITOR_SUCCESS',
  payload: data
});

const getExhibitorFailure = (error) => ({
  type: 'GET_EXHIBITOR_FAILURE',
  payload: error
});

const postExhibitor = (params) => {
  return (dispatch) => {
    dispatch(getExhibitorRequest());
    return apiCaller('exhibitors', 'post', params)
      .then((data) => dispatch(getExhibitorSuccess(data)))
      .catch((error) => dispatch(getExhibitorFailure(error)));
  };
};

const resetExhibitor = () => {
  const defaultData = {
    data: [],
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_POST_DATA',
      data: defaultData
    });
  };
};

export { postExhibitor, resetExhibitor };
