import get from 'lodash/get';
import { parseQuery } from '../../../helpers/functions';
import passportServiceKey from '../../../constant/passportService';

export const getQuery = (history) => {
  const { location } = history;
  const { search } = location;
  const searchQuery = parseQuery(search) || {};

  return searchQuery;
};

export const getPassportServiceId = (list, key) => {
  const passportServiceSelected = list.find(
    ({ title }) => title === passportServiceKey[key]
  );

  return get(passportServiceSelected, ['id']);
};

export const getPassportServiceDetail = (list, serviceId) => {
  const passportServiceSelected = list.find(({ id }) => id === serviceId);
  const passportTitle = get(passportServiceSelected, ['title']);
  const passportKey = Object.keys(passportServiceKey).find((key) => {
    if (passportServiceKey[key] === passportTitle) {
      return key;
    }
  });
  return passportDetail[passportKey];
};

export const passportDetail = {
  expired_pass: {
    route: '',
    instructionList: [
      'original_passpor',
      'permission_form',
      'permission_address',
      'identity',
      'birth_certificate',
      'marriage_certificate',
      'usps_money_order'
    ]
  },
  broken_pass: {
    route: '/change',
    instructionList: [
      'original_passpor',
      'permission_form',
      'permission_address',
      'identity',
      'birth_certificate',
      'marriage_certificate',
      'usps_money_order'
    ],
    price: 58
  },
  expired_child: {
    route: '/expired-child',
    instructionList: [
      'original_passpor',
      'copy_birth_certificate',
      'copy_parent_marriage_certificate',
      'copy_passpor_child_amerika',
      'copy_passport_parent',
      'permission_address_parent',
      'copy_birth_information_kjri',
      'usps_money_order'
    ]
  },
  lost_pass: {
    route: '/lost',
    instructionList: [
      'non_foreign_citizenship',
      'affidavid_legalisir',
      'lost_passpor_letter',
      'copy_lost_passpor',
      'copy_birth_certificate',
      'copy_family_certificate',
      'copy_identity',
      'copy_diploma_certificate',
      'permission_form',
      'permission_address_2',
      'usps_money_order'
    ],
    price: 91
  },
  new_born_pass: {
    route: '/new-born',
    instructionList: [
      'fill_form_birth',
      'parent_statement_letter',
      'donotmind_statement_letter',
      'copy_birth_certificate',
      'copy_parent_marriage_certificate',
      'copy_passpor_child_amerika',
      'copy_passport_parent',
      'permission_address_parent',
      'usps_money_order'
    ]
  },
  splp: {
    route: '/splp',
    instructionList: [
      'lost_letter',
      'copy_identitas_idn',
      'copy_lost_passpor',
      'photo_color',
      'usps_money_order'
    ]
  }
};
