import get from 'lodash/get';

export const getDetailForm = (data) => {
  const personalDetail = [
    {
      label: 'form.trx_type.label',
      value: get(data, ['biography', 'passport_service', 'title'])
    },
    { label: 'form.fullname.label', value: get(data, ['biography', 'name']) },
    { label: 'form.dob_date_place', value: get(data, ['biography', 'birth_city']) },
    { label: 'form.gender.label', value: get(data, ['biography', 'gender']) },
    { label: 'form.religion.label', value: get(data, ['biography', 'religion']) },
    { label: 'form.height.label', value: get(data, ['biography', 'height']) }
  ];

  const passportDetail = [
    {
      label: 'form.passport_type.label',
      value: get(data, ['passport', 'type']),
      detailValue: [
        {
          label: 'form.number.label',
          value: get(data, ['unique_number'])
        }
      ]
    },
    {
      label: 'form.place_date_issue',
      value: `${get(data, ['passport', 'issuing_city'])}, ${get(data, [
        'passport',
        'issuing_date'
      ])}`
    },
    { label: 'form.date_expired.label', value: get(data, ['passport', 'expired_date']) }
  ];

  const visaDetail = [
    {
      label: 'form.visa_type.label',
      value: get(data, ['visa', 'type']),
      detailValue: [
        {
          label: 'form.number.label',
          value: get(data, ['visa', 'visa_number'])
        }
      ]
    },
    { label: 'form.date_expired.label', value: get(data, ['visa', 'visa_expiry_at']) },
    {
      label: 'form.type_stay_america.label',
      value: get(data, ['visa', 'permit_stay_type']),
      detailValue: [
        {
          label: 'form.number.label',
          value: get(data, ['visa', 'permit_stay_number'])
        }
      ]
    },
    {
      label: 'form.date_expired.label',
      value: get(data, ['visa', 'permit_stay_expiry_at'])
    }
  ];

  const addressDetail = [
    { label: 'form.address.label', value: get(data, ['address', 'address']) },
    {
      label: 'form.city.label',
      value: get(data, ['address', 'state']),
      detailValue: [
        {
          label: 'form.state.label',
          value: get(data, ['address', 'state'])
        },
        {
          label: 'form.zip.label',
          value: get(data, ['address', 'zip_code'])
        }
      ]
    },
    {
      label: 'form.phone_number',
      value: `(rumah) +1 ${get(data, ['address', 'home_phone'])}`
    },
    { label: '', value: `(cellular) +1 ${get(data, ['address', 'phone'])}` },
    { label: 'form.email.label', value: get(data, ['address', 'email']) }
  ];

  const jobDetail = [
    { label: 'form.job.label', value: get(data, ['work', 'title']) },
    {
      label: 'form.scholl_office_name.label',
      value: get(data, ['work', 'company_name'])
    },
    {
      label: 'form.scholl_office_address.label',
      value: get(data, ['work', 'company_address'])
    },
    {
      label: 'form.city.label',
      value: get(data, ['work', 'state']),
      detailValue: [
        {
          label: 'form.state.label',
          value: get(data, ['work', 'state'])
        },
        {
          label: 'form.zip.label',
          value: get(data, ['work', 'zip_code'])
        }
      ]
    },
    { label: 'form.phone_number', value: get(data, ['work', 'company_phone']) }
  ];

  const marriageDetail = [
    {
      label: 'form.marriage_status.label',
      value: get(data, ['marriage', 'marital_status'])
    },
    { label: 'form.spouse_name.label', value: get(data, ['marriage', 'spouse', 'name']) },
    {
      label: 'form.spouse_dob',
      value: `${get(data, ['marriage', 'spouse', 'birth_city'])}, ${get(data, [
        'marriage',
        'spouse',
        'birth_date'
      ])}`
    },
    {
      label: 'form.spouse_citizenship.placeholder',
      value: get(data, ['marriage', 'spouse', 'nationality'])
    }
  ];

  const fatherDetail = [
    { label: 'form.father_name.label', value: get(data, ['parent', 'father_name']) },
    {
      label: 'form.father_dob',
      value: `${get(data, ['parent', 'father_birth_city'])}, ${get(data, [
        'parent',
        'father_birth_date'
      ])}`
    },
    {
      label: 'form.father_citizenship.label',
      value: get(data, ['parent', 'father_nationality'])
    }
  ];

  const motherDetail = [
    { label: 'form.mother_name.label', value: get(data, ['parent', 'mother_name']) },
    {
      label: 'form.mother_dob',
      value: `${get(data, ['parent', 'mother_birth_city'])}, ${get(data, [
        'parent',
        'mother_birth_date'
      ])}`
    },
    {
      label: 'form.mother_citizenship.label',
      value: get(data, ['parent', 'mother_nationality'])
    }
  ];

  const emergencyContactUS = [
    { label: 'form.fullname.label', value: get(data, ['us_emergency', 'name']) },
    { label: 'form.american_address', value: get(data, ['us_emergency', 'address']) },
    {
      label: 'form.city.label',
      value: get(data, ['us_emergency', 'city']),
      detailValue: [
        {
          label: 'form.state.label',
          value: get(data, ['us_emergency', 'state'])
        },
        {
          label: 'form.zip.label',
          value: get(data, ['us_emergency', 'zip_code'])
        }
      ]
    },
    { label: 'form.phone_number', value: `+1 ${get(data, ['us_emergency', 'phone'])}` },
    { label: 'form.relationship.label', value: get(data, ['us_emergency', 'relation']) }
  ];

  const emergencyContactID = [
    { label: 'form.fullname.label', value: get(data, ['id_emergency', 'name']) },
    { label: 'form.indonesia_address', value: get(data, ['id_emergency', 'address']) },
    {
      label: 'form.city.label',
      value: get(data, ['id_emergency', 'city']),
      detailValue: [
        {
          label: 'form.state.label',
          value: get(data, ['id_emergency', 'province'])
        },
        {
          label: 'form.zip.label',
          value: get(data, ['id_emergency', 'postal_code'])
        }
      ]
    },
    { label: 'form.phone_number', value: `+62 ${get(data, ['id_emergency', 'phone'])}` },
    { label: 'form.relationship.label', value: get(data, ['id_emergency', 'relation']) }
  ];

  return {
    personalDetail,
    passportDetail,
    visaDetail,
    addressDetail,
    jobDetail,
    marriageDetail,
    fatherDetail,
    motherDetail,
    emergencyContactUS,
    emergencyContactID
  };
};
