import { apiCaller } from '../../../../helpers/apiCaller';

const getSlotTime = (slotTime) => ({
  type: 'SET_SLOT_TIME',
  slotTime
});

const setSlotTimeAction = (slotTime) => {
  return (dispatch) => {
    dispatch(getSlotTime(slotTime));
  };
};

// APPOINTMENT LIST -- SCHEDULE LIST
const getAppointmentsRequest = () => ({
  type: 'GET_APPOINTMENT_LIST_REQUEST'
});

const getAppointmentsSuccess = (data) => {
  return {
    type: 'GET_APPOINTMENT_LIST_SUCCESS',
    appointments: data
  };
};

const getAppointmentsFailure = (error) => ({
  type: 'GET_APPOINTMENT_LIST_FAILURE',
  appointments: error
});

const getAppointmentsList = (params = '') => {
  return (dispatch) => {
    dispatch(getAppointmentsRequest());
    return apiCaller(`appointments${params}`)
      .then((data) => dispatch(getAppointmentsSuccess(data.data)))
      .catch((error) => dispatch(getAppointmentsFailure(error)));
  };
};

// POST BOOK SCHEDULE LIST
const getBookAppointmentsRequest = () => ({
  type: 'POST_BOOK_APPOINTMENT_REQUEST'
});

const getBookAppointmentsSuccess = (data) => {
  return {
    type: 'POST_BOOK_APPOINTMENT_SUCCESS',
    bookAppointments: data
  };
};

const getBookAppointmentsFailure = (error) => ({
  type: 'POST_BOOK_APPOINTMENT_FAILURE',
  bookAppointments: error
});

const postBookAppointment = (params = '') => {
  return (dispatch) => {
    dispatch(getBookAppointmentsRequest());
    return apiCaller('book-appointment', 'post', params)
      .then((data) => dispatch(getBookAppointmentsSuccess(data.data)))
      .catch((error) => dispatch(getBookAppointmentsFailure(error.response)));
  };
};

// POST MULTIBOOK SCHEDULE LIST
const getMultiBookAppointmentsRequest = () => ({
  type: 'POST_MULTI_BOOK_APPOINTMENT_REQUEST'
});

const getMultiBookAppointmentsSuccess = (data) => {
  return {
    type: 'POST_MULTI_BOOK_APPOINTMENT_SUCCESS',
    multiBookAppointments: data
  };
};

const getMultiBookAppointmentsFailure = (error) => ({
  type: 'POST_MULTI_BOOK_APPOINTMENT_FAILURE',
  multiBookAppointments: error
});

const postMultiBookAppointment = (params = '') => {
  return (dispatch) => {
    dispatch(getMultiBookAppointmentsRequest());
    return apiCaller('multibook-appointment', 'post', params)
      .then((data) => dispatch(getMultiBookAppointmentsSuccess(data.data)))
      .catch((error) => dispatch(getMultiBookAppointmentsFailure(error.response)));
  };
};

// GET APPOINTMENT DETAIL
const getAppointmentDetailRequest = () => ({
  type: 'GET_APPOINTMENT_DETAIL_REQUEST'
});

const getAppointmentDetailSuccess = (data) => {
  return {
    type: 'GET_APPOINTMENT_DETAIL_SUCCESS',
    appointmentDetail: data
  };
};

const getAppointmentDetailFailure = (error) => ({
  type: 'GET_APPOINTMENT_DETAIL_FAILURE',
  appointmentDetail: error
});

const getAppointmentDetail = (params = '') => {
  return (dispatch) => {
    dispatch(getAppointmentDetailRequest());
    return apiCaller(`appointments${params}`)
      .then((data) => dispatch(getAppointmentDetailSuccess(data.data)))
      .catch((error) => dispatch(getAppointmentDetailFailure(error)));
  };
};

export {
  setSlotTimeAction,
  getAppointmentsList,
  postBookAppointment,
  getAppointmentDetail,
  postMultiBookAppointment
};
