import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './style.scss';
import { postUbahJadwalAntrian, getJadwalAntrian } from '../action';
import Loading from '../../../../components/Loading';

const EditJadwal = ({
  history,
  showModal,
  setShowModal,
  ubahJadwalAntrian,
  postUbahJadwalAntrian,
  getJadwalAntrian
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [quota, setQuota] = useState(0);
  const [duration, setDuration] = useState(0);
  const [startHours, setStartHours] = useState('08:30');
  const [loading, setLoading] = useState(false);

  let params = {
    from: startDate,
    to: endDate,
    start: startHours,
    status: 'AVAILABLE',
    quota: parseInt(quota),
    duration: parseInt(duration)
  };

  const sendUbahJadwal = () => {
    setLoading(true);
    postUbahJadwalAntrian(params);
  };

  if (ubahJadwalAntrian.status === 'resolve' && loading) {
    getJadwalAntrian();
    setLoading(false);
    setShowModal(false);
  }

  return (
    <div className={showModal ? 'edit-jadwal-container' : 'edit-jadwal-container hide'}>
      {loading && <Loading />}
      <div className='edit-jadwal-row'>
        <div className='edit-jadwal-top'>
          <h2>Pengaturan Antrian</h2>
          <span className='close' onClick={() => setShowModal(false)}>
            x
          </span>
        </div>
        <div className='edit-jadwal-bottom'>
          <div className='select-time'>
            <label>Tanggal Awal *</label>
            <input type='date' onChange={(e) => setStartDate(e.target.value)} />
          </div>
          <div className='select-time'>
            <label>Tanggal Akhir *</label>
            <input type='date' onChange={(e) => setEndDate(e.target.value)} />
          </div>
          <div className='select-time'>
            <label>Jam Buka *</label>
            <select name='time' id='time' onChange={(e) => setStartHours(e.target.value)}>
              <option value='08:30'>08.30AM</option>
              <option value='09:00'>09.00AM</option>
              <option value='09:30'>09.30AM</option>
              <option value='10:00'>10.00AM</option>
              <option value='10:30'>10.30AM</option>
              <option value='11:00'>11.00AM</option>
              <option value='11:30'>11.30AM</option>
              <option value='12:00'>12.00AM</option>
              <option value='12:30'>12.30AM</option>
              <option value='13:00'>01.00PM</option>
              <option value='13:30'>01.30PM</option>
              <option value='14:00'>02.00PM</option>
              <option value='14:30'>02.30PM</option>
              <option value='15:00'>03.00PM</option>
              <option value='15:30'>03.30PM</option>
              <option value='16:00'>04.00PM</option>
              <option value='16:30'>04.30PM</option>
              <option value='17:00'>05.00PM</option>
              <option value='17:30'>05.30PM</option>
              <option value='18:00'>06.00PM</option>
              <option value='18:30'>06.30PM</option>
              <option value='19:00'>07.00PM</option>
              <option value='19:30'>07.30PM</option>
              <option value='20:00'>08.00PM</option>
              <option value='20:30'>08.30PM</option>
              <option value='21:00'>09.00PM</option>
              <option value='21:30'>09.30PM</option>
              <option value='22:00'>10.00PM</option>
            </select>
          </div>
          <div className='time-range'>
            <label>Time Range / hari *</label>
            <input
              type='number'
              name='time-range'
              placeholder='Contoh: 10 *cukup tulis angka dalam satuan menit'
              id='time-range'
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>
          <div className='kuota'>
            <label>Maximal Kuota / hari *</label>
            <input
              type='number'
              placeholder='Contoh: 10'
              onChange={(e) => setQuota(e.target.value)}
            />
          </div>
        </div>
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => sendUbahJadwal()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

EditJadwal.propTypes = {
  history: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  postUbahJadwalAntrian: PropTypes.func,
  ubahJadwalAntrian: PropTypes.object,
  getJadwalAntrian: PropTypes.func
};

const mapStateToProps = (state) => ({
  ubahJadwalAntrian: {
    status: state.ubahJadwalAntrianReducerAdmin.status,
    error: state.ubahJadwalAntrianReducerAdmin.error,
    data: state.ubahJadwalAntrianReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postUbahJadwalAntrian,
    getJadwalAntrian
  }),
  withRouter
)(EditJadwal);
