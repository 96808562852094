import get from 'lodash/get';
import moment from 'moment';

const graduateDetails = (data) => [
  {
    title: 'GENERAL INFORMATION',
    list: [
      { value: get(data, ['name']), label: 'Nama Lengkap' },
      {
        value: get(data, ['gender']) === 'M' ? 'Laki-laki' : 'Perempuan',
        label: 'Jenis Kelamin'
      },
      {
        value: `${get(data, ['birth_city'])}, ${moment(get(data, ['birth_date'])).format(
          'MM DD YYYY'
        )}`,
        label: 'Tempat, Tanggal Lahir'
      },
      { value: get(data, ['passport_type']), label: 'Jenis Paspor' },
      { value: get(data, ['passport_number']), label: 'No. Paspor' },
      { value: get(data, ['passport_expiry']), label: 'Masa Berlaku Paspor' }
    ]
  },
  {
    title: 'ALAMAT',
    list: [
      { value: get(data, ['us_address']), label: 'Alamat di Amerika Serikat' },
      { value: get(data, ['id_address']), label: 'Alamat di Indonesia' },
      { value: get(data, ['email']), label: 'Email' },
      { value: get(data, ['phone']), label: 'No. Telepon Yang Bisa Dihubungi' }
    ]
  },
  {
    title: 'KETERANGAN SEKOLAH',
    list: [
      { value: get(data, ['school', 'name']), label: 'Nama Sekolah / Universitas' },
      { value: get(data, ['school', 'address']), label: 'Alamat Sekolah' },
      { value: get(data, ['school', 'study_length']), label: 'Jangka Waktu Belajar' },
      {
        value: get(data, ['school', 'graduate_date']),
        label: 'Tanggal lulus/selesai belajar (di diploma/sertifikat)'
      },
      { value: get(data, ['school', 'major']), label: 'Jurusan/Bidang Studi' },
      { value: get(data, ['school', 'salutation']), label: 'Gelar yang dipeloreh' },
      { value: get(data, ['school', 'score']), label: 'Grade Point Average (GPA)' }
    ]
  },
  {
    title: 'RENCANA BERANGKAT DI INDONESIA',
    list: [
      { value: get(data, ['departure_date']), label: 'Tanggal Berangkat Ke Indonesia' },
      { value: get(data, ['flight_number']), label: 'No. Penerbangan / Maskapai' }
    ]
  }
];

const transferScholldetails = (data) => [
  {
    title: 'DATA ANAK',
    list: [
      { value: get(data, ['child', 'name']), label: 'Nama Lengkap Anak' },
      {
        value: get(data, ['child', 'gender']) === 'M' ? 'Laki-laki' : 'Perempuan',
        label: 'Jenis Kelamin'
      },
      {
        value: `${get(data, ['child', 'birth_city'])}, ${moment(
          get(data, ['child', 'birth_date'])
        ).format('MM DD YYYY')}`,
        label: 'Tempat, Tanggal Lahir'
      },
      { value: get(data, ['child', 'passport_type']), label: 'Jenis Paspor' },
      { value: get(data, ['child', 'passport_number']), label: 'No. Paspor' },
      { value: get(data, ['child', 'passport_expiry']), label: 'Masa Berlaku Paspor' }
    ]
  },
  {
    title: 'DATA ORANG TUA - AYAH',
    list: [
      { value: get(data, ['father', 'name']), label: 'Nama Ayah' },
      {
        value: `${get(data, ['father', 'birth_city'])}, ${moment(
          get(data, ['father', 'birth_date'])
        ).format('MM DD YYYY')}`,
        label: 'Tempat Tanggal lahi ayah'
      },
      { value: get(data, ['father', 'nationality']), label: 'Kewarganegaraan Ayah' },
      { value: get(data, ['father', 'passport_type']), label: 'Jenis passpor Ayah' },
      { value: get(data, ['father', 'passport_number']), label: 'No. Paspor' },
      { value: get(data, ['father', 'passport_expiry']), label: 'Masa Berlaku Paspor' },
      { value: get(data, ['father', 'job_name']), label: 'Pekerjaan Ayah' }
    ]
  },
  {
    title: 'DATA ORANG TUA - IBU',
    list: [
      { value: get(data, ['mother', 'name']), label: 'Nama Ibu' },
      {
        value: `${get(data, ['mother', 'birth_city'])}, ${moment(
          get(data, ['mother', 'birth_date'])
        ).format('MM DD YYYY')}`,
        label: 'Tempat Tanggal lahi Ibu'
      },
      { value: get(data, ['mother', 'nationality']), label: 'Kewarganegaraan Ibu' },
      { value: get(data, ['mother', 'passport_type']), label: 'Jenis passpor Ibu' },
      { value: get(data, ['mother', 'passport_number']), label: 'No. Paspor' },
      { value: get(data, ['mother', 'passport_expiry']), label: 'Masa Berlaku Paspor' },
      { value: get(data, ['mother', 'job_name']), label: 'Pekerjaan Ibu' }
    ]
  },
  {
    title: 'KETERANGAN SEKOLAH',
    list: [
      { value: get(data, ['school', 'name']), label: 'Nama Sekolah' },
      { value: get(data, ['school', 'address']), label: 'Alamat Sekolah' },
      { value: get(data, ['school', 'old_grade']), label: 'Terakhir duduk di kelas' },
      { value: get(data, ['reason']), label: 'Alasan Pindah' },
      {
        value: get(data, ['school', 'new_grade']),
        label:
          'Sesuai surat keteranga dalam kepindahannya ke Indonesa yang bersangkutan dapat di terima di kelas'
      }
    ]
  },
  {
    title: 'ALAMAT',
    list: [
      { value: get(data, ['us_address']), label: 'Alamat di Amerika Serikat' },
      { value: get(data, ['id_address']), label: 'Alamat di Indonesia' },
      ,
      { value: get(data, ['email']), label: 'Email' },
      { value: get(data, ['email']), label: 'No. Telepon' }
    ]
  }
];

const pnsdetails = (data) => [
  {
    title: 'DATA DIRI',
    list: [
      { value: get(data, ['name']), label: 'Nama Lengkap' },
      {
        value: get(data, ['gender']) === 'M' ? 'Laki-laki' : 'Perempuan',
        label: 'Jenis Kelamin'
      },
      {
        value: `${get(data, ['birth_city'])}, ${moment(get(data, ['birth_date'])).format(
          'MM DD YYYY'
        )}`,
        label: 'Tempat, Tanggal Lahir'
      },
      { value: get(data, ['passport_type']), label: 'Jenis Paspor' },
      { value: get(data, ['passport_number']), label: 'No. Paspor' },
      { value: get(data, ['passport_expiry']), label: 'Masa Berlaku Paspor' }
    ]
  },
  {
    title: 'ALAMAT',
    list: [
      { value: get(data, ['us_address']), label: 'Alamat di Amerika Serikat' },
      { value: get(data, ['id_address']), label: 'Alamat di Indonesia' },
      { value: get(data, ['email']), label: 'Email' },
      { value: get(data, ['phone']), label: 'No. Telepon Yang Bisa Dihubungi' }
    ]
  },
  {
    title: 'KETERANGAN SEKOLAH',
    list: [
      { value: get(data, ['school', 'name']), label: 'Nama Sekolah/Universitas' },
      { value: get(data, ['school', 'address']), label: 'Alamat Sekolah' },
      { value: get(data, ['school', 'study_length']), label: 'Jangka waktu belajar' },
      {
        value: get(data, ['school', 'graduate_date']),
        label: 'Tanggal lulus/selesai belajar'
      },
      { value: get(data, ['school', 'major']), label: 'Jurusan/Bidang Studi' },
      { value: get(data, ['school', 'salutation']), label: 'Gelar yang diperoleh' },
      { value: get(data, ['school', 'thesis_title']), label: 'Judul Thesis' },
      { value: get(data, ['school', 'setneg_number']), label: 'No. Surat Ijin Setneg' },
      { value: get(data, ['school', 'study_number']), label: 'No. SK Tugas Belajar' }
    ]
  },
  {
    title: 'RENCANA BERANGKAT DI INDONESIA',
    list: [
      { value: get(data, ['departure_date']), label: 'Tanggal Berangkat Ke Indonesia' },
      { value: get(data, ['flight_number']), label: 'No. Penerbangan / Maskapai' }
    ]
  }
];

export { graduateDetails, transferScholldetails, pnsdetails };
