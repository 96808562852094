import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router-dom';
import Button from '../../../../components/Button';
import TextInputNoob from '../../../../components/input/TextInputNoob';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUser } from '../action';
import './style.scss';

const EditUser = ({ history, updateUser, updateUserProgress }) => {
  const match = useRouteMatch();
  const { id } = match.params;
  const listUser = history.location.state;
  let detailUser;
  listUser.forEach((user) => {
    if (user.user_id === id) {
      detailUser = user;
    }
  });

  const [email, setEmailValue] = useState(detailUser.email);
  const [username, setUsername] = useState(detailUser.username);
  const [nama_lengkap, setNamaLengkap] = useState(detailUser.name);
  const [role, setRoleValue] = useState(detailUser.group._id);
  const [status, setStatusValue] = useState('1');
  const [old_password, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [passwordLengthFlag, setPasswordLengthFlag] = useState(false);
  const [passwordFlagDiff, setPasswordFlagDiff] = useState(false);
  const [passwordFlagEmpty, setPasswordFlagEmpty] = useState(false);
  const [usernameFlag, setUsernameFlag] = useState(false);

  const defaultForm = {
    email: email,
    username: username,
    name: nama_lengkap,
    group: role,
    newPassword: password,
    oldPassword: old_password,
    division: 'KJRI Admin',
    confirmNewPassword: passwordConfirm
  };

  const onChangeUsername = (value) => {
    setUsername(value);
    if (username.length <= 5) {
      setUsernameFlag(true);
    } else {
      setUsernameFlag(false);
    }
  };

  const checkOldPassword = () => {
    if (old_password === password) {
      setPasswordFlag(true);
    } else {
      setPasswordFlag(false);
    }
  };

  const checkSamePassword = () => {
    if (passwordConfirm === password) {
      setPasswordFlagDiff(false);
    } else {
      setPasswordFlagDiff(true);
    }
  };

  const checkPassLength = () => {
    if (passwordConfirm.length < 8 || password.length < 8) {
      setPasswordLengthFlag(true);
    } else {
      setPasswordLengthFlag(false);
    }
  };

  const submitUser = () => {
    if (password === '' && passwordConfirm === '' && old_password === '') {
      setPasswordFlagEmpty(false);
      updateUser(id, defaultForm);
    } else {
      setPasswordFlagEmpty(true);
      checkOldPassword();
      checkPassLength();
      checkSamePassword();
      if (
        passwordFlagEmpty === true &&
        passwordFlag === false &&
        passwordFlagDiff === false &&
        passwordLengthFlag === false
      ) {
        updateUser(id, defaultForm);
      }
    }
  };

  if (updateUserProgress.status === 'resolve') {
    window.location = '/admin/users';
  }

  return (
    <div className='create-user'>
      <span className='back-homepage' onClick={() => history.push('/admin/users')}>
        <span className='btn-back' />
        EDIT USER
      </span>
      <div className='content'>
        <span style={{ height: 10, display: 'inline-block' }} />
        <div className='top-row'>
          <div className='top-row-container'>
            <TextInputNoob
              label='Email *'
              value={email}
              action={setEmailValue}
              placeholder='Masukkan Email'
            />
          </div>
          <div className='top-row-container'>
            <label>Role *</label>
            <select
              name='role'
              id='role'
              value={role}
              onChange={(e) => setRoleValue(e.target.value)}
            >
              <option value='605f02e091c1fb000a4389a7'>Admin</option>
              <option value='605f02cc91c1fb000a4389a5'>Super Admin</option>
            </select>
          </div>
        </div>
        <div className='top-row'>
          <div className='top-row-container'>
            <TextInputNoob
              label='Nama Lengkap *'
              value={nama_lengkap}
              action={setNamaLengkap}
              placeholder='Masukkan Nama Lengkap'
            />
          </div>
          <div className='top-row-container'>
            <TextInputNoob
              label='Username *'
              value={username}
              action={onChangeUsername}
              placeholder='Masukkan Username'
            />
          </div>
        </div>
        <div className='top-row-container' style={{ display: `none` }}>
          <label>Status *</label>
          <select
            name='status'
            id='status'
            onChange={(e) => setStatusValue(e.target.value)}
          >
            <option value='1'>Active</option>
            <option value='2'>Non Active</option>
          </select>
        </div>
        <span style={{ height: 5, display: 'inline-block' }} />
        <label>Password Lama *</label>
        <input
          type='password'
          className='password'
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <span style={{ height: 5, display: 'inline-block' }} />
        <label>Password Baru *</label>
        <input
          type='password'
          className='password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <span style={{ height: 5, display: 'inline-block' }} />
        <label>Konfirmasi Password Baru *</label>
        <input
          type='password'
          className='password'
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        {passwordFlag === true ? (
          <div style={{ color: 'red' }}>* Password baru masih sama dengan yang lama</div>
        ) : (
          ''
        )}
        {passwordLengthFlag === true ? (
          <div style={{ color: 'red' }}>* Password kurang dari 8 karakter</div>
        ) : (
          ''
        )}
        {passwordFlagDiff === true ? (
          <div style={{ color: 'red' }}>
            * Password baru tidak sama dengan confirm password baru
          </div>
        ) : (
          ''
        )}
        {passwordFlagEmpty === true ? (
          <div style={{ color: 'red' }}>
            * Kosongkan input password jika tidak ingin ganti password
          </div>
        ) : (
          ''
        )}
        {usernameFlag === true ? (
          <div style={{ color: 'red' }}>* Username harus lebih dari 5 karakter</div>
        ) : (
          ''
        )}
        <span style={{ height: 5, display: 'inline-block' }} />
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => submitUser()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

EditUser.propTypes = {
  history: PropTypes.object,
  updateUserProgress: PropTypes.object,
  updateUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  updateUserProgress: {
    status: state.updateUserReducerAdmin.status,
    error: state.updateUserReducerAdmin.error,
    data: state.updateUserReducerAdmin.data
  }
});

export default compose(connect(mapStateToProps, { updateUser }), withRouter)(EditUser);
