const events = {
  data: {},
  status: 'idle',
  error: ''
};

const newEvent = {
  data: {},
  status: 'idle',
  error: ''
};

const editEvent = {
  data: {},
  status: 'idle',
  error: ''
};

const deleteEvent = {
  data: {},
  status: 'idle',
  error: ''
};

const detailEvent = {
  data: {},
  status: 'idle',
  error: ''
};

const eventsReducerAdmin = (state = events, action) => {
  switch (action.type) {
    case 'GET_EVENTS_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EVENTS_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EVENTS_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const newEventReducerAdmin = (state = newEvent, action) => {
  switch (action.type) {
    case 'POST_EVENT_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_EVENT_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_EVENT_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const editEventReducerAdmin = (state = editEvent, action) => {
  switch (action.type) {
    case 'EDIT_EVENT_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'EDIT_EVENT_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'EDIT_EVENT_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const deleteEventReducerAdmin = (state = deleteEvent, action) => {
  switch (action.type) {
    case 'DELETE_EVENT_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_EVENT_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_EVENT_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const detailEventReducerAdmin = (state = detailEvent, action) => {
  switch (action.type) {
    case 'GET_EVENT_DETAIL_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EVENT_DETAIL_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EVENT_DETAIL_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

export {
  eventsReducerAdmin,
  newEventReducerAdmin,
  deleteEventReducerAdmin,
  editEventReducerAdmin,
  detailEventReducerAdmin
};
