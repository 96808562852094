export default {
  expired_pass: 'Pembaruan Paspor Habis Masa Berlaku',
  broken_pass: 'Penggantian Paspor Rusak',
  expired_child: 'Pembaruan Paspor Habis Masa Berlaku bagi Anak yang lahir di AS',
  lost_pass: 'Penggantian Paspor Hilang',
  new_born_pass:
    'Pembuatan Paspor Pertama Kali bagi Anak yang Lahir di AS setelah 1 Agustus 2006',
  splp:
    'Penggantian Paspor Hilang Bagi Wni Turis & Surat Perjalanan Laksana Paspor (SPLP)'
};
