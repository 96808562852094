import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhotoUpload from '../../../../components/PhotoUpload';
import TextInputNoob from '../../../../components/input/TextInputNoob';
import TextAreaEditor from '../../../../components/TextAreaEditor';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { objectHasValue } from '../../../../helpers/helpers';
import moment from 'moment';
import { postEvent } from '../action';
import Button from '../../../../components/Button';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './style.scss';

const CreateEvents = ({ history, uploadPhoto, uploadEvent, postEvent }) => {
  const [textValue, setTextValue] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [price, setPrice] = useState('');
  const [contact, setContact] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState('');
  const [canRegister, setCanRegister] = useState(true);
  const defaultForm = {
    title: titleValue,
    image_url: objectHasValue(uploadPhoto.data) ? uploadPhoto.data.data.url : '',
    excerpt: shortDesc,
    description: textValue,
    currency: 'USD',
    admission_fee: price,
    datetime: moment(startDate._d).format('YYYY-MM-DDTHH:mm:ssZ'),
    address: location,
    place: location,
    can_register: canRegister == 'true' ? true : false,
    location: '40.6971494,-74.25987',
    phone: contact,
    contact: {
      address: location,
      phone: contact
    }
  };

  const submitEvent = () => {
    postEvent(defaultForm);
  };

  if (uploadEvent.status === 'resolve') {
    window.location.reload();
  }

  return (
    <div className='create-events'>
      <div className='header'>TAMBAH ACARA/PAMERAN</div>
      <div className='content'>
        <PhotoUpload />
        <span style={{ height: 10, display: 'inline-block' }} />
        <span style={{ height: 5, display: 'inline-block' }} />
        <TextInputNoob
          label='Title *'
          action={setTitleValue}
          placeholder='Masukkan judul kegiatan/pameran'
        />
        <TextInputNoob
          label='Deskripsi singkat tentang kegiatan *'
          action={setShortDesc}
          placeholder='Masukkan deskripsi'
        />
        <div className='div-2 div-wrap'>
          <div className='div-2 div-wrap-1'>
            <TextInputNoob
              label='Price * (Isikan 0 apabila gratis)'
              action={setPrice}
              placeholder='$ 0'
            />
            <TextInputNoob
              label='Contact *'
              action={setContact}
              placeholder='ex: +1 (232) 8288228'
            />
          </div>
          <div className='div-wrap-2'>
            <TextInputNoob
              label='Location *'
              action={setLocation}
              placeholder='ex: New York'
            />
          </div>
        </div>
        <div className='div-2'>
          <div className='div-wrap-2 date-input' style={{ paddingRight: 30 }}>
            <div className='label'>Start Date *</div>
            {/* <input
              type='datetime-local'
              className='start'
              id='start'
              name='start'
              onChange={(e) => setStartDate(e.target.value)}
            /> */}
            <Datetime onChange={(e) => setStartDate(e)} />
          </div>
          <div className='div-wrap-2 date-input' style={{ width: 300 }}>
            <div className='label'>User Can Register *</div>
            <select name='can_register' onChange={(e) => setCanRegister(e.target.value)}>
              <option value='true'>Yes</option>
              <option value='false'>No</option>
            </select>
          </div>
        </div>
        <span style={{ height: 5, display: 'inline-block' }} />
        <TextAreaEditor label='Deskripsi *' action={setTextValue} />
        <span style={{ height: 5, display: 'inline-block' }} />
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => submitEvent()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

CreateEvents.propTypes = {
  history: PropTypes.object,
  uploadPhoto: PropTypes.object,
  uploadEvent: PropTypes.object,
  postEvent: PropTypes.func
};

const mapStateToProps = (state) => ({
  uploadPhoto: {
    status: state.uploadPhotoGeneral.status,
    error: state.uploadPhotoGeneral.error,
    data: state.uploadPhotoGeneral.data
  },
  uploadEvent: {
    status: state.newEventReducerAdmin.status
  }
});

export default compose(connect(mapStateToProps, { postEvent }), withRouter)(CreateEvents);
