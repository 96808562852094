import get from 'lodash/get';
import moment from 'moment';
import passportService from '../../../constant/passportService';
import { passport_service } from '../../pages/Paspor/Schedule/functions';

export const setParams = (form) => {
  const { personalInfo, passportVisa, address, work, parent, emergencyContact } = form;
  const formatDate = 'YYYY-MM-DD';
  const params = {
    unique_number: get(form, ['applicationId']),
    biography: {
      passport_service: get(form, ['serviceId']),
      name: get(personalInfo, ['name']),
      birth_city: get(personalInfo, ['birth_city']),
      birth_date: moment(get(personalInfo, ['birth_date'])).format(formatDate),
      gender: get(personalInfo, ['gender']),
      religion: get(personalInfo, ['religion']),
      height: get(personalInfo, ['height'])
    },
    marriage: {
      marital_status: get(personalInfo, ['marital_status']),
      spouse: {
        name: get(personalInfo, ['spouse', 'name']),
        birth_city: get(personalInfo, ['spouse', 'birth_city']),
        birth_date: moment(get(personalInfo, ['spouse', 'birth_date'])).format(
          formatDate
        ),
        nationality: get(personalInfo, ['spouse', 'nationality', 'name'])
      }
    },
    passport: {
      type: get(passportVisa, ['passport_type', 'value']),
      issuing_city: get(passportVisa, ['issuing_city']),
      issuing_date: moment(get(passportVisa, ['issuing_date'])).format(formatDate),
      expired_date: moment(get(passportVisa, ['expired_date'])).format(formatDate)
    },
    visa: {
      type: get(passportVisa, ['visa_type']),
      visa_number: get(passportVisa, ['number_visa']),
      visa_expiry_at: moment(get(passportVisa, ['expired_date_visa'])).format(formatDate),
      permit_stay_type: get(passportVisa, ['type_stay_america']),
      permit_stay_number: get(passportVisa, ['number_stay']),
      permit_stay_expiry_at: moment(get(passportVisa, ['expired_date_stay'])).format(
        formatDate
      )
    },
    address: {
      address: get(address, ['address']),
      state: get(address, ['state', 'label']),
      zip_code: get(address, ['zip_code']),
      home_phone: get(address, ['home_phone']),
      phone: get(address, ['phone']),
      email: get(address, ['email']),
      city: get(address, ['city'])
    },
    work: {
      title: get(work, ['title']),
      company_name: get(work, ['company_name']) || '-',
      company_address: get(work, ['company_address']) || '-',
      state: get(work, ['state', 'label']),
      zip_code: get(work, ['zip_code']),
      company_phone: get(work, ['company_phone']),
      city: get(work, ['city'])
    },
    parent: {
      father_name: get(parent, ['father_name']),
      father_birth_date: moment(get(parent, ['father_birth_date'])).format(formatDate),
      father_birth_city: get(parent, ['father_birth_city']),
      father_nationality: get(parent, ['father_nationality', 'name']),
      mother_name: get(parent, ['mother_name']),
      mother_birth_city: get(parent, ['mother_birth_city']),
      mother_birth_date: moment(get(parent, ['mother_birth_date'])).format(formatDate),
      mother_nationality: get(parent, ['mother_nationality', 'name'])
    },
    us_emergency: {
      name: get(emergencyContact, ['us_name']),
      address: get(emergencyContact, ['us_address']),
      city: get(emergencyContact, ['us_city']),
      state: get(emergencyContact, ['us_state', 'label']),
      zip_code: get(emergencyContact, ['us_zip_code']),
      phone: get(emergencyContact, ['us_phone']),
      relation: get(emergencyContact, ['us_relation'])
    },
    id_emergency: {
      name: get(emergencyContact, ['id_name']),
      address: get(emergencyContact, ['id_address']),
      city: get(emergencyContact, ['id_city']),
      province: get(emergencyContact, ['id_state', 'name']),
      postal_code: get(emergencyContact, ['id_zip_code']),
      phone: get(emergencyContact, ['id_phone']),
      relation: get(emergencyContact, ['id_relation'])
    }
  };

  return params;
};

export const setParamsDoc = (form) => {
  const { documents } = form;
  const tmpDocuments = {};

  Object.keys(documents).map((docId) => {
    tmpDocuments[docId] = documents[docId].url;
  });

  return tmpDocuments;
};

export const getPassportServiceTitle = (list, passId) => {
  const passportServiceSelected = list.find(({ id }) => id === passId);

  const findKeyTitle = Object.keys(passportService).find(
    (key) => passportService[key] === get(passportServiceSelected, ['title'])
  );

  return findKeyTitle;
};

export const getServiceTitleByName = (name) => {
  const findKeyTitle = Object.keys(passportService).find(
    (key) => passportService[key] === name
  );

  if (findKeyTitle) {
    return `paspor_page.${passport_service[findKeyTitle].title}`;
  }
};
