import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

const GeneralInfo = ({ i18n }) => {
  const lang = (type, params) => i18n(`economy.tab1.${type}`, { ...params });

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />
        </div>
      </main>
    </div>
  );
};

GeneralInfo.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps))(GeneralInfo);
