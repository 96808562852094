import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './style.scss';
import placeIcon from '../../../assets/img/homepage/place_24px.svg';
import time from '../../../assets/img/homepage/event_24px.svg';

const ActivityThumbnail = (props) => {
  const { source, data } = props;
  const { image_url, title, datetime, _id, place } = data || {};

  return (
    <Col md='3'>
      <div className={`activity-thumbnail ${source === 'event-list' ? 'adjust' : ''}`}>
        <Link to={`/event/detail/${_id}`}>
          <div
            className='activity-thumbnail-img'
            style={{ backgroundImage: `url(${image_url})` }}
          />
        </Link>
        <div className='activity-thumbnail-detail'>
          <Link to={`/event/detail/${_id}`}>
            <div className='activity-thumbnail-title'>{title}</div>
          </Link>
          <div className='activity-thumbnail-info'>
            <div className='activity-thumbnail-place'>
              <img
                src={placeIcon}
                alt='activity-thumbnail-place'
                style={{ marginRight: 5 }}
              />
              {place}
            </div>
            <div className='activity-thumbnail-time'>
              <img src={time} alt='activity-thumbnail-place' style={{ marginRight: 5 }} />
              {moment(datetime).utcOffset(datetime).format('LLL')}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

ActivityThumbnail.propTypes = {
  source: PropTypes.string,
  data: PropTypes.object
};

export default ActivityThumbnail;
