import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InstructionWrap from '../../../../components/InstructionWrap';

const Medicine = ({ i18n }) => {
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('faq.tab1.title')}</div>
      <main>
        <InstructionWrap
          instruction={i18n('faq.tab1.content1')}
          instructionList={i18n('faq.instruction_step.medicine')}
          type='bullet'
        />
        <InstructionWrap instruction={i18n('faq.tab1.content2')} />
      </main>
    </div>
  );
};

Medicine.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Medicine);
