import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import DashboardTable from '../../../components/DashboardTable';
import Button from '../../../components/Button';
import DatepickerRange from '../components/DatepickerRange';
import MiniCard from '../../../components/CardDetail/MiniCard';

import {
  resetDiasporaList,
  getDiasporaList,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
} from './action';
import { getStatisticDate, downloadFile } from '../functions';

import './style.scss';

const Diaspora = ({
  history,
  diasporaRegisterList,
  getDiasporaList,
  resetDiasporaList,
  statistic,
  statisticWeek,
  statisticMonth,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
}) => {
  const title = 'Diaspora';

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();
  const statusTabList = {
    '1': 'REGISTERED'
  };

  const diasporaListData = get(diasporaRegisterList, ['data', 'data']) || [];
  const pagination = get(diasporaRegisterList, ['data', 'pagination']) || {};

  const columns = [
    { dataField: 'id', text: 'No' },
    { dataField: 'name', text: 'Full Name' },
    { dataField: 'organization', text: 'Organization' },
    { dataField: 'phone', text: 'Phone' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'created_at', text: 'Date' },
    { dataField: 'unique_number', text: 'Action' }
  ];

  const action = [
    {
      onClick: (id, data) => history.push(`/admin/diaspora/detail/${data.unique_number}`),
      type: 'view'
    }
  ];

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0)
      }
    ];
    const dayStatistic = get(statistic, ['data']) || [];
    const weekStatistic = get(statisticWeek, ['data']) || [];
    const monthStatistic = get(statisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ status, total }) => {
        switch (status) {
          case statusTabList['1']:
            summaryData[index].numOfIn = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const details = [
    {
      title: '',
      list: getSummaryData()
    }
  ];

  const loadData = () => {
    const params = {
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getDiasporaList(params);
  };

  const loadStatisticData = () => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();
    getStatistic({
      from: today,
      to: today
    });
    getStatisticWeek({
      from: startWeek,
      to: endWeek
    });
    getStatisticMonth({
      from: startMonth,
      to: endMonth
    });
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };
    const fileName = `diaspora-${params.from}-${params.to}`;
    downloadFile('diaspora', params, fileName);
  };

  useEffect(() => {
    loadData();
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetDiasporaList();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDivider, page, query]);

  const renderDetails = () =>
    details.map(({ title, list }, idx) => (
      <div className='detail-container' key={idx}>
        <div className='title'>{title}</div>
        <div className='detail-wrap'>
          {list.map((detail, idx2) => (
            <MiniCard detail={detail} key={idx2} />
          ))}
        </div>
      </div>
    ));

  return (
    <div className='darmasiswa'>
      <div className='darmasiswa-top'>
        <h1>Diaspora Indonesia</h1>
        <div className='darmasiswa-action'>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={loadData}
          />
          <Button color='info' onClick={handlerDownload}>
            Download
          </Button>
        </div>
      </div>
      {renderDetails()}
      <DashboardTable
        columns={columns}
        action={action}
        search='name'
        idKeyField='id'
        onPageChanges={setPage}
        onPageDividerChanges={(divider) => {
          setPageDivider(divider);
          setQuery();
          setPage(1);
        }}
        onQueryChanges={(q) => {
          setPage(1);
          setQuery(q);
        }}
        data={diasporaListData}
        pagination={pagination}
      />
    </div>
  );
};

Diaspora.propTypes = {
  history: PropTypes.object,
  diasporaRegisterList: PropTypes.object,
  getDiasporaList: PropTypes.func,
  resetDiasporaList: PropTypes.func,
  statistic: PropTypes.object,
  statisticWeek: PropTypes.object,
  statisticMonth: PropTypes.object,
  getStatistic: PropTypes.func,
  getStatisticWeek: PropTypes.func,
  getStatisticMonth: PropTypes.func
};

const mapStateToProps = (state) => ({
  diasporaRegisterList: {
    status: state.getDiasporaListReducer.status,
    error: state.getDiasporaListReducer.error,
    data: state.getDiasporaListReducer.data
  },
  statistic: {
    status: state.getDiasporaStatisticReducer.status,
    error: state.getDiasporaStatisticReducer.error,
    data: state.getDiasporaStatisticReducer.data
  },
  statisticWeek: {
    status: state.getDiasporaStatisticWeekReducer.status,
    error: state.getDiasporaStatisticWeekReducer.error,
    data: state.getDiasporaStatisticWeekReducer.data
  },
  statisticMonth: {
    status: state.getDiasporaStatisticMonthReducer.status,
    error: state.getDiasporaStatisticMonthReducer.error,
    data: state.getDiasporaStatisticMonthReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getDiasporaList,
    resetDiasporaList,
    getStatistic,
    getStatisticWeek,
    getStatisticMonth
  }),
  withRouter
)(Diaspora);
