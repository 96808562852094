import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Tabbing from '../../../../../components/Tabbing';
import DashboardTable from '../../../../../components/DashboardTable';
import MiniCard from '../../../../../components/CardDetail/MiniCard';
import Modal from '../../../../../components/Modal';
import Loading from '../../../../../components/Loading';

import { typeModalList } from '../../../functions';
import { patchApprove, patchPending, patchProcess } from '../../action';

import './style.scss';

const MainContent = ({
  history,
  title,
  dataTab,
  summaryData = [],
  type,
  i18n,
  loading,
  onTabChanges,
  onPageChanges,
  onPageDividerChanges,
  onQueryChanges,
  defaultActiveTab,
  pagination,
  patchApprove,
  patchPending,
  patchProcess,
  approve,
  pending,
  process,
  loadData,
  allStatistic,
  topActionComponent
}) => {
  const [showModal, setShowModal] = useState(false);
  const [actionData, setActionData] = useState();
  const [typeModal, setTypeModal] = useState();
  const [reason, setReason] = useState();
  const [selectedData, setSelectedData] = useState({});
  const [loadingAction, setLoadingAction] = useState(false);

  const { numOfIn, numOfProcess, numOfFinish, numOfPending } = allStatistic || {};

  // action
  const { status: statusApprove, data: dataApprove, error: errorApprove } = approve;
  const { status: statuspending, data: datapending, error: errorpending } = pending;
  const { status: statusProcess, data: dataProcess, error: errorProcess } = process;

  const navs = [
    `Masuk (${numOfIn})`,
    `Proses (${numOfProcess})`,
    `Selesai (${numOfFinish})`,
    `Tunda (${numOfPending})`
  ];

  const columns = [
    { dataField: 'id', text: 'No' },
    { dataField: 'created_at', text: 'Datetime' },
    { dataField: 'name', text: 'Title' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'phone', text: 'Phone' },
    { dataField: 'status', text: 'Status' },
    { dataField: 'id', text: 'Action' }
  ];

  const handlerAction = (type, data) => {
    if (showModal) {
      setShowModal(false);
      setTypeModal();
      setSelectedData({});
    } else {
      setShowModal(true);
      setTypeModal(type);
      setSelectedData(data);
    }
  };

  const handlerSubmitAction = () => {
    const { id } = selectedData;
    if (typeModal === 'process') {
      patchProcess(id);
    } else if (typeModal === 'tunda') {
      patchPending(id, {
        note: reason
      });
    } else if (typeModal === 'finish') {
      patchApprove(id);
    }

    setShowModal(false);
    setLoadingAction(true);
  };

  const actionList = {
    view: {
      onClick: (id) => history.push(`/admin/visa/${type}/detail/${id}`),
      type: 'view',
      name: 'Tourist Visa'
    },
    check: { onClick: (id, data) => handlerAction('process', data), type: 'check' },
    pending: { onClick: (id, data) => handlerAction('tunda', data), type: 'delete' }
  };

  const action1 = [actionList.view, actionList.check, actionList.pending];
  const action2 = [
    actionList.view,
    { onClick: (id, data) => handlerAction('finish', data), type: 'check' }
  ];
  const action3 = [actionList.view];

  const propsTableTab = {
    columns,
    search: 'name',
    idKeyField: 'id',
    onPageChanges,
    onPageDividerChanges,
    onQueryChanges,
    data: dataTab,
    pagination
  };

  const table = [
    <DashboardTable key='1' action={action1} {...propsTableTab} />,
    <DashboardTable key='2' action={action2} {...propsTableTab} />,
    <DashboardTable key='3' action={action3} {...propsTableTab} />,
    <DashboardTable key='4' action={action3} {...propsTableTab} />
  ];

  const details = [
    {
      title,
      list: summaryData
    }
  ];

  const detailModal = () => {
    const onChange = (e) => setReason(e.target.value);
    const name = get(selectedData, ['name']);

    return typeModalList[typeModal]({ name, reason, onChange, actionData });
  };

  const modalProps = typeModal ? detailModal() : {};

  const renderDetails = () =>
    details.map(({ title, list }, idx) => (
      <div className='detail-container' key={idx}>
        <div
          className='detail-top'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h1 className='title'>{title}</h1>
          {topActionComponent}
        </div>
        <div className='detail-wrap'>
          {list.map((detail, idx2) => (
            <MiniCard detail={detail} key={idx2} />
          ))}
        </div>
      </div>
    ));

  useEffect(() => {
    if (loadingAction) {
      let data = {};
      let error = {};
      let status = 'idle';

      if (typeModal === 'process') {
        data = dataProcess;
        error = errorProcess;
        status = statusProcess;
      } else if (typeModal === 'tunda') {
        data = datapending;
        error = errorpending;
        status = statuspending;
      } else if (typeModal === 'finish') {
        data = dataApprove;
        error = errorApprove;
        status = statusApprove;
      }

      if (status === 'resolve') {
        console.log(dataProcess, datapending, dataApprove, 'DATA');

        const messageError = get(error, ['response', 'data', 'error', 'message']);
        const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
        const messageDetail = get(error, [
          'response',
          'data',
          'error',
          'detail',
          0,
          'message'
        ]);

        setLoadingAction(false);

        const { status, message } = data;
        if (status) {
          // show success message
          handlerAction('successSave');
          loadData();
        } else {
          const messageErrorFinal =
            message !== '' ? message : `${messageError}. ${keyDetail}: ${messageDetail}.`;
          handlerAction('failedSave');
          setActionData(messageErrorFinal);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAction, statusApprove, statuspending, statusProcess]);

  return (
    <div className='admin-visa'>
      {(loading || loadingAction) && <Loading />}
      {renderDetails()}
      <Tabbing
        navs={navs}
        table={table}
        onChange={onTabChanges}
        defaultActiveTab={defaultActiveTab}
      />

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className={get(modalProps, ['className']) || 'confirm'}
        buttonProps={[
          {
            btnStyle: {
              color: get(modalProps, ['cta', 'no', 'color']) || 'info',
              outline: get(modalProps, ['cta', 'no', 'outline']) || true
            },
            label: get(modalProps, ['cta', 'no', 'text']),
            onClick: () => {
              handlerAction();
              setShowModal(false);
            }
          },
          ...[
            get(modalProps, ['cta', 'yes'])
              ? {
                  btnStyle: { color: get(modalProps, ['cta', 'yes', 'color']) },
                  label: get(modalProps, ['cta', 'yes', 'text']),
                  onClick: handlerSubmitAction
                }
              : {}
          ]
        ]}
        title={get(modalProps, ['title'])}
        desc={get(modalProps, ['desc'])}
      />
    </div>
  );
};

MainContent.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  approve: {
    status: state.patchVisaApproveReducer.status,
    error: state.patchVisaApproveReducer.error,
    data: state.patchVisaApproveReducer.data
  },
  pending: {
    status: state.patchVisaPendingReducer.status,
    error: state.patchVisaPendingReducer.error,
    data: state.patchVisaPendingReducer.data
  },
  process: {
    status: state.patchVisaProcessReducer.status,
    error: state.patchVisaProcessReducer.error,
    data: state.patchVisaProcessReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    patchApprove,
    patchPending,
    patchProcess
  }),
  withRouter
)(MainContent);
