import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import Banner from '../../../../components/Banner';
import './style.scss';

const TermCondition = ({ history, i18n }) => {
  return (
    <>
      <Banner
        title={i18n('passport_tnc.banner.title')}
        backButtonClick={() => history.goBack()}
      />
      <Container style={{ marginTop: 26 }}>
        <div className='tnc-container'>
          <div>{i18n('passport_tnc.intro')}</div>
          <div className='mtop16'>
            <h5 className='mtop16'>{i18n('passport_tnc.general.title')}</h5>
            <ul>
              <li>{i18n('passport_tnc.general.desc1')}</li>
              <li>{i18n('passport_tnc.general.desc2')}</li>
              <li>{i18n('passport_tnc.general.desc3')}</li>
            </ul>
          </div>
          <div className='mtop16'>
            <h5 className='mtop16'>{i18n('passport_tnc.agree_with.title')}</h5>
            <ul>
              <li>{i18n('passport_tnc.agree_with.desc1')}</li>
              <li>{i18n('passport_tnc.agree_with.desc2')}</li>
              <li>{i18n('passport_tnc.agree_with.desc3')}</li>
            </ul>
          </div>
        </div>
      </Container>
    </>
  );
};

TermCondition.propTypes = {
  i18n: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps, withRouter))(TermCondition);
