import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

const Tabbing = ({ history, navs, table, onChange, defaultActiveTab = '1' }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const navigation = navs.map((nav, index) => (
    <NavItem key={index + 1}>
      <NavLink
        className={classnames({ active: activeTab === `${index + 1}` })}
        onClick={() => {
          const nextActiveTab = `${index + 1}`;
          toggle(nextActiveTab);
          onChange && onChange(nextActiveTab);
        }}
      >
        {nav}
      </NavLink>
    </NavItem>
  ));

  const content = table.map((tbl, index) => (
    <TabPane key={index + 1} tabId={`${index + 1}`}>
      <Row>
        <Col sm='12'>{tbl}</Col>
      </Row>
    </TabPane>
  ));

  return (
    <div className='dashboard-tabbing'>
      <Nav tabs>{navigation}</Nav>
      <TabContent activeTab={activeTab}>{content}</TabContent>
    </div>
  );
};

Tabbing.propTypes = {
  history: PropTypes.object,
  navs: PropTypes.array,
  table: PropTypes.any
};

export default withRouter(Tabbing);
