import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../Button';

import './style.scss';

const CardInstruction = ({ history, stepOuterList, lang, instructionList }) => {
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        {stepOuterList.map(
          (
            { label, cta, link, iconCta, linkType, ctaColor = 'primary', linkCta },
            idx
          ) => (
            <div className='step-item' key={idx}>
              <div className='text' dangerouslySetInnerHTML={{ __html: label }} />
              {cta && (
                <Button color={ctaColor} onClick={() => !linkType && history.push(link)}>
                  {iconCta && <span className={`icon-cta ${iconCta}`} />}
                  {linkType ? (
                    <a href={link} target='_blank' rel='noopener noreferrer'>
                      {cta}
                    </a>
                  ) : (
                    cta
                  )}
                </Button>
              )}
              {linkCta && linkCta}
            </div>
          )
        )}

        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />
          <ol>
            {instructionList.map((list, idx) => (
              <li key={idx}>
                {typeof list === 'string' && (
                  <div dangerouslySetInnerHTML={{ __html: list }} />
                )}
                {typeof list === 'object' && (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: list.text }} />
                    {list.detail}
                  </>
                )}
              </li>
            ))}
          </ol>
        </div>
      </main>
    </div>
  );
};

CardInstruction.propTypes = {
  stepOuterList: PropTypes.array,
  lang: PropTypes.func,
  instructionList: PropTypes.array,
  history: PropTypes.object
};

export default withRouter(CardInstruction);
