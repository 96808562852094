export const optionsList = (i18n) => {
  const genderOptions = [
    { value: 'male', name: i18n('form.gender.male') },
    { value: 'female', name: i18n('form.gender.female') }
  ];

  const passport_type_options = [
    { value: 'biasa', name: i18n('pensosbud.graduate_form.form.passport_type.regular') },
    { value: 'dinas', name: i18n('pensosbud.graduate_form.form.passport_type.dinas') },
    {
      value: 'diplmatik',
      name: i18n('pensosbud.graduate_form.form.passport_type.diplomatic')
    }
  ];

  return {
    genderOptions,
    passport_type_options
  };
};

export const tradeTitleType = {
  allegra: 'tab13',
  'new-york-now': 'tab10',
  sena: 'tab11',
  'summer-food': 'tab12',
  other: 'tab19'
};
