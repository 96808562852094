import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import Content from '../components/SingleContent';
import Button from '../../../../components/Button';
import DatepickerRange from '../../components/DatepickerRange';

import {
  getExhibitorList,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
} from './action';
import { getStatisticDate, downloadFile } from '../../functions';

const PameranBudayaPariwisata = ({
  getExhibitorList,
  exhibitorList,
  statistic,
  statisticWeek,
  statisticMonth,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
}) => {
  const title = 'EKONOMI - Pameran Budaya dan Pariwisata';

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState();
  const [pageDivider, setPageDivider] = useState(5);
  const statusTabList = {
    '1': 'REGISTERED',
    '2': 'PROCESS',
    '3': 'APPROVED',
    '4': 'PENDING'
  };

  const activeData = exhibitorList;
  const listData = get(activeData, ['data', 'data', 'data']) || [];
  const pagination = get(activeData, ['data', 'pagination']) || [];
  const loading = get(activeData, ['status']) === 'pending';

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0)
      }
    ];
    const dayStatistic = get(statistic, ['data']) || [];
    const weekStatistic = get(statisticWeek, ['data']) || [];
    const monthStatistic = get(statisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ type, total }) => {
        switch (type) {
          case 'CULTURE':
            summaryData[index].numOfIn = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const summaryData = getSummaryData();

  const loadData = (type) => {
    const params = {
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getExhibitorList({
      ...params,
      type: 'CULTURE'
    });
  };

  const loadStatisticData = () => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();
    getStatistic({
      from: today,
      to: today
    });
    getStatisticWeek({
      from: startWeek,
      to: endWeek
    });
    getStatisticMonth({
      from: startMonth,
      to: endMonth
    });
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      type: 'CULTURE'
    };
    const fileName = `${title}-${params.from}-${params.to}`;
    downloadFile('exhibitors', params, fileName);
  };

  useEffect(() => {
    loadData();
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDivider, page, query]);

  return (
    <div className='admin-visa'>
      <Content
        title={title}
        dataTab={listData}
        summaryData={summaryData}
        onPageChanges={setPage}
        onQueryChanges={setQuery}
        onPageDividerChanges={setPageDivider}
        pagination={pagination}
        loading={loading}
        type='ekopameran'
        topActionComponent={
          <div className='detail-action' style={{ display: 'flex' }}>
            <DatepickerRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onCalendarClose={loadData}
            />
            <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
              Download
            </Button>
          </div>
        }
      />
    </div>
  );
};

PameranBudayaPariwisata.propTypes = {
  exhibitorList: PropTypes.object,
  getExhibitorList: PropTypes.func,
  statistic: PropTypes.object,
  statisticWeek: PropTypes.object,
  statisticMonth: PropTypes.object,
  getStatistic: PropTypes.func,
  getStatisticWeek: PropTypes.func,
  getStatisticMonth: PropTypes.func
};

const mapStateToProps = (state) => ({
  exhibitorList: {
    status: state.getExhibitorListReducer.status,
    error: state.getExhibitorListReducer.error,
    data: state.getExhibitorListReducer.data
  },
  statistic: {
    status: state.getExhibitorStatisticReducer.status,
    error: state.getExhibitorStatisticReducer.error,
    data: state.getExhibitorStatisticReducer.data
  },
  statisticWeek: {
    status: state.getExhibitorStatisticWeekReducer.status,
    error: state.getExhibitorStatisticWeekReducer.error,
    data: state.getExhibitorStatisticWeekReducer.data
  },
  statisticMonth: {
    status: state.getExhibitorStatisticMonthReducer.status,
    error: state.getExhibitorStatisticMonthReducer.error,
    data: state.getExhibitorStatisticMonthReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getExhibitorList,
    getStatistic,
    getStatisticWeek,
    getStatisticMonth
  }),
  withRouter
)(PameranBudayaPariwisata);
