import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';

import { getSosBudServiceId } from '../../../form/PensosbudForm/functions';

const PNS_RPPK = ({ i18n, sosBudServiceList }) => {
  const lang = (type, params) => i18n(`pensosbud.tab4.${type}`, { ...params });
  const serviceId = getSosBudServiceId(sosBudServiceList, 'rppk');

  const stepOuterList = [
    {
      label: i18n('pensosbud.step.take_number2.title'),
      cta: i18n('pensosbud.step.take_number2.cta'),
      link: `/pensosbud/registration/create?type=rppk&serviceId=${serviceId}`
    }
  ];

  const instructionList = [
    i18n('pensosbud.instruction_step.first_passport_page'),
    i18n('pensosbud.instruction_step.skp'),
    i18n('pensosbud.instruction_step.transkrip'),
    i18n('pensosbud.instruction_step.annual_review'),
    i18n('pensosbud.instruction_step.surat_ijin_setkab'),
    i18n('pensosbud.instruction_step.sk_tugas'),
    i18n('pensosbud.instruction_step.laporan_hasil_belajar'),
    i18n('pensosbud.instruction_step.kjri_form'),
    i18n('pensosbud.instruction_step.send_all_doc'),
    i18n('pensosbud.instruction_step.min_3_days')
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

PNS_RPPK.propTypes = {
  i18n: PropTypes.func,
  sosBudServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(PNS_RPPK);
