import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhotoUpload from '../../../../components/PhotoUpload';
import TextInputNoob from '../../../../components/input/TextInputNoob';
import TextAreaEditor from '../../../../components/TextAreaEditor';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { objectHasValue } from '../../../../helpers/helpers';
import moment from 'moment';
import Button from '../../../../components/Button';
import { postNews } from '../action';
import './style.scss';

const CreateNews = ({ history, uploadPhoto, uploadNews, postNews }) => {
  const [textValue, setTextValue] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const defaultForm = {
    title: titleValue,
    image_url: objectHasValue(uploadPhoto.data) ? uploadPhoto.data.data.url : '',
    excerpt: '',
    content: textValue,
    datetime: moment().format('YYYY-MM-DDTHH:MM:DD'),
    user_id: ''
  };

  const submitNews = () => {
    postNews(defaultForm);
  };

  if (uploadNews.status === 'resolve') {
    window.location.reload();
  }

  return (
    <div className='create-news'>
      <div className='header'>TAMBAH BERITA & INFORMASI</div>
      <div className='content'>
        <PhotoUpload />
        <span style={{ height: 10, display: 'inline-block' }} />
        <span style={{ height: 5, display: 'inline-block' }} />
        <TextInputNoob
          label='Title *'
          action={setTitleValue}
          placeholder='Masukkan Judul Pengumuman'
        />
        <span style={{ height: 5, display: 'inline-block' }} />
        <TextAreaEditor label='Deskripsi *' action={setTextValue} />
        <span style={{ height: 5, display: 'inline-block' }} />
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => submitNews()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

CreateNews.propTypes = {
  history: PropTypes.object,
  uploadPhoto: PropTypes.object,
  uploadNews: PropTypes.object,
  postNews: PropTypes.func
};

const mapStateToProps = (state) => ({
  uploadPhoto: {
    status: state.uploadPhotoGeneral.status,
    error: state.uploadPhotoGeneral.error,
    data: state.uploadPhotoGeneral.data
  },
  uploadNews: {
    status: state.newNewsReducerAdmin.status
  }
});

export default compose(connect(mapStateToProps, { postNews }), withRouter)(CreateNews);
