import get from 'lodash/get';
import sosBudServiceKey from '../../../constant/sosBudService';

export const optionsList = (i18n) => {
  const genderOptions = [
    { value: 'M', name: i18n('form.gender.male') },
    { value: 'F', name: i18n('form.gender.female') }
  ];

  const passport_type_options = [
    {
      value: 'REGULAR',
      name: i18n('pensosbud.graduate_form.form.passport_type.regular')
    },
    { value: 'BUSINESS', name: i18n('pensosbud.graduate_form.form.passport_type.dinas') },
    {
      value: 'DIPLOMATIC',
      name: i18n('pensosbud.graduate_form.form.passport_type.diplomatic')
    }
  ];

  return {
    genderOptions,
    passport_type_options
  };
};

export const sosBudType = {
  listServe: {
    label: 'pensosbud.tab11.title',
    key: 'LIST SERVE'
  },
  alumniBSBI: {
    label: 'pensosbud.tab10.title',
    key: 'ALUMNI PROGRAM DARMASISWA DAN BEASISWA SENI BUDAYA INDONESIA (BSBI)'
  },
  gamelan: {
    label: 'pensosbud.tab7.title',
    key: 'WORKSHOP GAMELAN BALI'
  },
  bahasa: {
    label: 'pensosbud.tab6.title',
    key: 'KELAS BAHASA INDONESIA'
  },
  research: {
    label: 'pensosbud.tab5.title',
    key: 'IZIN PENELITIAN'
  },
  rppk: {
    label: 'pensosbud.tab4.title',
    key: 'PEMBUATAN RPPK PNS BAGI KARYASISWA',
    list: [
      'first_passport_page',
      'skp',
      'transkrip',
      'annual_review',
      'surat_ijin_setkab',
      'sk_tugas',
      'laporan_hasil_belajar',
      'kjri_form',
      'send_all_doc',
      'min_3_days'
    ]
  },
  pns: {
    label: 'pensosbud.tab3.title',
    key: 'SURAT AKHIR STUDI UNTUK PNS',
    formPath: '/pensosbud/last-study-pns/form',
    list: [
      'original_doc',
      'surat_keterangan',
      'passport',
      'transkrip',
      'thesis',
      'surat_ijin_setkab',
      'sk_tugas',
      'laporan_hasil_belajar',
      'kjri_form',
      'send_all_doc',
      'min_3_days'
    ]
  },
  graduate: {
    label: 'pensosbud.tab2.title',
    key: 'SURAT KETERANGAN LULUS SEKOLAH / UNIVERSITAS',
    formPath: '/pensosbud/graduate-school/form',
    list: [
      'kjri_form',
      'ijasah0',
      'surat_keterangan',
      'passport',
      'transkrip',
      'thesis',
      'send_all_doc',
      'min_3_days'
    ]
  },
  iacs: {
    label: 'pensosbud.tab1.title',
    key: 'Darmasiswa Program and Indonesian Arts and Culture Scholarship (IACS) Alumni '
  },
  transferSchool: {
    label: 'pensosbud.tab1.title',
    key: 'Surat Pindah Sekolah',
    formPath: '/pensosbud/move-school/form',
    list: [
      'surat_keterangan_pindah',
      'raport',
      'ijasah',
      'passport_ortu',
      'akte_lahir',
      'pernyataan_ortu',
      'selesai_tugas',
      'kjri_form',
      'send_all_doc',
      'min_3_days'
    ]
  }
};

export const getSosBudServiceId = (list, key) => {
  const sosBudServiceSelected = list.find(({ title }) => title === sosBudServiceKey[key]);

  return get(sosBudServiceSelected, ['_id']);
};

export const getServiceTitle = (list, serviceId) => {
  const serviceSelected = list.find(({ _id }) => _id === serviceId);

  const findKeyTitle = Object.keys(sosBudServiceKey).find(
    (key) => sosBudServiceKey[key] === get(serviceSelected, ['title'])
  );

  return findKeyTitle;
};

export const getServiceTitleByName = (name) => {
  const findKeyTitle = Object.keys(sosBudServiceKey).find(
    (key) => sosBudServiceKey[key] === name
  );
  if (findKeyTitle) {
    return sosBudType[findKeyTitle].label;
  }
};
