import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';
import { getPassportServiceId } from '../function';

const ExpiredPassporChild = ({ i18n, passportServiceList }) => {
  const passportServiceId = getPassportServiceId(passportServiceList, 'expired_child');
  const lang = (type, params) => i18n(`paspor_page.tab2.${type}`, { ...params });

  const stepOuterList = [
    {
      label: i18n('paspor_page.step.report_self.title')
    },
    {
      label: i18n('paspor_page.step.make_appoiment.title')
    },
    {
      label: i18n('paspor_page.step.create_form_register.title'),
      cta: i18n('paspor_page.step.create_form_register.cta'),
      link: `/passpor/schedule?type=expired_child&serviceId=${passportServiceId}`
    }
  ];

  const instructionList = [
    i18n('paspor_page.instruction_step.original_passpor'),
    i18n('paspor_page.instruction_step.copy_birth_certificate'),
    i18n('paspor_page.instruction_step.copy_parent_marriage_certificate'),
    i18n('paspor_page.instruction_step.copy_passpor_child_amerika'),
    i18n('paspor_page.instruction_step.copy_passport_parent'),
    i18n('paspor_page.instruction_step.permission_address_parent'),
    i18n('paspor_page.instruction_step.copy_birth_information_kjri'),
    i18n('paspor_page.instruction_step.usps_money_order', { price: 23 })
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

ExpiredPassporChild.propTypes = {
  i18n: PropTypes.func,
  passportServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(ExpiredPassporChild);
