import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Icon from '../../../Icon';
import menuList from './menu';

import './style.scss';

const SideBar = ({ history, i18n, toggleSidebar, setToggleSidebar, location }) => {
  const [openSubmenu, setopenSubmenu] = useState([]);
  const activeMenu = get(location, ['pathname']);

  const toggleSubmenu = (parentKey) => {
    const check = openSubmenu.find((openedName) => openedName === parentKey);
    let tmpopenSubmenu = [...openSubmenu];
    if (check) {
      tmpopenSubmenu = openSubmenu.filter((openedName) => openedName !== parentKey);
    } else {
      tmpopenSubmenu.push(parentKey);
    }

    setopenSubmenu(tmpopenSubmenu);
    if (toggleSidebar) {
      setToggleSidebar(false);
    }
  };

  const renderMenu = () =>
    menuList.map(({ parentLabel, menu, type, parentOf }, idx) => (
      <div className='menu-container ' key={idx}>
        {parentLabel && (
          <div className='submenu'>
            <div className='text'>{parentLabel}</div>
          </div>
        )}
        {menu.map(({ label, icon, path, parentPath, submenus = [] }, idx2) => (
          <div className='menu-wrap' key={idx2}>
            <div
              className={`parent-menu ${activeMenu.includes(path) ? 'active' : ''}`}
              onClick={() =>
                parentPath ? toggleSubmenu(parentPath) : history.push(path)
              }
            >
              <div className='icon-wrap'>
                <Icon icon={icon} />
              </div>
              <div className='menu-text'>{label}</div>
              {parentPath && (
                <Icon
                  icon={`right-pos chevron ${
                    openSubmenu.find((openMenu) => openMenu === parentPath)
                      ? 'up'
                      : 'down'
                  }`}
                />
              )}
            </div>
            {submenus.length > 0 &&
              openSubmenu.find((activeSubMenu) => activeSubMenu === parentPath) && (
              <div className='submenu-list'>
                {submenus.map((submenu, idx3) => (
                  <div
                    className={`submenu-wrap ${
                      activeMenu.includes(submenu.path) ? 'active' : ''
                    }`}
                    onClick={() => history.push(submenu.path)}
                    key={idx3}
                  >
                    <div className='icon-wrap'>
                      <Icon icon='minimize' />
                    </div>
                    <div className='menu-text'>{submenu.label}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    ));

  useEffect(() => {
    menuList.find(({ menu }) => {
      menu.find((item) => {
        if (activeMenu.includes(item.parentPath)) {
          const checkExist = openSubmenu.find(
            (activeSub) => activeSub === item.parentPath
          );
          if (!checkExist) {
            setopenSubmenu([...openSubmenu, item.parentPath]);
          }
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu]);

  return (
    <div className={`sidebar-container ${toggleSidebar ? 'collapsed' : ''}`}>
      <header>
        <div className='logo' />
        <Icon
          icon='hamburger'
          onClick={() => {
            setToggleSidebar(!toggleSidebar);
            setopenSubmenu([]);
          }}
        />
      </header>
      <div className='sidebar-list'>{renderMenu()}</div>
    </div>
  );
};

SideBar.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func
};

export default withRouter(SideBar);
