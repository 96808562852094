export default [
  'Wisata',
  'Pembicaraan Bisnis',
  'Ceramah atau Seminar',
  'Pameran Internasional',
  'Bergabung dengan Alat Angkut',
  'Keluarga',
  'Sosial',
  'Bidang Seni Budaya',
  'Kerjasama Teknik Pemerintahan',
  'Olahraga Non Komersial',
  'Studi Banding, Kursus dan Pelatihan Singkat',
  'Pembelian Barang',
  'Mengikuti Rapat',
  'Meneruskan Perjalanan',
  'Keadaan Darurat'
];
