import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './style.scss';
import { postHolidayAntrian } from '../action';
import moment from 'moment';

const AddHoliday = ({
  history,
  showModal,
  setShowModal,
  postHolidayAntrian,
  holidayAntrian
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [name, setName] = useState(0);
  const [interval, setInterval] = useState(0);
  const [multiplier, setMultiplier] = useState(0);

  if (holidayAntrian.status === 'resolve') {
    window.location.reload();
  }

  var today = new Date(startDate);
  var tomorrow = new Date(today);

  let data = {
    scheduled_at:
      startDate && moment(startDate).utcOffset(startDate).format('YYYY-MM-DD'),
    end_at:
      startDate && moment(tomorrow.setDate(today.getDate() + 1)).format('YYYY-MM-DD'),
    status: 'HOLIDAY',
    name: name,
    interval: parseInt(interval),
    multiplier: parseInt(multiplier)
  };

  const params = {
    force: 'true'
  };

  const sendHoliday = () => {
    postHolidayAntrian(data, params);
  };

  return (
    <div className={showModal ? 'add-holiday-container' : 'add-holiday-container hide'}>
      <div className='add-holiday-wrap'>
        <div className='add-holiday-top'>
          <h2>Tambah Hari Libur</h2>
          <span className='close' onClick={() => setShowModal(false)}>
            x
          </span>
        </div>
        <div className='add-holiday-bottom'>
          <div className='select-time'>
            <label>Tanggal Hari Libur *</label>
            <input
              type='date'
              id='tanggal-libur'
              name='tanggal-libur'
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className='time-range'>
            <label>Nama Hari Libur *</label>
            <input
              name='nama-hari-libur'
              placeholder='Contoh: Idul Fitri'
              id='nama-hari-libur'
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='time-range'>
            <label>Interval *</label>
            <input
              name='nama-hari-libur'
              placeholder='Contoh: 10'
              onChange={(e) => setInterval(e.target.value)}
            />
          </div>
          <div className='time-range'>
            <label>Multiply *</label>
            <input
              name='nama-hari-libur'
              placeholder='Contoh: 10'
              onChange={(e) => setMultiplier(e.target.value)}
            />
          </div>
          <div className='kuota' style={{ display: `none`}}>
            <label>Status *</label>
            <select name='staus' id='status'>
              <option value='1'>Aktif</option>
              <option value='2'>Non Aktif</option>
            </select>
          </div>
        </div>
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => sendHoliday()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

AddHoliday.propTypes = {
  history: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  holidayAntrian: PropTypes.object,
  postHolidayAntrian: PropTypes.func
};

const mapStateToProps = (state) => ({
  holidayAntrian: {
    status: state.holidayAntrianReducerAdmin.status,
    error: state.holidayAntrianReducerAdmin.error,
    data: state.holidayAntrianReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postHolidayAntrian
  }),
  withRouter
)(AddHoliday);
