import { apiCaller } from '../../../helpers/apiCaller';

// Get News list
const getNewsListRequest = () => ({
  type: 'GET_NEWS_LIST_REQUEST'
});

const getNewsListSuccess = (data) => ({
  type: 'GET_NEWS_LIST_SUCCESS',
  newsList: data
});

const getNewsListFailure = (error) => ({
  type: 'GET_NEWS_LIST_FAILURE',
  newsList: error
});

const getNewsList = () => {
  return (dispatch) => {
    dispatch(getNewsListRequest());
    return apiCaller('news')
      .then((data) => dispatch(getNewsListSuccess(data)))
      .catch((error) => dispatch(getNewsListFailure(error)));
  };
};

// Get News detail
const getNewsDetailRequest = () => ({
  type: 'GET_NEWS_DETAIL_REQUEST'
});

const getNewsDetailSuccess = (data) => ({
  type: 'GET_NEWS_DETAIL_SUCCESS',
  newsDetail: data
});

const getNewsDetailFailure = (error) => ({
  type: 'GET_NEWS_DETAIL_FAILURE',
  newsDetail: error
});

const getNewsDetail = (id) => {
  return (dispatch) => {
    dispatch(getNewsDetailRequest());
    return apiCaller(`news/${id}`)
      .then((data) => dispatch(getNewsDetailSuccess(data)))
      .catch((error) => dispatch(getNewsDetailFailure(error)));
  };
};

// Search News
const searchNewsListRequest = () => ({
  type: 'SEARCH_NEWS_LIST_REQUEST'
});

const searchNewsListSuccess = (data) => ({
  type: 'SEARCH_NEWS_LIST_SUCCESS',
  newsList: data
});

const searchNewsListFailure = (error) => ({
  type: 'SEARCH_NEWS_LIST_FAILURE',
  newsList: error
});

const searchNewsList = () => {
  return (dispatch) => {
    dispatch(searchNewsListRequest());
    return apiCaller('news')
      .then((data) => dispatch(searchNewsListSuccess(data)))
      .catch((error) => dispatch(searchNewsListFailure(error)));
  };
};

export { getNewsList, getNewsDetail, searchNewsList };
