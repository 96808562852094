import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Form from '../components/NumberRegister/Form';

import { getQuery } from '../../pages/Paspor/function';
import { postNumberRegister } from './action';
import { getAppointmentDetail } from '../../pages/Paspor/Schedule/action';

const FormPassport = ({
  history,
  i18n,
  numberRegister,
  postNumberRegister,
  getAppointmentDetail,
  appointmentDetail
}) => {
  const { data, error, status } = numberRegister;
  const { serviceId, appointmentId, type } = getQuery(history);

  const { data: appointmentDetailData } = appointmentDetail;
  const { passport_service_title, email } =
    get(appointmentDetailData, [0]) || appointmentDetailData || {};

  const end_date_appointment = get(appointmentDetailData, ['end_at']);
  let errorSaveMessage = '';

  if (status === 'rejected') {
    const message = get(error, ['response', 'data', 'error', 'message']);
    const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
    const messageDetail = get(error, [
      'response',
      'data',
      'error',
      'detail',
      0,
      'message'
    ]);
    errorSaveMessage = `${message}. ${keyDetail}: ${messageDetail}.`;
  }

  const handleSubmit = (params) => {
    postNumberRegister({
      ...params,
      passport_service: serviceId,
      appointment_id: appointmentId
    });
  };

  useEffect(() => {
    if (status === 'resolve') {
      const { unique_number } = get(data, [0]) || {};
      history.push(`/registration/number-detail?uniqueId=${unique_number}&type=${type}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (appointmentId) {
      getAppointmentDetail(`/${appointmentId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      i18n={i18n}
      submit={handleSubmit}
      service_title={passport_service_title}
      end_date_appointment={end_date_appointment}
      loadingStatus={status}
      email={email}
      errorSaveMessage={errorSaveMessage}
    />
  );
};

FormPassport.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  numberRegister: PropTypes.object,
  postNumberRegister: PropTypes.func,
  getAppointmentDetail: PropTypes.func,
  appointmentDetail: PropTypes.object
};

const mapStateToProps = (state) => ({
  numberRegister: {
    status: state.numberRegisterReducer.status,
    error: state.numberRegisterReducer.error,
    data: state.numberRegisterReducer.data
  },
  appointmentDetail: {
    status: state.appointmentDetailReducer.status,
    error: state.appointmentDetailReducer.error,
    data: state.appointmentDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postNumberRegister,
    getAppointmentDetail
  }),
  withRouter
)(FormPassport);
