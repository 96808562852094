const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getDiasporaListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DIASPORA_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DIASPORA_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DIASPORA_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_DIASPORA_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getDiasporaDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DIASPORA_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DIASPORA_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DIASPORA_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const getDiasporaStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DIASPORA_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DIASPORA_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DIASPORA_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getDiasporaStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DIASPORA_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DIASPORA_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DIASPORA_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getDiasporaStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DIASPORA_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DIASPORA_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DIASPORA_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

export {
  getDiasporaListReducer,
  getDiasporaDetailReducer,
  getDiasporaStatisticReducer,
  getDiasporaStatisticWeekReducer,
  getDiasporaStatisticMonthReducer
};
