import Layout from './components/layouts/HomeLayout';
import Homepage from './containers/pages/Homepage';
import Paspor from './containers/pages/Paspor';
import NotFound from './containers/pages/NotFound';
import News from './containers/pages/News';
import Event from './containers/pages/Event';
import EventDetail from './containers/pages/Event/EventDetail';
import EventRegistration from './containers/pages/Event/EventRegistration';
import EventRegistrationSuccess from './containers/pages/Event/EventRegistrationSuccess';
import DetailNews from './containers/pages/News/DetailNews';
import Track from './containers/pages/Track';
import Tourism from './containers/pages/Tourism';
import DetailTourism from './containers/pages/Tourism/DetailTourism';

// Passport
import FormPassport from './containers/form/PassportForm';
import FormRegistrationPassport from './containers/form/NumberRegisterForm';
import NumberRegistDetail from './containers/form/NumberRegisterForm/Review';
import FormRegistrationVisa from './containers/form/ApplicationInformationForm';
import VisaNumberRegistDetail from './containers/form/ApplicationInformationForm/Review';
import ReviewPassport from './containers/pages/Paspor/Review';
import Schedule from './containers/pages/Paspor/Schedule';
import ScheduleForm from './containers/pages/Paspor/Schedule/Form';
import ScheduleConfirm from './containers/pages/Paspor/Schedule/Confirm';
import TermConditionPassport from './containers/pages/Paspor/TNC';

import Login from './containers/form/BusinessLoginForm';
import AdminLogin from './containers/form/AdminLoginForm';
import Visa from './containers/pages/Visa';
import VisaForm from './containers/form/VisaForm';
import StatementLetter from './containers/pages/StatementLetter';
import DocLegalization from './containers/pages/DocLegalization';
import SelfReport from './containers/pages/SelfReport';
import ContactUs from './containers/pages/ContactUs';
import FormComplaint from './containers/form/ComplaintForm';
import FormDiaspora from './containers/form/DiasporaForm';

// Admin
import AdminLayout from './components/layouts/AdminLayout';
import Dashboard from './containers/admin/Dashboard';
import AdminNews from './containers/admin/News';
import CreateNews from './containers/admin/News/CreateNews';
import EditNews from './containers/admin/News/EditNews';
import DetailNewsAdmin from './containers/admin/News/DetailNews';
import AdminPengaduan from './containers/admin/Pengaduan';
import DetailPengaduan from './containers/admin/Pengaduan/DetailPengaduan';
import AntrianPaspor from './containers/admin/AntrianPaspor';
import AdminHoliday from './containers/admin/AntrianPaspor/Holiday';
import AdminUser from './containers/admin/User';
import AdminUserCreate from './containers/admin/User/CreateUser';
import AdminUserEdit from './containers/admin/User/EditUser';
import AdminEvents from './containers/admin/Events';
import AdminEventsDetail from './containers/admin/Events/DetailEvent';
import EditEvents from './containers/admin/Events/EditEvent';
import CreateEvents from './containers/admin/Events/CreateEvents';
import AdminPariwisata from './containers/admin/Pariwisata';
import AdminDetailPariwisata from './containers/admin/Pariwisata/DetailPariwisata';
import AdminEditPariwisata from './containers/admin/Pariwisata/EditPariwisata';
import CreatePariwisata from './containers/admin/Pariwisata/CreatePariwisata';

// Admin Visa
import VisaDetail from './containers/admin/Visa/Detail';
import AdminVisa from './containers/admin/Visa';

//Admin Ekonomi Bisnis
import InvestasiPerdagangan from './containers/admin/EkonomiBisnis/BisnisDiIndonesia';
import MenghubungkanIndonesia from './containers/admin/EkonomiBisnis/MenghubungkanIndonesia';
import PameranDagang from './containers/admin/EkonomiBisnis/PameranDagang';
import EkonomiLainnya from './containers/admin/EkonomiBisnis/Other';
import PameranBudayaPariwisata from './containers/admin/EkonomiBisnis/PameranBudaya';
import EkonomiDetail from './containers/admin/EkonomiBisnis/Detail';

//Admin Sosbud Surat Keterangan
import AdminSosbudSK from './containers/admin/SosBudSK';
import DetailSosbudSuratKeterangan from './containers/admin/SosBudSK/Detail';

// Admin Lokakarya
import KelasBahasa from './containers/admin/Lokakarya/KelasBahasa';
import GamelanBali from './containers/admin/Lokakarya/GamelanBali';
import AdminLokakaryaDetail from './containers/admin/Lokakarya/Detail';
import Darmasiswa from './containers/admin/Darmasiswa';
import AdminListServe from './containers/admin/ListServe';
import AdminListServeDetail from './containers/admin/ListServe/Detail';
import AdminDarmasiswaDetail from './containers/admin/Darmasiswa/Detail';
import AdminDiaspora from './containers/admin/Diaspora';
import AdminDiasporaDetail from './containers/admin/Diaspora/Detail';

// Admin Passport
import AdminPassport from './containers/admin/Passport';
import PassportDetailAdmin from './containers/admin/Passport/Detail';

// Admin sk
import AdminLetter from './containers/admin/SuratKeterangan';

// Admin doc legal
import AdminDocLegal from './containers/admin/DocLegalization';

// Pensosbud
import Pensosbud from './containers/pages/Pensosbud';
import CoursePensosbud from './containers/form/PensosbudForm/Courses/Form';
import CourseReviewPensosbud from './containers/form/PensosbudForm/Courses/Review';
import GraduateForm from './containers/form/PensosbudForm/Graduate';
import MoveSchoolForm from './containers/form/PensosbudForm/MoveSchool';
import LastStudyForm from './containers/form/PensosbudForm/LastStudy';
import PensosbudRegistration from './containers/form/PensosbudForm/NumberRegister/Form';
import PensosbudRegistrationReview from './containers/form/PensosbudForm/NumberRegister/Review';

import Faq from './containers/pages/Faq';
import Rates from './containers/pages/Rates';

// Economy
import Economy from './containers/pages/Economy';
import CultureExhibition from './containers/form/EconomyForm/CultureExhibition';
import TradeExhibition from './containers/form/EconomyForm/TradeExhibition';
import InvestmentInquiry from './containers/form/EconomyForm/InvestmentInquiry';
import PermohonanInvest from './containers/form/EconomyForm/PermohonanInvest';
import StatementForm from './containers/form/EconomyForm/Statement';
import TradeInquiry from './containers/form/EconomyForm/TradeInquiry';
import TradeQuestion from './containers/form/EconomyForm/TradeQuestion';

// Statement Letter
import StatementLetterRegister from './containers/form/StatementLetterForm/NumberRegister/Form';
import StatementLetterRegisterReview from './containers/form/StatementLetterForm/NumberRegister/Review';

// Doc Legalization
import DocLegalRegister from './containers/form/DocLegalizationForm/NumberRegister/Form';
import DocLegalRegisterReview from './containers/form/DocLegalizationForm/NumberRegister/Review';

export default [
  {
    component: Layout,
    path: '/registration',
    theme: 'light',
    routes: [
      {
        path: '/registration/create',
        exact: true,
        component: FormRegistrationPassport
      },
      {
        path: '/registration/number-detail',
        exact: true,
        component: NumberRegistDetail
      }
    ]
  },
  {
    component: Layout,
    path: '/passpor',
    theme: 'light',
    routes: [
      {
        path: '/passpor/schedule',
        exact: true,
        component: Schedule
      },
      {
        path: '/passpor/schedule/form',
        exact: true,
        component: ScheduleForm
      },
      {
        path: '/passpor/schedule/form/confirm',
        exact: true,
        component: ScheduleConfirm
      },
      {
        path: '/passpor/term-condition',
        exact: true,
        component: TermConditionPassport
      },
      {
        path: '/passpor/registration/review',
        exact: true,
        component: ReviewPassport
      },
      {
        path: '/passpor/registration/:step?',
        exact: true,
        component: FormPassport
      },
      {
        path: '/passpor/:tab?',
        exact: true,
        component: Paspor
      }
    ]
  },
  {
    component: Layout,
    path: '/news',
    theme: 'light',
    routes: [
      {
        path: '/news',
        exact: true,
        component: News
      },
      {
        path: '/news/detail/:id',
        exact: true,
        component: DetailNews
      }
    ]
  },
  {
    component: Layout,
    path: '/tourism',
    theme: 'light',
    routes: [
      {
        path: '/tourism',
        exact: true,
        component: Tourism
      },
      {
        path: '/tourism/detail/:id',
        exact: true,
        component: DetailTourism
      }
    ]
  },
  {
    component: Layout,
    path: '/track',
    theme: 'light',
    routes: [
      {
        path: '/track',
        exact: true,
        component: Track
      }
    ]
  },
  {
    component: Layout,
    path: '/event',
    theme: 'light',
    routes: [
      {
        path: '/event',
        exact: true,
        component: Event
      },
      {
        path: '/event/detail/:id',
        exact: true,
        component: EventDetail
      },
      {
        path: '/event/register/:id',
        exact: true,
        component: EventRegistration
      },
      {
        path: '/event/register/success/:id',
        exact: true,
        component: EventRegistrationSuccess
      }
    ]
  },
  {
    component: Layout,
    path: '/login',
    theme: 'light login',
    routes: [
      {
        path: '/login',
        exact: true,
        component: Login
      }
    ]
  },
  {
    component: Layout,
    path: '/complaint',
    theme: 'light login',
    routes: [
      {
        path: '/complaint',
        exact: true,
        component: FormComplaint
      }
    ]
  },
  {
    component: Layout,
    path: '/diaspora',
    theme: 'light login',
    routes: [
      {
        path: '/diaspora',
        exact: true,
        component: FormDiaspora
      }
    ]
  },
  {
    component: Layout,
    path: '/visa',
    theme: 'light',
    routes: [
      {
        path: '/visa/:tab?',
        exact: true,
        component: Visa
      },
      {
        path: '/visa/form/:step?',
        exact: true,
        component: VisaForm
      },
      {
        path: '/visa/registration/create',
        exact: true,
        component: FormRegistrationVisa
      },
      {
        path: '/visa/registration/review',
        exact: true,
        component: VisaNumberRegistDetail
      }
    ]
  },
  {
    component: Layout,
    path: '/statement-letter',
    theme: 'light',
    routes: [
      {
        path: '/statement-letter/registration/create',
        exact: true,
        component: StatementLetterRegister
      },
      {
        path: '/statement-letter/registration/number-detail',
        exact: true,
        component: StatementLetterRegisterReview
      },
      {
        path: '/statement-letter/:tab?',
        exact: true,
        component: StatementLetter
      }
    ]
  },
  {
    component: Layout,
    path: '/faq',
    theme: 'light',
    routes: [
      {
        path: '/faq/:tab?',
        exact: true,
        component: Faq
      }
    ]
  },
  {
    component: Layout,
    path: '/rates',
    theme: 'light',
    routes: [
      {
        path: '/rates/:tab?',
        exact: true,
        component: Rates
      }
    ]
  },
  {
    component: Layout,
    path: '/doc-legalization',
    theme: 'light',
    routes: [
      {
        path: '/doc-legalization/registration/create',
        exact: true,
        component: DocLegalRegister
      },
      {
        path: '/doc-legalization/registration/number-detail',
        exact: true,
        component: DocLegalRegisterReview
      },
      {
        path: '/doc-legalization/:tab?',
        exact: true,
        component: DocLegalization
      }
    ]
  },
  {
    component: Layout,
    path: '/economy',
    theme: 'light',
    routes: [
      {
        path: '/economy/investment-inquiry/form',
        exact: true,
        component: InvestmentInquiry
      },
      {
        path: '/economy/statement/form',
        exact: true,
        component: StatementForm
      },
      {
        path: '/economy/trade-inquiry/form',
        exact: true,
        component: TradeInquiry
      },
      {
        path: '/economy/permohonan-invest/form',
        exact: true,
        component: PermohonanInvest
      },
      {
        path: '/economy/trade-question/form',
        exact: true,
        component: TradeQuestion
      },
      {
        path: '/economy/trade-expo/form',
        exact: true,
        component: TradeExhibition
      },
      {
        path: '/economy/culture-expo/form',
        exact: true,
        component: CultureExhibition
      },
      {
        path: '/economy/:tab?',
        exact: true,
        component: Economy
      }
    ]
  },
  {
    component: Layout,
    path: '/pensosbud',
    theme: 'light',
    routes: [
      {
        path: '/pensosbud/registration/create',
        exact: true,
        component: PensosbudRegistration
      },
      {
        path: '/pensosbud/registration/review',
        exact: true,
        component: PensosbudRegistrationReview
      },
      {
        path: '/pensosbud/courses/form/:type?',
        exact: true,
        component: CoursePensosbud
      },
      {
        path: '/pensosbud/courses/review/:type?',
        exact: true,
        component: CourseReviewPensosbud
      },
      {
        path: '/pensosbud/graduate-school/form/:type?',
        exact: true,
        component: GraduateForm
      },
      {
        path: '/pensosbud/move-school/form/:type?',
        exact: true,
        component: MoveSchoolForm
      },
      {
        path: '/pensosbud/last-study-pns/form/:type?',
        exact: true,
        component: LastStudyForm
      },
      {
        path: '/pensosbud/:tab?',
        exact: true,
        component: Pensosbud
      }
    ]
  },
  {
    component: Layout,
    path: '/admin/login',
    theme: 'light login',
    routes: [
      {
        path: '/admin/login',
        exact: true,
        component: AdminLogin
      }
    ]
  },
  {
    component: AdminLayout,
    path: '/admin',
    routes: [
      {
        path: '/admin/dashboard',
        exact: true,
        component: Dashboard
      },
      {
        path: '/admin/news',
        exact: true,
        component: AdminNews
      },
      {
        path: '/admin/news/create',
        exact: true,
        component: CreateNews
      },
      {
        path: '/admin/news/edit/:id',
        exact: true,
        component: EditNews
      },
      {
        path: '/admin/news/:id',
        exact: true,
        component: DetailNewsAdmin
      },
      {
        path: '/admin/passpor/:type',
        exact: true,
        component: AdminPassport
      },
      {
        path: '/admin/passpor/:type/detail/:id',
        exact: true,
        component: PassportDetailAdmin
      },
      {
        path: '/admin/pengaduan',
        exact: true,
        component: AdminPengaduan
      },
      {
        path: '/admin/pengaduan/:id',
        exact: true,
        component: DetailPengaduan
      },
      {
        path: '/admin/antrian-paspor',
        exact: true,
        component: AntrianPaspor
      },
      {
        path: '/admin/antrian-paspor/holiday',
        exact: true,
        component: AdminHoliday
      },
      {
        path: '/admin/users',
        exact: true,
        component: AdminUser
      },
      {
        path: '/admin/users/create',
        exact: true,
        component: AdminUserCreate
      },
      {
        path: '/admin/users/edit/:id',
        exact: true,
        component: AdminUserEdit
      },
      {
        path: '/admin/events',
        exact: true,
        component: AdminEvents
      },
      {
        path: '/admin/events/create',
        exact: true,
        component: CreateEvents
      },
      {
        path: '/admin/events/:id',
        exact: true,
        component: AdminEventsDetail
      },
      {
        path: '/admin/events/edit/:id',
        exact: true,
        component: EditEvents
      },
      {
        path: '/admin/pariwisata',
        exact: true,
        component: AdminPariwisata
      },
      {
        path: '/admin/pariwisata/create',
        exact: true,
        component: CreatePariwisata
      },
      {
        path: '/admin/pariwisata/:id',
        exact: true,
        component: AdminDetailPariwisata
      },
      {
        path: '/admin/pariwisata/edit/:id',
        exact: true,
        component: AdminEditPariwisata
      },
      {
        path: '/admin/visa/:type',
        exact: true,
        component: AdminVisa
      },
      {
        path: '/admin/visa/:type/detail/:id',
        exact: true,
        component: VisaDetail
      },
      {
        path: '/admin/lokakarya/kelas-bahasa',
        exact: true,
        component: KelasBahasa
      },
      {
        path: '/admin/lokakarya/gamelan-bali',
        exact: true,
        component: GamelanBali
      },
      {
        path: '/admin/lokakarya/:type/detail/:id',
        exact: true,
        component: AdminLokakaryaDetail
      },
      {
        path: '/admin/darmasiswa',
        exact: true,
        component: Darmasiswa
      },
      {
        path: '/admin/darmasiswa/detail/:id',
        exact: true,
        component: AdminDarmasiswaDetail
      },
      {
        path: '/admin/mailing-list',
        exact: true,
        component: AdminListServe
      },
      {
        path: '/admin/mailing-list/detail/:id',
        exact: true,
        component: AdminListServeDetail
      },
      {
        path: '/admin/diaspora',
        exact: true,
        component: AdminDiaspora
      },
      {
        path: '/admin/diaspora/detail/:id',
        exact: true,
        component: AdminDiasporaDetail
      },
      {
        path: '/admin/surat-keterangan/:type',
        exact: true,
        component: AdminLetter
      },
      {
        path: '/admin/doc-legal/:type',
        exact: true,
        component: AdminDocLegal
      },
      {
        path: '/admin/economy/bisnis',
        exact: true,
        component: InvestasiPerdagangan
      },
      {
        path: '/admin/economy/global',
        exact: true,
        component: MenghubungkanIndonesia
      },
      {
        path: '/admin/economy/dagang',
        exact: true,
        component: PameranDagang
      },
      {
        path: '/admin/economy/other',
        exact: true,
        component: EkonomiLainnya
      },
      {
        path: '/admin/economy/exhibition',
        exact: true,
        component: PameranBudayaPariwisata
      },
      {
        path: '/admin/economy/:type/detail/:id',
        exact: true,
        component: EkonomiDetail
      },
      {
        path: '/admin/sosbud/sk/:type',
        exact: true,
        component: AdminSosbudSK
      },
      {
        path: '/admin/sosbud/sk/:type/detail/:id',
        exact: true,
        component: DetailSosbudSuratKeterangan
      },
      {
        path: '*',
        component: NotFound
      }
    ]
  },
  {
    component: Layout,
    path: '/self-report',
    theme: 'light',
    routes: [
      {
        path: '/self-report',
        exact: true,
        component: SelfReport
      }
    ]
  },
  {
    component: Layout,
    path: '/contact-us',
    theme: 'light',
    routes: [
      {
        path: '/contact-us',
        exact: true,
        component: ContactUs
      }
    ]
  },
  {
    component: Layout,
    routes: [
      {
        path: '/',
        exact: true,
        component: Homepage
      },
      {
        path: '/en',
        exact: true,
        component: Homepage
      },
      {
        path: '/id',
        exact: true,
        component: Homepage
      },
      {
        path: '*',
        component: NotFound
      }
    ]
  }
];
