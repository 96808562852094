import React, { useEffect, useState } from 'react';
import Banner from '../../../components/Banner';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewsThumbnail from '../../../components/thumbnail/NewsThumbnail';

import { getNewsList } from './action';

import searchIcon from '../../../assets/img/icon/search.svg';
import './style.scss';

const News = ({ history, i18n, getNewsList, newsList }) => {
  const { data } = newsList || {};
  const [search, changeSearch] = useState('');

  useEffect(() => {
    getNewsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner
        title={i18n('news.heading.top1')}
        subtitle={i18n('news.heading.top2')}
        backButtonClick={() => history.goBack()}
      />
      <Container>
        <div className='news'>
          <div className='news-top'>
            <h3>{i18n('news.heading.list')}</h3>
            <div className='news-top-search'>
              <img src={searchIcon} alt='search-img' />
              <input
                placeholder={i18n('news.search')}
                onChange={(e) => changeSearch(e.target.value)}
              />
            </div>
          </div>
          <div className='news-list'>
            <Row>
              {data.map((news, key) =>
                news.title.toLowerCase().includes(search) ? (
                  <NewsThumbnail key={key} data={news} />
                ) : (
                  ''
                )
              )}
            </Row>
          </div>
          <div className='news-paginate' style={{ display: `none` }}>
            <div className='prev'>{i18n('nav.prev')}</div>
            <div className='number'>1</div>
            <div className='number active'>2</div>
            <div className='number'>3</div>
            <div className='next'>{i18n('nav.next')}</div>
          </div>
        </div>
      </Container>
    </>
  );
};

News.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  getNewsList: PropTypes.func,
  newsList: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  newsList: {
    status: state.newsListReducer.status,
    error: state.newsListReducer.error,
    data: state.newsListReducer.data
  }
});

export default compose(connect(mapStateToProps, { getNewsList }), withRouter)(News);
