import axios from 'axios';
import { apiCaller } from '../../../helpers/apiCaller';

const getToursRequest = () => ({
  type: 'GET_TOURS_ADMIN_REQUEST'
});

const getToursSuccess = (data) => ({
  type: 'GET_TOURS_ADMIN_SUCCESS',
  payload: data
});

const getToursFailure = (error) => ({
  type: 'GET_TOURS_ADMIN_FAILURE',
  payload: error
});

const getTours = () => {
  return (dispatch) => {
    dispatch(getToursRequest());
    return axios
      .get(process.env.REACT_APP_BACKEND_URI + '/tours')
      .then((data) => dispatch(getToursSuccess(data)))
      .catch((error) => dispatch(getToursFailure(error)));
  };
};

const postTourRequest = () => ({
  type: 'POST_TOUR_ADMIN_REQUEST'
});

const postTourSuccess = (data) => ({
  type: 'POST_TOUR_ADMIN_SUCCESS',
  payload: data
});

const postTourFailure = (error) => ({
  type: 'POST_TOUR_ADMIN_FAILURE',
  payload: error
});

const postTour = (params) => {
  return (dispatch) => {
    dispatch(postTourRequest());
    return apiCaller('admin/tours', 'post', params, true)
      .then((data) => dispatch(postTourSuccess(data)))
      .catch((error) => dispatch(postTourFailure(error)));
  };
};

const deleteTourRequest = () => ({
  type: 'DELETE_TOUR_ADMIN_REQUEST'
});

const deleteTourSuccess = (data) => ({
  type: 'DELETE_TOUR_ADMIN_SUCCESS',
  payload: data
});

const deleteTourFailure = (error) => ({
  type: 'DELETE_TOUR_ADMIN_FAILURE',
  payload: error
});

const deleteTour = (id) => {
  return (dispatch) => {
    dispatch(deleteTourRequest());
    return apiCaller('admin/tours/' + id, 'delete', '', true)
      .then((data) => dispatch(deleteTourSuccess(data)))
      .catch((error) => dispatch(deleteTourFailure(error)));
  };
};

const editTourRequest = () => ({
  type: 'EDIT_TOUR_ADMIN_REQUEST'
});

const editTourSuccess = (data) => ({
  type: 'EDIT_TOUR_ADMIN_SUCCESS',
  payload: data
});

const editTourFailure = (error) => ({
  type: 'EDIT_TOUR_ADMIN_FAILURE',
  payload: error
});

const editTour = (id, params) => {
  return (dispatch) => {
    dispatch(editTourRequest());
    return apiCaller('admin/tours/' + id, 'put', params, true)
      .then((data) => dispatch(editTourSuccess(data)))
      .catch((error) => dispatch(editTourFailure(error)));
  };
};

export { getTours, postTour, deleteTour, editTour };
