export const errorScroll = () => {
  const firstErrorElement = document.querySelectorAll('.err-message')[0];

  firstErrorElement &&
    firstErrorElement.scrollIntoView &&
    firstErrorElement.scrollIntoView(true);
  window && window.scrollBy(0, -100);
};

export const errorAlertScroll = () => {
  const firstErrorElement = document.querySelectorAll('.alert')[0];

  firstErrorElement &&
    firstErrorElement.scrollIntoView &&
    firstErrorElement.scrollIntoView(true);
  window && window.scrollBy(0, -100);
};

export const parseQuery = (subject) => {
  const results = {};
  const parser = /[^&?]+/g;
  let match = parser.exec(subject);

  while (match !== null) {
    const parts = match[0].split('=');

    results[parts[0]] = parts[1];
    match = parser.exec(subject);
  }

  return results;
};
