import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';
import { Alert } from 'reactstrap';
import moment from 'moment';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../../helpers/functions';

import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import TextInput from '../../../../components/input/TextInput';
import Dropdown from '../../../../components/Dropdown';
import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';
import { getOfficeList } from '../../NumberRegisterForm/action';

import './style.scss';

const FormRegisNumber = ({
  history,
  i18n,
  getOfficeList,
  offices,
  service_title,
  end_date_appointment,
  submit,
  loadingStatus,
  email,
  errorSaveMessage,
  useSelfReport = true,
  prependTextPhone
}) => {
  const defaultForm = {
    name: '',
    email: '',
    workingArea: ''
  };
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);

  const officeOptions = get(offices, ['data']);
  const isExpired = end_date_appointment
    ? !moment().isBefore(end_date_appointment)
    : false;

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      office_id: get(form, ['office_id', 'id']),
      name: get(form, ['name']),
      email: get(form, ['email']),
      phone: get(form, ['phone']),
      self_report_number: get(form, ['self_report_number'])
    };

    let schema = yup.object().shape({
      office_id: yup.string().required('required'),
      name: yup.string().required('required'),
      email: yup.string().email('email').required('required'),
      phone: yup.string().required('required')
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        submit(params);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    if (email && form.email === '') {
      setForm({ ...form, email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (errorSaveMessage && errorSave === '') {
      setErrorSave(errorSaveMessage);
      setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSaveMessage]);

  useEffect(() => {
    if (isExpired && errorSave === '') {
      setErrorSave(i18n('paspor_page.expired_appointment'));
      setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired]);

  useEffect(() => {
    getOfficeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadingStatus === 'pending' && <Loading />}
      <Banner
        title={service_title}
        subtitle={
          <>
            <div className='text'>{i18n('paspor_page.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('paspor_page.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      <CardDetail title={i18n('form.form_registration')} className='registration'>
        <form onSubmit={handleSubmit}>
          <Alert color='danger' isOpen={visible || isExpired}>
            {errorSave}
          </Alert>
          <div className={`form-wrap ${isExpired ? 'disable-form' : ''}`}>
            <div className='instruction'>{i18n('form.instruction')}</div>
            <TextInput
              label={i18n('form.fullname.label')}
              placeholder={i18n('form.fullname.placeholder')}
              value={form.name}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'name')
              }}
              errorMessage={
                get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
              }
            />
            <TextInput
              label={i18n('form.email.label')}
              placeholder={i18n('form.email.placeholder')}
              value={form.email}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'email')
              }}
              errorMessage={
                get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
              }
            />
            <TextInput
              label={i18n('form.phone.label')}
              placeholder={i18n('form.phone.placeholder')}
              value={form.phone}
              inputProps={{
                type: 'number',
                onChange: (e) => handlerChanges(e.target.value, 'phone')
              }}
              errorMessage={
                get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
              }
              prependText={prependTextPhone}
            />
            <Dropdown
              className='row input-container'
              label={i18n('form.workingArea.label')}
              sublabel={i18n('form.workingArea.sublabel')}
              placeholder={i18n('form.workingArea.placeholder')}
              value={get(form, ['office_id'])}
              options={officeOptions}
              onClick={(item) => {
                handlerChanges(item, 'office_id');
              }}
              isValueObject
              valueKey='id'
              labelKey='name'
              dropdownToggleProps={{
                tag: 'div',
                className: 'input-dropdown'
              }}
              withAutocomplete
              errorMessage={
                get(errors, ['office_id']) &&
                i18n(`validation.${get(errors, ['office_id'])}`)
              }
            />
            {useSelfReport && (
              <TextInput
                label={i18n('form.self_report_number.label')}
                sublabel={i18n('form.self_report_number.sublabel')}
                placeholder={i18n('form.self_report_number.placeholder')}
                value={form.self_report_number}
                inputProps={{
                  onChange: (e) => handlerChanges(e.target.value, 'self_report_number')
                }}
              />
            )}
          </div>
          <div className='btn-wrap'>
            <Button
              color='primary'
              type='submit'
              className={`${isExpired ? 'disabled' : ''}`}
            >
              {i18n('form.cta.get_number_register')}
            </Button>
          </div>
        </form>
      </CardDetail>
    </>
  );
};

FormRegisNumber.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  getOfficeList: PropTypes.func,
  offices: PropTypes.object,
  submit: PropTypes.func,
  service_title: PropTypes.string,
  end_date_appointment: PropTypes.string,
  loadingStatus: PropTypes.string,
  defaultForm: PropTypes.object
};

const mapStateToProps = (state) => ({
  offices: {
    status: state.officeListReducer.status,
    error: state.officeListReducer.error,
    data: state.officeListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getOfficeList
  }),
  withRouter
)(FormRegisNumber);
