import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';
import { Alert } from 'reactstrap';
import moment from 'moment';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../../../helpers/functions';
import { getQuery } from '../../../../pages/Paspor/function';

import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/input/TextInput';
import RadioButton from '../../../../../components/input/RadioButton';
import Datepicker from '../../../../../components/input/Datepicker';
import Dropdown from '../../../../../components/Dropdown';
import Icon from '../../../../../components/Icon';
import Loading from '../../../../../components/Loading';

import { postSosBudEducation } from '../../action';
import { getOfficeList } from '../../../NumberRegisterForm/action';

import './style.scss';

const CourseForm = ({
  history,
  i18n,
  postSosBudEducation,
  sosBudEducation,
  country,
  getOfficeList,
  offices
}) => {
  const { type, serviceId } = getQuery(history);
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const { data, error, status } = sosBudEducation;
  const officeOptions = get(offices, ['data']);

  const options = [
    { value: 'YES', name: i18n('pensosbud.lokakarya_form.form.learn_before.yes') },
    { value: 'NO', name: i18n('pensosbud.lokakarya_form.form.learn_before.no') }
  ];
  const total_courses_options = [
    { value: 1, label: 'x1' },
    { value: 2, label: 'x2' }
  ];
  const courseName = {
    bahasa: 'bahasa_class',
    gamelan: 'gamelan_class'
  };
  const courseLink = {
    bahasa: 'lokakarya-bahasa',
    gamelan: 'lokakarya-gamelan-bali'
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      name: yup.string().required('required'),
      dob: yup.date().required('required'),
      email: yup.string().email('email').required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      state: yup.object().shape({
        name: yup.string().required('required')
      }),
      learn_before: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        postSosBudEducation({
          name: form.name,
          email: form.email,
          phone: form.phone,
          state_id: form.state.name,
          service_id: serviceId,
          birth_date: moment(form.dob).format('YYYY-MM-DD'),
          'birth-city': '',
          is_learned: form.learn_before === 'YES'
        });
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
      const messageDetail = get(error, [
        'response',
        'data',
        'error',
        'detail',
        0,
        'message'
      ]);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      errorAlertScroll();
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      const { id } = data;
      history.push(`/pensosbud/courses/review${search}&id=${id}&email=${form.email}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    getOfficeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handlerSubmit} id='courses'>
      {status === 'pending' && <Loading />}
      <Alert color='danger' isOpen={visible}>
        {errorSave}
      </Alert>
      <div className='form-container'>
        <span
          className='back'
          onClick={() => history.push(`/pensosbud/${courseLink[type]}`)}
        >
          <Icon icon='arrow-blue' />
          {i18n('pensosbud.lokakarya_form.back')}
        </span>
        <div className='form-wrap with-border'>
          <div className='courses-detail'>
            <div className='label'>
              {i18n(`pensosbud.lokakarya_form.${courseName[type]}`)}
            </div>
            <div className='detail'>
              <div className='price'>{i18n('pensosbud.lokakarya_form.free')}</div>
            </div>
          </div>
        </div>
        <div className='form-wrap'>
          <div className='col2'>
            <TextInput
              label={i18n('pensosbud.lokakarya_form.form.name.label')}
              placeholder={i18n('pensosbud.lokakarya_form.form.name.placeholder')}
              value={get(form, ['name'])}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'name')
              }}
              errorMessage={
                get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
              }
            />
            <Datepicker
              label={i18n('pensosbud.lokakarya_form.form.dob.label')}
              placeholder={i18n('pensosbud.lokakarya_form.form.dob.placeholder')}
              className='dob'
              value={get(form, ['dob'])}
              onChange={(dob) => handlerChanges(dob, 'dob')}
              errorMessage={
                get(errors, ['dob']) && i18n(`validation.${get(errors, ['dob'])}`)
              }
            />
          </div>

          <div className='col2'>
            <TextInput
              label={i18n('pensosbud.lokakarya_form.form.email.label')}
              placeholder={i18n('pensosbud.lokakarya_form.form.email.placeholder')}
              value={get(form, ['email'])}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'email')
              }}
              errorMessage={
                get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
              }
            />
            <TextInput
              label={i18n('pensosbud.lokakarya_form.form.phone.label')}
              placeholder={i18n('pensosbud.lokakarya_form.form.phone.placeholder')}
              value={get(form, ['phone'])}
              inputProps={{
                type: 'number',
                onChange: (e) => handlerChanges(e.target.value, 'phone')
              }}
              errorMessage={
                get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
              }
            />
          </div>

          <div className='col2'>
            <Dropdown
              className='row input-container'
              label={i18n('pensosbud.lokakarya_form.form.state.label')}
              placeholder={i18n('pensosbud.lokakarya_form.form.state.placeholder')}
              value={get(form, ['state'])}
              options={officeOptions}
              onClick={(item) => handlerChanges(item, 'state', 'state.name')}
              isValueObject
              dropdownToggleProps={{
                tag: 'div',
                className: 'input-dropdown'
              }}
              valueKey='name'
              labelKey='name'
              withAutocomplete
              errorMessage={
                get(errors, ['state.name']) &&
                i18n(`validation.${get(errors, ['state.name'])}`)
              }
            />

            <RadioButton
              label={i18n('pensosbud.lokakarya_form.form.learn_before.label', {
                type: i18n(
                  `pensosbud.lokakarya_form.form.learn_before.${courseName[type]}`
                )
              })}
              options={options}
              value={get(form, ['learn_before'])}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'learn_before')
              }}
              errorMessage={
                get(errors, ['learn_before']) &&
                i18n(`validation.${get(errors, ['learn_before'])}`)
              }
            />
          </div>
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/pensosbud/${courseLink[type]}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('pensosbud.lokakarya_form.form.cta')}
        </Button>
      </div>
    </form>
  );
};

CourseForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  postSosBudEducation: PropTypes.func,
  sosBudEducation: PropTypes.object,
  offices: PropTypes.object,
  getOfficeList: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  sosBudEducation: {
    status: state.sosBudEducationReducer.status,
    error: state.sosBudEducationReducer.error,
    data: state.sosBudEducationReducer.data
  },
  offices: {
    status: state.officeListReducer.status,
    error: state.officeListReducer.error,
    data: state.officeListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postSosBudEducation,
    getOfficeList
  }),
  withRouter
)(CourseForm);
