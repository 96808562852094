import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Check from '../../../assets/img/check.svg';
import Close from '../../../assets/img/close.svg';
import Cross from '../../../assets/img/cross.svg';
import Edit from '../../../assets/img/edit.svg';
import View from '../../../assets/img/view.svg';
import Print from '../../../assets/img/print_24px.svg';
import Hadir from '../../../assets/img/hadir.svg';
import TidakHadir from '../../../assets/img/tidak_hadir.svg';
import Selesai from '../../../assets/img/approved.svg';

const Action = ({ action, id, data }) => {
  const imgAction = {
    view: View,
    edit: Edit,
    check: Check,
    print: Print,
    hadir: Hadir,
    tidak_hadir: TidakHadir,
    selesai: Selesai,
    cross: Cross
  };

  const listAction = action.map((action, index) => (
    <span className='action-icon' key={index} onClick={() => action.onClick(id, data)}>
      <img
        src={get(imgAction, [action.type]) || Close}
        alt={'icon-action-' + action.type}
        style={{ height: 25, paddingRight: 8 }}
      />
    </span>
  ));

  return listAction;
};

Action.propTypes = {
  action: PropTypes.array,
  id: PropTypes.any,
  history: PropTypes.object
};

export default withRouter(Action);
