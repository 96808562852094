import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MiniCard from '../../../../components/CardDetail/MiniCard';

import { apiCaller } from '../../../../helpers/apiCaller';
import { setSummary } from '../functions';

const EkonomiDashboard = (props) => {
  const serviceList = [
    { title: 'Investasi di Indonesia', type: 'investment', code: 'investmentIndo' },
    { title: 'Perdagangan di Indonesia', type: 'trade', code: 'tradeIndo' },
    {
      title: 'Investasi di Wilayah Kerja KJRI New York',
      type: 'investment',
      code: 'investmentKJRI'
    },
    {
      title: 'Perdagangan di Wilayah Kerja KJRI New York',
      type: 'trade',
      code: 'tradeKJRI'
    },
    { title: 'Pameran Dagang', code: 'exhibitorTrade' },
    { title: 'Pameran Budaya dan Pariwisata', code: 'exhibitorCulture' }
  ];

  const renderDetails = () =>
    serviceList.map((detail, idx) => (
      <StatisticDetail detail={detail} {...props} serviceList={serviceList} key={idx} />
    ));

  return (
    <div className='statistic detail-container'>
      <div className='title'>Ekonomi</div>
      <div className='detail-wrap'>{renderDetails()}</div>
    </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
const StatisticDetail = ({
  detail,
  history,
  startDate,
  endDate,
  reloadData,
  setReloadData
}) => {
  const { title, code } = detail;
  const [statistic, setStatistic] = useState({
    numOfIn: String(0)
  });

  const detailService = {
    investmentIndo: {
      adminLink: 'economy/bisnis',
      statisticLink: 'admin/investment/inquiry/statistic'
    },
    tradeIndo: {
      adminLink: 'economy/bisnis?tab=2',
      statisticLink: 'admin/trade/inquiry/statistic'
    },
    investmentKJRI: {
      adminLink: 'economy/global',
      statisticLink: 'admin/investment/question/statistic'
    },
    tradeKJRI: {
      adminLink: 'economy/global?tab=2',
      statisticLink: 'admin/trade/question/statistic'
    },
    exhibitorTrade: {
      adminLink: 'economy/dagang',
      statisticLink: 'admin/exhibitors/statistic'
    },
    exhibitorCulture: {
      adminLink: 'economy/exhibition',
      statisticLink: 'admin/exhibitors/statistic'
    }
  };

  const loadData = () => {
    const params = {
      from: startDate,
      to: endDate
    };

    apiCaller(detailService[code].statisticLink, 'get', undefined, true, params)
      .then((response) => {
        const statisticData = get(response, ['data', 'data']);
        const summaryData = {
          title,
          subtitle: 'Document WNI',
          onClick: () => history.push(`/admin/${detailService[code].adminLink}`),
          numOfIn: get(statisticData, [0, 'total']) || '0'
        };

        if (code === 'exhibitorTrade') {
          const findTotal = statisticData.find(({ type }) => type === 'TRADE');
          summaryData.numOfIn = get(findTotal, ['total']) || '0';
        } else if (code === 'exhibitorCulture') {
          const findTotal = statisticData.find(({ type }) => type === 'CULTURE');
          summaryData.numOfIn = get(findTotal, ['total']) || '0';
        }

        setStatistic(summaryData);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const detailMiniCard = {
    title,
    subtitle: 'Document WNI',
    ...statistic
  };

  return <MiniCard detail={detailMiniCard} />;
};

StatisticDetail.propTypes = {
  history: PropTypes.object,
  detail: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reloadData: PropTypes.bool,
  setReloadData: PropTypes.func,
  serviceList: PropTypes.array
};

EkonomiDashboard.propTypes = {
  history: PropTypes.object
};

export default EkonomiDashboard;
