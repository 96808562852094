import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../components/Button';
import DatepickerRange from '../components/DatepickerRange';
import Content from './components/MainContent';

import {
  getSosBudSubmissionList,
  resetSosBudSubmissionList,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth,
  getStatisticAll
} from './action';
import { getSosBudServiceList } from '../../form/PensosbudForm/action';
import { getSosBudServiceId } from '../../form/PensosbudForm/functions';
import sosbudServiceTitle from '../../../constant/sosbudSuratKeterangan';
import { getStatisticDate, downloadFile } from '../functions';

const AdminPassport = ({
  sosbudSubmissionList,
  sosbudService,
  getSosBudSubmissionList,
  resetSosBudSubmissionList,
  getSosBudServiceList,
  statistic,
  statisticWeek,
  statisticMonth,
  statisticAll,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth,
  getStatisticAll
}) => {
  const { type } = useParams();

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [activeTab, setActiveTab] = useState('1');
  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();
  const statusTabList = {
    '1': 'REGISTERED',
    '2': 'PROCESS',
    '3': 'APPROVED',
    '4': 'PENDING'
  };

  const title = sosbudServiceTitle[type];
  const sosbudListData = get(sosbudSubmissionList, ['data', 'data', 'data']) || [];
  const sosbudServiceList = get(sosbudService, ['data']) || [];
  const pagination = get(sosbudSubmissionList, ['data', 'pagination']) || {};

  const sosbudServiceId = getSosBudServiceId(sosbudServiceList, type);
  const loading =
    get(sosbudService, ['status']) === 'pending' ||
    get(sosbudSubmissionList, ['status']) === 'pending';

  const getAllStatistic = () => {
    const allStatistic = get(statisticAll, ['data']) || [];
    const summaryData = {
      numOfIn: String(0),
      numOfProcess: String(0),
      numOfFinish: String(0),
      numOfPending: String(0)
    };
    allStatistic.map(({ status, total }) => {
      switch (status) {
        case statusTabList['1']:
          summaryData.numOfIn = String(total);
          break;
        case statusTabList['2']:
          summaryData.numOfProcess = String(total);
          break;
        case statusTabList['3']:
          summaryData.numOfFinish = String(total);
          break;
        case statusTabList['4']:
          summaryData.numOfPending = String(total);
          break;
      }
    });
    return summaryData;
  };

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      }
    ];
    const dayStatistic = get(statistic, ['data']) || [];
    const weekStatistic = get(statisticWeek, ['data']) || [];
    const monthStatistic = get(statisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ status, total }) => {
        switch (status) {
          case statusTabList['1']:
            summaryData[index].numOfIn = String(total);
            break;
          case statusTabList['2']:
            summaryData[index].numOfProcess = String(total);
            break;
          case statusTabList['3']:
            summaryData[index].numOfFinish = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const loadData = () => {
    const params = {
      service_id: sosbudServiceId,
      status: statusTabList[activeTab],
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getSosBudSubmissionList(params);
  };

  const loadStatisticData = (isAllOnly) => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();
    if (isAllOnly) {
      getStatisticAll({
        service_id: sosbudServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    } else {
      getStatistic({
        service_id: sosbudServiceId,
        from: today,
        to: today
      });
      getStatisticWeek({
        service_id: sosbudServiceId,
        from: startWeek,
        to: endWeek
      });
      getStatisticMonth({
        service_id: sosbudServiceId,
        from: startMonth,
        to: endMonth
      });
      getStatisticAll({
        service_id: sosbudServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    }
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      service_id: sosbudServiceId
    };
    const fileName = `pensosbud-${title}-${params.from}-${params.to}`;
    downloadFile('pensosbud', params, fileName);
  };

  useEffect(() => {
    getSosBudServiceList();
    return () => resetSosBudSubmissionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sosbudServiceId]);

  useEffect(() => {
    resetSosBudSubmissionList();

    if (sosbudServiceId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pageDivider, page, query, sosbudServiceId]);

  useEffect(() => {
    setActiveTab('1');
    setPage(1);
    setPageDivider(5);
    setQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Content
      title={title}
      dataTab={sosbudListData}
      summaryData={getSummaryData()}
      type={type}
      loading={loading}
      onTabChanges={setActiveTab}
      onPageChanges={setPage}
      onPageDividerChanges={setPageDivider}
      onQueryChanges={setQuery}
      defaultActiveTab={activeTab}
      pagination={pagination}
      loadData={() => {
        loadData();
        loadStatisticData();
      }}
      allStatistic={getAllStatistic()}
      topActionComponent={
        <div className='detail-action' style={{ display: 'flex' }}>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={() => {
              loadData();
              loadStatisticData(true);
            }}
          />
          <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
            Download
          </Button>
        </div>
      }
    />
  );
};

AdminPassport.propTypes = {
  sosbudSubmissionList: PropTypes.object,
  sosbudService: PropTypes.object,
  getSosBudSubmissionList: PropTypes.func,
  resetSosBudSubmissionList: PropTypes.func,
  getSosBudServiceList: PropTypes.func,
  statistic: PropTypes.object,
  statisticWeek: PropTypes.object,
  statisticMonth: PropTypes.object,
  statisticAll: PropTypes.object,
  getStatistic: PropTypes.func,
  getStatisticWeek: PropTypes.func,
  getStatisticMonth: PropTypes.func,
  getStatisticAll: PropTypes.func
};

const mapStateToProps = (state) => ({
  sosbudSubmissionList: {
    status: state.getSosBudSubmissionListReducer.status,
    error: state.getSosBudSubmissionListReducer.error,
    data: state.getSosBudSubmissionListReducer.data
  },
  sosbudService: {
    status: state.sosBudServiceListReducer.status,
    error: state.sosBudServiceListReducer.error,
    data: state.sosBudServiceListReducer.data
  },
  statistic: {
    status: state.getSosbudStatisticReducer.status,
    error: state.getSosbudStatisticReducer.error,
    data: state.getSosbudStatisticReducer.data
  },
  statisticWeek: {
    status: state.getSosbudStatisticWeekReducer.status,
    error: state.getSosbudStatisticWeekReducer.error,
    data: state.getSosbudStatisticWeekReducer.data
  },
  statisticMonth: {
    status: state.getSosbudStatisticMonthReducer.status,
    error: state.getSosbudStatisticMonthReducer.error,
    data: state.getSosbudStatisticMonthReducer.data
  },
  statisticAll: {
    status: state.getSosbudStatisticAllReducer.status,
    error: state.getSosbudStatisticAllReducer.error,
    data: state.getSosbudStatisticAllReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudSubmissionList,
    resetSosBudSubmissionList,
    getSosBudServiceList,
    getStatistic,
    getStatisticWeek,
    getStatisticMonth,
    getStatisticAll
  }),
  withRouter
)(AdminPassport);
