import { apiCaller } from '../../../helpers/apiCaller';

const getTrackRequest = () => ({
  type: 'GET_TRACK_REQUEST'
});

const getTrackSuccess = (data) => ({
  type: 'GET_TRACK_SUCCESS',
  payload: data
});

const getTrackFailure = (error) => ({
  type: 'GET_TRACK_FAILURE',
  payload: error
});

const resetTrack = () => ({
  type: 'RESET_TRACK'
});

const postTrack = (params) => {
  return (dispatch) => {
    dispatch(getTrackRequest());
    return apiCaller('tracking', 'post', params)
      .then((data) => dispatch(getTrackSuccess(data)))
      .catch((error) => dispatch(getTrackFailure(error)));
  };
};

export { postTrack, resetTrack };
