import { apiCaller } from '../../../helpers/apiCaller';

// post image
const postFileRequest = () => ({
  type: 'POST_FILE_REQUEST'
});

const postFileSuccess = (data) => ({
  type: 'POST_FILE_SUCCESS',
  photo: data
});

const postFileFailure = (error) => ({
  type: 'POST_FILE_FAILURE',
  photo: error
});

const postFile = (params) => {
  return (dispatch) => {
    dispatch(postFileRequest());
    return apiCaller('storages', 'post', params)
      .then((data) => dispatch(postFileSuccess(data)))
      .catch((error) => dispatch(postFileFailure(error)));
  };
};

const postFilePasspor = (params) => {
  return (dispatch) => {
    dispatch(postFileRequest());
    return apiCaller('storages/passport', 'post', params)
      .then((data) => dispatch(postFileSuccess(data)))
      .catch((error) => dispatch(postFileFailure(error)));
  };
};

const defaultData = {
  data: {},
  status: 'idle',
  error: ''
};

// RESET DATA
const resetFileData = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_FILE_DATA',
      data: defaultData
    });
  };
};

export { postFile, resetFileData };
