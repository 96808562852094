import axios from 'axios';
import { apiCaller } from '../../../helpers/apiCaller';

const getEventsRequest = () => ({
  type: 'GET_EVENTS_ADMIN_REQUEST'
});

const getEventsSuccess = (data) => ({
  type: 'GET_EVENTS_ADMIN_SUCCESS',
  payload: data
});

const getEventsFailure = (error) => ({
  type: 'GET_EVENTS_ADMIN_FAILURE',
  payload: error
});

const getEvents = () => {
  return (dispatch) => {
    dispatch(getEventsRequest());
    return axios
      .get(process.env.REACT_APP_BACKEND_URI + '/events')
      .then((data) => dispatch(getEventsSuccess(data)))
      .catch((error) => dispatch(getEventsFailure(error)));
  };
};

const getEventDetailRequest = () => ({
  type: 'GET_EVENT_DETAIL_ADMIN_REQUEST'
});

const getEventDetailSuccess = (data) => ({
  type: 'GET_EVENT_DETAIL_ADMIN_SUCCESS',
  payload: data
});

const getEventDetailFailure = (error) => ({
  type: 'GET_EVENT_DETAIL_ADMIN_FAILURE',
  payload: error
});

const getEventDetail = (params) => {
  return (dispatch) => {
    dispatch(getEventDetailRequest());
    return apiCaller('admin/events/registration', 'get', undefined, true, params)
      .then((data) => dispatch(getEventDetailSuccess(data)))
      .catch((error) => dispatch(getEventDetailFailure(error)));
  };
};

const postEventRequest = () => ({
  type: 'POST_EVENT_ADMIN_REQUEST'
});

const postEventSuccess = (data) => ({
  type: 'POST_EVENT_ADMIN_SUCCESS',
  payload: data
});

const postEventFailure = (error) => ({
  type: 'POST_EVENT_ADMIN_FAILURE',
  payload: error
});

const postEvent = (params) => {
  return (dispatch) => {
    dispatch(postEventRequest());
    return apiCaller('admin/events', 'post', params, true)
      .then((data) => dispatch(postEventSuccess(data)))
      .catch((error) => dispatch(postEventFailure(error)));
  };
};

const editEventRequest = () => ({
  type: 'EDIT_EVENT_ADMIN_REQUEST'
});

const editEventSuccess = (data) => ({
  type: 'EDIT_EVENT_ADMIN_SUCCESS',
  payload: data
});

const editEventFailure = (error) => ({
  type: 'EDIT_EVENT_ADMIN_FAILURE',
  payload: error
});

const editEvent = (id, params) => {
  return (dispatch) => {
    dispatch(editEventRequest());
    return apiCaller('admin/events/' + id, 'put', params, true)
      .then((data) => dispatch(editEventSuccess(data)))
      .catch((error) => dispatch(editEventFailure(error)));
  };
};

const deleteEventRequest = () => ({
  type: 'DELETE_EVENT_ADMIN_REQUEST'
});

const deleteEventSuccess = (data) => ({
  type: 'DELETE_EVENT_ADMIN_SUCCESS',
  payload: data
});

const deleteEventFailure = (error) => ({
  type: 'DELETE_EVENT_ADMIN_FAILURE',
  payload: error
});

const deleteEvent = (id) => {
  return (dispatch) => {
    dispatch(deleteEventRequest());
    return apiCaller('admin/events/' + id, 'delete', '', true)
      .then((data) => dispatch(deleteEventSuccess(data)))
      .catch((error) => dispatch(deleteEventFailure(error)));
  };
};

export { getEvents, postEvent, deleteEvent, editEvent, getEventDetail };
