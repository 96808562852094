import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';
import Dropdown from '../../../../components/Dropdown';
import { parseQuery } from '../../../../helpers/functions';

import { passport_service } from './functions';

import './style.scss';

const Schedule = ({ history, i18n }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [totalFamily, setTotalFamily] = useState();
  const [error, setError] = useState();

  const { location } = history;
  const { search } = location;
  const searchQuery = parseQuery(search) || {};
  const { type } = searchQuery;

  const passport_type = [
    { label: i18n('form.type.individu'), value: 'individu' },
    { label: i18n('form.type.family'), value: 'family' }
  ];

  const numberoffamity_options = [
    { label: i18n('passpor_schedule.num_fam', { num: 1 }), value: 1 },
    { label: i18n('passpor_schedule.num_fam', { num: 2 }), value: 2 },
    { label: i18n('passpor_schedule.num_fam', { num: 3 }), value: 3 },
    { label: i18n('passpor_schedule.num_fam', { num: 4 }), value: 4 },
    { label: i18n('passpor_schedule.num_fam', { num: 5 }), value: 5 }
  ];

  const handleSubmit = () => {
    if (selectedType) {
      let isValid = true;
      if (selectedType === 'family') {
        if (!totalFamily) {
          isValid = false;
          setError({ num_fam: i18n('passpor_schedule.required') });
        }
      }

      if (isValid) {
        history.push(
          `/passpor/schedule/form${search}${
            totalFamily ? `&num=${totalFamily.value}` : ''
          }`
        );
      }
    } else {
      setError({ type: i18n('passpor_schedule.required') });
    }
  };

  useEffect(() => {
    if (!type) {
      history.push('/passpor');
    }
  }, [history, type]);

  useEffect(() => {
    if (!showModal) {
      setSelectedType();
      setTotalFamily();
    }
  }, [showModal]);

  return (
    <>
      <Banner
        title={
          passport_service[type] && i18n(`paspor_page.${passport_service[type].title}`)
        }
        subtitle={
          <>
            <div className='text'>{i18n('paspor_page.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('paspor_page.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a
                href='https://peduliwni.kemlu.go.id/beranda.html'
                target='_blank'
                without
                rel='noopener noreferrer'
              >
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      <div className='container'>
        <CardDetail
          title={i18n('passpor_schedule.title')}
          description={i18n('passpor_schedule.subtile')}
          className='schedule'
        >
          <div
            className='instruction'
            dangerouslySetInnerHTML={{ __html: i18n('passpor_schedule.instruction') }}
          />
          <div className='schedule-container'>
            <div className='book-now-container'>
              <div className='book-now-wrap'>
                <div className='text-wrap'>
                  <div className='opening'>
                    {i18n('passpor_schedule.make_appointment')}
                  </div>
                  <div className='type'>
                    {i18n(
                      `paspor_page.${
                        passport_service[type] && passport_service[type].title
                      }`
                    )}
                  </div>
                </div>
                <Button color='primary' onClick={() => setShowModal(true)}>
                  {i18n('passpor_schedule.cta_book_now')}
                </Button>
              </div>
              <div className='check' onClick={() => history.push('/rates')}>
                <Icon icon='link' />
                {i18n('passpor_schedule.fee_konsuler')}
              </div>
              <div className='konsuler-address-container'>
                <div className='phone'>+1 212-879-0600 to 0604</div>
                <div className='email'>
                  <a href='mailto: consular.newyork@kemlu.go.id'>
                    consular.newyork@kemlu.go.id
                  </a>
                </div>
                <div className='address'>
                  East 68th Street, 5, Manhattan Community Board 8, US, 10065
                </div>
                <div className='website'>
                  <a
                    href='https://kemlu.go.id/newyork/id'
                    target='_blank'
                    without
                    rel='noopener noreferrer'
                  >
                    kemlu.go.id/newyork/id
                  </a>
                </div>
              </div>
            </div>
          </div>
        </CardDetail>
      </div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className='confirm schedule'
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: i18n('passpor_schedule.choose_time'),
            onClick: () => handleSubmit()
          }
        ]}
        title={
          <div className='title-wrap'>
            <div className='title'>{i18n('passpor_schedule.make_appointment')}</div>
            <div className='type'>
              {i18n(
                `paspor_page.${passport_service[type] && passport_service[type].title}`
              )}
            </div>
          </div>
        }
        desc={
          <div className='type-container'>
            <div className='instruction'>{i18n('passpor_schedule.book_for')}</div>
            <div className={`button-wrap ${get(error, ['type']) ? 'error' : ''}`}>
              {passport_type.map(({ label, value }, key) => (
                <Button
                  color={`${selectedType === value ? 'info' : 'grey'}`}
                  onClick={() => {
                    setSelectedType(value);
                    setTotalFamily();
                    setError();
                  }}
                  key={key}
                >
                  {label}
                </Button>
              ))}
              {get(error, ['type']) && (
                <div className='error-message'>{get(error, ['type'])}</div>
              )}
            </div>
            {selectedType === 'family' && (
              <div className='family-container'>
                <div className='instruction'>{i18n('passpor_schedule.total_fam')}</div>
                <Dropdown
                  className='row input-container'
                  placeholder={i18n('form.numberOfFamily.placeholder')}
                  value={totalFamily}
                  options={numberoffamity_options}
                  onClick={(item) => {
                    setTotalFamily(item);
                    setError();
                  }}
                  dropdownToggleProps={{
                    tag: 'div',
                    className: 'input-dropdown withoutAutocomplete'
                  }}
                  isValueObject
                  errorMessage={get(error, ['num_fam'])}
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

Schedule.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func
};

export default withRouter(Schedule);
