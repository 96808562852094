import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const CheckBox = ({
  label,
  errorMessage,
  className = '',
  checked = false,
  onChange,
  disabled
}) => {
  return (
    <div
      className={`checkbox-input-container ${className} ${disabled ? 'disabled' : ''} ${
        errorMessage ? 'err' : ''
      }`}
    >
      <label className={'checkbox-container'}>
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className={`checkbox-style ${disabled ? 'disabled' : ''}`} />
        {label}
      </label>
      {errorMessage && <div className='err-message'>{errorMessage}</div>}
    </div>
  );
};

CheckBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default CheckBox;
