const antrian = {
  data: {},
  status: 'idle',
  error: ''
};

const statisticDate = {
  data: {},
  status: 'idle',
  error: ''
};

const statisticWeek = {
  data: {},
  status: 'idle',
  error: ''
};

const statisticMonth = {
  data: {},
  status: 'idle',
  error: ''
};

const statisticAntrian = {
  data: {},
  status: 'idle',
  error: ''
};

const ubahJadwalAntrian = {
  data: {},
  status: 'idle',
  error: ''
};

const jadwalAntrian = {
  data: {},
  status: 'idle',
  error: ''
};

const holidayAntrian = {
  data: {},
  status: 'idle',
  error: ''
};

const exportAntrian = {
  data: {},
  status: 'idle',
  error: ''
};

const patchAntrian = {
  data: {},
  status: 'idle',
  error: ''
};

const antrianReducerAdmin = (state = antrian, action) => {
  switch (action.type) {
    case 'GET_ANTRIAN_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_ANTRIAN_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_ANTRIAN_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const antrianStatisticDayReducerAdmin = (state = statisticDate, action) => {
  switch (action.type) {
    case 'GET_ANTRIAN_STATISTIC_DAY_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_ANTRIAN_STATISTIC_DAY_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_ANTRIAN_STATISTIC_DAY_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const antrianStatisticWeekReducerAdmin = (state = statisticWeek, action) => {
  switch (action.type) {
    case 'GET_ANTRIAN_STATISTIC_WEEK_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_ANTRIAN_STATISTIC_WEEK_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_ANTRIAN_STATISTIC_WEEK_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const antrianStatisticMonthReducerAdmin = (state = statisticMonth, action) => {
  switch (action.type) {
    case 'GET_ANTRIAN_STATISTIC_MONTH_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_ANTRIAN_STATISTIC_MONTH_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_ANTRIAN_STATISTIC_MONTH_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const antrianStatisticAntrianReducerAdmin = (state = statisticAntrian, action) => {
  switch (action.type) {
    case 'GET_ANTRIAN_STATISTIC_ANTRIAN_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_ANTRIAN_STATISTIC_ANTRIAN_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_ANTRIAN_STATISTIC_ANTRIAN_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const ubahJadwalAntrianReducerAdmin = (state = ubahJadwalAntrian, action) => {
  switch (action.type) {
    case 'POST_UBAH_JADWAL_ANTRIAN_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_UBAH_JADWAL_ANTRIAN_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_UBAH_JADWAL_ANTRIAN_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const jadwalAntrianReducerAdmin = (state = jadwalAntrian, action) => {
  switch (action.type) {
    case 'GET_JADWAL_ANTRIAN_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_JADWAL_ANTRIAN_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_JADWAL_ANTRIAN_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const holidayAntrianReducerAdmin = (state = holidayAntrian, action) => {
  switch (action.type) {
    case 'POST_HOLIDAY_ANTRIAN_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_HOLIDAY_ANTRIAN_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_HOLIDAY_ANTRIAN_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const exportAntrianReducerAdmin = (state = exportAntrian, action) => {
  switch (action.type) {
    case 'GET_EXPORT_ANTRIAN_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXPORT_ANTRIAN_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXPORT_ANTRIAN_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchAntrianReducerAdmin = (state = patchAntrian, action) => {
  switch (action.type) {
    case 'PATCH_ANTRIAN_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_ANTRIAN_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_ANTRIAN_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export {
  antrianReducerAdmin,
  antrianStatisticDayReducerAdmin,
  antrianStatisticWeekReducerAdmin,
  antrianStatisticMonthReducerAdmin,
  antrianStatisticAntrianReducerAdmin,
  ubahJadwalAntrianReducerAdmin,
  jadwalAntrianReducerAdmin,
  holidayAntrianReducerAdmin,
  exportAntrianReducerAdmin,
  patchAntrianReducerAdmin
};
