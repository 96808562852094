import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';

const TradeImpor = ({ i18n, history }) => {
  const lang = (type, params) => i18n(`economy.tab4.${type}`, { ...params });

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />
          <Button
            color='primary'
            className='mtop16'
            onClick={() => history.push('/economy/trade-inquiry/form')}
          >
            {lang('step.cta1')}
          </Button>

          <div
            className='mtop16'
            dangerouslySetInnerHTML={{ __html: lang('step.for_further') }}
          />
          <a
            className='the-btn mtop16 btn btn-third'
            href='https://intr.insw.go.id/'
            target='_blank'
            without
            rel='noopener noreferrer'
            style={{ width: 'max-content' }}
          >
            {lang('step.cta2')}
          </a>
        </div>
      </main>
    </div>
  );
};

TradeImpor.propTypes = {
  i18n: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(TradeImpor);
