import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';

import { getStatementServiceId } from '../../../form/StatementLetterForm/functions';

const Birth = ({ i18n, statementServiceList }) => {
  const lang = (type, params) => i18n(`statement_letter.tab6.${type}`, { ...params });
  const serviceId = getStatementServiceId(statementServiceList, 'move');

  const stepOuterList = [
    {
      label: i18n('statement_letter.step.report_self.title')
    },
    {
      label: i18n('statement_letter.step.take_number.title'),
      cta: i18n('statement_letter.step.take_number.cta'),
      link: `/statement-letter/registration/create?type=statement&serviceId=${serviceId}`
    }
  ];

  const instructionList = [
    i18n('statement_letter.instruction_step.move_letter'),
    i18n('statement_letter.instruction_step.copy_passport_visa'),
    i18n('statement_letter.instruction_step.stuff_list'),
    i18n('statement_letter.instruction_step.letter_scholl_work'),
    i18n('statement_letter.instruction_step.stuff_letter')
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

Birth.propTypes = {
  i18n: PropTypes.func,
  statementServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Birth);
