const defaultData = (isObject) => ({
  data: isObject ? {} : [],
  status: 'idle',
  error: ''
});

const tourListReducer = (state = defaultData(), action) => {
  switch (action.type) {
    case 'GET_TOUR_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TOUR_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.tourList
      };
    case 'GET_TOUR_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.tourList.data.data
      };
    default:
      return state;
  }
};

const tourDetailReducer = (state = defaultData(true), action) => {
  switch (action.type) {
    case 'GET_TOUR_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TOUR_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.tourDetail
      };
    case 'GET_TOUR_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.tourDetail.data.data
      };
    default:
      return state;
  }
};

export { tourListReducer, tourDetailReducer };
