import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';
import Loading from '../../../components/Loading';

import Birth from './Birth';
import Married from './Married';
import Death from './Death';
import Death2 from './Death2';
import Domisili from './Domisili';
import Move from './Move';

import { getStatementServiceList } from '../../form/StatementLetterForm/action';

const StatementLetter = ({
  history,
  i18n,
  statementService,
  getStatementServiceList
}) => {
  const statementServiceList = get(statementService, ['data']);
  const serviceStatus = get(statementService, ['status']);
  const loadingService = serviceStatus !== 'resolve';
  const sideBarList = [
    {
      label: i18n('statement_letter.tab1.title'),
      link: '/statement-letter',
      Component: Birth
    },
    {
      label: i18n('statement_letter.tab2.title'),
      link: '/statement-letter/married',
      Component: Married
    },
    {
      label: i18n('statement_letter.tab3.title'),
      link: '/statement-letter/death',
      Component: Death
    },
    {
      label: i18n('statement_letter.tab4.title'),
      link: '/statement-letter/death-2',
      Component: Death2
    },
    {
      label: i18n('statement_letter.tab5.title'),
      link: '/statement-letter/domisili',
      Component: Domisili
    },
    {
      label: i18n('statement_letter.tab6.title'),
      link: '/statement-letter/move',
      Component: Move
    }
  ];

  // active tab
  const activePath = get(history, ['location', 'pathname']);

  useEffect(() => {
    getStatementServiceList();
  }, [getStatementServiceList]);

  return (
    <>
      <Banner
        title={i18n('statement_letter.banner.title')}
        subtitle={
          <>
            <div className='text'>{i18n('statement_letter.banner.subtitle')}</div>
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: i18n('statement_letter.banner.desc') }}
            />
          </>
        }
        buttonProps={[
          {
            btnStyle: { color: 'primary' },
            label: (
              <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                {i18n('cta.report_self')}
              </a>
            )
          }
        ]}
        backButtonClick={() => history.goBack()}
      />
      {loadingService && <Loading />}
      <CardSideBar
        activePath={activePath}
        sideBarList={sideBarList}
        statementServiceList={statementServiceList}
      />
    </>
  );
};

StatementLetter.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  statementService: PropTypes.object,
  getStatementServiceList: PropTypes.func
};

const mapStateToProps = (state) => ({
  statementService: {
    status: state.statementServiceReducer.status,
    error: state.statementServiceReducer.error,
    data: state.statementServiceReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getStatementServiceList }),
  withRouter
)(StatementLetter);
