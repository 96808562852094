import { apiCaller } from '../../../../helpers/apiCaller';

// Number regis passport
const getTradeQuestionRequest = () => ({
  type: 'GET_TRADE_QUESTION_REQUEST'
});

const getTradeQuestionSuccess = (data) => ({
  type: 'GET_TRADE_QUESTION_SUCCESS',
  payload: data
});

const getTradeQuestionFailure = (error) => ({
  type: 'GET_TRADE_QUESTION_FAILURE',
  payload: error
});

const postTradeQuestion = (params) => {
  return (dispatch) => {
    dispatch(getTradeQuestionRequest());
    return apiCaller('trade/question', 'post', params)
      .then((data) => dispatch(getTradeQuestionSuccess(data)))
      .catch((error) => dispatch(getTradeQuestionFailure(error)));
  };
};

const resetPostData = () => {
  const defaultData = {
    data: {},
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_POST_DATA',
      data: defaultData
    });
  };
};

export { postTradeQuestion, resetPostData };
