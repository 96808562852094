export default {
  listServe: 'List Serve',
  alumniBSBI: 'ALUMNI PROGRAM DARMASISWA DAN BEASISWA SENI BUDAYA INDONESIA (BSBI)',
  gamelan: 'Workshop Gamelan Bali',
  bahasa: 'Kelas Bahasa Indonesia',
  research: 'IZIN PENELITIAN',
  rppk: 'Pembuatan RPPK PNS bagi Karyasiswa',
  pns: 'Surat Akhir Studi untuk PNS',
  graduate: 'Surat Keterangan Lulus Sekolah/Universitas',
  iacs: 'Darmasiswa Program and Indonesian Arts and Culture Scholarship (IACS) Alumni',
  transferSchool: 'Surat Pindah Sekolah'
};
