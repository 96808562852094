import React from 'react';
import placeIcon from '../../../assets/img/homepage/place_24px.svg';
import './style.scss';

const TourThumbnail = ({ data, history }) => {
  const { title, image_url, place, _id } = data || {};

  return (
    <div
      className='tour-thumbnail'
      style={{ backgroundImage: `url(${image_url})` }}
      onClick={() => history.push(`/tourism/detail/${_id}`)}
    >
      <div className='tour-thumbnail-info'>
        <div className='tour-thumbnail-title'>{title}</div>
        <div className='tour-thumbnail-time' style={{ marginBottom: 4 }}>
          <img
            src={placeIcon}
            alt='activity-thumbnail-place'
            style={{ marginRight: 5 }}
          />
          {place}
        </div>
      </div>
    </div>
  );
};

export default TourThumbnail;
