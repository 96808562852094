import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { Alert } from 'reactstrap';

import downloadImg from '../../../assets/img/download.svg';

import { errorAlertScroll } from '../../../helpers/functions';
import { postFile, resetFileData } from './action';

import './style.scss';

const FileInput = ({
  label,
  sublabel,
  errorMessage,
  className = '',
  disable = false,
  type,
  postFile,
  fileUploaded,
  setFileInput
}) => {
  const [file, setFile] = useState({});
  const [alertDetail, setAlertDetail] = useState({});
  const [visible, setVisible] = useState(false);

  const img = (type === 'img' && get(file, ['base64'])) || get(file, ['url']);
  const { data, error: errorUpload, status } = fileUploaded;

  const handlerChanges = (e) => {
    const tmpFile = {};
    const file = e.target.files[0];
    tmpFile.file = file;

    var reader = new FileReader();
    reader.onloadend = () => {
      tmpFile.base64 = reader.result;
      setFile(tmpFile);
    };
    reader.readAsDataURL(file);
  };

  const handlerSubmit = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append('file', file.file);

    // Request made to the backend api
    // Send formData object
    postFile(formData);
  };

  const handlerAlert = (data) => {
    setVisible(true);
    setAlertDetail(data);
    setTimeout(() => {
      setVisible(false);
    }, 5000);
    errorAlertScroll();
  };

  useEffect(() => {
    return () => {
      resetFileData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 'resolve' && !file.url) {
      setFile({ ...data.data });
      setFileInput(get(data, ['data', 'url']));
      handlerAlert({
        color: 'success',
        message: 'Photo success uploaded. Click continue to continue your registration'
      });
    } else if (status === 'rejected') {
      const message = get(errorUpload, ['response', 'data', 'error', 'message']);
      handlerAlert({
        color: 'danger',
        message
      });
    }
  }, [data, errorUpload, file.url, setFileInput, status]);

  return (
    <div
      className={`input-container ${className} ${errorMessage ? 'err' : ''} ${
        disable ? 'disable' : ''
      }`}
    >
      {label && (
        <div className='label'>
          {label}
          {sublabel && <div className='sublabel'>{sublabel}</div>}
        </div>
      )}
      <div className='input-wrap'>
        <Alert color={get(alertDetail, ['color'])} isOpen={visible}>
          {get(alertDetail, ['message'])}
        </Alert>
        <input className='file-input' type='file' onChange={handlerChanges} />
        {status !== 'pending' ? (
          <div className='input-group'>
            <div className='upload-button' onClick={handlerSubmit}>
              <img src={downloadImg} alt='download-icon' />
              Upload File
            </div>
          </div>
        ) : (
          'Loading...'
        )}
      </div>

      {type === 'img' && (
        <div className='photo-preview'>
          {img ? <img src={img} alt='img-preview' /> : <div className='empty-img' />}
        </div>
      )}
    </div>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  sublabel: PropTypes.any,
  disable: PropTypes.bool,
  postFile: PropTypes.func
};

const mapStateToProps = (state) => ({
  fileUploaded: {
    status: state.fileReducer.status,
    error: state.fileReducer.error,
    data: state.fileReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postFile
  })
)(FileInput);
