import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '../../../../components/Button';
import TextInputNoob from '../../../../components/input/TextInputNoob';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { postUser } from '../action';
import './style.scss';

const CreateUser = ({ history, postUser, newUser }) => {
  const [email, setEmailValue] = useState('');
  const [username, setUsername] = useState('');
  const [nama_lengkap, setNamaLengkap] = useState('');
  const [role, setRoleValue] = useState('605f02e091c1fb000a4389a7');
  const [status, setStatusValue] = useState('1');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [usernameFlag, setUsernameFlag] = useState(false);

  const defaultForm = {
    email: email,
    username: username,
    name: nama_lengkap,
    group: role,
    password: password,
    division: 'KJRI Admin',
    confirmPassword: passwordConfirm,
    user_permit: []
  };

  const onChangeUsername = (value) => {
    setUsername(value);
    if (username.length <= 5) {
      setUsernameFlag(true);
    } else {
      setUsernameFlag(false);
    }
  };

  const submitUser = () => {
    if (password !== passwordConfirm || password.length < 8) {
      setPasswordFlag(true);
    } else {
      postUser(defaultForm);
    }
  };

  if (newUser.status === 'resolve') {
    window.location = '/admin/users';
  }

  return (
    <div className='create-user'>
      <span className='back-homepage' onClick={() => history.push('/admin/users')}>
        <span className='btn-back' />
        TAMBAH USER
      </span>
      <div className='content'>
        <span style={{ height: 10, display: 'inline-block' }} />
        <div className='top-row'>
          <div className='top-row-container'>
            <TextInputNoob
              label='Email *'
              action={setEmailValue}
              placeholder='Masukkan Email'
            />
          </div>
          <div className='top-row-container'>
            <label>Role *</label>
            <select name='role' id='role' onChange={(e) => setRoleValue(e.target.value)}>
              <option value='605f02e091c1fb000a4389a7'>Admin</option>
              <option value='605f02cc91c1fb000a4389a5'>Super Admin</option>
            </select>
          </div>
        </div>
        <div className='top-row'>
          <div className='top-row-container'>
            <TextInputNoob
              label='Nama Lengkap *'
              action={setNamaLengkap}
              placeholder='Masukkan Nama Lengkap'
            />
          </div>
          <div className='top-row-container'>
            <TextInputNoob
              label='Username *'
              action={onChangeUsername}
              placeholder='Masukkan Username'
            />
          </div>
        </div>
        <div className='top-row-container' style={{ display: `none` }}>
          <label>Status *</label>
          <select
            name='status'
            id='status'
            onChange={(e) => setStatusValue(e.target.value)}
          >
            <option value='1'>Active</option>
            <option value='2'>Non Active</option>
          </select>
        </div>
        <span style={{ height: 5, display: 'inline-block' }} />
        <label>Password *</label>
        <input
          type='password'
          className='password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <span style={{ height: 5, display: 'inline-block' }} />
        <label>Konfirmasi Password *</label>
        <input
          type='password'
          className='password'
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        {passwordFlag === true ? (
          <span style={{ color: 'red' }}>
            * Password tidak sesuai atau password kurang dari 8 karakter
          </span>
        ) : (
          ''
        )}
        {usernameFlag === true ? (
          <div style={{ color: 'red' }}>* Username harus lebih dari 5 karakter</div>
        ) : (
          ''
        )}
        <span style={{ height: 5, display: 'inline-block' }} />
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => submitUser()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

CreateUser.propTypes = {
  history: PropTypes.object,
  newUser: PropTypes.object,
  postUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  newUser: {
    status: state.newUserReducerAdmin.status,
    error: state.newUserReducerAdmin.error,
    data: state.newUserReducerAdmin.data
  }
});

export default compose(connect(mapStateToProps, { postUser }), withRouter)(CreateUser);
