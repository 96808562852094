import React from 'react';
import { Button as ButtonStrap } from 'reactstrap';
import './style.scss';

const Button = (props) => {
  return (
    <ButtonStrap {...props} className={`the-btn ${props.className || ''}`}>
      {props.children}
    </ButtonStrap>
  );
};

export default Button;
