const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getDocLegalListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DOC_LEGAL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DOC_LEGAL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DOC_LEGAL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_DOC_LEGAL_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getDocLegalStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DOC_LEGAL_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DOC_LEGAL_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DOC_LEGAL_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getDocLegalStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DOC_LEGAL_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DOC_LEGAL_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DOC_LEGAL_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getDocLegalStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DOC_LEGAL_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DOC_LEGAL_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DOC_LEGAL_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getDocLegalStatisticAllReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_DOC_LEGAL_STATISTIC_ALL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DOC_LEGAL_STATISTIC_ALL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_DOC_LEGAL_STATISTIC_ALL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const patchAction = {
  data: {},
  status: 'idle',
  error: ''
};

const patchDocLegalApproveReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_DOC_LEGAL_APPROVE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_DOC_LEGAL_APPROVE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_DOC_LEGAL_APPROVE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchDocLegalRejectReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_DOC_LEGAL_REJECT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_DOC_LEGAL_REJECT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_DOC_LEGAL_REJECT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchDocLegalProcessReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_DOC_LEGAL_PROCESS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_DOC_LEGAL_PROCESS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_DOC_LEGAL_PROCESS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchDocLegalPendingReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_DOC_LEGAL_PENDING_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_DOC_LEGAL_PENDING_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_DOC_LEGAL_PENDING_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export {
  getDocLegalListReducer,
  getDocLegalStatisticReducer,
  getDocLegalStatisticWeekReducer,
  getDocLegalStatisticMonthReducer,
  getDocLegalStatisticAllReducer,
  patchDocLegalApproveReducer,
  patchDocLegalRejectReducer,
  patchDocLegalProcessReducer,
  patchDocLegalPendingReducer
};
