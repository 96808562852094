import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../components/Button';
import DatepickerRange from '../components/DatepickerRange';
import Content from './components/MainContent';

import {
  getLetterList,
  resetLetterList,
  getLetterStatistic,
  getLetterStatisticWeek,
  getLetterStatisticMonth,
  getLetterStatisticAll
} from './action';
import { getStatementServiceList } from '../../form/StatementLetterForm/action';
import { getStatementServiceId } from '../../form/StatementLetterForm/functions';
import statementServiceTitle from '../../../constant/statementLetterService';
import { getStatisticDate, downloadFile } from '../functions';

const AdminLetter = ({
  getLetterList,
  passportList,
  getStatementServiceList,
  statementService,
  resetLetterList,
  getLetterStatistic,
  getLetterStatisticWeek,
  getLetterStatisticMonth,
  getLetterStatisticAll,
  letterStatisticAll,
  letterStatistic,
  letterStatisticWeek,
  letterStatisticMonth
}) => {
  const { type } = useParams();

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [activeTab, setActiveTab] = useState('1');
  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();
  const statusTabList = {
    '1': 'REGISTERED',
    '2': 'PROCESS',
    '3': 'APPROVED',
    '4': 'REJECTED'
  };

  const title = statementServiceTitle[type];
  const passportListData = get(passportList, ['data', 'data']) || [];
  const statementServiceList = get(statementService, ['data']) || [];
  const pagination = get(passportList, ['data', 'pagination']) || {};

  const statementServiceId = getStatementServiceId(statementServiceList, type);
  const loading =
    get(statementService, ['status']) === 'pending' ||
    get(passportList, ['status']) === 'pending';

  const getAllStatistic = () => {
    const allStatistic = get(letterStatisticAll, ['data']) || [];
    const summaryData = {
      numOfIn: String(0),
      numOfProcess: String(0),
      numOfFinish: String(0),
      numOfPending: String(0)
    };
    allStatistic.map(({ status, total }) => {
      switch (status) {
        case statusTabList['1']:
          summaryData.numOfIn = String(total);
          break;
        case statusTabList['2']:
          summaryData.numOfProcess = String(total);
          break;
        case statusTabList['3']:
          summaryData.numOfFinish = String(total);
          break;
        case statusTabList['4']:
          summaryData.numOfPending = String(total);
          break;
      }
    });
    return summaryData;
  };

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0),
        numOfProcess: String(0),
        numOfFinish: String(0)
      }
    ];
    const dayStatistic = get(letterStatistic, ['data']) || [];
    const weekStatistic = get(letterStatisticWeek, ['data']) || [];
    const monthStatistic = get(letterStatisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ status, total }) => {
        switch (status) {
          case statusTabList['1']:
            summaryData[index].numOfIn = String(total);
            break;
          case statusTabList['2']:
            summaryData[index].numOfProcess = String(total);
            break;
          case statusTabList['3']:
            summaryData[index].numOfFinish = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const loadData = () => {
    const params = {
      letter_service: statementServiceId,
      status: statusTabList[activeTab],
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getLetterList(params);
  };

  const loadStatisticData = (isAllOnly) => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();
    if (isAllOnly) {
      getLetterStatisticAll({
        letter_service: statementServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    } else {
      getLetterStatistic({
        letter_service: statementServiceId,
        from: today,
        to: today
      });
      getLetterStatisticWeek({
        letter_service: statementServiceId,
        from: startWeek,
        to: endWeek
      });
      getLetterStatisticMonth({
        letter_service: statementServiceId,
        from: startMonth,
        to: endMonth
      });
      getLetterStatisticAll({
        letter_service: statementServiceId,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD')
      });
    }
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      letter_service: statementServiceId
    };
    const fileName = `statement-letter-${title}-${params.from}-${params.to}`;
    downloadFile('statement-letter', params, fileName);
  };

  useEffect(() => {
    getStatementServiceList();
    return () => resetLetterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statementServiceId]);

  useEffect(() => {
    resetLetterList();

    if (statementServiceId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pageDivider, page, query, statementServiceId]);

  useEffect(() => {
    setActiveTab('1');
    setPage(1);
    setPageDivider(5);
    setQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Content
      title={title}
      dataTab={passportListData}
      summaryData={getSummaryData()}
      type={type}
      loading={loading}
      onTabChanges={setActiveTab}
      onPageChanges={setPage}
      onPageDividerChanges={setPageDivider}
      onQueryChanges={setQuery}
      defaultActiveTab={activeTab}
      pagination={pagination}
      loadData={() => {
        loadData();
        loadStatisticData();
      }}
      allStatistic={getAllStatistic()}
      topActionComponent={
        <div className='detail-action' style={{ display: 'flex' }}>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={() => {
              loadData();
              loadStatisticData(true);
            }}
          />
          <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
            Download
          </Button>
        </div>
      }
    />
  );
};

AdminLetter.propTypes = {
  getLetterList: PropTypes.func,
  passportList: PropTypes.object,
  getStatementServiceList: PropTypes.func,
  statementService: PropTypes.object,
  resetLetterList: PropTypes.func,
  getLetterStatistic: PropTypes.func,
  letterStatistic: PropTypes.object,
  getLetterStatisticWeek: PropTypes.func,
  getLetterStatisticAll: PropTypes.func,
  letterStatisticAll: PropTypes.object
};

const mapStateToProps = (state) => ({
  passportList: {
    status: state.getLetterListReducer.status,
    error: state.getLetterListReducer.error,
    data: state.getLetterListReducer.data
  },
  statementService: {
    status: state.statementServiceReducer.status,
    error: state.statementServiceReducer.error,
    data: state.statementServiceReducer.data
  },
  letterStatistic: {
    status: state.getLetterStatisticReducer.status,
    error: state.getLetterStatisticReducer.error,
    data: state.getLetterStatisticReducer.data
  },
  letterStatisticWeek: {
    status: state.getLetterStatisticWeekReducer.status,
    error: state.getLetterStatisticWeekReducer.error,
    data: state.getLetterStatisticWeekReducer.data
  },
  letterStatisticMonth: {
    status: state.getLetterStatisticMonthReducer.status,
    error: state.getLetterStatisticMonthReducer.error,
    data: state.getLetterStatisticMonthReducer.data
  },
  letterStatisticAll: {
    status: state.getLetterStatisticAllReducer.status,
    error: state.getLetterStatisticAllReducer.error,
    data: state.getLetterStatisticAllReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getLetterList,
    getStatementServiceList,
    resetLetterList,
    getLetterStatistic,
    getLetterStatisticWeek,
    getLetterStatisticMonth,
    getLetterStatisticAll
  }),
  withRouter
)(AdminLetter);
