const printPreview = (selector) => {
  let toPrint = document.querySelector(selector);
  let popupWin = window.open('', '_blank', 'width=700,height=700,location=no,left=200px');
  popupWin.document.open();
  popupWin.document.write(printPreviewNumber());
  popupWin.document.write(toPrint.innerHTML);
  popupWin.document.write('</html>');
  popupWin.document.close();
};

const printPreviewNumber = () => {
  return `<html>
  <title>Register Number Preview</title>
  <style>
  @media print /*--This is for Print--*/
  {
    body {
      font-family: 'Roboto', sans-serif!important;
      padding: 24px;
    }

    .header {
      padding: 24px 0;
      border-bottom: 1px solid #DDDDDD;
    }
  
    .header .title {
      color: #053960;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    .note {
      padding: 24px 0;
      padding-bottom: 0;
      font-size: 16px;
      line-height: 24px;
      color: #053960;
    }

    .review-wrap .number-item {
      padding: 24px 0;
    }

    .review-wrap .number-item .item {
      margin-bottom: 16px;
    }

    .review-wrap .number-item .label {
      font-size: 18px;
      line-height: 24px;
      color: #666666;
      margin-bottom: 8px;
    }

    .review-wrap .number-item .text {
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      color: #111111;
    }

    .btn-wrap {
      display: none;
    }
  }

  body {
    font-family: 'Roboto', sans-serif!important;
    padding: 24px;
  }

  .header {
    padding: 24px 0;
    border-bottom: 1px solid #DDDDDD;
  }

  .header .title {
    color: #053960;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  .note {
    padding: 24px 0;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: #053960;
  }

  .review-wrap .number-item {
    padding: 24px 0;
  }

  .review-wrap .number-item .item {
    margin-bottom: 16px;
  }

  .review-wrap .number-item .label {
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    margin-bottom: 8px;
  }

  .review-wrap .number-item .text {
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #111111;
  }

  .btn-wrap {
    display: none;
  }
  </style>
  </head>
  <body onload="window.print()">`;
};

export { printPreviewNumber, printPreview };
