import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MiniCard from '../../../../components/CardDetail/MiniCard';

import { apiCaller } from '../../../../helpers/apiCaller';
import { getPassportStatistic } from '../../Passport/action';
import { getPassportServiceList } from '../../../form/PassportForm/action';
import { getPassportServiceTitle } from '../../../form/PassportForm/functions';
import { setSummary } from '../functions';

const PassportDashboard = ({ getPassportServiceList, passportService, ...props }) => {
  const passportServiceList = get(passportService, ['data']) || [];

  useEffect(() => {
    getPassportServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = () =>
    passportServiceList.map((detail, idx) => (
      <PassportDetail
        detail={detail}
        {...props}
        passportServiceList={passportServiceList}
        key={idx}
      />
    ));

  return (
    <div className='statistic detail-container'>
      <div className='title'>Passport & SPLP</div>
      <div className='detail-wrap'>{renderDetails()}</div>
    </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
const PassportDetail = ({
  detail,
  history,
  startDate,
  endDate,
  reloadData,
  setReloadData,
  passportServiceList
}) => {
  const { title, id } = detail;
  const [statistic, setStatistic] = useState({
    numOfIn: String(0),
    numOfProcess: String(0),
    numOfFinish: String(0),
    numOfPending: String(0),
    numOfWaiting: String(0)
  });

  const loadData = () => {
    const params = {
      passport_service: id,
      from: startDate,
      to: endDate
    };

    apiCaller('admin/passport/statistic', 'get', undefined, true, params)
      .then((response) => {
        const statisticData = get(response, ['data', 'data']);
        const keyTitle = getPassportServiceTitle(passportServiceList, id);
        const summaryData = {
          title,
          subtitle: 'Document WNI',
          onClick: () => history.push(`/admin/passpor/${keyTitle}`),
          ...setSummary(statisticData)
        };
        setStatistic(summaryData);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const detailMiniCard = {
    title,
    subtitle: 'Document WNI',
    ...statistic
  };

  return <MiniCard detail={detailMiniCard} />;
};

PassportDetail.propTypes = {
  history: PropTypes.object,
  detail: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reloadData: PropTypes.bool,
  setReloadData: PropTypes.func,
  passportServiceList: PropTypes.array
};

PassportDashboard.propTypes = {
  history: PropTypes.object,
  getPassportServiceList: PropTypes.func,
  passportService: PropTypes.object,
  getPassportStatistic: PropTypes.func,
  passportStatistic: PropTypes.object
};

const mapStateToProps = (state) => ({
  passportService: {
    status: state.passportServiceReducer.status,
    error: state.passportServiceReducer.error,
    data: state.passportServiceReducer.data
  },
  passportStatistic: {
    status: state.getPassportStatisticReducer.status,
    error: state.getPassportStatisticReducer.error,
    data: state.getPassportStatisticReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getPassportServiceList,
    getPassportStatistic
  })
)(PassportDashboard);
