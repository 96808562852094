import { apiCaller } from '../../../helpers/apiCaller';

const getUsersRequest = () => ({
  type: 'GET_USERS_REQUEST'
});

const getUsersSuccess = (data) => ({
  type: 'GET_USERS_SUCCESS',
  payload: data
});

const getUsersFailure = (error) => ({
  type: 'GET_USERS_FAILURE',
  payload: error
});

const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersRequest());
    return apiCaller('admin/user', 'get', '', true)
      .then((data) => dispatch(getUsersSuccess(data)))
      .catch((error) => dispatch(getUsersFailure(error)));
  };
};

const postUserRequest = () => ({
  type: 'POST_USER_REQUEST'
});

const postUserSuccess = (data) => ({
  type: 'POST_USER_SUCCESS',
  payload: data
});

const postUserFailure = (error) => ({
  type: 'POST_USER_FAILURE',
  payload: error
});

const postUser = (params) => {
  return (dispatch) => {
    dispatch(postUserRequest());
    return apiCaller('admin/registration', 'post', params, true)
      .then((data) => dispatch(postUserSuccess(data)))
      .catch((error) => dispatch(postUserFailure(error)));
  };
};

const deleteUserRequest = () => ({
  type: 'DELETE_USER_REQUEST'
});

const deleteUserSuccess = (data) => ({
  type: 'DELETE_USER_SUCCESS',
  payload: data
});

const deleteUserFailure = (error) => ({
  type: 'DELETE_USER_FAILURE',
  payload: error
});

const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(deleteUserRequest());
    return apiCaller('admin/user/' + id, 'delete', undefined, true)
      .then((data) => dispatch(deleteUserSuccess(data)))
      .catch((error) => dispatch(deleteUserFailure(error)));
  };
};

const updateUserRequest = () => ({
  type: 'UPDATE_USER_REQUEST'
});

const updateUserSuccess = (data) => ({
  type: 'UPDATE_USER_SUCCESS',
  payload: data
});

const updateUserFailure = (error) => ({
  type: 'UPDATE_USER_FAILURE',
  payload: error
});

const updateUser = (id, data) => {
  return (dispatch) => {
    dispatch(updateUserRequest());
    return apiCaller('admin/profile', 'put', data, true)
      .then((data) => dispatch(updateUserSuccess(data)))
      .catch((error) => dispatch(updateUserFailure(error)));
  };
};

export { getUsers, postUser, deleteUser, updateUser };
