import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import './style.scss';

const Header = ({ theme, i18n, history }) => {
  return (
    <header className={theme}>
      <Container>
        <div className='logo' onClick={() => history.push('/')} />
        <div className='content'>
          <div className='pretitle'>{i18n('header.official_website')}</div>
          <div className='title'>{i18n('header.smart_consulate')}</div>
          <div className='title'>{i18n('header.kjri')}</div>
        </div>
        {/* <div className='right-col'>
          <span className='tag'>{i18n('header.individual')}</span>
          <span className='tag green' onClick={() => history.push('/admin/login')}>
            {i18n('header.business')}
          </span>
        </div> */}
      </Container>
    </header>
  );
};

Header.propTypes = {
  theme: PropTypes.oneOfType(['light', 'dark']),
  i18n: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(Header);
