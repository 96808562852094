import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Review from '../../components/NumberRegister/Review';

import { getQuery } from '../../../pages/Paspor/function';
import {
  getSosBudLetter,
  getSosBudServiceList,
  getSosBudLetterSuccess,
  resetDetailRegister
} from '../action';
import { getServiceTitle, sosBudType } from '../functions';
import { getOfficeList } from '../../NumberRegisterForm/action';

const RegisterReview = ({
  history,
  i18n,
  getSosBudLetter,
  letterRegisDetail = {},
  getSosBudServiceList,
  letterService,
  getOfficeList,
  offices,
  resetDetailRegister
}) => {
  const { uniqueId, type } = getQuery(history);
  const docLegalDetail = get(letterRegisDetail, ['data', 0]);
  const serviceId = get(docLegalDetail, ['service_id']);
  const officeId = get(docLegalDetail, ['state_id']);
  const keyTitle = getServiceTitle(get(letterService, ['data']), serviceId);
  const title = keyTitle && sosBudType[keyTitle] && i18n(`${sosBudType[keyTitle].label}`);
  const withModalSuccess =
    keyTitle && sosBudType[keyTitle] && (sosBudType[keyTitle].formPath ? false : true);
  const redirect = withModalSuccess
    ? '/pensosbud'
    : keyTitle &&
      sosBudType[keyTitle] &&
      `${sosBudType[keyTitle].formPath}?id=${uniqueId}`;

  const officeOptions = get(offices, ['data']);
  const officeDetail = officeOptions.find(({ id }) => id === officeId);

  const reviewDetail = {
    ...docLegalDetail,
    office: officeDetail
  };

  // form lulus, form pindah,last studi

  useEffect(() => {
    if (!uniqueId) {
      if (type) {
        history.push(`/${type}`);
      } else {
        history.push('/');
      }
    } else if (type && uniqueId) {
      getOfficeList();
      getSosBudServiceList();
      getSosBudLetter(uniqueId);
    }

    return () => {
      resetDetailRegister();
      getSosBudLetterSuccess({
        data: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNextStep = () => {
    if (keyTitle && sosBudType[keyTitle]) {
      const list = keyTitle && sosBudType[keyTitle].list;
      return (
        <>
          <ol>
            {list.map((listTitle, key) => (
              <li
                key={key}
                dangerouslySetInnerHTML={{
                  __html: i18n(`pensosbud.instruction_step.${listTitle}`)
                }}
              />
            ))}
          </ol>
          <div style={{ marginBottom: '16px' }}>{i18n('track_status_info')}</div>
        </>
      );
    }

    return null;
  };

  return (
    <Review
      i18n={i18n}
      reviewDetail={reviewDetail}
      title={title}
      nextDirection={redirect}
      serviceName={title}
      withModalSuccess={withModalSuccess}
      stepNextRegister={renderNextStep()}
    />
  );
};

RegisterReview.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  getSosBudLetter: PropTypes.func,
  letterRegisDetail: PropTypes.object,
  getSosBudServiceList: PropTypes.func,
  letterService: PropTypes.array,
  getOfficeList: PropTypes.func,
  offices: PropTypes.object,
  resetDetailRegister: PropTypes.func
};

const mapStateToProps = (state) => ({
  letterRegisDetail: {
    status: state.sosBudLetterDetailReducer.status,
    error: state.sosBudLetterDetailReducer.error,
    data: state.sosBudLetterDetailReducer.data
  },
  letterService: {
    status: state.sosBudServiceListReducer.status,
    error: state.sosBudServiceListReducer.error,
    data: state.sosBudServiceListReducer.data
  },
  offices: {
    status: state.officeListReducer.status,
    error: state.officeListReducer.error,
    data: state.officeListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudLetter,
    getSosBudServiceList,
    getOfficeList,
    resetDetailRegister
  }),
  withRouter
)(RegisterReview);
