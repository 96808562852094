import { getServiceTitleByName as getTitleLetter } from '../../form/StatementLetterForm/functions';
import { getServiceTitleByName as getTitleDocument } from '../../form/DocLegalizationForm/functions';
import { getServiceTitleByName as getTitleVisa } from '../../form/VisaForm/functions';
import { getServiceTitleByName as getTitlePassport } from '../../form/PassportForm/functions';
import { getServiceTitleByName as getTitlePensosbud } from '../../form/PensosbudForm/functions';

export const getTitle = (name, type, i18n) => {
  let titleKey;
  if (type === 'letter') {
    titleKey = getTitleLetter(name);
  } else if (type === 'document') {
    titleKey = getTitleDocument(name);
  } else if (type === 'visa') {
    titleKey = getTitleVisa(name, i18n);
  } else if (type === 'passport') {
    titleKey = getTitlePassport(name);
  } else if (type === 'pensosbud') {
    titleKey = getTitlePensosbud(name);
  }

  return titleKey ? i18n(titleKey) : name;
};
