import { apiCaller } from '../../../../helpers/apiCaller';

// INVESTMENT QUESTION
const getInvestmentQuestionListRequest = () => ({
  type: 'GET_INVESTMENT_QUESTION_LIST_REQUEST'
});

const getInvestmentQuestionListSuccess = (data) => ({
  type: 'GET_INVESTMENT_QUESTION_LIST_SUCCESS',
  payload: data
});

const getInvestmentQuestionListFailure = (error) => ({
  type: 'GET_INVESTMENT_QUESTION_LIST_FAILURE',
  payload: error
});

const resetInvestmentQuestionList = () => ({
  type: 'RESET_INVESTMENT_QUESTION_LIST_LIST'
});

const getInvestmentQuestionList = (params) => {
  return (dispatch) => {
    dispatch(getInvestmentQuestionListRequest());
    return apiCaller('admin/investment/question', 'get', undefined, true, params)
      .then((data) => dispatch(getInvestmentQuestionListSuccess(data)))
      .catch((error) => dispatch(getInvestmentQuestionListFailure(error)));
  };
};

// INVESTMENT question DETAIL
const getInvestmentQuestionDetailRequest = () => ({
  type: 'GET_INVESTMENT_QUESTION_DETAIL_REQUEST'
});

const getInvestmentQuestionDetailSuccess = (data) => ({
  type: 'GET_INVESTMENT_QUESTION_DETAIL_SUCCESS',
  payload: data
});

const getInvestmentQuestionDetailFailure = (error) => ({
  type: 'GET_INVESTMENT_QUESTION_DETAIL_FAILURE',
  payload: error
});

const resetInvestmentQuestionDetail = () => ({
  type: 'RESET_INVESTMENT_QUESTION_DETAIL_LIST'
});

const getInvestmentQuestionDetail = (id) => {
  return (dispatch) => {
    dispatch(getInvestmentQuestionDetailRequest());
    return apiCaller(`investment/question/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getInvestmentQuestionDetailSuccess(data)))
      .catch((error) => dispatch(getInvestmentQuestionDetailFailure(error)));
  };
};

// TRADE QUESTION
const getTradeQuestionListRequest = () => ({
  type: 'GET_TRADE_QUESTION_LIST_REQUEST'
});

const getTradeQuestionListSuccess = (data) => ({
  type: 'GET_TRADE_QUESTION_LIST_SUCCESS',
  payload: data
});

const getTradeQuestionListFailure = (error) => ({
  type: 'GET_TRADE_QUESTION_LIST_FAILURE',
  payload: error
});

const resetTradeQuestionList = () => ({
  type: 'RESET_TRADE_QUESTION_LIST_LIST'
});

const getTradeQuestionList = (params) => {
  return (dispatch) => {
    dispatch(getTradeQuestionListRequest());
    return apiCaller('admin/trade/question', 'get', undefined, true, params)
      .then((data) => dispatch(getTradeQuestionListSuccess(data)))
      .catch((error) => dispatch(getTradeQuestionListFailure(error)));
  };
};

// TRADE question DETAIL
const getTradeQuestionDetailRequest = () => ({
  type: 'GET_TRADE_QUESTION_DETAIL_REQUEST'
});

const getTradeQuestionDetailSuccess = (data) => ({
  type: 'GET_TRADE_QUESTION_DETAIL_SUCCESS',
  payload: data
});

const getTradeQuestionDetailFailure = (error) => ({
  type: 'GET_TRADE_QUESTION_DETAIL_FAILURE',
  payload: error
});

const resetTradeQuestionDetail = () => ({
  type: 'RESET_TRADE_QUESTION_DETAIL_LIST'
});

const getTradeQuestionDetail = (id) => {
  return (dispatch) => {
    dispatch(getTradeQuestionDetailRequest());
    return apiCaller(`trade/question/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getTradeQuestionDetailSuccess(data)))
      .catch((error) => dispatch(getTradeQuestionDetailFailure(error)));
  };
};

// DAY
const getStatisticRequest = () => ({
  type: 'GET_HUB_INDO_STATISTIC_REQUEST'
});

const getStatisticSuccess = (data) => ({
  type: 'GET_HUB_INDO_STATISTIC_SUCCESS',
  payload: data
});

const getStatisticFailure = (error) => ({
  type: 'GET_HUB_INDO_STATISTIC_FAILURE',
  payload: error
});

const getStatistic = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticRequest());
    return apiCaller(`admin/${type}/question/statistic`, 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticSuccess(data)))
      .catch((error) => dispatch(getStatisticFailure(error)));
  };
};

// WEEK
const getStatisticWeekRequest = () => ({
  type: 'GET_HUB_INDO_STATISTIC_WEEK_REQUEST'
});

const getStatisticWeekSuccess = (data) => ({
  type: 'GET_HUB_INDO_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getStatisticWeekFailure = (error) => ({
  type: 'GET_HUB_INDO_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getStatisticWeek = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticWeekRequest());
    return apiCaller(`admin/${type}/question/statistic`, 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getStatisticWeekFailure(error)));
  };
};

// MONTH
const getStatisticMonthRequest = () => ({
  type: 'GET_HUB_INDO_STATISTIC_MONTH_REQUEST'
});

const getStatisticMonthSuccess = (data) => ({
  type: 'GET_HUB_INDO_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getStatisticMonthFailure = (error) => ({
  type: 'GET_HUB_INDO_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getStatisticMonth = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticMonthRequest());
    return apiCaller(`admin/${type}/question/statistic`, 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getStatisticMonthFailure(error)));
  };
};

export {
  resetInvestmentQuestionList,
  getInvestmentQuestionList,
  resetInvestmentQuestionDetail,
  getInvestmentQuestionDetail,
  resetTradeQuestionList,
  getTradeQuestionList,
  resetTradeQuestionDetail,
  getTradeQuestionDetail,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
};
