import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import { getSosBudRegisterDetail } from '../../SosBudSK/action';

import './style.scss';

const DetailDarmasiswa = ({ history, registerDetail, getSosBudRegisterDetail }) => {
  const { id } = useParams();
  const data = get(registerDetail, ['data', 'data', 0]);

  const details = [
    { value: get(data, ['name']), label: 'Nama' },
    { value: get(data, ['scholarship_type']), label: 'Scholarship' },
    {
      value: `${get(data, ['scholarship_month'])} / ${get(data, ['scholarship_year'])}`,
      label: 'Scholarship Period (Month / Year)'
    },
    { value: get(data, ['study_city']), label: 'City studied in Indonesia' },
    {
      value: `${get(data, ['birth_city'])} ${moment(get(data, ['birth_date'])).format(
        'DD MMM YYYY'
      )}`,
      label: 'Tempat, Tanggal Lahir'
    },
    { value: get(data, ['job']), label: 'Occupation' },
    { value: get(data, ['phone']), label: 'Phone Number' },
    { value: get(data, ['email']), label: 'Email Address' },
    { value: get(data, ['address']), label: 'Street Address' }
  ];

  useEffect(() => {
    getSosBudRegisterDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='detail-pengaduan'>
      <span className='back-homepage' onClick={() => history.goBack()}>
        <span className='btn-back' />
        Detail Darmasiswa
      </span>
      <div className='detail-pengaduan-content'>
        <div className='title'>Detail Darmasiswa</div>
        <table>
          {details.map(({ label, value }, key) => (
            <tr key={key}>
              <td>{label}</td>
              <td>{value || '-'}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

DetailDarmasiswa.propTypes = {
  history: PropTypes.any,
  registerDetail: PropTypes.object,
  getSosBudRegisterDetail: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  registerDetail: {
    status: state.getSosBudRegisterDetailReducer.status,
    error: state.getSosBudRegisterDetailReducer.error,
    data: state.getSosBudRegisterDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudRegisterDetail
  }),
  withRouter
)(DetailDarmasiswa);
