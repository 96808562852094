import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';

import Medicine from './Medicine';
import Pet from './Pet';
import Tax from './Tax';

const Faq = ({ history, i18n }) => {
  const sideBarList = [
    {
      label: i18n('faq.tab1.title'),
      link: '/faq',
      Component: Medicine
    },
    {
      label: i18n('faq.tab2.title'),
      link: '/faq/pet',
      Component: Pet
    },
    {
      label: i18n('faq.tab3.title'),
      link: '/faq/tax',
      Component: Tax
    }
  ];

  // active tab
  const activePath = get(history, ['location', 'pathname']);

  return (
    <>
      <Banner
        title={i18n('faq.banner.title')}
        subtitle={
          <>
            <div className='text'>{i18n('faq.banner.subtitle')}</div>
          </>
        }
        backButtonClick={() => history.goBack()}
      />
      <CardSideBar activePath={activePath} sideBarList={sideBarList} />
    </>
  );
};

Faq.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func
};

export default withRouter(Faq);
