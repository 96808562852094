import { apiCaller } from '../../../helpers/apiCaller';
import get from 'lodash/get';

// Sosbud service
const getSosBudServiceListRequest = () => ({
  type: 'GET_SOSBUD_SERVICE_REQUEST'
});

const getSosBudServiceListSuccess = (data) => ({
  type: 'GET_SOSBUD_SERVICE_SUCCESS',
  sosBudService: data
});

const getSosBudServiceListFailure = (error) => ({
  type: 'GET_SOSBUD_SERVICE_FAILURE',
  sosBudService: error
});

const getSosBudServiceList = () => {
  return (dispatch) => {
    dispatch(getSosBudServiceListRequest());
    return apiCaller('pensosbud/service')
      .then((data) => dispatch(getSosBudServiceListSuccess(data)))
      .catch((error) => dispatch(getSosBudServiceListFailure(error)));
  };
};

// Register Letter Sosbud service
const postSosBudLetterRequest = () => ({
  type: 'POST_SOSBUD_LETTER_REQUEST'
});

const postSosBudLetterSuccess = (data) => ({
  type: 'POST_SOSBUD_LETTER_SUCCESS',
  sosBudLetter: data
});

const postSosBudLetterFailure = (error) => ({
  type: 'POST_SOSBUD_LETTER_FAILURE',
  sosBudLetter: error
});

const postSosBudLetter = (params) => {
  return (dispatch) => {
    dispatch(postSosBudLetterRequest());
    return apiCaller('pensosbud/letter', 'post', params)
      .then((data) => {
        if (get(data, ['response', 'data', 'status']) === 422) {
          return dispatch(postSosBudLetterFailure(data));
        }

        return dispatch(postSosBudLetterSuccess(data));
      })
      .catch((error) => dispatch(postSosBudLetterFailure(error)));
  };
};

// Register Education Sosbud
const postSosBudEducationRequest = () => ({
  type: 'POST_SOSBUD_EDUCATION_REQUEST'
});

const postSosBudEducationSuccess = (data) => ({
  type: 'POST_SOSBUD_EDUCATION_SUCCESS',
  sosBudEducation: data
});

const postSosBudEducationFailure = (error) => ({
  type: 'POST_SOSBUD_EDUCATION_FAILURE',
  sosBudEducation: error
});

const postSosBudEducation = (params) => {
  return (dispatch) => {
    dispatch(postSosBudEducationRequest());
    return apiCaller('pensosbud/education', 'post', params)
      .then((data) => dispatch(postSosBudEducationSuccess(data)))
      .catch((error) => dispatch(postSosBudEducationFailure(error)));
  };
};

// Register Alumni Sosbud
const postSosBudAlumniRequest = () => ({
  type: 'POST_SOSBUD_ALUMNI_REQUEST'
});

const postSosBudAlumniSuccess = (data) => ({
  type: 'POST_SOSBUD_ALUMNI_SUCCESS',
  sosBudAlumni: data
});

const postSosBudAlumniFailure = (error) => ({
  type: 'POST_SOSBUD_ALUMNI_FAILURE',
  sosBudAlumni: error
});

const postSosBudAlumni = (params) => {
  return (dispatch) => {
    dispatch(postSosBudAlumniRequest());
    return apiCaller('pensosbud/alumni', 'post', params)
      .then((data) => dispatch(postSosBudAlumniSuccess(data)))
      .catch((error) => dispatch(postSosBudAlumniFailure(error)));
  };
};

// Register List Serve Sosbud
const postSosBudListServeRequest = () => ({
  type: 'POST_SOSBUD_LISTSERVE_REQUEST'
});

const postSosBudListServeSuccess = (data) => ({
  type: 'POST_SOSBUD_LISTSERVE_SUCCESS',
  sosBudListServe: data
});

const postSosBudListServeFailure = (error) => ({
  type: 'POST_SOSBUD_LISTSERVE_FAILURE',
  sosBudListServe: error
});

const postSosBudListServe = (params) => {
  return (dispatch) => {
    dispatch(postSosBudListServeRequest());
    return apiCaller('pensosbud/listserve', 'post', params)
      .then((data) => dispatch(postSosBudListServeSuccess(data)))
      .catch((error) => dispatch(postSosBudListServeFailure(error)));
  };
};

// Register Letter Sosbud service
const getSosBudLetterRequest = () => ({
  type: 'GET_SOSBUD_LETTER_REQUEST'
});

const getSosBudLetterSuccess = (data) => ({
  type: 'GET_SOSBUD_LETTER_SUCCESS',
  sosBudLetter: data
});

const getSosBudLetterFailure = (error) => ({
  type: 'GET_SOSBUD_LETTER_FAILURE',
  sosBudLetter: error
});

const getSosBudLetter = (id) => {
  return (dispatch) => {
    dispatch(getSosBudLetterRequest());
    return apiCaller(`pensosbud/registration/${id}?type=id`)
      .then((data) => dispatch(getSosBudLetterSuccess(data)))
      .catch((error) => dispatch(getSosBudLetterFailure(error)));
  };
};

// Post pindah sekolah, post graduate, post pns
const postSosBudSubmissionRequest = () => ({
  type: 'POST_SOSBUD_SUBMISSION_REQUEST'
});

const postSosBudSubmissionSuccess = (data) => ({
  type: 'POST_SOSBUD_SUBMISSION_SUCCESS',
  sosBudListServe: data
});

const postSosBudSubmissionFailure = (error) => ({
  type: 'POST_SOSBUD_SUBMISSION_FAILURE',
  sosBudListServe: error
});

const resetPostSubmission = () => {
  const defaultData = {
    data: {},
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_SOSBUD_SUBMISSION',
      data: defaultData
    });
  };
};

const postSosBudSubmission = (params) => {
  return (dispatch) => {
    dispatch(postSosBudSubmissionRequest());
    return apiCaller('pensosbud/submission', 'post', params)
      .then((data) => {
        if (get(data, ['status']) === 200) {
          return dispatch(postSosBudSubmissionSuccess(data));
        } else {
          return dispatch(postSosBudSubmissionFailure(data));
        }
      })
      .catch((error) => dispatch(postSosBudSubmissionFailure(error)));
  };
};

const resetNumberRegister = () => {
  const defaultData = {
    data: [],
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_NUMBER_REGISTER',
      data: defaultData
    });
  };
};

const resetDetailRegister = () => {
  const defaultData = {
    data: [],
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_DETAIL_REGISTER',
      data: defaultData
    });
  };
};

export {
  getSosBudServiceList,
  postSosBudLetter,
  postSosBudEducation,
  postSosBudAlumni,
  postSosBudListServe,
  getSosBudLetter,
  postSosBudSubmission,
  postSosBudSubmissionSuccess,
  getSosBudLetterSuccess,
  resetNumberRegister,
  resetDetailRegister,
  resetPostSubmission
};
