import { apiCaller } from '../../../helpers/apiCaller';

const getComplainRegisterRequest = () => ({
  type: 'GET_COMPLAINT_REGISTER_REQUEST'
});

const getComplainRegisterSuccess = (data) => ({
  type: 'GET_COMPLAINT_REGISTER_SUCCESS',
  payload: data
});

const getComplainRegisterFailure = (error) => ({
  type: 'GET_COMPLAINT_REGISTER_FAILURE',
  payload: error
});

const postComplainRegister = (params) => {
  return (dispatch) => {
    dispatch(getComplainRegisterRequest());
    return apiCaller('complaint/registration', 'post', params)
      .then((data) => dispatch(getComplainRegisterSuccess(data)))
      .catch((error) => dispatch(getComplainRegisterFailure(error)));
  };
};

export { postComplainRegister };
