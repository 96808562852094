import { apiCaller } from '../../../helpers/apiCaller';

const getAntrianRequest = () => ({
  type: 'GET_ANTRIAN_ADMIN_REQUEST'
});

const getAntrianSuccess = (data) => ({
  type: 'GET_ANTRIAN_ADMIN_SUCCESS',
  payload: data
});

const getAntrianFailure = (error) => ({
  type: 'GET_ANTRIAN_ADMIN_FAILURE',
  payload: error
});

const getStatisticDayRequest = () => ({
  type: 'GET_ANTRIAN_STATISTIC_DAY_ADMIN_REQUEST'
});

const getStatisticDaySuccess = (data) => ({
  type: 'GET_ANTRIAN_STATISTIC_DAY_ADMIN_SUCCESS',
  payload: data
});

const getStatisticDayFailure = (error) => ({
  type: 'GET_ANTRIAN_STATISTIC_DAY_ADMIN_FAILURE',
  payload: error
});

const getStatisticWeekRequest = () => ({
  type: 'GET_ANTRIAN_STATISTIC_WEEK_ADMIN_REQUEST'
});

const getStatisticWeekSuccess = (data) => ({
  type: 'GET_ANTRIAN_STATISTIC_WEEK_ADMIN_SUCCESS',
  payload: data
});

const getStatisticWeekFailure = (error) => ({
  type: 'GET_ANTRIAN_STATISTIC_WEEK_ADMIN_FAILURE',
  payload: error
});

const getStatisticMonthRequest = () => ({
  type: 'GET_ANTRIAN_STATISTIC_MONTH_ADMIN_REQUEST'
});

const getStatisticMonthSuccess = (data) => ({
  type: 'GET_ANTRIAN_STATISTIC_MONTH_ADMIN_SUCCESS',
  payload: data
});

const getStatisticMonthFailure = (error) => ({
  type: 'GET_ANTRIAN_STATISTIC_MONTH_ADMIN_FAILURE',
  payload: error
});

const getStatisticAntrianRequest = () => ({
  type: 'GET_ANTRIAN_STATISTIC_ANTRIAN_ADMIN_REQUEST'
});

const getStatisticAntrianSuccess = (data) => ({
  type: 'GET_ANTRIAN_STATISTIC_ANTRIAN_ADMIN_SUCCESS',
  payload: data
});

const getStatisticAntrianFailure = (error) => ({
  type: 'GET_ANTRIAN_STATISTIC_ANTRIAN_ADMIN_FAILURE',
  payload: error
});

const postUbahJadwalAntrianRequest = () => ({
  type: 'POST_UBAH_JADWAL_ANTRIAN_ADMIN_REQUEST'
});

const postUbahJadwalAntrianSuccess = (data) => ({
  type: 'POST_UBAH_JADWAL_ANTRIAN_ADMIN_SUCCESS',
  payload: data
});

const postUbahJadwalAntrianFailure = (error) => ({
  type: 'POST_UBAH_JADWAL_ANTRIAN_ADMIN_FAILURE',
  payload: error
});

const postHolidayAntrianRequest = () => ({
  type: 'POST_HOLIDAY_ANTRIAN_ADMIN_REQUEST'
});

const postHolidayAntrianSuccess = (data) => ({
  type: 'POST_HOLIDAY_ANTRIAN_ADMIN_SUCCESS',
  payload: data
});

const postHolidayAntrianFailure = (error) => ({
  type: 'POST_HOLIDAY_ANTRIAN_ADMIN_FAILURE',
  payload: error
});

const getJadwalAntrianRequest = () => ({
  type: 'GET_JADWAL_ANTRIAN_ADMIN_REQUEST'
});

const getJadwalAntrianSuccess = (data) => ({
  type: 'GET_JADWAL_ANTRIAN_ADMIN_SUCCESS',
  payload: data
});

const getJadwalAntrianFailure = (error) => ({
  type: 'GET_JADWAL_ANTRIAN_ADMIN_FAILURE',
  payload: error
});

const getExportAntrianRequest = () => ({
  type: 'GET_EXPORT_ANTRIAN_ADMIN_REQUEST'
});

const getExportAntrianSuccess = (data) => ({
  type: 'GET_EXPORTL_ANTRIAN_ADMIN_SUCCESS',
  payload: data
});

const getExportAntrianFailure = (error) => ({
  type: 'GET_EXPORTL_ANTRIAN_ADMIN_FAILURE',
  payload: error
});

const patchAntrianRequest = () => ({
  type: 'PATCH_ANTRIAN_ADMIN_REQUEST'
});

const patchAntrianSuccess = (data) => ({
  type: 'PATCH_ANTRIAN_ADMIN_SUCCESS',
  payload: data
});

const patchAntrianFailure = (error) => ({
  type: 'PATCH_ANTRIAN_ADMIN_FAILURE',
  payload: error
});

const getAntrian = (params) => {
  return (dispatch) => {
    dispatch(getAntrianRequest());
    return apiCaller('admin/appointments', 'get', undefined, true, params)
      .then((data) => dispatch(getAntrianSuccess(data)))
      .catch((error) => dispatch(getAntrianFailure(error)));
  };
};

const getStatisticAntrian = (params) => {
  return (dispatch) => {
    dispatch(getStatisticAntrianRequest());
    return apiCaller('admin/appointments/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticAntrianSuccess(data)))
      .catch((error) => dispatch(getStatisticAntrianFailure(error)));
  };
};

const getStatisticDay = (params) => {
  return (dispatch) => {
    dispatch(getStatisticDayRequest());
    return apiCaller('admin/appointments/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticDaySuccess(data)))
      .catch((error) => dispatch(getStatisticDayFailure(error)));
  };
};

const getStatisticMonth = (params) => {
  return (dispatch) => {
    dispatch(getStatisticMonthRequest());
    return apiCaller('admin/appointments/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getStatisticMonthFailure(error)));
  };
};

const getStatisticWeek = (params) => {
  return (dispatch) => {
    dispatch(getStatisticWeekRequest());
    return apiCaller('admin/appointments/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getStatisticWeekFailure(error)));
  };
};

const postUbahJadwalAntrian = (params) => {
  return (dispatch) => {
    dispatch(postUbahJadwalAntrianRequest());
    return apiCaller('admin/appointments/generate', 'post', params, true)
      .then((data) => dispatch(postUbahJadwalAntrianSuccess(data)))
      .catch((error) => dispatch(postUbahJadwalAntrianFailure(error)));
  };
};

const postHolidayAntrian = (data, params) => {
  return (dispatch) => {
    dispatch(postHolidayAntrianRequest());
    return apiCaller('admin/appointments/duplicate', 'post', data, true, params)
      .then((data) => dispatch(postHolidayAntrianSuccess(data)))
      .catch((error) => dispatch(postHolidayAntrianFailure(error)));
  };
};

const getJadwalAntrian = () => {
  return (dispatch) => {
    dispatch(getJadwalAntrianRequest());
    return apiCaller('admin/settings', 'get', undefined, true)
      .then((data) => dispatch(getJadwalAntrianSuccess(data)))
      .catch((error) => dispatch(getJadwalAntrianFailure(error)));
  };
};

const getExportAntrian = (params) => {
  return (dispatch) => {
    dispatch(getExportAntrianRequest());
    return apiCaller('admin/appointments/export', 'get', undefined, true, params)
      .then((data) => dispatch(getExportAntrianSuccess(data)))
      .catch((error) => dispatch(getExportAntrianFailure(error)));
  };
};

const patchAntrian = (action, id) => {
  return (dispatch) => {
    dispatch(patchAntrianRequest());
    return apiCaller('admin/appointments/' + action + '/' + id, 'patch', undefined, true)
      .then((data) => dispatch(patchAntrianSuccess(data)))
      .catch((error) => dispatch(patchAntrianFailure(error)));
  };
};

export {
  getAntrian,
  getStatisticDay,
  getStatisticWeek,
  getStatisticMonth,
  getStatisticAntrian,
  postUbahJadwalAntrian,
  getJadwalAntrian,
  postHolidayAntrian,
  getExportAntrian,
  patchAntrian
};
