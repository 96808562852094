import { apiCaller } from '../../../helpers/apiCaller';
import get from 'lodash/get';

// service list
const getStatementServiceListRequest = () => ({
  type: 'GET_STATEMENT_SERVICE_REQUEST'
});

const getStatementServiceListSuccess = (data) => ({
  type: 'GET_STATEMENT_SERVICE_SUCCESS',
  statementService: data
});

const getStatementServiceListFailure = (error) => ({
  type: 'GET_STATEMENT_SERVICE_FAILURE',
  statementService: error
});

const getStatementServiceList = () => {
  return (dispatch) => {
    dispatch(getStatementServiceListRequest());
    return apiCaller('letter-service')
      .then((data) => dispatch(getStatementServiceListSuccess(data)))
      .catch((error) => dispatch(getStatementServiceListFailure(error)));
  };
};

// Number regis statement letter
const getNumberRegisterStatementRequest = () => ({
  type: 'GET_NUMBER_REGISTER_STATEMENT_REQUEST'
});

const getNumberRegisterStatementSuccess = (data) => ({
  type: 'GET_NUMBER_REGISTER_STATEMENT_SUCCESS',
  statementRegis: data
});

const getNumberRegisterStatementFailure = (error) => ({
  type: 'GET_NUMBER_REGISTER_STATEMENT_FAILURE',
  statementRegis: error
});

const postNumberRegisterStatement = (params) => {
  return (dispatch) => {
    dispatch(getNumberRegisterStatementRequest());
    return apiCaller('statement-letter/registration', 'post', params)
      .then((data) => {
        if (get(data, ['response', 'data', 'status']) === 422) {
          return dispatch(getNumberRegisterStatementFailure(data));
        }

        return dispatch(getNumberRegisterStatementSuccess(data));
      })
      .catch((error) => dispatch(getNumberRegisterStatementFailure(error)));
  };
};

// Detail Number regis statement
const getRegisDetailStatementRequest = () => ({
  type: 'GET_REGIS_DETAIL_STATEMENT_REQUEST'
});

const getRegisDetailStatementSuccess = (data) => ({
  type: 'GET_REGIS_DETAIL_STATEMENT_SUCCESS',
  statementRegisDetail: data
});

const getRegisDetailStatementFailure = (error) => ({
  type: 'GET_REGIS_DETAIL_STATEMENT_FAILURE',
  statementRegisDetail: error
});

const getRegisDetailStatement = (params) => {
  return (dispatch) => {
    dispatch(getRegisDetailStatementRequest());
    return apiCaller(`statement-letter/detail/${params}`)
      .then((data) => dispatch(getRegisDetailStatementSuccess(data)))
      .catch((error) => dispatch(getRegisDetailStatementFailure(error)));
  };
};

const resetNumberRegisterStatement = () => {
  const defaultData = {
    data: {},
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_NUMBER_REGISTER_STATEMENT',
      data: defaultData
    });
  };
};

const resetDetailRegisterStatement = () => {
  const defaultData = {
    data: {},
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_DETAIL_REGISTER_STATEMENT',
      data: defaultData
    });
  };
};

export {
  getStatementServiceList,
  postNumberRegisterStatement,
  getRegisDetailStatement,
  resetNumberRegisterStatement,
  resetDetailRegisterStatement
};
