import get from 'lodash/get';
import docLegalizationServiceKey from '../../../constant/docLegalizationService';

export const docLegalType = {
  power: {
    label: 'doc_legalization.tab1.title',
    key: 'Surat Kuasa',
    list: [
      'original_passport_legalization',
      'copy_resident',
      'copy_id',
      'for_us',
      'for_id'
    ]
  },
  validation: {
    label: 'doc_legalization.tab2.title',
    key: 'Pengesahan Fotokopi Dokumen',
    list: ['proof_domicile', 'ori_passport', 'copy_doc', 'no_fee']
  }
};

export const getDocLegalServiceId = (list, key) => {
  const docLegalServiceSelected = list.find(
    ({ name }) => name === docLegalizationServiceKey[key]
  );

  return get(docLegalServiceSelected, ['id']);
};

export const getServiceTitle = (list, serviceId) => {
  const serviceSelected = list.find(({ id }) => id === serviceId);

  const findKeyTitle = Object.keys(docLegalizationServiceKey).find(
    (key) => docLegalizationServiceKey[key] === get(serviceSelected, ['name'])
  );

  return findKeyTitle;
};

export const getServiceTitleByName = (name) => {
  const findKeyTitle = Object.keys(docLegalizationServiceKey).find(
    (key) => docLegalizationServiceKey[key] === name
  );
  if (findKeyTitle) {
    return docLegalType[findKeyTitle].label;
  }
};
