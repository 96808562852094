import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Tabbing from '../../../../../components/Tabbing';
import DashboardTable from '../../../../../components/DashboardTable';
import MiniCard from '../../../../../components/CardDetail/MiniCard';
import Loading from '../../../../../components/Loading';

import './style.scss';

const MainContent = ({
  history,
  title,
  dataTab = [],
  summaryData = [],
  type,
  onTabChanges,
  defaultActiveTab,
  onPageChanges,
  onPageDividerChanges,
  onQueryChanges,
  pagination,
  typeTab,
  loading,
  topActionComponent
}) => {
  const navs = ['Investasi', 'Perdagangan'];

  const columns = [
    { dataField: 'id', text: 'No' },
    { dataField: 'first_name', text: 'Name' },
    { dataField: 'company_name', text: 'Company' },
    { dataField: 'country', text: 'Country' },
    { dataField: 'primary_phone', text: 'Phone' },
    { dataField: 'primary_email', text: 'Email' },
    { dataField: 'updated_at', text: 'Datetime' },
    { dataField: 'id', text: 'Action' }
  ];

  const actionList = {
    view: {
      onClick: (id, data) =>
        history.push(`/admin/economy/${type}/detail/${data.id}?tab=${typeTab}`),
      type: 'view'
    }
  };

  const action1 = [actionList.view];
  const propsTableTab = {
    columns,
    search: 'first_name',
    idKeyField: 'id',
    onPageChanges,
    onPageDividerChanges,
    onQueryChanges,
    data: dataTab,
    pagination,
    action: action1
  };

  const table = [
    <DashboardTable key='1' {...propsTableTab} />,
    <DashboardTable key='2' {...propsTableTab} />
  ];

  const details = [
    {
      title,
      list: summaryData
    }
  ];

  const renderDetails = () =>
    details.map(({ title, list }, idx) => (
      <div className='detail-container' key={idx}>
        <div
          className='detail-top'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h1 className='title'>{title}</h1>
          {topActionComponent}
        </div>
        <div className='detail-wrap'>
          {list.map((detail, idx2) => (
            <MiniCard detail={detail} key={idx2} />
          ))}
        </div>
      </div>
    ));

  return (
    <div className='admin-visa'>
      {loading && <Loading />}
      {renderDetails()}
      <Tabbing
        navs={navs}
        table={table}
        onChange={(tabIdx) => {
          onTabChanges(tabIdx);
          onPageChanges(1);
          onQueryChanges();
        }}
        defaultActiveTab={defaultActiveTab}
      />
    </div>
  );
};

MainContent.propTypes = {
  history: PropTypes.object
};

export default withRouter(MainContent);
