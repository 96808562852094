import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RadioButton from '../../../../components/input/RadioButton';
import Dropdown from '../../../../components/Dropdown';
import Datepicker from '../../../../components/input/Datepicker';

import { optionsList } from './functions';
import purposeVisaOptions from '../../../../constant/purpose_visit';

import { getPortList, getProvinceIDList } from '../../PassportForm/action';

const PersonalInfoForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  port,
  getPortList,
  province,
  getProvinceIDList,
  citiesId
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({ ...formGlobal.generalInfo });
  const [errors, setErrors] = useState({});
  const provinceOptions = get(province, ['data']);
  const portOptions = get(port, ['data']);
  const cityIdList = get(citiesId, ['data']);

  const { visaTypeOptions } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      purposeVisit: yup.string().required('required'),
      visaType: yup.string().required('required'),
      lengthStay: yup
        .number()
        .typeError('age harus angka')
        .required('required')
        .integer('number')
        .positive('number'),
      pointEntry: yup.object().shape({
        _id: yup.string().required('required')
      }),
      dateEntry: yup.date().required('required'),
      flightVessel: yup.string().required('required'),
      dateDeparture: yup.date().required('required'),
      portDeparture: yup.object().shape({
        _id: yup.string().required('required')
      }),
      addressId: yup.string().required('required'),
      city: yup.string().required('required'),
      province: yup.object().shape({
        _id: yup.string().required('required')
      }),
      zip: yup.string().required('required'),
      id_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, generalInfo: form });
        history.push(`/visa/form/personal-info${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    getPortList();
    getProvinceIDList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handlerSubmit} id='personal-info'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>{i18n('form_visa.tab.general_info')}</div>
          <Dropdown
            className='row input-container'
            label={i18n('form.purpose_visit.label')}
            placeholder={i18n('form.purpose_visit.placeholder')}
            value={get(form, ['purposeVisit'])}
            options={purposeVisaOptions}
            onClick={(item) => handlerChanges(item, 'purposeVisit', 'purposeVisit')}
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown withoutAutocomplete'
            }}
            errorMessage={
              get(errors, ['purposeVisit']) &&
              i18n(`validation.${get(errors, ['purposeVisit'])}`)
            }
            detailInput={i18n('form.purpose_visit.info')}
          />
          <RadioButton
            className='visa-type'
            label={i18n('form.visa_type_1.label')}
            options={visaTypeOptions}
            value={get(form, ['visaType'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'visaType')
            }}
            errorMessage={
              get(errors, ['visaType']) && i18n(`validation.${get(errors, ['visaType'])}`)
            }
          />
          <TextInput
            label={i18n('form.length_of_stay_id.label')}
            placeholder={i18n('form.length_of_stay_id.placeholder')}
            value={get(form, ['lengthStay'])}
            textRight={i18n('form.length_of_stay_id.day')}
            inputProps={{
              maxLength: 255,
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'lengthStay')
            }}
            errorMessage={
              get(errors, ['lengthStay']) &&
              i18n(`validation.${get(errors, ['lengthStay'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.point_entry.label')}
            placeholder={i18n('form.point_entry.placeholder')}
            value={get(form, ['pointEntry'])}
            options={portOptions}
            onClick={(item) => handlerChanges(item, 'pointEntry', 'pointEntry._id')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            withAutocomplete
            valueKey='_id'
            errorMessage={
              get(errors, ['pointEntry._id']) &&
              i18n(`validation.${get(errors, ['pointEntry._id'])}`)
            }
            detailInput={i18n('form.point_entry.info')}
          />
          <Datepicker
            label={i18n('form.date_entry.label')}
            placeholder={i18n('form.date_entry.placeholder')}
            value={get(form, ['dateEntry'])}
            onChange={(date) => handlerChanges(date, 'dateEntry')}
            errorMessage={
              get(errors, ['dateEntry']) &&
              i18n(`validation.${get(errors, ['dateEntry'])}`)
            }
          />
          <TextInput
            label={i18n('form.flight/vessel.label')}
            placeholder={i18n('form.flight/vessel.placeholder')}
            value={get(form, ['flightVessel'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'flightVessel')
            }}
            errorMessage={
              get(errors, ['flightVessel']) &&
              i18n(`validation.${get(errors, ['flightVessel'])}`)
            }
          />
          <Datepicker
            label={i18n('form.date_departure.label')}
            placeholder={i18n('form.date_departure.placeholder')}
            value={get(form, ['dateDeparture'])}
            onChange={(date) => handlerChanges(date, 'dateDeparture')}
            errorMessage={
              get(errors, ['dateDeparture']) &&
              i18n(`validation.${get(errors, ['dateDeparture'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.port_departure.label')}
            placeholder={i18n('form.port_departure.placeholder')}
            value={get(form, ['portDeparture'])}
            options={portOptions}
            onClick={(item) => handlerChanges(item, 'portDeparture', 'portDeparture._id')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='_id'
            withAutocomplete
            errorMessage={
              get(errors, ['portDeparture._id']) &&
              i18n(`validation.${get(errors, ['portDeparture._id'])}`)
            }
            detailInput={i18n('form.port_departure.info')}
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>{i18n('form_visa.tab.instruction.address_id')}</div>
          <TextInput
            label={i18n('form.address_in_id.label')}
            placeholder={i18n('form.address_in_id.placeholder')}
            value={get(form, ['addressId'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'addressId')
            }}
            errorMessage={
              get(errors, ['addressId']) &&
              i18n(`validation.${get(errors, ['addressId'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.city.label')}
            placeholder={i18n('form.city.placeholder')}
            value={get(form, ['city'])}
            options={cityIdList}
            onClick={(item) => handlerChanges(item, 'city', 'city')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='_id'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['city']) && i18n(`validation.${get(errors, ['city'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.province.label')}
            placeholder={i18n('form.province.placeholder')}
            value={get(form, ['province'])}
            options={provinceOptions}
            onClick={(item) => handlerChanges(item, 'province', 'province._id')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='_id'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['province._id']) &&
              i18n(`validation.${get(errors, ['province._id'])}`)
            }
          />
          <TextInput
            label={i18n('form.zip_code.label')}
            placeholder={i18n('form.zip_code.placeholder')}
            value={get(form, ['zip'])}
            inputProps={{
              maxLength: 255,
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'zip')
            }}
            errorMessage={
              get(errors, ['zip']) && i18n(`validation.${get(errors, ['zip'])}`)
            }
          />
          <TextInput
            label={i18n('form.phone.label')}
            sublabel={i18n('form.at_indonesia')}
            placeholder={i18n('form.phone.placeholder')}
            prependText='+62'
            value={get(form, ['id_phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'id_phone')
            }}
            errorMessage={
              get(errors, ['id_phone']) && i18n(`validation.${get(errors, ['id_phone'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button color='link' type='button' onClick={() => history.push('/visa')}>
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

PersonalInfoForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  port: PropTypes.object,
  getPortList: PropTypes.func,
  province: PropTypes.object,
  getProvinceIDList: PropTypes.func,
  citiesId: PropTypes.object
};

const mapStateToProps = (state) => ({
  port: {
    status: state.portListReducer.status,
    error: state.portListReducer.error,
    data: state.portListReducer.data
  },
  province: {
    status: state.provinceIDReducer.status,
    error: state.provinceIDReducer.error,
    data: state.provinceIDReducer.data
  },
  citiesId: {
    status: state.cityIdReducer.status,
    error: state.cityIdReducer.error,
    data: state.cityIdReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getPortList, getProvinceIDList }),
  withRouter
)(PersonalInfoForm);
