import React, { useState } from 'react';
import whatsapp from '../../../../assets/img/homepage/whatsapp.svg';
import email from '../../../../assets/img/homepage/email_24px.svg';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';

const ContactDetail = ({ title, mail, number, i18n, wa, wil }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTeritory, setSelectedTeritory] = useState();
  const teritory = {
    WDC: {
      area: [i18n('teritory.all_area')],
      title: i18n('teritory.area', { area: 'KBRI Washington D.C.' })
    },
    CH: {
      area: [
        'Illinois',
        'Michigan',
        'Wisconsin',
        'Indiana',
        'Iowa',
        'Kansas',
        'Minnesota',
        'Missouri',
        'Nebraska',
        'North Dakota',
        'South Dakota',
        'Kentucky',
        'Ohio'
      ],
      title: i18n('teritory.area', { area: 'KJRI Chicago' })
    },
    HO: {
      area: [
        'Alabama',
        'Arkansas',
        'Florida',
        'Georgia',
        'Louisiana',
        'Mississippi',
        'New Mexico',
        'Oklahoma',
        'Tennessee',
        'Texas',
        'U.S. Virgin Island',
        'The Commonwealth of Puerto Rico'
      ],
      title: i18n('teritory.area', { area: 'KJRI Houston' })
    },
    LA: {
      area: [
        'Colorado',
        'Arizona',
        'Nevada',
        'Montana',
        'Wyoming',
        'Utah',
        'Hawai',
        'Pacific Island Territory under US',
        'Southern California (Counties of Imperial, Kern, Los Angeles, Orange, Riverside, San Benardino, San Diego, San Luis Obispo, Santa Barbara, Ventura)'
      ],
      title: i18n('teritory.area', { area: 'KJRI Los Angeles' })
    },
    SF: {
      area: [
        'Alaska',
        'Idaho',
        'Oregon',
        'Washington State',
        'Northern California (Counties of Almeda, Alpine, Amador, Butte, Calaveras, Colusa, Contra, Costa del Norte, Eldorado, Fresno, Glenn, Humboldt, Invo, Kings, Lakelaslen, Mardera, Monterey, Napa, Palcer, Plumas, Sacramento, San Benito, San Francisco, San Joaquin, San Metao, Santa Carla, Santa Cruz, Shasta, Sierra, Siskyou, Solana, Sonoma, Stanislaus, Sutter, Tehama, Trinity, Tulare, Toulumne, Yolo, Yuba)'
      ],
      title: i18n('teritory.area', { area: 'KJRI San Francisco' })
    }
  };

  const call = (phoneNumber) => {
    window.open('https://wa.me/' + phoneNumber, '_blank');
  };

  const handleModal = (keyTeritory) => {
    if (showModal) {
      setShowModal(false);
      setSelectedTeritory();
    } else {
      setShowModal(true);
      setSelectedTeritory(keyTeritory);
    }
  };

  return (
    <div className='contact-detail'>
      <span className='title'>{title}</span>
      <span className='phone'>{number}</span>
      {wa !== '' && (
        <div className='whatsapp' onClick={() => call(wa)}>
          <img src={whatsapp} alt='whatsapp' />
          {i18n('contact_us.call')}
        </div>
      )}
      {wil === '' && (
        <a href={'mailto:' + mail}>
          <div className='email'>
            <img src={email} alt='email' />
            {i18n('contact_us.email')}
          </div>
        </a>
      )}
      {mail === '' && (
        <div className='email' onClick={() => handleModal(wil)}>
          {title.includes('Washington')
            ? i18n('contact_us.teritory')
            : i18n('contact_us.teritory2')}
        </div>
      )}
      <Modal
        className='teritory'
        showModal={showModal}
        setShowModal={setShowModal}
        title={selectedTeritory && teritory[selectedTeritory].title}
        desc={
          selectedTeritory && (
            <div className='teritory-detail'>
              <ul className='area-wrap'>
                {teritory[selectedTeritory].area.map((area, idx) => (
                  <li key={idx}>{area}</li>
                ))}
              </ul>
            </div>
          )
        }
      />
    </div>
  );
};

ContactDetail.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  i18n: PropTypes.func,
  wa: PropTypes.string,
  mail: PropTypes.string,
  wil: PropTypes.string
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps, withRouter))(ContactDetail);
