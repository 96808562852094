import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import Icon from '../../Icon';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const DatepickerInput = ({
  label,
  sublabel,
  placeholder,
  errorMessage,
  value,
  className,
  onChange,
  datepickerProps,
  disable = false,
  icon = 'calendar',
  rangeDetail,
  notes,
  dateFormat = 'dd/MM/yyyy'
}) => {
  return (
    <div className={`datepicker-container ${className} ${errorMessage ? 'err' : ''}`}>
      <div className='input-wrap'>
        <div className='label'>
          {label}
          {sublabel && <div className='sublabel'>{sublabel}</div>}
        </div>
        <div className='datepicker-input-wrap'>
          <DatePicker
            dateFormat={dateFormat}
            selected={value}
            onChange={(date) => onChange(date)}
            placeholderText={placeholder}
            disabled={disable}
            {...datepickerProps}
          />
          {rangeDetail && <DatePicker dateFormat={dateFormat} {...rangeDetail} />}
          <Icon icon={icon} />
        </div>
      </div>
      {errorMessage && <div className='err-message'>{errorMessage}</div>}
      {notes && <div className='notes'>{notes}</div>}
    </div>
  );
};

DatepickerInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  inputProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  className: PropTypes.string,
  sublabel: PropTypes.any,
  onChange: PropTypes.func,
  datepickerProps: PropTypes.object
};

export default DatepickerInput;
