const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getVisaListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_VISA_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_VISA_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_VISA_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getVisaStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_VISA_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_VISA_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_VISA_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getVisaStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_VISA_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_VISA_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_VISA_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getVisaStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_VISA_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_VISA_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_VISA_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getVisaStatisticAllReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_VISA_STATISTIC_ALL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_STATISTIC_ALL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_VISA_STATISTIC_ALL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_VISA_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const patchAction = {
  data: {},
  status: 'idle',
  error: ''
};

const patchVisaApproveReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_VISA_APPROVE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_VISA_APPROVE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_VISA_APPROVE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchVisaPendingReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_VISA_PENDING_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_VISA_PENDING_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_VISA_PENDING_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchVisaProcessReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_VISA_PROCESS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_VISA_PROCESS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_VISA_PROCESS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const getVisaDetailReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'GET_VISA_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_VISA_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export {
  getVisaListReducer,
  getVisaStatisticReducer,
  getVisaStatisticWeekReducer,
  getVisaStatisticMonthReducer,
  patchVisaApproveReducer,
  patchVisaPendingReducer,
  patchVisaProcessReducer,
  getVisaDetailReducer,
  getVisaStatisticAllReducer
};
