import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import TextInput from '../../../../components/input/TextInput';
import Dropdown from '../../../../components/Dropdown';

import kjri_area from '../../../../constant/kjri_area';
import { getProvinceIDList } from '../../PassportForm/action';

import './style.scss';

const StateZip = ({
  i18n,
  form,
  handlerChanges,
  errors,
  type,
  getProvinceIDList,
  province,
  isStateText = false
}) => {
  const provinceList = get(province, ['data']);

  useEffect(() => {
    if (provinceList.length === 0 && type === 'id') {
      getProvinceIDList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceList, type]);

  return (
    <div className='row-input state-zip'>
      <TextInput
        label={i18n('form.city.label')}
        placeholder={i18n('form.city.placeholder')}
        value={get(form, ['city'])}
        className='city'
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'city')
        }}
        errorMessage={
          get(errors, ['city']) && i18n(`validation.${get(errors, ['city'])}`)
        }
      />
      {isStateText ? (
        <TextInput
          label={`${i18n('form.state.label')}/${i18n('form.province.label')}`}
          placeholder={`${i18n('form.state.placeholder')}/${i18n(
            'form.province.placeholder'
          )}`}
          value={get(form, ['state'])}
          className='state'
          inputProps={{
            onChange: (e) => handlerChanges(e.target.value, 'state')
          }}
          errorMessage={
            get(errors, ['state']) && i18n(`validation.${get(errors, ['state'])}`)
          }
        />
      ) : (
        <Dropdown
          className='row input-container'
          label={i18n(`form.${type === 'id' ? 'province' : 'state'}.label`)}
          placeholder={i18n(`form.${type === 'id' ? 'province' : 'state'}.placeholder`)}
          value={get(form, ['state'])}
          options={type === 'id' ? provinceList : kjri_area}
          onClick={(item) =>
            handlerChanges(item, 'state', `state.${type === 'id' ? '_id' : 'value'}`)
          }
          isValueObject
          dropdownToggleProps={{
            tag: 'div',
            className: 'input-dropdown'
          }}
          valueKey={type === 'id' ? '_id' : 'value'}
          labelKey={type === 'id' ? 'name' : 'label'}
          withAutocomplete
          errorMessage={
            get(errors, ['state.value']) &&
            i18n(`validation.${get(errors, ['state.value'])}`)
          }
        />
      )}
      <TextInput
        label={i18n('form.zip.label')}
        placeholder={i18n('form.zip.label')}
        value={get(form, ['zip_code'])}
        className='zip'
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'zip_code')
        }}
        errorMessage={
          get(errors, ['zip_code']) && i18n(`validation.${get(errors, ['zip_code'])}`)
        }
      />
    </div>
  );
};

StateZip.propTypes = {
  i18n: PropTypes.func,
  handlerChanges: PropTypes.func,
  form: PropTypes.object,
  errors: PropTypes.object,
  getProvinceIDList: PropTypes.func,
  province: PropTypes.object
};

const mapStateToProps = (state) => ({
  province: {
    status: state.provinceIDReducer.status,
    error: state.provinceIDReducer.error,
    data: state.provinceIDReducer.data
  }
});

export default compose(connect(mapStateToProps, { getProvinceIDList }))(StateZip);
