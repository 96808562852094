import { apiCaller } from '../../../../helpers/apiCaller';

// INVESTMENT INQUIRY
const getExhibitorListRequest = () => ({
  type: 'GET_EXHIBITOR_LIST_REQUEST'
});

const getExhibitorListSuccess = (data) => ({
  type: 'GET_EXHIBITOR_LIST_SUCCESS',
  payload: data
});

const getExhibitorListFailure = (error) => ({
  type: 'GET_EXHIBITOR_LIST_FAILURE',
  payload: error
});

const resetExhibitorList = () => ({
  type: 'RESET_EXHIBITOR_LIST_LIST'
});

const getExhibitorList = (params) => {
  return (dispatch) => {
    dispatch(getExhibitorListRequest());
    return apiCaller('admin/exhibitors', 'get', undefined, true, params)
      .then((data) => dispatch(getExhibitorListSuccess(data)))
      .catch((error) => dispatch(getExhibitorListFailure(error)));
  };
};

// INVESTMENT INQUIRY DETAIL
const getExhibitorDetailRequest = () => ({
  type: 'GET_EXHIBITOR_DETAIL_REQUEST'
});

const getExhibitorDetailSuccess = (data) => ({
  type: 'GET_EXHIBITOR_DETAIL_SUCCESS',
  payload: data
});

const getExhibitorDetailFailure = (error) => ({
  type: 'GET_EXHIBITOR_DETAIL_FAILURE',
  payload: error
});

const resetExhibitorDetail = () => ({
  type: 'RESET_EXHIBITOR_DETAIL_LIST'
});

const getExhibitorDetail = (id) => {
  return (dispatch) => {
    dispatch(getExhibitorDetailRequest());
    return apiCaller(`exhibitors/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getExhibitorDetailSuccess(data)))
      .catch((error) => dispatch(getExhibitorDetailFailure(error)));
  };
};

export { resetExhibitorList, getExhibitorList, resetExhibitorDetail, getExhibitorDetail };
