import React, { useEffect, useState } from 'react';
import PhotoUpload from '../../../../components/PhotoUploadSecond';
import TextArea from '../../../../components/input/TextArea';
import { withRouter, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '../../../../components/Button';
import { arrayHasValue, objectHasValue } from '../../../../helpers/helpers';
import { getComplaintDetail, postAnswerComplaint, sendEmail } from '../action';
import moment from 'moment';
import './style.scss';

const DetailPengaduan = ({
  history,
  i18n,
  uploadPhoto,
  complaint,
  getComplaintDetail,
  postAnswerComplaint,
  postComplaint, 
  sendEmail
}) => {
  const match = useRouteMatch();
  const { id } = match.params;

  useEffect(() => {
    if (!arrayHasValue(complaint.data)) {
      getComplaintDetail(id);
    }
  }, []);

  const submitAnswer = () => {
    postAnswerComplaint(id, defaultForm);
    sendEmail({
      sender: 'Consulate Connect KJRI',
      from: 'noreply@consulateconnectkjri.go.id',
      to: [complaint.data.email],
      subject: 'Complaint Response',
      html: '<h3>Question</h3><p>' + complaint.data.message + '</p><h3>Answer</h3><p>' + answer + '</p>'
    })
  };

  if (postComplaint.status === 'resolve') {
    window.location.reload();
  }

  const [answer, setAnswer] = useState('');
  const defaultForm = {
    response: answer,
    files: [objectHasValue(uploadPhoto.data) ? uploadPhoto.data.data.url : complaint.data.response_files]
  };

  function Photos(listPhotos) {
    if (listPhotos.data.length > 0 && listPhotos.data[0] === '') {
      return '-';
    } else {
      return listPhotos.data.map((photo, index) =>
        <div key={index} className='photos'>
          <img src={photo} alt='complaint' width='350' />
        </div>
      );
    }
  }

  const renderTextArea = () => {
    if(complaint.data.status == 'ANSWERED'){
      return <TextArea
        setValue={setAnswer}
        initialValue={complaint.status === 'resolve' ? complaint.data.response : ''} 
        trueValue={answer}
        readOnly={true}
      />
    }else{
      return <TextArea
        setValue={setAnswer}
        initialValue={complaint.status === 'resolve' ? complaint.data.response : ''} 
        trueValue={answer}
      />
    }
  }

  return (
    <div className='detail-pengaduan'>
      <span className='back-homepage' onClick={() => history.push('/admin/pengaduan')}>
        <span className='btn-back' />
        DETAIL PENGADUAN
      </span>
      <div className='detail-pengaduan-content admin'>
        <div className='date-pengaduan'>
          {complaint.status === 'resolve'
            ? 'Tanggal Pengaduan: ' + moment(complaint.data.created_at).format('YYYY-MM-DD HH:mm')
            : ''}
        </div>
        <div className='content-top'>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className='row-name'>Info Pengadu</div>
                </td>
                <td>
                  <div className='row-content'>
                    <div>
                      <b>{complaint.status === 'resolve' ? complaint.data.name : ''}</b>
                    </div>
                    <div style={{ fontSize: 14 }}>
                      {complaint.status === 'resolve' ? complaint.data.email : ''}
                    </div>
                    <div style={{ fontSize: 14 }}>
                      {complaint.status === 'resolve' ? complaint.data.phone : ''}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='row-name'>Subject</div>
                </td>
                <td>
                  <div className='row-content'>
                    <div>{complaint.status === 'resolve' ? complaint.data.subject : ''}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='row-name'>Message</div>
                </td>
                <td>
                  <div className='row-content'>
                    <div>{complaint.status === 'resolve' ? complaint.data.message : '-'}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='row-name'>Attach File</div>
                </td>
                <td>
                  <div className='row-content'>
                    {complaint.status === 'resolve' ? 
                      <Photos data={complaint.data.files} /> : '-'
                    }
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='content-bottom'>
          <div className='content-bottom-title'>Tanggapi Pengaduan</div>
          {renderTextArea()}
          <PhotoUpload />
          <div className='row-information'>
            <div className='row-name' style={{ width: 175, display: 'inline-block'}} />
            <div className='row-content' style={{ display: 'inline-block', marginTop: 30 }}>
              {complaint.status === 'resolve' && arrayHasValue(complaint.data.response_files) ? 
                <Photos data={complaint.data.response_files} /> : ''
              }
            </div>
          </div>
          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button
              color='primary'
              type='submit'
              className='pass-btn'
              onClick={() => submitAnswer()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPengaduan.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  getComplaintDetail: PropTypes.func,
  complaint: PropTypes.object,
  postAnswerComplaint: PropTypes.func,
  postComplaint: PropTypes.object,
  sendEmail: PropTypes.object,
  uploadPhoto: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  complaint: {
    status: state.complaintDetailReducerAdmin.status,
    error: state.complaintDetailReducerAdmin.error,
    data: state.complaintDetailReducerAdmin.data
  },
  uploadPhoto: {
    status: state.uploadPhotoGeneral.status,
    error: state.uploadPhotoGeneral.error,
    data: state.uploadPhotoGeneral.data
  },
  postComplaint: {
    status: state.postAnswerComplaintReducerAdmin.status,
    error: state.postAnswerComplaintReducerAdmin.error,
    data: state.postAnswerComplaintReducerAdmin.data
  }, 
  sendEmail: {
    status: state.sendEmailReducer.status,
    error: state.sendEmailReducer.error,
    data: state.sendEmailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getComplaintDetail, postAnswerComplaint, sendEmail }),
  withRouter)
(DetailPengaduan);
