import React, { useState, useEffect } from 'react';
import DashboardTable from '../../../components/DashboardTableOld2';
import Button from '../../../components/Button';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DatepickerRange from '../components/DatepickerRange';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { arrayHasValue } from '../../../helpers/helpers';
import { getComplaints } from './action';
import Loading from '../../../components/Loading';
import './style.scss';

const AdminPengaduan = ({ history, getComplaints, complaints }) => {
  const date = new Date();
  var last = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(300);

  useEffect(() => {
    if (!arrayHasValue(complaints.data.data)) {
      const params = {
        per_page: per_page,
        page: page
      };

      getComplaints(params);
    }
  }, [getComplaints, complaints.data.data]);

  const columns = [
    { dataField: 'subject', text: 'Subject' },
    { dataField: 'name', text: 'Full Name' },
    { dataField: 'phone', text: 'Phone Number' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'updated_at', text: 'Date' },
    { dataField: 'status', text: 'Status' },
    { dataField: 'id', text: 'Action' }
  ];

  const action = [
    { onClick: (id) => history.push(`/admin/pengaduan/${id}`), type: 'view' }
  ];
  const data = complaints.data.data;
  const pagination = complaints.data.pagination;

  const dataOnChange = () => {
    const params = {
      per_page: per_page,
      page: page
    };

    getComplaints(params);
  };

  return (
    <div className='admin-news'>
      <div className='admin-news-top'>
        <h1>DATA PENGADUAN</h1>
        <div className='admin-news-action' style={{ display: `none` }}>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <Button color='info' onClick={() => history.push('/admin/pegaduan-download')}>
            Download
          </Button>
        </div>
      </div>
      {complaints.status === 'resolve' ? (
        <DashboardTable
          columns={columns}
          data={data}
          action={action}
          search='name'
          idKeyField='id'
          pagination={pagination}
          setPerPage={setPerPage}
          setPage={setPage}
          dataOnChange={dataOnChange}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

AdminPengaduan.propTypes = {
  history: PropTypes.object,
  getComplaints: PropTypes.func,
  complaints: PropTypes.object
};

const mapStateToProps = (state) => ({
  complaints: {
    status: state.complaintsReducerAdmin.status,
    error: state.complaintsReducerAdmin.error,
    data: state.complaintsReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps,{
    getComplaints
  }),
  withRouter
)(AdminPengaduan);
