const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getLetterListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_LETTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_LETTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_LETTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_LETTER_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getLetterStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_LETTER_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_LETTER_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_LETTER_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getLetterStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_LETTER_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_LETTER_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_LETTER_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getLetterStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_LETTER_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_LETTER_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_LETTER_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getLetterStatisticAllReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_LETTER_STATISTIC_ALL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_LETTER_STATISTIC_ALL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_LETTER_STATISTIC_ALL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_LETTER_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const patchAction = {
  data: {},
  status: 'idle',
  error: ''
};

const patchLetterApproveReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_LETTER_APPROVE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_LETTER_APPROVE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_LETTER_APPROVE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchLetterRejectReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_LETTER_REJECT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_LETTER_REJECT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_LETTER_REJECT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchLetterProcessReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_LETTER_PROCESS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_LETTER_PROCESS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_LETTER_PROCESS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchLetterPendingReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_LETTER_PENDING_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_LETTER_PENDING_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_LETTER_PENDING_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export {
  getLetterListReducer,
  getLetterStatisticReducer,
  getLetterStatisticWeekReducer,
  getLetterStatisticMonthReducer,
  getLetterStatisticAllReducer,
  patchLetterApproveReducer,
  patchLetterRejectReducer,
  patchLetterProcessReducer,
  patchLetterPendingReducer
};
