const menuList = [
  { menu: [{ label: 'Dashboard', icon: 'dashboard', path: '/admin/dashboard' }] },
  {
    parentLabel: 'Ekonomi',
    menu: [
      {
        label: 'Berbisnis di Indonesia',
        icon: 'handshake',
        path: '/admin/economy/bisnis'
      },
      {
        label: 'Berbisnis di Wilayah Kerja KJRI New York',
        icon: 'globe',
        path: '/admin/economy/global'
      },
      {
        label: 'Pameran Dagang',
        icon: 'dagang',
        path: '/admin/economy/dagang'
      },
      {
        label: 'Pameran Budaya dan Pariwisata',
        icon: 'budaya',
        path: '/admin/economy/exhibition'
      }
    ]
  },
  {
    parentLabel: 'Sosial Budaya',
    menu: [
      {
        label: 'Surat Keterangan',
        icon: 'book',
        parentPath: '/admin/sosbud',
        submenus: [
          {
            label: 'Surat Pindah Sekolah',
            icon: 'handshake',
            path: '/admin/sosbud/sk/transferSchool'
          },
          {
            label: 'Keterangan Lulus Sekolah/Universitas',
            icon: 'handshake',
            path: '/admin/sosbud/sk/graduate'
          },
          {
            label: 'Surat Permohonan Studi Akhir Bagi PNS',
            icon: 'handshake',
            path: '/admin/sosbud/sk/pns'
          }
        ]
      },
      {
        label: 'Lokakarya dan Pendidikan',
        icon: 'school',
        parentPath: '/admin/lokakarya',
        submenus: [
          {
            label: 'Kelas Bahasa Indonesia',
            icon: 'minimize',
            path: '/admin/lokakarya/kelas-bahasa',
            parent: 'Lokakarya dan Pendidikan'
          },
          {
            label: 'Workshop Gamelan Bali',
            icon: 'minimize',
            path: '/admin/lokakarya/gamelan-bali',
            parent: 'Lokakarya dan Pendidikan'
          }
        ]
      },
      {
        label: 'Darmasiswa Program dan Intern',
        icon: 'document',
        path: '/admin/darmasiswa'
      },
      { label: 'Milis', icon: 'list', path: '/admin/mailing-list' },
      { label: 'Diaspora', icon: 'diaspora', path: '/admin/diaspora' }
      // {
      //   label: 'Darmawisata Program',
      //   icon: 'darmawisata',
      //   path: '/admin/sosbud/darmawisata'
      // }
    ]
  },
  {
    parentLabel: 'Konsuler',
    menu: [
      {
        label: 'Paspor & SPLP',
        icon: 'passport',
        parentPath: '/admin/passpor',
        submenus: [
          {
            label: 'Pembaruan Passpor Habis masa berlaku',
            path: '/admin/passpor/expired_pass'
          },
          {
            label: 'Pembaruan Passpor Habis bagi anak yang lahir di as',
            path: '/admin/passpor/expired_child'
          },
          {
            label:
              'Pembuatan Paspor Pertama Kali Bagi Anak Yang Lahir Di AS Setelah 1 Agustus 2006',
            path: '/admin/passpor/new_born_pass'
          },
          {
            label: 'Penggantian Paspor Hilang',
            path: '/admin/passpor/lost_pass'
          },
          {
            label: 'Penggantian Paspor Rusak',
            path: '/admin/passpor/broken_pass'
          },
          {
            label:
              'Penggantian Paspor Hilang Bagi Wni Turis / Surat Perjalanan Laksana Paspor (SPLP)',
            path: '/admin/passpor/splp'
          }
        ]
      },
      {
        label: 'Surat Keterangan',
        icon: 'document',
        parentPath: '/admin/surat-keterangan',
        submenus: [
          {
            label: 'Surat Keterangan Kelahiran',
            icon: 'minimize',
            path: '/admin/surat-keterangan/birth',
            parent: 'Surat Keterangan'
          },
          {
            label: 'Surat Keterangan Pernikahan',
            icon: 'minimize',
            path: '/admin/surat-keterangan/married',
            parent: 'Surat Keterangan'
          },
          {
            label: 'Surat Keterangan Kematian',
            icon: 'minimize',
            path: '/admin/surat-keterangan/death',
            parent: 'Surat Keterangan'
          },
          {
            label: 'Surat Keterangan Pengiriman Abu Jenasah',
            icon: 'minimize',
            path: '/admin/surat-keterangan/death2',
            parent: 'Surat Keterangan'
          },
          {
            label: 'Surat Keterangan Domisili',
            icon: 'minimize',
            path: '/admin/surat-keterangan/domisili',
            parent: 'Surat Keterangan'
          },
          {
            label: 'Surat Keterangan Barang Pindahan',
            icon: 'minimize',
            path: '/admin/surat-keterangan/move',
            parent: 'Surat Keterangan'
          }
        ]
      },
      {
        label: 'Visa',
        icon: 'visa',
        parentPath: '/admin/visa',
        submenus: [
          {
            label: 'Tourist Visa',
            path: '/admin/visa/tourist'
          },
          {
            label: 'Bussiness Visa - Single Entry',
            path: '/admin/visa/bussiness_single'
          },
          {
            label: 'Bussiness Visa - Multiple Entry',
            path: '/admin/visa/bussiness_multiple'
          },
          {
            label: 'Socio-Cultural Visa',
            path: '/admin/visa/socio_culture'
          },
          {
            label: 'Limited Stay Visa',
            path: '/admin/visa/limited_stay'
          },
          {
            label: 'Diplomatic / Service Visa',
            path: '/admin/visa/diplomatic'
          },
          {
            label: 'Journalist Visa',
            path: '/admin/visa/journalist'
          }
        ]
      },
      {
        label: 'Document Legalization',
        icon: 'stamp',
        parentPath: '/admin/doc-legal',
        submenus: [
          {
            label: 'Surat Kuasa',
            path: '/admin/doc-legal/power'
          },
          {
            label: 'Pengesahan Fotokopi Dokumen',
            path: '/admin/doc-legal/validation'
          }
        ]
      },
      { label: 'Antrian', icon: 'report', path: '/admin/antrian-paspor' }
    ]
  },
  {
    parentLabel: 'Master Data',
    menu: [
      { label: 'Pengaduan', icon: 'chat', path: '/admin/pengaduan' },
      { label: 'Berita & Informasi', icon: 'report', path: '/admin/news' },
      { label: 'Acara/Pameran', icon: 'report', path: '/admin/events' },
      { label: 'Pariwisata', icon: 'report', path: '/admin/pariwisata' },
      // { label: 'Tempat Wisata', icon: 'report', path: '/admin/travel' },
      { label: 'User', icon: 'report', path: '/admin/users' }
    ]
  }
  // {
  //   parentLabel: 'Antrian Paspor',
  //   menu: [
  //     {
  //       label: 'Antrian Paspor',
  //       icon: 'chat',
  //       path: '/admin/antrian-paspor'
  //     }
  //   ]
  // },
  // {
  //   parentLabel: 'Paspor dan SPLP',
  //   menu: [
  //     {
  //       label: 'Paspor dan SPLP',
  //       icon: 'chat',
  //       path: '/admin/paspor-splp'
  //     }
  //   ]
  // }
];

export default menuList;
