import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SideBar from './SideBar';
import Dropdown from '../../Dropdown';

import './style.scss';

const AdminLayout = ({ route, i18n, history }) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [username, setUsername] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    window.location.href = '/admin/login';
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    setUsername(localStorage.getItem('username'));
    if (!accessToken) {
      history.push('/admin/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='admin-wrap'>
      <SideBar
        toggleSidebar={toggleSidebar}
        setToggleSidebar={setToggleSidebar}
        i18n={i18n}
      />
      <div className='wrap'>
        <header>
          <Dropdown
            className='profile-wrap'
            options={[
              { label: ' ' + username, disabled: true },
              { label: 'Logout', value: 'logout' }
            ]}
            customToggle={
              <div className='profile-detail-wrap'>
                <div className='photo-user' />
                <div className='username'>Hi, {username}</div>
              </div>
            }
            onClick={({ value }) => {
              if (value === 'logout') {
                handleLogout();
              }
            }}
            dropdownToggleProps={{
              tag: 'div'
            }}
          />
        </header>
        <main>
          {renderRoutes(route.routes, { i18n })}
          <div className='footer'>
            <div className='text'>{i18n('footer.copyright_admin')}</div>
            <nav className='menu-footer'>
              <a href='/'>{i18n('nav.home')}</a>
              <a href='/contact-us'>{i18n('nav.contact_us')}</a>
              <a href='/help'>{i18n('nav.help')}</a>
            </nav>
          </div>
        </main>
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  route: PropTypes.object,
  i18n: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(AdminLayout);
