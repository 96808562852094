import get from 'lodash/get';

const statusTabList = {
  '1': 'REGISTERED',
  '2': 'PROCESS',
  '3': 'APPROVED',
  '4': 'PENDING',
  '5': 'WAITING'
};

const setSummary = (statisticData, statusTab) => {
  const summaryData = {
    numOfIn: String(0),
    numOfProcess: String(0),
    numOfFinish: String(0),
    numOfPending: String(0),
    numOfWaiting: String(0)
  };

  const statusTabListFinal = {
    ...statusTabList,
    ...statusTab
  };

  statisticData.map(({ status, total }) => {
    switch (status) {
      case statusTabListFinal['1']:
        summaryData.numOfIn = String(total);
        break;
      case statusTabListFinal['2']:
        summaryData.numOfProcess = String(total);
        break;
      case statusTabListFinal['3']:
        summaryData.numOfFinish = String(total);
        break;
    }
  });

  return summaryData;
};

export { setSummary };
