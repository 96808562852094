import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Banner from '../../../components/Banner';
import './style.scss';
import { Container } from 'reactstrap';
import Button from '../../../components/Button';

const SelfReport = ({ history, i18n }) => {
  return (
    <>
      <Banner
        title={i18n('self_report.title')}
        subtitle={i18n('self_report.subtitle')}
        backButtonClick={() => history.goBack()}
      />
      <Container style={{ paddingTop: 24 }}>
        <div className='self-report'>
          <div className='left'>
            <h1>{i18n('self_report.title1')}</h1>
            <p dangerouslySetInnerHTML={{ __html: i18n('self_report.content1') }} />
            <h1 style={{ marginTop: 35 }}>{i18n('self_report.title2')}</h1>
            <p dangerouslySetInnerHTML={{ __html: i18n('self_report.content2') }} />
            <div
              className='warn'
              dangerouslySetInnerHTML={{ __html: i18n('self_report.warn') }}
            />
          </div>
          <div className='right'>
            <h1>{i18n('self_report.title3')}</h1>
            <p
              style={{ fontSize: 20 }}
              dangerouslySetInnerHTML={{ __html: i18n('self_report.content3') }}
            />
            <Button
              className='top'
              color='primary'
              onClick={() => window.open('https://peduliwni.kemlu.go.id/beranda.html')}
            >
              {i18n('self_report.btn5')}
            </Button>
            <h1 style={{ marginTop: 20 }}>{i18n('self_report.title4')}</h1>
            <p
              style={{ fontSize: 20 }}
              dangerouslySetInnerHTML={{ __html: i18n('self_report.content4') }}
            />
            <Button className='bottom' onClick={() => history.push('/passpor')}>
              {i18n('self_report.btn1')}
            </Button>
            <Button className='bottom' onClick={() => history.push('/statement-letter')}>
              {i18n('self_report.btn2')}
            </Button>
            <Button className='bottom' onClick={() => history.push('/doc-legalization')}>
              {i18n('self_report.btn3')}
            </Button>
            <Button className='bottom' onClick={() => history.push('/economy')}>
              {i18n('self_report.btn4')}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

SelfReport.propTypes = {
  i18n: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps, withRouter))(SelfReport);
