import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import parse from 'html-react-parser';
import moment from 'moment';

import './style.scss';
import Button from '../../../../components/Button';

import { getEventDetail } from '../action';

const EventDetail = ({ history, i18n, getEventDetail, eventDetail, match }) => {
  const { id } = get(match, ['params']);
  const data = get(eventDetail, ['data', 0]);
  const {
    title,
    address,
    contact,
    currency,
    datetime,
    description = '',
    excerpt = '',
    image_url,
    place,
    admission_fee,
    can_register
  } = data || {};

  useEffect(() => {
    getEventDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ paddingTop: 24 }}>
      {eventDetail.status === 'resolve' && (
        <div className='event-detail'>
          <img src={image_url} alt='detail-event-img' />
          <span className='back-homepage' onClick={() => history.goBack()}>
            <span className='btn-back' />
            {i18n('cta.back_prev_page')}
          </span>

          <h2>{title}</h2>
          <p>{parse(excerpt)}</p>

          <div className='event-detail-registration'>
            <span className='event-detail-registration-status'>
              {admission_fee === 0 ? 'Free Admision' : `${currency} ${admission_fee}`}
            </span>
            <span
              className={`event-detail-registration-button ${
                can_register === 'true' ? '' : 'hide'
              }`}
            >
              <Button color='primary' onClick={() => history.push(`/event/register/${id}`)}>
                {i18n('event.detail.register_button')}
              </Button>
            </span>
          </div>
          <hr />
          <div className='event-detail-info'>
            <span>
              <div className='event-detail-info-header'>{i18n('event.detail.time')}</div>
              {/* {moment(datetime).format('DDDD')} <br /> */}
              {/* {moment(datetime).utcOffset(datetime).format('DD MMMM YYYY')}
              <br />
              {moment(datetime).format('HH:MM A')} */}
              {moment(datetime).utcOffset(datetime).format('LLL')}
              <br />
            </span>
            <span>
              <div className='event-detail-info-header'>{i18n('event.detail.place')}</div>
              {place} <br />
              {address}
              <br />
            </span>
            <span>
              <div className='event-detail-info-header'>
                {i18n('event.detail.contact')}
              </div>
              {get(contact, ['phone'])}
              <br />
              {get(contact, ['address'])}
            </span>
          </div>
          <div className='event-detail-desc'>
            <div className='event-detail-desc-header'>{i18n('event.detail.desc')}</div>
            {parse(description)}
          </div>
        </div>
      )}
    </Container>
  );
};

EventDetail.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  getEventDetail: PropTypes.func,
  eventDetail: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  eventDetail: {
    status: state.eventDetailReducer.status,
    error: state.eventDetailReducer.error,
    data: state.eventDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getEventDetail }),
  withRouter
)(EventDetail);
