import React, { useEffect, useState } from 'react';
import Banner from '../../../components/Banner';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActivityThumbnail from '../../../components/thumbnail/ActivityThumbnail';

import { getEventList } from './action';

import searchIcon from '../../../assets/img/icon/search.svg';
import './style.scss';

const Event = ({ history, i18n, eventList, getEventList }) => {
  const { data } = eventList;
  const [search, changeSearch] = useState('');

  useEffect(() => {
    getEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner
        title={i18n('event.heading.top1')}
        subtitle={i18n('event.heading.top2')}
        backButtonClick={() => history.goBack()}
      />
      <Container>
        <div className='event'>
          <div className='event-top'>
            <h3>{i18n('event.heading.list')}</h3>
            <div className='event-top-search'>
              <img src={searchIcon} alt='search-img' />
              <input
                placeholder={i18n('event.search')}
                onChange={(e) => changeSearch(e.target.value)}
              />
            </div>
          </div>
          <div className='event-list'>
            <Row>
              {data.map((event, idx) =>
                event.title.toLowerCase().includes(search) ? (
                  <ActivityThumbnail source='event-list' key={idx} data={event} />
                ) : (
                  ''
                )
              )}
            </Row>
          </div>
          <div className='event-paginate' style={{ display: `none` }}>
            <div className='prev'>{i18n('nav.prev')}</div>
            <div className='number'>1</div>
            <div className='number active'>2</div>
            <div className='number'>3</div>
            <div className='next'>{i18n('nav.next')}</div>
          </div>
        </div>
      </Container>
    </>
  );
};

Event.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  eventList: PropTypes.object,
  getEventList: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  eventList: {
    status: state.eventListReducer.status,
    error: state.eventListReducer.error,
    data: state.eventListReducer.data
  }
});

export default compose(connect(mapStateToProps, { getEventList }), withRouter)(Event);
