import { apiCaller } from '../../../helpers/apiCaller';

// Number regis passport
const getNumberRegisterRequest = () => ({
  type: 'GET_NUMBER_REGISTER_REQUEST'
});

const getNumberRegisterSuccess = (data) => ({
  type: 'GET_NUMBER_REGISTER_SUCCESS',
  payload: data
});

const getNumberRegisterFailure = (error) => ({
  type: 'GET_NUMBER_REGISTER_FAILURE',
  payload: error
});

const postNumberRegister = (params) => {
  return (dispatch) => {
    dispatch(getNumberRegisterRequest());
    return apiCaller('passport/submission', 'post', params)
      .then((data) => dispatch(getNumberRegisterSuccess(data)))
      .catch((error) => dispatch(getNumberRegisterFailure(error)));
  };
};

// Working Area
const getOfficeListRequest = () => ({
  type: 'GET_OFFICE_LIST_REQUEST'
});

const getOfficeListSuccess = (data) => ({
  type: 'GET_OFFICE_LIST_SUCCESS',
  offices: data
});

const getOfficeListFailure = (error) => ({
  type: 'GET_OFFICE_LIST_FAILURE',
  offices: error
});

const getOfficeList = () => {
  return (dispatch) => {
    dispatch(getOfficeListRequest());
    return apiCaller('offices')
      .then((data) => dispatch(getOfficeListSuccess(data)))
      .catch((error) => dispatch(getOfficeListFailure(error)));
  };
};

export { postNumberRegister, getOfficeList };
