import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Tabbing from '../../../../../components/Tabbing';
import DashboardTable from '../../../../../components/DashboardTable';
import MiniCard from '../../../../../components/CardDetail/MiniCard';
import Loading from '../../../../../components/Loading';

import './style.scss';

const SingleContent = ({
  history,
  title,
  dataTab = [],
  summaryData = [],
  type,
  onPageChanges,
  onPageDividerChanges,
  onQueryChanges,
  pagination,
  loading,
  topActionComponent,
  i18n
}) => {
  const navs = [];

  const columns = [
    { dataField: 'id', text: 'No' },
    { dataField: 'first_name', text: 'Name' },
    { dataField: 'country', text: 'Country' },
    { dataField: 'primary_phone', text: 'Phone' },
    { dataField: 'primary_email', text: 'Email Aktif' },
    {
      dataField: 'event',
      text: 'Exhibition',
      customColumn: (data) => exhibitionList(i18n)['sena']
    },
    { dataField: 'updated_at', text: 'Datetime' },
    { dataField: 'id', text: 'Action' }
  ];

  const actionList = {
    view: {
      onClick: (id, data) => history.push(`/admin/economy/${type}/detail/${data.id}`),
      type: 'view'
    }
  };

  const action1 = [actionList.view];
  const propsTableTab = {
    columns,
    search: 'first_name',
    idKeyField: 'id',
    onPageChanges,
    onPageDividerChanges,
    onQueryChanges,
    data: dataTab,
    pagination,
    action: action1
  };

  const table = [<DashboardTable key='1' {...propsTableTab} />];

  const details = [
    {
      title,
      list: summaryData
    }
  ];

  const renderDetails = () =>
    details.map(({ title, list }, idx) => (
      <div className='detail-container' key={idx}>
        <div
          className='detail-top'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h1 className='title'>{title}</h1>
          {topActionComponent}
        </div>
        <div className='detail-wrap'>
          {list.map((detail, idx2) => (
            <MiniCard detail={detail} key={idx2} />
          ))}
        </div>
      </div>
    ));

  return (
    <div className='admin-visa'>
      {loading && <Loading />}
      {renderDetails()}
      <Tabbing navs={navs} table={table} />
    </div>
  );
};

export const exhibitionList = (i18n) => ({
  'new-york-now': i18n('economy.tab10.title'),
  summer: i18n('economy.tab11.sidebar'),
  sena: i18n('economy.tab12.sidebar'),
  'allegra-festival': i18n('economy.tab13.sidebar'),
  other: i18n('economy.tab19.sidebar'),
  'new-york-travel': i18n('economy.tab15.sidebar'),
  'philladelphia-travel': i18n('economy.tab16.sidebar'),
  'boston-travel': i18n('economy.tab17.sidebar'),
  'indonesian-festival': i18n('economy.tab18.sidebar')
});

SingleContent.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(SingleContent);
