const defaultResponse = {
  data: {},
  status: 'idle',
  error: ''
};

const numberRegisterDocLegalReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_NUMBER_REGISTER_DOC_LEGAL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NUMBER_REGISTER_DOC_LEGAL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.docLegalRegis
      };
    case 'GET_NUMBER_REGISTER_DOC_LEGAL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.docLegalRegis.data.data
      };
    case 'RESET_NUMBER_REGISTER_DOC':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const regisDetailDocLegalReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_REGIS_DETAIL_DOC_LEGAL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_REGIS_DETAIL_DOC_LEGAL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.docLegalRegis
      };
    case 'GET_REGIS_DETAIL_DOC_LEGAL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.docLegalRegis.data.data
      };
    case 'RESET_DETAIL_REGISTER_DOC':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const docLegalServiceReducer = (state = { ...defaultResponse, data: [] }, action) => {
  switch (action.type) {
    case 'GET_DOC_LEGAL_SERVICE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_DOC_LEGAL_SERVICE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.docLegalService
      };
    case 'GET_DOC_LEGAL_SERVICE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.docLegalService.data.data
      };
    case 'RESET_POST_DATA':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export {
  numberRegisterDocLegalReducer,
  regisDetailDocLegalReducer,
  docLegalServiceReducer
};
