import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhotoUpload from '../../../../components/PhotoUpload';
import TextInputNoob from '../../../../components/input/TextInputNoob';
import TextAreaEditor from '../../../../components/TextAreaEditor';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { objectHasValue } from '../../../../helpers/helpers';
import { editTour } from '../action';
import Button from '../../../../components/Button';
import './style.scss';

const EditPariwisata = ({ history, uploadPhoto, editTourProcess, editTour }) => {
  const match = useRouteMatch();
  const { id } = match.params;
  const listPariwisata = history.location.state;

  let detail;
  listPariwisata.forEach((pariwisata) => {
    if (pariwisata._id === id) {
      detail = pariwisata;
    }
  });

  const [textValue, setTextValue] = useState(detail.content);
  const [titleValue, setTitleValue] = useState(detail.title);
  const [wisataSekitar, setWisataSekitar] = useState(detail.nearest_places.toString());
  const [tags, setTags] = useState(detail.tags.toString());
  const [location, setLocation] = useState(detail.place);
  const defaultForm = {
    title: titleValue,
    image_url: objectHasValue(uploadPhoto.data)
      ? uploadPhoto.data.data.url
      : detail.image_url,
    content: textValue,
    place: location,
    location: '0,0',
    nearest_places: wisataSekitar === '' ? [] : wisataSekitar.split(','),
    tags: tags === '' ? [] : tags.split(',')
  };

  const submitEvent = () => {
    editTour(id, defaultForm);
  };

  if (editTourProcess.status === 'resolve') {
    window.location.reload();
  }

  return (
    <div className='create-events'>
      <div className='header'>EDIT PARIWISATA</div>
      <div className='content'>
        <PhotoUpload initialImg={detail.image_url} />
        <span style={{ height: 10, display: 'inline-block' }} />
        <span style={{ height: 5, display: 'inline-block' }} />
        <div className='div-2 div-wrap'>
          <div className='div-wrap-2' style={{ paddingRight: 20 }}>
            <TextInputNoob
              label='Judul *'
              value={titleValue}
              action={setTitleValue}
              placeholder='Masukkan Judul'
            />
          </div>
          <div className='div-wrap-2' style={{ paddingRight: 20 }}>
            <TextInputNoob
              label='Lokasi *'
              value={location}
              action={setLocation}
              placeholder='Masukkan lokasi'
            />
          </div>
        </div>
        <div className='div-2 div-wrap' style={{ display: 'none' }}>
          <div className='div-wrap-2 select' style={{ paddingRight: 20 }}>
            <label>Status *</label>
            <select name='staus' id='status'>
              <option value='Aktif'>Aktif</option>
              <option value='Non Aktif'>Non Aktif</option>
            </select>
          </div>
          <div className='div-wrap-2 select'>
            <label>Kategori *</label>
            <select name='staus' id='status'>
              <option value='NATURE, SCIENCE LANDSCAPE AND WILDLIFE'>
                NATURE, SCIENCE LANDSCAPE AND WILDLIFE
              </option>
              <option value='ARTIFACT'>ARTIFACT</option>
            </select>
          </div>
        </div>
        <span style={{ height: 5, display: 'inline-block' }} />
        <TextAreaEditor value={textValue} label='Deskripsi *' action={setTextValue} />
        <TextInputNoob
          label='Tags'
          value={tags}
          action={setTags}
          placeholder='Masukkan tag tanpa menggunakan spasi setelah koma'
        />
        <TextInputNoob
          label='Tempat Wisata Terdekat'
          value={wisataSekitar}
          action={setWisataSekitar}
          placeholder='Masukkan nama tempat tanpa menggunakan spasi setelah koma'
        />
        <span style={{ height: 5, display: 'inline-block' }} />
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='pass-btn'
            onClick={() => submitEvent()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

EditPariwisata.propTypes = {
  history: PropTypes.object,
  uploadPhoto: PropTypes.object,
  editTourProcess: PropTypes.object,
  editTour: PropTypes.func
};

const mapStateToProps = (state) => ({
  uploadPhoto: {
    status: state.uploadPhotoGeneral.status,
    error: state.uploadPhotoGeneral.error,
    data: state.uploadPhotoGeneral.data
  },
  editTourProcess: {
    status: state.editTourReducerAdmin.status
  }
});

export default compose(
  connect(mapStateToProps, { editTour }),
  withRouter
)(EditPariwisata);
