import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import DashboardTable from '../../../components/DashboardTable';
import Button from '../../../components/Button';
import DatepickerRange from '../components/DatepickerRange';
import MiniCard from '../../../components/CardDetail/MiniCard';

import {
  getSosBudRegisterList,
  resetSosBudRegisterList,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
} from '../SosBudSK/action';
import { getSosBudServiceList } from '../../form/PensosbudForm/action';
import { getSosBudServiceId } from '../../form/PensosbudForm/functions';
import { getStatisticDate, downloadFile } from '../functions';

import './style.scss';

const ListServe = ({
  history,
  getSosBudRegisterList,
  getSosBudServiceList,
  sosbudRegisterList,
  sosbudService,
  resetSosBudRegisterList,
  statistic,
  statisticWeek,
  statisticMonth,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
}) => {
  const title = 'Milis';

  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);

  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();
  const statusTabList = {
    '1': 'REGISTERED'
  };

  const sosbudServiceList = get(sosbudService, ['data']) || [];
  const sosbudServiceId = getSosBudServiceId(sosbudServiceList, 'listServe');
  const sosbudListData = get(sosbudRegisterList, ['data', 'data', 'data']) || [];
  const pagination = get(sosbudRegisterList, ['data', 'pagination']) || {};

  const columns = [
    { dataField: 'id', text: 'No' },
    { dataField: 'name', text: 'Full Name' },
    { dataField: 'phone', text: 'Phone' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'created_at', text: 'Date' },
    { dataField: 'id', text: 'Action' }
  ];

  const action = [
    {
      onClick: (id) => history.push(`/admin/mailing-list/detail/${id}`),
      type: 'view'
    }
  ];

  const getSummaryData = () => {
    const summaryData = [
      {
        title: 'Data Hari Ini',
        subtitle: title,
        numOfIn: String(0)
      },
      {
        title: 'Data Minggu Ini',
        subtitle: title,
        numOfIn: String(0)
      },
      {
        title: 'Data Bulan Ini',
        subtitle: title,
        numOfIn: String(0)
      }
    ];
    const dayStatistic = get(statistic, ['data']) || [];
    const weekStatistic = get(statisticWeek, ['data']) || [];
    const monthStatistic = get(statisticMonth, ['data']) || [];

    const setTotal = (statistic, index) => {
      statistic.map(({ status, total }) => {
        switch (status) {
          case statusTabList['1']:
            summaryData[index].numOfIn = String(total);
            break;
        }
      });
    };

    setTotal(dayStatistic, 0);
    setTotal(weekStatistic, 1);
    setTotal(monthStatistic, 2);

    return summaryData;
  };

  const details = [
    {
      title: '',
      list: getSummaryData()
    }
  ];

  const loadData = () => {
    const params = {
      service_id: sosbudServiceId,
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getSosBudRegisterList(params);
  };

  const loadStatisticData = () => {
    const { today, startWeek, endWeek, startMonth, endMonth } = getStatisticDate();
    getStatistic({
      service_id: sosbudServiceId,
      from: today,
      to: today
    });
    getStatisticWeek({
      service_id: sosbudServiceId,
      from: startWeek,
      to: endWeek
    });
    getStatisticMonth({
      service_id: sosbudServiceId,
      from: startMonth,
      to: endMonth
    });
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      service_id: sosbudServiceId
    };
    const fileName = `pensosbud-${title}-${params.from}-${params.to}`;
    downloadFile('pensosbud', params, fileName);
  };

  useEffect(() => {
    getSosBudServiceList();
    return () => resetSosBudRegisterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sosbudServiceId]);

  useEffect(() => {
    resetSosBudRegisterList();

    if (sosbudServiceId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDivider, page, query, sosbudServiceId]);

  const renderDetails = () =>
    details.map(({ title, list }, idx) => (
      <div className='detail-container' key={idx}>
        <div className='title'>{title}</div>
        <div className='detail-wrap'>
          {list.map((detail, idx2) => (
            <MiniCard detail={detail} key={idx2} />
          ))}
        </div>
      </div>
    ));

  return (
    <div className='listserve'>
      <div className='listserve-top'>
        <h1>Mailing List</h1>
        <div className='listserve-action'>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={loadData}
          />
          <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
            Download
          </Button>
        </div>
      </div>
      {renderDetails()}
      <DashboardTable
        columns={columns}
        action={action}
        search='name'
        idKeyField='id'
        onPageChanges={setPage}
        onPageDividerChanges={(divider) => {
          setPageDivider(divider);
          setQuery();
          setPage(1);
        }}
        onQueryChanges={(q) => {
          setPage(1);
          setQuery(q);
        }}
        data={sosbudListData}
        pagination={pagination}
      />
    </div>
  );
};

ListServe.propTypes = {
  history: PropTypes.object,
  getSosBudRegisterList: PropTypes.func,
  getSosBudServiceList: PropTypes.func,
  resetSosBudRegisterList: PropTypes.func,
  sosbudRegisterList: PropTypes.object,
  sosbudService: PropTypes.object,
  statistic: PropTypes.object,
  statisticWeek: PropTypes.object,
  statisticMonth: PropTypes.object,
  getStatistic: PropTypes.func,
  getStatisticWeek: PropTypes.func,
  getStatisticMonth: PropTypes.func
};

const mapStateToProps = (state) => ({
  sosbudRegisterList: {
    status: state.getSosBudRegisterListReducer.status,
    error: state.getSosBudRegisterListReducer.error,
    data: state.getSosBudRegisterListReducer.data
  },
  sosbudService: {
    status: state.sosBudServiceListReducer.status,
    error: state.sosBudServiceListReducer.error,
    data: state.sosBudServiceListReducer.data
  },
  statistic: {
    status: state.getSosbudStatisticReducer.status,
    error: state.getSosbudStatisticReducer.error,
    data: state.getSosbudStatisticReducer.data
  },
  statisticWeek: {
    status: state.getSosbudStatisticWeekReducer.status,
    error: state.getSosbudStatisticWeekReducer.error,
    data: state.getSosbudStatisticWeekReducer.data
  },
  statisticMonth: {
    status: state.getSosbudStatisticMonthReducer.status,
    error: state.getSosbudStatisticMonthReducer.error,
    data: state.getSosbudStatisticMonthReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudRegisterList,
    resetSosBudRegisterList,
    getSosBudServiceList,
    getStatistic,
    getStatisticWeek,
    getStatisticMonth
  }),
  withRouter
)(ListServe);
