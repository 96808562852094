import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Schollarship = ({ i18n }) => {
  const lang = (type, params) => i18n(`pensosbud.tab9.${type}`, { ...params });

  const coverageList = [
    lang('coverage.tuition_fee'),
    lang('coverage.round_trip'),
    lang('coverage.accomodation'),
    lang('coverage.local_transport'),
    lang('coverage.healt_insurance'),
    lang('coverage.mothly_allowance')
  ];

  const tncList = [
    lang('tnc.single'),
    lang('tnc.possess_high_interes'),
    lang('tnc.bearing'),
    lang('tnc.arrive_in_indo'),
    lang('tnc.follow_whole_program'),
    {
      title: lang('tnc.complete_form'),
      detail: [
        lang('tnc.motivation_letter'),
        lang('tnc.photo_passport'),
        lang('tnc.passpor_valid'),
        lang('tnc.academic_diploma'),
        lang('tnc.helth_certificate'),
        lang('tnc.letter_recom'),
        lang('tnc.personal_sign')
      ]
    },
    lang('tnc.indo_lesson')
  ];

  const scheduleList = [
    lang('schedule.iacs_regis', {
      date: '17 February - 3 April 2020'
    }),
    lang('schedule.interview', { date: '17 February - 3 April 2020' }),
    lang('schedule.submission', {
      date: '17 February - 3 April 2020'
    }),
    lang('schedule.final_aanouncement', {
      date: '17 February - 3 April 2020'
    })
  ];

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('coverage.title')}</b>
          </div>
          <ol>
            {coverageList.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('tnc.title')}</b>
          </div>
          <ol>
            {tncList.map((item, key) => (
              <li key={key}>
                {item.detail ? (
                  <>
                    <div>{item.title}</div>
                    <ol>
                      {item.detail.map((itemdetail, keyDetail) => (
                        <li key={keyDetail}>{itemdetail}</li>
                      ))}
                    </ol>
                  </>
                ) : (
                  item
                )}
              </li>
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('howtoapply.title')}</b>
          </div>
          <div>{lang('howtoapply.send_doc')}</div>
          <ol>
            <li>{lang('howtoapply.app_forn')}</li>
            <li>{lang('howtoapply.personal_statement')}</li>
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('schedule.title')}</b>
          </div>
          {scheduleList.map((item, key) => (
            <div
              key={key}
              dangerouslySetInnerHTML={{
                __html: item
              }}
            />
          ))}
        </div>

        <div className='mtop16'>
          <div className='red'>{lang('announcement')}</div>
          <div
            className='mtop16'
            dangerouslySetInnerHTML={{ __html: lang('more_info') }}
          />
        </div>
      </main>
    </div>
  );
};

Schollarship.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Schollarship);
