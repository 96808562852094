import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Banner from '../../../components/Banner';
import CardSideBar from '../../../components/CardSideBar';
import Loading from '../../../components/Loading';

import SchoolMove from './SchoolMove';
import GraduateSchool from './GraduateSchool';
import PNSLastStudy from './PNSLastStudy';
import PNS_RPPK from './PNS_RPPK';
import Research from './Research';
import Lokakarya from './Lokakarya';
import LokakaryaGamelan from './LokakaryaGamelan';
import Schollarship from './Schollarship';
import SchollarshipIACS from './SchollarshipIACS';
import SchollarshipIACSAlumni from './SchollarshipIACSAlumni';
import ListServe from './ListServe';

import { getSosBudServiceList } from '../../form/PensosbudForm/action';

import './style.scss';

const Pensosbud = ({ history, i18n, sosBudService, getSosBudServiceList }) => {
  const lang = localStorage.getItem('userLang');
  const sosBudServiceList = get(sosBudService, ['data']);
  const serviceStatus = get(sosBudService, ['status']);
  const loadingService = serviceStatus !== 'resolve';

  const onlyInd = [
    {
      label: i18n('pensosbud.tab3.title'),
      link: '/pensosbud/last-study-pns',
      Component: PNSLastStudy
    },
    {
      label: i18n('pensosbud.tab4.title'),
      link: '/pensosbud/rppk',
      Component: PNS_RPPK
    }
  ];

  const sideBarList = [
    {
      label: i18n('pensosbud.tab1.parent_title'),
      parentKey: 'school',
      sublist: [
        {
          label: i18n('pensosbud.tab1.title'),
          link: '/pensosbud',
          Component: SchoolMove
        },
        {
          label: i18n('pensosbud.tab2.title'),
          link: '/pensosbud/graduate-school',
          Component: GraduateSchool
        },
        ...(lang === 'en' ? [] : onlyInd)
      ]
    },
    {
      label: i18n('pensosbud.tab5.title'),
      link: '/pensosbud/research',
      Component: Research
    },
    {
      label: i18n('pensosbud.tab6.parent_title'),
      parentKey: 'lokakarya',
      sublist: [
        {
          label: i18n('pensosbud.tab6.sidebar'),
          link: '/pensosbud/lokakarya-bahasa',
          Component: Lokakarya
        },
        {
          label: i18n('pensosbud.tab7.sidebar'),
          link: '/pensosbud/lokakarya-gamelan-bali',
          Component: LokakaryaGamelan
        }
      ]
    },
    {
      label: i18n('pensosbud.tab8.parent_title'),
      parentKey: 'scholarship',
      sublist: [
        {
          label: i18n('pensosbud.tab8.title'),
          link: '/pensosbud/darmasiswa',
          Component: Schollarship
        },
        {
          label: i18n('pensosbud.tab9.title'),
          link: '/pensosbud/iacs',
          Component: SchollarshipIACS
        },
        {
          label: i18n('pensosbud.tab10.title'),
          link: '/pensosbud/iacs-alumni',
          Component: SchollarshipIACSAlumni
        }
      ]
    },
    {
      label: i18n('pensosbud.tab11.title'),
      link: '/pensosbud/mailing-list',
      Component: ListServe
    }
  ];

  // active tab
  const activePath = get(history, ['location', 'pathname']);

  useEffect(() => {
    getSosBudServiceList();
  }, [getSosBudServiceList]);

  return (
    <>
      <Banner
        title={i18n('pensosbud.banner.title')}
        subtitle={i18n('pensosbud.banner.subtitle')}
        backButtonClick={() => history.goBack()}
      />
      {loadingService && <Loading />}
      <CardSideBar
        activePath={activePath}
        sideBarList={sideBarList}
        sosBudServiceList={sosBudServiceList}
      />
    </>
  );
};

Pensosbud.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  sosBudService: PropTypes.object,
  getSosBudServiceList: PropTypes.func
};

const mapStateToProps = (state) => ({
  sosBudService: {
    status: state.sosBudServiceListReducer.status,
    error: state.sosBudServiceListReducer.error,
    data: state.sosBudServiceListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getSosBudServiceList }),
  withRouter
)(Pensosbud);
