import React from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';

import Header from '../../Header';
import Footer from '../../Footer';
import MobileDownload from '../MobileLayout';

import './style.scss';

class HomeLayout extends React.Component {
  render() {
    const { route, i18n } = this.props;
    const { theme } = route || 'dark';

    return (
      <div className={`wrap home-wrap ${theme}`}>
        <Header theme={theme} i18n={i18n} />
        <main>{renderRoutes(route.routes, { i18n })}</main>
        <main className='mobile'>
          <MobileDownload i18n={i18n} />
        </main>
        <Footer i18n={i18n} />
      </div>
    );
  }
}

HomeLayout.propTypes = {
  route: PropTypes.object,
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(HomeLayout);
