import { apiCaller } from '../../../helpers/apiCaller';
import get from 'lodash/get';

// service list
const getDocLegalServiceListRequest = () => ({
  type: 'GET_DOC_LEGAL_SERVICE_REQUEST'
});

const getDocLegalServiceListSuccess = (data) => ({
  type: 'GET_DOC_LEGAL_SERVICE_SUCCESS',
  docLegalService: data
});

const getDocLegalServiceListFailure = (error) => ({
  type: 'GET_DOC_LEGAL_SERVICE_FAILURE',
  docLegalService: error
});

const getDocLegalServiceList = () => {
  return (dispatch) => {
    dispatch(getDocLegalServiceListRequest());
    return apiCaller('document-legalization/service')
      .then((data) => dispatch(getDocLegalServiceListSuccess(data)))
      .catch((error) => dispatch(getDocLegalServiceListFailure(error)));
  };
};
// Number regis doc letter
const getNumberRegisterDocLegalRequest = () => ({
  type: 'GET_NUMBER_REGISTER_DOC_LEGAL_REQUEST'
});

const getNumberRegisterDocLegalSuccess = (data) => ({
  type: 'GET_NUMBER_REGISTER_DOC_LEGAL_SUCCESS',
  docLegalRegis: data
});

const getNumberRegisterDocLegalFailure = (error) => ({
  type: 'GET_NUMBER_REGISTER_DOC_LEGAL_FAILURE',
  docLegalRegis: error
});

const postNumberRegisterDocLegal = (params) => {
  return (dispatch) => {
    dispatch(getNumberRegisterDocLegalRequest());
    return apiCaller('document-legalization/registration', 'post', params)
      .then((data) => {
        if (get(data, ['response', 'data', 'status']) === 422) {
          return dispatch(getNumberRegisterDocLegalFailure(data));
        }

        return dispatch(getNumberRegisterDocLegalSuccess(data));
      })
      .then((data) => dispatch(getNumberRegisterDocLegalSuccess(data)))
      .catch((error) => dispatch(getNumberRegisterDocLegalFailure(error)));
  };
};

// Detail Number regis doc letter
const getRegisDetailDocLegalRequest = () => ({
  type: 'GET_REGIS_DETAIL_DOC_LEGAL_REQUEST'
});

const getRegisDetailDocLegalSuccess = (data) => ({
  type: 'GET_REGIS_DETAIL_DOC_LEGAL_SUCCESS',
  docLegalRegis: data
});

const getRegisDetailDocLegalFailure = (error) => ({
  type: 'GET_REGIS_DETAIL_DOC_LEGAL_FAILURE',
  docLegalRegis: error
});

const getRegisDetailDocLegal = (params) => {
  return (dispatch) => {
    dispatch(getRegisDetailDocLegalRequest());
    return apiCaller(`document-legalization/detail/${params}`)
      .then((data) => dispatch(getRegisDetailDocLegalSuccess(data)))
      .catch((error) => dispatch(getRegisDetailDocLegalFailure(error)));
  };
};

const resetNumberRegisterDoc = () => {
  const defaultData = {
    data: {},
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_NUMBER_REGISTER_DOC',
      data: defaultData
    });
  };
};

const resetDetailRegisterDoc = () => {
  const defaultData = {
    data: {},
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_DETAIL_REGISTER_DOC',
      data: defaultData
    });
  };
};

export {
  postNumberRegisterDocLegal,
  getRegisDetailDocLegal,
  getDocLegalServiceList,
  resetNumberRegisterDoc,
  resetDetailRegisterDoc
};
