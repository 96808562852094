import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Alert } from 'reactstrap';

import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import Icon from '../../../components/Icon';
import LayoutForm from '../LayoutForm';
import GeneralInformation from './GeneralInfo';
import PersonalInfo from './PersonalInfo';
import Passport from './Passport';
import Other from './Other';
import Photo from './Photo';
import SignIn from './SignIn';

import { errorAlertScroll } from '../../../helpers/functions';
import { postSubmitVisa, getVisaService, getCityId } from './action';
import { setParams, getVisaServiceTitle, setParamsStorage } from './functions';
import { visaType, getQuery } from '../../pages/Visa/function';
import { getVisaApplicant } from '../ApplicationInformationForm/action';

import './style.scss';

const VisaForm = ({
  history,
  i18n,
  postSubmitVisa,
  submitVisa,
  visaService,
  getVisaService,
  visaApplicant,
  getVisaApplicant,
  getCityId
}) => {
  const { data, error, status } = submitVisa;

  const [form, setForm] = useState({});
  const [tmpform, setTmpForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const { type, uniqueId, serviceId } = getQuery(history);
  const { location } = history;
  const { search } = location;
  const visaApplicantId = get(visaApplicant, ['data', 0, '_id']);

  const keyTitle = getVisaServiceTitle(get(visaService, ['data']), serviceId);
  const visaStringType = visaType[keyTitle];

  const dataStep = [
    {
      link: `/visa/form/general-info${search}`,
      label: 'form_visa.tab.general_info',
      keyForm: 'generalInfo',
      Component: GeneralInformation
    },
    {
      link: `/visa/form/personal-info${search}`,
      label: 'form_visa.tab.personal_info',
      keyForm: 'personalInfo',
      Component: PersonalInfo
    },
    {
      link: `/visa/form/passport${search}`,
      label: 'form_visa.tab.passport',
      keyForm: 'passport',
      Component: Passport
    },
    {
      link: `/visa/form/other${search}`,
      label: 'form_visa.tab.other',
      keyForm: 'other',
      Component: Other
    },
    {
      link: `/visa/form/photo${search}`,
      label: 'form_visa.tab.photo',
      keyForm: 'photo',
      Component: Photo
    },
    {
      link: `/visa/form/sign-in${search}`,
      label: 'form_visa.tab.sign_in',
      keyForm: 'signIn',
      Component: SignIn
    }
  ];

  const activePath = get(history, ['location', 'pathname']);
  const activeDetail = dataStep.find(({ link }) => link.includes(activePath));
  const ActiveComponent = get(activeDetail, ['Component']);

  const handleSubmit = () => {
    if (showModal) {
      const params = setParams({ ...form, applicationId: uniqueId, serviceId });
      setShowModal(false);
      postSubmitVisa(`/${visaApplicantId}`, params);
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePath]);

  useEffect(() => {
    if (!serviceId || !uniqueId) {
      history.push('/visa');
    } else {
      // FOR DEBUG
      // const tmpData = localStorage.getItem('visaParams');
      // const tmpForm = setParamsStorage(tmpData);
      // console.log(tmpForm);
      // setForm(tmpForm);
      getCityId();
      getVisaService();
      getVisaApplicant(`?unique_number=${uniqueId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const message = get(error, ['response', 'data', 'error', 'message']);
    const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
    const messageDetail = get(error, [
      'response',
      'data',
      'error',
      'detail',
      0,
      'message'
    ]);

    if (status === 'rejected' || message) {
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setShowModal(true);
      setTypeModal('success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, status]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  // Modal
  let modalProps = {
    className: 'confirm',
    buttonProps: [
      {
        btnStyle: { color: 'info', outline: true },
        label: i18n('cta.no'),
        onClick: () => setShowModal(false)
      },
      {
        btnStyle: { color: 'info' },
        label: i18n('cta.ok'),
        onClick: () => handleSubmit()
      }
    ],
    title: i18n('form_visa.confirm_modal.title'),
    desc: i18n('form_visa.confirm_modal.desc')
  };

  if (typeModal === 'success') {
    modalProps = {
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('cta.ok'),
          onClick: () => history.push('/visa')
        }
      ],
      className: 'success',
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>{i18n('success_save_modal.title')}</div>
        </div>
      ),
      desc: (
        <div
          style={{
            marginBottom: '16px'
          }}
        >
          {i18n('thankyou_modal.title')}
        </div>
      )
    };
  }

  return (
    <>
      {status === 'pending' && <Loading />}
      <LayoutForm
        i18n={i18n}
        parentPath='/visa/form'
        dataStep={dataStep}
        form={form}
        applicationId={uniqueId}
        title={
          visaStringType && i18n('form_visa.title', { name: i18n(visaStringType.label) })
        }
        formTitle={
          visaStringType &&
          i18n('form_visa.form_title', { type: i18n(visaStringType.label) })
        }
        formSubtitle={i18n('form_visa.form_subtitle')}
        withoutSelfReportBtn
      >
        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        {ActiveComponent && (
          <ActiveComponent
            setForm={setForm}
            form={form}
            i18n={i18n}
            setTmpForm={setTmpForm}
            tmpform={tmpform}
            handleSubmit={handleSubmit}
            type={type}
          />
        )}
      </LayoutForm>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

VisaForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  postSubmitVisa: PropTypes.func,
  submitVisa: PropTypes.object,
  visaService: PropTypes.object,
  getVisaService: PropTypes.func,
  visaApplicant: PropTypes.object,
  getVisaApplicant: PropTypes.func,
  getCityId: PropTypes.func
};

const mapStateToProps = (state) => ({
  submitVisa: {
    status: state.visaSubmitReducer.status,
    error: state.visaSubmitReducer.error,
    data: state.visaSubmitReducer.data
  },
  visaService: {
    status: state.visaServiceReducer.status,
    error: state.visaServiceReducer.error,
    data: state.visaServiceReducer.data
  },
  visaApplicant: {
    status: state.visaApplicantReducer.status,
    error: state.visaApplicantReducer.error,
    data: state.visaApplicantReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postSubmitVisa,
    getVisaService,
    getVisaApplicant,
    getCityId
  }),
  withRouter
)(VisaForm);
