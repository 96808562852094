const track = {
  data: {},
  status: 'idle',
  error: ''
};

const trackReducer = (state = track, action) => {
  switch (action.type) {
    case 'GET_TRACK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TRACK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_TRACK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_TRACK':
      return {
        ...state,
        ...track
      };
    default:
      return state;
  }
};

export { trackReducer };
