import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Alert } from 'reactstrap';
import * as yup from 'yup';
import moment from 'moment';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import Banner from '../../../../components/Banner';
import CardDetail from '../../../../components/CardDetail';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import Datepicker from '../../../../components/input/Datepicker';
import Icon from '../../../../components/Icon';

import { errorAlertScroll } from '../../../../helpers/functions';
import { postStatement, resetPostData } from './action';

const Statement = ({ history, i18n, statement, postStatement, resetPostData }) => {
  const { data, error, status } = statement;

  const [form, setForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [errorSave, setErrorSave] = useState('');
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      name_us_company: yup.string().required('required'),
      name_pres_company: yup.string().required('required'),
      legal_company: yup.string().required('required'),
      date_establishment: yup.string().required('required'),
      us_address: yup.string().required('required'),
      website: yup.string().url().required('required'),
      company_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      fax: yup.string().required('required'),
      name_representative: yup.string().required('required'),
      address_representative: yup.string().required('required'),
      phone_representative: yup.string().required('required'),
      fax_representative: yup.string().required('required'),
      nature_business: yup.string().required('required'),
      name: yup.string().required('required'),
      id_number: yup.string().required('required'),
      address: yup.string().required('required'),
      email: yup.string().email('email').required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      cell: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      length_appointment: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setShowModal(true);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const submit = () => {
    const params = {
      company: {
        name: get(form, ['name_us_company']),
        president: get(form, ['name_pres_company']),
        legal: get(form, ['legal_company']),
        establish_date: moment(get(form, ['date_establishment'])).format('YYYY-MM-DD'),
        address: get(form, ['us_address']),
        website: get(form, ['website']),
        phone: get(form, ['company_phone']),
        fax: get(form, ['fax'])
      },
      office: {
        name: get(form, ['name_representative']),
        address: get(form, ['address_representative']),
        phone: get(form, ['phone_representative']),
        fax: get(form, ['fax_representative']),
        duty: get(form, ['nature_business'])
      },
      chief: {
        name: get(form, ['name']),
        id: get(form, ['id_number']),
        address: get(form, ['address']),
        email: get(form, ['email']),
        phone: get(form, ['phone']),
        cell: get(form, ['cell']),
        term: get(form, ['length_appointment'])
      }
    };
    setShowModal(false);
    postStatement(params);
  };

  const handlerChanges = (value, key, errorKey) => {
    if (key === 'marital_status') {
      const tmp_form = { ...form };
      if (value !== 'married') {
        tmp_form.spouse = {};
      }
      setForm({ ...tmp_form, [key]: value });
    } else {
      setForm({ ...form, [key]: value });
    }
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
      const messageDetail = get(error, [
        'response',
        'data',
        'error',
        'detail',
        0,
        'message'
      ]);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setIsSuccessModal(true);
      setShowModal(true);
      setForm({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    return () => {
      resetPostData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let modalProps = {
    className: 'confirm',
    buttonProps: [
      {
        btnStyle: { color: 'info', outline: true },
        label: i18n('cta.no'),
        onClick: () => setShowModal(false)
      },
      {
        btnStyle: { color: 'info' },
        label: i18n('cta.ok'),
        onClick: () => submit()
      }
    ],
    title: i18n('confirm_modal.title'),
    desc: i18n('confirm_modal.desc')
  };

  if (isSuccessModal) {
    modalProps = {
      className: 'success complaint',
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('cta.ok'),
          onClick: () => setShowModal(false)
        }
      ],
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>{i18n('success_save_modal.title')}</div>
        </div>
      )
    };
  }

  return (
    <>
      {status === 'pending' && <Loading />}
      <Banner
        title={i18n('economy.form.statement')}
        subtitle={i18n('economy.form.statement')}
        backButtonClick={() => history.goBack()}
      />
      <CardDetail title={i18n('economy.form.statement')} className='pensosbud-form form'>
        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        <form onSubmit={handlerSubmit} id='graduate'>
          <div className='form-container'>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('form_personal_data.instruction_company')}
              </div>
              <TextInput
                label={i18n('form.name_us_company.label')}
                placeholder={i18n('form.name_us_company.placeholder')}
                value={get(form, ['name_us_company'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'name_us_company')
                }}
                errorMessage={
                  get(errors, ['name_us_company']) &&
                  i18n(`validation.${get(errors, ['name_us_company'])}`)
                }
              />
              <TextInput
                label={i18n('form.name_pres_company.label')}
                placeholder={i18n('form.name_pres_company.placeholder')}
                value={get(form, ['name_pres_company'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'name_pres_company')
                }}
                errorMessage={
                  get(errors, ['name_pres_company']) &&
                  i18n(`validation.${get(errors, ['name_pres_company'])}`)
                }
              />
              <Datepicker
                label={i18n('form.date_establishment.label')}
                placeholder={i18n('form.date_establishment.placeholder')}
                className='date_establishment'
                value={get(form, ['date_establishment'])}
                onChange={(passport_expired) =>
                  handlerChanges(passport_expired, 'date_establishment')
                }
                errorMessage={
                  get(errors, ['date_establishment']) &&
                  i18n(`validation.${get(errors, ['date_establishment'])}`)
                }
              />
              <TextInput
                label={i18n('form.legal_company.label')}
                placeholder={i18n('form.legal_company.placeholder')}
                value={get(form, ['legal_company'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'legal_company')
                }}
                errorMessage={
                  get(errors, ['legal_company']) &&
                  i18n(`validation.${get(errors, ['legal_company'])}`)
                }
              />
              <TextInput
                label={i18n('form.us_address.label')}
                placeholder={i18n('form.us_address.placeholder')}
                value={get(form, ['us_address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'us_address')
                }}
                errorMessage={
                  get(errors, ['us_address']) &&
                  i18n(`validation.${get(errors, ['us_address'])}`)
                }
              />
              <TextInput
                label={i18n('form.website.label')}
                placeholder={i18n('form.website.placeholder')}
                value={get(form, ['website'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'website')
                }}
                errorMessage={
                  get(errors, ['website']) &&
                  i18n(`validation.${get(errors, ['website'])}`)
                }
              />
              <TextInput
                label={i18n('form.phone.label')}
                placeholder={i18n('form.phone.placeholder')}
                value={get(form, ['company_phone'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'company_phone')
                }}
                errorMessage={
                  get(errors, ['company_phone']) &&
                  i18n(`validation.${get(errors, ['company_phone'])}`)
                }
              />
              <TextInput
                label={i18n('form.fax.label')}
                placeholder={i18n('form.fax.placeholder')}
                value={get(form, ['fax'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'fax')
                }}
                errorMessage={
                  get(errors, ['fax']) && i18n(`validation.${get(errors, ['fax'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('form_personal_data.instruction_representative')}
              </div>
              <TextInput
                label={i18n('form.name_representative.label')}
                placeholder={i18n('form.name_representative.placeholder')}
                value={get(form, ['name_representative'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'name_representative')
                }}
                errorMessage={
                  get(errors, ['name_representative']) &&
                  i18n(`validation.${get(errors, ['name_representative'])}`)
                }
              />
              <TextInput
                label={i18n('form.address_representative.label')}
                placeholder={i18n('form.address_representative.placeholder')}
                value={get(form, ['address_representative'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) =>
                    handlerChanges(e.target.value, 'address_representative')
                }}
                errorMessage={
                  get(errors, ['address_representative']) &&
                  i18n(`validation.${get(errors, ['address_representative'])}`)
                }
              />
              <TextInput
                label={i18n('form.phone.label')}
                placeholder={i18n('form.phone.placeholder')}
                value={get(form, ['phone_representative'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'phone_representative')
                }}
                errorMessage={
                  get(errors, ['phone_representative']) &&
                  i18n(`validation.${get(errors, ['phone_representative'])}`)
                }
              />
              <TextInput
                label={i18n('form.fax.label')}
                placeholder={i18n('form.fax.placeholder')}
                value={get(form, ['fax_representative'])}
                inputProps={{
                  type: 'number',
                  onChange: (e) => handlerChanges(e.target.value, 'fax_representative')
                }}
                errorMessage={
                  get(errors, ['fax_representative']) &&
                  i18n(`validation.${get(errors, ['fax_representative'])}`)
                }
              />
              <TextInput
                label={i18n('form.nature_business.label')}
                placeholder={i18n('form.nature_business.placeholder')}
                value={get(form, ['nature_business'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'nature_business')
                }}
                errorMessage={
                  get(errors, ['nature_business']) &&
                  i18n(`validation.${get(errors, ['nature_business'])}`)
                }
              />
            </div>
            <div className='form-wrap'>
              <div className='title'>
                {i18n('form_personal_data.instruction_chief_representative')}
              </div>
              <TextInput
                label={i18n('form.name.label')}
                placeholder={i18n('form.name.placeholder')}
                value={get(form, ['name'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'name')
                }}
                errorMessage={
                  get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
                }
              />
              <TextInput
                label={i18n('form.id_number.label')}
                placeholder={i18n('form.id_number.placeholder')}
                value={get(form, ['id_number'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'id_number')
                }}
                errorMessage={
                  get(errors, ['id_number']) &&
                  i18n(`validation.${get(errors, ['id_number'])}`)
                }
              />
              <TextInput
                label={i18n('form.address.label')}
                placeholder={i18n('form.address.placeholder')}
                value={get(form, ['address'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'address')
                }}
                errorMessage={
                  get(errors, ['address']) &&
                  i18n(`validation.${get(errors, ['address'])}`)
                }
              />
              <TextInput
                label={i18n('form.email.label')}
                placeholder={i18n('form.email.placeholder')}
                value={get(form, ['email'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'email')
                }}
                errorMessage={
                  get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
                }
              />
              <TextInput
                label={i18n('form.phone.label')}
                placeholder={i18n('form.phone.placeholder')}
                value={get(form, ['phone'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'phone')
                }}
                errorMessage={
                  get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
                }
              />
              <TextInput
                label={i18n('form.cell.label')}
                placeholder={i18n('form.cell.placeholder')}
                value={get(form, ['cell'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'cell')
                }}
                errorMessage={
                  get(errors, ['cell']) && i18n(`validation.${get(errors, ['cell'])}`)
                }
              />
              <TextInput
                label={i18n('form.length_appointment.label')}
                placeholder={i18n('form.length_appointment.placeholder')}
                value={get(form, ['length_appointment'])}
                inputProps={{
                  maxLength: 255,
                  onChange: (e) => handlerChanges(e.target.value, 'length_appointment')
                }}
                errorMessage={
                  get(errors, ['length_appointment']) &&
                  i18n(`validation.${get(errors, ['length_appointment'])}`)
                }
              />
            </div>
          </div>

          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.goBack()}>
              {i18n('cta.cancel')}
            </Button>
            <Button color='primary' type='submit' className='pass-btn'>
              {i18n('cta.submit')}
            </Button>
          </div>
        </form>
      </CardDetail>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

Statement.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  statement: PropTypes.object,
  postStatement: PropTypes.func,
  resetPostData: PropTypes.func
};

const mapStateToProps = (state) => ({
  statement: {
    status: state.statementReducer.status,
    error: state.statementReducer.error,
    data: state.statementReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { postStatement, resetPostData }),
  withRouter
)(Statement);
