import { apiCaller } from '../../../helpers/apiCaller';

const getDocLegalListRequest = () => ({
  type: 'GET_DOC_LEGAL_REQUEST'
});

const getDocLegalListSuccess = (data) => ({
  type: 'GET_DOC_LEGAL_SUCCESS',
  payload: data
});

const getDocLegalListFailure = (error) => ({
  type: 'GET_DOC_LEGAL_FAILURE',
  payload: error
});

const resetDocLegalList = () => ({
  type: 'RESET_DOC_LEGAL_LIST'
});

const getDocLegalList = (params) => {
  return (dispatch) => {
    dispatch(getDocLegalListRequest());
    return apiCaller(
      'admin/document-legalization/registration',
      'get',
      undefined,
      true,
      params
    )
      .then((data) => dispatch(getDocLegalListSuccess(data)))
      .catch((error) => dispatch(getDocLegalListFailure(error)));
  };
};

// DAY
const getDocLegalStatisticRequest = () => ({
  type: 'GET_DOC_LEGAL_STATISTIC_REQUEST'
});

const getDocLegalStatisticSuccess = (data) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_SUCCESS',
  payload: data
});

const getDocLegalStatisticFailure = (error) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_FAILURE',
  payload: error
});

const getDocLegalStatistic = (params) => {
  return (dispatch) => {
    dispatch(getDocLegalStatisticRequest());
    return apiCaller(
      'admin/document-legalization/statistic',
      'get',
      undefined,
      true,
      params
    )
      .then((data) => dispatch(getDocLegalStatisticSuccess(data)))
      .catch((error) => dispatch(getDocLegalStatisticFailure(error)));
  };
};

// WEEK
const getDocLegalStatisticWeekRequest = () => ({
  type: 'GET_DOC_LEGAL_STATISTIC_WEEK_REQUEST'
});

const getDocLegalStatisticWeekSuccess = (data) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getDocLegalStatisticWeekFailure = (error) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getDocLegalStatisticWeek = (params) => {
  return (dispatch) => {
    dispatch(getDocLegalStatisticWeekRequest());
    return apiCaller(
      'admin/document-legalization/statistic',
      'get',
      undefined,
      true,
      params
    )
      .then((data) => dispatch(getDocLegalStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getDocLegalStatisticWeekFailure(error)));
  };
};

// MONTH
const getDocLegalStatisticMonthRequest = () => ({
  type: 'GET_DOC_LEGAL_STATISTIC_MONTH_REQUEST'
});

const getDocLegalStatisticMonthSuccess = (data) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getDocLegalStatisticMonthFailure = (error) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getDocLegalStatisticMonth = (params) => {
  return (dispatch) => {
    dispatch(getDocLegalStatisticMonthRequest());
    return apiCaller(
      'admin/document-legalization/statistic',
      'get',
      undefined,
      true,
      params
    )
      .then((data) => dispatch(getDocLegalStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getDocLegalStatisticMonthFailure(error)));
  };
};

// ALL
const getDocLegalStatisticAllRequest = () => ({
  type: 'GET_DOC_LEGAL_STATISTIC_ALL_REQUEST'
});

const getDocLegalStatisticAllSuccess = (data) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_ALL_SUCCESS',
  payload: data
});

const getDocLegalStatisticAllFailure = (error) => ({
  type: 'GET_DOC_LEGAL_STATISTIC_ALL_FAILURE',
  payload: error
});

const getDocLegalStatisticAll = (params) => {
  return (dispatch) => {
    dispatch(getDocLegalStatisticAllRequest());
    return apiCaller(
      'admin/document-legalization/statistic',
      'get',
      undefined,
      true,
      params
    )
      .then((data) => dispatch(getDocLegalStatisticAllSuccess(data)))
      .catch((error) => dispatch(getDocLegalStatisticAllFailure(error)));
  };
};

// APPROVE
const patchApproveRequest = () => ({
  type: 'PATCH_DOC_LEGAL_APPROVE_REQUEST'
});

const patchApproveSuccess = (data) => ({
  type: 'PATCH_DOC_LEGAL_APPROVE_SUCCESS',
  payload: data
});

const patchApproveFailure = (error) => ({
  type: 'PATCH_DOC_LEGAL_APPROVE_FAILURE',
  payload: error
});

const patchApprove = (id) => {
  return (dispatch) => {
    dispatch(patchApproveRequest());
    return apiCaller(
      `admin/document-legalization/approve/${id}`,
      'patch',
      undefined,
      true
    )
      .then((data) => dispatch(patchApproveSuccess(data)))
      .catch((error) => dispatch(patchApproveFailure(error)));
  };
};

// REJECT
const patchRejectRequest = () => ({
  type: 'PATCH_DOC_LEGAL_REJECT_REQUEST'
});

const patchRejectSuccess = (data) => ({
  type: 'PATCH_DOC_LEGAL_REJECT_SUCCESS',
  payload: data
});

const patchRejectFailure = (error) => ({
  type: 'PATCH_DOC_LEGAL_REJECT_FAILURE',
  payload: error
});

const patchReject = (id, params) => {
  return (dispatch) => {
    dispatch(patchRejectRequest());
    return apiCaller(`admin/document-legalization/reject/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchRejectSuccess(data)))
      .catch((error) => dispatch(patchRejectFailure(error)));
  };
};

// PROCESS
const patchProcessRequest = () => ({
  type: 'PATCH_DOC_LEGAL_PROCESS_REQUEST'
});

const patchProcessSuccess = (data) => ({
  type: 'PATCH_DOC_LEGAL_PROCESS_SUCCESS',
  payload: data
});

const patchProcessFailure = (error) => ({
  type: 'PATCH_DOC_LEGAL_PROCESS_FAILURE',
  payload: error
});

const patchProcess = (id) => {
  return (dispatch) => {
    dispatch(patchProcessRequest());
    return apiCaller(
      `admin/document-legalization/process/${id}`,
      'patch',
      undefined,
      true
    )
      .then((data) => dispatch(patchProcessSuccess(data)))
      .catch((error) => dispatch(patchProcessFailure(error)));
  };
};

// PENDING
const patchPendingRequest = () => ({
  type: 'PATCH_DOC_LEGAL_PENDING_REQUEST'
});

const patchPendingSuccess = (data) => ({
  type: 'PATCH_DOC_LEGAL_PENDING_SUCCESS',
  payload: data
});

const patchPendingFailure = (error) => ({
  type: 'PATCH_DOC_LEGAL_PENDING_FAILURE',
  payload: error
});

const patchPending = (id, params) => {
  return (dispatch) => {
    dispatch(patchPendingRequest());
    return apiCaller(`admin/document-legalization/pending/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchPendingSuccess(data)))
      .catch((error) => dispatch(patchPendingFailure(error)));
  };
};

export {
  resetDocLegalList,
  getDocLegalList,
  getDocLegalStatistic,
  getDocLegalStatisticWeek,
  getDocLegalStatisticMonth,
  getDocLegalStatisticAll,
  patchApprove,
  patchReject,
  patchProcess,
  patchPending
};
