import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll } from '../../../../../helpers/functions';

import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/input/TextInput';
import Dropdown from '../../../../../components/Dropdown';
import StateZip from '../../../components/StateZip';

import job_options from '../../../../../constant/job_list';

const JobForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  setTmpForm,
  tmpform
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({ ...(formGlobal.work || tmpform.work) });
  const [errors, setErrors] = useState({});

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      title: yup.string().required('required'),
      ...(form.title !== 'Tidak Bekerja' && {
        company_name: yup.string().required('required'),
        company_address: yup.string().required('required'),
        state: yup.object().shape({
          value: yup.string().required('required')
        }),
        city: yup.string().required('required'),
        zip_code: yup.string().required('required'),
        company_phone: yup
          .string()
          .test('len', i18n('validation.min_length', { min: 10 }), (val) =>
            val && val !== '' ? val.toString().length > 9 : true
          )
      })
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, work: form });
        history.push(`/passpor/registration/parent${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });

    const tmpErros = { ...errors };
    if (key === 'title' && value === 'Tidak Bekerja') {
      tmpErros['state.value'] = undefined;
      tmpErros['city'] = undefined;
      tmpErros['zip_code'] = undefined;
    }

    setErrors({ ...tmpErros, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, work: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <form onSubmit={handlerSubmit} id='job'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>{i18n('form_personal_data.instruction_job')}</div>

          <Dropdown
            className='row input-container'
            label={i18n('form.job.label')}
            placeholder={i18n('form.job.placeholder')}
            value={get(form, ['title'])}
            options={job_options}
            onClick={(item) => handlerChanges(item, 'title', 'title')}
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            withAutocomplete
            errorMessage={
              get(errors, ['title']) && i18n(`validation.${get(errors, ['title'])}`)
            }
          />
          <TextInput
            label={i18n('form.scholl_office_name.label')}
            placeholder={i18n('form.scholl_office_name.placeholder')}
            value={get(form, ['company_name'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'company_name')
            }}
            errorMessage={
              get(errors, ['company_name']) &&
              i18n(`validation.${get(errors, ['company_name'])}`)
            }
            detailInput={i18n('form.scholl_office_name.notes')}
          />
          <TextInput
            label={i18n('form.scholl_office_address.label')}
            placeholder={i18n('form.scholl_office_address.placeholder')}
            value={get(form, ['company_address'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'company_address')
            }}
            errorMessage={
              get(errors, ['company_address']) &&
              i18n(`validation.${get(errors, ['company_address'])}`)
            }
            detailInput={i18n('form.scholl_office_address.notes')}
          />
          <StateZip
            i18n={i18n}
            form={form}
            handlerChanges={handlerChanges}
            errors={errors}
          />
          <TextInput
            label={i18n('form.phone_office.label')}
            placeholder={i18n('form.phone_office.placeholder')}
            value={get(form, ['company_phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'company_phone')
            }}
            errorMessage={
              get(errors, ['company_phone']) &&
              i18n(`validation.${get(errors, ['company_phone'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/passpor/registration/american-address${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

JobForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object
};

export default withRouter(JobForm);
