import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import DashboardTable from '../../../../components/DashboardTable';
import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import DocumentReview from '../../../pages/Paspor/Review/components/DocumentReview';

import passportService from '../../../../constant/passportService';
import {
  getRegistrationDetail,
  getSubmissionDetail,
  getPassportDocList
} from '../../../form/PassportForm/action';

import { printPreview, typeModalList } from '../../functions';
import { patchApprove, patchReject, patchProcess, patchPending } from '../action';

import './style.scss';

const DetailPassport = ({
  history,
  getRegistrationDetail,
  registerDetail,
  getSubmissionDetail,
  submissionDetail,
  i18n,
  getPassportDocList,
  passportDoc,
  patchApprove,
  patchProcess,
  patchPending,
  pending,
  approve,
  process
}) => {
  const { type, id: unique_id } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [selectedData, setSelectedData] = useState({});
  const [reason, setReason] = useState();
  const [loadingAction, setLoadingAction] = useState(false);
  const [actionData, setActionData] = useState();

  const detailPassport = get(registerDetail, ['data']);
  const loadingDetail = get(registerDetail, ['status']) === 'pending';
  const linkPdfDownload = get(registerDetail, ['data', 'pdf']);
  const serviceId = get(registerDetail, ['data', 'passport_service_id']);
  const status = get(registerDetail, ['data', 'status']);
  const passportDocList = get(passportDoc, ['data']) || [];
  const detailSubmission = get(submissionDetail, ['data']);
  const appointment = get(detailSubmission, ['appointment']);

  // action
  const { status: statusApprove, data: dataApprove, error: errorApprove } = approve;
  const { status: statusProcess, data: dataProcess, error: errorProcess } = process;
  const { status: statusPending, data: dataPending, error: errorPending } = pending;

  const handlerAction = (type, data) => {
    if (showModal) {
      setTypeModal();
      setSelectedData({});
      setShowModal(false);
    } else {
      setTypeModal(type);
      setSelectedData(data);
      setShowModal(true);
    }
  };

  const handlerSubmitAction = () => {
    const id = get(registerDetail, ['data', 'id']);
    if (typeModal === 'process') {
      patchProcess(id);
    } else if (typeModal === 'reject') {
      patchReject(id, {
        note: reason
      });
    } else if (typeModal === 'finish') {
      patchApprove(id);
    } else if (typeModal === 'tunda') {
      patchPending(id, {
        note: reason
      });
    } else {
      //ACCEPT / TOLAK DOC
      console.log(typeModal, 'TYPEMODAL');
    }

    setShowModal(false);
    setLoadingAction(true);
  };

  const handlerPrint = (data) => {
    const file = get(data, ['file']);
    const isPdfFile = file.includes('pdf');
    if (isPdfFile) {
      handlerAction('print_preview', data);
    } else {
      printPreview(`<div >
        <h3>${get(data, ['name'])} ${unique_id}</h3>
        <img src="${file}" alt='' width='600' />
      </div>`);
    }
  };

  const actionList = {
    view: { onClick: (id, data) => handlerAction('view', data), type: 'view' },
    print: { onClick: (id, data) => handlerPrint(data), type: 'print' }
  };

  const action = () => {
    return [actionList.view, actionList.print];
  };

  const columns = [
    { dataField: 'date', text: 'Tanggal Upload Dokumen' },
    { dataField: 'name', text: 'Nama Berkas' },
    { dataField: 'file', text: 'Berkas', className: 'doc-link' },
    { dataField: 'status', text: 'Status' },
    { dataField: 'id', text: 'CustomAction' }
  ];
  const totalDoc = passportDocList.length;
  let totalDetailDoc = 0;

  const documentData = useMemo(() => {
    const list = [];
    passportDocList.map(({ number, name }) => {
      const file = get(detailPassport, ['documents', `doc_${number}`]);
      const updatedAt = get(detailPassport, ['updated_at']);
      list.push({
        id: number,
        name,
        file,
        date: updatedAt,
        status: get(detailPassport, ['status'])
      });

      if (file !== '') {
        totalDetailDoc += 1;
      }
    });
    return list;
  }, [passportDocList, registerDetail]);

  const personalData = {
    name: get(detailPassport, ['biography', 'name']),
    email: get(detailPassport, ['address', 'email']),
    phone: get(detailPassport, ['address', 'phone']),
    unique_id,
    citizenship: 'Indonesia',
    bookingTime: get(detailPassport, ['created_at'])
  };

  const modalProps = () => {
    let tmpModalProps = {};
    if (typeModal === 'accept') {
      tmpModalProps.title = 'Konfirmasi!';
      tmpModalProps.desc = (
        <span>
          Apakah Anda yakin akan <b>memverifikasi</b> berkas ini?
        </span>
      );
      tmpModalProps.cta = {
        yes: {
          text: 'Verifikasi',
          color: 'primary'
        },
        no: {
          text: 'Tidak'
        }
      };
    } else if (typeModal === 'reject') {
      tmpModalProps.title = 'Konfirmasi!';
      tmpModalProps.desc = (
        <span>
          Apakah Anda yakin akan <b>menolak</b> berkas ini?
        </span>
      );
      tmpModalProps.cta = {
        yes: {
          text: 'Ya, Tolak!',
          color: 'warn'
        },
        no: {
          text: 'Tidak'
        }
      };
    } else if (typeModal === 'view' || typeModal === 'print_preview') {
      const file = get(selectedData, ['file']);
      const isPdfFile = file.includes('pdf');
      if (isPdfFile) {
        tmpModalProps.desc = (
          <a href={file} target='_blank'>
            {typeModal === 'print_preview'
              ? 'Klik link ini untuk cetak dokumen PDF'
              : 'File PDF'}
            {get(selectedData, ['name'])}
          </a>
        );
      } else if (file && file !== '') {
        tmpModalProps.desc = <img src={get(selectedData, ['file'])} alt='' width='800' />;
      } else {
        tmpModalProps.desc = 'No file';
      }
    } else if (typeModal === 'process') {
      tmpModalProps = typeModalList[typeModal]({
        name: get(personalData, ['name']),
        actionData
      });
    } else if (typeModal === 'tunda') {
      const onChange = (e) => setReason(e.target.value);
      const name = get(personalData, ['name']);

      tmpModalProps = typeModalList[typeModal]({ name, reason, onChange, actionData });
    } else if (typeModal && typeModal !== '') {
      const name = get(personalData, ['name']);

      tmpModalProps = typeModalList[typeModal]({ name, reason, actionData });
    }
    return tmpModalProps;
  };

  useEffect(() => {
    getRegistrationDetail(`/${unique_id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSubmissionDetail(`/${unique_id}`);
  }, [])

  useEffect(() => {
    if (serviceId) {
      getPassportDocList(serviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  useEffect(() => {
    if (loadingAction) {
      if (
        statusApprove === 'resolve' ||
        statusProcess === 'resolve' ||
        statusPending === 'resolve'
      ) {
        let data = {};
        let error = {};
        if (typeModal === 'process') {
          data = dataProcess;
          error = errorProcess;
        } else if (typeModal === 'finish') {
          data = dataApprove;
          error = errorApprove;
        } else if (typeModal === 'tunda') {
          data = dataPending;
          error = errorPending;
        }

        const messageError = get(error, ['response', 'data', 'error', 'message']);
        const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
        const messageDetail = get(error, [
          'response',
          'data',
          'error',
          'detail',
          0,
          'message'
        ]);

        const { status, message } = data;
        if (status) {
          // show success message
          handlerAction('successSave', {});
          getRegistrationDetail(`/${unique_id}`);
          getSubmissionDetail(`/${unique_id}`);
        } else {
          const messageErrorFinal =
            message !== '' ? message : `${messageError}. ${keyDetail}: ${messageDetail}.`;
          handlerAction('failedSave', {});
          setActionData(messageErrorFinal);
        }
        setLoadingAction(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAction, statusApprove, statusProcess, statusPending]);

  return (
    <div className='admin-paspor-detail'>
      {(loadingDetail || loadingAction) && <Loading />}
      <span className='back-homepage' onClick={() => history.goBack()}>
        <span className='btn-back' />
        Detail {passportService[type]}
      </span>
      <div className='content'>
        <div className='detail-top'>
          <div className='img' />
          <div className='detail-top-user'>
            <table>
              <tr>
                <td>
                  <label>Nama Lengkap</label>
                </td>
                <td>{get(personalData, ['name'])}</td>
              </tr>
              <tr>
                <td>
                  <label>Email</label>
                </td>
                <td>{get(personalData, ['email'])}</td>
              </tr>
              <tr>
                <td>
                  <label>Nomor Telepon</label>
                </td>
                <td>{get(personalData, ['phone'])}</td>
              </tr>
            </table>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Nomor Registrasi</label>
            </div>
            <div className='det-value'>
              {get(personalData, ['unique_id'])}
              <span className='label'>{get(detailPassport, ['status'])}</span>
            </div>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Kewarganegaraan</label>
            </div>
            <div className='det-value'>{get(personalData, ['citizenship'])}</div>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Tanggal Booking</label>
            </div>
            <div className='det-value'>
              {moment(get(appointment, ['scheduled_at'])).format('LLL')}
            </div>
          </div>
        </div>
        <div className='detail-mid'>
          <div className='detail-mid-top'>
            <div className='detail-mid-left'>
              <div className='title'>Berkas berkas dokumen</div>
              <p>
                Silakan mengecek dan meastikan kebenaran setiap berkas yang telah diunggah
                oleh pemohon
              </p>
            </div>
            <div className='detail-mid-right'>
              <div className='detail-mid-right-content'>
                <div className='subject'>
                  Total
                  <br />
                  Berkas
                </div>
                <div className='total'>
                  {totalDetailDoc}/{totalDoc}
                </div>
              </div>
            </div>
          </div>
          <DashboardTable
            columns={columns}
            data={documentData}
            action={action}
            search='nama_berkas'
          />
        </div>
        <div className='detail-bottom'>
          <div className='title'>Rekapitulasi Data {passportService[type]} </div>
          <DocumentReview i18n={i18n} data={detailPassport} />
        </div>

        <div className='btn-bottom-freeze'>
          <a
            href={linkPdfDownload}
            target='_blank'
            without
            rel='noopener noreferrer'
            className='the-btn btn btn-outline-primary '
          >
            <Icon icon='print' />
            {i18n('cta.print_report')}
          </a>
          <div className='right-pos'>
            {status === 'REGISTERED' && (
              <>
                <Button color='warn' onClick={() => handlerAction('tunda')}>
                  <Icon icon='close' />
                  Tunda Proses
                </Button>
                <Button color='primary' onClick={() => handlerAction('process')}>
                  <Icon icon='check' />
                  Proses
                </Button>
              </>
            )}
            {status === 'PROCESS' && (
              <>
                <Button color='primary' onClick={() => handlerAction('finish')}>
                  <Icon icon='check' />
                  Proses
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className={get(modalProps(), ['className']) || 'confirm'}
        {...(get(modalProps(), ['cta'])
          ? {
              buttonProps: [
                {
                  btnStyle: {
                    color: get(modalProps(), ['cta', 'no', 'color']) || 'info',
                    outline: get(modalProps(), ['cta', 'no', 'outline']) || true
                  },
                  label: get(modalProps(), ['cta', 'no', 'text']),
                  onClick: () => {
                    handlerAction();
                    setShowModal(false);
                  }
                },
                {
                  btnStyle: { color: get(modalProps(), ['cta', 'yes', 'color']) },
                  label: get(modalProps(), ['cta', 'yes', 'text']),
                  onClick: handlerSubmitAction
                }
              ]
            }
          : {})}
        title={get(modalProps(), ['title'])}
        desc={get(modalProps(), ['desc'])}
      />
    </div>
  );
};

DetailPassport.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  getRegistrationDetail: PropTypes.func,
  registerDetail: PropTypes.object,
  getPassportDocList: PropTypes.func,
  passportDoc: PropTypes.object,
  getSubmissionDetail: PropTypes.func,
  submissionDetail: PropTypes.object,
};

const mapStateToProps = (state) => ({
  registerDetail: {
    status: state.registrationDetailReducer.status,
    error: state.registrationDetailReducer.error,
    data: state.registrationDetailReducer.data
  },
  submissionDetail: {
    status: state.submissionDetailReducer.status,
    error: state.submissionDetailReducer.error,
    data: state.submissionDetailReducer.data
  },
  passportDoc: {
    status: state.passportDocReducer.status,
    error: state.passportDocReducer.error,
    data: state.passportDocReducer.data
  },
  approve: {
    status: state.patchApproveReducer.status,
    error: state.patchApproveReducer.error,
    data: state.patchApproveReducer.data
  },
  reject: {
    status: state.patchRejectReducer.status,
    error: state.patchRejectReducer.error,
    data: state.patchRejectReducer.data
  },
  process: {
    status: state.patchProcessReducer.status,
    error: state.patchProcessReducer.error,
    data: state.patchProcessReducer.data
  },
  pending: {
    status: state.patchPendingReducer.status,
    error: state.patchPendingReducer.error,
    data: state.patchPendingReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getRegistrationDetail,
    getSubmissionDetail,
    getPassportDocList,
    patchApprove,
    patchReject,
    patchProcess,
    patchPending
  }),
  withRouter
)(DetailPassport);
