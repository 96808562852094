const tours = {
  data: {},
  status: 'idle',
  error: ''
};

const newTour = {
  data: {},
  status: 'idle',
  error: ''
};

const editTour = {
  data: {},
  status: 'idle',
  error: ''
};

const deleteTour = {
  data: {},
  status: 'idle',
  error: ''
};

const toursReducerAdmin = (state = tours, action) => {
  switch (action.type) {
    case 'GET_TOURS_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TOURS_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_TOURS_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const newTourReducerAdmin = (state = newTour, action) => {
  switch (action.type) {
    case 'POST_TOUR_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_TOUR_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_TOUR_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const editTourReducerAdmin = (state = editTour, action) => {
  switch (action.type) {
    case 'EDIT_TOUR_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'EDIT_TOUR_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'EDIT_TOUR_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const deleteTourReducerAdmin = (state = deleteTour, action) => {
  switch (action.type) {
    case 'DELETE_TOUR_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_TOUR_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_TOUR_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export {
  toursReducerAdmin,
  newTourReducerAdmin,
  deleteTourReducerAdmin,
  editTourReducerAdmin
};
