import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListServeForm from '../../../form/PensosbudForm/ListServe';
import { getSosBudServiceId } from '../../../form/PensosbudForm/functions';

const ListServe = ({ i18n, sosBudServiceList }) => {
  const lang = (type, params) => i18n(`pensosbud.tab11.${type}`, { ...params });
  const serviceId = getSosBudServiceId(sosBudServiceList, 'listServe');

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />
        </div>
        <ListServeForm i18n={i18n} serviceId={serviceId} />
      </main>
    </div>
  );
};

ListServe.propTypes = {
  i18n: PropTypes.func,
  sosBudServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(ListServe);
