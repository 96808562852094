import get from 'lodash/get';
import statementServiceKey from '../../../constant/statementLetterService';

export const statementType = {
  birth: {
    label: 'statement_letter.tab1.title',
    key: 'Surat Keterangan Pencatatan Kelahiran',
    list: [
      'copy_passport_parent',
      'copy_parent_marriage_certificate',
      'copy_birth_letter',
      'copy_id_parent',
      'no_fee'
    ]
  },
  married: {
    label: 'statement_letter.tab2.title',
    key: 'SURAT KETERANGAN PENCATATAN PERNIKAHAN',
    list: [
      'copy_marriage_certificate',
      'copy_passport_spouse',
      'copy_id_spouse',
      'no_fee'
    ]
  },
  death: {
    label: 'statement_letter.tab3.title',
    key: 'SURAT KETERANGAN KEMATIAN',
    list: [
      'copy_passport_death',
      'letter_death',
      'letter_funeral',
      'letter_funeral_balm',
      'letter_funeral_kremasi',
      'no_fee'
    ]
  },
  death2: {
    label: 'statement_letter.tab4.title',
    key: 'SURAT KETERANGAN KEMATIAN UNTUK PENGIRIMAN JENAZAH ATAU PENGIRIMAN ABU JENAZAH',
    list: [
      'copy_passport_death',
      'letter_death',
      'letter_funeral',
      'letter_funeral_balm',
      'letter_funeral_kremasi',
      'copy_id_recipient_body',
      'no_fee'
    ]
  },
  domisili: {
    label: 'statement_letter.tab5.title',
    key: 'SURAT KETERANGAN DOMISILI',
    list: ['copy_passport_apply', 'address_us', 'no_fee']
  },
  move: {
    label: 'statement_letter.tab6.title',
    key: 'SURAT KETERANGAN BARANG PINDAHAN',
    list: [
      'move_letter',
      'copy_passport_visa',
      'stuff_list',
      'letter_scholl_work',
      'stuff_letter'
    ]
  }
};

export const getStatementServiceId = (list, key) => {
  const statementServiceSelected = list.find(
    ({ name }) => name === statementServiceKey[key]
  );

  return get(statementServiceSelected, ['id']);
};

export const getServiceTitle = (list, serviceId) => {
  const serviceSelected = list.find(({ id }) => id === serviceId);

  const findKeyTitle = Object.keys(statementServiceKey).find(
    (key) => statementServiceKey[key] === get(serviceSelected, ['name'])
  );

  return findKeyTitle;
};

export const getServiceTitleByName = (name) => {
  const findKeyTitle = Object.keys(statementServiceKey).find(
    (key) => statementServiceKey[key] === name
  );

  if (findKeyTitle) {
    return statementType[findKeyTitle].label;
  }
};
