import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const TextInputNoob = ({ label, placeholder, value, action }) => {
  const handleTextChange = (e) => {
    action(e);
  };

  return (
    <div className='text-input-noob'>
      <div className='label'>{label}</div>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleTextChange(e.target.value)}
      />
    </div>
  );
};

TextInputNoob.propTypes = {
  label: PropTypes.string,
  action: PropTypes.any,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

export default TextInputNoob;
