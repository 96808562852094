import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InstructionWrap from '../../../../components/InstructionWrap';

const Pet = ({ i18n }) => {
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('faq.tab2.title')}</div>
      <main>
        <InstructionWrap instruction={i18n('faq.tab2.content1')} />
      </main>
    </div>
  );
};

Pet.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Pet);
