import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Alert } from 'reactstrap';

import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import Icon from '../../../components/Icon';
import LayoutForm from '../LayoutForm';
import PersonalInformation from './PersonalData/PersonalInformation';
import PassporVisa from './PersonalData/PassporVisa';
import AmericanAddress from './PersonalData/AmericanAddress';
import Job from './PersonalData/Job';
import Parent from './PersonalData/Parent';
import EmergencyContact from './PersonalData/EmergencyContact';
import UploadDoc from './PersonalData/UploadDoc';

import { errorAlertScroll } from '../../../helpers/functions';
import { postRegisterDetail } from './action';
import { setParams, getPassportServiceTitle, setParamsDoc } from './functions';
import { getQuery } from '../../pages/Paspor/function';
import { passport_service } from '../../pages/Paspor/Schedule/functions';

import { getPassportServiceList, getCountryList } from '../PassportForm/action';

import './style.scss';

const FormPassport = ({
  history,
  i18n,
  postRegisterDetail,
  registerDetail,
  getPassportServiceList,
  passportService,
  country,
  getCountryList
}) => {
  const { error, status } = registerDetail;

  const [form, setForm] = useState({});
  const [tmpform, setTmpForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { type, serviceId, uniqueId } = getQuery(history);
  const { location } = history;
  const { search } = location;
  const keyTitle = getPassportServiceTitle(get(passportService, ['data']), serviceId);
  const title =
    keyTitle &&
    passport_service[keyTitle] &&
    i18n(`paspor_page.${passport_service[keyTitle].title}`);
  const country_options = get(country, ['data']);

  const personalDataStep = [
    {
      link: `/passpor/registration/personal-info${search}`,
      label: 'form_personal_data.tab.personal_info',
      keyForm: 'personalInfo',
      Component: PersonalInformation
    },
    {
      link: `/passpor/registration/passpor-visa${search}`,
      label: 'form_personal_data.tab.passpor_visa',
      keyForm: 'passportVisa',
      Component: PassporVisa
    },
    {
      link: `/passpor/registration/american-address${search}`,
      label: 'form_personal_data.tab.american_address',
      keyForm: 'address',
      Component: AmericanAddress
    },
    {
      link: `/passpor/registration/job${search}`,
      label: 'form_personal_data.tab.job',
      keyForm: 'work',
      Component: Job
    },
    {
      link: `/passpor/registration/parent${search}`,
      label: 'form_personal_data.tab.parent',
      keyForm: 'parent',
      Component: Parent
    },
    {
      link: `/passpor/registration/emergency-contact${search}`,
      label: 'form_personal_data.tab.emergency_contact',
      keyForm: 'emergencyContact',
      Component: EmergencyContact
    },
    {
      link: `/passpor/registration/document${search}`,
      label: 'form_personal_data.tab.upload_doc',
      keyForm: 'documents',
      Component: UploadDoc
    }
  ];

  const activePath = get(history, ['location', 'pathname']);
  const activeDetail = personalDataStep.find(({ link }) => link.includes(activePath));
  const ActiveComponent = get(activeDetail, ['Component']);

  const handleSubmit = () => {
    if (showModal) {
      setLoading(true);
      setShowModal(false);
      setTypeModal();

      const params = setParams({ ...form, applicationId: uniqueId, serviceId });
      const documents = setParamsDoc(form);
      postRegisterDetail({
        ...params,
        documents
      });
    } else {
      setShowModal(true);
      setTypeModal('confirm');
    }
  };

  const handlerFinishModal = () => {
    setShowModal(false);
    setTypeModal();
    history.push(`/passpor/registration/review${search}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePath]);

  useEffect(() => {
    const message = get(error, ['response', 'data', 'error', 'message']);
    const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
    const messageDetail = get(error, [
      'response',
      'data',
      'error',
      'detail',
      0,
      'message'
    ]);

    if (status === 'rejected' || message) {
      setLoading(false);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 10000);
    } else if (status === 'resolve') {
      setShowModal(true);
      setTypeModal('thankyou');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, status]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    if (!type || !serviceId || !uniqueId) {
      history.push('/passpor');
    } else {
      getPassportServiceList();
      getCountryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let modalProps = {
    className: 'confirm',
    buttonProps: [
      {
        btnStyle: { color: 'info', outline: true },
        label: i18n('cta.no'),
        onClick: () => {
          setShowModal(false);
          setTypeModal();
        }
      },
      {
        btnStyle: { color: 'info' },
        label: i18n('cta.ok'),
        onClick: () => handleSubmit()
      }
    ],
    title: i18n('confirm_modal.title'),
    desc: i18n('confirm_modal.desc')
  };

  if (typeModal === 'thankyou') {
    modalProps = {
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('cta.ok'),
          onClick: handlerFinishModal
        }
      ],
      className: 'success',
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>{i18n('passport_schedule_confirm.modal_success.title')}</div>
        </div>
      ),
      desc: i18n('thankyou_modal.title')
    };
  }

  return (
    <>
      {loading && <Loading />}
      <LayoutForm
        i18n={i18n}
        parentPath='/passpor/registration'
        dataStep={personalDataStep}
        form={form}
        applicationId={uniqueId}
        title={i18n('form_personal_data.title')}
        formTitle={title}
        type={type}
      >
        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        {ActiveComponent && (
          <ActiveComponent
            setForm={setForm}
            form={form}
            i18n={i18n}
            setTmpForm={setTmpForm}
            tmpform={tmpform}
            handleSubmit={handleSubmit}
            type={type}
            country_options={country_options}
            transactionType={title}
          />
        )}
      </LayoutForm>
      <Modal
        showModal={showModal}
        setShowModal={(val) => {
          setShowModal(val);
          if (!val) {
            setTypeModal();
          }
        }}
        {...modalProps}
      />
    </>
  );
};

FormPassport.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  postRegisterDetail: PropTypes.func,
  registerDetail: PropTypes.object,
  getPassportServiceList: PropTypes.func,
  passportService: PropTypes.object,
  country: PropTypes.object,
  getCountryList: PropTypes.func
};

const mapStateToProps = (state) => ({
  registerDetail: {
    status: state.registerReducer.status,
    error: state.registerReducer.error,
    data: state.registerReducer.data
  },
  passportService: {
    status: state.passportServiceReducer.status,
    error: state.passportServiceReducer.error,
    data: state.passportServiceReducer.data
  },
  country: {
    status: state.countryListReducer.status,
    error: state.countryListReducer.error,
    data: state.countryListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postRegisterDetail,
    getPassportServiceList,
    getCountryList
  }),
  withRouter
)(FormPassport);
