export const passport_service = {
  expired_pass: {
    title: 'tab1.title'
  },
  expired_child: {
    title: 'tab2.title'
  },
  new_born_pass: {
    title: 'tab3.title'
  },
  lost_pass: {
    title: 'tab4.title'
  },
  broken_pass: {
    title: 'tab5.title'
  },
  splp: {
    title: 'tab6.sidebar_title'
  }
};
