import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll } from '../../../../../helpers/functions';

import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/input/TextInput';
import RadioButton from '../../../../../components/input/RadioButton';
import Dropdown from '../../../../../components/Dropdown';
import PlaceDateInput from '../../../components/Dob';
import Datepicker from '../../../../../components/input/Datepicker';

import { optionsList } from './functions';
import './style.scss';

const PassportVisaForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  setTmpForm,
  tmpform
}) => {
  const [form, setForm] = useState({
    ...(formGlobal.passportVisa || tmpform.passportVisa)
  });
  const [errors, setErrors] = useState({});
  const { location } = history;
  const { search } = location;

  const {
    passport_type_options,
    visa_american_options,
    american_stay_types
  } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      passport_type: yup.object().shape({
        value: yup.string().required('required')
      }),
      issuing_city: yup.string().required('required'),
      issuing_date: yup.string().required('required'),
      expired_date: yup.date().required('required'),
      visa_type: yup.string().required('required'),
      ...(form.visa_type !== 'other' && {
        number_visa: yup.string().required('required')
      }),
      ...(form.visa_type === 'other' && {
        visa_name: yup.string().required('required')
      }),
      expired_date_visa: yup.date().required('required'),
      type_stay_america: yup.string().required('required'),
      ...(form.type_stay_america !== 'other' && {
        number_stay: yup.string().required('required')
      }),
      expired_date_stay: yup.date().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, passportVisa: form });
        history.push(`/passpor/registration/american-address${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    const tmpError = { ...errors };
    setForm({ ...form, [key]: value });

    if (key === 'visa_type' && value === 'other') {
      tmpError['number_visa'] = undefined;
    }
    if (key === 'type_stay_america' && value === 'other') {
      tmpError['number_stay'] = undefined;
    }
    setErrors({ ...tmpError, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, passportVisa: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <form onSubmit={handlerSubmit} id='passport-visa'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_passpor_visa')}
          </div>
          <Dropdown
            className='row input-container'
            label={i18n('form.passport_type.label')}
            placeholder={i18n('form.passport_type.placeholder')}
            value={get(form, ['passport_type'])}
            options={passport_type_options}
            onClick={(item) =>
              handlerChanges(item, 'passport_type', 'passport_type.value')
            }
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown withoutAutocomplete'
            }}
            errorMessage={
              get(errors, ['passport_type.value']) &&
              i18n(`validation.${get(errors, ['passport_type.value'])}`)
            }
          />
          <PlaceDateInput
            place={{
              label: i18n('form.place_issue.label'),
              placeholder: i18n('form.place_issue.placeholder'),
              onChange: (issuing_city) => handlerChanges(issuing_city, 'issuing_city'),
              value: get(form, ['issuing_city']),
              errorMessage:
                get(errors, ['issuing_city']) &&
                i18n(`validation.${get(errors, ['issuing_city'])}`)
            }}
            date={{
              label: i18n('form.date_issue.label'),
              placeholder: i18n('form.date_issue.placeholder'),
              onChange: (issuing_date) => handlerChanges(issuing_date, 'issuing_date'),
              value: get(form, ['issuing_date']),
              errorMessage:
                get(errors, ['issuing_date']) &&
                i18n(`validation.${get(errors, ['issuing_date'])}`)
            }}
          />
          <Datepicker
            label={i18n('form.date_expired.label')}
            placeholder={i18n('form.date_expired.placeholder')}
            className='date_issue-date'
            value={get(form, ['expired_date'])}
            onChange={(expired_date) => handlerChanges(expired_date, 'expired_date')}
            errorMessage={
              get(errors, ['expired_date']) &&
              i18n(`validation.${get(errors, ['expired_date'])}`)
            }
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_passpor_america')}
          </div>

          <div className='row-input radio-input-container'>
            <RadioButton
              className='visa-type'
              label={i18n('form.visa_type.label')}
              options={visa_american_options}
              value={get(form, ['visa_type'])}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'visa_type')
              }}
              errorMessage={
                get(errors, ['visa_type']) &&
                i18n(`validation.${get(errors, ['visa_type'])}`)
              }
            />
            <TextInput
              label={i18n('form.number.label')}
              placeholder={i18n('form.number.placeholder')}
              value={get(form, ['number_visa'])}
              inputProps={{
                maxLength: 255,
                minLength: 6,
                onChange: (e) => handlerChanges(e.target.value, 'number_visa')
              }}
              errorMessage={
                get(errors, ['number_visa']) &&
                i18n(`validation.${get(errors, ['number_visa'])}`)
              }
            />
          </div>
          {get(form, ['visa_type']) === 'other' && (
            <TextInput
              label={i18n('form.visa_type.name')}
              placeholder={i18n('form.visa_type.name')}
              value={get(form, ['visa_name'])}
              inputProps={{
                maxLength: 255,
                onChange: (e) => handlerChanges(e.target.value, 'visa_name')
              }}
              errorMessage={
                get(errors, ['visa_name']) &&
                i18n(`validation.${get(errors, ['visa_name'])}`)
              }
            />
          )}
          <Datepicker
            label={i18n('form.date_expired.label')}
            sublabel={i18n('form.visa_type.label')}
            placeholder={i18n('form.date_expired.placeholder')}
            className='date_expired-date'
            value={get(form, ['expired_date_visa'])}
            onChange={(expired_date) => handlerChanges(expired_date, 'expired_date_visa')}
            errorMessage={
              get(errors, ['expired_date_visa']) &&
              i18n(`validation.${get(errors, ['expired_date_visa'])}`)
            }
            notes={i18n('form.date_expired.notes')}
          />

          <div className='row-input radio-input-container stay-america'>
            <RadioButton
              className='visa-type'
              label={i18n('form.type_stay_america.label')}
              options={american_stay_types}
              value={get(form, ['type_stay_america'])}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'type_stay_america')
              }}
              errorMessage={
                get(errors, ['type_stay_america']) &&
                i18n(`validation.${get(errors, ['type_stay_america'])}`)
              }
            />
            <TextInput
              label={i18n('form.number.label')}
              placeholder={i18n('form.number.placeholder')}
              value={get(form, ['number_stay'])}
              inputProps={{
                maxLength: 255,
                minLength: 6,
                onChange: (e) => handlerChanges(e.target.value, 'number_stay')
              }}
              errorMessage={
                get(errors, ['number_stay']) &&
                i18n(`validation.${get(errors, ['number_stay'])}`)
              }
              detailInput={i18n('form.type_stay_america.notes')}
            />
          </div>

          <Datepicker
            label={i18n('form.date_expired.label')}
            sublabel={i18n('form.type_stay_america.label')}
            placeholder={i18n('form.date_expired.placeholder')}
            className='date_expired-date'
            value={get(form, ['expired_date_stay'])}
            onChange={(expired_date) => handlerChanges(expired_date, 'expired_date_stay')}
            errorMessage={
              get(errors, ['expired_date_stay']) &&
              i18n(`validation.${get(errors, ['expired_date_stay'])}`)
            }
            notes={i18n('form.date_expired.notes')}
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/passpor/registration/personal-info${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

PassportVisaForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object
};

export default withRouter(PassportVisaForm);
