import { apiCaller } from '../../../helpers/apiCaller';

const getDiasporaRegisterRequest = () => ({
  type: 'GET_DIASPORA_REGISTER_REQUEST'
});

const getDiasporaRegisterSuccess = (data) => ({
  type: 'GET_DIASPORA_REGISTER_SUCCESS',
  payload: data
});

const getDiasporaRegisterFailure = (error) => ({
  type: 'GET_DIASPORA_REGISTER_FAILURE',
  payload: error
});

const postDiasporaRegister = (params) => {
  return (dispatch) => {
    dispatch(getDiasporaRegisterRequest());
    return apiCaller('diaspora/registration', 'post', params)
      .then((data) => dispatch(getDiasporaRegisterSuccess(data)))
      .catch((error) => dispatch(getDiasporaRegisterFailure(error)));
  };
};

export { postDiasporaRegister };
