import { apiCaller } from '../../../helpers/apiCaller';

// Get News list
const getTourListRequest = () => ({
  type: 'GET_TOUR_LIST_REQUEST'
});

const getTourListSuccess = (data) => ({
  type: 'GET_TOUR_LIST_SUCCESS',
  tourList: data
});

const getTourListFailure = (error) => ({
  type: 'GET_TOUR_LIST_FAILURE',
  tourList: error
});

const getTourList = () => {
  return (dispatch) => {
    dispatch(getTourListRequest());
    return apiCaller('tours')
      .then((data) => dispatch(getTourListSuccess(data)))
      .catch((error) => dispatch(getTourListFailure(error)));
  };
};

// Get News detail
const getTourDetailRequest = () => ({
  type: 'GET_TOUR_DETAIL_REQUEST'
});

const getTourDetailSuccess = (data) => ({
  type: 'GET_TOUR_DETAIL_SUCCESS',
  tourDetail: data
});

const getTourDetailFailure = (error) => ({
  type: 'GET_TOUR_DETAIL_FAILURE',
  tourDetail: error
});

const getTourDetail = (id) => {
  return (dispatch) => {
    dispatch(getTourDetailRequest());
    return apiCaller(`tours/${id}`)
      .then((data) => dispatch(getTourDetailSuccess(data)))
      .catch((error) => dispatch(getTourDetailFailure(error)));
  };
};

export { getTourList, getTourDetail };
