import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import { getSosBudRegisterDetail } from '../../SosBudSK/action';

import './style.scss';

const DetailKelas = ({ history, registerDetail, getSosBudRegisterDetail }) => {
  const { type, id } = useParams();
  const data = get(registerDetail, ['data', 'data', 0]);

  const details = [
    {
      title: 'Detail Kelas',
      list: [
        { value: get(data, ['name']), label: 'Nama' },
        {
          value: `${get(data, ['birth-city'])} ${moment(get(data, ['birth_date'])).format(
            'DD MMM YYYY'
          )}`,
          label: 'Tempat, Tanggal Lahir'
        },
        { value: get(data, ['unique_number']), label: 'Unique Id' },
        { value: get(data, ['status']), label: 'Status' },
        { value: get(data, ['state_id']), label: 'State' },
        { value: get(data, ['phone']), label: 'Phone Number' },
        { value: get(data, ['email']), label: 'Email Address' },
        { value: get(data, ['is_learned']) ? 'Yes' : 'Not Yet', label: 'Learning' }
      ]
    }
  ];

  useEffect(() => {
    getSosBudRegisterDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='detail-pengaduan'>
      <span className='back-homepage' onClick={() => history.goBack()}>
        <span className='btn-back' />
        Detail Kelas - {type}
      </span>
      <div className='detail-pengaduan-content'>
        {details.map(({ title, list }, key) => (
          <React.Fragment key={key}>
            <div className='title'>{title}</div>
            <table>
              {list.map(({ value, label }, childKey) => (
                <tr key={childKey}>
                  <td>{label}</td>
                  <td>{value || '-'}</td>
                </tr>
              ))}
            </table>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

DetailKelas.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  registerDetail: PropTypes.object,
  getSosBudRegisterDetail: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  registerDetail: {
    status: state.getSosBudRegisterDetailReducer.status,
    error: state.getSosBudRegisterDetailReducer.error,
    data: state.getSosBudRegisterDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudRegisterDetail
  }),
  withRouter
)(DetailKelas);
