import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import parse from 'html-react-parser';

import './style.scss';
import bigLoc from '../../../../assets/img/big_loc.svg';
import blueDot from '../../../../assets/img/bluedot.svg';
import link from '../../../../assets/img/link.svg';
import placeIcon from '../../../../assets/img/homepage/place_24px.svg';

import { getTourDetail } from '../action';

const DetailTourism = ({ history, i18n, getTourDetail, tourDetail, match }) => {
  const { id } = get(match, ['params']);
  const data = get(tourDetail, ['data', 0]);
  const { title, content = '', image_url, place } = data || {};
  // TODO : wisata terdekat, cara kesana

  useEffect(() => {
    getTourDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container style={{ paddingTop: 24 }}>
        <div className='tourism-detail'>
          <img style={{ marginBottom: 22 }} src={image_url} alt='tourism-title' />
          <span className='back-homepage' onClick={() => history.goBack()}>
            <span className='btn-back' />
            {i18n('cta.back_prev_page')}
          </span>

          <h2>{title}</h2>

          <div className='tourism-info'>
            <div className='tour-thumbnail-time' style={{ marginBottom: 4 }}>
              <img
                src={placeIcon}
                alt='activity-thumbnail-place'
                style={{ marginRight: 5 }}
              />
              {place}
            </div>
          </div>
          <div className='tourism-content'>
            {parse(content)}
            <br />
            <h5 style={{ display: `none `}}>Cara menuju kesana</h5>
            <div className='how-to-go-there' style={{ display: `none `}}>
              <div className='place'>
                <img src={blueDot} alt='place' />
                Transportasi dari kota Jakarta menuju Labuan Bajo
                <div className='horizontal-line' />
              </div>
              <div className='place'>
                <img src={blueDot} alt='place' />
                Transportasi dari kota Jakarta menuju Labuan Bajo
                <div className='horizontal-line' />
              </div>
              <div className='place'>
                <img src={blueDot} alt='place' />
                Transportasi dari kota Jakarta menuju Labuan Bajo
                <div className='horizontal-line' />
              </div>
              <div className='place'>
                <img src={blueDot} alt='place' />
                Transportasi dari kota Jakarta menuju Labuan Bajo
                <div className='horizontal-line' />
              </div>
            </div>
            <h5 style={{ display: `none `}}>Obyek wisata terdekat</h5>
            <div className='nearest-object' style={{ display: `none `}}>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
              <div className='object'>
                <img src={bigLoc} alt='object' />
                <div className='object-detail'>
                  <div className='od-name'>Hotel Garuda</div>
                  <div className='od-link'>
                    <img src={link} alt='link' /> Buka Link
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

DetailTourism.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  getTourDetail: PropTypes.func,
  tourDetail: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  tourDetail: {
    status: state.tourDetailReducer.status,
    error: state.tourDetailReducer.error,
    data: state.tourDetailReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getTourDetail }),
  withRouter
)(DetailTourism);
