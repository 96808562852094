import React from 'react';
import InstructionWrap from '../../../../components/InstructionWrap';
import StepItemVisa from '../../../../components/StepItemVisa';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVisaServiceId } from '../function';

const LimitedStay = ({ i18n, visaServiceList }) => {
  const visaServiceId = getVisaServiceId(visaServiceList, 'limited_stay');

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('visa_page.tab7.title')}</div>
      <main>
        <StepItemVisa
          instructions={i18n('visa_page.tab7.step.instruction1')}
          i18n={i18n}
          type='limited_stay'
          serviceId={visaServiceId}
        />
        <InstructionWrap
          instruction={i18n('visa_page.tab7.step.instruction2')}
          instructionList={i18n('visa_page.instruction_step.limited_stay_req')}
          type='number'
        />
      </main>
    </div>
  );
};

LimitedStay.propTypes = {
  i18n: PropTypes.func,
  visaServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(LimitedStay);
