import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { Alert } from 'reactstrap';

import { errorAlertScroll } from '../../../../../helpers/functions';

import Modal from '../../../../../components/Modal';
import Loading from '../../../../../components/Loading';
import Icon from '../../../../../components/Icon';
import PassportDocForm from '../../../../form/PassportForm/PersonalData/UploadDoc';
import {
  putRegisterDetail,
  resetPutRegister
} from '../../../../form/PassportForm/action';
import { resetTrack } from '../../action';
import { setParamsDoc } from '../../../../form/PassportForm/functions';

const PassportReuploadDoc = ({
  history,
  data,
  i18n,
  putRegisterDetail,
  putPassportDoc,
  resetTrack,
  resetPutRegister
}) => {
  const [tmpform, setTmpForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [filesForm, setFilesForm] = useState();

  const { error, status } = putPassportDoc;

  const handleSubmit = (files) => {
    if (showModal) {
      setLoading(true);
      setShowModal(false);
      const documents = setParamsDoc({ documents: filesForm });
      // put file passport
      putRegisterDetail({
        unique_number: get(data, ['unique_number']),
        documents
      });
    } else {
      setFilesForm(files);
      setShowModal(true);
    }
  };

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    const message = get(error, ['response', 'data', 'error', 'message']);
    const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
    const messageDetail = get(error, [
      'response',
      'data',
      'error',
      'detail',
      0,
      'message'
    ]);

    if (status === 'rejected' || message) {
      setLoading(false);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 10000);
    } else if (status === 'resolve') {
      setShowModal(true);
      setTypeModal('success');
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, status]);

  // Modal
  let modalProps = {
    className: 'confirm',
    buttonProps: [
      {
        btnStyle: { color: 'info', outline: true },
        label: i18n('cta.no'),
        onClick: () => setShowModal(false)
      },
      {
        btnStyle: { color: 'info' },
        label: i18n('cta.ok'),
        onClick: () => handleSubmit()
      }
    ],
    title: i18n('form_visa.confirm_modal.title'),
    desc: i18n('form_visa.confirm_modal.desc')
  };

  if (typeModal === 'success') {
    modalProps = {
      buttonProps: [
        {
          btnStyle: { color: 'primary' },
          label: i18n('cta.ok'),
          onClick: () => {
            resetTrack();
            resetPutRegister();
            history.push('/');
          }
        }
      ],
      className: 'success',
      title: (
        <div className='top-wrap'>
          <Icon icon='green-check' />
          <div>{i18n('success_save_modal.title')}</div>
        </div>
      ),
      desc: (
        <div
          style={{
            marginBottom: '16px'
          }}
        >
          {i18n('thankyou_modal.title')}
        </div>
      )
    };
  }

  return (
    <div className='pass-doc-container'>
      {loading && <Loading />}
      <Alert color='danger' isOpen={visible}>
        {errorSave}
      </Alert>
      <div className='instruction'>
        <div className='red-text'>
          Perhatian! Silahkan lengkapi dan upload kembali berkas berkas yang belum lengkap
        </div>
        Silahkan upload ulang berkas pada form di bawah ini :
      </div>
      <PassportDocForm
        i18n={i18n}
        setTmpForm={setTmpForm}
        tmpform={tmpform}
        handleSubmit={handleSubmit}
        isEditDoc
        editDocData={{
          serviceId: get(data, ['passport_service', 'id']),
          uniqueId: get(data, ['unique_number'])
        }}
      />
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </div>
  );
};

PassportReuploadDoc.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  putRegisterDetail: PropTypes.func,
  putPassportDoc: PropTypes.object
};

const mapStateToProps = (state) => ({
  putPassportDoc: {
    status: state.registerDocReducer.status,
    error: state.registerDocReducer.error,
    data: state.registerDocReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { putRegisterDetail, resetTrack, resetPutRegister })
)(PassportReuploadDoc);
