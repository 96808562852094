import React, { useState } from 'react';
import './style.scss';
import { aiport } from '../../assets/json/airport';
import { landborder } from '../../assets/json/landborder';

const Checkpoint = () => {
  const defaultState = {
    airport: true,
    seaport: false,
    land: false
  };

  const [menu, setMenu] = useState(defaultState);
  const [showMore, setShowMore] = useState(defaultState);

  const changeMenu = (params) => {
    switch (params) {
      case 'airport':
        setMenu({
          airport: true,
          seaport: false,
          land: false
        });
        break;
      case 'seaport':
        setMenu({
          airport: false,
          seaport: true,
          land: false
        });
        break;
      case 'land':
        setMenu({
          airport: false,
          seaport: false,
          land: true
        });
        break;
      default:
        break;
    }
  };

  const changeShowMore = (params) => {
    switch (params) {
      case 'airport':
        setShowMore({
          airport: !showMore.airport,
          seaport: false,
          land: false
        });
        break;
      case 'seaport':
        setShowMore({
          airport: false,
          seaport: true,
          land: false
        });
        break;
      case 'land':
        setShowMore({
          airport: false,
          seaport: false,
          land: true
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className='checkpoint'>
      <div className='checkpoint-top'>
        <span
          onClick={() => changeMenu('airport')}
          className={`${menu.airport && 'active'}`}
        >
          A. AIRPORTS
        </span>
        <span
          onClick={() => changeMenu('seaport')}
          className={`${menu.seaport && 'active'}`}
        >
          B. SEAPORTS
        </span>
        <span onClick={() => changeMenu('land')} className={`${menu.land && 'active'}`}>
          C. LAND BORDER
        </span>
      </div>
      <div className='checkpoint-bottom'>
        <span className={` ${!menu.airport && 'hide'}`}>
          <table className={`airport-list-data ${!showMore.airport ? 'show-more' : ''}`}>
            <tbody>
              {aiport.map((c, i) => (
                <tr key={i}>
                  <td width='35'>{i + 1}.</td>
                  <td>{c}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <span
            onClick={() => changeShowMore('airport')}
            className={`show-more-button ${!showMore.airport && 'revert'}`}
          >
            {!showMore.airport ? 'Show Less' : 'Show All'}
          </span>
        </span>
        <span className={` ${!menu.seaport && 'hide'}`}>
          <table className={`airport-list-data ${!showMore.airport ? 'show-more' : ''}`}>
            <tbody>
              {aiport.map((c, i) => (
                <tr key={i}>
                  <td width='35'>{i + 1}.</td>
                  <td>{c}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <span
            onClick={() => changeShowMore('airport')}
            className={`show-more-button ${!showMore.airport && 'revert'}`}
          >
            {!showMore.airport ? 'Show Less' : 'Show All'}
          </span>
        </span>
        <span className={` ${!menu.land && 'hide'}`}>
          <table className={`airport-list-data ${!showMore.airport ? 'show-more' : ''}`}>
            <tbody>
              {landborder.map((c, i) => (
                <tr key={i}>
                  <td width='35'>{i + 1}.</td>
                  <td>{c}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <span
            onClick={() => changeShowMore('airport')}
            className={`show-more-button ${!showMore.airport && 'revert'}`}
          >
            {!showMore.airport ? 'Show Less' : 'Show All'}
          </span>
        </span>
      </div>
    </div>
  );
};

export default Checkpoint;
