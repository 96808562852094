import React from 'react';
import InstructionWrap from '../../../../components/InstructionWrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/Button';
import { getVisaServiceId } from '../function';

const Diplomatic = ({ i18n, visaServiceList, history }) => {
  const visaServiceId = getVisaServiceId(visaServiceList, 'diplomatic');

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('visa_page.tab8.title')}</div>
      <main>
        <InstructionWrap
          instruction={i18n('visa_page.tab8.step.instruction')}
          instructionList=''
          type=''
        />

        <Button
          color='primary'
          onClick={() =>
            history.push(
              `/visa/registration/create?type=diplomatic&serviceId=${visaServiceId}`
            )
          }
        >
          {i18n('cta.start_applicant')}
        </Button>
        <InstructionWrap
          instruction={i18n('visa_page.tab8.step.instruction1')}
          instructionList=''
          type=''
        />
        <InstructionWrap
          instruction={i18n('visa_page.tab8.step.instruction2')}
          instructionList={i18n('visa_page.instruction_step.diplomatic_req')}
          type='number'
        />
      </main>
    </div>
  );
};

Diplomatic.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  visaServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(Diplomatic);
