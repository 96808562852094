import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';
import purposeVisaOptions from '../../../../constant/purpose_visit';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RadioButton from '../../../../components/input/RadioButton';
import Dropdown from '../../../../components/Dropdown';
import Datepicker from '../../../../components/input/Datepicker';

import { optionsList } from './functions';
import sponsorship_type from '../../../../constant/sponsorhip_type';

const OtherForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  tmpform,
  setTmpForm,
  type,
  province,
  citiesId
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({
    ...(formGlobal.other || tmpform.other)
  });

  const [errors, setErrors] = useState({});
  const provinceOptions = get(province, ['data']);
  const cityIdList = get(citiesId, ['data']);
  const { answerOptions } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      sponsorship_type: yup.string().required('required'),
      sponsor_name: yup.string().required('required'),
      contact_name: yup.string().required('required'),
      address: yup.string().required('required'),
      city: yup.string().required('required'),
      province: yup.object().shape({
        _id: yup.string().required('required')
      }),
      zip_code: yup.string().required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      question_1: yup.string().required('required'),
      ...(form.question_1 === 'true' && {
        question_2: yup.date().required('required'),
        question_3: yup.string().required('required')
      }),
      question_4: yup.string().required('required'),
      question_5: yup.string().required('required'),
      question_6: yup.string().required('required'),
      question_7: yup.string().required('required'),
      question_8: yup.string().required('required'),
      question_9: yup.string().required('required'),
      question_10: yup.string().required('required'),
      question_11: yup.string().required('required'),
      question_12: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, other: form });
        history.push(`/visa/form/photo${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    const tmpErrors = { ...errors };
    if (key === 'question_1' && value === 'false') {
      tmpErrors['question_2'] = undefined;
      tmpErrors['question_3'] = undefined;
      setForm({ ...form, [key]: value, question_2: undefined, question_3: undefined });
    }
    setErrors({ ...tmpErrors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, other: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <form onSubmit={handlerSubmit} id='other-info'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>{i18n('form.visa_other.heading_1.label')}</div>
          <Dropdown
            className='row input-container'
            label={i18n('form.visa_other.sponsorship_type.label')}
            placeholder={i18n('form.visa_other.sponsorship_type.placeholder')}
            value={get(form, ['sponsorship_type'])}
            options={sponsorship_type}
            onClick={(item) =>
              handlerChanges(item, 'sponsorship_type', 'sponsorship_type')
            }
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            withAutocomplete
            errorMessage={
              get(errors, ['sponsorship_type']) &&
              i18n(`validation.${get(errors, ['sponsorship_type'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_other.sponsor_name.label')}
            placeholder={i18n('form.visa_other.sponsor_name.placeholder')}
            value={get(form, ['sponsor_name'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'sponsor_name')
            }}
            errorMessage={
              get(errors, ['sponsor_name']) &&
              i18n(`validation.${get(errors, ['sponsor_name'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_other.contact_name.label')}
            placeholder={i18n('form.visa_other.contact_name.placeholder')}
            value={get(form, ['contact_name'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'contact_name')
            }}
            errorMessage={
              get(errors, ['contact_name']) &&
              i18n(`validation.${get(errors, ['contact_name'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_other.address.label')}
            placeholder={i18n('form.visa_other.address.placeholder')}
            value={get(form, ['address'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'address')
            }}
            errorMessage={
              get(errors, ['address']) && i18n(`validation.${get(errors, ['address'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.visa_other.city.label')}
            placeholder={i18n('form.visa_other.city.placeholder')}
            value={get(form, ['city'])}
            options={cityIdList}
            onClick={(item) => handlerChanges(item, 'city', 'city')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='_id'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['city']) && i18n(`validation.${get(errors, ['city'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.visa_other.province.label')}
            placeholder={i18n('form.visa_other.province.placeholder')}
            value={get(form, ['province'])}
            options={provinceOptions}
            onClick={(item) => handlerChanges(item, 'province', 'province._id')}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='_id'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['province._id']) &&
              i18n(`validation.${get(errors, ['province._id'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_other.zip_code.label')}
            placeholder={i18n('form.visa_other.zip_code.placeholder')}
            value={get(form, ['zip_code'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'zip_code')
            }}
            errorMessage={
              get(errors, ['zip_code']) && i18n(`validation.${get(errors, ['zip_code'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_other.phone_number.label')}
            placeholder={i18n('form.visa_other.phone_number.label')}
            prependText='+62'
            value={get(form, ['phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'phone')
            }}
            errorMessage={
              get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
            }
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>{i18n('form.visa_other.heading_2.label')}</div>
          <RadioButton
            className='long-questions'
            label={i18n('form.visa_other.question_1.label')}
            options={answerOptions}
            value={get(form, ['question_1'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'question_1')
            }}
            errorMessage={
              get(errors, ['question_1']) &&
              i18n(`validation.${get(errors, ['question_1'])}`)
            }
          />
          {get(form, ['question_1']) === 'true' && (
            <>
              <Datepicker
                label={i18n('form.visa_other.question_2.label')}
                placeholder={i18n('form.visa_other.question_2.placeholder')}
                value={get(form, ['question_2'])}
                onChange={(date) => handlerChanges(date, 'question_2')}
                errorMessage={
                  get(errors, ['question_2']) &&
                  i18n(`validation.${get(errors, ['question_2'])}`)
                }
              />
              <Dropdown
                className='row input-container'
                label={i18n('form.visa_other.question_3.label')}
                placeholder={i18n('form.visa_other.question_3.placeholder')}
                value={get(form, ['question_3'])}
                options={purposeVisaOptions}
                onClick={(item) => handlerChanges(item, 'question_3', 'question_3')}
                dropdownToggleProps={{
                  tag: 'div',
                  className: 'input-dropdown withoutAutocomplete'
                }}
                errorMessage={
                  get(errors, ['question_3']) &&
                  i18n(`validation.${get(errors, ['question_3'])}`)
                }
              />
            </>
          )}
          <RadioButton
            className='long-questions'
            label={i18n('form.visa_other.question_4.label')}
            options={answerOptions}
            value={get(form, ['question_4'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'question_4')
            }}
            errorMessage={
              get(errors, ['question_4']) &&
              i18n(`validation.${get(errors, ['question_4'])}`)
            }
          />
          <RadioButton
            className='long-questions'
            label={i18n('form.visa_other.question_5.label')}
            options={answerOptions}
            value={get(form, ['question_5'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'question_5')
            }}
            errorMessage={
              get(errors, ['question_5']) &&
              i18n(`validation.${get(errors, ['question_5'])}`)
            }
          />
          <RadioButton
            className='long-questions'
            label={i18n('form.visa_other.question_6.label')}
            options={answerOptions}
            value={get(form, ['question_6'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'question_6')
            }}
            errorMessage={
              get(errors, ['question_6']) &&
              i18n(`validation.${get(errors, ['question_6'])}`)
            }
          />
          <RadioButton
            className='long-questions'
            label={i18n('form.visa_other.question_7.label')}
            options={answerOptions}
            value={get(form, ['question_7'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'question_7')
            }}
            errorMessage={
              get(errors, ['question_7']) &&
              i18n(`validation.${get(errors, ['question_7'])}`)
            }
          />
          <RadioButton
            className='long-questions'
            label={i18n('form.visa_other.question_8.label')}
            options={answerOptions}
            value={get(form, ['question_8'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'question_8')
            }}
            errorMessage={
              get(errors, ['question_8']) &&
              i18n(`validation.${get(errors, ['question_8'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_other.question_9.label')}
            placeholder={i18n('form.visa_other.question_9.placeholder')}
            detailInput={i18n('form.visa_other.question_9.info')}
            value={get(form, ['question_9'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'question_9')
            }}
            errorMessage={
              get(errors, ['question_9']) &&
              i18n(`validation.${get(errors, ['question_9'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_other.question_10.label')}
            placeholder={i18n('form.visa_other.question_10.placeholder')}
            detailInput={i18n('form.visa_other.question_10.info')}
            value={get(form, ['question_10'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'question_10')
            }}
            errorMessage={
              get(errors, ['question_10']) &&
              i18n(`validation.${get(errors, ['question_10'])}`)
            }
          />
          <Datepicker
            label={i18n('form.visa_other.question_11.label')}
            placeholder={i18n('form.visa_other.question_11.placeholder')}
            value={get(form, ['question_11'])}
            onChange={(date) => handlerChanges(date, 'question_11')}
            errorMessage={
              get(errors, ['question_11']) &&
              i18n(`validation.${get(errors, ['question_11'])}`)
            }
          />
          <Datepicker
            label={i18n('form.visa_other.question_12.label')}
            placeholder={i18n('form.visa_other.question_12.placeholder')}
            value={get(form, ['question_12'])}
            onChange={(date) => handlerChanges(date, 'question_12')}
            errorMessage={
              get(errors, ['question_12']) &&
              i18n(`validation.${get(errors, ['question_12'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/visa/form/passport${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

OtherForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object,
  form: PropTypes.object,
  province: PropTypes.object,
  citiesId: PropTypes.object
};

const mapStateToProps = (state) => ({
  province: {
    status: state.provinceIDReducer.status,
    error: state.provinceIDReducer.error,
    data: state.provinceIDReducer.data
  },
  citiesId: {
    status: state.cityIdReducer.status,
    error: state.cityIdReducer.error,
    data: state.cityIdReducer.data
  }
});

export default compose(connect(mapStateToProps), withRouter)(OtherForm);
