import React, { useState, useEffect } from 'react';
import DashboardTable from '../../../../components/DashboardTableOld2';
import Button from '../../../../components/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';
import AddHoliday from '../AddHoliday';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayHasValue } from '../../../../helpers/helpers';
import Loading from '../../../../components/Loading';
import { getAntrian } from '../action';
import moment from 'moment';

const AdminHoliday = ({ history, getAntrian, antrianList }) => {
  const [showModal, setShowModal] = useState(false);
  const year = moment().year();
  const columns = [
    { dataField: 'id', text: 'No' },
    { dataField: 'scheduled_at', text: 'Holiday' },
    { dataField: 'name', text: 'National Holiday' },
    { dataField: 'status', text: 'Status' },
    { dataField: 'id', text: 'Action' }
  ];

  useEffect(() => {
    if (!arrayHasValue(antrianList.data)) {
      const params = {
        // from: year + '-01-01',
        // to: year + '-12-31',
        status: 'HOLIDAY',
        force: 'true',
        per_page: 200
      };

      getAntrian(params);
    }
  }, []);

  const action = [
    {
      onClick: (id) => history.push(`#`),
      type: 'edit'
    },
    {
      onClick: (id) => history.push(`#`),
      type: 'delete'
    }
  ];

  const data = antrianList.status === 'resolve' ? antrianList.data.data : '';

  const backTo = () => {
    window.location.href = '/admin/antrian-paspor';
  };

  return (
    <div className='holiday-container'>
      <div className='admin-news-top'>
        <span className='back-homepage' onClick={() => backTo()}>
          <span className='btn-back' />
          <h1>DATA HARI LIBUR</h1>
        </span>
        <div className='admin-news-action'>
          <Button color='primary' onClick={() => setShowModal(true)}>
            Tambah Hari Libur
          </Button>
        </div>
      </div>
      {antrianList.status === 'resolve' ? (
        <DashboardTable
          columns={columns}
          data={data.data}
          action={action}
          search='status'
          idKeyField='id'
        />
      ) : (
        <Loading />
      )}
      <AddHoliday showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

AdminHoliday.propTypes = {
  history: PropTypes.object,
  getAntrian: PropTypes.func,
  antrianList: PropTypes.object
};

const mapStateToProps = (state) => ({
  antrianList: {
    status: state.antrianReducerAdmin.status,
    error: state.antrianReducerAdmin.error,
    data: state.antrianReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getAntrian
  }),
  withRouter
)(AdminHoliday);
