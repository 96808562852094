import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';
import { Alert } from 'reactstrap';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../../helpers/functions';
import { postPhoto, resetPhotoData } from '../action';

import './style.scss';
import imgReq from '../../../../assets/img/visa-photo-upload.png';

import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';

const PhotoForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  tmpform,
  setTmpForm,
  postPhoto,
  uploadPhoto,
  resetPhotoData
}) => {
  const { location } = history;
  const { search } = location;
  const form = {
    ...(formGlobal.photo || tmpform.photo)
  };
  const [photo, setPhoto] = useState({});
  const [submitType, setSubmitType] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [alertDetail, setAlertDetail] = useState({});
  const [visible, setVisible] = useState(false);

  const img = get(photo, ['base64']) || get(photo, ['url']) || get(form, ['url']); //just temporary, delete if the endpoint is available
  const { data, error: errorUpload, status } = uploadPhoto;

  const handlerImageUploader = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append('file', photo.file);

    // Request made to the backend api
    // Send formData object
    setErrorMessage();
    postPhoto(formData);
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    const key = submitType === 'upload' ? 'base64' : 'url';
    let schema = yup.object().shape({
      [key]: yup.string().required('required')
    });

    schema
      .validate(photo, { abortEarly: false })
      .then(() => {
        // upload to server
        if (submitType === 'upload') {
          handlerImageUploader();
        } else {
          setFormGlobal({ ...formGlobal, photo: { ...photo } });
          resetPhotoData();
          history.push(`/visa/form/sign-in${search}`);
        }
      })
      .catch((err) => {
        let tmpErrorMessage = convertArrayToObject(err.inner, 'path', 'message')[key];
        if (submitType === 'next' && photo.base64 && !photo.url) {
          tmpErrorMessage = 'upload_image';
        }
        setErrorMessage(tmpErrorMessage);
        errorScroll();
      });
  };

  const handlerChanges = (e) => {
    const photo = {};
    const file = e.target.files[0];
    photo.file = file;

    var reader = new FileReader();
    reader.onloadend = () => {
      photo.base64 = reader.result;
      setPhoto({ ...photo });
      setErrorMessage();
    };
    reader.readAsDataURL(file);
  };

  const handlerAlert = (data) => {
    setVisible(true);
    setAlertDetail(data);
    setTimeout(() => {
      setVisible(false);
    }, 5000);
    errorAlertScroll();
  };

  useEffect(() => {
    return () => {
      resetPhotoData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTmpForm({ ...tmpform, photo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo.url]);

  useEffect(() => {
    if (status === 'resolve') {
      setPhoto({ ...data.data });
      setSubmitType();
      handlerAlert({
        color: 'success',
        message: 'Photo success uploaded. Click continue to continue your registration'
      });
    } else if (status === 'rejected') {
      const message = get(errorUpload, ['response', 'data', 'error', 'message']);
      handlerAlert({
        color: 'danger',
        message
      });
    }
  }, [data, errorUpload, status]);

  return (
    <form onSubmit={handlerSubmit} id='photo'>
      {status === 'pending' && <Loading />}
      <Alert color={get(alertDetail, ['color'])} isOpen={visible}>
        {get(alertDetail, ['message'])}
      </Alert>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='photo-input'>
            <div className='label'>{i18n('form.visa_photo.label')}</div>
            <div className='content'>
              <input className='file-input' type='file' onChange={handlerChanges} />
              <button
                className='submit-button'
                type='submit'
                onClick={() => setSubmitType('upload')}
              >
                Upload
              </button>
              {errorMessage && (
                <div className='err-message'>{i18n(`validation.${errorMessage}`)}</div>
              )}
              <div className='photo-preview'>
                {img ? (
                  <img src={img} alt='img-preview' />
                ) : (
                  <div className='empty-img' />
                )}
              </div>
              <p
                style={{ marginBottom: 16 }}
                dangerouslySetInnerHTML={{ __html: i18n('form.visa_photo.req1') }}
              />
              {/* <img style={{ marginBottom: 16 }} src={imgReq} alt='img-requirement' /> */}
              <p dangerouslySetInnerHTML={{ __html: i18n('form.visa_photo.req2') }} />
              {/* <p dangerouslySetInnerHTML={{ __html: i18n('form.visa_photo.req3') }} /> */}
            </div>
          </div>
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/visa/form/other${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button
          color='primary'
          type='submit'
          className='pass-btn'
          name='next'
          onClick={() => setSubmitType('next')}
        >
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

PhotoForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object,
  postPhoto: PropTypes.func,
  uploadPhoto: PropTypes.object,
  resetPhotoData: PropTypes.func
};

const mapStateToProps = (state) => ({
  uploadPhoto: {
    status: state.photoReducer.status,
    error: state.photoReducer.error,
    data: state.photoReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { postPhoto, resetPhotoData }),
  withRouter
)(PhotoForm);
