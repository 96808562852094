import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll } from '../../../../helpers/functions';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RadioButton from '../../../../components/input/RadioButton';
import Dropdown from '../../../../components/Dropdown';
import Datepicker from '../../../../components/input/Datepicker';

import { optionsList } from './functions';

const PassportForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  setTmpForm,
  tmpform,
  type,
  country
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({
    ...(formGlobal.passport || tmpform.passport)
  });
  const [errors, setErrors] = useState({});
  const country_options = get(country, ['data']);

  const { passportTypes } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      country_place_issue: yup.object().shape({
        id: yup.string().required('required')
      }),
      passport_type: yup.string().required('required'),
      passport_number: yup.string().required('required'),
      place_issue: yup.string().required('required'),
      date_issue: yup.date().required('required'),
      date_expired: yup.date().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, passport: form });
        history.push(`/visa/form/other${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, passport: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <form onSubmit={handlerSubmit} id='passport'>
      <div className='form-container'>
        <div className='form-wrap'>
          <RadioButton
            className='passport-type'
            label={i18n('form.type_of_passport.label')}
            detailInput={i18n('form.type_of_passport.info')}
            options={passportTypes}
            value={get(form, ['passport_type'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'passport_type')
            }}
            errorMessage={
              get(errors, ['passport_type']) &&
              i18n(`validation.${get(errors, ['passport_type'])}`)
            }
          />
          <TextInput
            label={i18n('form.passport_number.label')}
            placeholder={i18n('form.passport_number.placeholder')}
            value={
              get(form, ['passport_number']) &&
              get(form, ['passport_number']).replace(/\s/gi, '')
            }
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'passport_number')
            }}
            errorMessage={
              get(errors, ['passport_number']) &&
              i18n(`validation.${get(errors, ['passport_number'])}`)
            }
          />
          <TextInput
            label={i18n('form.place_issue.label')}
            placeholder={i18n('form.place_issue.placeholder')}
            value={get(form, ['place_issue'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'place_issue')
            }}
            errorMessage={
              get(errors, ['place_issue']) &&
              i18n(`validation.${get(errors, ['place_issue'])}`)
            }
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.country_place_issue.label')}
            placeholder={i18n('form.country_place_issue.placeholder')}
            detailInput={i18n('form.country_place_issue.info')}
            value={get(form, ['country_place_issue'])}
            options={country_options}
            onClick={(item) =>
              handlerChanges(item, 'country_place_issue', 'country_place_issue.id')
            }
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='id'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['country_place_issue.id']) &&
              i18n(`validation.${get(errors, ['country_place_issue.id'])}`)
            }
          />
          <Datepicker
            label={i18n('form.date_issue.label')}
            placeholder={i18n('form.date_issue.placeholder')}
            value={get(form, ['date_issue'])}
            onChange={(expired_date) => handlerChanges(expired_date, 'date_issue')}
            errorMessage={
              get(errors, ['date_issue']) &&
              i18n(`validation.${get(errors, ['date_issue'])}`)
            }
          />
          <Datepicker
            label={i18n('form.date_expired.label')}
            placeholder={i18n('form.date_expired.placeholder')}
            value={get(form, ['date_expired'])}
            onChange={(expired_date) => handlerChanges(expired_date, 'date_expired')}
            errorMessage={
              get(errors, ['date_expired']) &&
              i18n(`validation.${get(errors, ['date_expired'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/visa/form/personal-info${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

PassportForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object,
  country: PropTypes.object
};

const mapStateToProps = (state) => ({
  country: {
    status: state.countryListReducer.status,
    error: state.countryListReducer.error,
    data: state.countryListReducer.data
  }
});

export default compose(connect(mapStateToProps), withRouter)(PassportForm);
