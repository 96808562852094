import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';

const IndonesianStreetFestival = ({ i18n, history }) => {
  const lang = (type, params) => i18n(`economy.tab18.${type}`, { ...params });

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('instruction') }} />
        </div>
        <Button
          color='primary'
          className='mtop16'
          onClick={() => history.push('/economy/culture-expo/form')}
        >
          {i18n('economy.apply_now')}
        </Button>
      </main>
    </div>
  );
};

IndonesianStreetFestival.propTypes = {
  i18n: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(IndonesianStreetFestival);
