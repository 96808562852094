import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';
import { getPassportServiceId } from '../function';

const NewBornPasspor = ({ i18n, passportServiceList }) => {
  const passportServiceId = getPassportServiceId(passportServiceList, 'lost_pass');
  const lang = (type, params) => i18n(`paspor_page.tab4.${type}`, { ...params });

  const stepOuterList = [
    {
      label: i18n('paspor_page.step.report_self.title')
    },
    {
      label: i18n('paspor_page.step.make_appoiment.title')
    },
    {
      label: i18n('paspor_page.step.create_form_register.title'),
      cta: i18n('paspor_page.step.create_form_register.cta'),
      link: `/passpor/schedule?type=lost_pass&serviceId=${passportServiceId}`
    }
  ];

  const instructionList = [
    i18n('paspor_page.instruction_step.non_foreign_citizenship'),
    i18n('paspor_page.instruction_step.affidavid_legalisir'),
    i18n('paspor_page.instruction_step.lost_passpor_letter'),
    i18n('paspor_page.instruction_step.copy_lost_passpor'),
    i18n('paspor_page.instruction_step.copy_birth_certificate'),
    i18n('paspor_page.instruction_step.copy_family_certificate'),
    i18n('paspor_page.instruction_step.copy_identity'),
    i18n('paspor_page.instruction_step.copy_diploma_certificate'),
    i18n('paspor_page.instruction_step.permission_form'),
    i18n('paspor_page.instruction_step.permission_address_2'),
    i18n('paspor_page.instruction_step.usps_money_order', { price: 91 })
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

NewBornPasspor.propTypes = {
  i18n: PropTypes.func,
  passportServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(NewBornPasspor);
