import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Dropdown from '../Dropdown';
import { setLang } from '../../lang/langAction';

const Multilang = ({ lang, label, setLang }) => {
  const options_lang = [
    { value: 'en', label: 'English' },
    { value: 'id', label: 'Indonesia' }
  ];

  const selectedLang = options_lang.find(({ value }) => value === lang);

  return (
    <div className='lang-wrap'>
      <Dropdown
        label={label}
        options={options_lang}
        value={selectedLang}
        onClick={({ value }) => {
          setLang(value);
        }}
        isValueObject
        dropdownToggleProps={{
          tag: 'span'
        }}
      />
    </div>
  );
};

Multilang.propTypes = {
  lang: PropTypes.string,
  label: PropTypes.string,
  setLang: PropTypes.func
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang
});

export default connect(mapStateToProps, { setLang })(Multilang);
