import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InstructionWrap from '../../../../components/InstructionWrap';

const Tax = ({ i18n }) => {
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('faq.tab3.title')}</div>
      <main>
        <InstructionWrap
          instruction={i18n('faq.tab3.content1')}
          instructionList={i18n('faq.instruction_step.tax')}
          type='bullet'
        />
      </main>
    </div>
  );
};

Tax.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Tax);
