import React, { useEffect, useState } from 'react';
import PhotoUpload from '../../../../components/PhotoUpload';
import TextAreaEditor from '../../../../components/TextAreaEditor';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '../../../../components/Button';
import TextInputNoob from '../../../../components/input/TextInputNoob';
import { arrayHasValue } from '../../../../helpers/helpers';
import PropTypes from 'prop-types';
import { getNewsDetail, updateNews } from '../action';
import { objectHasValue } from '../../../../helpers/helpers';
import moment from 'moment';
import './style.scss';

const EditNews = ({
  history,
  newsDetail,
  getNewsDetail,
  uploadPhoto,
  updateNews,
  newsUpdate
}) => {
  const [textValue, setTextValue] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const match = useRouteMatch();
  const { id } = match.params;

  const defaultForm = {
    title: titleValue,
    image_url: objectHasValue(uploadPhoto.data)
      ? uploadPhoto.data.data.url
      : newsDetail.data.image_url,
    excerpt: '',
    content: textValue,
    datetime: moment().format('YYYY-MM-DDTHH:MM:SS'),
    user_id: ''
  };

  useEffect(() => {
    if (!arrayHasValue(newsDetail.data)) {
      getNewsDetail(id);
    }
  }, []);

  const submitNews = () => {
    updateNews(id, defaultForm);
  };

  if (newsUpdate.status === 'resolve') {
    window.location.reload();
  }

  if (newsDetail.status === 'resolve' && titleValue === '' && textValue === '') {
    setTitleValue(newsDetail.data.title);
    setTextValue(newsDetail.data.content);
  }

  return (
    <div className='create-news'>
      <div className='header'>EDIT BERITA & INFORMASI</div>
      <div className='content'>
        <PhotoUpload
          initialImg={ newsDetail.status === 'resolve' ? newsDetail.data.image_url : ''}
        />
        <span style={{ height: 10, display: 'inline-block' }} />
        <TextInputNoob
          label='Title *'
          value={titleValue}
          action={setTitleValue}
          placeholder='Masukkan Judul Pengumuman'
        />
        <span style={{ height: 5, display: 'inline-block' }} />
        <TextAreaEditor
          value={textValue}
          label='Deskripsi'
          mandatory='true'
          action={setTextValue}
        />
        <span style={{ height: 5, display: 'inline-block' }} />
        <div className='btn-wrap'>
          <Button color='link' type='button' onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button color='primary' type='submit' className='pass-btn' onClick={() => submitNews()}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

EditNews.propTypes = {
  history: PropTypes.object,
  newsDetail: PropTypes.object,
  getNewsDetail: PropTypes.func,
  uploadPhoto: PropTypes.object,
  updateNews: PropTypes.func,
  newsUpdate: PropTypes.object
};

const mapStateToProps = (state) => ({
  newsDetail: {
    status: state.newsDetailReducerAdmin.status,
    error: state.newsDetailReducerAdmin.error,
    data: state.newsDetailReducerAdmin.data
  },
  newsUpdate: {
    status: state.updateNewsReducerAdmin.status
  },
  uploadPhoto: {
    status: state.uploadPhotoGeneral.status,
    error: state.uploadPhotoGeneral.error,
    data: state.uploadPhotoGeneral.data
  }
});

export default compose(connect(mapStateToProps, { getNewsDetail, updateNews }), withRouter)(EditNews);
