import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { Alert } from 'reactstrap';

import CardDetail from '../../../../../components/CardDetail';
import TextInput from '../../../../../components/input/TextInput';
import Dropdown from '../../../../../components/Dropdown';
import Button from '../../../../../components/Button';
import Loading from '../../../../../components/Loading';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll } from '../../../../../helpers/functions';

import { getOfficeList } from '../../../../form/NumberRegisterForm/action';
import './style.scss';

const TrxTracking = ({
  i18n,
  offices,
  getOfficeList,
  handlerSubmit,
  visible,
  errorSave,
  status
}) => {
  const defaultValue = {
    country: {},
    code: ''
  };
  const [form, setForm] = useState(defaultValue);
  const [errors, setErrors] = useState({});

  const officeOptions = get(offices, ['data']);
  const serviceOptions = [
    { label: 'Passport', value: 'passport' },
    { label: 'Visa', value: 'visa' },
    { label: 'Statement Letter', value: 'letter' },
    { label: 'Socio Culture', value: 'pensosbud' },
    { label: i18n('pensosbud.tab1.title'), value: 'pensosbud' },
    { label: i18n('pensosbud.tab2.title'), value: 'pensosbud' },
    { label: i18n('pensosbud.tab3.title'), value: 'pensosbud' },
    { label: i18n('pensosbud.tab4.title'), value: 'pensosbud' }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      office_id: get(form, ['office_id', 'id']),
      code: form.code
    };

    let schema = yup.object().shape({
      office_id: yup.string().required('required'),
      code: yup.string().required('required')
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        handlerSubmit(form);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    getOfficeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardDetail title={i18n('track.form1.title')} className='track-1'>
      {status === 'pending' && <Loading />}
      <Alert color='danger' isOpen={visible} className='m0'>
        {errorSave}
      </Alert>
      <form onSubmit={handleSubmit}>
        <Dropdown
          className='row input-container'
          label={i18n('form.service_type.label')}
          placeholder={i18n('form.service_type.placeholder')}
          value={get(form, ['service_type'])}
          options={serviceOptions}
          onClick={(item) => {
            handlerChanges(item, 'service_type');
          }}
          isValueObject
          dropdownToggleProps={{
            tag: 'div'
          }}
          errorMessage={
            get(errors, ['service_type']) &&
            i18n(`validation.${get(errors, ['service_type'])}`)
          }
        />
        <TextInput
          label={i18n('track.form1.code.label')}
          placeholder={i18n('track.form1.code.placeholder')}
          value={form.code}
          inputProps={{
            onChange: (e) => handlerChanges(e.target.value, 'code')
          }}
          errorMessage={
            get(errors, ['code']) && i18n(`validation.${get(errors, ['code'])}`)
          }
        />
        <Dropdown
          className='row input-container'
          label={i18n('form.workingArea.label')}
          sublabel={i18n('form.workingArea.sublabel')}
          placeholder={i18n('form.workingArea.placeholder')}
          value={get(form, ['office_id'])}
          options={officeOptions}
          onClick={(item) => {
            handlerChanges(item, 'office_id');
          }}
          isValueObject
          valueKey='id'
          labelKey='name'
          dropdownToggleProps={{
            tag: 'div',
            className: 'input-dropdown'
          }}
          withAutocomplete
          errorMessage={
            get(errors, ['office_id']) && i18n(`validation.${get(errors, ['office_id'])}`)
          }
        />
        <Button color='primary' type='submit'>
          {i18n('track.form1.cta')}
        </Button>
      </form>
    </CardDetail>
  );
};

TrxTracking.propTypes = {
  i18n: PropTypes.func,
  offices: PropTypes.object,
  getOfficeList: PropTypes.func,
  handlerSubmit: PropTypes.func,
  visible: PropTypes.bool,
  errorSave: PropTypes.string,
  status: PropTypes.string
};
const mapStateToProps = (state) => ({
  offices: {
    status: state.officeListReducer.status,
    error: state.officeListReducer.error,
    data: state.officeListReducer.data
  }
});

export default compose(connect(mapStateToProps, { getOfficeList }))(TrxTracking);
