import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Review from '../../components/NumberRegister/Review';

import { getQuery } from '../../../pages/Paspor/function';
import {
  getRegisDetailDocLegal,
  getDocLegalServiceList,
  resetDetailRegisterDoc
} from '../action';
import { getServiceTitle, docLegalType } from '../functions';

const RegisterReview = ({
  history,
  i18n,
  getRegisDetailDocLegal,
  docLegalRegisDetail,
  getDocLegalServiceList,
  docLegalService,
  resetDetailRegisterDoc
}) => {
  const { uniqueId, type } = getQuery(history);
  const docLegalDetail = get(docLegalRegisDetail, ['data']);
  const serviceId = get(docLegalDetail, ['legalization_service', 'id']);
  const keyTitle = getServiceTitle(get(docLegalService, ['data']), serviceId);
  const title =
    keyTitle && docLegalType[keyTitle] && i18n(`${docLegalType[keyTitle].label}`);
  const redirect = '/doc-legalization';

  useEffect(() => {
    if (!uniqueId) {
      if (type) {
        history.push(`/${type}`);
      } else {
        history.push('/');
      }
    } else if (type && uniqueId) {
      getDocLegalServiceList();
      getRegisDetailDocLegal(`${uniqueId}`);
    }

    return () => {
      resetDetailRegisterDoc();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNextStep = () => {
    if (keyTitle && docLegalType[keyTitle]) {
      const list = keyTitle && docLegalType[keyTitle].list;
      return (
        <>
          <ol>
            {list.map((listTitle, key) => (
              <li
                key={key}
                dangerouslySetInnerHTML={{
                  __html: i18n(`doc_legalization.instruction_step.${listTitle}`)
                }}
              />
            ))}
          </ol>
          <div>{i18n('track_status_info')}</div>
        </>
      );
    }
  };

  return (
    <Review
      i18n={i18n}
      reviewDetail={docLegalDetail}
      title={title}
      nextDirection={redirect}
      serviceName={title}
      withModalSuccess
      stepNextRegister={renderNextStep()}
    />
  );
};

RegisterReview.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  getRegisDetailDocLegal: PropTypes.func,
  docLegalRegisDetail: PropTypes.object,
  getDocLegalServiceList: PropTypes.func,
  docLegalService: PropTypes.array,
  resetDetailRegisterDoc: PropTypes.func
};

const mapStateToProps = (state) => ({
  docLegalRegisDetail: {
    status: state.regisDetailDocLegalReducer.status,
    error: state.regisDetailDocLegalReducer.error,
    data: state.regisDetailDocLegalReducer.data
  },
  docLegalService: {
    status: state.docLegalServiceReducer.status,
    error: state.docLegalServiceReducer.error,
    data: state.docLegalServiceReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getRegisDetailDocLegal,
    getDocLegalServiceList,
    resetDetailRegisterDoc
  }),
  withRouter
)(RegisterReview);
