const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getSosBudRegisterListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_REGISTER_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_REGISTER_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_REGISTER_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_SOSBUD_REGISTER_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getSosBudSubmissionListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_SUBMISSION_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_SUBMISSION_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_SUBMISSION_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_SOSBUD_SUBMISSION_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getSosBudRegisterDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_REGISTER_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_REGISTER_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_REGISTER_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const getSosBudSubmissionDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_SUBMISSION_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_SUBMISSION_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_SUBMISSION_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const patchSosBudApproveReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'PATCH_SOSBUD_APPROVE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_SOSBUD_APPROVE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_SOSBUD_APPROVE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const patchSosBudRejectReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'PATCH_SOSBUD_REJECT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_SOSBUD_REJECT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_SOSBUD_REJECT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const patchSosBudProcessReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'PATCH_SOSBUD_PROCESS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_SOSBUD_PROCESS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_SOSBUD_PROCESS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const patchSosBudPendingReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'PATCH_SOSBUD_PENDING_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_SOSBUD_PENDING_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_SOSBUD_PENDING_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const getSosbudStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getSosbudStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getSosbudStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getSosbudStatisticAllReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_SOSBUD_STATISTIC_ALL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SOSBUD_STATISTIC_ALL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SOSBUD_STATISTIC_ALL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

export {
  getSosBudRegisterListReducer,
  getSosBudSubmissionListReducer,
  getSosBudRegisterDetailReducer,
  getSosBudSubmissionDetailReducer,
  patchSosBudApproveReducer,
  patchSosBudRejectReducer,
  patchSosBudProcessReducer,
  patchSosBudPendingReducer,
  getSosbudStatisticReducer,
  getSosbudStatisticWeekReducer,
  getSosbudStatisticMonthReducer,
  getSosbudStatisticAllReducer
};
