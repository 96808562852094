import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll } from '../../../../../helpers/functions';

import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/input/TextInput';
import StateZip from '../../../components/StateZip';

const EmergenctContactForm = ({
  history,
  i18n,
  form: formGlobal,
  setForm: setFormGlobal,
  setTmpForm,
  tmpform,
  handleSubmit
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({
    ...(formGlobal.emergencyContact || tmpform.emergencyContact)
  });
  const [errors, setErrors] = useState({});

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      us_name: yup.string().required('required'),
      us_address: yup.string().required('required'),
      us_city: yup.string().required('required'),
      us_state: yup.object().shape({
        value: yup.string().required('required')
      }),
      us_zip_code: yup.string().required('required'),
      us_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      us_relation: yup.string().required('required'),
      id_name: yup.string().required('required'),
      id_address: yup.string().required('required'),
      id_city: yup.string().required('required'),
      id_state: yup.object().shape({
        _id: yup.string().required('required')
      }),
      id_zip_code: yup.string().required('required'),
      id_phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      id_relation: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, emergencyContact: form });
        history.push(`/passpor/registration/document${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, emergencyContact: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <form onSubmit={handlerSubmit} id='emergency-contact'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_emergency_contact_america')}
          </div>
          <TextInput
            label={i18n('form.full_name_contact.label')}
            sublabel={i18n('form.at_america')}
            placeholder={i18n('form.full_name_contact.placeholder')}
            value={get(form, ['us_name'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'us_name')
            }}
            errorMessage={
              get(errors, ['us_name']) && i18n(`validation.${get(errors, ['us_name'])}`)
            }
          />
          <TextInput
            label={i18n('form.address.label')}
            sublabel={i18n('form.at_america')}
            placeholder={i18n('form.american_address')}
            value={get(form, ['us_address'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'us_address')
            }}
            errorMessage={
              get(errors, ['us_address']) &&
              i18n(`validation.${get(errors, ['us_address'])}`)
            }
          />
          <StateZip
            i18n={i18n}
            form={{
              state: get(form, ['us_state']),
              zip_code: get(form, ['us_zip_code']),
              city: get(form, ['us_city'])
            }}
            handlerChanges={(value, key, errorKey) => {
              handlerChanges(value, `us_${key}`, errorKey && `us_${errorKey}`);
            }}
            errors={{
              'state.value': get(errors, ['us_state.value']),
              zip_code: get(errors, ['us_zip_code']),
              city: get(errors, ['us_city'])
            }}
          />
          <TextInput
            label={i18n('form.phone.label')}
            sublabel={i18n('form.at_america')}
            placeholder={i18n('form.phone.placeholder')}
            prependText='+1'
            value={get(form, ['us_phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'us_phone')
            }}
            errorMessage={
              get(errors, ['us_phone']) && i18n(`validation.${get(errors, ['us_phone'])}`)
            }
          />
          <TextInput
            label={i18n('form.relationship.label')}
            placeholder={i18n('form.relationship.placeholder')}
            value={get(form, ['us_relation'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'us_relation')
            }}
            errorMessage={
              get(errors, ['us_relation']) &&
              i18n(`validation.${get(errors, ['us_relation'])}`)
            }
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_emergency_contact_indonesia')}
          </div>
          <TextInput
            label={i18n('form.full_name_contact.label')}
            sublabel={i18n('form.at_indonesia')}
            placeholder={i18n('form.full_name_contact.placeholder')}
            value={get(form, ['id_name'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'id_name')
            }}
            errorMessage={
              get(errors, ['id_name']) && i18n(`validation.${get(errors, ['id_name'])}`)
            }
          />
          <TextInput
            label={i18n('form.address.label')}
            sublabel={i18n('form.at_indonesia')}
            placeholder={i18n('form.indonesia_address')}
            value={get(form, ['id_address'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'id_address')
            }}
            errorMessage={
              get(errors, ['id_address']) &&
              i18n(`validation.${get(errors, ['id_address'])}`)
            }
          />
          <StateZip
            i18n={i18n}
            type='id'
            form={{
              state: get(form, ['id_state']),
              zip_code: get(form, ['id_zip_code']),
              city: get(form, ['id_city'])
            }}
            handlerChanges={(value, key, errorKey) => {
              handlerChanges(value, `id_${key}`, errorKey && `id_${errorKey}`);
            }}
            errors={{
              'state.value': get(errors, ['id_state._id']),
              city: get(errors, ['id_city']),
              zip_code: get(errors, ['id_zip_code'])
            }}
          />
          <TextInput
            label={i18n('form.phone.label')}
            sublabel={i18n('form.at_indonesia')}
            placeholder={i18n('form.phone.placeholder')}
            prependText='+62'
            value={get(form, ['id_phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'id_phone')
            }}
            errorMessage={
              get(errors, ['id_phone']) && i18n(`validation.${get(errors, ['id_phone'])}`)
            }
          />
          <TextInput
            label={i18n('form.relationship.label')}
            placeholder={i18n('form.relationship.placeholder')}
            value={get(form, ['id_relation'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'id_relation')
            }}
            errorMessage={
              get(errors, ['id_relation']) &&
              i18n(`validation.${get(errors, ['id_relation'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/passpor/registration/document${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

EmergenctContactForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  form: PropTypes.object,
  setForm: PropTypes.func,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object
};

export default withRouter(EmergenctContactForm);
