import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InstructionWrap from '../../../../components/InstructionWrap';

const Letter = ({ i18n }) => {
  const pricelist = [
    {
      label: i18n('rates.tab5.pricelist.1.title'),
      price: i18n('rates.tab5.pricelist.1.price')
    },
    {
      label: i18n('rates.tab5.pricelist.2.title'),
      price: i18n('rates.tab5.pricelist.2.price')
    },
    {
      label: i18n('rates.tab5.pricelist.3.title'),
      price: i18n('rates.tab5.pricelist.3.price')
    },
    {
      label: i18n('rates.tab5.pricelist.4.title'),
      price: i18n('rates.tab5.pricelist.4.price')
    },
    {
      label: i18n('rates.tab5.pricelist.5.title'),
      price: i18n('rates.tab5.pricelist.5.price')
    },
    {
      label: i18n('rates.tab5.pricelist.6.title'),
      price: i18n('rates.tab5.pricelist.6.price')
    },
    {
      label: i18n('rates.tab5.pricelist.7.title'),
      price: i18n('rates.tab5.pricelist.7.price')
    },
    {
      label: i18n('rates.tab5.pricelist.8.title'),
      price: i18n('rates.tab5.pricelist.8.price')
    },
    {
      label: i18n('rates.tab5.pricelist.9.title'),
      price: i18n('rates.tab5.pricelist.9.price')
    },
    {
      label: i18n('rates.tab5.pricelist.10.title'),
      price: i18n('rates.tab5.pricelist.10.price')
    }
  ];

  return (
    <div className='card-instruction-detail'>
      <div className='rates'>
        <span style={{ fontSize: 14 }}>{i18n('rates.info1')}</span>
        <div style={{ fontSize: 16, color: 'red' }}>{i18n('rates.info2')}</div>
      </div>
      <main>
        <InstructionWrap type='rates' rates={pricelist} />
      </main>
    </div>
  );
};

Letter.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Letter);
