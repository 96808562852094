const register = {
  data: [],
  status: 'idle',
  error: ''
};

const registerReducer = (state = register, action) => {
  switch (action.type) {
    case 'GET_REGISTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_REGISTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_REGISTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: [...state.data, action.payload.data]
      };
    default:
      return state;
  }
};

const citiesID = {
  data: [],
  status: 'idle',
  error: ''
};

const citiesIdReducer = (state = citiesID, action) => {
  switch (action.type) {
    case 'GET_CITIES_ID_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_CITIES_ID_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.cities
      };
    case 'GET_CITIES_ID_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.cities.data
      };
    default:
      return state;
  }
};

const provinceID = {
  data: [],
  status: 'idle',
  error: ''
};

const provinceIDReducer = (state = provinceID, action) => {
  switch (action.type) {
    case 'GET_PROVINCE_ID_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PROVINCE_ID_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.province
      };
    case 'GET_PROVINCE_ID_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.province.data
      };
    default:
      return state;
  }
};

const portList = {
  data: [],
  status: 'idle',
  error: ''
};

const portListReducer = (state = portList, action) => {
  switch (action.type) {
    case 'GET_PORT_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PORT_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.port
      };
    case 'GET_PORT_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.port.data
      };
    default:
      return state;
  }
};

const countryList = {
  data: [],
  status: 'idle',
  error: ''
};

const countryListReducer = (state = countryList, action) => {
  switch (action.type) {
    case 'GET_COUNTRY_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_COUNTRY_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.country
      };
    case 'GET_COUNTRY_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.country.data
      };
    default:
      return state;
  }
};

const submissionDetail = {
  data: [],
  status: 'idle',
  error: ''
};

const submissionDetailReducer = (state = submissionDetail, action) => {
  switch (action.type) {
    case 'GET_SUBMISSION_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SUBMISSION_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.submission
      };
    case 'GET_SUBMISSION_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.submission.data
      };
    default:
      return state;
  }
};

// PASSPORT SERVICE
const passportService = {
  data: [],
  status: 'idle',
  error: ''
};

const passportServiceReducer = (state = passportService, action) => {
  switch (action.type) {
    case 'GET_PASSPORT_SERVICE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PASSPORT_SERVICE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.passportService
      };
    case 'GET_PASSPORT_SERVICE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.passportService.data.data
      };
    default:
      return state;
  }
};

// REGISTRATION DETAIL
const registrationDetail = {
  data: {},
  status: 'idle',
  error: ''
};

const registrationDetailReducer = (state = registrationDetail, action) => {
  switch (action.type) {
    case 'GET_REGISTRATION_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_REGISTRATION_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.registrationDetail
      };
    case 'GET_REGISTRATION_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.registrationDetail.data.data
      };
    default:
      return state;
  }
};

// PASSPORT DOC SERVICE
const passportDoc = {
  data: [],
  status: 'idle',
  error: ''
};

const passportDocReducer = (state = passportDoc, action) => {
  switch (action.type) {
    case 'GET_PASSPORT_DOC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PASSPORT_DOC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.passportDoc
      };
    case 'GET_PASSPORT_DOC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.passportDoc.data.data
      };
    default:
      return state;
  }
};

const registerDoc = {
  data: [],
  status: 'idle',
  error: ''
};

const registerDocReducer = (state = registerDoc, action) => {
  switch (action.type) {
    case 'PUT_REGISTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PUT_REGISTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PUT_REGISTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: [...state.data, action.payload.data]
      };
    case 'RESET_PUT_REGISTER':
      return {
        ...state,
        ...registerDoc
      };
    default:
      return state;
  }
};
export {
  registerReducer,
  citiesIdReducer,
  provinceIDReducer,
  portListReducer,
  countryListReducer,
  submissionDetailReducer,
  passportServiceReducer,
  registrationDetailReducer,
  passportDocReducer,
  registerDocReducer
};
