import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import DatepickerRange from '../components/DatepickerRange';
import Passport from './components/Passport';
import Visa from './components/Visa';
import Pensosbud from './components/Pensosbud';
import Ekonomi from './components/Ekonomi';
import Other from './components/Other';

import './style.scss';

const Dashboard = ({ history }) => {
  const date = new Date();
  var last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);
  const [reloadData, setReloadData] = useState(false);

  const statisticProps = {
    history,
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
    reloadData,
    setReloadData
  };

  return (
    <div className='homepage'>
      <DatepickerRange
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onCalendarClose={() => {
          setReloadData(true);
        }}
      />
      <div className='big-title'>Konsuler</div>
      <Passport {...statisticProps} />
      <Visa {...statisticProps} />
      <Pensosbud {...statisticProps} />
      <Ekonomi {...statisticProps} />
      <Other {...statisticProps} />
    </div>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object
};

export default withRouter(Dashboard);
