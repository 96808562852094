import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';
import moment from 'moment';
import { Alert } from 'reactstrap';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../../helpers/functions';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import Datepicker from '../../../../components/input/Datepicker';
import Loading from '../../../../components/Loading';
import Modal from '../../../../components/Modal';
import Icon from '../../../../components/Icon';

import { postSosBudListServe } from '../action';

import './style.scss';

const ListServeForm = ({
  history,
  i18n,
  serviceId,
  sosBudListServe,
  postSosBudListServe
}) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { data, error, status } = sosBudListServe;

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      name: yup.string().required('required'),
      dob_place: yup.string().required('required'),
      dob: yup.date().required('required'),
      job: yup.string().required('required'),
      universitas: yup.string().required('required'),
      afiliasi: yup.string().required('required'),
      address: yup.string().required('required'),
      phone: yup
        .number()
        .test(
          'len',
          i18n('validation.min_length', { min: 10 }),
          (val) => val && val.toString().length > 9
        )
        .typeError('number')
        .required('required')
        .integer('number')
        .positive('number'),
      email: yup.string().email('email').required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        postSosBudListServe({
          name: form.name,
          email: form.email,
          phone: `${form.phone}`,
          service_id: serviceId,
          birth_date: moment(form.dob).format('YYYY-MM-DD'),
          birth_city: form.dob_place,
          job: form.job,
          address: form.address,
          university: form.universitas,
          organization: form.afiliasi
        });
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
      const messageDetail = get(error, [
        'response',
        'data',
        'error',
        'detail',
        0,
        'message'
      ]);
      setErrorSave(`${message}. ${keyDetail}: ${messageDetail}.`);
      errorAlertScroll();
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // Modal
  let modalProps = {
    buttonProps: [
      {
        btnStyle: { color: 'primary' },
        label: i18n('cta.ok'),
        onClick: () => {
          setForm({});
          setShowModal(false);
          history.push('/');
        }
      }
    ],
    className: 'success',
    title: (
      <div className='top-wrap'>
        <Icon icon='green-check' />
        <div>{i18n('success_save_modal.title')}</div>
      </div>
    ),
    desc: (
      <div
        style={{
          marginBottom: '16px'
        }}
      >
        {i18n('thankyou_modal.title')}
      </div>
    )
  };

  return (
    <>
      <form onSubmit={handlerSubmit} id='list-serve'>
        {status === 'pending' && <Loading />}

        <Alert color='danger' isOpen={visible}>
          {errorSave}
        </Alert>
        <div className='form-wrap'>
          <TextInput
            label={i18n('pensosbud.tab11.form.name.label')}
            placeholder={i18n('pensosbud.tab11.form.name.placeholder')}
            value={get(form, ['name'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'name')
            }}
            errorMessage={
              get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
            }
          />

          <div className='col2'>
            <TextInput
              label={i18n('pensosbud.tab11.form.dob_place.label')}
              placeholder={i18n('pensosbud.tab11.form.dob_place.placeholder')}
              value={get(form, ['dob_place'])}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'dob_place')
              }}
              errorMessage={
                get(errors, ['dob_place']) &&
                i18n(`validation.${get(errors, ['dob_place'])}`)
              }
            />
            <Datepicker
              label={i18n('pensosbud.tab11.form.dob.label')}
              placeholder={i18n('pensosbud.tab11.form.dob.placeholder')}
              className='dob'
              value={get(form, ['dob'])}
              onChange={(dob) => handlerChanges(dob, 'dob')}
              errorMessage={
                get(errors, ['dob']) && i18n(`validation.${get(errors, ['dob'])}`)
              }
            />
          </div>

          <TextInput
            label={i18n('pensosbud.tab11.form.job.label')}
            placeholder={i18n('pensosbud.tab11.form.job.placeholder')}
            value={get(form, ['job'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'job')
            }}
            errorMessage={
              get(errors, ['job']) && i18n(`validation.${get(errors, ['job'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab11.form.universitas.label')}
            placeholder={i18n('pensosbud.tab11.form.universitas.placeholder')}
            value={get(form, ['universitas'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'universitas')
            }}
            errorMessage={
              get(errors, ['universitas']) &&
              i18n(`validation.${get(errors, ['universitas'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab11.form.afiliasi.label')}
            placeholder={i18n('pensosbud.tab11.form.afiliasi.placeholder')}
            value={get(form, ['afiliasi'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'afiliasi')
            }}
            errorMessage={
              get(errors, ['afiliasi']) && i18n(`validation.${get(errors, ['afiliasi'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab11.form.address.label')}
            placeholder={i18n('pensosbud.tab11.form.address.placeholder')}
            value={get(form, ['address'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'address')
            }}
            errorMessage={
              get(errors, ['address']) && i18n(`validation.${get(errors, ['address'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab11.form.email.label')}
            placeholder={i18n('pensosbud.tab11.form.email.placeholder')}
            value={get(form, ['email'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'email')
            }}
            errorMessage={
              get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
            }
          />

          <TextInput
            label={i18n('pensosbud.tab11.form.phone.label')}
            placeholder={i18n('pensosbud.tab11.form.phone.placeholder')}
            value={get(form, ['phone'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'phone')
            }}
            errorMessage={
              get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
            }
            prependText='+62'
          />
        </div>

        <div className='btn-wrap'>
          <Button color='primary' type='submit' className='pass-btn'>
            {i18n('pensosbud.tab11.form.cta')}
          </Button>
        </div>
      </form>
      <Modal showModal={showModal} setShowModal={setShowModal} {...modalProps} />
    </>
  );
};

ListServeForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  serviceId: PropTypes.string,
  postSosBudListServe: PropTypes.func,
  sosBudListServe: PropTypes.object
};

const mapStateToProps = (state) => ({
  sosBudListServe: {
    status: state.sosBudListServeReducer.status,
    error: state.sosBudListServeReducer.error,
    data: state.sosBudListServeReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postSosBudListServe
  }),
  withRouter
)(ListServeForm);
