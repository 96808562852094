import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalReact, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../Button';

import './style.scss';

const Modal = ({ showModal, setShowModal, className, buttonProps, title, desc }) => {
  const renderBtn = () =>
    buttonProps.map(({ btnStyle, label, onClick, ...props }, idx) => {
      if (!label) {
        return;
      }

      return (
        <Button key={idx} {...btnStyle} onClick={onClick}>
          {label}
        </Button>
      );
    });

  return (
    <ModalReact
      isOpen={showModal}
      toggle={() => setShowModal(!showModal)}
      className={className}
    >
      <ModalHeader toggle={() => setShowModal(!showModal)}>{title}</ModalHeader>
      <ModalBody>{desc}</ModalBody>
      {buttonProps && <ModalFooter>{renderBtn()}</ModalFooter>}
    </ModalReact>
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  className: PropTypes.string,
  buttonProps: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default Modal;
