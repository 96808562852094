import React, { useState, useEffect } from 'react';
import { Container, Input } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import { postEventRegister } from '../action';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import './style.scss';
import detailEvent from '../../../../assets/img/event-detail.png';

import { getEventDetail } from '../action';

const EventRegistration = ({
  history,
  i18n,
  getEventDetail,
  eventDetail,
  match,
  postEventRegister,
  eventRegister
}) => {
  const { id } = get(match, ['params']);
  const data = get(eventDetail, ['data', 0]);
  const { title, currency, datetime, admission_fee, image_url } = data || {};

  const defaultValue = {
    event_id: id,
    qty: 1,
    name: '',
    email: ''
  };
  const [form, setForm] = useState(defaultValue);
  const quantity_options = [
    { value: '1', label: '1x' },
    { value: '2', label: '2x' },
    { value: '3', label: '3x' },
    { value: '4', label: '4x' },
    { value: '5', label: '5x' }
  ];

  const changeQty = (event) => {
    setForm({ ...form, qty: event.target.value });
  };

  const handlerSubmit = () => {
    postEventRegister(form);
  };

  if (eventRegister.status === 'resolve') {
    window.location.reload();
  }

  useEffect(() => {
    getEventDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ paddingTop: 24 }}>
      {eventDetail.status === 'resolve' && (
        <div className='event-registration'>
          <span className='back-homepage' onClick={() => history.goBack()}>
            <span className='btn-back' />
            {i18n('cta.back_prev_page')}
          </span>
          <div className='event-registration-detail'>
            <div className='event-registration-summary'>
              <div className='event-registration-summary-img'>
                <img src={image_url} alt='event-detail-img' />
              </div>
              <div className='event-registration-summary-info'>
                <div className='event-id'>#{id}</div>
                <div className='event-name'>{title}</div>
                <div className='event-date-time'>
                  {/* {moment(datetime).format('DD MMM YYYY, HH:MM')} */}
                  {moment(datetime).utcOffset(datetime).format('LLL')}
                </div>
                <div className='event-price'>
                  {currency} {admission_fee === 0 ? 'Free' : admission_fee}
                </div>
              </div>
            </div>
            <div className='event-registration-total' style={{ display: 'none' }}>
              <Input type='select' name='select' onChange={changeQty}>
                {quantity_options.map((q) => (
                  <option key={q.value} value={q.value}>
                    {q.label}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <hr />
          <div className='event-registration-form'>
            <div className='event-registration-form-top'>
              <span>{i18n('event.register.information')}</span>
              <span>
                Total
                <b>
                  {currency} {form.qty * admission_fee}
                </b>
              </span>
            </div>
            <div className='event-registration-form-bottom'>
              <TextInput
                label={i18n('event.register.fullname')}
                placeholder={i18n('event.register.name_placeholder')}
                value={form.name}
                inputProps={{
                  onChange: (e) => setForm({ ...form, name: e.target.value })
                }}
              />
              <TextInput
                label='Email *'
                placeholder={i18n('event.register.email_placeholder')}
                value={form.email}
                inputProps={{
                  onChange: (e) => setForm({ ...form, email: e.target.value })
                }}
              />
            </div>
            <div className='btn-wrap'>
              <span>
                <Link to='/'>{i18n('cta.cancel')}</Link>
              </span>
              <Button color='primary' onClick={handlerSubmit}>
                {i18n('cta.submit')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

EventRegistration.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func,
  getEventDetail: PropTypes.func,
  eventDetail: PropTypes.object,
  match: PropTypes.object,
  postEventRegister: PropTypes.func,
  eventRegister: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  eventDetail: {
    status: state.eventDetailReducer.status,
    error: state.eventDetailReducer.error,
    data: state.eventDetailReducer.data
  },
  eventRegister: {
    status: state.eventRegisterReducer.status,
    error: state.eventRegisterReducer.error,
    data: state.eventRegisterReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getEventDetail, postEventRegister }),
  withRouter
)(EventRegistration);
