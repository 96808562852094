import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import './style.scss';
import { postPhotoGeneral } from './action';

const PhotoUploader = ({ i18n, uploadPhoto, postPhotoGeneral, initialImg }) => {
  const handlerChanges = (e) => {
    const photo = {};
    const file = e.target.files[0];
    photo.file = file;
    const formData = new FormData();
    formData.append('file', photo.file);
    postPhotoGeneral(formData);
  };

  return (
    <div className='photo-uploader'>
      <div className='img-preview'>
        {uploadPhoto.status === 'resolve' ? (
          <img src={uploadPhoto.data.data.url} alt='photox' height='100%' />
        ) : initialImg === undefined ? '' : (<img src={initialImg} alt='photox' height='100%' />) }
      </div>
      <div className='upload-wrap'>
        <div className='upload-button-wrap'>
          <input
            type='file'
            placeholder='Upload File'
            className='input-file'
            onChange={handlerChanges}
          />
        </div>
        <p dangerouslySetInnerHTML={{ __html: i18n('upload_component.note') }} />
      </div>
    </div>
  );
};

PhotoUploader.propTypes = {
  i18n: PropTypes.func,
  uploadPhoto: PropTypes.any,
  postPhotoGeneral: PropTypes.func,
  initialImg: PropTypes.string
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  uploadPhoto: {
    status: state.uploadPhotoGeneral.status,
    error: state.uploadPhotoGeneral.error,
    data: state.uploadPhotoGeneral.data
  }
});

export default compose(
  connect(mapStateToProps, { postPhotoGeneral }),
  withRouter
)(PhotoUploader);
