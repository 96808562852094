const complaints = {
  data: {},
  status: 'idle',
  error: ''
};

const complaintDetail = {
  data: {},
  status: 'idle',
  error: ''
};

const answerComplaint = {
  data: {},
  status: 'idle',
  error: ''
};

const complaintsReducerAdmin = (state = complaints, action) => {
  switch (action.type) {
    case 'GET_COMPLAINTS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_COMPLAINTS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_COMPLAINTS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const complaintDetailReducerAdmin = (state = complaintDetail, action) => {
  switch (action.type) {
    case 'GET_COMPLAINTS_DETAIL_ADMIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_COMPLAINTS_DETAIL_ADMIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_COMPLAINTS_DETAIL_ADMIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const postAnswerComplaintReducerAdmin = (state = answerComplaint, action) => {
  switch (action.type) {
    case 'POST_ANSWER_COMPLAINT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_ANSWER_COMPLAINT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_ANSWER_COMPLAINT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

const sendEmailReducer = (state = answerComplaint, action) => {
  switch (action.type) {
    case 'SEND_EMAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'SEND_EMAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload.data
      };
    case 'SEND_EMAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        error: action.payload.data
      };
    default:
      return state;
  }
}

export {
  complaintsReducerAdmin,
  complaintDetailReducerAdmin,
  postAnswerComplaintReducerAdmin,
  sendEmailReducer
};
