import { apiCaller } from '../../../../helpers/apiCaller';

// Number regis passport
const getStatementRequest = () => ({
  type: 'GET_STATEMENT_REQUEST'
});

const getStatementSuccess = (data) => ({
  type: 'GET_STATEMENT_SUCCESS',
  payload: data
});

const getStatementFailure = (error) => ({
  type: 'GET_STATEMENT_FAILURE',
  payload: error
});

const postStatement = (params) => {
  return (dispatch) => {
    dispatch(getStatementRequest());
    return apiCaller('investment/statement', 'post', params)
      .then((data) => dispatch(getStatementSuccess(data)))
      .catch((error) => dispatch(getStatementFailure(error)));
  };
};

const resetPostData = () => {
  const defaultData = {
    data: [],
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_POST_DATA',
      data: defaultData
    });
  };
};

export { postStatement, resetPostData };
