const defaultData = {
  data: [],
  status: 'idle',
  error: ''
};

const exhibitorReducer = (state = defaultData, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_DATA':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export { exhibitorReducer };
