import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import './style.scss';

const TextInput = ({
  label,
  sublabel,
  errorMessage,
  inputProps,
  value,
  className = '',
  disabledAll,
  options,
  valueKey = 'value',
  nameKey = 'name',
  additionalOptions,
  detailInput
}) => {
  return (
    <div className={`radio-input-container ${className} ${errorMessage ? 'err' : ''}`}>
      <div className='label'>{label}</div>
      {sublabel && <div className='sublabel'>{sublabel}</div>}
      <div className='radio-input-wrap'>
        <div className='radio-options-container'>
          <div className='radio-options-wrap'>
            {options.map((option, idx) => (
              <label
                key={idx}
                className={`radio-container ${
                  disabledAll || option.disabled ? 'disabled' : ''
                }`}
              >
                <input
                  type='radio'
                  value={option[valueKey]}
                  checked={`${value}` === `${option[valueKey]}`}
                  disabled={disabledAll || get(option, 'disabled', false)}
                  {...inputProps}
                />
                <span
                  className={`radio-style ${
                    disabledAll || option.disabled ? 'disabled' : ''
                  }`}
                />
                <span className='option-label'>{option[nameKey]}</span>
              </label>
            ))}
          </div>
          {additionalOptions}
        </div>
        {detailInput && <div className='detail-input'>{detailInput}</div>}
        {errorMessage && <div className='err-message'>{errorMessage}</div>}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  className: PropTypes.string,
  sublabel: PropTypes.any,
  disabledAll: PropTypes.bool,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  nameKey: PropTypes.string,
  additionalOptions: PropTypes.any
};

export default TextInput;
