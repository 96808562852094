import React from 'react';
import TextInput from '../../components/input/TextInput';
import Icon from '../../components/Icon';
import moment from 'moment';
import fileSaver from 'file-saver';
import { apiCaller } from '../../helpers/apiCaller';

const process = ({ name }) => ({
  title: 'Konfirmasi!',
  desc: (
    <span>
      Apakah Anda yakin akan <b>memproses</b> data untuk {name}?
    </span>
  ),
  cta: {
    yes: {
      text: 'Proses',
      color: 'primary'
    },
    no: {
      text: 'Tidak'
    }
  }
});

const finish = ({ name }) => ({
  title: 'Konfirmasi!',
  desc: (
    <span>
      Apakah Anda yakin merubah status menjadi <b>selesai</b> data untuk {name}?
    </span>
  ),
  cta: {
    yes: {
      text: 'Ya',
      color: 'info'
    },
    no: {
      text: 'Tidak'
    }
  }
});

const tunda = ({ name, reason, onChange }) => ({
  title: 'Konfirmasi Tunda Proses',
  desc: (
    <div className='tolak-wrap'>
      <span style={{ marginBottom: '24px', display: 'inline-block' }}>
        Apakah Anda yakin akan{' '}
        <span className='red-text'>
          <b>menunda</b>
        </span>{' '}
        data untuk {name} ?
      </span>
      <TextInput
        value={reason}
        placeholder='Tuliskan alasan Anda menunda data ini'
        type='text'
        inputMode='textarea'
        inputProps={{
          onChange
        }}
      />
    </div>
  ),
  cta: {
    yes: {
      text: 'Ya, Tunda',
      color: 'warn'
    },
    no: {
      text: 'Tidak'
    }
  }
});

const reject = ({ name, reason, onChange }) => ({
  title: 'Konfirmasi Tunda Proses',
  desc: (
    <div className='tolak-wrap'>
      <span style={{ marginBottom: '24px', display: 'inline-block' }}>
        Apakah Anda yakin akan{' '}
        <span className='red-text'>
          <b>menunda</b>
        </span>{' '}
        data untuk {name} ?
      </span>
      <TextInput
        value={reason}
        placeholder='Tuliskan alasan Anda menunda data ini'
        type='text'
        inputMode='textarea'
        inputProps={{
          onChange
        }}
      />
    </div>
  ),
  cta: {
    yes: {
      text: 'Ya, Tunda',
      color: 'warn'
    },
    no: {
      text: 'Tidak'
    }
  }
});

const failedSave = ({ actionData }) => ({
  title: 'Gagal menyimpan',
  desc: actionData,
  cta: {
    no: {
      text: 'Close'
    }
  }
});

const successSave = () => ({
  title: (
    <div className='top-wrap'>
      <Icon icon='green-check' />
      <div>Status berhasil diupdate</div>
    </div>
  ),
  className: 'success complaint',
  cta: {
    no: {
      text: 'Ok',
      color: 'primary',
      outline: false
    }
  }
});

const typeModalList = {
  process,
  tunda,
  finish,
  failedSave,
  successSave,
  reject
};

const printPreview = (element) => {
  let toPrint = element;
  let popupWin = window.open('', '_blank', 'width=700,height=700,location=no,left=200px');
  popupWin.document.open();
  popupWin.document.write(printPreviewBerkas());
  popupWin.document.write(toPrint);
  popupWin.document.write('</html>');
  popupWin.document.close();
};

const printPreviewBerkas = () => {
  return `<html>
  <title>Berkas</title>
  <style>
  @media print /*--This is for Print--*/
  {
    body {
      font-family: 'Roboto', sans-serif!important;
      padding: 24px;
    }

    .header {
      padding: 24px 0;
      border-bottom: 1px solid #DDDDDD;
    }
  
    .header .title {
      color: #053960;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    .note {
      padding: 24px 0;
      padding-bottom: 0;
      font-size: 16px;
      line-height: 24px;
      color: #053960;
    }

    .review-wrap .number-item {
      padding: 24px 0;
    }

    .review-wrap .number-item .item {
      margin-bottom: 16px;
    }

    .review-wrap .number-item .label {
      font-size: 18px;
      line-height: 24px;
      color: #666666;
      margin-bottom: 8px;
    }

    .review-wrap .number-item .text {
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      color: #111111;
    }

    .btn-wrap {
      display: none;
    }
  }

  body {
    font-family: 'Roboto', sans-serif!important;
    padding: 24px;
  }

  .header {
    padding: 24px 0;
    border-bottom: 1px solid #DDDDDD;
  }

  .header .title {
    color: #053960;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  .note {
    padding: 24px 0;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: #053960;
  }

  .review-wrap .number-item {
    padding: 24px 0;
  }

  .review-wrap .number-item .item {
    margin-bottom: 16px;
  }

  .review-wrap .number-item .label {
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    margin-bottom: 8px;
  }

  .review-wrap .number-item .text {
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #111111;
  }

  .btn-wrap {
    display: none;
  }
  </style>
  </head>
  <body onload="window.print()">`;
};

const getStatisticDate = () => {
  const formatDate = 'YYYY-MM-DD';
  const today = moment().format(formatDate);
  const startWeek = moment().startOf('week').format(formatDate);
  const endWeek = moment().endOf('week').format(formatDate);
  const startMonth = moment().startOf('month').format(formatDate);
  const endMonth = moment().endOf('month').format(formatDate);

  return {
    today,
    startWeek,
    endWeek,
    startMonth,
    endMonth
  };
};

const downloadFile = (type, params, fileName) => {
  apiCaller(`admin/${type}/export`, 'get', undefined, true, params, {
    responseType: 'arraybuffer'
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      fileSaver.saveAs(blob, fileName);
    })
    .catch((err) => console.log(err));
};

export { typeModalList, printPreview, getStatisticDate, downloadFile };
