import React from 'react';
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';
import './style.scss';
import PropTypes from 'prop-types';

import kjriLogo from '../../../assets/img/kjri_logo.png';
import ccLogo from '../../../assets/img/cc_logo.svg';
import homeLogo from '../../../assets/img/home_24.svg';

const Login = ({ history }) => {
  return (
    <div className='login-page'>
      <div className='login-page-img'>
        <img src={kjriLogo} alt='kjri logo' width='62' />
        <img src={ccLogo} alt='consuler logo' width='62' />
      </div>
      <div className='desc-1'>Welcome to</div>
      <div className='desc-2'>
        Business Portal Consulate Connect
        <br />
        KJRI New York
      </div>
      <div className='desc-3'>
        Portal Digital Informasi Kerjasama Indonesia dan Amerika Serikat
      </div>
      <div className='login-form'>
        <div className='form-title'>Login</div>
        <input placeholder='Email' />
        <input placeholder='Password' type='password' />
        <Button color='third'>Login Sekarang</Button>
      </div>
      <div className='back-to-home' onClick={() => history.push('/')}>
        <img src={homeLogo} alt='home logo' />
        Kembali ke halaman utama konsular
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.any
};

export default withRouter(Login);
