import React, { useState } from 'react';
import { stringHasValue } from '../../helpers/helpers';
import './style.scss';

import { country } from '../../assets/json/country';

const CountryCard = () => {
  const [showMore, setShowMore] = useState(false);
  const [keyWord, setKeyWord] = useState('');

  const handleKeyWord = (event) => {
    setKeyWord(event.target.value);
  };

  return (
    <div className='country-list'>
      <input type='text' placeholder='Search' value={keyWord} onChange={handleKeyWord} />
      <table className={`country-list-data ${showMore ? 'show-more' : ''}`}>
        <tbody>
          {stringHasValue(keyWord) ? (
            <>
              {country.map((c, i) => (
                <>
                  {c.toLowerCase().match(keyWord) && (
                    <tr key={i}>
                      <td>{i + 1}.</td>
                      <td>{c}</td>
                    </tr>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {country.map((c, i) => (
                <tr key={i}>
                  <td>{i + 1}.</td>
                  <td>{c}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {!stringHasValue(keyWord) && (
        <span
          onClick={() => setShowMore(!showMore)}
          className={`show-more-button ${showMore && 'revert'}`}
        >
          {showMore ? 'Show Less' : 'Show All'}
        </span>
      )}
    </div>
  );
};

export default CountryCard;
