import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col } from 'reactstrap';
import Multilang from '../Multilang';
import Icon from '../Icon';

import './style.scss';

const Footer = ({ i18n }) => {
  return (
    <footer>
      <Container>
        <Col>
          <div className='logo' />
          <div className='content'>
            <div className='title'>{i18n('footer.title')}</div>
            <div className='copyright'>{i18n('footer.copyright')}</div>
          </div>
        </Col>
        <Col>
          <nav>
            <a href='/'>{i18n('nav.home')}</a>
            <a href='/contact-us'>{i18n('nav.contact_us')}</a>
            <a href='/faq'>{i18n('nav.faq')}</a>
          </nav>
          <div className='socmed-wrap'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/indonesiainnewyork/'
            >
              <Icon icon='instagram' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.facebook.com/kjrinewyork/'
            >
              <Icon icon='facebook' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://twitter.com/kjri_ny'
            >
              <Icon icon='twitter' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.youtube.com/channel/UC4S5Z87I0d6B05TzHwDA8VQ/featured'
            >
              <Icon icon='youtube' />
            </a>
          </div>
          <Multilang />
        </Col>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  i18n: PropTypes.func
};

export default Footer;
