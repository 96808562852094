import React from 'react';
import PropType from 'prop-types';
import CountryCard from '../CountryCard';
import Checkpoint from '../Checkpoint';
import { connect } from 'react-redux';

const InstructionWrap = ({ instruction, instructionList, rates, type, i18n }) => {
  return (
    <div className='instruction-wrap'>
      {type === 'rates' && (
        <table className='rates-table'>
          {rates.map((list, idx) => (
            <tr key={idx}>
              <td>
                {list.label}
                <div className='subtitle'>{list.sub}</div>
              </td>
              <td
                className={list.price === 'Gratis' || list.price === 'Free' ? 'free' : ''}
              >
                {list.price}
              </td>
            </tr>
          ))}
        </table>
      )}
      <p dangerouslySetInnerHTML={{ __html: instruction }} />
      {type === 'country' && <CountryCard />}
      {type === 'checkpoint' && <Checkpoint />}
      {type === 'number' && (
        <ol>
          {instructionList.split(';').map((list, idx) => (
            <li key={idx} dangerouslySetInnerHTML={{ __html: list }} />
          ))}
        </ol>
      )}
      {type === 'bullet' && (
        <ul>
          {instructionList.split(';').map((list, idx) => (
            <li key={idx} dangerouslySetInnerHTML={{ __html: list }} />
          ))}
        </ul>
      )}
    </div>
  );
};

InstructionWrap.propTypes = {
  instruction: PropType.string,
  instructionList: PropType.any,
  type: PropType.string,
  i18n: PropType.func,
  rates: PropType.any
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(InstructionWrap);
