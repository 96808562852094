import React from 'react';
import { getDetailForm } from './functions';
import './style.scss';

const ReviewPassportDoc = ({ i18n, data }) => {
  const {
    personalDetail,
    passportDetail,
    visaDetail,
    addressDetail,
    jobDetail,
    marriageDetail,
    fatherDetail,
    motherDetail,
    emergencyContactUS,
    emergencyContactID
  } = getDetailForm(data);

  const renderDetail = (detail) => {
    return detail.map(({ label, value, detailValue }, key) => (
      <div className='row-detail' key={key}>
        <div className='label'>{i18n(`${label}`)}</div>
        <div className='value'>
          <div className='main-value'>{value || '-'}</div>
          {detailValue && (
            <div className='secondary-value'>
              {detailValue.map((detail, idx) => (
                <div className='flex' key={idx}>
                  <div className='label'>{i18n(`${detail.label}`)}</div>
                  <div className='value'>{detail.value || '-'}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className='doc-wrap'>
      <div className='header-top'>
        <div className='title'>{i18n('header.kjri')}</div>
        <div className='address'>5 East 68th Street, New York, NY 10065</div>
        <div className='phone-email'>
          <span className='phone'>Phone: +1 (212) 879-0602</span>
          <span className='email'>Email: consular.newyork@kemlu.go.id</span>
        </div>
      </div>
      <main>
        <div className='title-form'>{i18n('form_personal_data.title')}</div>
        <div className='form-wrap'>
          <div className='block-title'>
            {i18n('form_personal_data.instruction_personal_info')}
          </div>
          <div className='detail-form-wrap'>{renderDetail(personalDetail)}</div>
          <div className='detail-form-wrap'>{renderDetail(passportDetail)}</div>
          <div className='detail-form-wrap'>{renderDetail(visaDetail)}</div>
          <div className='detail-form-wrap'>{renderDetail(addressDetail)}</div>
          <div className='detail-form-wrap'>{renderDetail(jobDetail)}</div>
          <div className='detail-form-wrap'>{renderDetail(marriageDetail)}</div>
        </div>
        <div className='form-wrap'>
          <div className='block-title'>{i18n('form_personal_data.parent_data')}</div>
          <div className='detail-form-wrap'>{renderDetail(fatherDetail)}</div>
          <div className='detail-form-wrap'>{renderDetail(motherDetail)}</div>
        </div>
        <div className='form-wrap'>
          <div className='block-title'>
            {i18n('form_personal_data.emergendy_contact_data')}
          </div>
          <div className='grey-subtitle'>{i18n('form.at_america')}</div>
          <div className='detail-form-wrap'>{renderDetail(emergencyContactUS)}</div>
          <div className='grey-subtitle'>{i18n('form.at_indonesia')}</div>
          <div className='detail-form-wrap'>{renderDetail(emergencyContactID)}</div>
        </div>
      </main>
      <div className='closing-wrap'>
        <div className='text'>{i18n('form_personal_data.review.closing_text')}</div>
        <div className='assign-box'>
          <div className='col1' />
          <div className='col2'>
            <div className='text'>{i18n('form_personal_data.review.place_date')}</div>
          </div>
          <div className='col1'>{i18n('form_personal_data.review.name_assign')}</div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPassportDoc;
