import { combineReducers } from 'redux';

import { newsReducer } from '../containers/pages/Homepage/newsReducer';
import { langReducer } from '../lang/langReducer';
import {
  numberRegisterReducer,
  officeListReducer
} from '../containers/form/NumberRegisterForm/reducer';
import {
  registerReducer,
  citiesIdReducer,
  provinceIDReducer,
  portListReducer,
  countryListReducer,
  submissionDetailReducer,
  passportServiceReducer,
  registrationDetailReducer,
  passportDocReducer,
  registerDocReducer
} from '../containers/form/PassportForm/reducer';
import { trackReducer } from '../containers/pages/Track/reducer';
import {
  scheduleReducer,
  appointmentListReducer,
  bookAppointmentReducer,
  appointmentDetailReducer,
  multiBookAppointmentReducer
} from '../containers/pages/Paspor/Schedule/reducer';
import {
  visaServiceReducer,
  photoReducer,
  visaReducer,
  emailVerificationReducer,
  codeVerificationReducer,
  changeStatusReducer,
  visaSubmitReducer,
  cityIdReducer
} from '../containers/form/VisaForm/reducer';
import { visaApplicantReducer } from '../containers/form/ApplicationInformationForm/reducer';
import {
  sosBudServiceListReducer,
  sosBudLetterReducer,
  sosBudEducationReducer,
  sosBudAlumniReducer,
  sosBudListServeReducer,
  sosBudLetterDetailReducer,
  sosBudSubmissionReducer
} from '../containers/form/PensosbudForm/reducer';
import {
  numberRegisterStatementReducer,
  regisDetailStatementReducer,
  statementServiceReducer
} from '../containers/form/StatementLetterForm/reducer';
import {
  numberRegisterDocLegalReducer,
  regisDetailDocLegalReducer,
  docLegalServiceReducer
} from '../containers/form/DocLegalizationForm/reducer';
import {
  newsReducerAdmin,
  newNewsReducerAdmin,
  newsDetailReducerAdmin,
  deleteNewsReducerAdmin,
  updateNewsReducerAdmin
} from '../containers/admin/News/reducer';
import { uploadPhotoGeneral } from '../components/PhotoUpload/reducer';
import { postAdminLoginReducer } from '../containers/form/AdminLoginForm/reducer';
import { complaintRegisterReducer } from '../containers/form/ComplaintForm/reducer';
import { diasporaRegisterReducer } from '../containers/form/DiasporaForm/reducer';
import { fileReducer } from '../components/input/File/reducer';
import { investmentInquiryReducer } from '../containers/form/EconomyForm/InvestmentInquiry/reducer';
import { tradeInquiryReducer } from '../containers/form/EconomyForm/TradeInquiry/reducer';
import { investmentQuestionReducer } from '../containers/form/EconomyForm/PermohonanInvest/reducer';
import { tradeQuestionReducer } from '../containers/form/EconomyForm/TradeQuestion/reducer';
import { exhibitorReducer } from '../containers/form/EconomyForm/CultureExhibition/reducer';
import { statementReducer } from '../containers/form/EconomyForm/Statement/reducer';
import { tourListReducer, tourDetailReducer } from '../containers/pages/Tourism/reducer';
import { newsListReducer, newsDetailReducer } from '../containers/pages/News/reducer';
import {
  eventListReducer,
  eventDetailReducer,
  eventRegisterReducer
} from '../containers/pages/Event/reducer';
import {
  eventsReducerAdmin,
  newEventReducerAdmin,
  deleteEventReducerAdmin,
  editEventReducerAdmin,
  detailEventReducerAdmin
} from '../containers/admin/Events/reducer';
import {
  usersReducerAdmin,
  newUserReducerAdmin,
  deleteUserReducerAdmin,
  updateUserReducerAdmin
} from '../containers/admin/User/reducer';
import {
  complaintsReducerAdmin,
  complaintDetailReducerAdmin,
  postAnswerComplaintReducerAdmin,
  sendEmailReducer
} from '../containers/admin/Pengaduan/reducer';
import {
  getPassportListReducer,
  getPassportStatisticReducer,
  getPassportStatisticWeekReducer,
  getPassportStatisticMonthReducer,
  getPassportStatisticAllReducer,
  patchApproveReducer,
  patchRejectReducer,
  patchProcessReducer,
  patchPendingReducer
} from '../containers/admin/Passport/reducer';
import {
  getVisaListReducer,
  getVisaStatisticReducer,
  getVisaStatisticWeekReducer,
  getVisaStatisticMonthReducer,
  patchVisaApproveReducer,
  patchVisaPendingReducer,
  patchVisaProcessReducer,
  getVisaDetailReducer,
  getVisaStatisticAllReducer
} from '../containers/admin/Visa/reducer';
import {
  getLetterListReducer,
  getLetterStatisticReducer,
  getLetterStatisticWeekReducer,
  getLetterStatisticMonthReducer,
  getLetterStatisticAllReducer,
  patchLetterApproveReducer,
  patchLetterRejectReducer,
  patchLetterProcessReducer,
  patchLetterPendingReducer
} from '../containers/admin/SuratKeterangan/reducer';
import {
  getDocLegalListReducer,
  getDocLegalStatisticReducer,
  getDocLegalStatisticWeekReducer,
  getDocLegalStatisticMonthReducer,
  getDocLegalStatisticAllReducer,
  patchDocLegalApproveReducer,
  patchDocLegalRejectReducer,
  patchDocLegalProcessReducer,
  patchDocLegalPendingReducer
} from '../containers/admin/DocLegalization/reducer';
import {
  antrianReducerAdmin,
  antrianStatisticDayReducerAdmin,
  antrianStatisticWeekReducerAdmin,
  antrianStatisticMonthReducerAdmin,
  antrianStatisticAntrianReducerAdmin,
  ubahJadwalAntrianReducerAdmin,
  jadwalAntrianReducerAdmin,
  holidayAntrianReducerAdmin,
  exportAntrianReducerAdmin,
  patchAntrianReducerAdmin
} from '../containers/admin/AntrianPaspor/reducer';
import {
  getInvestmentInquiryListReducer,
  getInvestmentInquiryDetailReducer,
  getTradeInquiryListReducer,
  getTradeInquiryDetailReducer,
  getBisnisStatisticReducer,
  getBisnisStatisticWeekReducer,
  getBisnisStatisticMonthReducer
} from '../containers/admin/EkonomiBisnis/BisnisDiIndonesia/reducer';
import {
  getInvestmentQuestionListReducer,
  getInvestmentQuestionDetailReducer,
  getTradeQuestionListReducer,
  getTradeQuestionDetailReducer,
  getHubIndoStatisticReducer,
  getHubIndoStatisticWeekReducer,
  getHubIndoStatisticMonthReducer
} from '../containers/admin/EkonomiBisnis/MenghubungkanIndonesia/reducer';
import {
  getExhibitorListReducer,
  getExhibitorDetailReducer
} from '../containers/admin/EkonomiBisnis/PameranDagang/reducer';
import {
  getExhibitorStatisticReducer,
  getExhibitorStatisticWeekReducer,
  getExhibitorStatisticMonthReducer
} from '../containers/admin/EkonomiBisnis/PameranBudaya/reducer';
import {
  getInvestmentStatementReducer,
  getInvestmentStatmentDetailReducer
} from '../containers/admin/EkonomiBisnis/Other/reducer';
import {
  getSosBudRegisterListReducer,
  getSosBudSubmissionListReducer,
  getSosBudRegisterDetailReducer,
  getSosBudSubmissionDetailReducer,
  patchSosBudApproveReducer,
  patchSosBudRejectReducer,
  patchSosBudProcessReducer,
  patchSosBudPendingReducer,
  getSosbudStatisticReducer,
  getSosbudStatisticWeekReducer,
  getSosbudStatisticMonthReducer,
  getSosbudStatisticAllReducer
} from '../containers/admin/SosBudSK/reducer';
import {
  getDiasporaListReducer,
  getDiasporaDetailReducer,
  getDiasporaStatisticReducer,
  getDiasporaStatisticWeekReducer,
  getDiasporaStatisticMonthReducer
} from '../containers/admin/Diaspora/reducer';
import {
  toursReducerAdmin,
  newTourReducerAdmin,
  deleteTourReducerAdmin,
  editTourReducerAdmin
} from '../containers/admin/Pariwisata/reducer';

export default combineReducers({
  langReducer,
  newsReducer,
  numberRegisterReducer,
  passportServiceReducer,
  registerReducer,
  trackReducer,
  scheduleReducer,
  citiesIdReducer,
  provinceIDReducer,
  portListReducer,
  countryListReducer,
  officeListReducer,
  appointmentListReducer,
  bookAppointmentReducer,
  appointmentDetailReducer,
  visaServiceReducer,
  photoReducer,
  visaReducer,
  emailVerificationReducer,
  codeVerificationReducer,
  changeStatusReducer,
  visaSubmitReducer,
  multiBookAppointmentReducer,
  visaApplicantReducer,
  submissionDetailReducer,
  registrationDetailReducer,
  cityIdReducer,
  sosBudServiceListReducer,
  sosBudLetterReducer,
  sosBudEducationReducer,
  sosBudAlumniReducer,
  sosBudListServeReducer,
  numberRegisterStatementReducer,
  numberRegisterDocLegalReducer,
  regisDetailDocLegalReducer,
  regisDetailStatementReducer,
  docLegalServiceReducer,
  statementServiceReducer,
  newsReducerAdmin,
  newNewsReducerAdmin,
  newsDetailReducerAdmin,
  deleteNewsReducerAdmin,
  updateNewsReducerAdmin,
  uploadPhotoGeneral,
  complaintRegisterReducer,
  diasporaRegisterReducer,
  fileReducer,
  investmentInquiryReducer,
  tradeInquiryReducer,
  investmentQuestionReducer,
  tradeQuestionReducer,
  exhibitorReducer,
  statementReducer,
  sosBudLetterDetailReducer,
  sosBudSubmissionReducer,
  tourListReducer,
  tourDetailReducer,
  newsListReducer,
  newsDetailReducer,
  eventListReducer,
  eventDetailReducer,
  eventRegisterReducer,
  eventsReducerAdmin,
  newEventReducerAdmin,
  deleteEventReducerAdmin,
  editEventReducerAdmin,
  detailEventReducerAdmin,
  postAdminLoginReducer,
  passportDocReducer,
  registerDocReducer,
  usersReducerAdmin,
  newUserReducerAdmin,
  deleteUserReducerAdmin,
  updateUserReducerAdmin,
  getPassportListReducer,
  complaintsReducerAdmin,
  complaintDetailReducerAdmin,
  getPassportStatisticReducer,
  getPassportStatisticWeekReducer,
  getPassportStatisticMonthReducer,
  getPassportStatisticAllReducer,
  patchApproveReducer,
  patchRejectReducer,
  patchProcessReducer,
  patchPendingReducer,
  postAnswerComplaintReducerAdmin,
  getVisaListReducer,
  getVisaStatisticReducer,
  getVisaStatisticWeekReducer,
  getVisaStatisticMonthReducer,
  patchVisaApproveReducer,
  patchVisaPendingReducer,
  patchVisaProcessReducer,
  getVisaDetailReducer,
  getLetterListReducer,
  getLetterStatisticReducer,
  getLetterStatisticWeekReducer,
  getLetterStatisticMonthReducer,
  getLetterStatisticAllReducer,
  patchLetterApproveReducer,
  patchLetterRejectReducer,
  patchLetterProcessReducer,
  patchLetterPendingReducer,
  getVisaStatisticAllReducer,
  getDocLegalListReducer,
  getDocLegalStatisticReducer,
  getDocLegalStatisticWeekReducer,
  getDocLegalStatisticMonthReducer,
  getDocLegalStatisticAllReducer,
  patchDocLegalApproveReducer,
  patchDocLegalRejectReducer,
  patchDocLegalProcessReducer,
  patchDocLegalPendingReducer,
  antrianReducerAdmin,
  antrianStatisticDayReducerAdmin,
  antrianStatisticWeekReducerAdmin,
  antrianStatisticMonthReducerAdmin,
  antrianStatisticAntrianReducerAdmin,
  ubahJadwalAntrianReducerAdmin,
  jadwalAntrianReducerAdmin,
  holidayAntrianReducerAdmin,
  exportAntrianReducerAdmin,
  patchAntrianReducerAdmin,
  getInvestmentInquiryListReducer,
  getInvestmentInquiryDetailReducer,
  getTradeInquiryListReducer,
  getTradeInquiryDetailReducer,
  getInvestmentQuestionListReducer,
  getInvestmentQuestionDetailReducer,
  getTradeQuestionListReducer,
  getTradeQuestionDetailReducer,
  getExhibitorListReducer,
  getExhibitorDetailReducer,
  getInvestmentStatementReducer,
  getInvestmentStatmentDetailReducer,
  getSosBudRegisterListReducer,
  getSosBudSubmissionListReducer,
  getSosBudRegisterDetailReducer,
  getSosBudSubmissionDetailReducer,
  patchSosBudApproveReducer,
  patchSosBudRejectReducer,
  getDiasporaListReducer,
  getDiasporaDetailReducer,
  patchSosBudProcessReducer,
  patchSosBudPendingReducer,
  getSosbudStatisticReducer,
  getSosbudStatisticWeekReducer,
  getSosbudStatisticMonthReducer,
  getSosbudStatisticAllReducer,
  getBisnisStatisticReducer,
  getBisnisStatisticWeekReducer,
  getBisnisStatisticMonthReducer,
  getHubIndoStatisticReducer,
  getHubIndoStatisticWeekReducer,
  getHubIndoStatisticMonthReducer,
  getExhibitorStatisticReducer,
  getExhibitorStatisticWeekReducer,
  getExhibitorStatisticMonthReducer,
  getDiasporaStatisticReducer,
  getDiasporaStatisticWeekReducer,
  getDiasporaStatisticMonthReducer,
  toursReducerAdmin,
  newTourReducerAdmin,
  deleteTourReducerAdmin,
  editTourReducerAdmin,
  sendEmailReducer
});
