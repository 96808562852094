import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';

import { tabDetail as tabDetailStructure } from './functions';

const TabDetail = ({ menuActive, data }) => {
  const tabDetail = tabDetailStructure[menuActive];

  return (
    <div className={'information-detail active'}>
      {tabDetail.map(({ groupTitle, detail }, idx) => (
        <div key={idx}>
          {groupTitle && <div className='title'>{groupTitle}</div>}
          <table>
            {detail.map(({ label, value, type }, idxDetail) => {
              const finalValue = get(data, [...value]) || '-';
              return (
                <tr key={idxDetail}>
                  <td>{label}</td>
                  <td>
                    {type === 'img' ? (
                      <img src={finalValue} alt='' width='800' />
                    ) : type === 'date' ? (
                      moment(finalValue).format('DD MMMM YYYY')
                    ) : (
                      finalValue
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      ))}
    </div>
  );
};

TabDetail.propTypes = {
  menuActive: PropTypes.string,
  data: PropTypes.object
};

export default TabDetail;
