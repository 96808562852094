export const optionsList = (i18n) => {
  const trxOptions = [
    { value: 'new', label: 'Pembuatan passpor baru' },
    { value: 'update', label: 'Perbaharuan passpor' }
  ];

  const genderOptions = [
    { value: 'male', name: i18n('form.gender.male') },
    { value: 'female', name: i18n('form.gender.female') }
  ];

  const marriageOptions = [
    { value: 'single', name: i18n('form.marriage_status.single') },
    { value: 'married', name: i18n('form.marriage_status.married') },
    { value: 'divorced', name: i18n('form.marriage_status.divorced') },
    { value: 'death_divorced', name: i18n('form.marriage_status.death_divorced') }
  ];

  const country_options = [
    { value: 'en', label: 'English' },
    { value: 'id', label: 'Indonesia' }
  ];

  const religion_options = [
    { value: 'moslem', label: i18n('form.religion.moslem') },
    { value: 'christian', label: i18n('form.religion.christian') },
    { value: 'budhis', label: i18n('form.religion.budhis') },
    { value: 'hindu', label: i18n('form.religion.hindu') },
    { value: 'khonghucu', label: i18n('form.religion.khonghucu') },
    { value: 'other', label: i18n('form.religion.other') }
  ];

  return {
    trxOptions,
    genderOptions,
    marriageOptions,
    country_options,
    religion_options
  };
};
