import React, { useEffect } from 'react';
import DashboardTable from '../../../components/DashboardTableOld2';
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayHasValue } from '../../../helpers/helpers';
import './style.scss';
import { getEvents, deleteEvent } from './action';
import Loading from '../../../components/Loading';

const AdminEvents = ({ history, getEvents, eventsList, deleteEvent, deletedEvent }) => {
  useEffect(() => {
    if (!arrayHasValue(eventsList.data)) {
      getEvents();
    }
  }, [getEvents, eventsList.data]);

  const handleDelete = (id) => {
    deleteEvent(id);
  };

  if (deletedEvent.status === 'resolve') {
    window.location.reload();
  };

  const columns = [
    { dataField: '_id', text: 'No' },
    { dataField: 'image_url', text: 'Image' },
    { dataField: 'title', text: 'Title' },
    { dataField: 'place', text: 'Location' },
    { dataField: 'datetime', text: 'Start Date' },
    { dataField: '_id', text: 'Action' }
  ];
  const data = eventsList.data;

  const action = [
    {
      onClick: (id) => history.push(`/admin/events/${id}`, data),
      type: 'view'
    },
    {
      onClick: (id) => history.push(`/admin/events/edit/${id}`, data),
      type: 'edit'
    },
    {
      onClick: (id) => handleDelete(id),
      type: 'delete'
    }
  ];

  return (
    <div className='admin-news'>
      <div className='admin-news-top'>
        <h1>DATA ACARA / PAMERAN</h1>
        <div className='admin-news-action'>
          <Button color='primary' onClick={() => history.push('/admin/events/create')}>
            Tambah Kegiatan
          </Button>
        </div>
      </div>
      {eventsList.status === 'resolve' ? (
        <DashboardTable
          columns={columns}
          data={data}
          action={action}
          search='title'
          idKeyField='_id'
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

AdminEvents.propTypes = {
  history: PropTypes.object,
  eventsList: PropTypes.object,
  getEvents: PropTypes.func,
  deletedEvent: PropTypes.object,
  deleteEvent: PropTypes.func
};

const mapStateToProps = (state) => ({
  eventsList: {
    status: state.eventsReducerAdmin.status,
    error: state.eventsReducerAdmin.error,
    data: state.eventsReducerAdmin.data
  },
  deletedEvent: {
    status: state.deleteEventReducerAdmin.status,
    error: state.deleteEventReducerAdmin.error,
    data: state.deleteEventReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getEvents,
    deleteEvent
  }),
  withRouter
)(AdminEvents);
