import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';
import { Alert } from 'reactstrap';

import { convertArrayToObject } from '../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../helpers/functions';

import Banner from '../../../components/Banner';
import CardDetail from '../../../components/CardDetail';
import TextInput from '../../../components/input/TextInput';
import Dropdown from '../../../components/Dropdown';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';

import { postNumberRegister } from './action';
import { visaType, getQuery } from '../../pages/Visa/function';

import { getOfficeList } from '../NumberRegisterForm/action';

import './style.scss';

const FormVisa = ({
  history,
  i18n,
  numberRegister,
  postNumberRegister,
  getOfficeList,
  offices
}) => {
  const { data, error, status } = numberRegister;
  const defaultForm = {
    answer: '',
    country: '',
    questions: ''
  };
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState({});
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const { type, serviceId } = getQuery(history);
  const titleVisaType = visaType[type];
  const officeOptions = get(offices, ['data']);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      visa_service_id: serviceId,
      office_id: get(form, ['office_id', 'id']),
      name: get(form, ['name']),
      email: get(form, ['email']),
      phone: get(form, ['phone'])
    };

    let schema = yup.object().shape({
      office_id: yup.string().required('required'),
      name: yup.string().required('required'),
      email: yup.string().email('email').required('required'),
      phone: yup.string().required('required')
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        postNumberRegister(params);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      setErrorSave(message);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      const { unique_number } = data;
      history.push(`/visa/registration/review?uniqueId=${unique_number}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!type) {
      history.push('/visa');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    errorAlertScroll();
  }, [errorSave]);

  useEffect(() => {
    getOfficeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {status === 'pending' && <Loading />}
      <Banner
        title={
          titleVisaType &&
          i18n('visa_page.banner.title', { type: i18n(titleVisaType.label) })
        }
        subtitle={
          <div
            className='desc'
            dangerouslySetInnerHTML={{ __html: i18n('visa_page.banner.desc') }}
          />
        }
        backButtonClick={() => history.goBack()}
      />
      <CardDetail
        title={
          titleVisaType &&
          i18n('visa_number_regis.heading1', { type: i18n(titleVisaType.label) })
        }
        className='registration'
      >
        <form onSubmit={handleSubmit}>
          <Alert color='danger' isOpen={visible}>
            {errorSave}
          </Alert>
          <div className='form-wrap'>
            <div
              className='instruction'
              dangerouslySetInnerHTML={{ __html: i18n('visa_number_regis.description') }}
            />
            <TextInput
              label={i18n('form.fullname.label')}
              placeholder={i18n('form.fullname.placeholder')}
              value={form.name}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'name')
              }}
              errorMessage={
                get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
              }
            />
            <TextInput
              label={i18n('form.email.label')}
              placeholder={i18n('form.email.placeholder')}
              value={form.email}
              inputProps={{
                onChange: (e) => handlerChanges(e.target.value, 'email')
              }}
              errorMessage={
                get(errors, ['email']) && i18n(`validation.${get(errors, ['email'])}`)
              }
            />
            <TextInput
              label={i18n('form.phone.label')}
              placeholder={i18n('form.phone.placeholder')}
              value={form.phone}
              inputProps={{
                type: 'number',
                onChange: (e) => handlerChanges(e.target.value, 'phone')
              }}
              errorMessage={
                get(errors, ['phone']) && i18n(`validation.${get(errors, ['phone'])}`)
              }
            />
            <Dropdown
              className='row input-container'
              label={i18n('form.workingArea.label')}
              sublabel={i18n('form.workingArea.sublabel')}
              placeholder={i18n('form.workingArea.placeholder')}
              value={get(form, ['office_id'])}
              options={officeOptions}
              onClick={(item) => {
                handlerChanges(item, 'office_id');
              }}
              isValueObject
              valueKey='id'
              labelKey='name'
              dropdownToggleProps={{
                tag: 'div',
                className: 'input-dropdown'
              }}
              withAutocomplete
              errorMessage={
                get(errors, ['office_id']) &&
                i18n(`validation.${get(errors, ['office_id'])}`)
              }
            />
          </div>
          <div className='btn-wrap'>
            <Button
              color='link'
              type='button'
              onClick={() => history.push('/visa')}
              className='cancel-button'
            >
              {i18n('cta.cancel')}
            </Button>
            <Button color='primary' type='submit'>
              {i18n('nav.continue')}
            </Button>
          </div>
        </form>
      </CardDetail>
    </>
  );
};

FormVisa.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  numberRegister: PropTypes.object,
  postNumberRegister: PropTypes.func,
  getOfficeList: PropTypes.func,
  offices: PropTypes.object
};

const mapStateToProps = (state) => ({
  numberRegister: {
    status: state.numberRegisterReducer.status,
    error: state.numberRegisterReducer.error,
    data: state.numberRegisterReducer.data
  },
  offices: {
    status: state.officeListReducer.status,
    error: state.officeListReducer.error,
    data: state.officeListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { postNumberRegister, getOfficeList }),
  withRouter
)(FormVisa);
