import { apiCaller } from '../../../helpers/apiCaller';

const getLetterListRequest = () => ({
  type: 'GET_LETTER_REQUEST'
});

const getLetterListSuccess = (data) => ({
  type: 'GET_LETTER_SUCCESS',
  payload: data
});

const getLetterListFailure = (error) => ({
  type: 'GET_LETTER_FAILURE',
  payload: error
});

const resetLetterList = () => ({
  type: 'RESET_LETTER_LIST'
});

const getLetterList = (params) => {
  return (dispatch) => {
    dispatch(getLetterListRequest());
    return apiCaller('admin/statement-letter', 'get', undefined, true, params)
      .then((data) => dispatch(getLetterListSuccess(data)))
      .catch((error) => dispatch(getLetterListFailure(error)));
  };
};

// DAY
const getLetterStatisticRequest = () => ({
  type: 'GET_LETTER_STATISTIC_REQUEST'
});

const getLetterStatisticSuccess = (data) => ({
  type: 'GET_LETTER_STATISTIC_SUCCESS',
  payload: data
});

const getLetterStatisticFailure = (error) => ({
  type: 'GET_LETTER_STATISTIC_FAILURE',
  payload: error
});

const getLetterStatistic = (params) => {
  return (dispatch) => {
    dispatch(getLetterStatisticRequest());
    return apiCaller('admin/statement-letter/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getLetterStatisticSuccess(data)))
      .catch((error) => dispatch(getLetterStatisticFailure(error)));
  };
};

// WEEK
const getLetterStatisticWeekRequest = () => ({
  type: 'GET_LETTER_STATISTIC_WEEK_REQUEST'
});

const getLetterStatisticWeekSuccess = (data) => ({
  type: 'GET_LETTER_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getLetterStatisticWeekFailure = (error) => ({
  type: 'GET_LETTER_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getLetterStatisticWeek = (params) => {
  return (dispatch) => {
    dispatch(getLetterStatisticWeekRequest());
    return apiCaller('admin/statement-letter/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getLetterStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getLetterStatisticWeekFailure(error)));
  };
};

// MONTH
const getLetterStatisticMonthRequest = () => ({
  type: 'GET_LETTER_STATISTIC_MONTH_REQUEST'
});

const getLetterStatisticMonthSuccess = (data) => ({
  type: 'GET_LETTER_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getLetterStatisticMonthFailure = (error) => ({
  type: 'GET_LETTER_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getLetterStatisticMonth = (params) => {
  return (dispatch) => {
    dispatch(getLetterStatisticMonthRequest());
    return apiCaller('admin/statement-letter/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getLetterStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getLetterStatisticMonthFailure(error)));
  };
};

// ALL
const getLetterStatisticAllRequest = () => ({
  type: 'GET_LETTER_STATISTIC_ALL_REQUEST'
});

const getLetterStatisticAllSuccess = (data) => ({
  type: 'GET_LETTER_STATISTIC_ALL_SUCCESS',
  payload: data
});

const getLetterStatisticAllFailure = (error) => ({
  type: 'GET_LETTER_STATISTIC_ALL_FAILURE',
  payload: error
});

const getLetterStatisticAll = (params) => {
  return (dispatch) => {
    dispatch(getLetterStatisticAllRequest());
    return apiCaller('admin/statement-letter/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getLetterStatisticAllSuccess(data)))
      .catch((error) => dispatch(getLetterStatisticAllFailure(error)));
  };
};

// APPROVE
const patchApproveRequest = () => ({
  type: 'PATCH_LETTER_APPROVE_REQUEST'
});

const patchApproveSuccess = (data) => ({
  type: 'PATCH_LETTER_APPROVE_SUCCESS',
  payload: data
});

const patchApproveFailure = (error) => ({
  type: 'PATCH_LETTER_APPROVE_FAILURE',
  payload: error
});

const patchApprove = (id) => {
  return (dispatch) => {
    dispatch(patchApproveRequest());
    return apiCaller(`admin/statement-letter/approve/${id}`, 'patch', undefined, true)
      .then((data) => dispatch(patchApproveSuccess(data)))
      .catch((error) => dispatch(patchApproveFailure(error)));
  };
};

// REJECT
const patchRejectRequest = () => ({
  type: 'PATCH_LETTER_REJECT_REQUEST'
});

const patchRejectSuccess = (data) => ({
  type: 'PATCH_LETTER_REJECT_SUCCESS',
  payload: data
});

const patchRejectFailure = (error) => ({
  type: 'PATCH_LETTER_REJECT_FAILURE',
  payload: error
});

const patchReject = (id, params) => {
  return (dispatch) => {
    dispatch(patchRejectRequest());
    return apiCaller(`admin/statement-letter/reject/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchRejectSuccess(data)))
      .catch((error) => dispatch(patchRejectFailure(error)));
  };
};

// PROCESS
const patchProcessRequest = () => ({
  type: 'PATCH_LETTER_PROCESS_REQUEST'
});

const patchProcessSuccess = (data) => ({
  type: 'PATCH_LETTER_PROCESS_SUCCESS',
  payload: data
});

const patchProcessFailure = (error) => ({
  type: 'PATCH_LETTER_PROCESS_FAILURE',
  payload: error
});

const patchProcess = (id) => {
  return (dispatch) => {
    dispatch(patchProcessRequest());
    return apiCaller(`admin/statement-letter/process/${id}`, 'patch', undefined, true)
      .then((data) => dispatch(patchProcessSuccess(data)))
      .catch((error) => dispatch(patchProcessFailure(error)));
  };
};

export {
  resetLetterList,
  getLetterList,
  getLetterStatistic,
  getLetterStatisticWeek,
  getLetterStatisticMonth,
  getLetterStatisticAll,
  patchApprove,
  patchReject,
  patchProcess
};
