export const optionsList = (i18n) => {
  const purposeVisaOptions = [
    { value: 'visit1', label: 'Visit 1' },
    { value: 'visit2', label: 'Visit 2' }
  ];

  const visaTypeOptions = [
    { value: 'SINGLE', name: i18n('form.visa_type_1.single') },
    { value: 'MULTIPLE', name: i18n('form.visa_type_1.multiple') }
  ];

  const cityOptions = [
    { value: 'en', label: 'English' },
    { value: 'id', label: 'Indonesia' }
  ];

  const provinceOptions = [
    { value: 'en', label: 'English' },
    { value: 'id', label: 'Indonesia' }
  ];

  const pointEntryOptions = [
    { value: 'en', label: 'English' },
    { value: 'id', label: 'Indonesia' }
  ];

  const portDepatureOptions = [
    { value: 'en', label: 'English' },
    { value: 'id', label: 'Indonesia' }
  ];

  return {
    purposeVisaOptions,
    visaTypeOptions,
    cityOptions,
    provinceOptions,
    pointEntryOptions,
    portDepatureOptions
  };
};
