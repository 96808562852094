export const aiport = [
  'Bandar Udara Internasional Soekarno–Hatta',
  'Bandar Udara Internasional Ngurah Rai',
  'Bandar Udara Internasional Juanda',
  'Bandar Udara Internasional Sultan Hasanuddin',
  'Bandar Udara Internasional Kualanamu',
  'Bandar Udara Internasional Adisutjipto',
  'Bandar Udara Sultan Aji Muhammad Sulaiman',
  'Bandar Udara Halim Perdanakusuma',
  'Bandar Udara Internasional Hang Nadim',
  'Bandar Udara Internasional Sultan Mahmud Badaruddin II',
  'Bandar Udara Internasional Jenderal Ahmad Yani',
  'Bandar Udara Internasional Husein Sastranegara',
  'Bandar Udara Internasional Supadio',
  'Bandar Udara Internasional Lombok',
  'Bandar Udara Internasional Sultan Syarif Kasim II',
  'Bandar Udara Internasional Minangkabau',
  'Bandar Udara Syamsudin Noor',
  'Bandar Udara Sam Ratulangi',
  'Bandar Udara Radin Inten II',
  'Bandar Udara Internasional Adisumarmo',
  'Bandar Udara Internasional Sentani',
  'Bandar Udara Depati Amir',
  'Bandar Udara El Tari',
  'Bandar Udara Sultan Thaha',
  'Bandar Udara Domine Eduard Osok',
  'Bandar Udara Haluoleo',
  'Bandar Udara Pattimura',
  'Bandar Udara Mutiara',
  'Bandar Udara Abdul Rachman Saleh',
  'Bandar Udara Internasional Sultan Iskandar Muda',
  'Bandar Udara Fatmawati Soekarno',
  'Bandar Udara Internasional H.A.S. Hanandjoeddin',
  'Bandar Udara Internasional Juwata',
  'Bandar Udara Tjilik Riwut',
  'Bandar Udara Sultan Babullah',
  'Bandar Udara Rendani',
  'Bandar Udara Jalaluddin',
  'Bandar Udara Iskandar',
  'Bandar Udara Kalimarau',
  'Bandar Udara Komodo',
  'Bandar Udara H. Asan',
  'Bandar Udara Internasional Mopah',
  'Bandar Udara Rahadi Osman',
  'Bandar Udara Sultan Muhammad Salahuddin',
  'Bandar Udara Internasional Raja Haji Fisabilillah',
  'Bandar Udara Frans Kaisiepo',
  'Bandar Udara Wamena',
  'Bandar Udara Internasional Aji Pangeran Tumenggung Pranoto',
  'Bandar Udara Binaka',
  'Bandar Udara Betoambari',
  'Bandar Udara H. Hasan Aroeboesman',
  'Bandar Udara Mozes Kilangin'
];
