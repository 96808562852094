import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll } from '../../../../../helpers/functions';

import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/input/TextInput';
import RadioButton from '../../../../../components/input/RadioButton';
import Dropdown from '../../../../../components/Dropdown';
import DobInput from '../../../components/Dob';

import { optionsList } from './functions';

const PersonalInfoForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  country_options,
  transactionType
}) => {
  const [form, setForm] = useState({ ...formGlobal.personalInfo });
  const [errors, setErrors] = useState({});
  const { location } = history;
  const { search } = location;

  const { genderOptions, marriageOptions } = optionsList(i18n);

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      birth_city: yup.string().required('required'),
      birth_date: yup.date().required('required'),
      gender: yup.string().required('required'),
      height: yup
        .number()
        .typeError('age harus angka')
        .required('required')
        .integer('number')
        .positive('number'),
      marital_status: yup.string().required('required'),
      name: yup.string().required('required'),
      ...(form.marital_status &&
        form.marital_status === 'married' && {
          spouse: yup.object().shape({
            name: yup.string().required('required'),
            birth_city: yup.string().required('required'),
            birth_date: yup.date().required('required'),
            nationality: yup.object().shape({
              name: yup.string().required('required')
            })
          })
        })
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, personalInfo: form });
        history.push(`/passpor/registration/passpor-visa${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    if (key === 'marital_status') {
      const tmp_form = { ...form };
      if (value !== 'married') {
        tmp_form.spouse = {};
      }
      setForm({ ...tmp_form, [key]: value });
    } else {
      setForm({ ...form, [key]: value });
    }
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  const disableSpouse =
    get(form, ['marital_status']) && get(form, ['marital_status']) !== 'married';

  return (
    <form onSubmit={handlerSubmit} id='personal-info'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_personal_info')}
          </div>
          <TextInput
            label={i18n('form.trx_type.label')}
            placeholder={i18n('form.trx_type.placeholder')}
            value={transactionType}
            disable
          />
          <TextInput
            label={i18n('form.fullname.label')}
            placeholder={i18n('form.fullname.placeholder')}
            value={get(form, ['name'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'name')
            }}
            errorMessage={
              get(errors, ['name']) && i18n(`validation.${get(errors, ['name'])}`)
            }
          />
          <DobInput
            i18n={i18n}
            id='personal'
            place={{
              label: i18n('form.dob_place.label'),
              placeholder: i18n('form.dob_place.placeholder'),
              onChange: (birth_city) => handlerChanges(birth_city, 'birth_city'),
              value: get(form, ['birth_city']),
              errorMessage:
                get(errors, ['birth_city']) &&
                i18n(`validation.${get(errors, ['birth_city'])}`)
            }}
            date={{
              label: i18n('form.dob.label'),
              placeholder: i18n('form.dob.placeholder'),
              datepickerProps: { maxDate: new Date() },
              onChange: (birth_date) => handlerChanges(birth_date, 'birth_date'),
              value: get(form, ['birth_date']),
              errorMessage:
                get(errors, ['birth_date']) &&
                i18n(`validation.${get(errors, ['birth_date'])}`)
            }}
          />
          <RadioButton
            className='gender-type'
            label={i18n('form.gender.label')}
            options={genderOptions}
            value={get(form, ['gender'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'gender')
            }}
            errorMessage={
              get(errors, ['gender']) && i18n(`validation.${get(errors, ['gender'])}`)
            }
          />
          <TextInput
            label={i18n('form.religion.label')}
            placeholder={i18n('form.religion.placeholder')}
            value={get(form, ['religion'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'religion')
            }}
          />
          <TextInput
            label={i18n('form.height.label')}
            placeholder={i18n('form.height.placeholder')}
            textRight='cm'
            value={get(form, ['height'])}
            inputProps={{
              type: 'number',
              onChange: (e) => handlerChanges(e.target.value, 'height')
            }}
            errorMessage={
              get(errors, ['height']) && i18n(`validation.${get(errors, ['height'])}`)
            }
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_personal_status_marriage')}
          </div>

          <RadioButton
            className='marriage_status-type'
            label={i18n('form.marriage_status.label')}
            options={marriageOptions}
            value={get(form, ['marital_status'])}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'marital_status')
            }}
            errorMessage={
              get(errors, ['marital_status']) &&
              i18n(`validation.${get(errors, ['marital_status'])}`)
            }
          />

          <TextInput
            label={i18n('form.spouse_name.label')}
            placeholder={i18n('form.spouse_name.placeholder')}
            value={get(form, ['spouse', 'name'])}
            inputProps={{
              onChange: (e) => {
                setForm({ ...form, spouse: { ...form.spouse, name: e.target.value } });
                setErrors({ ...errors, 'spouse.name': undefined });
              }
            }}
            errorMessage={
              get(errors, ['spouse.name']) &&
              i18n(`validation.${get(errors, ['spouse.name'])}`)
            }
            disable={disableSpouse}
          />

          <DobInput
            i18n={i18n}
            id='spouse'
            place={{
              label: i18n('form.dob_place.label'),
              placeholder: i18n('form.dob_place.placeholder'),
              onChange: (birth_city) => {
                setForm({ ...form, spouse: { ...form.spouse, birth_city } });
                setErrors({ ...errors, 'spouse.birth_city': undefined });
              },
              value: get(form, ['spouse', 'birth_city']),
              errorMessage:
                get(errors, ['spouse.birth_city']) &&
                i18n(`validation.${get(errors, ['spouse.birth_city'])}`)
            }}
            date={{
              label: i18n('form.dob.label'),
              placeholder: i18n('form.dob.placeholder'),
              datepickerProps: { maxDate: new Date() },
              onChange: (birth_date) => {
                setForm({ ...form, spouse: { ...form.spouse, birth_date } });
                setErrors({ ...errors, 'spouse.birth_date': undefined });
              },
              value: get(form, ['spouse', 'birth_date']),
              errorMessage:
                get(errors, ['spouse.birth_date']) &&
                i18n(`validation.${get(errors, ['spouse.birth_date'])}`)
            }}
            disable={disableSpouse}
          />

          <Dropdown
            className='row input-container'
            label={i18n('form.spouse_citizenship.label')}
            sublabel={i18n('form.spouse_citizenship.sublabel')}
            placeholder={i18n('form.spouse_citizenship.placeholder')}
            value={get(form, ['spouse', 'nationality'])}
            options={country_options}
            onClick={(nationality) => {
              setForm({ ...form, spouse: { ...form.spouse, nationality } });
              setErrors({ ...errors, 'spouse.nationality.name': undefined });
            }}
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: `input-dropdown ${disableSpouse ? 'disable' : ''}`,
              disabled: disableSpouse
            }}
            valueKey='name'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['spouse.nationality.name']) &&
              i18n(`validation.${get(errors, ['spouse.nationality.name'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button color='link' type='button' onClick={() => history.push('/passpor')}>
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

PersonalInfoForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object
};

export default withRouter(PersonalInfoForm);
