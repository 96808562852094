import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';
import { getPassportServiceId } from '../function';

const ExpiredPasspor = ({ i18n, passportServiceList }) => {
  const passportServiceId = getPassportServiceId(passportServiceList, 'expired_pass');
  const lang = (type, params) => i18n(`paspor_page.tab1.${type}`, { ...params });

  const stepOuterList = [
    {
      label: i18n('paspor_page.step.report_self.title')
    },
    {
      label: i18n('paspor_page.step.make_appoiment.title')
    },
    {
      label: i18n('paspor_page.step.create_form_register.title'),
      cta: i18n('paspor_page.step.create_form_register.cta'),
      link: `/passpor/schedule?type=expired_pass&serviceId=${passportServiceId}`
    }
  ];

  const instructionList = [
    i18n('paspor_page.instruction_step.original_passpor'),
    i18n('paspor_page.instruction_step.permission_form'),
    i18n('paspor_page.instruction_step.permission_address'),
    i18n('paspor_page.instruction_step.identity'),
    i18n('paspor_page.instruction_step.usps_money_order', { price: 23 })
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

ExpiredPasspor.propTypes = {
  i18n: PropTypes.func,
  passportServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(ExpiredPasspor);
