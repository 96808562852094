import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './style.scss';
import Button from '../../../../components/Button';
import greenCheck from '../../../../assets/img/green-check-mark.svg';

const EventRegistrationSuccess = ({ history, i18n }) => {
  return (
    <Container style={{ paddingTop: '24px' }}>
      <div className='event-registration-success'>
        <span className='back-homepage' onClick={() => history.push('/')}>
          <span className='btn-back' />
          {i18n('cta.back_prev_page')}
        </span>
        <div className='event-registration-success-info-top'>
          <img src={greenCheck} alt='event-registration-success-img' />
          <span>{i18n('event.success.ok')}</span>
          <span>#1231834884</span>
        </div>
        <div className='event-registration-success-info-activity'>
          <span>{i18n('event.success.event')}</span>
          <span>Indonesia Festival in New York</span>
        </div>
        <div className='event-registration-success-info-date'>
          <div>
            <span>{i18n('event.success.date')}</span>
            <span>Wed, 20 Sep 2020 | 08:00 AM</span>
          </div>
          <div>
            <span>{i18n('event.success.send')}</span>
            <span>bambang_baskoro@gmail.com</span>
          </div>
        </div>
        <div className='event-registration-success-bottom'>
          <hr />
          <Button color='primary' onClick={() => history.push('/')}>
            {i18n('cta.back_prev_page')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

EventRegistrationSuccess.propTypes = {
  history: PropTypes.any,
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps, withRouter))(EventRegistrationSuccess);
