import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Form from '../../components/NumberRegister/Form';

import { getQuery } from '../../../pages/Paspor/function';
import {
  postNumberRegisterStatement,
  getStatementServiceList,
  resetNumberRegisterStatement
} from '../action';
import { getServiceTitle, statementType } from '../functions';

const FormPassport = ({
  history,
  i18n,
  postNumberRegisterStatement,
  statementRegis,
  statementService,
  getStatementServiceList,
  resetNumberRegisterStatement
}) => {
  const { data, error, status } = statementRegis;
  const { serviceId, type } = getQuery(history);
  const keyTitle = getServiceTitle(get(statementService, ['data']), serviceId);
  const title =
    keyTitle && statementType[keyTitle] && i18n(`${statementType[keyTitle].label}`);
  let errorSaveMessage = '';

  if (status === 'rejected') {
    const message = get(error, ['response', 'data', 'error', 'message']);
    const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
    const messageDetail = get(error, [
      'response',
      'data',
      'error',
      'detail',
      0,
      'message'
    ]);
    errorSaveMessage = `${message}. ${keyDetail}: ${messageDetail}.`;
  }

  const handleSubmit = (params) => {
    postNumberRegisterStatement({
      ...params,
      letter_service: serviceId
    });
  };

  useEffect(() => {
    if (status === 'resolve') {
      const { unique_number } = data || {};
      resetNumberRegisterStatement();
      history.push(
        `/statement-letter/registration/number-detail?uniqueId=${unique_number}&type=${type}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    getStatementServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      resetNumberRegisterStatement();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      i18n={i18n}
      submit={handleSubmit}
      service_title={title}
      loadingStatus={status}
      errorSaveMessage={errorSaveMessage}
    />
  );
};

FormPassport.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  statementRegis: PropTypes.object,
  postNumberRegisterStatement: PropTypes.func,
  statementService: PropTypes.object,
  getStatementServiceList: PropTypes.func,
  resetNumberRegisterStatement: PropTypes.func
};

const mapStateToProps = (state) => ({
  statementRegis: {
    status: state.numberRegisterStatementReducer.status,
    error: state.numberRegisterStatementReducer.error,
    data: state.numberRegisterStatementReducer.data
  },
  statementService: {
    status: state.statementServiceReducer.status,
    error: state.statementServiceReducer.error,
    data: state.statementServiceReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postNumberRegisterStatement,
    getStatementServiceList,
    resetNumberRegisterStatement
  }),
  withRouter
)(FormPassport);
