import { apiCaller } from '../../../helpers/apiCaller';

const getPassportListRequest = () => ({
  type: 'GET_PASSPORT_REQUEST'
});

const getPassportListSuccess = (data) => ({
  type: 'GET_PASSPORT_SUCCESS',
  payload: data
});

const getPassportListFailure = (error) => ({
  type: 'GET_PASSPORT_FAILURE',
  payload: error
});

const resetPassportList = () => ({
  type: 'RESET_PASSPORT_LIST'
});

const getPassportList = (params) => {
  return (dispatch) => {
    dispatch(getPassportListRequest());
    return apiCaller('admin/passport', 'get', undefined, true, params)
      .then((data) => dispatch(getPassportListSuccess(data)))
      .catch((error) => dispatch(getPassportListFailure(error)));
  };
};

// DAY
const getPassportStatisticRequest = () => ({
  type: 'GET_PASSPORT_STATISTIC_REQUEST'
});

const getPassportStatisticSuccess = (data) => ({
  type: 'GET_PASSPORT_STATISTIC_SUCCESS',
  payload: data
});

const getPassportStatisticFailure = (error) => ({
  type: 'GET_PASSPORT_STATISTIC_FAILURE',
  payload: error
});

const getPassportStatistic = (params) => {
  return (dispatch) => {
    dispatch(getPassportStatisticRequest());
    return apiCaller('admin/passport/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getPassportStatisticSuccess(data)))
      .catch((error) => dispatch(getPassportStatisticFailure(error)));
  };
};

// WEEK
const getPassportStatisticWeekRequest = () => ({
  type: 'GET_PASSPORT_STATISTIC_WEEK_REQUEST'
});

const getPassportStatisticWeekSuccess = (data) => ({
  type: 'GET_PASSPORT_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getPassportStatisticWeekFailure = (error) => ({
  type: 'GET_PASSPORT_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getPassportStatisticWeek = (params) => {
  return (dispatch) => {
    dispatch(getPassportStatisticWeekRequest());
    return apiCaller('admin/passport/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getPassportStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getPassportStatisticWeekFailure(error)));
  };
};

// MONTH
const getPassportStatisticMonthRequest = () => ({
  type: 'GET_PASSPORT_STATISTIC_MONTH_REQUEST'
});

const getPassportStatisticMonthSuccess = (data) => ({
  type: 'GET_PASSPORT_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getPassportStatisticMonthFailure = (error) => ({
  type: 'GET_PASSPORT_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getPassportStatisticMonth = (params) => {
  return (dispatch) => {
    dispatch(getPassportStatisticMonthRequest());
    return apiCaller('admin/passport/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getPassportStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getPassportStatisticMonthFailure(error)));
  };
};

// ALL
const getPassportStatisticAllRequest = () => ({
  type: 'GET_PASSPORT_STATISTIC_ALL_REQUEST'
});

const getPassportStatisticAllSuccess = (data) => ({
  type: 'GET_PASSPORT_STATISTIC_ALL_SUCCESS',
  payload: data
});

const getPassportStatisticAllFailure = (error) => ({
  type: 'GET_PASSPORT_STATISTIC_ALL_FAILURE',
  payload: error
});

const getPassportStatisticAll = (params) => {
  return (dispatch) => {
    dispatch(getPassportStatisticAllRequest());
    return apiCaller('admin/passport/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getPassportStatisticAllSuccess(data)))
      .catch((error) => dispatch(getPassportStatisticAllFailure(error)));
  };
};

// APPROVE
const patchApproveRequest = () => ({
  type: 'PATCH_APPROVE_REQUEST'
});

const patchApproveSuccess = (data) => ({
  type: 'PATCH_APPROVE_SUCCESS',
  payload: data
});

const patchApproveFailure = (error) => ({
  type: 'PATCH_APPROVE_FAILURE',
  payload: error
});

const patchApprove = (id) => {
  return (dispatch) => {
    dispatch(patchApproveRequest());
    return apiCaller(`admin/passport/approve/${id}`, 'patch', undefined, true)
      .then((data) => dispatch(patchApproveSuccess(data)))
      .catch((error) => dispatch(patchApproveFailure(error)));
  };
};

// REJECT
const patchRejectRequest = () => ({
  type: 'PATCH_REJECT_REQUEST'
});

const patchRejectSuccess = (data) => ({
  type: 'PATCH_REJECT_SUCCESS',
  payload: data
});

const patchRejectFailure = (error) => ({
  type: 'PATCH_REJECT_FAILURE',
  payload: error
});

const patchReject = (id, params) => {
  return (dispatch) => {
    dispatch(patchRejectRequest());
    return apiCaller(`admin/passport/reject/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchRejectSuccess(data)))
      .catch((error) => dispatch(patchRejectFailure(error)));
  };
};

// PROCESS
const patchProcessRequest = () => ({
  type: 'PATCH_PROCESS_REQUEST'
});

const patchProcessSuccess = (data) => ({
  type: 'PATCH_PROCESS_SUCCESS',
  payload: data
});

const patchProcessFailure = (error) => ({
  type: 'PATCH_PROCESS_FAILURE',
  payload: error
});

const patchProcess = (id) => {
  return (dispatch) => {
    dispatch(patchProcessRequest());
    return apiCaller(`admin/passport/process/${id}`, 'patch', undefined, true)
      .then((data) => dispatch(patchProcessSuccess(data)))
      .catch((error) => dispatch(patchProcessFailure(error)));
  };
};

// PENDING
const patchPendingRequest = () => ({
  type: 'PATCH_PENDING_REQUEST'
});

const patchPendingSuccess = (data) => ({
  type: 'PATCH_PENDING_SUCCESS',
  payload: data
});

const patchPendingFailure = (error) => ({
  type: 'PATCH_PENDING_FAILURE',
  payload: error
});

const patchPending = (id, params) => {
  return (dispatch) => {
    dispatch(patchPendingRequest());
    return apiCaller(`admin/passport/pending/${id}`, 'patch', params, true)
      .then((data) => dispatch(patchPendingSuccess(data)))
      .catch((error) => dispatch(patchPendingFailure(error)));
  };
};

export {
  getPassportList,
  resetPassportList,
  getPassportStatistic,
  getPassportStatisticWeek,
  getPassportStatisticMonth,
  getPassportStatisticAll,
  patchApprove,
  patchReject,
  patchProcess,
  patchPending
};
