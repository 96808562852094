import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';

import Content from '../components/SingleContent';

import { getInvestmentStatementList } from './action';

const EkonomiLainnya = ({ getInvestmentStatementList, investmentStatementList }) => {
  const title = 'EKONOMI - Lainnya';
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState();
  const [pageDivider, setPageDivider] = useState(5);

  const activeData = investmentStatementList;
  const listData = get(activeData, ['data', 'data', 'data']) || [];
  const pagination = get(activeData, ['data', 'pagination']) || [];
  const loading = get(activeData, ['status']) === 'pending';
  // TODO STATISTIK
  const summaryData = [
    {
      title: 'Data Hari Ini',
      subtitle: title,
      numOfIn: 12,
      numOfProcess: 122,
      numOfFinish: 200
    },
    {
      title: 'Data Minggu Ini',
      subtitle: title,
      numOfIn: 12,
      numOfProcess: 122,
      numOfFinish: 200
    },
    {
      title: 'Data Bulan Ini',
      subtitle: title,
      numOfIn: 12,
      numOfProcess: 122,
      numOfFinish: 200
    }
  ];

  const loadData = (type) => {
    const params = {
      per_page: pageDivider,
      page: page
    };

    if (query && query !== '') {
      params.q = query;
    }

    getInvestmentStatementList(params);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDivider, page, query]);

  return (
    <div className='admin-visa'>
      <Content
        title={title}
        dataTab={listData}
        summaryData={summaryData}
        onPageChanges={setPage}
        onQueryChanges={setQuery}
        onPageDividerChanges={setPageDivider}
        pagination={pagination}
        loading={loading}
        type='ekolain'
      />
    </div>
  );
};

EkonomiLainnya.propTypes = {
  getInvestmentStatementList: PropTypes.func,
  investmentStatementList: PropTypes.object
};

const mapStateToProps = (state) => ({
  investmentStatementList: {
    status: state.getInvestmentStatementReducer.status,
    error: state.getInvestmentStatementReducer.error,
    data: state.getInvestmentStatementReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getInvestmentStatementList
  }),
  withRouter
)(EkonomiLainnya);
