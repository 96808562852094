// import axios from 'axios';
import { apiCaller } from '../../../helpers/apiCaller';
import get from 'lodash/get';

const postAdminLoginRequest = () => ({
  type: 'POST_ADMIN_LOGIN_REQUEST'
});

const postAdminLoginSuccess = (data) => ({
  type: 'POST_ADMIN_LOGIN_SUCCESS',
  payload: data
});

const postAdminLoginFailure = (error) => ({
  type: 'POST_ADMIN_LOGIN_FAILURE',
  payload: error
});

const postAdminLogin = (params) => {
  return (dispatch) => {
    dispatch(postAdminLoginRequest());
    return apiCaller('login', 'post', params)
      .then((data) => {
        if (get(data, ['response', 'data', 'status']) === 422) {
          return dispatch(postAdminLoginFailure(data));
        }

        return dispatch(postAdminLoginSuccess(data));
      })
      .catch((error) => dispatch(postAdminLoginFailure(error)));
  };
};

export { postAdminLogin };
