const visaService = {
  data: [],
  status: 'idle',
  error: ''
};

const visaServiceReducer = (state = visaService, action) => {
  switch (action.type) {
    case 'GET_VISA_SERVICE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_SERVICE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.visaService
      };
    case 'GET_VISA_SERVICE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.visaService.data.data
      };
    default:
      return state;
  }
};

const photo = {
  data: {},
  status: 'idle',
  error: ''
};

const photoReducer = (state = photo, action) => {
  switch (action.type) {
    case 'POST_PHOTO_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_PHOTO_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.photo
      };
    case 'POST_PHOTO_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.photo.data
      };
    case 'RESET_PHOTO_DATA':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const visa = {
  data: [],
  status: 'idle',
  error: ''
};

const visaReducer = (state = visa, action) => {
  switch (action.type) {
    case 'GET_VISA_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_VISA_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.visa
      };
    case 'GET_VISA_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.visa.data
      };
    default:
      return state;
  }
};

const emailVerification = {
  data: [],
  status: 'idle',
  error: ''
};

const emailVerificationReducer = (state = emailVerification, action) => {
  switch (action.type) {
    case 'POST_EMAIL_VERIFICATION_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_EMAIL_VERIFICATION_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.emailVerification
      };
    case 'POST_EMAIL_VERIFICATION_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.emailVerification.data
      };
    case 'RESET_EMAIL_VERIFICATION':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const codeVerification = {
  data: [],
  status: 'idle',
  error: ''
};

const codeVerificationReducer = (state = codeVerification, action) => {
  switch (action.type) {
    case 'POST_CODE_VERIFICATION_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_CODE_VERIFICATION_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.codeVerification
      };
    case 'POST_CODE_VERIFICATION_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.codeVerification.data
      };
    case 'RESET_CODE_VERIFICATION':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

const changeStatus = {
  data: [],
  status: 'idle',
  error: ''
};

const changeStatusReducer = (state = changeStatus, action) => {
  switch (action.type) {
    case 'POST_CHANGE_STATUS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_CHANGE_STATUS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.changeStatus
      };
    case 'POST_CHANGE_STATUS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.changeStatus.data
      };
    default:
      return state;
  }
};

const submitVisa = {
  data: [],
  status: 'idle',
  error: ''
};

const visaSubmitReducer = (state = submitVisa, action) => {
  switch (action.type) {
    case 'POST_SUBMIT_VISA_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_SUBMIT_VISA_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.visaSubmit
      };
    case 'POST_SUBMIT_VISA_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.visaSubmit.data
      };
    default:
      return state;
  }
};

const citiesId = {
  data: [],
  status: 'idle',
  error: ''
};

const cityIdReducer = (state = citiesId, action) => {
  switch (action.type) {
    case 'GET_CITY_ID_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_CITY_ID_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.cities
      };
    case 'GET_CITY_ID_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.cities.data.data
      };
    default:
      return state;
  }
};

export {
  visaServiceReducer,
  photoReducer,
  visaReducer,
  emailVerificationReducer,
  codeVerificationReducer,
  changeStatusReducer,
  visaSubmitReducer,
  cityIdReducer
};
