import React from 'react';
import InstructionWrap from '../../../../components/InstructionWrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ShortVisit = ({ i18n }) => {
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('visa_page.tab1.title')}</div>
      <main>
        <InstructionWrap
          instruction={i18n('visa_page.tab1.step.instruction1')}
          instructionList={i18n('visa_page.instruction_step.purpose')}
          type='number'
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab1.step.instruction2')}
          instructionList={i18n('visa_page.instruction_step.short_free_req')}
          type='number'
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab1.step.instruction3')}
          instructionList=''
          type='country'
        />

        <InstructionWrap
          instruction={i18n('visa_page.tab1.step.instruction4')}
          instructionList=''
          type='checkpoint'
        />
      </main>
    </div>
  );
};

ShortVisit.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(ShortVisit);
