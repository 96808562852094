import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import moment from 'moment';

import DashboardTable from '../../../../components/DashboardTable';
import Button from '../../../../components/Button';
import DatepickerRange from '../../components/DatepickerRange';

import { getSosBudRegisterList, resetSosBudRegisterList } from '../../SosBudSK/action';
import { getSosBudServiceList } from '../../../form/PensosbudForm/action';
import { getSosBudServiceId } from '../../../form/PensosbudForm/functions';
import { downloadFile } from '../../functions';

import './style.scss';

const GamelanBali = ({
  history,
  getSosBudRegisterList,
  getSosBudServiceList,
  sosbudRegisterList,
  sosbudService,
  resetSosBudRegisterList
}) => {
  const title = 'KELAS GAMELAN BALI';
  const date = new Date();
  const last = new Date();
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(last);
  const [page, setPage] = useState(1);
  const [pageDivider, setPageDivider] = useState(5);
  const [query, setQuery] = useState();

  const sosbudServiceList = get(sosbudService, ['data']) || [];
  const sosbudServiceId = getSosBudServiceId(sosbudServiceList, 'gamelan');
  const sosbudListData = get(sosbudRegisterList, ['data', 'data', 'data']) || [];
  const pagination = get(sosbudRegisterList, ['data', 'pagination']) || {};

  const columns = [
    { dataField: 'id', text: 'No' },
    { dataField: 'unique_number', text: '#' },
    { dataField: 'name', text: 'Name' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'birth_date', text: 'Birth Date' },
    { dataField: 'state_id', text: 'State' },
    {
      dataField: 'is_learned',
      text: 'Learned Gamelan',
      customColumn: (data) => {
        if (data.is_learned) {
          return <span style={{ color: '#7EAD26' }}>Yes</span>;
        } else {
          return <span style={{ color: 'red' }}>Not Yet</span>;
        }
      }
    },
    { dataField: 'created_at', text: 'Date' },
    { dataField: 'id', text: 'Action' }
  ];

  const action = [
    {
      onClick: (id) => history.push(`/admin/lokakarya/gamelan-bali/detail/${id}`),
      type: 'view'
    }
  ];

  const loadData = () => {
    const params = {
      service_id: sosbudServiceId,
      per_page: pageDivider,
      page: page,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };

    if (query && query !== '') {
      params.q = query;
    }

    getSosBudRegisterList(params);
  };

  const handlerDownload = () => {
    const params = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
      service_id: sosbudServiceId
    };
    const fileName = `pensosbud-${title}-${params.from}-${params.to}`;
    downloadFile('pensosbud', params, fileName);
  };

  useEffect(() => {
    getSosBudServiceList();
    return () => resetSosBudRegisterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetSosBudRegisterList();

    if (sosbudServiceId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDivider, page, query, sosbudServiceId]);

  return (
    <div className='lokakarya gamelan-bali'>
      <div className='lokakarya-top'>
        <h1>KELAS GAMELAN BALI</h1>
        <div className='lokakarya-action'>
          <DatepickerRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onCalendarClose={loadData}
          />
          <Button color='info' onClick={handlerDownload} style={{ marginLeft: '16px' }}>
            Download
          </Button>
        </div>
      </div>
      <div className='loka-banner'>
        <div className='lb-left'>
          <h3>Kelas Gamelan Bali</h3>
          <p>
            Konsulat Jenderal Indonesia di New York menawarkan Kelas Gamelan Bali gratis
          </p>
        </div>
        <div className='lb-right'>
          <div className='total-pendaftar'>{get(pagination, ['total_data'])}</div>
          <div className='label'>Pendaftar</div>
        </div>
      </div>
      <DashboardTable
        columns={columns}
        action={action}
        search='name'
        idKeyField='id'
        onPageChanges={setPage}
        onPageDividerChanges={setPageDivider}
        onQueryChanges={(q) => {
          setPage(1);
          setQuery(q);
        }}
        data={sosbudListData}
        pagination={pagination}
      />
    </div>
  );
};

GamelanBali.propTypes = {
  history: PropTypes.object,
  getSosBudRegisterList: PropTypes.func,
  getSosBudServiceList: PropTypes.func,
  resetSosBudRegisterList: PropTypes.func,
  sosbudRegisterList: PropTypes.object,
  sosbudService: PropTypes.object
};

const mapStateToProps = (state) => ({
  sosbudRegisterList: {
    status: state.getSosBudRegisterListReducer.status,
    error: state.getSosBudRegisterListReducer.error,
    data: state.getSosBudRegisterListReducer.data
  },
  sosbudService: {
    status: state.sosBudServiceListReducer.status,
    error: state.sosBudServiceListReducer.error,
    data: state.sosBudServiceListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudRegisterList,
    resetSosBudRegisterList,
    getSosBudServiceList
  }),
  withRouter
)(GamelanBali);
