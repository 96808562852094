const defaultData = (isObject) => ({
  data: isObject ? {} : [],
  status: 'idle',
  error: ''
});

const newsListReducer = (state = defaultData(), action) => {
  switch (action.type) {
    case 'GET_NEWS_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NEWS_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.newsList
      };
    case 'GET_NEWS_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.newsList.data.data
      };
    default:
      return state;
  }
};

const newsDetailReducer = (state = defaultData(true), action) => {
  switch (action.type) {
    case 'GET_NEWS_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_NEWS_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.newsDetail
      };
    case 'GET_NEWS_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.newsDetail.data.data
      };
    default:
      return state;
  }
};

export { newsListReducer, newsDetailReducer };
