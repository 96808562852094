import { apiCaller } from '../../../helpers/apiCaller';
import get from 'lodash/get';

// Visa service list
const getVisaServiceRequest = () => ({
  type: 'GET_VISA_SERVICE_REQUEST'
});

const getVisaServiceSuccess = (data) => ({
  type: 'GET_VISA_SERVICE_SUCCESS',
  visaService: data
});

const getVisaServiceFailure = (error) => ({
  type: 'GET_VISA_SERVICE_FAILURE',
  visaService: error
});

const getVisaService = (params = '') => {
  return (dispatch) => {
    dispatch(getVisaServiceRequest());
    return apiCaller(`visa/service${params}`)
      .then((data) => dispatch(getVisaServiceSuccess(data)))
      .catch((error) => dispatch(getVisaServiceFailure(error)));
  };
};

// post image
const postPhotoRequest = () => ({
  type: 'POST_PHOTO_REQUEST'
});

const postPhotoSuccess = (data) => ({
  type: 'POST_PHOTO_SUCCESS',
  photo: data
});

const postPhotoFailure = (error) => ({
  type: 'POST_PHOTO_FAILURE',
  photo: error
});

const postPhoto = (params) => {
  return (dispatch) => {
    dispatch(postPhotoRequest());
    return apiCaller('storages', 'post', params)
      .then((data) => dispatch(postPhotoSuccess(data)))
      .catch((error) => dispatch(postPhotoFailure(error)));
  };
};

// find visa
const getVisaRequest = () => ({
  type: 'GET_VISA_REQUEST'
});

const getVisaSuccess = (data) => ({
  type: 'GET_VISA_SUCCESS',
  visa: data
});

const getVisaFailure = (error) => ({
  type: 'GET_VISA_FAILURE',
  visa: error
});

const getVisa = (params) => {
  return (dispatch) => {
    dispatch(getVisaRequest());
    return apiCaller(`visa/find-submission?${params}`)
      .then((data) => dispatch(getVisaSuccess(data)))
      .catch((error) => dispatch(getVisaFailure(error)));
  };
};

// email verification code
const postEmailVerificationRequest = () => ({
  type: 'POST_EMAIL_VERIFICATION_REQUEST'
});

const postEmailVerificationSuccess = (data) => ({
  type: 'POST_EMAIL_VERIFICATION_SUCCESS',
  emailVerification: data
});

const postEmailVerificationFailure = (error) => ({
  type: 'POST_EMAIL_VERIFICATION_FAILURE',
  emailVerification: error
});

const postEmailVerification = (params) => {
  return (dispatch) => {
    dispatch(postEmailVerificationRequest());
    return apiCaller('visa/send-verification', 'post', params)
      .then((data) => dispatch(postEmailVerificationSuccess(data)))
      .catch((error) => dispatch(postEmailVerificationFailure(error)));
  };
};

// verification code
const postCodeVerificationRequest = () => ({
  type: 'POST_CODE_VERIFICATION_REQUEST'
});

const postCodeVerificationSuccess = (data) => ({
  type: 'POST_CODE_VERIFICATION_SUCCESS',
  codeVerification: data
});

const postCodeVerificationFailure = (error) => ({
  type: 'POST_CODE_VERIFICATION_FAILURE',
  codeVerification: error
});

const postCodeVerification = (params) => {
  return (dispatch) => {
    dispatch(postCodeVerificationRequest());
    return apiCaller('visa/verify', 'post', params)
      .then((data) => dispatch(postCodeVerificationSuccess(data)))
      .catch((error) => dispatch(postCodeVerificationFailure(error)));
  };
};

// change status
const postChangeStatusRequest = () => ({
  type: 'POST_CHANGE_STATUS_REQUEST'
});

const postChangeStatusSuccess = (data) => ({
  type: 'POST_CHANGE_STATUS_SUCCESS',
  changeStatus: data
});

const postChangeStatusFailure = (error) => ({
  type: 'POST_CHANGE_STATUS_FAILURE',
  changeStatus: error
});

const postChangeStatus = (params) => {
  return (dispatch) => {
    dispatch(postChangeStatusRequest());
    return apiCaller(`visa/change-status${params}`, 'post')
      .then((data) => dispatch(postChangeStatusSuccess(data)))
      .catch((error) => dispatch(postChangeStatusFailure(error)));
  };
};

// Submit visa
const postSubmitVisaRequest = () => ({
  type: 'POST_SUBMIT_VISA_REQUEST'
});

const postSubmitVisaSuccess = (data) => ({
  type: 'POST_SUBMIT_VISA_SUCCESS',
  visaSubmit: data
});

const postSubmitVisaFailure = (error) => ({
  type: 'POST_SUBMIT_VISA_FAILURE',
  visaSubmit: error
});

const postSubmitVisa = (id, params) => {
  return (dispatch) => {
    dispatch(postSubmitVisaRequest());
    return apiCaller(`visa/submission${id}`, 'post', params)
      .then((data) => {
        if (get(data, ['response', 'data', 'status']) === 422) {
          return dispatch(postSubmitVisaFailure(data));
        }

        return dispatch(postSubmitVisaSuccess(data));
      })
      .catch((error) => {
        return dispatch(postSubmitVisaFailure(error));
      });
  };
};

// City list
const getCityIdRequest = () => ({
  type: 'GET_CITY_ID_REQUEST'
});

const getCityIdSuccess = (data) => ({
  type: 'GET_CITY_ID_SUCCESS',
  cities: data
});

const getCityIdFailure = (error) => ({
  type: 'GET_CITY_ID_FAILURE',
  cities: error
});

const getCityId = (params = '') => {
  return (dispatch) => {
    dispatch(getCityIdRequest());
    return apiCaller(`cities${params}`)
      .then((data) => dispatch(getCityIdSuccess(data)))
      .catch((error) => dispatch(getCityIdFailure(error)));
  };
};

const defaultData = {
  data: {},
  status: 'idle',
  error: ''
};

const resetPhotoData = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_PHOTO_DATA',
      data: defaultData
    });
  };
};

const resetEmailVerification = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_EMAIL_VERIFICATION',
      data: defaultData
    });
  };
};

const resetCodeVerification = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_CODE_VERIFICATION',
      data: defaultData
    });
  };
};
export {
  getVisaService,
  postPhoto,
  getVisa,
  postEmailVerification,
  postCodeVerification,
  postChangeStatus,
  postSubmitVisa,
  resetPhotoData,
  resetEmailVerification,
  resetCodeVerification,
  getCityId
};
