import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';

import './style.scss';

const MiniCard = ({ detail }) => {
  const {
    title,
    subtitle,
    onClick,
    numOfIn,
    numOfProcess,
    numOfFinish,
    content = {
      numOfIn: 'Masuk',
      numOfProcess: 'Sedang diproses',
      numOfFinish: 'Selesai'
    }
  } = detail || {};
  const [showHover, setShowHover] = useState(false);

  return (
    <div className='mini-card-container'>
      <div className='head'>
        <div className='col1'>
          <div
            className='title'
            onMouseEnter={() => setShowHover(true)}
            onMouseLeave={() => setShowHover(false)}
          >
            {title}
          </div>
          {showHover && <span className='hover-title'>{title}</span>}
          <div className='subtitle'>{subtitle}</div>
        </div>
        {onClick && <Icon icon='chevron' onClick={() => onClick()} />}
      </div>
      <div className='detail-container'>
        {numOfIn && (
          <div className='col1'>
            <div className='count'>{numOfIn}</div>
            <div className='unit'>{content.numOfIn}</div>
          </div>
        )}
        <div className='col1'>
          {numOfProcess && (
            <div className='row'>
              <div className='count green'>{numOfProcess}</div>
              <div className='status'>{content.numOfProcess}</div>
            </div>
          )}
          {numOfFinish && (
            <div className='row'>
              <div className='count blue'>{numOfFinish}</div>
              <div className='status'>{content.numOfFinish}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MiniCard.propTypes = {
  detail: PropTypes.object,
  i18n: PropTypes.func
};

export default MiniCard;
