export const optionsList = (i18n) => {
  const passportTypes = [
    { value: 'DIPLOMATIC', name: i18n('form.type_of_passport.diplomatic') },
    { value: 'SERVICE', name: i18n('form.type_of_passport.service') },
    { value: 'UNLP', name: i18n('form.type_of_passport.unlp') },
    { value: 'REGULAR', name: i18n('form.type_of_passport.other') }
  ];

  const country_options = [
    { value: 'en', label: 'English' },
    { value: 'id', label: 'Indonesia' }
  ];

  return {
    passportTypes,
    country_options
  };
};
