const getLang = (lang) => ({
  type: 'GET_LANG',
  lang
});

const setLang = (lang) => {
  return (dispatch) => {
    dispatch(getLang(lang));
  };
};

export { setLang };
