const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getInvestmentInquiryListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_INVESTMENT_INQUIRY_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INVESTMENT_INQUIRY_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INVESTMENT_INQUIRY_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_INVESTMENT_INQUIRY_LIST_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getInvestmentInquiryDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_INVESTMENT_INQUIRY_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INVESTMENT_INQUIRY_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INVESTMENT_INQUIRY_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_INVESTMENT_INQUIRY_DETAIL_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getTradeInquiryListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_TRADE_INQUIRY_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TRADE_INQUIRY_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_TRADE_INQUIRY_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_TRADE_INQUIRY_LIST_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getTradeInquiryDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_TRADE_INQUIRY_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_TRADE_INQUIRY_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_TRADE_INQUIRY_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_TRADE_INQUIRY_DETAIL_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getBisnisStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_BISNIS_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_BISNIS_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_BISNIS_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getBisnisStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_BISNIS_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_BISNIS_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_BISNIS_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getBisnisStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_BISNIS_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_BISNIS_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_BISNIS_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

export {
  getInvestmentInquiryListReducer,
  getInvestmentInquiryDetailReducer,
  getTradeInquiryListReducer,
  getTradeInquiryDetailReducer,
  getBisnisStatisticReducer,
  getBisnisStatisticWeekReducer,
  getBisnisStatisticMonthReducer
};
