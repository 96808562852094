import { apiCaller } from '../../../helpers/apiCaller';

const getRegisterRequest = () => ({
  type: 'GET_REGISTER_REQUEST'
});

const getRegisterSuccess = (data) => ({
  type: 'GET_REGISTER_SUCCESS',
  payload: data
});

const getRegisterFailure = (error) => ({
  type: 'GET_REGISTER_FAILURE',
  payload: error
});

const postRegisterDetail = (params) => {
  return (dispatch) => {
    dispatch(getRegisterRequest());
    return apiCaller('passport/registration', 'post', params)
      .then((data) => dispatch(getRegisterSuccess(data)))
      .catch((error) => dispatch(getRegisterFailure(error)));
  };
};

// CITIES
const getCitiesIDRequest = () => ({
  type: 'GET_CITIES_ID_REQUEST'
});

const getCitiesIDSuccess = (data) => ({
  type: 'GET_CITIES_ID_SUCCESS',
  cities: data
});

const getCitiesIDFailure = (error) => ({
  type: 'GET_CITIES_ID_FAILURE',
  cities: error
});

const getCitiesIDList = (params = '') => {
  return (dispatch) => {
    dispatch(getCitiesIDRequest());
    return apiCaller(`cities${params}`)
      .then((data) => dispatch(getCitiesIDSuccess(data.data)))
      .catch((error) => dispatch(getCitiesIDFailure(error)));
  };
};

// PROVINCE
const getProvinceIDRequest = () => ({
  type: 'GET_PROVINCE_ID_REQUEST'
});

const getProvinceIDSuccess = (data) => ({
  type: 'GET_PROVINCE_ID_SUCCESS',
  province: data
});

const getProvinceIDFailure = (error) => ({
  type: 'GET_PROVINCE_ID_FAILURE',
  province: error
});

const getProvinceIDList = (params = '') => {
  return (dispatch) => {
    dispatch(getProvinceIDRequest());
    return apiCaller(`provinces${params}`)
      .then((data) => dispatch(getProvinceIDSuccess(data.data)))
      .catch((error) => dispatch(getProvinceIDFailure(error)));
  };
};

// portList
const getPortRequest = () => ({
  type: 'GET_PORT_LIST_REQUEST'
});

const getPortSuccess = (data) => {
  const list = [...data.data];
  const tmpList = [];

  if (list.length > 0) {
    list.map((item) => {
      tmpList.push({
        ...item,
        label: `${item.name} (${item.province}/${item.city})`
      });
    });
  }

  return {
    type: 'GET_PORT_LIST_SUCCESS',
    port: { ...data, data: tmpList }
  };
};

const getPortFailure = (error) => ({
  type: 'GET_PORT_LIST_FAILURE',
  port: error
});

const getPortList = (params = '') => {
  return (dispatch) => {
    dispatch(getPortRequest());
    return apiCaller(`ports${params}`)
      .then((data) => dispatch(getPortSuccess(data.data)))
      .catch((error) => dispatch(getPortFailure(error)));
  };
};

// countryList
const getCountryRequest = () => ({
  type: 'GET_COUNTRY_LIST_REQUEST'
});

const getCountrySuccess = (data) => {
  return {
    type: 'GET_COUNTRY_LIST_SUCCESS',
    country: data
  };
};

const getCountryFailure = (error) => ({
  type: 'GET_COUNTRY_LIST_FAILURE',
  country: error
});

const getCountryList = (params = '') => {
  return (dispatch) => {
    dispatch(getCountryRequest());
    return apiCaller(`country${params}`)
      .then((data) => dispatch(getCountrySuccess(data.data)))
      .catch((error) => dispatch(getCountryFailure(error)));
  };
};

// detail submission
const getSubmissionDetailRequest = () => ({
  type: 'GET_SUBMISSION_DETAIL_REQUEST'
});

const getSubmissionDetailSuccess = (data) => {
  return {
    type: 'GET_SUBMISSION_DETAIL_SUCCESS',
    submission: data
  };
};

const getSubmissionDetailFailure = (error) => ({
  type: 'GET_SUBMISSION_DETAIL_FAILURE',
  submission: error
});

const getSubmissionDetail = (params = '') => {
  return (dispatch) => {
    dispatch(getSubmissionDetailRequest());
    return apiCaller(`passport/submission${params}`)
      .then((data) => dispatch(getSubmissionDetailSuccess(data.data)))
      .catch((error) => dispatch(getSubmissionDetailFailure(error)));
  };
};

// Passport service
const getPassportServiceListRequest = () => ({
  type: 'GET_PASSPORT_SERVICE_REQUEST'
});

const getPassportServiceListSuccess = (data) => ({
  type: 'GET_PASSPORT_SERVICE_SUCCESS',
  passportService: data
});

const getPassportServiceListFailure = (error) => ({
  type: 'GET_PASSPORT_SERVICE_FAILURE',
  passportService: error
});

const getPassportServiceList = () => {
  return (dispatch) => {
    dispatch(getPassportServiceListRequest());
    return apiCaller('passport/services')
      .then((data) => dispatch(getPassportServiceListSuccess(data)))
      .catch((error) => dispatch(getPassportServiceListFailure(error)));
  };
};

// registration detail
const getRegistrationDetailRequest = () => ({
  type: 'GET_REGISTRATION_DETAIL_REQUEST'
});

const getRegistrationDetailSuccess = (data) => ({
  type: 'GET_REGISTRATION_DETAIL_SUCCESS',
  registrationDetail: data
});

const getRegistrationDetailFailure = (error) => ({
  type: 'GET_REGISTRATION_DETAIL_FAILURE',
  registrationDetail: error
});

const getRegistrationDetail = (params) => {
  return (dispatch) => {
    dispatch(getRegistrationDetailRequest());
    return apiCaller(`passport/registration${params}`)
      .then((data) => dispatch(getRegistrationDetailSuccess(data)))
      .catch((error) => dispatch(getRegistrationDetailFailure(error)));
  };
};

// File Passport service
const getPassportDocListRequest = () => ({
  type: 'GET_PASSPORT_DOC_REQUEST'
});

const getPassportDocListSuccess = (data) => ({
  type: 'GET_PASSPORT_DOC_SUCCESS',
  passportDoc: data
});

const getPassportDocListFailure = (error) => ({
  type: 'GET_PASSPORT_DOC_FAILURE',
  passportDoc: error
});

const getPassportDocList = (serviceId) => {
  return (dispatch) => {
    dispatch(getPassportDocListRequest());
    return apiCaller(`passport/services/docs/${serviceId}`)
      .then((data) => dispatch(getPassportDocListSuccess(data)))
      .catch((error) => dispatch(getPassportDocListFailure(error)));
  };
};

// POST FILE PASSPORT
const putRegisterRequest = () => ({
  type: 'PUT_REGISTER_REQUEST'
});

const putRegisterSuccess = (data) => ({
  type: 'PUT_REGISTER_SUCCESS',
  payload: data
});

const putRegisterFailure = (error) => ({
  type: 'PUT_REGISTER_FAILURE',
  payload: error
});

const resetPutRegister = () => ({
  type: 'RESET_PUT_REGISTER'
});

const putRegisterDetail = (params) => {
  return (dispatch) => {
    dispatch(putRegisterRequest());
    return apiCaller('passport/registration', 'put', params)
      .then((data) => dispatch(putRegisterSuccess(data)))
      .catch((error) => dispatch(putRegisterFailure(error)));
  };
};

export {
  postRegisterDetail,
  getCitiesIDList,
  getProvinceIDList,
  getPortList,
  getCountryList,
  getSubmissionDetail,
  getPassportServiceList,
  getRegistrationDetail,
  getPassportDocList,
  putRegisterDetail,
  resetPutRegister
};
