import { apiCaller } from '../../../../helpers/apiCaller';

// Number regis passport
const getInvestmentInquiryRequest = () => ({
  type: 'GET_INVESTMENT_INQUIRY_REQUEST'
});

const getInvestmentInquirySuccess = (data) => ({
  type: 'GET_INVESTMENT_INQUIRY_SUCCESS',
  payload: data
});

const getInvestmentInquiryFailure = (error) => ({
  type: 'GET_INVESTMENT_INQUIRY_FAILURE',
  payload: error
});

const postInvestmentInquiry = (params) => {
  return (dispatch) => {
    dispatch(getInvestmentInquiryRequest());
    return apiCaller('investment/inquiry', 'post', params)
      .then((data) => dispatch(getInvestmentInquirySuccess(data)))
      .catch((error) => dispatch(getInvestmentInquiryFailure(error)));
  };
};

const resetPostData = () => {
  const defaultData = {
    data: [],
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_POST_DATA',
      data: defaultData
    });
  };
};

export { postInvestmentInquiry, resetPostData };
