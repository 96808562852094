import get from 'lodash/get';
import { parseQuery } from '../../../helpers/functions';
import visaServiceKey from '../../../constant/visaService';

export const visaType = {
  bussiness_multiple: {
    label: 'visa_page.tab6.title',
    key: 'Business Visa - Multiple Entry'
  },
  bussiness_single: {
    label: 'visa_page.tab5.title',
    key: 'Business Visa - Single Entry'
  },
  limited_stay: {
    label: 'visa_page.tab7.title',
    key: 'Limited Stay Visa'
  },
  socio_culture: {
    label: 'visa_page.tab4.title',
    key: 'Socio-Cultural Visa'
  },
  tourist: {
    label: 'visa_page.tab3.title',
    key: 'Tourist Visa'
  },
  diplomatic: {
    label: 'visa_page.tab8.title',
    key: 'Diplomatic / Service Visa'
  },
  journalist: {
    label: 'visa_page.tab10.title',
    key: 'Journalist Visa'
  }
};

export const getVisaServiceId = (list, key) => {
  const visaServiceSelected = list.find(({ title }) => title === visaServiceKey[key]);

  return get(visaServiceSelected, ['_id']);
};

export const getQuery = (history) => {
  const { location } = history;
  const { search } = location;
  const searchQuery = parseQuery(search) || {};

  return searchQuery;
};
