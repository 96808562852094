import axios from 'axios';
import get from 'lodash/get';

const baseUrl = process.env.REACT_APP_BACKEND_URI;
const authData = {
  username: 'myapp',
  password: 'jakarta2020'
};

const backToLoginMessage = ['Invalid token'];

const noPermissionMessage = ['Forbidden'];

export const apiCaller = (
  endpoint,
  method = 'get',
  body,
  isAdmin = false,
  params,
  otherProps = {}
) => {
  const authToken = {
    headers: {
      'x-auth': localStorage.getItem('accessToken')
    }
  };

  return axios({
    method,
    url: `${baseUrl}/${endpoint}`,
    auth: authData,
    ...(body ? { data: body } : {}),
    ...(params ? { params } : {}),
    ...(isAdmin ? authToken : {}),
    ...otherProps
  }).catch((err) => {
    const errMessage = get(err, ['response', 'data', 'error', 'message']);
    const checkLogin = backToLoginMessage.find((message) => message === errMessage);
    const checkNoPermission = noPermissionMessage.find(
      (message) => message === errMessage
    );

    if (checkLogin) {
      localStorage.removeItem('accessToken');
      localStorage.setItem('INVALID_TOKEN', 'show message');
      window.location.href = '/admin/login';
    } else if (checkNoPermission) {
      window.location.href = '/admin/forbidden';
    } else {
      return err;
    }
  });
};
