import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

const TextArea = ({ i18n, setValue, initialValue, trueValue, readOnly = false }) => {
  const setAnswer = (value) => {
    setValue(value);
  };

  const renderTextArea = (readOnly) => {
    if(readOnly){
      return <textarea
        placeholder='Masukkan Balasan Anda'
        rows='4'
        value={trueValue === '' ? initialValue : trueValue}
        onChange={(e) => setAnswer(e.target.value)}
        readOnly={readOnly}
        style={{color: 'grey'}}
      />
    }else{
      return <textarea
        placeholder='Masukkan Balasan Anda'
        rows='4'
        value={trueValue === '' ? initialValue : trueValue}
        onChange={(e) => setAnswer(e.target.value)}
        readOnly={readOnly}
      />
    }
  }

  return (
    <div className='textarea-wrap'>
      <div className='row-name'>Balasan</div>
      <div className='textarea-input'>
        {renderTextArea(readOnly)}
      </div>
    </div>
  );
};

TextArea.propTypes = {
  i18n: PropTypes.func,
  setValue: PropTypes.func,
  initialValue: PropTypes.string,
  trueValue: PropTypes.string
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(TextArea);
