import { apiCaller } from '../../../../helpers/apiCaller';

// Number regis passport
const getInvestmentQuestionRequest = () => ({
  type: 'GET_INVESTMENT_QUESTION_REQUEST'
});

const getInvestmentQuestionSuccess = (data) => ({
  type: 'GET_INVESTMENT_QUESTION_SUCCESS',
  payload: data
});

const getInvestmentQuestionFailure = (error) => ({
  type: 'GET_INVESTMENT_QUESTION_FAILURE',
  payload: error
});

const postInvestmentQuestion = (params) => {
  return (dispatch) => {
    dispatch(getInvestmentQuestionRequest());
    return apiCaller('investment/question', 'post', params)
      .then((data) => dispatch(getInvestmentQuestionSuccess(data)))
      .catch((error) => dispatch(getInvestmentQuestionFailure(error)));
  };
};

const resetPostData = () => {
  const defaultData = {
    data: [],
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_POST_DATA',
      data: defaultData
    });
  };
};

export { postInvestmentQuestion, resetPostData };
