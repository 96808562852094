import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Schollarship = ({ i18n }) => {
  const lang = (type, params) => i18n(`pensosbud.tab8.${type}`, { ...params });

  const scheduleList = [
    lang('schedule.online_regis', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.interview', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.select_univer', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.decision', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.attendance', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.final', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.fliht_itin', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.arrival', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.orientation', { date: '2 January 2020 until 2 March 2020' }),
    lang('schedule.schedule_change', { date: '2 January 2020 until 2 March 2020' })
  ];

  const qualificationList = [
    lang('qualification.us_nationality'),
    lang('qualification.completed_education'),
    lang('qualification.min_age'),
    lang('qualification.comunication'),
    lang('qualification.good_healt'),
    lang('qualification.keep_interest'),
    lang('qualification.basic_knowledge'),
    lang('qualification.not_alumni'),
    lang('qualification.not_study_indo'),
    lang('qualification.not_work_indo')
  ];

  const syaratList = [
    lang('syarat.cv'),
    lang('syarat.medical'),
    lang('syarat.high_scholl'),
    lang('syarat.passport_valid'),
    lang('syarat.language_certificate'),
    lang('syarat.certificates'),
    lang('syarat.recomend_letter'),
    lang('syarat.essay'),
    lang('syarat.video')
  ];

  const qualification2aList = [
    lang('qualification2a.monthly_stipend'),
    lang('qualification2a.accomodation'),
    lang('qualification2a.settlement_allowance'),
    lang('qualification2a.legal_permit'),
    lang('qualification2a.seek_assistance')
  ];

  const qualification2bList = [
    lang('qualification2b.sign_statement'),
    lang('qualification2b.req_inform'),
    lang('qualification2b.responsible'),
    lang('qualification2b.follow_until_end'),
    lang('qualification2b.write_final_report')
  ];

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('schedule.title')}</b>
          </div>
          <ol>
            {scheduleList.map((item, key) => (
              <li key={key}>
                <div dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('procedures.title')}</b>
          </div>
          <div dangerouslySetInnerHTML={{ __html: lang('procedures.prospective') }} />
          <div className='mtop16'>{lang('procedures.not_allowed')}</div>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('qualification.title')}</b>
          </div>
          <ol>
            {qualificationList.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('syarat.title')}</b>
          </div>
          <ol>
            {syaratList.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('qualification2a.title')}</b>
          </div>
          <ol>
            {qualification2aList.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('qualification2b.title')}</b>
          </div>
          <ol>
            {qualification2bList.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div>
            <b>{lang('places.title')}</b>
          </div>
          <div dangerouslySetInnerHTML={{ __html: lang('places.url') }} />
          <div
            className='mtop16'
            dangerouslySetInnerHTML={{ __html: lang('places.more_info') }}
          />
          <div
            className='mtop16 red'
            dangerouslySetInnerHTML={{ __html: lang('places.announcement') }}
          />
        </div>
      </main>
    </div>
  );
};

Schollarship.propTypes = {
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(Schollarship);
