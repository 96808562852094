import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';

import { getSosBudServiceId } from '../../../form/PensosbudForm/functions';

const LokakaryaGamelan = ({ i18n, history, sosBudServiceList }) => {
  const lang = (type, params) => i18n(`pensosbud.tab7.${type}`, { ...params });
  const serviceId = getSosBudServiceId(sosBudServiceList, 'gamelan');

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div className='intro'>
            <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />
            <Button
              color='primary'
              className='mtop16'
              onClick={() =>
                history.push(
                  `/pensosbud/courses/form?type=gamelan&serviceId=${serviceId}`
                )
              }
            >
              {lang('step.cta')}
            </Button>
          </div>

          <div className='more mtop16'>{lang('more_question')}</div>
          <div
            className='mtop16'
            dangerouslySetInnerHTML={{ __html: lang('address_office') }}
          />
          <div
            className='mtop16 announcement red'
            dangerouslySetInnerHTML={{ __html: lang('announcement') }}
          />
        </div>
      </main>
    </div>
  );
};

LokakaryaGamelan.propTypes = {
  i18n: PropTypes.func,
  history: PropTypes.object,
  sosBudServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(LokakaryaGamelan);
