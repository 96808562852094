const users = {
  data: {},
  status: 'idle',
  error: ''
};

const newUser = {
  data: {},
  status: 'idle',
  error: ''
};

const deleteUser = {
  data: {},
  status: 'idle',
  error: ''
};

const updateUser = {
  data: {},
  status: 'idle',
  error: ''
};

const usersReducerAdmin = (state = users, action) => {
  switch (action.type) {
    case 'GET_USERS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_USERS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const newUserReducerAdmin = (state = newUser, action) => {
  switch (action.type) {
    case 'POST_USER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_USER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_USER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const deleteUserReducerAdmin = (state = deleteUser, action) => {
  switch (action.type) {
    case 'DELETE_USER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_USER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const updateUserReducerAdmin = (state = updateUser, action) => {
  switch (action.type) {
    case 'UPDATE_USER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'UPDATE_USER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    default:
      return state;
  }
};

export {
  usersReducerAdmin,
  newUserReducerAdmin,
  deleteUserReducerAdmin,
  updateUserReducerAdmin
};
