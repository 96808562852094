const defaultData = {
  data: [],
  status: 'idle',
  error: ''
};

const investmentInquiryReducer = (state = defaultData, action) => {
  switch (action.type) {
    case 'GET_INVESTMENT_INQUIRY_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INVESTMENT_INQUIRY_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INVESTMENT_INQUIRY_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_DATA':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export { investmentInquiryReducer };
