import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';

import { getDocLegalServiceId } from '../../../form/DocLegalizationForm/functions';

const PowerLetter = ({ i18n, docLegalServiceList }) => {
  const lang = (type, params) => i18n(`doc_legalization.tab1.${type}`, { ...params });
  const serviceId = getDocLegalServiceId(docLegalServiceList, 'power');

  const stepOuterList = [
    {
      label: i18n('doc_legalization.step.report_self.title')
    },
    {
      label: i18n('doc_legalization.step.take_number.title'),
      cta: i18n('doc_legalization.step.take_number.cta'),
      link: `/doc-legalization/registration/create?type=docLegal&serviceId=${serviceId}`
    }
  ];

  const instructionList = [
    i18n('doc_legalization.instruction_step.original_passport_legalization'),
    i18n('doc_legalization.instruction_step.copy_resident'),
    i18n('doc_legalization.instruction_step.copy_id'),
    {
      text: i18n('doc_legalization.instruction_step.letter_statement'),
      detail: (
        <ol className='lower-roman'>
          <li
            dangerouslySetInnerHTML={{
              __html: i18n('doc_legalization.instruction_step.passport_number')
            }}
          />
          <li
            dangerouslySetInnerHTML={{
              __html: i18n('doc_legalization.instruction_step.address_us')
            }}
          />
          <li
            dangerouslySetInnerHTML={{
              __html: i18n('doc_legalization.instruction_step.name_applicant')
            }}
          />
          <li
            dangerouslySetInnerHTML={{
              __html: i18n('doc_legalization.instruction_step.if_stamp')
            }}
          />
        </ol>
      )
    },
    i18n('doc_legalization.instruction_step.for_us'),
    i18n('doc_legalization.instruction_step.for_id')
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

PowerLetter.propTypes = {
  i18n: PropTypes.func,
  docLegalServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(PowerLetter);
