const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getExhibitorListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_EXHIBITOR_LIST_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getExhibitorDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_EXHIBITOR_DETAIL_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getExhibitorStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getExhibitorStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getExhibitorStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

export {
  getExhibitorListReducer,
  getExhibitorDetailReducer,
  getExhibitorStatisticReducer,
  getExhibitorStatisticWeekReducer,
  getExhibitorStatisticMonthReducer
};
