import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import './style.scss';

const TextAreaEditor = ({ label, value, action }) => {
  const handleEditorChange = (content, editor) => {
    action(content);
  };

  return (
    <div className='text-area-editor'>
      <div className='label'>{label}</div>
      <Editor
        className='editor-area'
        initialValue={value}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
        }}
        onEditorChange={handleEditorChange}
      />
    </div>
  );
};

TextAreaEditor.propTypes = {
  mandatory: PropTypes.bool,
  label: PropTypes.string,
  action: PropTypes.any,
  value: PropTypes.any
};

export default TextAreaEditor;
