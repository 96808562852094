import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const ImageView = ({ url, history }) => {
  return (
    <span className='image-view'>
      <img src={url} alt='event-img' style={{ height: 25, paddingRight: 8 }} />
    </span>
  );
};

ImageView.propTypes = {
  url: PropTypes.string,
  history: PropTypes.object
};

export default withRouter(ImageView);
