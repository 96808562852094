import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Icon from '../Icon';
import './style.scss';

const FormMenu = ({ history, i18n, lastFormIdx, stepList, activePath }) => {
  const renderMenu = () => {
    return stepList.map((step, idx) => {
      const lastFormIndex = lastFormIdx + 1;
      const isPassed = lastFormIndex >= 0 && lastFormIndex >= parseInt(idx);
      const isActive = activePath === step.link;

      return (
        <div
          className={`step-item 
          ${isActive ? 'active' : ''} 
          ${isPassed ? 'passed' : ''}`}
          key={idx}
          onClick={() => (!isActive && isPassed ? history.push(step.link) : {})}
        >
          <span className='text'>{i18n(step.label)}</span> <Icon icon='chevron' />
        </div>
      );
    });
  };

  return <div className='menu-form-container'>{renderMenu()}</div>;
};

FormMenu.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func
};

export default withRouter(FormMenu);
