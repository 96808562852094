import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import Close from '../../../../assets/img/close_24px.svg';
import Check from '../../../../assets/img/check_24px.svg';

import { typeModalList } from '../../functions';

import './style.scss';

import sosbudService from '../../../../constant/sosBudService';
import {
  getSosBudSubmissionDetail,
  patchApprove,
  patchReject,
  patchProcess,
  patchPending
} from '../action';
import { graduateDetails, transferScholldetails, pnsdetails } from './functions';

const DetailSosbudSuratKeterangan = ({
  history,
  submissionDetail,
  approve,
  reject,
  getSosBudSubmissionDetail,
  patchApprove,
  patchReject,
  process,
  pending,
  patchProcess,
  patchPending
}) => {
  const { type, id } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const [selectedData, setSelectedData] = useState({});
  const [reason, setReason] = useState();
  const [loadingAction, setLoadingAction] = useState(false);
  const [actionData, setActionData] = useState();

  const detail = get(submissionDetail, ['data', 'data', 0]);
  const loadingDetail = get(submissionDetail, ['status']) === 'pending';
  const status = get(detail, ['status']) || 'REGISTERED';
  // action
  const { status: statusApprove, data: dataApprove, error: errorApprove } = approve;
  const { status: statusProcess, data: dataProcess, error: errorProcess } = process;
  const { status: statusPending, data: dataPending, error: errorPending } = pending;
  const { status: statusReject, data: dataReject, error: errorReject } = reject;

  const uniqueId = get(detail, ['unique_number']);
  const personalData = {
    name:
      type === 'transferSchool' ? get(detail, ['child', 'name']) : get(detail, ['name']),
    email: get(detail, ['email']),
    phone: get(detail, ['phone']),
    unique_id: get(detail, ['unique_number']),
    bookingTime: get(detail, ['created_at'])
  };
  const handlerModal = (type, data) => {
    if (showModal) {
      setTypeModal();
      setSelectedData({});
      setShowModal(false);
    } else {
      setTypeModal(type);
      setSelectedData(data);
      setShowModal(true);
    }
  };

  const handlerSubmitAction = () => {
    if (typeModal === 'process') {
      patchProcess(id);
    } else if (typeModal === 'reject') {
      patchReject(id, {
        note: reason
      });
    } else if (typeModal === 'finish') {
      patchApprove(id);
    } else if (typeModal === 'tunda') {
      patchPending(id, {
        note: reason
      });
    } else {
      //ACCEPT / TOLAK DOC
      console.log(typeModal, 'TYPEMODAL');
    }

    setShowModal(false);
    setLoadingAction(true);
  };

  const detailModal = () => {
    const onChange = (e) => setReason(e.target.value);
    const name = personalData.name;

    return typeModalList[typeModal]({ name, reason, onChange });
  };

  const modalProps = typeModal ? detailModal() : {};

  let details = [];
  if (type === 'transferSchool') {
    details = transferScholldetails(detail);
  } else if (type === 'graduate') {
    details = graduateDetails(detail);
  } else if (type === 'pns') {
    details = pnsdetails(detail);
  }

  useEffect(() => {
    getSosBudSubmissionDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingAction) {
      let data = {};
      let error = {};
      let status = 'idle';

      if (typeModal === 'process') {
        data = dataProcess;
        error = errorProcess;
        status = statusProcess;
      } else if (typeModal === 'reject') {
        data = dataReject;
        error = errorReject;
        status = statusReject;
      } else if (typeModal === 'finish') {
        data = dataApprove;
        error = errorApprove;
        status = statusApprove;
      } else if (typeModal === 'tunda') {
        data = dataPending;
        error = errorPending;
        status = statusPending;
      }

      if (status === 'resolve') {
        const messageError = get(error, ['response', 'data', 'error', 'message']);
        const keyDetail = get(error, ['response', 'data', 'error', 'detail', 0, 'key']);
        const messageDetail = get(error, [
          'response',
          'data',
          'error',
          'detail',
          0,
          'message'
        ]);

        const { status, message } = data;
        if (status) {
          // show success message
          handlerModal('successSave', {});
          getSosBudSubmissionDetail(id);
        } else {
          const messageErrorFinal =
            message !== '' ? message : `${messageError}. ${keyDetail}: ${messageDetail}.`;
          handlerModal('failedSave', {});
          setActionData(messageErrorFinal);
        }
        setLoadingAction(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAction, statusApprove, statusReject, statusProcess, statusPending]);

  return (
    <div className='admin-sosbud-detail'>
      {(loadingDetail || loadingAction) && <Loading />}
      <span className='back-homepage' onClick={() => history.goBack()}>
        <span className='btn-back' />
        {sosbudService[type]}
      </span>
      <div className='content'>
        <div className='detail-top'>
          <div className='img' />
          <div className='detail-top-user'>
            <table>
              <tr>
                <td>
                  <label>Nama Lengkap</label>
                </td>
                <td>{personalData.name}</td>
              </tr>
              <tr>
                <td>
                  <label>Email</label>
                </td>
                <td>{personalData.email}</td>
              </tr>
              <tr>
                <td>
                  <label>Nomor Telepon</label>
                </td>
                <td>{personalData.phone}</td>
              </tr>
            </table>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Nomor Registrasi</label>
            </div>
            <div className='det-value'>
              <span className='unique-id'>{uniqueId}</span>
            </div>
          </div>
          <div className='detail-top-nomor'>
            <div>
              <label>Tangal Booking</label>
            </div>
            <div className='det-value'>
              {moment(personalData.bookingTime).format('lll')}
            </div>
          </div>
        </div>
        <div className='detail-mid'>
          <div className='general-information information-detail active'>
            {details.map(({ title, list }, key) => (
              <React.Fragment key={key}>
                <div className='title'>{title}</div>
                <table>
                  {list.map(({ value, label }, childKey) => (
                    <tr key={childKey}>
                      <td>{label}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </table>
              </React.Fragment>
            ))}
          </div>

          <div className='btn-wrap'>
            <Button color='link' type='button' onClick={() => history.goBack()}>
              Cancel
            </Button>
            <div className='btn-wrap-right'>
              {status === 'REGISTERED' && (
                <>
                  <Button
                    color='warn'
                    type='submit'
                    className='pass-btn'
                    onClick={() => handlerModal('tunda')}
                  >
                    <img src={Close} alt='img-icon' />
                    Tolak
                  </Button>
                  <Button
                    color='primary'
                    type='submit'
                    className='pass-btn'
                    onClick={() => handlerModal('process')}
                  >
                    <img src={Check} alt='img-icon' />
                    Proses Sekarang
                  </Button>
                </>
              )}
              {status === 'PROCESS' && (
                <Button
                  color='primary'
                  type='submit'
                  className='pass-btn'
                  onClick={() => handlerModal('finish')}
                >
                  <img src={Check} alt='img-icon' />
                  Proses
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className={get(modalProps, ['className']) || 'confirm'}
        buttonProps={[
          {
            btnStyle: { color: 'info', outline: true },
            label: get(modalProps, ['cta', 'no', 'text']),
            onClick: () => {
              setShowModal(false);
            }
          },
          {
            btnStyle: { color: get(modalProps, ['cta', 'yes', 'color']) },
            label: get(modalProps, ['cta', 'yes', 'text']),
            onClick: handlerSubmitAction
          }
        ]}
        title={get(modalProps, ['title'])}
        desc={get(modalProps, ['desc'])}
      />
    </div>
  );
};

DetailSosbudSuratKeterangan.propTypes = {
  history: PropTypes.object,
  submissionDetail: PropTypes.object,
  approve: PropTypes.object,
  reject: PropTypes.object,
  getSosBudSubmissionDetail: PropTypes.func,
  patchApprove: PropTypes.func,
  patchReject: PropTypes.func
};

const mapStateToProps = (state) => ({
  submissionDetail: {
    status: state.getSosBudSubmissionDetailReducer.status,
    error: state.getSosBudSubmissionDetailReducer.error,
    data: state.getSosBudSubmissionDetailReducer.data
  },
  approve: {
    status: state.patchSosBudApproveReducer.status,
    error: state.patchSosBudApproveReducer.error,
    data: state.patchSosBudApproveReducer.data
  },
  reject: {
    status: state.patchSosBudRejectReducer.status,
    error: state.patchSosBudRejectReducer.error,
    data: state.patchSosBudRejectReducer.data
  },
  process: {
    status: state.patchSosBudProcessReducer.status,
    error: state.patchSosBudProcessReducer.error,
    data: state.patchSosBudProcessReducer.data
  },
  pending: {
    status: state.patchSosBudPendingReducer.status,
    error: state.patchSosBudPendingReducer.error,
    data: state.patchSosBudPendingReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getSosBudSubmissionDetail,
    patchApprove,
    patchReject,
    patchProcess,
    patchPending
  }),
  withRouter
)(DetailSosbudSuratKeterangan);
