import React from 'react';
import InstructionWrap from '../../../../components/InstructionWrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getVisaServiceId } from '../function';

import Button from '../../../../components/Button';

const Journalist = ({ i18n, visaServiceList, history }) => {
  const visaServiceId = getVisaServiceId(visaServiceList, 'journalist');

  const proceduresList = [
    i18n('visa_page.tab10.procedures.step.1'),
    i18n('visa_page.tab10.procedures.step.2'),
    i18n('visa_page.tab10.procedures.step.3'),
    i18n('visa_page.tab10.procedures.step.4'),
    i18n('visa_page.tab10.procedures.step.5')
  ];

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('visa_page.tab10.title')}</div>
      <main>
        <div
          className='mtop8'
          dangerouslySetInnerHTML={{
            __html: i18n('visa_page.tab10.step.intro')
          }}
        />
        <div className='mtop16'>
          <div>
            <b
              dangerouslySetInnerHTML={{
                __html: i18n('visa_page.tab10.procedures.title')
              }}
            />
          </div>
          <ol>
            {proceduresList.map((item, key) => (
              <li
                key={key}
                dangerouslySetInnerHTML={{
                  __html: item
                }}
              />
            ))}
          </ol>
        </div>
        <div className='mtop16'>
          <div className='mtop16'>
            <b>{i18n('visa_page.tab10.requirement.title')}</b>
          </div>
          <div className='mtop16'>{i18n('visa_page.tab10.requirement.1')}</div>
          <Button
            color='primary'
            className='mtop16'
            onClick={() => {
              history.push(
                `/visa/registration/create?type=journalist&serviceId=${visaServiceId}`
              );
            }}
          >
            {i18n('visa_page.tab10.requirement.cta1')}
          </Button>
          <br />
          <InstructionWrap
            instruction={i18n('visa_page.tab10.requirement.2')}
            instructionList={i18n('visa_page.tab10.requirement.steps2b')}
            type='number'
          />
        </div>
        <div className='mtop16'>
          <div className='mtop8'>
            <b>{i18n('visa_page.tab10.process.title')}</b>
          </div>
          <div
            className='mtop8'
            dangerouslySetInnerHTML={{
              __html: i18n('visa_page.tab10.process.instruction')
            }}
          />
        </div>
      </main>
    </div>
  );
};

Journalist.propTypes = {
  i18n: PropTypes.func,
  visaServiceList: PropTypes.array,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(Journalist);
