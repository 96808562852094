import React from 'react';
import InstructionWrap from '../../../../components/InstructionWrap';
import StepItemVisa from '../../../../components/StepItemVisa';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVisaServiceId } from '../function';

const SocioCulture = ({ i18n, visaServiceList }) => {
  const visaServiceId = getVisaServiceId(visaServiceList, 'socio_culture');
  return (
    <div className='card-instruction-detail'>
      <div className='title'>{i18n('visa_page.tab4.title')}</div>
      <main>
        <StepItemVisa
          instructions={i18n('visa_page.tab4.step.instruction2')}
          i18n={i18n}
          type='socio_culture'
          serviceId={visaServiceId}
        />
        <InstructionWrap
          instruction={i18n('visa_page.tab4.step.instruction1')}
          instructionList={i18n('visa_page.instruction_step.socio_req')}
          type='number'
        />

        <div
          className='mtop8'
          dangerouslySetInnerHTML={{
            __html: i18n('visa_page.tab4.step.additional_info')
          }}
        />
        <div
          className='mtop8'
          dangerouslySetInnerHTML={{ __html: i18n('visa_page.tab4.step.address') }}
        />
      </main>
    </div>
  );
};

SocioCulture.propTypes = {
  i18n: PropTypes.func,
  visaServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(SocioCulture);
