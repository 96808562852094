import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import './style.scss';

import { setLang } from '../../../lang/langAction';
import Multilang from '../../../components/Multilang';
import ActivityThumbnail from '../../../components/thumbnail/ActivityThumbnail';
import TourThumbnail from '../../../components/thumbnail/TourThumbnail';
import HomepageMenu from './HomepageMenu';

import safeTravel from '../../../assets/img/homepage/safe_travel.png';
import arrowRight from '../../../assets/img/icon/arrow_right.svg';

import { getEventList } from '../Event/action';
import { getTourList } from '../Tourism/action';

const Homepage = ({
  setLang,
  history,
  i18n,
  getEventList,
  getTourList,
  eventList,
  tourList
}) => {
  const { data: eventData } = eventList;
  const { data: tourData } = tourList;

  const { pathname } = get(history, ['location']);

  useEffect(() => {
    if (pathname === '/en') {
      setLang('en');
    } else if (pathname === '/id') {
      setLang('id');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    getEventList();
    getTourList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='homepage'>
      <div className='homepage-header'>
        <Container>
          <Row>
            <Col>
              <span className='hh-top'>{i18n('homepage.welcome')}</span>
              <span className='hh-title'>{i18n('homepage.kjri')}</span>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <span className='hh-top'>Bahasa / Language</span>
              <Multilang />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='homepage-header-menu'>
        <div className='homepage-header-blue' />
        <Container>
          <div className='homepage-menu'>
            <div className='homepage-menu-top'>{i18n('homepage.menu.choose')}</div>
            <HomepageMenu />
          </div>
        </Container>
      </div>
      <Container>
        <div className='portal-menu'>
          <a
            href='https://peduliwni.kemlu.go.id/beranda.html'
            target='_blank'
            without
            rel='noopener noreferrer'
            className='peduli-wni'
          >
            <span className='info'>Portal Peduli WNI</span>
          </a>
          <a
            href='https://safetravel.id/'
            target='_blank'
            without
            rel='noopener noreferrer'
            className='safe-travel'
          >
            <img src={safeTravel} alt='safe-travel-img' width='150' />
            <span className='info'>Safe Travel</span>
          </a>
          <a
            href='https://www.indonesianstreetfestivalny.com/'
            target='_blank'
            without
            rel='noopener noreferrer'
            className='street-festival'
          >
            <span className='info'>Market Place Indonesian Street Festival</span>
          </a>
          <a
            href='https://survey.kemlu.go.id/index.php/796918?lang=en'
            target='_blank'
            without
            rel='noopener noreferrer'
            className='citra'
          >
            <span className='info'>Citra Indonesia Survey</span>
          </a>
          <a href='https://www.911.gov/' target='_blank' className='sembilan'>
            <span className='info'>911 Emergency</span>
          </a>
        </div>
      </Container>

      {/*EVENT */}
      <Container>
        <div className='homepage-activity'>
          <h3 style={{ marginBottom: 24 }}>{i18n('homepage.heading.event')}</h3>
          <span className='more-button' style={{ float: 'right' }}>
            <Link to='/event'>
              {i18n('homepage.see_more')}
              <img src={arrowRight} alt='more-button-img' style={{ marginLeft: 9 }} />
            </Link>
          </span>

          <div className='homepage-activity-list'>
            {eventData.slice(0, 4).map((detail, key) => (
              <ActivityThumbnail source='event-list' key={key} data={detail} />
            ))}
          </div>
        </div>
      </Container>

      {/*TOURISM */}
      <Container>
        <div className='homepage-activity'>
          <h3 style={{ marginBottom: 16 }}>{i18n('homepage.heading.tour')}</h3>
          <span className='more-button' style={{ float: 'right' }}>
            <Link to='/tourism'>
              {i18n('homepage.see_more')}
              <img src={arrowRight} alt='more-button-img' style={{ marginLeft: 9 }} />
            </Link>
          </span>

          <div className='homepage-activity-category-list' style={{ display: 'none' }}>
            <div className='homepage-activity-category active'>
              Nature, Science Landscape And Wildlife
            </div>
            <div className='homepage-activity-category'>Arts, Culture, And Heritage</div>
            <div className='homepage-activity-category'>Recreation And Leisure</div>
            <div className='homepage-activity-category'>Culinary And Wellnes</div>
            <div className='homepage-activity-category'>Adventures</div>
          </div>

          <div className='homepage-activity-list' style={{ marginTop: 24 }}>
            {tourData.slice(0, 4).map((tour, key) => (
              <TourThumbnail key={key} data={tour} history={history} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

Homepage.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getEventList: PropTypes.func,
  getTourList: PropTypes.func,
  eventList: PropTypes.object,
  tourList: PropTypes.object
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  status: state.newsReducer.status,
  error: state.newsReducer.error,
  news: state.newsReducer.data,
  eventList: {
    status: state.eventListReducer.status,
    error: state.eventListReducer.error,
    data: state.eventListReducer.data
  },
  tourList: {
    status: state.tourListReducer.status,
    error: state.tourListReducer.error,
    data: state.tourListReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { getTourList, setLang, getEventList }),
  withRouter
)(Homepage);
