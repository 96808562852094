import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../helpers/helpers';
import { errorScroll, errorAlertScroll } from '../../../../helpers/functions';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import Loading from '../../../../components/Loading';

import {
  postEmailVerification,
  postCodeVerification,
  resetEmailVerification,
  resetCodeVerification
} from '../action';

const SignInForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  tmpform,
  setTmpForm,
  postEmailVerification,
  emailVerification,
  handleSubmit,
  codeVerification,
  postCodeVerification,
  resetEmailVerification,
  resetCodeVerification
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({
    ...(formGlobal.signIn || tmpform.signIn)
  });
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState(get(formGlobal, ['personalInfo', 'email']));
  const [alertDetail, setAlertDetail] = useState({});
  const [visible, setVisible] = useState(false);

  const {
    status: statusEmailVerification,
    error: errorEmailVerification
  } = emailVerification;
  const status = statusEmailVerification;

  const {
    status: statusCodeVerification,
    error: errorCodeVerification
  } = codeVerification;
  const disableButton = statusCodeVerification === 'resolve' ? false : true;

  const handlerEmailVerification = (email) => {
    setEmail(email);
    postEmailVerification({
      name: get(formGlobal, ['personalInfo', 'firstName']),
      email
    });
  };

  const handlerResend = () => {
    let schema = yup.object().shape({
      resend: yup.string().email('email').required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        handlerEmailVerification(form.resend);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      completed_at: yup.string().required('required'),
      signature_name: yup.string().required('required'),
      verification_code: yup.string().required('required')
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        if (!disableButton) {
          setFormGlobal({ ...formGlobal, signIn: form });
          resetEmailVerification();
          resetCodeVerification();
          handleSubmit();
        }
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  const handlerAlert = (data) => {
    setVisible(true);
    setAlertDetail(data);
    setTimeout(() => {
      setVisible(false);
      setAlertDetail({});
    }, 5000);

    errorAlertScroll();
  };

  useEffect(() => {
    return () => {
      resetEmailVerification();
      resetCodeVerification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTmpForm({ ...tmpform, signIn: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (statusEmailVerification === 'resolve') {
      handlerAlert({
        color: 'success',
        message: i18n('form.visa_signin.resend.send_verification', {
          email
        })
      });
    } else if (statusEmailVerification === 'rejected') {
      const message = get(errorEmailVerification, [
        'response',
        'data',
        'error',
        'message'
      ]);
      const messageDetail = get(errorEmailVerification, [
        'response',
        'data',
        'error',
        'detail'
      ]);
      const fullMessage = `${message}. ${messageDetail}.`;

      handlerAlert({
        color: 'danger',
        message: fullMessage
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusEmailVerification]);

  useEffect(() => {
    if (
      form.verification_code &&
      form.verification_code !== '' &&
      form.verification_code.length > 6 &&
      statusCodeVerification !== 'pending'
    ) {
      postCodeVerification({
        email,
        code: form.verification_code
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.verification_code]);

  useEffect(() => {
    if (statusCodeVerification === 'rejected') {
      const message = get(errorCodeVerification, [
        'response',
        'data',
        'error',
        'message'
      ]);
      const messageDetail = get(errorEmailVerification, [
        'response',
        'data',
        'error',
        'detail'
      ]);
      const fullMessage = `${message}. ${messageDetail}.`;

      handlerAlert({
        color: 'danger',
        message: fullMessage
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCodeVerification]);

  return (
    <form onSubmit={handlerSubmit} id='personal-info'>
      {status === 'pending' && <Loading />}
      <Alert color={get(alertDetail, 'color')} isOpen={visible}>
        {get(alertDetail, 'message')}
      </Alert>
      <div className='form-container'>
        <div className='form-wrap'>
          <p dangerouslySetInnerHTML={{ __html: i18n('form.visa_signin.desc') }} />
          <TextInput
            label={i18n('form.visa_signin.completed_at.label')}
            placeholder={i18n('form.visa_signin.completed_at.placeholder')}
            value={get(form, ['completed_at'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'completed_at')
            }}
            errorMessage={
              get(errors, ['completed_at']) &&
              i18n(`validation.${get(errors, ['completed_at'])}`)
            }
          />
          <TextInput
            label={i18n('form.visa_signin.signature_name.label')}
            placeholder={i18n('form.visa_signin.signature_name.placeholder')}
            value={get(form, ['signature_name'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'signature_name')
            }}
            errorMessage={
              get(errors, ['signature_name']) &&
              i18n(`validation.${get(errors, ['signature_name'])}`)
            }
          />
          <div className='input-container'>
            <div className='label'>{i18n('form.visa_signin.resend.method')}</div>
            <div
              className='verification-method'
              onClick={() => handlerEmailVerification(email)}
            >
              {i18n('form.visa_signin.resend.email')}
            </div>
            <p>
              {i18n('form.visa_signin.resend.send_verification', {
                email
              })}
            </p>
          </div>
          <p
            style={{
              paddingLeft: 175,
              fontSize: 18,
              lineHeight: '21px',
              color: '#053960'
            }}
          >
            {i18n('form.visa_signin.resend.not_receive_code')}
          </p>
          <TextInput
            className='bold-label'
            label={i18n('form.visa_signin.resend.label')}
            placeholder={i18n('form.visa_signin.resend.placeholder')}
            value={get(form, ['resend'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'resend')
            }}
            errorMessage={
              get(errors, ['resend']) && i18n(`validation.${get(errors, ['resend'])}`)
            }
          />
          <div className='resend-email' onClick={handlerResend}>
            {i18n('form.visa_signin.resend.resend_cta')}
          </div>
          <TextInput
            className='bold-label short-input'
            label={i18n('form.visa_signin.verification_number.label')}
            placeholder={i18n('form.visa_signin.verification_number.placeholder')}
            value={get(form, ['verification_code'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'verification_code')
            }}
            errorMessage={
              get(errors, ['verification_code']) &&
              i18n(`validation.${get(errors, ['verification_code'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/visa/form/other${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button
          color='primary'
          type='submit'
          disabled={disableButton}
          className='pass-btn'
        >
          {i18n('cta.submit')} & Sign In
        </Button>
      </div>
    </form>
  );
};

SignInForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  tmpform: PropTypes.object,
  setTmpForm: PropTypes.func,
  postEmailVerification: PropTypes.func,
  handleSubmit: PropTypes.func,
  emailVerification: PropTypes.object,
  codeVerification: PropTypes.object,
  postCodeVerification: PropTypes.func,
  resetEmailVerification: PropTypes.func,
  resetCodeVerification: PropTypes.func
};

const mapStateToProps = (state) => ({
  emailVerification: {
    status: state.emailVerificationReducer.status,
    error: state.emailVerificationReducer.error,
    data: state.emailVerificationReducer.data
  },
  codeVerification: {
    status: state.codeVerificationReducer.status,
    error: state.codeVerificationReducer.error,
    data: state.codeVerificationReducer.data
  }
});

export default compose(
  connect(mapStateToProps, {
    postEmailVerification,
    postCodeVerification,
    resetEmailVerification,
    resetCodeVerification
  }),
  withRouter
)(SignInForm);
