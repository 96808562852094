import { apiCaller } from '../../../../helpers/apiCaller';

// INVESTMENT INQUIRY
const getExhibitorListRequest = () => ({
  type: 'GET_EXHIBITOR_LIST_REQUEST'
});

const getExhibitorListSuccess = (data) => ({
  type: 'GET_EXHIBITOR_LIST_SUCCESS',
  payload: data
});

const getExhibitorListFailure = (error) => ({
  type: 'GET_EXHIBITOR_LIST_FAILURE',
  payload: error
});

const resetExhibitorList = () => ({
  type: 'RESET_EXHIBITOR_LIST_LIST'
});

const getExhibitorList = (params) => {
  return (dispatch) => {
    dispatch(getExhibitorListRequest());
    return apiCaller('admin/exhibitors', 'get', undefined, true, params)
      .then((data) => dispatch(getExhibitorListSuccess(data)))
      .catch((error) => dispatch(getExhibitorListFailure(error)));
  };
};

// INVESTMENT INQUIRY DETAIL
const getExhibitorDetailRequest = () => ({
  type: 'GET_EXHIBITOR_DETAIL_REQUEST'
});

const getExhibitorDetailSuccess = (data) => ({
  type: 'GET_EXHIBITOR_DETAIL_SUCCESS',
  payload: data
});

const getExhibitorDetailFailure = (error) => ({
  type: 'GET_EXHIBITOR_DETAIL_FAILURE',
  payload: error
});

const resetExhibitorDetail = () => ({
  type: 'RESET_EXHIBITOR_DETAIL_LIST'
});

const getExhibitorDetail = (id) => {
  return (dispatch) => {
    dispatch(getExhibitorDetailRequest());
    return apiCaller(`exhibitors/${id}`, 'get', undefined, true)
      .then((data) => dispatch(getExhibitorDetailSuccess(data)))
      .catch((error) => dispatch(getExhibitorDetailFailure(error)));
  };
};

// DAY
const getStatisticRequest = () => ({
  type: 'GET_EXHIBITOR_STATISTIC_REQUEST'
});

const getStatisticSuccess = (data) => ({
  type: 'GET_EXHIBITOR_STATISTIC_SUCCESS',
  payload: data
});

const getStatisticFailure = (error) => ({
  type: 'GET_EXHIBITOR_STATISTIC_FAILURE',
  payload: error
});

const getStatistic = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticRequest());
    return apiCaller('admin/exhibitors/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticSuccess(data)))
      .catch((error) => dispatch(getStatisticFailure(error)));
  };
};

// WEEK
const getStatisticWeekRequest = () => ({
  type: 'GET_EXHIBITOR_STATISTIC_WEEK_REQUEST'
});

const getStatisticWeekSuccess = (data) => ({
  type: 'GET_EXHIBITOR_STATISTIC_WEEK_SUCCESS',
  payload: data
});

const getStatisticWeekFailure = (error) => ({
  type: 'GET_EXHIBITOR_STATISTIC_WEEK_FAILURE',
  payload: error
});

const getStatisticWeek = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticWeekRequest());
    return apiCaller('admin/exhibitors/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticWeekSuccess(data)))
      .catch((error) => dispatch(getStatisticWeekFailure(error)));
  };
};

// MONTH
const getStatisticMonthRequest = () => ({
  type: 'GET_EXHIBITOR_STATISTIC_MONTH_REQUEST'
});

const getStatisticMonthSuccess = (data) => ({
  type: 'GET_EXHIBITOR_STATISTIC_MONTH_SUCCESS',
  payload: data
});

const getStatisticMonthFailure = (error) => ({
  type: 'GET_EXHIBITOR_STATISTIC_MONTH_FAILURE',
  payload: error
});

const getStatisticMonth = (params, type) => {
  return (dispatch) => {
    dispatch(getStatisticMonthRequest());
    return apiCaller('admin/exhibitors/statistic', 'get', undefined, true, params)
      .then((data) => dispatch(getStatisticMonthSuccess(data)))
      .catch((error) => dispatch(getStatisticMonthFailure(error)));
  };
};
export {
  resetExhibitorList,
  getExhibitorList,
  resetExhibitorDetail,
  getExhibitorDetail,
  getStatistic,
  getStatisticWeek,
  getStatisticMonth
};
