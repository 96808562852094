import { apiCaller } from '../../../../helpers/apiCaller';

// Number regis passport
const getTradeInquiryRequest = () => ({
  type: 'GET_TRADE_INQUIRY_REQUEST'
});

const getTradeInquirySuccess = (data) => ({
  type: 'GET_TRADE_INQUIRY_SUCCESS',
  payload: data
});

const getTradeInquiryFailure = (error) => ({
  type: 'GET_TRADE_INQUIRY_FAILURE',
  payload: error
});

const postTradeInquiry = (params) => {
  return (dispatch) => {
    dispatch(getTradeInquiryRequest());
    return apiCaller('trade/inquiry', 'post', params)
      .then((data) => dispatch(getTradeInquirySuccess(data)))
      .catch((error) => dispatch(getTradeInquiryFailure(error)));
  };
};

const resetTradeInquiry = () => {
  const defaultData = {
    data: [],
    status: 'idle',
    error: ''
  };

  return (dispatch) => {
    dispatch({
      type: 'RESET_TRADE_INQUIRY',
      data: defaultData
    });
  };
};

export { postTradeInquiry, resetTradeInquiry };
