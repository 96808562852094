import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import get from 'lodash/get';

import Button from '../../../components/Button';
import LoadingOverlay from '../../../components/Loading';
import TextInput from '../../../components/input/TextInput';

import kjriLogo from '../../../assets/img/kjri_logo.png';
import ccLogo from '../../../assets/img/cc_logo.svg';
import homeLogo from '../../../assets/img/home_24.svg';

import { postAdminLogin } from './action';

import './style.scss';

const Login = ({ history, postAdminLogin, loginState }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [seePassword, setSeePassword] = useState(false);
  const [errorSave, setErrorSave] = useState('');
  const [visible, setVisible] = useState(false);
  const defaultForm = {
    username: email,
    password: password,
    device: 'desktop'
  };

  const { data, status, error } = loginState;

  const submitLogin = () => {
    postAdminLogin(defaultForm);
  };

  useEffect(() => {
    if (status === 'rejected') {
      const message = get(error, ['response', 'data', 'error', 'message']);
      const detailMessage = get(error, ['response', 'data', 'error', 'detail']);
      setErrorSave(`${message}. ${detailMessage}`);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setErrorSave('');
      }, 5000);
    } else if (status === 'resolve') {
      const token = get(data, [0, 'token']);
      localStorage.setItem('accessToken', token);
      localStorage.setItem('username', email);
      history.push('/admin/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      history.push('/admin/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='login-page admin'>
      {status === 'pending' && <LoadingOverlay />}
      <Alert color='danger' isOpen={visible}>
        {errorSave}
      </Alert>
      <div className='login-page-img'>
        <img src={kjriLogo} alt='kjri logo' width='62' />
        <img src={ccLogo} alt='consuler logo' width='62' />
      </div>
      <div className='desc-1'>Welcome to</div>
      <div className='desc-2'>
        Business Portal Consulate Connect
        <br />
        KJRI New York
      </div>
      <div className='desc-3'>
        Portal Digital Informasi Kerjasama Indonesia dan Amerika Serikat
      </div>
      <div className='login-form'>
        <div className='form-title'>Login</div>
        <TextInput
          value={email}
          placeholder='Username'
          type='text'
          inputProps={{
            onChange: (e) => setEmail(e.target.value)
          }}
        />
        <TextInput
          placeholder='Password'
          value={password}
          inputProps={{
            onChange: (e) => setPassword(e.target.value),
            type: `${seePassword ? 'text' : 'password'}`
          }}
          iconRight='eye'
          iconRightClick={() => setSeePassword(!seePassword)}
        />
        <Button color='third' onClick={submitLogin}>
          Login Sekarang
        </Button>
      </div>
      <div className='back-to-home' onClick={() => history.push('/')}>
        <img src={homeLogo} alt='home logo' />
        Kembali ke halaman utama konsular
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.any,
  postAdminLogin: PropTypes.func,
  loginState: PropTypes.object
};

const mapStateToProps = (state) => ({
  loginState: {
    status: state.postAdminLoginReducer.status,
    error: state.postAdminLoginReducer.error,
    data: state.postAdminLoginReducer.data
  }
});

export default compose(connect(mapStateToProps, { postAdminLogin }), withRouter)(Login);
