import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Alert } from 'reactstrap';

import { errorAlertScroll } from '../../../../helpers/functions';
import { apiCaller } from '../../../../helpers/apiCaller';

import './style.scss';

const maxFileSize = 1; //mb

const FileInput = ({
  label,
  sublabel,
  errorMessage,
  className = '',
  disable = false,
  setFiles,
  unique_number,
  doc_type,
  doc_number,
  infoLabel,
  filePassport,
  fileTypePassport
}) => {
  const [file, setFile] = useState({});
  const [alertDetail, setAlertDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(fileTypePassport);

  const img =
    filePassport || (type === 'img' && get(file, ['base64'])) || get(file, ['url']);

  const handlerChanges = (e) => {
    const tmpFile = {};
    const file = e.target.files[0];
    if (file) {
      tmpFile.file = file;

      const fileSize = (file.size / 1024 / 1024).toFixed(4);
      const fileType = file.type.includes('image') ? 'img' : 'pdf';
      const isValidType = file.type.includes('image') || file.type.includes('pdf');
      if (fileSize < maxFileSize && isValidType) {
        var reader = new FileReader();
        reader.onloadend = () => {
          tmpFile.base64 = reader.result;
          setFile(tmpFile);
          setType(fileType);
        };
        reader.readAsDataURL(file);
      } else {
        document.getElementById(`uploadCaptureInputFile_${doc_number}`).value = '';
        handlerAlert({
          color: 'danger',
          message: isValidType
            ? `Maksimum ukuran file ${maxFileSize}MB`
            : 'Format file JPG / JPEG / PDF / PNG'
        });
      }
    }
  };

  const handlerAlert = (data) => {
    setVisible(true);
    setAlertDetail(data);
    setTimeout(() => {
      setVisible(false);
    }, 5000);
    errorAlertScroll();
  };

  const handlerSubmit = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append('file', file.file);
    formData.append('doc_type', doc_type);
    formData.append('unique_number', unique_number);

    // Send formData object
    setLoading(true);
    apiCaller('storages/passport', 'post', formData)
      .then((res) => {
        setLoading(false);
        if (get(res, ['response', 'data', 'status']) === 422) {
          const message = get(res, ['response', 'data', 'error', 'detail', 0, 'message']);
          handlerAlert({
            color: 'danger',
            message: `Gagal upload. Coba sekali lagi. ${message}`
          });
        } else {
          const { data } = res;
          setFiles({
            url: get(data, ['data', 'url']),
            type
          });
          handlerAlert({
            color: 'success',
            message: 'File success uploaded.'
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        const errRes = err.response;
        const message = get(errRes, ['data', 'error', 'detail', 0, 'message']);
        handlerAlert({
          color: 'danger',
          message: `Gagal upload. Coba sekali lagi. ${message}`
        });
      });
  };

  const deleteFile = () => {
    setFile({});
    setFiles();
    document.getElementById(`uploadCaptureInputFile_${doc_number}`).value = '';
  };

  return (
    <>
      <Alert color={get(alertDetail, ['color'])} isOpen={visible}>
        {get(alertDetail, ['message'])}
      </Alert>
      <div
        className={`input-container-passport ${className} ${errorMessage ? 'err' : ''} ${
          disable ? 'disable' : ''
        }`}
      >
        {label && (
          <div className='label'>
            {label}
            {sublabel && <div className='sublabel'>{sublabel}</div>}
          </div>
        )}
        <div className='img-input-wrap'>
          {!filePassport && (
            <div className='input-wrap'>
              <input
                className='file-input'
                id={`uploadCaptureInputFile_${doc_number}`}
                type='file'
                onChange={handlerChanges}
                accept='image/x-png,image/jpeg,application/pdf'
              />
              {!loading ? (
                <div className='input-group'>
                  <div
                    className={`upload-button ${
                      Object.keys(file).length === 0 ? 'disable' : ''
                    }`}
                    onClick={handlerSubmit}
                  >
                    Upload
                  </div>
                </div>
              ) : (
                'Loading...'
              )}
            </div>
          )}

          {type === 'img' && (Object.keys(file).length > 0 || filePassport) && (
            <div className='photo-preview'>
              {img ? <img src={img} alt='img-preview' /> : <div className='empty-img' />}
              <span onClick={deleteFile}>Delete</span>
            </div>
          )}

          {type !== 'img' && (Object.keys(file).length > 0 || filePassport) && (
            <div className='photo-preview'>
              {filePassport && (
                <>
                  {filePassport}
                  <br />
                </>
              )}
              <span onClick={deleteFile}>Delete</span>
            </div>
          )}

          <div className='desc'>
            <div className='max-file'>
              * Maksimum ukuran file {maxFileSize}MB dengan format JPG | JPEG | PDF | PNG
            </div>
            {infoLabel && <div className='desc-info'>{infoLabel}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  sublabel: PropTypes.any,
  disable: PropTypes.bool
};

export default FileInput;
