import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';

import ekonomiService from '../../../../constant/ekonomiBisnis';
import { getQuery } from '../../../pages/Paspor/function';

import {
  getInvestmentInquiryDetail,
  getTradeInquiryDetail
} from '../BisnisDiIndonesia/action';
import {
  getInvestmentQuestionDetail,
  getTradeQuestionDetail
} from '../MenghubungkanIndonesia/action';
import { getExhibitorDetail } from '../PameranDagang/action';
import { exhibitionList } from '../components/SingleContent';

import './style.scss';

const DetailEkonomi = ({
  history,
  getInvestmentInquiryDetail,
  getTradeInquiryDetail,
  getInvestmentQuestionDetail,
  getTradeQuestionDetail,
  getExhibitorDetail,
  investmentInquiry,
  tradeInquiry,
  investmentQuestion,
  tradeQuestion,
  exhibitor,
  i18n
}) => {
  const { type, id } = useParams();
  const { tab } = getQuery(history);

  const tabTitle = {
    investment: 'Investasi',
    trade: 'Perdagangan'
  };

  let data = {};
  if (type === 'ekobisnis') {
    if (tab === 'investment') {
      data = investmentInquiry;
    } else if (tab === 'trade') {
      data = tradeInquiry;
    }
  } else if (type === 'ekohubung') {
    if (tab === 'investment') {
      data = investmentQuestion;
    } else if (tab === 'trade') {
      data = tradeQuestion;
    }
  } else if (type === 'ekopameran' || type === 'ekodagang') {
    data = exhibitor;
  }

  const detail = get(data, ['data', 'data', 0]);

  useEffect(() => {
    if (type === 'ekobisnis') {
      if (tab === 'investment') {
        getInvestmentInquiryDetail(id);
      } else if (tab === 'trade') {
        getTradeInquiryDetail(id);
      }
    } else if (type === 'ekohubung') {
      if (tab === 'investment') {
        getInvestmentQuestionDetail(id);
      } else if (tab === 'trade') {
        getTradeQuestionDetail(id);
      }
    } else if (type === 'ekopameran' || type === 'ekodagang') {
      getExhibitorDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id]);

  const details = [
    {
      title: 'NAME',
      list: [
        { label: 'First Name', value: get(detail, ['first_name']) },
        { label: 'Last Name', value: get(detail, ['last_name']) },
        { label: 'Current Position', value: get(detail, ['current_position']) },
        { label: 'Company Name', value: get(detail, ['company_name']) },
        { label: 'Business Fields', value: get(detail, ['business_field']) }
      ]
    },
    {
      title: 'PHONE NUMBER',
      list: [
        { label: 'Primary', value: get(detail, ['primary_phone']) },
        { label: 'Alternative', value: get(detail, ['secondary_phone']) }
      ]
    },
    {
      title: 'EMAIL ADDRESS',
      list: [
        { label: 'Primary', value: get(detail, ['primary_email']) },
        { label: 'Alternative', value: get(detail, ['secondary_email']) }
      ]
    },
    {
      title: 'ADDRESS',
      list: [
        { label: 'Street Address', value: get(detail, ['address']) },
        { label: 'Country', value: get(detail, ['country']) },
        { label: 'City', value: get(detail, ['city']) },
        { label: 'State Province', value: get(detail, ['state']) },
        { label: 'ZIP Code', value: get(detail, ['zip_code']) },
        ...(type !== 'ekodagang' && type !== 'ekopameran'
          ? [{ label: 'Ketertarikan Bisnis', value: get(detail, ['business_need']) }]
          : []),
        ...(type === 'ekodagang' || type === 'ekopameran'
          ? [{ label: 'Pameran', value: exhibitionList(i18n)[get(detail, ['event'])] }]
          : []),
        ...(type === 'ekobisnis'
          ? [{ label: 'Inquiry', value: get(detail, ['inquiry']) }]
          : []),
        ...(type === 'ekohubung' || type === 'ekopameran' || type === 'ekodagang'
          ? [{ label: 'Pertanyaan', value: get(detail, ['question']) }]
          : [])
        // { label: 'Lainnya', value: 'other' }
      ]
    }
  ];

  return (
    <div className='admin-visa-detail'>
      <span className='back-homepage' onClick={() => history.goBack()}>
        <span className='btn-back' />
        {`${ekonomiService[type]} ${tab ? `- ${tabTitle[tab]}` : ''}`}
      </span>
      <div className='content'>
        <div className='detail-mid'>
          <div className='general-information information-detail active'>
            {details.map(({ title, list }, key) => (
              <>
                <div className='title' key={key}>
                  {title}
                </div>
                <table>
                  {list.map(({ value, label }, keyChild) => (
                    <tr key={keyChild}>
                      <td>{label}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </table>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

DetailEkonomi.propTypes = {
  history: PropTypes.object,
  getInvestmentInquiryDetail: PropTypes.func,
  getTradeInquiryDetail: PropTypes.func,
  getInvestmentQuestionDetail: PropTypes.func,
  getTradeQuestionDetail: PropTypes.func,
  getExhibitorDetail: PropTypes.func,
  investmentInquiry: PropTypes.object,
  tradeInquiry: PropTypes.object,
  investmentQuestion: PropTypes.object,
  tradeQuestion: PropTypes.object,
  exhibitor: PropTypes.object
};

const mapStateToProps = (state) => ({
  investmentInquiry: {
    status: state.getInvestmentInquiryDetailReducer.status,
    error: state.getInvestmentInquiryDetailReducer.error,
    data: state.getInvestmentInquiryDetailReducer.data
  },
  tradeInquiry: {
    status: state.getTradeInquiryDetailReducer.status,
    error: state.getTradeInquiryDetailReducer.error,
    data: state.getTradeInquiryDetailReducer.data
  },
  investmentQuestion: {
    status: state.getInvestmentQuestionDetailReducer.status,
    error: state.getInvestmentQuestionDetailReducer.error,
    data: state.getInvestmentQuestionDetailReducer.data
  },
  tradeQuestion: {
    status: state.getTradeQuestionDetailReducer.status,
    error: state.getTradeQuestionDetailReducer.error,
    data: state.getTradeQuestionDetailReducer.data
  },
  exhibitor: {
    status: state.getExhibitorDetailReducer.status,
    error: state.getExhibitorDetailReducer.error,
    data: state.getExhibitorDetailReducer.data
  },
  i18n: state.langReducer.i18n
});

export default compose(
  connect(mapStateToProps, {
    getInvestmentInquiryDetail,
    getTradeInquiryDetail,
    getInvestmentQuestionDetail,
    getTradeQuestionDetail,
    getExhibitorDetail
  }),
  withRouter
)(DetailEkonomi);
