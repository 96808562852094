const defaultState = {
  slotTime: []
};

const scheduleReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SLOT_TIME':
      return {
        ...state,
        slotTime: action.slotTime
      };
    default:
      return state;
  }
};

// APPOINTMENT LIST
const appointmentList = {
  data: [],
  status: 'idle',
  error: ''
};

const appointmentListReducer = (state = appointmentList, action) => {
  switch (action.type) {
    case 'GET_APPOINTMENT_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_APPOINTMENT_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.appointments
      };
    case 'GET_APPOINTMENT_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.appointments.data
      };
    default:
      return state;
  }
};

// BOOK APPOINTMENT
const bookAppointment = {
  data: [],
  status: 'idle',
  error: ''
};

const bookAppointmentReducer = (state = bookAppointment, action) => {
  switch (action.type) {
    case 'POST_BOOK_APPOINTMENT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_BOOK_APPOINTMENT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.bookAppointments
      };
    case 'POST_BOOK_APPOINTMENT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.bookAppointments.data
      };
    default:
      return state;
  }
};

// MULTIBOOK APPOINTMENT
const multiBookAppointment = {
  data: [],
  status: 'idle',
  error: ''
};

const multiBookAppointmentReducer = (state = multiBookAppointment, action) => {
  switch (action.type) {
    case 'POST_MULTI_BOOK_APPOINTMENT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_MULTI_BOOK_APPOINTMENT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.multiBookAppointments
      };
    case 'POST_MULTI_BOOK_APPOINTMENT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.multiBookAppointments.data
      };
    default:
      return state;
  }
};

//  APPOINTMENT DETAIL
const appointmentDetail = {
  data: [],
  status: 'idle',
  error: ''
};

const appointmentDetailReducer = (state = appointmentDetail, action) => {
  switch (action.type) {
    case 'GET_APPOINTMENT_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_APPOINTMENT_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.appointmentDetail
      };
    case 'GET_APPOINTMENT_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.appointmentDetail.data
      };
    default:
      return state;
  }
};

export {
  scheduleReducer,
  appointmentListReducer,
  bookAppointmentReducer,
  appointmentDetailReducer,
  multiBookAppointmentReducer
};
