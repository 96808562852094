import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import Action from './Action';
import ImageView from './ImageView';
import searchIcon from '../../assets/img/icon/search.svg';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import './style.scss';
import get from 'lodash/get';
import { date } from 'yup';

const DashboardTable = ({
  columns,
  data,
  action,
  search,
  idKeyField = 'id',
  pagination,
  setPage,
  setPerPage,
  startDate,
  endDate,
  status,
  dataOnChange
}) => {
  const [pageDivider, setPageDivider] = useState(pagination.per_page);
  const [currentPage, setCurrentPage] = useState(pagination.page);
  const [keyWord, setKeyword] = useState('');
  // const lastView = currentPage * pageDivider;
  // const firstView = lastView - pageDivider;
  const listHead = columns.map((column, index) => (
    <td key={index} className={column.className || ''}>
      {column.text}
    </td>
  ));
  let count = 0;

  const columnDetail = (column, data, count) => {
    const { text, customColumn } = column;
    const columData = get(data, column.dataField);
    if (text === 'Action') {
      return <Action action={action} id={data[idKeyField]} data={data} />;
    } else if (text === 'CustomAction') {
      const selectedAction = action(data);
      return <Action action={selectedAction} id={data[idKeyField]} data={data} />;
    } else if (text === 'No') {
      return count;
    } else if (text.includes('Date')) {
      return moment(data[column.dataField])
        .utcOffset(data[column.dataField])
        .format('LLL');
    } else if (text.includes('At')) {
      return DateTime(data[column.dataField])
    } else if (text === 'Image') {
      return <ImageView url={data[column.dataField]} />;
    } else if (text === 'Name & Email') {
      return NameEmail(data[column.dataField[0]], data[column.dataField[1]])
    } else if (text === 'Unique Number') {
      return UniqueNumber(data[column.dataField[0]], data[column.dataField[1]])
    } else if (customColumn) {
      return customColumn(data);
    } else {
      // return data[column.dataField];
      return columData || '-';
    }
  };

  const NameEmail = (name, email) => {
    return (
    <div>
      <b>{ name ? name : '-' }</b>
      <br/>
      { email }
    </div>
  );}

  const DateTime = (datetime) => {
    return (
      <div>
        {moment(datetime).utcOffset(datetime).format('LL')}
        <br/>
        {moment(datetime).utcOffset(datetime).format('LT')}
      </div>
    );
  }

  const UniqueNumber = (number, serviceId) => {
    if (number) {
      return (
        <div>
          <a href={"/admin/passpor/" + serviceIdToUrl(serviceId) + "/detail/" + number} target="_blank">
            {number}
          </a>
        </div>
      );
    }else{
      return '-';
    }
  }

  const serviceIdToUrl = (id) => {
    const map = {
      '601660325d0da90b123033ae': 'broken_pass',
      '601660325d0da90b123033af': 'lost_pass',
      '601660325d0da90b123033b0': 'new_born_pass',
      '601660325d0da90b123033ab': 'expired_pass',
      '601660325d0da90b123033ac': 'splp',
      '601660325d0da90b123033ad': 'expired_child',
    }
    return map[id]
  }

  const dataView = data.map((data, index) => (
    <>
      {data[search].toString().toLowerCase().includes(keyWord) && (
        <>
          <tr key={index}>
            {columns.map((column, index) => (
              <td
                key={index}
                className={column.className || ''}
                style={{ maxWidth: 180 }}
              >
                {columnDetail(column, data, count)}
              </td>
            ))}
          </tr>
        </>
      )}
    </>
  ));

  const changePageDivider = (e) => {
    setPageDivider(e.target.value);
    setCurrentPage(1);
    setPerPage && setPerPage(e.target.value);
    setPage && setPage(1);
    dataOnChange(e.target.value, currentPage, startDate, endDate, status);
  };

  const changeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const changeCurrentPage = (e) => {
    setCurrentPage(e);
    setPage && setPage(e);
    dataOnChange(pageDivider, e, startDate, endDate, status);
  };

  return (
    <div className='dashboard-table'>
      <div className='table-top'>
        <div className='page-view'>
          Show
          <select
            value={pageDivider}
            name='pageview'
            id='pageview'
            onChange={changePageDivider}
          >
            <option value='5'>5</option>
            <option value='15'>15</option>
            <option value='25'>25</option>
            <option value='45'>45</option>
          </select>
        </div>
        <div className='search'>
          <img src={searchIcon} alt='search-img' />
          <input value={keyWord} placeholder='Search Data..' onChange={changeKeyword} />
        </div>
      </div>
      <table>
        <thead>
          <tr>{listHead}</tr>
        </thead>
        <tbody>{dataView}</tbody>
      </table>
      <div className='table-bottom'>
        <div className='data-view'>
          {keyWord === '' && (
            <span>
              Data {pagination.total} of {pagination.total_data}
            </span>
          )}
        </div>
        <Pagination
          divider={pagination.per_page}
          total={pagination.total_data}
          currentPage={pagination.page}
          total_page={pagination.total_page}
          setCurrentPage={changeCurrentPage}
        />
      </div>
    </div>
  );
};

DashboardTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  action: PropTypes.array,
  search: PropTypes.string,
  idKeyField: PropTypes.any,
  pagination: PropTypes.array
};

export default withRouter(DashboardTable);
