import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';

const RepresentativeOffice = ({ i18n, history }) => {
  const lang = (type, params) => i18n(`economy.tab3.${type}`, { ...params });

  return (
    <div className='card-instruction-detail'>
      <div className='title'>{lang('title')}</div>
      <main>
        <div className='instruction-wrap'>
          <div dangerouslySetInnerHTML={{ __html: lang('step.instruction') }} />

          <div
            className='mtop16'
            dangerouslySetInnerHTML={{ __html: lang('step.instruction1') }}
          />
          <a
            className='the-btn mtop16 btn btn-primary'
            href='https://api.whatsapp.com/send?phone=16464913809'
            target='_blank'
            without
            rel='noopener noreferrer'
            style={{ width: 'max-content' }}
          >
            {lang('step.cta1')}
          </a>
          <div
            className='mtop16'
            dangerouslySetInnerHTML={{ __html: lang('step.instruction2') }}
          />
          <Button
            color='primary'
            className='mtop16'
            onClick={() => history.push('/economy/investment-inquiry/form')}
          >
            {lang('step.cta2')}
          </Button>

          <div
            className='mtop16'
            dangerouslySetInnerHTML={{ __html: lang('step.instruction3') }}
          />
          <Button
            color='primary'
            className='mtop16'
            onClick={() => history.push('/economy/statement/form')}
          >
            {lang('step.cta3')}
          </Button>
          <br />
          <a
            href='https://www.indonesia-investments.com/business/foreign-investment/establish-foreign-company-pt-pma/item5739'
            target='_blank'
            without
            rel='noopener noreferrer'
          >
            https://www.indonesia-investments.com/business/foreign-investment/establish-foreign-company-pt-pma/item5739
          </a>
          <br />
          <a
            href='https://www.indonesia-investments.com/id/business/foreign-investment/representative-office-kppa/item5743'
            target='_blank'
            without
            rel='noopener noreferrer'
          >
            https://www.indonesia-investments.com/id/business/foreign-investment/representative-office-kppa/item5743
          </a>
        </div>
      </main>
    </div>
  );
};

RepresentativeOffice.propTypes = {
  i18n: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default compose(connect(mapStateToProps), withRouter)(RepresentativeOffice);
