import axios from 'axios';

const postPhotoRequest = () => ({
  type: 'POST_PHOTO_GENERAL_REQUEST'
});

const postPhotoSuccess = (data) => ({
  type: 'POST_PHOTO_GENERAL_SUCCESS',
  payload: data
});

const postPhotoFailure = (error) => ({
  type: 'POST_PHOTO_GENERAL_FAILURE',
  payload: error
});

const postPhotoGeneral = (params) => {
  return (dispatch) => {
    dispatch(postPhotoRequest());
    return axios
      .post(process.env.REACT_APP_BACKEND_URI + '/storages', params)
      .then((data) => dispatch(postPhotoSuccess(data)))
      .catch((error) => dispatch(postPhotoFailure(error)));
  };
};

export { postPhotoGeneral };
