export const optionsList = (i18n) => {
  const passportTypes = [
    { value: 'diplomatic', name: i18n('form.type_of_passport.diplomatic') },
    { value: 'service', name: i18n('form.type_of_passport.service') },
    { value: 'unlp', name: i18n('form.type_of_passport.unlp') },
    { value: 'other', name: i18n('form.type_of_passport.other') }
  ];

  const country_list = [
    { value: '1', label: 'Country1' },
    { value: '2', label: 'Country2' }
  ];

  const answerOptions = [
    { value: 'true', name: i18n('form.answer_options.yes') },
    { value: 'false', name: i18n('form.answer_options.no') }
  ];

  return {
    passportTypes,
    country_list,
    answerOptions
  };
};
