import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CardInstruction from '../../../../components/CardInstruction';

import { getDocLegalServiceId } from '../../../form/DocLegalizationForm/functions';

const ValidationDoc = ({ i18n, docLegalServiceList }) => {
  const lang = (type, params) => i18n(`doc_legalization.tab2.${type}`, { ...params });
  const serviceId = getDocLegalServiceId(docLegalServiceList, 'validation');

  const stepOuterList = [
    {
      label: i18n('doc_legalization.step.report_self.title')
    },
    {
      label: i18n('doc_legalization.step.take_number.title'),
      cta: i18n('doc_legalization.step.take_number.cta'),
      link: `/doc-legalization/registration/create?type=docLegal&serviceId=${serviceId}`
    }
  ];

  const instructionList = [
    i18n('doc_legalization.instruction_step.proof_domicile'),
    i18n('doc_legalization.instruction_step.ori_passport'),
    i18n('doc_legalization.instruction_step.copy_doc'),
    i18n('doc_legalization.instruction_step.no_fee')
  ];

  return (
    <CardInstruction
      stepOuterList={stepOuterList}
      instructionList={instructionList}
      lang={lang}
    />
  );
};

ValidationDoc.propTypes = {
  i18n: PropTypes.func,
  docLegalServiceList: PropTypes.array
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(ValidationDoc);
