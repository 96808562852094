import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import './style.scss';

const CardSideBar = ({ history, activePath, sideBarList, currMenu, ...cardProps }) => {
  const [openSideBar, setOpenSidebar] = useState([]);
  const [ActiveComponent, setActiveComponent] = useState(null);
  const findActiveComponent = () => {
    let activeComponent = null;
    let tmpOpenSidebar = [...openSideBar];

    sideBarList.find(({ link, sublist, Component, parentKey }) => {
      if (link) {
        if (link === activePath) {
          activeComponent = Component;
        }
      } else {
        const findSubList = sublist.find(({ link: linkSub }) => linkSub === activePath);
        if (findSubList) {
          activeComponent = findSubList.Component;
          const check = openSideBar.find((openedName) => openedName === parentKey);

          // only add if new opened menu
          if (!check) {
            tmpOpenSidebar.push(parentKey);
          }
        }
      }
    });

    return {
      activeComponent,
      tmpOpenSidebar
    };
  };

  useEffect(() => {
    const { activeComponent, tmpOpenSidebar } = findActiveComponent();
    setActiveComponent(activeComponent);
    setOpenSidebar(tmpOpenSidebar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePath]);

  const toggleSidebar = (parentKey) => {
    const check = openSideBar.find((openedName) => openedName === parentKey);
    let tmpOpenSidebar = [...openSideBar];
    if (check) {
      tmpOpenSidebar = openSideBar.filter((openedName) => openedName !== parentKey);
    } else {
      tmpOpenSidebar.push(parentKey);
    }

    setOpenSidebar(tmpOpenSidebar);
  };

  const renderMenus = () => {
    return sideBarList.map(({ label, link, sublist = [], parentKey }, idx) => {
      const openSubMenu =
        sublist.length > 0
          ? openSideBar.find((openedName) => openedName === parentKey)
            ? true
            : false
          : false;

      return (
        <div className={`menu-wrap ${openSubMenu ? 'opened' : ''}`} key={idx}>
          <div
            className={`menu ${link === activePath ? 'active' : ''} ${
              sublist.length === 0 ? '' : 'with-sub-menu'
            }`}
            onClick={() =>
              sublist.length === 0 ? history.push(link) : toggleSidebar(parentKey)
            }
          >
            <label htmlFor=''>{label}</label>
            <div className='arrow' />
          </div>
          {sublist.length > 0 && (
            <div className='sublist-wrap'>
              {sublist.map(({ label, link }, idx2) => (
                <div
                  className={`sub menu ${link === activePath ? 'active' : ''}`}
                  key={idx2}
                  onClick={() => history.push(link)}
                >
                  <label htmlFor=''>{label}</label>
                  <div className='arrow' />
                </div>
              ))}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <Container className='card-side-bar'>
      <div className='sidebar'>
        <div className={`custom-rates ${currMenu !== undefined ? 'show' : 'hide'}`}>
          {currMenu}
          <br />
          <hr />
        </div>
        {renderMenus()}
      </div>
      <div className='card-container'>
        {ActiveComponent && <ActiveComponent {...cardProps} />}
      </div>
    </Container>
  );
};

export default withRouter(CardSideBar);
