import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as yup from 'yup';

import { convertArrayToObject } from '../../../../../helpers/helpers';
import { errorScroll } from '../../../../../helpers/functions';

import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/input/TextInput';
import Dropdown from '../../../../../components/Dropdown';
import DobInput from '../../../components/Dob';

import { optionsList } from '../PersonalInformation/functions';

const ParentForm = ({
  history,
  i18n,
  setForm: setFormGlobal,
  form: formGlobal,
  setTmpForm,
  tmpform,
  country_options
}) => {
  const { location } = history;
  const { search } = location;
  const [form, setForm] = useState({ ...(formGlobal.parent || tmpform.parent) });
  const [errors, setErrors] = useState({});

  const handlerSubmit = (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      father_name: yup.string().required('required'),
      mother_name: yup.string().required('required'),
      ...(form.father_name !== 'Tidak diketahui' && {
        father_nationality: yup.object().shape({
          name: yup.string().required('required')
        })
      }),
      ...(form.mother_name !== 'Tidak diketahui' && {
        mother_nationality: yup.object().shape({
          name: yup.string().required('required')
        })
      })
    });

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setFormGlobal({ ...formGlobal, parent: form });
        history.push(`/passpor/registration/emergency-contact${search}`);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    const tmpErrors = { ...errors };
    if (key === 'father_name' && value === 'Tidak diketahui') {
      tmpErrors['father_nationality.value'] = undefined;
    }
    if (key === 'mother_name' && value === 'Tidak diketahui') {
      tmpErrors['mother_nationality.value'] = undefined;
    }
    setErrors({ ...tmpErrors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    setTmpForm({ ...tmpform, parent: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <form onSubmit={handlerSubmit} id='personal-info'>
      <div className='form-container'>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_parent_father')}
          </div>
          <TextInput
            label={i18n('form.father_name.label')}
            placeholder={i18n('form.father_name.placeholder')}
            value={get(form, ['father_name'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'father_name')
            }}
            errorMessage={
              get(errors, ['father_name']) &&
              i18n(`validation.${get(errors, ['father_name'])}`)
            }
            detailInput={i18n('form.father_name.notes')}
          />
          <DobInput
            i18n={i18n}
            id='personal'
            place={{
              label: i18n('form.dob_place.label'),
              placeholder: i18n('form.dob_place.placeholder'),
              onChange: (birth_city) => handlerChanges(birth_city, 'father_birth_city'),
              value: get(form, ['father_birth_city']),
              errorMessage:
                get(errors, ['father_birth_city']) &&
                i18n(`validation.${get(errors, ['father_birth_city'])}`)
            }}
            date={{
              label: i18n('form.dob.label'),
              placeholder: i18n('form.dob.placeholder'),
              datepickerProps: { maxDate: new Date() },
              onChange: (birth_date) => handlerChanges(birth_date, 'father_birth_date'),
              value: get(form, ['father_birth_date']),
              errorMessage:
                get(errors, ['father_birth_date']) &&
                i18n(`validation.${get(errors, ['father_birth_date'])}`)
            }}
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.father_citizenship.label')}
            placeholder={i18n('form.father_citizenship.placeholder')}
            value={get(form, ['father_nationality'])}
            options={country_options}
            onClick={(item) =>
              handlerChanges(item, 'father_nationality', 'father_nationality.name')
            }
            isValueObject
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            valueKey='name'
            labelKey='name'
            withAutocomplete
            errorMessage={
              get(errors, ['father_nationality.name']) &&
              i18n(`validation.${get(errors, ['father_nationality.name'])}`)
            }
          />
        </div>
        <div className='form-wrap'>
          <div className='title'>
            {i18n('form_personal_data.instruction_parent_mother')}
          </div>
          <TextInput
            label={i18n('form.mother_name.label')}
            placeholder={i18n('form.mother_name.placeholder')}
            value={get(form, ['mother_name'])}
            inputProps={{
              maxLength: 255,
              onChange: (e) => handlerChanges(e.target.value, 'mother_name')
            }}
            errorMessage={
              get(errors, ['mother_name']) &&
              i18n(`validation.${get(errors, ['mother_name'])}`)
            }
            detailInput={i18n('form.mother_name.notes')}
          />
          <DobInput
            i18n={i18n}
            id='personal'
            place={{
              label: i18n('form.dob_place.label'),
              placeholder: i18n('form.dob_place.placeholder'),
              onChange: (birth_city) => handlerChanges(birth_city, 'mother_birth_city'),
              value: get(form, ['mother_birth_city']),
              errorMessage:
                get(errors, ['mother_birth_city']) &&
                i18n(`validation.${get(errors, ['mother_birth_city'])}`)
            }}
            date={{
              label: i18n('form.dob.label'),
              placeholder: i18n('form.dob.placeholder'),
              datepickerProps: { maxDate: new Date() },
              onChange: (birth_date) => handlerChanges(birth_date, 'mother_birth_date'),
              value: get(form, ['mother_birth_date']),
              errorMessage:
                get(errors, ['mother_birth_date']) &&
                i18n(`validation.${get(errors, ['mother_birth_date'])}`)
            }}
          />
          <Dropdown
            className='row input-container'
            label={i18n('form.mother_citizenship.label')}
            placeholder={i18n('form.mother_citizenship.placeholder')}
            value={get(form, ['mother_nationality'])}
            options={country_options}
            onClick={(item) =>
              handlerChanges(item, 'mother_nationality', 'mother_nationality.name')
            }
            isValueObject
            valueKey='name'
            labelKey='name'
            dropdownToggleProps={{
              tag: 'div',
              className: 'input-dropdown'
            }}
            withAutocomplete
            errorMessage={
              get(errors, ['mother_nationality.name']) &&
              i18n(`validation.${get(errors, ['mother_nationality.name'])}`)
            }
          />
        </div>
      </div>

      <div className='btn-wrap'>
        <Button
          color='link'
          type='button'
          onClick={() => history.push(`/passpor/registration/job${search}`)}
        >
          {i18n('cta.cancel')}
        </Button>
        <Button color='primary' type='submit' className='pass-btn'>
          {i18n('cta.next')}
        </Button>
      </div>
    </form>
  );
};

ParentForm.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  setForm: PropTypes.func,
  form: PropTypes.object,
  setTmpForm: PropTypes.func,
  tmpform: PropTypes.object
};

export default withRouter(ParentForm);
