const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getPassportListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_PASSPORT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PASSPORT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_PASSPORT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_PASSPORT_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getPassportStatisticReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_PASSPORT_STATISTIC_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PASSPORT_STATISTIC_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_PASSPORT_STATISTIC_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getPassportStatisticWeekReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_PASSPORT_STATISTIC_WEEK_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PASSPORT_STATISTIC_WEEK_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_PASSPORT_STATISTIC_WEEK_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getPassportStatisticMonthReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_PASSPORT_STATISTIC_MONTH_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PASSPORT_STATISTIC_MONTH_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_PASSPORT_STATISTIC_MONTH_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getPassportStatisticAllReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_PASSPORT_STATISTIC_ALL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_PASSPORT_STATISTIC_ALL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_PASSPORT_STATISTIC_ALL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PASSPORT_STATISTIC_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const patchAction = {
  data: {},
  status: 'idle',
  error: ''
};

const patchApproveReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_APPROVE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_APPROVE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_APPROVE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchRejectReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_REJECT_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_REJECT_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_REJECT_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchProcessReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_PROCESS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_PROCESS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_PROCESS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const patchPendingReducer = (state = patchAction, action) => {
  switch (action.type) {
    case 'PATCH_PENDING_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PATCH_PENDING_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PATCH_PENDING_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

export {
  getPassportListReducer,
  getPassportStatisticReducer,
  getPassportStatisticWeekReducer,
  getPassportStatisticMonthReducer,
  getPassportStatisticAllReducer,
  patchApproveReducer,
  patchRejectReducer,
  patchProcessReducer,
  patchPendingReducer
};
