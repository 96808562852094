import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import findLastKey from 'lodash/findLastKey';
import findIndex from 'lodash/findIndex';

import Banner from '../../../components/Banner';
import CardDetail from '../../../components/CardDetail';
import FormMenu from '../../../components/FormMenu';

import './style.scss';

const FormPassport = ({
  history,
  i18n,
  parentPath,
  dataStep,
  form,
  applicationId,
  children,
  title,
  formTitle,
  formSubtitle,
  withoutSelfReportBtn = false
}) => {
  const keyLastFormSubmited = useMemo(() => findLastKey(form), [form]); // Check position should submited position
  const findIndexLastForm = useMemo(
    () =>
      findIndex(dataStep, {
        keyForm: keyLastFormSubmited
      }),
    [dataStep, keyLastFormSubmited]
  );
  const activePath = get(history, ['location', 'pathname']);

  // Check last form should submited - redirect to right form
  useEffect(() => {
    let redirectLink;
    const activeIndex = dataStep.findIndex(({ link }) => link.includes(activePath));
    const isRegistrationOnly =
      activePath === parentPath || activePath === `${parentPath}/`;

    if (keyLastFormSubmited) {
      const nextIndex =
        findIndexLastForm + 1 >= dataStep.length - 1
          ? dataStep.length - 1
          : findIndexLastForm + 1;

      // Check activeIndex below/same with submited position
      // Should redirect right page if only registration
      if (activeIndex < 0 || activeIndex > nextIndex || isRegistrationOnly) {
        redirectLink = dataStep[nextIndex].link;
      }
    } else {
      redirectLink = dataStep[0].link;
    }

    if (redirectLink && redirectLink !== activePath) {
      history.push(redirectLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePath]);

  return (
    <>
      <Banner
        title={formTitle}
        subtitle={
          formSubtitle ? (
            formSubtitle
          ) : (
            <>
              <div className='text'>{i18n('paspor_page.banner.subtitle')}</div>
              <div
                className='desc'
                dangerouslySetInnerHTML={{ __html: i18n('paspor_page.banner.desc') }}
              />
            </>
          )
        }
        buttonProps={
          !withoutSelfReportBtn && [
            {
              btnStyle: { color: 'primary' },
              label: (
                <a href='https://peduliwni.kemlu.go.id/beranda.html'>
                  {i18n('cta.report_self')}
                </a>
              )
            }
          ]
        }
        backButtonClick={() => history.goBack()}
      />
      <CardDetail
        title={title}
        className='form'
        rightInfo={
          <span>
            <span className='grey-text'>{i18n('form_personal_data.application_id')}</span>{' '}
            {applicationId}
          </span>
        }
      >
        <FormMenu
          i18n={i18n}
          lastFormIdx={findIndexLastForm}
          stepList={dataStep}
          activePath={activePath}
        />
        {children}
      </CardDetail>
    </>
  );
};

FormPassport.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.func,
  parentPath: PropTypes.string,
  dataStep: PropTypes.array,
  form: PropTypes.object,
  applicationId: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string
};

export default withRouter(FormPassport);
