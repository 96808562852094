const adminLogin = {
  data: [],
  status: 'idle',
  error: ''
};

const getExhibitorListReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_EXHIBITOR_LIST_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const getExhibitorDetailReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'GET_EXHIBITOR_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_EXHIBITOR_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_EXHIBITOR_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data
      };
    case 'RESET_EXHIBITOR_DETAIL_LIST':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

export { getExhibitorListReducer, getExhibitorDetailReducer };
