import React, { useEffect } from 'react';
import DashboardTable from '../../../components/DashboardTableOld2';
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { arrayHasValue } from '../../../helpers/helpers';
import './style.scss';
import { getTours, deleteTour } from './action';
import Loading from '../../../components/Loading';

const AdminPariwisata = ({ history, getTours, toursList, deleteTour, deletedTour }) => {
  useEffect(() => {
    if (!arrayHasValue(toursList.data)) {
      getTours();
    }
  }, [getTours, toursList.data]);

  const handleDelete = (id) => {
    deleteTour(id);
  };

  if (deletedTour.status === 'resolve') {
    window.location.reload();
  };

  const columns = [
    { dataField: '_id', text: 'No' },
    { dataField: 'image_url', text: 'Image' },
    { dataField: 'title', text: 'Title' },
    { dataField: 'place', text: 'Location' },
    { dataField: 'created_at', text: 'Start Date' },
    { dataField: '_id', text: 'Action' }
  ];
  const data = toursList.data;

  const action = [
    {
      onClick: (id) => history.push(`/admin/pariwisata/${id}`, data),
      type: 'view'
    },
    {
      onClick: (id) => history.push(`/admin/pariwisata/edit/${id}`, data),
      type: 'edit'
    },
    {
      onClick: (id) => handleDelete(id),
      type: 'delete'
    }
  ];

  return (
    <div className='admin-news'>
      <div className='admin-news-top'>
        <h1>DATA PARIWISATA</h1>
        <div className='admin-news-action'>
          <Button
            color='primary'
            onClick={() => history.push('/admin/pariwisata/create')}
          >
            Tambah Pariwisata
          </Button>
        </div>
      </div>
      {toursList.status === 'resolve' ? (
        <DashboardTable
          columns={columns}
          data={data}
          action={action}
          search='title'
          idKeyField='_id'
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

AdminPariwisata.propTypes = {
  history: PropTypes.object,
  toursList: PropTypes.object,
  getTours: PropTypes.func,
  deletedTour: PropTypes.object,
  deleteTour: PropTypes.func
};

const mapStateToProps = (state) => ({
  toursList: {
    status: state.toursReducerAdmin.status,
    error: state.toursReducerAdmin.error,
    data: state.toursReducerAdmin.data
  },
  deletedTour: {
    status: state.deleteTourReducerAdmin.status,
    error: state.deleteTourReducerAdmin.error,
    data: state.deleteTourReducerAdmin.data
  }
});

export default compose(
  connect(mapStateToProps, {
    getTours,
    deleteTour
  }),
  withRouter
)(AdminPariwisata);
